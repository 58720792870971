import React, { MouseEvent } from 'react';

import Icon from 'components/icon/Icon';
import InsetText from 'components/text/inset/InsetText';

import styles from './IconButton.module.scss';

type OwnProps = {
  className?: string;
  icon?: string;
  loading?: boolean;
  size?: string;
  isDanger?: boolean;
  isFilled?: boolean;
  isUnselected?: boolean;
  isSuccess?: boolean;
  disabled?: boolean;
  textLabel?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export type IconButtonProps = OwnProps & React.RefAttributes<HTMLDivElement>;

const IconButton: React.FC<IconButtonProps> = React.forwardRef<HTMLDivElement, IconButtonProps>(
  (
    {
      className = '',
      textLabel = '',
      size = 'default',
      disabled,
      isFilled = false,
      isDanger = false,
      isSuccess = false,
      isUnselected = false,
      ...props
    },
    ref
  ) => {
    const disabledCls = disabled ? 'disabled' : '';
    const clickHandler = (e: MouseEvent<HTMLDivElement>) => {
      // e.stopPropagation();
      // e.preventDefault();
      if (props.onClick) props.onClick(e);
    };
    return (
      <div
        ref={(node) => node && (disabled ? node.setAttribute('inert', '') : node.removeAttribute('inert'))}
        {...props}
        onClick={clickHandler}
        className={`${styles.IconButton} ${size} ${disabledCls}`}>
        <span ref={ref} itemID="anchorForPositioning"></span>
        {props.icon && (
          <Icon
            icon={props.icon}
            className={className}
            isUnselected={isUnselected}
            isDanger={isDanger}
            isSuccess={isSuccess}
            isFilled={isFilled}
          />
        )}
        {textLabel && <InsetText size="sma">&nbsp;{textLabel}</InsetText>}
      </div>
    );
  }
);

export default IconButton;
