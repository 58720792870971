import { db } from '../firebase/Firebase';
import { doc, collection, setDoc, query, getDocs, where, updateDoc } from 'firebase/firestore';
import { store } from 'app/store';

import {
  CareerId,
  CareerHistoryReportT,
  CareerHistoryReportsT,
  CareerHistoryResponseT,
  CareerHistoryReportMapT,
  GameHistoryReportsT,
  ReportT,
} from 'types/Career.types';

import { useStyledLogger } from 'app/config/LogConfig';
import { sortHistory } from 'helpers/sortHelpers';
import { getModifiedInfo } from 'helpers/careerHelpers';

import { doSetActiveScene } from 'scenes/scenesSlice';
import { getProfileTitle } from 'modules/user/userSlice';

import { userStatusUpdated } from './UserAPI';
import { reportCardGames } from 'api/GameHistoryAPI';
import { historyConverter, historyToDoc } from 'api/converters/HistoryConverter';
import { IProfile, ProfileId } from 'types/User.types';
import { isPrint } from 'helpers/appHelpers';
import { QuestionT } from 'types/Question.types';
import { fetchQuestionsAsDictionary } from 'api/QuestionsAPI';
import { Dictionary } from 'types/App.types';
import { fetchUserProfile } from 'api/ProfilesAPI';
import { isGameTitle } from 'helpers/gamesHelpers';

const PRIMARY_COLOR = 'deeppink';
const SECONDARY_COLOR = 'cornsilk';
const CLS_NAME = 'HistoryAPI';
const PRINT_FLAG = true;

const printMsg = useStyledLogger(CLS_NAME, PRIMARY_COLOR, SECONDARY_COLOR);

// #region HISTORY APIs ******************************************************************
export const getAllHistory = async (careerId: CareerId): Promise<CareerHistoryReportsT> => {
  const collectionData = collection(db, `careers/${careerId}/history`).withConverter(historyConverter);
  const q = query(collectionData);

  const querySnapshot = await getDocs(q);
  const history: Array<CareerHistoryReportT> = [];
  querySnapshot.docs.forEach((doc) => {
    const docData = { ...doc.data(), historyId: doc.id };
    history.push(docData);
  });

  return history;
};

export const getHistory = async (careerId: CareerId): Promise<CareerHistoryReportsT> => {
  const collectionData = collection(db, `careers/${careerId}/history`).withConverter(historyConverter);
  const q = query(collectionData, where('reportType', '==', 'question'));
  // const q = query(collectionData);

  const querySnapshot = await getDocs(q);
  const history: Array<CareerHistoryReportT> = [];
  querySnapshot.docs.forEach((doc) => {
    const docData = { ...doc.data(), historyId: doc.id };
    history.push(docData);
  });

  return history;
};

export const updateHistoryEntry = async (history: CareerHistoryReportT): Promise<void> => {
  const docRef = doc(db, `careers/${history.careerId}/history/${history.historyId}`).withConverter(historyConverter);
  const modified = getModifiedInfo();
  isPrint(PRINT_FLAG) && console.log(...printMsg('Update CareerHistoryReportT', 'title', history.title));
  return await updateDoc(docRef, historyToDoc({ ...history, ...modified }));
};

export const createHistoryEntry = async (historyItem: CareerHistoryReportT): Promise<CareerHistoryReportT> => {
  const collectionData = collection(db, `careers/${historyItem.careerId}/history`).withConverter(historyConverter);
  const docRef = doc(collectionData);
  const createdHistoryEntry: CareerHistoryReportT = { ...historyItem, historyId: docRef.id };
  // Create new record with autogenerated id as lessonId
  await setDoc(docRef, createdHistoryEntry);
  isPrint(PRINT_FLAG) && console.log(...printMsg('Created CareerHistoryReportT', 'historyId', docRef.id));
  return createdHistoryEntry;
};
// #endregion

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const reportCardSceneLoader = async ({ params }: any): Promise<CareerHistoryResponseT> => {
  const userStatus = await userStatusUpdated();
  if (userStatus === 'signedIn') {
    store.dispatch(doSetActiveScene('account:profile:reportcard'));
    const careerId = params.careerId as CareerId;
    const profileId = params.profileId as ProfileId;
    const profileTitle: string = getProfileTitle(store.getState(), profileId);
    return await reportCardDataLoader(profileTitle, careerId);
  }
  return { reports: [], reportsMapByQuestionId: {}, gameReports: [] };
};

export const reportCardDataLoader = async (title: string, careerId: CareerId): Promise<CareerHistoryResponseT> => {
  const questionDictionary: Dictionary<QuestionT> = await fetchQuestionsAsDictionary();
  const history: CareerHistoryReportsT = await getHistory(careerId);
  const gameReports: GameHistoryReportsT = await reportCardGames(careerId);

  const reports = history.map((h) => ({
    ...h,
    questionTitle: h.title,
    profileTitle: title,
    title: h.title,
  }));

  reports.sort(sortHistory);

  // create groups
  const reportsMapByQuestionId: CareerHistoryReportMapT = {};
  reports.forEach((h) => {
    if (!reportsMapByQuestionId[h.questionId]) reportsMapByQuestionId[h.questionId] = [];
    reportsMapByQuestionId[h.questionId].push(h);
  });

  return { reports, reportsMapByQuestionId, gameReports, questionDictionary };
};

/**
 * Updates missing fields or empty values where it is missing.
 *
 * @param {ProfileId} profileId - profile id
 * @param {CareerId} careerId
 *
 * @return {Promise<CareerHistoryResponseT>}
 */
export const reportCardDataAutoFixer = async (
  profileId: ProfileId,
  careerId: CareerId
): Promise<CareerHistoryResponseT> => {
  const questionDictionary: Dictionary<QuestionT> = await fetchQuestionsAsDictionary();
  const profile: IProfile = await fetchUserProfile(profileId);
  const history: CareerHistoryReportsT = await getAllHistory(careerId);
  const gameReports: GameHistoryReportsT = await reportCardGames(careerId);
  const fnName = 'ReportCardDataLoader';
  isPrint(PRINT_FLAG) && console.log(...printMsg(fnName, 'history.length', String(history.length)));
  isPrint(PRINT_FLAG) && console.log(...printMsg(fnName, 'gameReports.length', String(gameReports.length)));

  const hasReportTypeProp = history.filter((h) => Object.hasOwn(h, 'reportType')) ?? [];
  const hasGameInstanceProp = history.filter((h) => Object.hasOwn(h, 'gameInstance')) ?? [];
  const hasMissingTitles = history.filter((h) => h.reportType === 'question' && h.title === '') ?? [];
  const hasGameTitles = history.filter((h) => isGameTitle(h.title)) ?? [];

  isPrint(PRINT_FLAG) && console.log(...printMsg(fnName, 'NEEDS REPAIR?', String(history.length)));
  isPrint(PRINT_FLAG) && console.log(...printMsg(fnName, 'missingTitles', String(hasMissingTitles.length)));
  isPrint(PRINT_FLAG) && console.log(...printMsg(fnName, 'invalidReportType', String(hasReportTypeProp.length)));
  isPrint(PRINT_FLAG) && console.log(...printMsg(fnName, 'invalidGameReport', String(hasGameInstanceProp.length)));
  isPrint(PRINT_FLAG) && console.log(...printMsg(fnName, 'hasGameTitles', String(hasGameTitles.length)));

  if (questionDictionary && history) {
    const modified = getModifiedInfo();

    const reports = history.map((h: CareerHistoryReportT) => {
      const isGameType = Object.hasOwn(h, 'gameInstance') || isGameTitle(h.title);
      if (isGameType) return { ...h, reportType: 'game' as ReportT, profileTitle: profile.title, ...modified };

      const title = h.title.length === 0 ? questionDictionary[h.questionId].title : h.title;
      return {
        ...h,
        questionTitle: h.title,
        profileTitle: profile.title,
        reportType: 'question' as ReportT,
        title: title,
        ...modified,
      };
    });

    reports.forEach(async (r) => {
      if (!Object.hasOwn(r, 'gameInstance') && !isGameTitle(r.title)) await updateHistoryEntry(r);
    });
  }

  return reportCardDataLoader(profile.title, careerId);
};
