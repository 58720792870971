import React, { AnimationEvent } from 'react';
import type { MeIconProps } from 'types/App.types';

import styles from './MeIcon.module.scss';

const MeIcon: React.FC<MeIconProps> = ({
  size = 'sma',
  weight = 'bold600',
  className = '',
  insetCls = '',
  isDanger = false,
  isSuccess = false,
  isFilled = false,
  isDisabled = false,
  isUnselected = false,
  isInfo = false,
  mainColor = false,
  showPointerCursor = false,
  insetIcon = false,
  lineHeight, // because some `ElementSizeT` have preset `line-height` do not use default here
  ...props
}) => {
  const lHeightCls = lineHeight ?? '';
  const filledCls = isFilled ? styles.MeIconFilled : '';
  const unselectedCls = isUnselected ? styles.MeIconUnselected : '';
  const dangerCls = isDanger ? styles.DangerBg : '';
  const successCls = isSuccess ? styles.SuccessBg : '';
  const infoCls = isInfo ? styles.InfoBg : '';
  const cursorCls = showPointerCursor ? styles.UsePointerCursor : '';
  const disabledCls = isDisabled ? styles.DisabledButton : '';
  const mainCls = mainColor ? styles.MainBg : '';
  const colorCls = `${dangerCls} ${successCls} ${infoCls} ${mainCls}`;
  const propsCls = `${filledCls} ${cursorCls} ${disabledCls} ${unselectedCls}`;

  const onAnimationEnd = (e: AnimationEvent) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  const IconSpan = (
    <span
      id={props.id}
      data-item-id={props.id}
      aria-label={`${props.icon} icon`}
      className={`material-symbols-outlined ${styles.MeIcon} ${size} ${propsCls} ${weight} ${lHeightCls} ${className}`}
      title={props.title}
      {...props}
      onAnimationEnd={onAnimationEnd}
      style={props.style}
      onClick={props.onClick}>
      {props.icon}
    </span>
  );

  if (insetIcon) {
    return <div className={`${styles.InsetIcon} ${colorCls} ${insetCls}`}>{IconSpan}</div>;
  }

  return IconSpan;
};

export default MeIcon;
