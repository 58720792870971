import { store } from 'app/store';
import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { getModifiedInfo } from 'helpers/careerHelpers';
import { TutorialT } from 'types/Tutorial.types';

// #region CONVERTERS + BASE MODEL
export const getInitialTutorial = (): TutorialT => {
  const userId = store.getState().user.uid || '';
  const initTutorial: TutorialT = {
    title: '',
    badgeId: '',
    category: 'unknown',
    tutorialId: 'createTutorialId',
    part: '',
    createdBy: userId,
    createdOn: Date.now(),
    modifiedBy: userId,
    modifiedOn: Date.now(),
    versionId: '',
  };

  return initTutorial;
};

export const tutorialToDoc = (tutorial: TutorialT): DocumentData => {
  return {
    title: tutorial.title,
    category: tutorial.category,
    tutorialId: tutorial.tutorialId,
    badgeId: tutorial.badgeId,
    part: tutorial.part,
    createdBy: tutorial.createdBy,
    createdOn: tutorial.createdOn,
    modifiedBy: tutorial.modifiedBy,
    modifiedOn: tutorial.modifiedOn,
    versionId: tutorial.versionId,
  };
};

export const tutorialConverter: FirestoreDataConverter<TutorialT> = {
  toFirestore(tutorial: TutorialT): DocumentData {
    return { ...tutorialToDoc(tutorial), ...getModifiedInfo() };
  },

  fromFirestore(docSnap: QueryDocumentSnapshot, options?: SnapshotOptions): TutorialT {
    const tutorial = docSnap.data(options);
    return {
      category: tutorial.category,
      title: tutorial.title,
      tutorialId: tutorial.tutorialId || docSnap.id,
      badgeId: tutorial.badgeId,
      part: tutorial.part || '1',
      createdBy: tutorial.createdBy || '',
      createdOn: tutorial.createdOn || 0,
      modifiedBy: tutorial.modifiedBy,
      modifiedOn: tutorial.modifiedOn,
      versionId: tutorial.versionId || '',
    };
  },
};
// #endregion
