/* eslint-disable @typescript-eslint/no-explicit-any */
import { persistor, store } from 'app/store';
import { db } from '../firebase/Firebase';
import {
  getDoc,
  doc,
  updateDoc,
  collection,
  setDoc,
  query,
  getDocs,
  arrayUnion,
  arrayRemove,
  orderBy,
} from 'firebase/firestore';
import { redirect } from 'react-router-dom';

import {
  AwardCode,
  AwardCodesT,
  AwardMedals,
  AwardStars,
  MedalsT,
  ModifyT,
  Status,
  StatusT,
  SubmitActionData,
} from 'types/App.types';
import {
  CareerT,
  CareerLoaderResponseT,
  CareersT,
  ManageCareerData,
  CareerHubLoaderResponseT,
  CareerId,
  CareerBadgeItemT,
  CareerHubActionResponseT,
} from 'types/Career.types';
import { CourseId, CourseIds, CourseUI, CourseUIs, CoursesT } from 'types/Course.types';
import { AwardedGameT, AwardedGamesCollection } from 'types/Game.types';
import {
  IExpandedProfiles,
  IProfile,
  IExpandedProfile,
  IUsers,
  ProfilesT,
  UserStatusT,
  ProfileId,
} from 'types/User.types';
import { CareerChainCards } from 'types/BaseCareer.types';

import {
  setCareerStatus,
  setCareerT,
  setCourseIds,
  getCareerStatus,
  getCurrentCareerId,
  resetCareer,
  getByIdCareerCourseUI,
  setCareerBadges,
  setCareerChain,
  setCareerChainActiveIndex,
  getCareerChain,
  getAwardCodes,
  setCareerAwardCodes,
  setCareerAwardedGames,
  isCareerStatusLoaded,
} from 'modules/career/careerSlice';
import {
  CareerUncompleteItemsT,
  getCareerUncompleteItems,
  setCareerUncompleteItems,
  setCareers,
  setManageCareersStatus,
  setProfiles,
  setCourses as setAllAvailableCourses,
  setProfileStatus,
  updateProfile,
} from 'scenes/management/managerSlice';
import { doSetActiveScene, setLastActiveManageScene } from 'scenes/scenesSlice';
import { doSetActiveProfileId, getActiveCareerId, getActiveProfile } from 'modules/user/userSlice';

import { setToastNotification } from 'modules/user/userUiSlice';

import { getCourses } from './CoursesAPI';
import { fetchUserProfile, fetchUserProfiles, patchUserProfile } from './ProfilesAPI';
import { fetchUsers, setUserProfileAsActive, userStatusUpdated } from './UserAPI';
import { resolveTutorial, resolveQuestion, resolveQuiz, resolveLesson, resolveCourse } from './ResolveCareerItemsAPI';
import {
  simpleResolveCourse,
  simpleResolveLesson,
  simpleResolveQuestion,
  simpleResolveQuiz,
  simpleResolveTutorial,
} from './SimpleResolveCareerItemsAPI';
import {
  careerConverter,
  getCareerUser,
  careerToDoc,
  awardsConverter,
  awardedGamesConverter,
  medalsConverter,
} from 'api/converters/CareerConverter';

import { useStyledLogger } from 'app/config/LogConfig';
import { AppRoutes } from 'app/constants/Routes';

import { convertFormDataToSubmitActionData } from 'helpers/typeCastingHelpers';
import { createCareerChain, findStartingActiveCardIndex, getModifiedInfo } from 'helpers/careerHelpers';
import { getExtendedUserProfiles } from 'helpers/profileHelpers';
import { getToastSuccessPayload } from 'helpers/uiHelpers';
import { isPrint } from 'helpers/appHelpers';
import { retrieveCareerBadges } from 'helpers/badgeHelpers';
import { sortCourses } from 'helpers/sortHelpers';
import { validateActiveProfileSelected } from 'api/AppAPI';

type ManagerCareerProfilesT = { careers: CareersT; profiles: IExpandedProfiles };

const PRINT_FLAG = true;

const printMsg = useStyledLogger('CareerAPI', 'lightpink');

// #region       CAREER APIs *************************************************************
export const getCareer = async (careerId: CareerId): Promise<CareerT> => {
  const docRef = doc(db, `careers/${careerId}`).withConverter(careerConverter);
  const docSnap = await getDoc(docRef);

  if (docSnap && docSnap.exists()) {
    const career: CareerT = docSnap.data();
    const userName: string = getCareerUser(career);
    const awardCodes: AwardCodesT = getAwardCodes(store.getState());
    return { ...career, userName, awardCodes: career.awardCodes.concat(awardCodes) };
  } else {
    isPrint(PRINT_FLAG) && console.log(...printMsg('getCareer', 'No such document!', `Unable to find ${careerId}`));
    throw Error(`Career (${careerId}) unable to find`);
  }
};

export const updateCareer = async (career: CareerT): Promise<void> => {
  const docCareer = careerToDoc(career);
  const careerRef = doc(db, `careers/${career.careerId}`);
  return await updateDoc(careerRef, { ...docCareer, complete: arrayUnion(...docCareer.complete) });
};

export const setCareerCourseIds = async (careerId: CareerId, courseIds: CourseIds) => {
  return await getCareer(careerId)
    .then(async (careerT: CareerT) => {
      if (careerT && careerT.userId) {
        const courses: CoursesT = await getCourses(courseIds);
        const modified = getModifiedInfo();
        const updatedCareer: CareerT = { ...careerT, courseIds: courseIds, courses, ...modified };
        const docCareer = careerToDoc(updatedCareer);
        const careerRef = doc(db, `careers/${careerId}`);
        await updateDoc(careerRef, docCareer);
        return updatedCareer;
      }
    })
    .catch((error) => {
      throw new Error(`could not load career ${error}`);
    });
};

export const updateCareerCompleteItems = async (uncomplete: CareerUncompleteItemsT): Promise<void> => {
  const careerRef = doc(db, `careers/${uncomplete.careerId}`);
  return await setDoc(careerRef, {
    complete: arrayRemove(uncomplete.itemsToUncomplete.toString()),
  });
};

export const updateCareerCompleteList = async (careerId: CareerId, completedId: string): Promise<void> => {
  const careerRef = doc(db, `careers/${careerId}`);
  return await updateDoc(careerRef, { complete: arrayUnion(completedId) });
};

export const updateCareerBadges = async (careerId: CareerId, badgeId: string): Promise<void> => {
  const careerRef = doc(db, `careers/${careerId}`);
  return await updateDoc(careerRef, { badges: arrayUnion(badgeId) });
};

export const updateCareerAwardsCollection = async (careerId: CareerId, awardCode: AwardCode): Promise<void> => {
  const careerRef = doc(db, `careers/${careerId}/awards/stars`);
  return await updateDoc(careerRef, { codes: arrayUnion(awardCode) });
};

export const updateCareerAwardMedalsCollection = async (
  careerId: CareerId,
  addMedals: MedalsT,
  removeMedals?: MedalsT
): Promise<void> => {
  const careerMedalRef = doc(db, `careers/${careerId}/awards/medals`);

  if (removeMedals !== undefined) {
    await updateDoc(careerMedalRef, {
      awarded: arrayRemove(...removeMedals),
    });
  }

  return await updateDoc(careerMedalRef, {
    awarded: arrayUnion(...addMedals),
  });
};

export const getCareerAwardsCollection = async (careerId: CareerId): Promise<AwardStars> => {
  const docRef = doc(db, `careers/${careerId}/awards/stars`).withConverter(awardsConverter);
  const docSnap = await getDoc(docRef);

  if (docSnap && docSnap.exists()) {
    const awardStars: AwardStars = docSnap.data();
    return awardStars;
  } else {
    isPrint(PRINT_FLAG) && console.log(...printMsg('GetCareerAwardsCollection', 'No document!', 'Create Empty Array'));
    await createCareerAwardsCollection(careerId);
    const toCreateCareerAwards: AwardStars = { codes: [] };
    return toCreateCareerAwards;
  }
};

export const getCareerMedalCollection = async (careerId: CareerId): Promise<AwardMedals> => {
  const docRef = doc(db, `careers/${careerId}/awards/medals`).withConverter(medalsConverter);
  const docSnap = await getDoc(docRef);

  if (docSnap && docSnap.exists()) {
    const awardMedals: AwardMedals = docSnap.data();
    return awardMedals;
  } else {
    isPrint(PRINT_FLAG) && console.log(...printMsg('getCareerMedalCollection', 'No document!', 'Create Empty Array'));
    await createCareerAwardsMedalCollection(careerId);
    const toCreateCareerAwards: AwardMedals = { awarded: [] };
    return toCreateCareerAwards;
  }
};

export const getCareerAwardedGames = async (careerId: CareerId): Promise<AwardedGamesCollection> => {
  const docRef = doc(db, `careers/${careerId}/awards/games`).withConverter(awardedGamesConverter);
  const docSnap = await getDoc(docRef);

  if (docSnap && docSnap.exists()) {
    const games: AwardedGamesCollection = docSnap.data();
    return games;
  } else {
    isPrint(PRINT_FLAG) && console.log(...printMsg('getCareerAwardedGames', 'No document!', 'Creating blank array'));
    await createCareerAwardedGamesCollection(careerId);
    return { awardedGames: [] };
  }
};

export const createCareer = async (career: CareerT): Promise<CareerT> => {
  const collectionData = collection(db, 'careers').withConverter(careerConverter);
  const docRef = doc(collectionData);
  const toCreateCareer: CareerT = { ...career, careerId: docRef.id };
  // Create new record with autogenerated id as profileId
  await setDoc(docRef, toCreateCareer);
  await createCareerAwardsCollection(docRef.id);

  return toCreateCareer;
};

export const createCareerAwardsCollection = async (careerId: CareerId): Promise<void> => {
  const awardsEmptyData = collection(db, `careers/${careerId}/awards`);
  const awardsDocRef = doc(awardsEmptyData, 'stars');
  const toCreateCareerAwards: AwardStars = { codes: [] };
  await setDoc(awardsDocRef, toCreateCareerAwards);
};

export const createCareerAwardsMedalCollection = async (careerId: CareerId): Promise<void> => {
  const awardsEmptyData = collection(db, `careers/${careerId}/awards`);
  const awardsDocRef = doc(awardsEmptyData, 'medals');
  const toCreateCareerAwards: AwardMedals = { awarded: [] };
  await setDoc(awardsDocRef, toCreateCareerAwards);
};

export const updateCareerGamesCollection = async (careerId: CareerId, awardedGame: AwardedGameT): Promise<void> => {
  const careerRef = doc(db, `careers/${careerId}/awards/games`);
  return await updateDoc(careerRef, { awardedGames: arrayUnion(awardedGame) });
};

export const createCareerAwardedGamesCollection = async (careerId: CareerId): Promise<void> => {
  const awardsEmptyData = collection(db, `careers/${careerId}/awards`);
  const awardsDocRef = doc(awardsEmptyData, 'games');
  const toCreateCareerAwardedGames: AwardedGamesCollection = { awardedGames: [] };
  await setDoc(awardsDocRef, toCreateCareerAwardedGames);
};

export const fetchCareers = async (): Promise<CareersT> => {
  const collectionData = collection(db, 'careers').withConverter(careerConverter);
  const q = query(collectionData, orderBy('userId', 'asc'));
  const querySnapshot = await getDocs(q);
  const careers: CareersT = [];
  querySnapshot.docs.forEach((doc) => {
    const career: CareerT = doc.data();
    const userName: string = getCareerUser(career);
    careers.push({ ...career, careerId: doc.id, userName });
  });
  return careers;
};

export const careerStatusUpdated = async (): Promise<StatusT> => {
  const careerStatus: StatusT = getCareerStatus(store.getState());
  const careerStatusUpdatedPromise: StatusT = await new Promise((resolve, reject) => {
    if (careerStatus === Status.Idle) {
      let count = 50;
      const intervalId = setInterval(
        (count) => {
          const status = store.getState().career.status;
          if (status === Status.Loaded) {
            clearInterval(intervalId);
            resolve(status);
          } else if (status === Status.Error) {
            clearInterval(intervalId);
            reject(status);
          } else if (count === 0 && status === Status.Idle) {
            clearInterval(intervalId);
            reject(status);
          }
        },
        50,
        count--
      );
    } else {
      resolve(careerStatus);
    }
  });

  return careerStatusUpdatedPromise;
};
// #endregion

// #region       CAREER LOADER & ACTION FUNCTIONS ****************************************
export const appCareerSceneLoader = async (): Promise<void> => {
  const careerId = getActiveCareerId(store.getState());
  const awardStars: AwardStars = await getCareerAwardsCollection(careerId);
  store.dispatch(setCareerAwardCodes(awardStars));

  const awardGames: AwardedGamesCollection = await getCareerAwardedGames(careerId);
  store.dispatch(setCareerAwardedGames(awardGames));
  const stars = String(awardStars.codes.length);
  const games = String(awardGames.awardedGames.length);
  isPrint(PRINT_FLAG) && console.log(...printMsg('AppCareerSceneLoader', 'stars:games', `${stars}:${games}`));
};

export const montessoriHubSceneLoader = async (): Promise<CareerHubLoaderResponseT | Response> => {
  const userStatus = await userStatusUpdated();
  store.dispatch(doSetActiveScene('montessori'));
  if (userStatus === 'signedIn') {
    const activeProfileId = store.getState().user.activeProfileId;
    if (activeProfileId) {
      store.dispatch(setCareerStatus(Status.Loading));
      const profile: IProfile = await fetchUserProfile(activeProfileId);
      const careerId = profile.careerId;
      const loadedCareer: CareerT = await getCareer(careerId);
      const awardCodeObj: AwardStars = await getCareerAwardsCollection(careerId);
      const awardMedals: AwardMedals = await getCareerMedalCollection(careerId);
      const awardedGames: AwardedGamesCollection = await getCareerAwardedGames(careerId);
      const careerChainCards: CareerChainCards = createCareerChain(loadedCareer);
      const careerChainCardsActiveCardIndex = findStartingActiveCardIndex(careerChainCards);
      const career: CareerT = {
        ...loadedCareer,
        awardCodes: awardCodeObj.codes,
        awardMedals: awardMedals.awarded,
        awardGames: awardedGames.awardedGames,
      };

      store.dispatch(setCareerT(career));
      store.dispatch(setCareerChain(careerChainCards));
      store.dispatch(setCareerChainActiveIndex(careerChainCardsActiveCardIndex));
      const careerBadges = retrieveCareerBadges(careerChainCards);
      store.dispatch(setCareerStatus(Status.Loaded));

      return { career, profile, careerBadges };
    } else {
      return redirect(AppRoutes.Hub);
    }
  } else {
    return { courses: [], careerBadges: [] };
  }
};

export const montessoriHubSceneAction = async ({ request }: any): Promise<CareerHubActionResponseT> => {
  persistor.pause();
  const actionResponse = await persistor.purge().then(async () => {
    const formData = await request.formData();
    const profileId: ProfileId = formData.get('profileId');

    store.dispatch(doSetActiveProfileId(profileId));
    await setUserProfileAsActive(profileId);
    persistor.persist();
    return { shouldRevalidate: true };
  });

  return actionResponse;
};

export const careerSceneLoaderInner = async (
  careerId: CareerId,
  userStatus: UserStatusT
): Promise<CareerLoaderResponseT> => {
  if (userStatus === 'signedIn') {
    store.dispatch(doSetActiveScene('career'));
    store.dispatch(setCareerStatus(Status.Loading));

    const awardCodeObj: AwardStars = await getCareerAwardsCollection(careerId);
    const awardMedals: AwardMedals = await getCareerMedalCollection(careerId);
    const awardedGames: AwardedGamesCollection = await getCareerAwardedGames(careerId);
    const career: CareerT = await getCareer(careerId);
    career.courses.sort(sortCourses);

    const careerChainCards: CareerChainCards = createCareerChain(career);
    const careerChainCardsActiveCardIndex = findStartingActiveCardIndex(careerChainCards);
    store.dispatch(
      setCareerT({
        ...career,
        awardCodes: awardCodeObj.codes,
        awardMedals: awardMedals.awarded,
        awardGames: awardedGames.awardedGames,
      })
    );
    store.dispatch(setCareerChain(careerChainCards));
    store.dispatch(setCareerChainActiveIndex(careerChainCardsActiveCardIndex));

    // const complete = career.complete;
    const profile: IProfile = await fetchUserProfile(career.profileId);
    store.dispatch(doSetActiveProfileId(career.profileId));
    store.dispatch(setCourseIds(profile.courseIds));
    const careerBadges = retrieveCareerBadges(careerChainCards);
    store.dispatch(setCareerBadges(careerBadges));

    store.dispatch(setCareerStatus(Status.Loaded));

    // console.log(...printMsg('LOADED CareerScene', 'careerId', careerId));
    return { career, profile };
  } else {
    isPrint(PRINT_FLAG) && console.log(...printMsg('Redirecting', 'userStatus', userStatus));
    throw redirect(AppRoutes.Hub);
  }
};

export const careerSceneLoader = async ({ params }: any): Promise<CareerLoaderResponseT> => {
  const careerId = params.careerId;
  const courseId = params.courseId;

  const isCareerIdMatch = getCurrentCareerId(store.getState()) === careerId;
  const isLoaded = isCareerStatusLoaded(store.getState());
  store.dispatch(doSetActiveScene('career'));

  if (isCareerIdMatch && isLoaded) {
    isPrint(PRINT_FLAG) && console.log(...printMsg('ALREADY LOADED', careerId, ''));
    const profile = getActiveProfile(store.getState()) as IProfile;
    const career = store.getState().career;
    return { career, profile };
  }

  if (courseId) {
    store.dispatch(resetCareer());
  }

  const userStatus = await userStatusUpdated();
  return await careerSceneLoaderInner(careerId, userStatus);
};

export const careerBadgesLoader = async ({ params }: any): Promise<Array<CareerBadgeItemT> | Response> => {
  let loadState = 'from REDUX';

  validateActiveProfileSelected();

  const careerStatus: StatusT = getCareerStatus(store.getState());
  if (careerStatus !== Status.Loaded && careerStatus !== Status.Loading) {
    const careerId = params.careerId;
    const userStatus = await userStatusUpdated();

    await careerSceneLoaderInner(careerId, userStatus);
    loadState = 'from FIREBASE';
  }
  store.dispatch(doSetActiveScene('career:badges:gallery'));
  const careerChainCards: CareerChainCards = getCareerChain(store.getState());
  const careerBadges: Array<CareerBadgeItemT> = retrieveCareerBadges(careerChainCards);
  const badgeCount = String(careerBadges.length);
  isPrint(PRINT_FLAG) && console.log(...printMsg(`LOADED ${loadState} CareerBadges`, 'count', badgeCount));

  return careerBadges;
};

/**
 * When an item is completed (question is answered, tutorial reaches the end),
 * Item dispatches submit() call containing Item's {SubmitActionData} model as {FormData}
 *
 * @param {any} request  contains FormData
 *
 * @return {Promise<CourseUI | Response>}
 */
export const careerSceneAction = async ({ request }: any): Promise<CourseUI | Response> => {
  const formData = await request.formData();
  const submitData: SubmitActionData = convertFormDataToSubmitActionData(formData);
  switch (submitData.type) {
    case 'tutorial':
      return await resolveTutorial(submitData);
    case 'question':
      return await resolveQuestion(submitData);
    case 'quiz':
      return await resolveQuiz(submitData);
    case 'lesson':
      return await resolveLesson(submitData);
    case 'course':
      return await resolveCourse(submitData);
    case 'career':
    default:
      // TODO: Should w complete career resolve?
      isPrint(PRINT_FLAG) && console.log(...printMsg('CareerSceneAction Reached DEFAULT', 'type', submitData.type));
  }

  const courseUIs = store.getState().career.courseIds as CourseIds;
  const courseId =
    courseUIs.find(() => !store.getState().career.complete.includes(submitData.courseId as CourseId)) || '';
  return getByIdCareerCourseUI(store.getState(), courseId);
};

export const careerSceneActionSimple = async ({ request }: any): Promise<CourseUIs | Response | null | string> => {
  const formData = await request.formData();
  const submitData: SubmitActionData = convertFormDataToSubmitActionData(formData);
  switch (submitData.type) {
    case 'tutorial':
      return await simpleResolveTutorial(submitData);
    case 'question':
      return await simpleResolveQuestion(submitData);
    case 'quiz':
      return await simpleResolveQuiz(submitData);
    case 'lesson':
      return await simpleResolveLesson(submitData);
    case 'course':
      return await simpleResolveCourse(submitData);
    case 'career':
    default:
      // TODO: Should w complete career resolve?
      isPrint(PRINT_FLAG) &&
        console.log(...printMsg('CareerSceneActionSimple Reached DEFAULT', 'type', submitData.type));
  }
  const courseUIs = store.getState().career.courseUIs;
  isPrint(PRINT_FLAG) && console.log(...printMsg('Updated courseUIs', 'count', String(courseUIs?.length)));
  return courseUIs as CourseUIs;
};
// #endregion

// #region       EDITOR LOADER & ACTION FUNCTIONS ****************************************
const manageLoadCareerAndExtendedProfiles = async (): Promise<ManagerCareerProfilesT> => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('LOAD ManageCareerScene', 'Careers', 'ALL'));
  store.dispatch(setManageCareersStatus(Status.Loading));
  store.dispatch(setProfileStatus(Status.Loading));
  const profiles: ProfilesT = await fetchUserProfiles([]);
  const users: IUsers = await fetchUsers();
  const localCareers = await fetchCareers();
  const extendedProfiles = getExtendedUserProfiles(users, profiles);
  store.dispatch(setProfiles({ collection: extendedProfiles, status: Status.Loaded }));
  store.dispatch(setCareers({ collection: localCareers, status: Status.Loaded }));
  isPrint(PRINT_FLAG) && console.log(...printMsg('LOADED ManageCareerScene', 'Careers', 'ALL'));
  return { careers: localCareers, profiles: extendedProfiles };
};

export const manageCareersSceneLoader = async (): Promise<ManagerCareerProfilesT> => {
  const userStatus = await userStatusUpdated();
  if (userStatus === 'signedIn' && store.getState().user.isAdmin) {
    store.dispatch(doSetActiveScene('manage:career:collection'));
    store.dispatch(setLastActiveManageScene('career'));

    return await manageLoadCareerAndExtendedProfiles();
  }
  throw redirect(AppRoutes.Hub);
};

export const manageEditCareerSceneLoader = async ({ params }: any): Promise<ManageCareerData> => {
  const careerId = params.careerId;
  isPrint(PRINT_FLAG) && console.log(...printMsg('LOAD ManageEditCareerScene', 'careerId', careerId));
  store.dispatch(setManageCareersStatus(Status.Loading));
  store.dispatch(doSetActiveScene('manage:career:edit'));
  const courses = await getCourses();
  courses.sort(sortCourses);
  store.dispatch(setAllAvailableCourses({ collection: courses, status: Status.Loaded }));

  const manager = store.getState().manager;
  if (manager.careersStatus !== Status.Loaded || manager.profilesStatus !== Status.Loaded) {
    await manageLoadCareerAndExtendedProfiles();
  }

  const managerStore = store.getState().manager;
  const awardedMedals: AwardMedals = await getCareerMedalCollection(careerId);
  const careerIndex = managerStore.careers.findIndex((c: CareerT) => c.careerId === careerId);
  const career: CareerT = { ...managerStore.careers[careerIndex], awardMedals: awardedMedals.awarded };
  const profileIndex = managerStore.profiles.findIndex((p) => p.profileId === career.profileId);
  const profile: IExpandedProfile = managerStore.profiles[profileIndex];

  store.dispatch(setManageCareersStatus(Status.Loaded));
  isPrint(PRINT_FLAG) && console.log(...printMsg('LOADED ManageEditCareerScene', 'careerId', careerId));
  return { career, courses, profile, index: careerIndex, profileIndex };
};

export const manageEditCareerSceneAction = async ({ params }: any) => {
  const careerId = params.careerId;
  const managerCareers = store.getState().manager.careers;
  const profileIndex = store.getState().manager.profiles.findIndex((p) => p.careerId === careerId);
  const profile: IExpandedProfile = store.getState().manager.profiles[profileIndex];
  const indexOfEditedItem = managerCareers.findIndex((ct) => ct.careerId === careerId);
  const careerT = managerCareers[indexOfEditedItem] as CareerT;
  const uncomplete = getCareerUncompleteItems(store.getState());
  const medals = careerT.awardMedals;

  store.dispatch(setManageCareersStatus('updating'));

  const modifiedData: ModifyT = getModifiedInfo();
  const toUpdateCareer: CareerT = {
    ...careerT,
    careerId: careerT.careerId,
    ...modifiedData,
  };

  const toUpdateExpandedProfile: IExpandedProfile = {
    ...profile,
    uid: profile.createdBy,
    careerId: careerT.careerId,
    ...modifiedData,
  };

  const toUpdateProfile: IProfile = { ...(toUpdateExpandedProfile as IProfile) };

  await updateCareerAwardMedalsCollection(careerId, medals);

  if (uncomplete.careerId && uncomplete.itemsToUncomplete) {
    await updateCareerCompleteItems(uncomplete);
    store.dispatch(setCareerUncompleteItems({ careerId: careerT.careerId, itemsToUncomplete: [] }));
  }
  await updateCareer(toUpdateCareer);
  await patchUserProfile(toUpdateProfile);

  store.dispatch(updateProfile({ userProfile: toUpdateExpandedProfile, index: profileIndex }));
  store.dispatch(setManageCareersStatus('updated'));
  store.dispatch(setToastNotification(getToastSuccessPayload('Career Updated')));
  return redirect(AppRoutes.ManagerCareer);
};
// #endregion
