import { SceneT } from 'scenes/scenesSlice';
import { CareerItemT, ArithmeticT } from 'types/App.types';
import { VersionId } from 'types/BaseCareer.types';
import { CareerId } from 'types/Career.types';
import { CourseId } from 'types/Course.types';
import { LessonId } from 'types/Lesson.types';
import { QuizId } from 'types/Quiz.types';
import { TutorialId } from 'types/Tutorial.types';
import { QuestionId, QuestionT } from 'types/Question.types';
import { GameId, GameLevelT, GameQuestionId } from 'types/Game.types';
import { AppIcons } from 'app/constants/Constants';

export const RouteName = {
  HOME: 'home',
  COUNT: 'count',
  QUIZ: 'quiz',
  COUNT_TUTORIAL: 'count:tutorial',
  MULTIPLY: 'multiply',
  MULTIPLY_TUTORIAL: 'multiply:tutorial',
  SUBTRACTION: 'subtraction',
  SUBTRACTION_TUTORIAL: 'subtraction:tutorial',
  USER_ACCOUNT: 'career:account',
  ACCOUNT: 'account',
};

export const asSceneT = (route: string) => route as SceneT;

export const getCareerToUrl = (careerId: CareerId): string => `/career/${careerId}`;

export const getCourseToUrl = (careerId: CareerId, courseId: CourseId): string =>
  `${getCareerToUrl(careerId)}/course/${courseId}`;

export const getLessonToUrl = (careerId: CareerId, courseId: CourseId, lessonId: LessonId): string =>
  `${getCourseToUrl(careerId, courseId)}/lesson/${lessonId}`;

export const getTutorialToUrl = (tutorialId: TutorialId, versionId: VersionId): string =>
  `/career/tutorial/${tutorialId}/${versionId}`;

export const getQuizToUrl = (careerId: CareerId, courseId: CourseId, lessonId: LessonId, quizId: QuizId): string =>
  `${getLessonToUrl(careerId, courseId, lessonId)}/quiz/${quizId}`;

export const getQuestionToUrlByCategory = (questionId: QuestionId, category: ArithmeticT): string =>
  `/question/${category}/${questionId}`;

export const getQuestionToUrl = (questionT: QuestionT): string =>
  getQuestionToUrlByCategory(questionT.questionId, questionT.categoryType as ArithmeticT);

export const getVersionedQuestionToUrl = (questionId: QuestionId, versionId: VersionId): string =>
  `/question/${questionId}/${versionId}`;

export const getCourseCompletionId = (courseId: CourseId): string => `career:course=${courseId}`;

export const getLessonCompletionId = (courseId: CourseId, lessonId: LessonId): string =>
  `career:lesson=${courseId}:${lessonId}`;

export const getTutorialCompletionId = (courseId: CourseId, lessonId: LessonId, tutorialId: TutorialId): string =>
  `career:tutorial=${courseId}:${lessonId}:${tutorialId}`;

export const getQuizCompletionId = (courseId: CourseId, lessonId: LessonId, quizId: QuizId): string =>
  `career:quiz=${courseId}:${lessonId}:${quizId}`;

export const getQuestionCompletionId = (
  courseId: CourseId,
  lessonId: LessonId,
  quizId: QuizId,
  questionId: QuestionId
): string => `career:question=${courseId}:${lessonId}:${quizId}:${questionId}`;

export const getBoardTutorialCompletionId = (boardTutorialType: string) => `career:boardtutorial=${boardTutorialType}`;

export const getCareerItemType = (completedId: string): CareerItemT => {
  if (completedId.indexOf('career:question') > -1) {
    return 'question';
  } else if (completedId.indexOf('career:quiz') > -1) {
    return 'quiz';
  } else if (completedId.indexOf('career:tutorial') > -1) {
    return 'tutorial';
  } else if (completedId.indexOf('career:lesson') > -1) {
    return 'lesson';
  } else if (completedId.indexOf('career:course') > -1) {
    return 'course';
  } else if (completedId.indexOf('career:game') > -1) {
    return 'game';
  } else {
    return 'career';
  }
};
export const GAME_COMPLETION_PREFIX = 'career:game=';
export const getGameCompletionId = (gameId: GameId, level: GameLevelT, questionId: GameQuestionId = '0'): string =>
  `${GAME_COMPLETION_PREFIX}${gameId}:${questionId}:${level}`;

export const getIconByCareerItemType = (itemT: CareerItemT | 'game'): string => {
  switch (itemT) {
    case 'career':
      return AppIcons.Career.Career;
    case 'course':
      return AppIcons.Career.Course;
    case 'lesson':
      return AppIcons.Career.Lesson;
    case 'tutorial':
      return AppIcons.Career.Tutorial;
    case 'quiz':
      return AppIcons.Career.Quiz;
    case 'question':
      return AppIcons.Career.Questions;
    case 'game':
      return AppIcons.Career.Game;
    default:
      return AppIcons.Career.Error;
  }
};
