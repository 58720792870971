import { ElementSizeT, PropsWithChildren } from 'types/App.types';
import InsetText from 'components/text/inset/InsetText';
import React, { useEffect, useState } from 'react';

import styles from './SimpleButton.module.scss';
import MeIcon from 'components/icon/meicon/MeIcon';

type SimpleButtonProps = {
  className?: string;
  iconClassName?: string;
  icon?: string;
  selected?: boolean;
  textSize?: ElementSizeT;
  iconSize?: ElementSizeT;
} & React.HTMLAttributes<HTMLDivElement>;

const SimpleButton: React.FC<PropsWithChildren<SimpleButtonProps>> = ({
  textSize = 'med',
  className = '',
  iconClassName = '',
  selected = false,
  iconSize = 'mlg',
  ...props
}) => {
  const [selectedCls, setSelectedCls] = useState<string>('');

  useEffect(() => {
    setSelectedCls(selected ? 'Selected' : '');
  }, [selected]);

  return (
    <div {...props} style={{}} className={`${styles.SimpleButton} ${selectedCls} ${className}`}>
      {props.icon && (
        <MeIcon
          insetIcon
          icon={props.icon}
          size={iconSize}
          weight="bold600"
          style={{ aspectRatio: '1', lineHeight: 1 }}
          className={iconClassName}
        />
      )}
      <InsetText size={textSize}>{props.children}</InsetText>
    </div>
  );
};

export default SimpleButton;
