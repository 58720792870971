import { store } from 'app/store';
import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { getModifiedInfo } from 'helpers/careerHelpers';
import { encodeUserPassKey } from 'modules/user/secret/userSecret';
import { IProfile } from 'types/User.types';

// #region CONVERTERS ********************************************************************
export const getInitialUserProfile = (uid?: string): IProfile => {
  const userId = uid || store.getState().user.uid || '';
  const initUserProfile: IProfile = {
    uid: userId,
    profileId: 'createUserProfileId',
    title: 'Student\'s name',
    icon: 'sentiment_satisfied',
    userPassKey: '',
    userPassKeyEnabled: false,
    courseIds: [],
    careerId: '',
    createdBy: userId,
    createdOn: Date.now(),
    modifiedBy: userId,
    modifiedOn: Date.now(),
  };

  return initUserProfile;
};

export const profileToDoc = (profile: IProfile): DocumentData => {
  return {
    uid: profile.uid,
    profileId: profile.profileId,
    careerId: profile.careerId,
    title: profile.title,
    icon: profile.icon,
    userPassKey: profile.userPassKey ?? encodeUserPassKey('1111', profile.profileId),
    userPassKeyEnabled: !!profile.userPassKeyEnabled,
    courseIds: profile.courseIds,
    createdBy: profile.createdBy,
    createdOn: profile.createdOn,
    modifiedBy: profile.modifiedBy,
    modifiedOn: profile.modifiedOn,
  };
};

export const profileConverter: FirestoreDataConverter<IProfile> = {
  toFirestore(user: IProfile): DocumentData {
    return { ...profileToDoc(user), ...getModifiedInfo() };
  },

  fromFirestore(docSnap: QueryDocumentSnapshot, options?: SnapshotOptions): IProfile {
    const user = docSnap.data(options);
    return {
      uid: user.uid,
      profileId: user.profileId,
      title: user.title,
      icon: user.icon,
      userPassKey: user.userPassKey ?? encodeUserPassKey('1111', user.profileId),
      userPassKeyEnabled: !!user.userPassKeyEnabled,
      careerId: user.careerId,
      courseIds: user.courseIds,
      createdBy: user.createdBy,
      createdOn: user.createdOn,
      modifiedBy: user.modifiedBy,
      modifiedOn: user.modifiedOn,
    };
  },
};
// #endregion
