import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { memoize } from 'proxy-memoize';
import { AppThunk, RootState } from 'app/store';
import { analytics, logEvent } from '../../firebase/Firebase';

import { CreateModifyT, MaybeType, StatusT } from 'types/App.types';
import {
  AwardedGamesT,
  AwardedGameT,
  GameId,
  GameQuestionId,
  GameQuestionT,
  GamesT,
  GameT,
  SetGamesResponseT,
  UpdateGameResponseT,
} from 'types/Game.types';

import { getFormattedString, getNameClsString } from 'app/config/LogConfig';
import { AchievementType, AppEvent, getUnlockedAchievement as getAchievement } from 'app/constants/AnalyticEvents';

import { isPrint } from 'helpers/appHelpers';
import { getCreateModifiedInfo } from 'helpers/careerHelpers';

import { updateCareerGamesCollection } from 'api/CareerAPI';

import { addAwardedGame, getAwardedGames } from 'modules/career/careerSlice';
import { getActiveCareerId } from 'modules/user/userSlice';
import { setCompleteNotification } from 'scenes/montessori/appUiSlice';

export interface GamesState {
  collection: GamesT;
  status: StatusT;
}

const initialState: GamesState = {
  collection: [],
  status: 'idle',
};

const PRIMARY_COLOR = getNameClsString('olivedrab', 'cornsilk');
export const CLS_NAME = 'GamesSlice';
const PRINT_FLAG = true;

const printMsg = (action: string, prop: string, status: string) =>
  getFormattedString(PRIMARY_COLOR, CLS_NAME, action, prop, status);

export const receiveGame =
  (gameId: GameId): AppThunk =>
  async (dispatch, getState) => {
    if (!getState().career) {
      isPrint(PRINT_FLAG) && console.log(...printMsg('receiveGame', 'Cannot apply award, Career NOT LOADED', gameId));
      return;
    }
    const awardedGames: AwardedGamesT = getAwardedGames(getState());
    const awardedGameIds: Array<GameId> = awardedGames.map((g) => g.gameId);
    const gameIds: Array<GameId> = getGameIds(getState());

    const isValidGame = gameIds.includes(gameId);
    const isUserReceivedGame = awardedGameIds.includes(gameId);

    if (isValidGame && !isUserReceivedGame) {
      const game: GameT = getCollectionGameById(getState(), gameId) as GameT;
      isPrint(PRINT_FLAG) && console.log(...printMsg('User get', 'game', game.gameTitle));
      const careerId = getActiveCareerId(getState());
      const createModify: CreateModifyT = getCreateModifiedInfo();
      const awardedGame: AwardedGameT = { gameId: gameId, gameTitle: game.gameTitle, ...createModify };
      dispatch(addAwardedGame(awardedGame));
      logEvent(analytics, AppEvent.UnlockAchievement, getAchievement(AchievementType.Game, gameId, game.gameTitle));
      await updateCareerGamesCollection(careerId, awardedGame);
      dispatch(
        setCompleteNotification({
          showNotification: true,
          showActionButton: true,
          actionButtonLabel: 'COLLECT',
          headerLabel: 'GAME UNLOCKED',
          notificationIcon: 'joystick',
        })
      );
    } else {
      isPrint(PRINT_FLAG) && console.log(...printMsg(`Game ${gameId}`, 'Already owns?', String(isUserReceivedGame)));
    }
  };

// #region REDUCERS *********************************************************************
export const gamesSlice = createSlice({
  name: 'games',
  initialState,
  reducers: {
    setGamesCollection: (state, action: PayloadAction<SetGamesResponseT>) => {
      state.collection = action.payload.collection;
      state.status = action.payload.status;
    },
    setGamesCollectionStatus: (state, action: PayloadAction<StatusT>) => {
      state.status = action.payload;
    },
    addCollectionGame: (state, action: PayloadAction<GameT>) => {
      state.collection = state.collection.concat(action.payload);
    },
    updateCollectionGame: (state, action: PayloadAction<UpdateGameResponseT>) => {
      const tempGames = state.collection.slice();
      tempGames.splice(action.payload.index, 1, action.payload.game);
      state.collection = tempGames;
    },
    resetGames: () => {
      return { ...initialState };
    },
  },
});
// #endregion

// #region ACTIONS **********************************************************************
export const { setGamesCollection, setGamesCollectionStatus, addCollectionGame, updateCollectionGame, resetGames } =
  gamesSlice.actions;
// #endregion

// #region SELECTORS ********************************************************************
export const getGamesCollection = (state: RootState): GamesT => state.games.collection;
export const getGamesCollectionStatus = (state: RootState): StatusT => state.games.status;
export const getCollectionGameById = (state: RootState, gameId: GameId): MaybeType<GameT> =>
  createSelector([getGamesCollection], (games: GamesT) => {
    const gameIndex = games.findIndex((g: GameT) => g.gameId === gameId);
    const game = games[gameIndex];
    return game;
  })(state);
export const getCollectionGameQuestion = (
  state: RootState,
  gameId: GameId,
  gameQuestionId: GameQuestionId
): MaybeType<GameQuestionT> =>
  createSelector([getCollectionGameById], (game: MaybeType<GameT>) => {
    if (!game) return undefined;
    const gameQuestionIndex = game.gameQuestions.findIndex((g: GameQuestionT) => g.gameQuestionId === gameQuestionId);
    const gameQuestion = game.gameQuestions[gameQuestionIndex] as GameQuestionT;
    return gameQuestion;
  })(state, gameId);
export const getGameIds = memoize((state: RootState): Array<GameId> => state.games.collection.map((g) => g.gameId));
// #endregion

export default gamesSlice.reducer;
