import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store, persistor } from './app/store';
import { PersistGate } from 'redux-persist/integration/react';
import { RouterProvider } from 'react-router-dom';
import router from './app/Router';

import './styles.scss';
import { AuthProvider } from './app/hooks/useAuth';

const container: HTMLElement | null = document.getElementById('root');

if (container === null) {
  throw Error('root not found');
}

const root = createRoot(container);

document.documentElement.style.setProperty('--game-container-height', '25em');

root.render(
  <StrictMode>
    <Provider
      store={store}
      children={
        <PersistGate loading={null} persistor={persistor}>
          <AuthProvider children={<RouterProvider router={router} />}></AuthProvider>
        </PersistGate>
      }
    />
  </StrictMode>
);
