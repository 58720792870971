import { To } from 'react-router';

import { Arithmetic, ArithmeticT, SubmitActionData, VisualStateT, VisualState } from 'types/App.types';
import {
  BadgeT,
  BadgesT,
  BaseHistoryReportT,
  CareerHistoryReportT,
  CareerId,
  HistoryId,
  ReportT,
} from 'types/Career.types';
import { CoursesT, CourseUIs, CourseT, CourseUI, CourseId } from 'types/Course.types';
import { QuizT, QuizId } from 'types/Quiz.types';
import { QuestionUI, BaseQuestionUIs, BaseQuestionT, BaseQuestionUI, QuestionUIs } from 'types/Question.types';
import { LessonId, LessonT, LessonUI, LessonUIs } from 'types/Lesson.types';
import { BaseTutorialUI, TutorialId, TutorialT, TutorialUI } from 'types/Tutorial.types';

import { getFormattedString, getNameClsString } from 'app/config/LogConfig';
import {
  getCourseToUrl,
  getCourseCompletionId,
  getLessonCompletionId,
  getQuestionCompletionId,
  getQuizCompletionId,
  getQuizToUrl,
  getTutorialCompletionId,
  getTutorialToUrl,
  getCareerToUrl,
  getVersionedQuestionToUrl,
} from './routeHelpers';
import { ProfileId } from 'types/User.types';
import { CareerChainCard, VersionedItemT } from 'types/BaseCareer.types';
import { MathMode, MathModeT } from 'types/Board.types';
import { sortCourseUIs } from 'helpers/sortHelpers';
import { GameLevel, GameLevelT } from 'types/Game.types';

const LOG_COLORS = getNameClsString('darkcyan', 'cornsilk');
const CLASS_NAME = 'TypeCastingHelpers';

/**
 * Utility to display pretty text in the console
 *
 * @param actionDescription text describing what "action" is taking place (loading, updating, converting... etc)
 * @param propName name of the property that you want to print to the console
 * @param propValue value of the {prop}
 * @returns styled and formatted {string} `%{CLASS_NAME} ${Action Text} ${prop}:${propValue}`
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const printMsg = (actionDescription: string, propName: string, propValue: string) =>
  getFormattedString(LOG_COLORS, CLASS_NAME, actionDescription, propName, propValue);

// #region CASTING TO UI
export const castCareerChainCardToBaseQuestionUI = (card: CareerChainCard): BaseQuestionUI => {
  return {
    questionId: card.versionedItemId,
    versionId: card.versionId,
    submitActionData: card.submitActionData,
    visualState: card.visualState,
    level: card.level,
    title: card.versionedItemTitle,
    completionId: card.completionId,
    toUrl: card.toUrl,
    categoryType: card.submitActionData?.value,
  } as BaseQuestionUI;
};

export const castToQuestionUIs = (
  careerId: CareerId,
  courseId: CourseId,
  lessonId: LessonId,
  quizT: QuizT,
  completedItems: Array<string>
): QuestionUIs => {
  return quizT.versionedQuestionIds.map((q: VersionedItemT) => {
    const toUrl: string = getVersionedQuestionToUrl(q.versionedItemId, q.versionId);
    const completionId = getQuestionCompletionId(courseId, lessonId, quizT.quizId, q.versionedItemId);
    const submitActionData: SubmitActionData = {
      actionUrl: getCareerToUrl(careerId),
      simpleActionUrl: `/career/simple/${careerId}`,
      completionId: completionId,
      id: q.versionedItemId,
      name: q.versionedItemTitle,
      type: 'question',
      careerId: careerId,
      courseId: courseId,
      lessonId: lessonId,
      quizId: quizT.quizId,
      questionId: q.versionedItemId,
      value: quizT.quizCategory,
      level: String(quizT.quizLevel),
    };

    let visualState: VisualStateT = 'locked';
    const isCompleted = completedItems.includes(completionId);
    if (isCompleted) {
      visualState = VisualState.Completed;
      // } else if (index === 0) {
      //   visualState = VisualState.Active;
    } else {
      // const prevQuestion = array[index - 1] as QuestionT;
      // const prevCompletionId = getQuestionCompletionId(courseId, lessonId, quizT.quizId, prevQuestion.id);
      // const isPrevCompleted = completedItems.includes(prevCompletionId);
      // visualState = isPrevCompleted ? VisualState.Active : 'locked';
      visualState = 'locked';
    }
    return { ...q, toUrl, visualState, completionId, submitActionData } as unknown as QuestionUI;
  });
};

export const castToBaseQuestionUIs = (
  careerId: CareerId,
  courseId: CourseId,
  lessonId: LessonId,
  quizT: QuizT,
  completedItems: Array<string>
): BaseQuestionUIs => {
  return quizT.versionedQuestionIds.map((q: VersionedItemT) => {
    const toUrl: string = getVersionedQuestionToUrl(q.versionedItemId, q.versionId);
    const completionId = getQuestionCompletionId(courseId, lessonId, quizT.quizId, q.versionedItemId);
    const submitActionData: SubmitActionData = {
      actionUrl: getCareerToUrl(careerId),
      simpleActionUrl: `/career/simple/${careerId}`,
      completionId: completionId,
      id: q.versionedItemId,
      name: q.versionedItemTitle,
      type: 'question',
      careerId: careerId,
      courseId: courseId,
      lessonId: lessonId,
      quizId: quizT.quizId,
      questionId: q.versionedItemId,
      value: quizT.quizCategory,
      level: String(quizT.quizLevel),
    };

    const baseQuestionT: BaseQuestionT = {
      questionId: q.versionedItemId,
      title: q.versionedItemTitle,
      level: quizT.quizLevel,
      versionId: q.versionId,
      categoryType: quizT.quizCategory,
    };

    let visualState: VisualStateT = 'locked';
    const isCompleted = completedItems.includes(completionId);
    if (isCompleted) {
      visualState = VisualState.Completed;
      // } else if (index === 0) {
      //   visualState = VisualState.Active;
    } else {
      // const prevQuestion = array[index - 1] as QuestionT;
      // const prevCompletionId = getQuestionCompletionId(courseId, lessonId, quizT.quizId, prevQuestion.id);
      // const isPrevCompleted = completedItems.includes(prevCompletionId);
      // visualState = isPrevCompleted ? VisualState.Active : 'locked';
      visualState = 'locked';
    }
    return { ...baseQuestionT, toUrl, visualState, completionId, submitActionData } as BaseQuestionUI;
  });
};

export const castLessonQuizToQuizUI = (
  careerId: CareerId,
  courseId: CourseId,
  lessonUI: LessonUI,
  completedItems: Array<string>
) => {
  const quizT: QuizT = lessonUI.quiz as QuizT;
  const lessonId: LessonId = lessonUI.lessonId;
  const toUrl: string = getQuizToUrl(careerId, courseId, lessonId, quizT.quizId);
  const completionId = getQuizCompletionId(courseId, lessonId, quizT.quizId);
  const visualState: VisualStateT = completedItems.includes(completionId) ? VisualState.Completed : 'locked';
  const baseQuestionUIs: BaseQuestionUIs = castToBaseQuestionUIs(careerId, courseId, lessonId, quizT, completedItems);
  const submitActionData: SubmitActionData = {
    actionUrl: getCareerToUrl(careerId),
    simpleActionUrl: `/career/simple/${careerId}`,
    completionId: completionId,
    id: quizT.quizId,
    name: quizT.quizTitle,
    type: 'quiz',
    careerId: careerId,
    courseId: courseId,
    lessonId: lessonId,
    quizId: quizT.quizId,
    value: quizT.quizCategory,
    level: String(quizT.quizLevel),
  };
  return { ...quizT, visualState, toUrl, completionId, baseQuestionUIs, submitActionData };
};

export const castToQuizUI = (
  careerId: CareerId,
  courseId: CourseId,
  lessonId: LessonId,
  visualState: VisualStateT,
  completedItems: Array<string>,
  quizT: QuizT
) => {
  const baseQuestionUIs: BaseQuestionUIs = castToBaseQuestionUIs(careerId, courseId, lessonId, quizT, completedItems);
  const toUrl: string = getQuizToUrl(careerId, courseId, lessonId, quizT.quizId);
  const completionId = getQuizCompletionId(courseId, lessonId, quizT.quizId);
  const submitActionData: SubmitActionData = {
    actionUrl: getCareerToUrl(careerId),
    simpleActionUrl: `/career/simple/${careerId}`,
    completionId: completionId,
    id: quizT.quizId,
    name: quizT.quizTitle,
    type: 'quiz',
    careerId: careerId,
    courseId: courseId,
    lessonId: lessonId,
    quizId: quizT.quizId,
    value: quizT.quizCategory,
    level: String(quizT.quizLevel),
  };

  return { ...quizT, visualState, toUrl, completionId, baseQuestionUIs, submitActionData };
};

export const castCareerChainCardToBaseTutorialUI = (card: CareerChainCard, part: string): BaseTutorialUI => {
  return {
    tutorialId: card.versionedItemId,
    versionId: card.versionId,
    submitActionData: card.submitActionData,
    visualState: card.visualState,
    title: card.versionedItemTitle,
    part: part,
    completionId: card.completionId,
    toUrl: card.toUrl,
    category: (card.submitActionData as SubmitActionData).value as ArithmeticT,
  } as BaseTutorialUI;
};

export const castToTutorialUI = (
  careerId: CareerId,
  courseId: CourseId,
  lessonId: LessonId,
  tutorialT: TutorialT,
  visualState: VisualStateT
): TutorialUI => {
  const toUrl: string = getTutorialToUrl(careerId, tutorialT.tutorialId);
  const completionId = getTutorialCompletionId(courseId, lessonId, tutorialT.tutorialId);
  const submitActionData: SubmitActionData = {
    actionUrl: getCareerToUrl(careerId),
    simpleActionUrl: `/career/simple/${careerId}`,
    completionId: completionId,
    id: tutorialT.tutorialId,
    name: tutorialT.title,
    type: 'tutorial',
    careerId: careerId,
    courseId: courseId,
    lessonId: lessonId,
    tutorialId: tutorialT.tutorialId,
    value: tutorialT.category,
  };
  return { ...tutorialT, visualState, toUrl, completionId, submitActionData };
};

export const castToLessonUI = (
  careerId: CareerId,
  courseId: CourseId,
  lessonT: LessonT,
  complete: Array<string>,
  badges: BadgesT,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _prevLessonVS: VisualStateT
) => {
  const lessonId = lessonT.lessonId;
  const tutorialCompletionId = getTutorialCompletionId(
    courseId,
    lessonId,
    lessonT.versionedTutorialId?.versionedItemId as TutorialId
  );
  const isTutorialComplete = complete.includes(tutorialCompletionId);
  const tutorialVS: VisualStateT = isTutorialComplete ? VisualState.Completed : 'locked';
  const tutorialUI = castToTutorialUI(careerId, courseId, lessonId, lessonT.tutorial as TutorialT, tutorialVS);

  const quizCompletionId = getQuizCompletionId(courseId, lessonId, lessonT.versionedQuizId?.versionedItemId as QuizId);
  const isQuizComplete = complete.includes(quizCompletionId);
  const quizVS: VisualStateT = isQuizComplete ? VisualState.Completed : 'locked';

  const quizUI = castToQuizUI(careerId, courseId, lessonId, quizVS, complete, lessonT.quiz as QuizT);

  const tutorialBadge = badges.find((badge: BadgeT) => tutorialUI.badgeId === badge.badgeId);
  const quizBadge = badges.find((badge: BadgeT) => quizUI.badgeId === badge.badgeId);
  const lessonBadge = badges.find((badge: BadgeT) => lessonT.badge === badge.badgeId);

  // const toUrl = getLessonToUrl(careerId, courseId, lessonId);
  const toUrl = quizUI.toUrl;
  const completionId = getLessonCompletionId(courseId, lessonId);

  const itemsCompleted: boolean = tutorialVS === VisualState.Completed && quizVS === VisualState.Completed;
  const isLessonCompleted: boolean = complete.includes(completionId) || itemsCompleted;
  const visualState: VisualStateT = isLessonCompleted ? VisualState.Completed : 'locked';
  // console.log(...printMsg('Loading LessonUI', 'lessonId', lessonId));
  const submitActionData: SubmitActionData = {
    actionUrl: getCareerToUrl(careerId),
    simpleActionUrl: `/career/simple/${careerId}`,
    completionId: completionId,
    id: lessonT.lessonId,
    name: lessonT.title,
    type: 'lesson',
    careerId: careerId,
    courseId: courseId,
    lessonId: lessonT.lessonId,
    value: lessonT.category,
  };

  const lessonUI: LessonUI = {
    ...lessonT,
    tutorialUI,
    quizUI,
    lessonBadge,
    quizBadge,
    tutorialBadge,
    visualState,
    toUrl,
    completionId,
    submitActionData,
  };

  return lessonUI;
};

export const castToCourseUIs = (
  careerId: CareerId,
  complete: Array<string>,
  parentVisualState: VisualStateT,
  badges: BadgesT,
  coursesT: CoursesT
): CourseUIs => {
  const courseUIs: CourseUIs = coursesT.map((c: CourseT, index: number, list: CoursesT) => {
    const toUrl: To = getCourseToUrl(careerId, c.courseId);
    const completionId = getCourseCompletionId(c.courseId);
    let visualState: VisualStateT = parentVisualState;
    if (complete.includes(completionId)) {
      visualState = VisualState.Completed;
    } else if (index > 0 && !complete.includes(getCourseCompletionId(list[index - 1].courseId))) {
      visualState = 'locked';
    }

    let prevLessonVS: VisualStateT;
    const courseLessonUIs: LessonUIs = c.courseLessons?.map((l) => {
      const lessonUI: LessonUI = castToLessonUI(careerId, c.courseId, l, complete, badges, prevLessonVS);
      prevLessonVS = lessonUI.visualState;

      return lessonUI;
    }) as LessonUIs;
    const submitActionData: SubmitActionData = {
      actionUrl: getCareerToUrl(careerId),
      simpleActionUrl: `/career/simple/${careerId}`,
      completionId: completionId,
      id: c.courseId,
      name: c.courseTitle,
      type: 'course',
      careerId: careerId,
      courseId: c.courseId,
      value: String(c.courseLevel),
      level: String(c.courseLevel),
    };
    // console.log(...printMsg('Casted to LessonUIs', 'Lesson Count', `${courseLessonUIs.length}`));
    return { ...c, visualState, toUrl, completionId, courseLessonUIs, submitActionData } as CourseUI;
  });

  courseUIs.sort(sortCourseUIs);

  return courseUIs;
};

// export const convertArithmeticTypeToModeType = (kind: ArithmeticT): ModeT => {
//   if (kind === Arithmetic.Add) return Mode.Addition;
//   if (kind === Arithmetic.Subtract) return Mode.Subtraction;
//   if (kind === Arithmetic.Multiply) return Mode.Multiplication;
//   if (kind === Arithmetic.Divide) return Mode.Division;
//   if (kind === Arithmetic.Count) return Mode.Counting;
//   if (kind === Arithmetic.Compare) return Mode.Comparing;
//   return Mode.Explore;
// };

export const convertArithmeticTypeToMathModeType = (kind: ArithmeticT): MathModeT => {
  if (kind === Arithmetic.Subtract) return MathMode.Subtraction;
  if (kind === Arithmetic.Multiply) return MathMode.Multiplication;
  if (kind === Arithmetic.Divide) return MathMode.Division;
  if (kind === Arithmetic.Count) return MathMode.Counting;
  if (kind === Arithmetic.Compare) return MathMode.Comparing;
  return MathMode.Addition;
};

export const convertGameLevelToLevelName = (gameLevel: GameLevelT): string => {
  switch (gameLevel) {
    case GameLevel.Level1:
      return 'Easy';
    case GameLevel.Level2:
      return 'Medium';
    case GameLevel.Level3:
      return 'Hard';
    case GameLevel.Level4:
      return 'Extra Hard';
    default:
      return gameLevel;
  }
};

// #region Covert FORM DATA
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertFormDataToSubmitActionData = (formData: any): SubmitActionData => ({
  actionUrl: formData.get('actionUrl'),
  simpleActionUrl: formData.get('simpleActionUrl'),
  completionId: formData.get('completionId'),
  id: formData.get('id'),
  name: formData.get('name'),
  type: formData.get('type'),
  careerId: formData.get('careerId'),
  courseId: formData.get('courseId'),
  lessonId: formData.get('lessonId'),
  tutorialId: formData.get('tutorialId'),
  questionId: formData.get('questionId'),
  quizId: formData.get('quizId'),
  level: formData.get('level'),
  chainIndex: formData.get('chainIndex'),
  timeToSolve: formData.get('timeToSolve'),
  value: formData.get('value'),
});

export const convertToBaseHistoryEntry = (
  careerId: CareerId,
  timeToSolve: string,
  name: string,
  reportType: ReportT,
  profileId: ProfileId,
  uid: string,
  historyId: HistoryId = 'historyId'
): BaseHistoryReportT => {
  return {
    historyId: historyId,
    timeToSolve: parseInt(timeToSolve),
    title: String(name),
    reportType: reportType,
    careerId: String(careerId),
    profileId: profileId,
    createdOn: Date.now(),
    createdBy: uid,
  };
};

export const convertToCareerHistoryEntry = (
  submitData: SubmitActionData,
  profileId: ProfileId,
  uid: string,
  historyId: HistoryId = 'historyId'
): CareerHistoryReportT => {
  const { careerId, courseId, lessonId, questionId, quizId, timeToSolve, value, name, versionId, id } = submitData;
  const baseH = convertToBaseHistoryEntry(
    careerId as CareerId,
    String(timeToSolve),
    name,
    'question',
    profileId,
    uid,
    historyId
  );
  return {
    ...baseH,
    questionId: id || String(questionId),
    questionVersion: String(versionId),
    questionCategory: value as ArithmeticT,
    questionTitle: String(name),
    courseId: String(courseId),
    lessonId: String(lessonId),
    quizId: String(quizId),
  };
};

// #endregion
