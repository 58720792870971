import React, { useEffect, useRef, useState } from 'react';
import { BoldWeightT, ElementSizeT, LineHeightT, PropsWithChildren } from 'types/App.types';
import MeIcon from 'components/icon/meicon/MeIcon';
import { v4 as uuidv4 } from 'uuid';

import styles from './MeText.module.scss';

type MeTextOwnProps = {
  id?: string;
  className?: string;
  hrefUrl?: string;
  textSize?: ElementSizeT;
  icon?: string;
  iconSize?: ElementSizeT;
  insetText?: boolean;
  isAnchor?: boolean;
  isDanger?: boolean;
  weight?: BoldWeightT;
  lineHeight?: LineHeightT;
  useDiv?: boolean;
} & Pick<React.HTMLAttributes<HTMLDivElement>, 'onClick' | 'style' | 'datatype'>;

type MeTextProps = PropsWithChildren<MeTextOwnProps>;

const MeText: React.FC<MeTextProps> = ({
  textSize = 'mlg',
  id = undefined,
  iconSize = 'xs11',
  icon = 'anchor',
  insetText = false,
  isAnchor = false,
  weight = 'bold700',
  lineHeight = 'line1',
  useDiv = false,
  ...props
}) => {
  const innerDivRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
  const [localId] = useState<string>(id ?? `id-${uuidv4()}`);
  const insetCls = insetText ? styles.InsetText : '';
  const url = props.hrefUrl ? props.hrefUrl : isAnchor ? `#${localId}` : undefined;
  const hideHoverEffectCls = url ? styles.ShowHover : '';
  const propCls = `${hideHoverEffectCls} ${insetCls} ${props.className ?? ''}`;

  useEffect(() => {
    if (innerDivRef.current) {
      innerDivRef.current.scrollBy({ top: 100 });
    }
  }, [props.children]);

  if (useDiv) {
    return (
      <div
        id={localId}
        className={`${styles.MeTextContainer} ${propCls}`}
        data-item-id={localId}
        onClick={props.onClick}
        style={props.style}>
        <div
          ref={innerDivRef}
          className={`${styles.MeText} ${weight} ${textSize} ${lineHeight} `}
          data-item-id={localId}>
          {props.children}
          {!!icon && isAnchor && <MeIcon className={styles.MeTextIcon} icon={icon} size={iconSize} />}
        </div>
      </div>
    );
  }

  return (
    <div id={localId} className={`${styles.MeTextContainer} ${propCls}`} style={props.style}>
      <a
        href={url}
        className={`${styles.MeText} ${weight} ${textSize} ${lineHeight} `}
        style={{
          display: 'flex',
          flexDirection: 'row',
          flex: '1 1 100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {props.children}
        {!!icon && isAnchor && <MeIcon className={styles.MeTextIcon} icon={icon} size={iconSize} />}
      </a>
    </div>
  );
};

export default MeText;
