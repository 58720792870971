import React from 'react';
// import { ProfileId } from 'types/User.types';

import MeIcon from 'components/icon/meicon/MeIcon';
import RoundedButton from 'components/buttons/rounded/RoundedButton';
import MeText from 'components/text/metext/MeText';

import styles from './StudentCard.module.scss';

export type StudentModel = {
  className?: string;
  id: string;
  icon: string;
  title: string;
  bodyText: string;
  buttonLabel: string;
  buttonIcon: string;
  to: string;
  // onAction: (profileId: ProfileId) => void;
  footerText: string;
  isActiveProfile?: boolean;
};

type StudentCardProps = {
  className?: string;
} & StudentModel;

const StudentCard: React.FC<StudentCardProps> = (props) => {
  // const actionHandler = () => props.onAction(props.id);
  const highlightCls = props.isActiveProfile ? styles.HighlightBg : '';
  return (
    <div className={`${styles.StudentCard} ${props.className ?? ''}`}>
      <div className={`${styles.StudentCardAvatar} ${highlightCls}`}>
        <MeIcon style={{ lineHeight: 1 }} insetIcon size="xl5" weight="bold400" icon={props.icon} />
      </div>
      <div className={styles.StudentCardInnerLayout}>
        <MeText
          textSize="xtl"
          weight="bold400"
          insetText
          style={{ textAlign: 'center', letterSpacing: '-1px', height: '6em' }}>
          {props.title.toUpperCase()}
        </MeText>
        {/* <MeText textSize="sam">{p.careerId}</MeText> */}
        <MeText style={{ margin: '1em 0' }} textSize="mel" insetText weight="bold400">
          {props.bodyText.toUpperCase()}
        </MeText>
        <RoundedButton className={styles.ActionButton} to={props.to} icon={props.buttonIcon} mainColor>
          {props.buttonLabel}
        </RoundedButton>
        <MeText style={{ marginBottom: '1em', marginTop: '1em' }} weight="bold400" insetText textSize="xs12">
          {props.footerText}
        </MeText>
      </div>
    </div>
  );
};

export default StudentCard;
