/* eslint-disable @typescript-eslint/no-explicit-any */
import { store } from 'app/store';
import { db } from '../firebase/Firebase';
import { defer, redirect } from 'react-router-dom';
import { doc, updateDoc } from 'firebase/firestore';
import { isPrint } from 'helpers/appHelpers';

import { IUser, IProfile, ProfileId } from 'types/User.types';

import { fetchUserProfile, loadProfileAndPublicCourses, updateOwnUserAccountSceneAction } from './ProfilesAPI';

import { userToDoc } from 'api/converters/UserConverter';
import { useStyledLogger } from 'app/config/LogConfig';
import { getActiveProfileId, isUserSignedIn } from 'modules/user/userSlice';
import { setToastNotification } from 'modules/user/userUiSlice';
import { getToastErrorPayload } from 'helpers/uiHelpers';
import { receiveAward } from 'modules/profiles/awards/awardsSlice';
import { AwardCodes } from 'app/constants/AwardCodes';
import { doSetActiveScene } from 'scenes/scenesSlice';
import { AppRoutes } from 'app/constants/Routes';

const PRIMARY_COLOR = 'yellow';
const CLS_NAME = 'StudentAPI';
const PRINT_FLAG = true;

const printMsg = useStyledLogger(CLS_NAME, PRIMARY_COLOR);

// #region APIs
export const setStudentProfileAsActive = async (profileId: string): Promise<IUser> => {
  const userModel = store.getState().user;
  // TODO: @Andrey11 check to make sure `activeProfileId` matches the `profileId` param
  // eventually the passkey will be also verified here

  const userRef = doc(db, `users/${userModel.uid}`);
  const userToUpdate: IUser = { ...userModel, activeProfileId: profileId, modifiedOn: Date.now() };
  const docToUpdate = userToDoc(userToUpdate);
  await updateDoc(userRef, docToUpdate);

  return userToUpdate;
};

export const updateStudentProfile = async (profileId: ProfileId): Promise<IProfile> => {
  // TODO: @Andrey11 check to make sure `activeProfileId` matches the `profileId` param
  // eventually the passkey will be also verified here

  const studentProfile: IProfile = await fetchUserProfile(profileId);
  return studentProfile;
};
// #endregion

// #region ROUTER LOADER & ACTION FUNCTIONS **********************************************

export const studentProfileLoader = async ({ params }: any) => {
  const profileId = params.profileId;
  isPrint(PRINT_FLAG) && console.log(...printMsg('LOAD StudentProfileLoader', 'ProfileId', profileId));
  const isLoggedIn = isUserSignedIn(store.getState());
  const isActiveStudentId = getActiveProfileId(store.getState()) === profileId;

  if (isLoggedIn && isActiveStudentId) {
    store.dispatch(doSetActiveScene('student:profile'));
    const studentData = loadProfileAndPublicCourses(profileId);
    return defer({ studentData });
  }
  const errorText = `ERROR Cannot load student profile ${profileId}`;
  const errorStatus = `LoggedIn:${isLoggedIn}, isActiveStudentProfile: ${isActiveStudentId}`;
  isPrint(PRINT_FLAG) && console.log(...printMsg(errorText, errorStatus, 'redirecting to home'));
  throw redirect(AppRoutes.Hub);
};

/**
 * @param {any} params params passed into this `action`
 *
 * @return {Promise<void>} - nothing is actually being return here
 */
export const studentProfileAction = async ({ request, params }: any): Promise<Response> => {
  const profileId = params.profileId;
  isPrint(PRINT_FLAG) && console.log(...printMsg('Patch studentProfileAction', 'ProfileId', profileId));

  const isLoggedIn = isUserSignedIn(store.getState());
  const isActiveStudentId = getActiveProfileId(store.getState()) === profileId;

  if (isLoggedIn && isActiveStudentId) {
    const url = new URL(request.url);
    const icon = url.searchParams.get('icon');
    const profileId = params.profileId;

    await updateOwnUserAccountSceneAction(profileId);
    if (icon === 'updated') store.dispatch(receiveAward(AwardCodes.Code0004));
    return redirect(AppRoutes.Hub);
  }
  const errorText = `ERROR Cannot patch student profile ${profileId}`;
  const errorStatus = `LoggedIn:${isLoggedIn}, isActiveStudentProfile: ${isActiveStudentId}`;
  isPrint(PRINT_FLAG) && console.log(...printMsg(errorText, errorStatus, 'redirecting to home'));
  store.dispatch(setToastNotification(getToastErrorPayload('Unable to update profile')));
  throw redirect(AppRoutes.Hub);
};
// #endregion
