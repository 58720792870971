import React, { CSSProperties } from 'react';
import { BoldWeightT, ElementSizeT } from 'types/App.types';
import { PassKeyDigitsT, PassKey, PassKeyT } from 'components/passkey/PassKey.types';

import MeIconRaised from 'components/icon/meiconraised/MeIconRaised';

import styles from './DisplayDigits.module.scss';

type DisplayDigitsProps = {
  className?: string;
  activeSpot?: PassKeyT;
  iconSize?: ElementSizeT;
  iconWeight?: BoldWeightT;
  isFilled?: boolean;
  disabled?: boolean;
  showHighlight?: boolean;
  small?: boolean;
  tiny?: boolean;
} & PassKeyDigitsT;

const DisplayDigits: React.FC<DisplayDigitsProps> = ({
  activeSpot,
  iconSize = 'xl4',
  iconWeight = 'bold700',
  isFilled = true,
  disabled = true,
  digit1 = 0,
  digit2 = 0,
  digit3 = 0,
  digit4 = 0,
  showHighlight = false,
  small = false,
  tiny = false,
  ...props
}) => {
  const sizeCls = small ? 'small' : tiny ? 'tiny' : '';

  const highlightStyle: CSSProperties = showHighlight
    ? { backdropFilter: 'brightness(1.2)' }
    : { backdropFilter: 'brightness(1)' };

  const displayDigitArray = Array.from(Array(4).keys());

  const getDigitValue = (key: number) => {
    return key === 0 ? digit1 : key === 1 ? digit2 : key === 2 ? digit3 : digit4;
  };

  return (
    <div className={`${styles.DisplayDigits} ${props.className ?? ''} ${sizeCls}`} style={highlightStyle}>
      {displayDigitArray.map((val: number, index: number) => (
        <MeIconRaised
          key={`key_${val}_${index}`}
          iconSize={iconSize}
          disabled={disabled}
          isUnselected={activeSpot === PassKey[`key${index + 1}` as PassKeyT]}
          showSuccess
          iconWeight={iconWeight}
          icon={`counter_${getDigitValue(index)}`}
          isFilled={isFilled}
        />
      ))}
    </div>
  );
};

export default DisplayDigits;
