import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { BaseFiltersT, FilterCategories } from 'types/App.types';

export type ManagementUiState = {
  questionFilterCategories: FilterCategories<BaseFiltersT>;
  activeQuestionFilters: Array<string>;
  quizFilterCategories: FilterCategories<BaseFiltersT>;
  activeQuizFilters: Array<string>;
  lessonFilterCategories: FilterCategories<BaseFiltersT>;
  activeLessonFilters: Array<string>;
};

const initialState: ManagementUiState = {
  activeQuestionFilters: [],
  questionFilterCategories: [],
  quizFilterCategories: [],
  activeQuizFilters: [],
  lessonFilterCategories: [],
  activeLessonFilters: [],
};

export const managementUISlice = createSlice({
  name: 'managementUI',
  initialState,
  reducers: {
    setActiveQuestionFilters: (state, action: PayloadAction<Array<string>>) => {
      state.activeQuestionFilters = action.payload;
    },
    setQuestionFilterCategories: (state, action: PayloadAction<FilterCategories<BaseFiltersT>>) => {
      state.questionFilterCategories = action.payload;
    },
    setActiveQuizFilters: (state, action: PayloadAction<Array<string>>) => {
      state.activeQuizFilters = action.payload;
    },
    setQuizFilterCategories: (state, action: PayloadAction<FilterCategories<BaseFiltersT>>) => {
      state.quizFilterCategories = action.payload;
    },
    setActiveLessonFilters: (state, action: PayloadAction<Array<string>>) => {
      state.activeLessonFilters = action.payload;
    },
    setLessonFilterCategories: (state, action: PayloadAction<FilterCategories<BaseFiltersT>>) => {
      state.lessonFilterCategories = action.payload;
    },
  },
});

export const {
  setActiveQuestionFilters,
  setQuestionFilterCategories,
  setActiveQuizFilters,
  setQuizFilterCategories,
  setActiveLessonFilters,
  setLessonFilterCategories,
} = managementUISlice.actions;

export const getActiveQuestionFilters = (state: RootState): Array<string> =>
  state.ui.managementUI.activeQuestionFilters;
export const getQuestionFilterCategories = (state: RootState): Array<string> =>
  state.ui.managementUI.questionFilterCategories;
export const getActiveQuizFilters = (state: RootState): Array<string> => state.ui.managementUI.activeQuizFilters;
export const getQuizFilterCategories = (state: RootState): Array<string> => state.ui.managementUI.quizFilterCategories;
export const getActiveLessonFilters = (state: RootState): Array<string> => state.ui.managementUI.activeLessonFilters;
export const getLessonFilterCategories = (state: RootState): Array<string> =>
  state.ui.managementUI.lessonFilterCategories;

export default managementUISlice.reducer;
