/* eslint-disable @typescript-eslint/no-explicit-any */
import { store } from 'app/store';
import { redirect } from 'react-router-dom';
import { db } from '../firebase/Firebase';
import {
  query,
  collection,
  orderBy,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  setDoc,
} from 'firebase/firestore';
import { AwardId, AwardT, AwardUpdateResponseT, AwardsT, Status } from 'types/App.types';

import { useStyledLogger } from 'app/config/LogConfig';
import { awardConverter, awardToDoc, getInitialAward } from 'api/converters/AwardConverter';

import { doSetActiveScene, setLastActiveManageScene } from 'scenes/scenesSlice';
import { updateAward, setAwards, setAwardsStatus } from 'scenes/management/managerSlice';
import { setToastNotification } from 'modules/user/userUiSlice';
import { setAllAwards, setAllAwardsStatus } from 'modules/profiles/awards/awardsSlice';
import { isPrint } from 'helpers/appHelpers';
import { getToastSuccessPayload } from 'helpers/uiHelpers';
import { AppRoutes } from 'app/constants/Routes';

const printMsg = useStyledLogger('AwardsAPI', 'coral', 'cornsilk');
const PRINT_FLAG = false;

// #region AWARD APIs *****************************************************************
export const fetchAwards = async (): Promise<AwardsT> => {
  const collectionData = collection(db, 'awards').withConverter(awardConverter);
  const q = query(collectionData, orderBy('createdOn', 'asc'));
  const querySnapshot = await getDocs(q);
  const awards: AwardsT = [];
  querySnapshot.docs.forEach((doc) => {
    const docData: AwardT = doc.data();
    awards.push({
      ...docData,
      awardId: doc.id,
    });
  });

  return awards;
};

export const getAward = async (awardId: AwardId): Promise<AwardT> => {
  const docRef = doc(db, `awards/${awardId}`).withConverter(awardConverter);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const docData: AwardT = docSnap.data();
    return { ...docData, awardId: awardId };
  } else {
    console.error('No such document!');
    throw Error(`Award (${awardId}) unable to find`);
  }
};

export const createAward = async (award: AwardT): Promise<AwardT> => {
  const collectionData = collection(db, 'awards').withConverter(awardConverter);
  const docRef = doc(collectionData);
  const createdAward: AwardT = { ...award, awardId: docRef.id };
  // Create new record with autogenerated id as AwardId
  await setDoc(docRef, createdAward);
  isPrint(PRINT_FLAG) && console.log(...printMsg('Document written with ID', 'docRef.id', ''));
  return createdAward as AwardT;
};

export const patchAward = async (award: AwardT): Promise<void> => {
  const awardRef = doc(db, `awards/${award.awardId}`);
  const docAward = awardToDoc(award);
  return await updateDoc(awardRef, docAward);
};
// #endregion

// #region ROUTER LOADER & ACTION FUNCTIONS **********************************************
export const appAwardsSceneLoader = async (): Promise<AwardsT> => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('Loading', 'AppAwardSceneLoader', ''));

  store.dispatch(setAllAwardsStatus(Status.Loading));
  const awards: AwardsT = await fetchAwards();
  isPrint(PRINT_FLAG) && console.log(...printMsg('LOADED App Awards', 'count', String(awards.length)));
  store.dispatch(setAllAwards(awards));
  store.dispatch(setAllAwardsStatus(Status.Loaded));

  return awards;
};

// #endregion

// #region MANAGE Awards **************************************************************

export const manageAwardCollectionSceneLoader = async () => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('Loading ManageAwardCollectionScene', '', ''));
  store.dispatch(doSetActiveScene('manage:awards:collection'));
  store.dispatch(setLastActiveManageScene('awards'));

  const awards = await fetchAwards();
  store.dispatch(setAwards({ collection: awards, status: 'loaded' }));

  return awards;
};

export const manageCreateAwardSceneLoader = async () => {
  store.dispatch(doSetActiveScene('manage:award:create'));
  store.dispatch(setLastActiveManageScene('awards'));

  const award: AwardT = getInitialAward();
  return { award };
};

export const manageCreateAwardSceneAction = async () => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('ManageCreateAwardSceneAction', 'Creating Award', ''));
  store.dispatch(setAwardsStatus('creating'));
  const managerAwards = store.getState().manager.awards;
  const indexOfCreatedItem = managerAwards.findIndex((q) => q.awardId === 'createAwardId');
  const AwardItem = managerAwards[indexOfCreatedItem] as AwardT;
  const toCreateAward: AwardT = {
    ...AwardItem,
    createdOn: Date.now(),
    modifiedOn: Date.now(),
  };

  const createdAward = await createAward(toCreateAward);
  store.dispatch(updateAward({ award: createdAward, index: indexOfCreatedItem }));
  store.dispatch(setAwardsStatus('created'));
  store.dispatch(setToastNotification(getToastSuccessPayload('Award Created')));
  // update main award redux section to indicate it will have to update itself at some point
  store.dispatch(setAllAwardsStatus('created'));
  return redirect(AppRoutes.ManagerAwards);
};

export const manageEditAwardSceneLoader = async ({ params }: any): Promise<AwardUpdateResponseT> => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('ManageEditAwardSceneLoader', 'Loading ManageEditAwardScene', ''));
  store.dispatch(doSetActiveScene('manage:award:edit'));
  const awardId = params.awardId;

  const award = await getAward(awardId);
  const index = store.getState().manager.awards.findIndex((q) => q.awardId === award.awardId);
  return { award, index };
};

export const manageEditAwardSceneAction = async ({ params }: any) => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('ManageEditAwardSceneAction', 'Editing Award', ''));
  const awardId = params.awardId;
  store.dispatch(setAwardsStatus('updating'));
  const managerAwards = store.getState().manager.awards;
  const indexOfEditedItem = managerAwards.findIndex((q) => q.awardId === awardId);
  const awardItem = managerAwards[indexOfEditedItem] as AwardT;
  const awardItemToEdit: AwardT = {
    ...awardItem,
    modifiedOn: Date.now(),
    modifiedBy: store.getState().user.uid,
  };

  await patchAward(awardItemToEdit);
  store.dispatch(setAwardsStatus(Status.Updated));
  store.dispatch(setToastNotification(getToastSuccessPayload('Award Updated')));
  // update main award redux section to indicate it will have to update itself at some point
  store.dispatch(setAllAwardsStatus(Status.Updated));
  return redirect(AppRoutes.ManagerAwards);
};

// #endregion
