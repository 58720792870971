import {
  ArithmeticT,
  AppNotificationT,
  ElementSizeT,
  Arithmetic,
  Fruit,
  FruitT,
  ToastNotificationT,
  AppNotification,
} from 'types/App.types';

import { AppleIcon, LemonIcon, OrangeIcon, TomatoIcon, WatermelonIcon } from 'components/icon/FruitIcon';
import InsetIcon from 'components/icon/InsetIcon';

const scrollToCard = (scrollElementId: string, activeElCls: string) => {
  const scrollEl = document.getElementById(scrollElementId);
  const activeCardEl = document.querySelector(activeElCls);

  if (scrollEl && activeCardEl) {
    const cardRect: DOMRect = activeCardEl.getBoundingClientRect();
    const scrollerRect: DOMRect = scrollEl.getBoundingClientRect();

    // offset based on scroller element position
    const mid = scrollerRect.width / 2 + scrollerRect.left;
    // offset based center of the card - minus the scroller offset
    const leftOffset = cardRect.width / 2 + cardRect.left - mid;

    scrollEl.scrollLeft += leftOffset;
  }
};

/**
 * Scrolls to a specific `Card` in a `Carousel` where `scrollElementId` is a scroll list and
 * `cardQuery` is a query that identifies the item to scroll to.
 *
 * @param {string} scrollElementId id of the `Carousel` scroll element
 * @param {string} cardQuery `querySelector` string that locates the card in a list
 */
export const scrollToCardId = (
  scrollElementId = 'CareerCarouselId',
  cardQuery = '[class*="SimpleCard_SimpleCardWrapper"].active'
) => {
  scrollToCard(scrollElementId, cardQuery);
};

export const scrollToActiveCard = (
  scrollElementId = 'CareerCarouselId',
  activeElCls = '[class*="SimpleCard_SimpleCardWrapper"].active'
) => {
  scrollToCard(scrollElementId, activeElCls);
};

export const scrollToOpenCard = (
  scrollElementId = 'CareerCarouselId',
  activeElCls = '[class*="SimpleCard_SimpleCardWrapper"].open'
) => {
  scrollToCard(scrollElementId, activeElCls);
};

export const isMobile = () => {
  const container = document.getElementById('root');
  if (container) {
    const cardRect: DOMRect = container.getBoundingClientRect();
    return cardRect.width < 450;
  }

  return false;
};

export const isMobileBrowser = () => {
  return 'ontouchstart' in document.documentElement && /mobi/i.test(navigator.userAgent);
};

export const getArrayForIconSpots = (itemCount: number, useBase10Rows: boolean, minCols = 0): Array<number> => {
  const numSpots: number = useBase10Rows ? Math.ceil(itemCount / 10) * 10 : Math.max(itemCount, minCols);
  return Array.from(Array(numSpots).keys());
};

export const getIconByNotificationToastType = (type?: AppNotificationT) => {
  let iconName = 'info';

  if (type === 'success') {
    iconName = 'check_circle';
  } else if (type === 'error') {
    iconName = 'error';
  } else if (type === 'warning') {
    iconName = 'warning';
  }

  return iconName;
};

/**
 * Returns a UI arrow indicating if given {value} is greater, less, or equal to the average
 *
 * @param {number} value number to compare to the average
 * @param {number} averageTimeMs average value to compare against
 * @return {JSX.Element} UI indicator based on value vs averageTimeMs outcome
 */
export const getVsAverageIndicator = (value: number, averageTimeMs: number): JSX.Element => {
  const avgSec = Math.floor(averageTimeMs / 1000);
  const valSec = Math.floor(value / 1000);

  if (avgSec < valSec) {
    return <InsetIcon size="med" color="Red" icon="arrow_drop_down" />;
  } else if (avgSec > valSec) {
    return <InsetIcon size="med" color="Green" icon="arrow_drop_up" />;
  } else {
    const diff = averageTimeMs - value;
    const iconName = diff < 0 ? 'arrow_drop_down' : 'arrow_drop_up';
    return <InsetIcon size="med" color="Default" icon={iconName} />;
  }
};

export const getFruitIcon = (fruit?: FruitT, size: ElementSizeT = 'xl10') => {
  if (fruit === Fruit.Watermelon) {
    return <WatermelonIcon size={size} />;
  } else if (fruit === Fruit.Tomato) {
    return <TomatoIcon size={size} />;
  } else if (fruit === Fruit.Orange) {
    return <OrangeIcon size={size} />;
  } else if (fruit === Fruit.Apple) {
    return <AppleIcon size={size} />;
  } else if (fruit === Fruit.Lemon) {
    return <LemonIcon size={size} />;
  }

  return <TomatoIcon size={size} />;
};

export const getFruitTypeByCategory = (category: ArithmeticT): FruitT => {
  if (category === Arithmetic.Add) {
    return Fruit.Tomato;
  } else if (category === Arithmetic.Subtract) {
    return Fruit.Watermelon;
  } else if (category === Arithmetic.Multiply) {
    return Fruit.Apple;
  } else if (category === Arithmetic.Divide) {
    return Fruit.Lemon;
  } else if (category === Arithmetic.Compare) {
    return Fruit.Orange;
  } else if (category === Arithmetic.Count) {
    return Fruit.Orange;
  }
  return Fruit.Tomato;
};

export const getFruitTypeByMathOperator = (operator: string): FruitT => {
  if (operator === '+') {
    return Fruit.Tomato;
  } else if (operator === '−') {
    return Fruit.Watermelon;
  } else if (operator === '×') {
    return Fruit.Apple;
  } else if (operator === '÷') {
    return Fruit.Lemon;
  } else if (operator === '≘' || operator === '≆') {
    return Fruit.Orange;
  } else if (operator === '±') {
    return Fruit.Orange;
  }
  return Fruit.Tomato;
};

export const getNavigateBackURL = (urlPattern: string, count: number): string => {
  const list = new Array(count).fill(urlPattern);
  return list.join('/');
};

export const PASS_ANIMATION = 'pass-animation';
export const FAIL_ANIMATION = 'fail-animation';
export const CARD_CLOSED_ANIMATION = 'close-card-y';

export const isAnimationName = (animation: string, containsName: string) => animation.indexOf(containsName) >= 0;

export const isPassAnimation = (animationName: string): boolean => {
  return isAnimationName(animationName, PASS_ANIMATION);
};

export const isFailAnimation = (animationName: string): boolean => {
  return isAnimationName(animationName, FAIL_ANIMATION);
};

export const isCardClosedAnimation = (animationName: string): boolean => {
  return isAnimationName(animationName, CARD_CLOSED_ANIMATION);
};

export const getBoldTextTokens = (text: string): Array<string> => {
  const matches: Array<string> = text.match(/(:?\{{2})([\s\S][^}{2}]*)(:?\}{2})/gm) as unknown as Array<string>;
  return matches ? matches.map((m) => m.substring(2, m.length - 2)) : [];
};

export const getTextString = (text: string): Array<string> => {
  const strLinks = text.replaceAll(/(:?<{2})([\s\S][^>{2}]*)(:?>{2})/gm, '|<a>|');
  const strLinksAndBold = strLinks.replaceAll(/(?:\{{2})([\s\S][^}{2}]*)(?:\}{2})/gm, '|<span>|');
  return strLinksAndBold.split('|');
};

export const getTextTokens = (text: string): Array<string> => {
  const str = text.replaceAll(/(:?<{2})([\s\S][^>{2}]*)(:?>{2})/gm, '<a>');
  const strTokens = str.replaceAll(/(?:\{{2})([\s\S][^}{2}]*)(?:\}{2})/gm, '<span>');
  return strTokens.split('<span>');
};

export const getLinkTokens = (text: string): Array<string> => {
  const matches: Array<string> = text.match(/(:?<{2})([\s\S][^>{2}]*)(:?>{2})/gm) as unknown as Array<string>;
  return matches ? matches.map((m) => m.substring(2, m.length - 2)) : [];
};

const boldStyle = '"FILL" 0,"wght" 850,"GRAD" 200,"opsz" 48';
export const b = (text: string): JSX.Element => <span style={{ fontVariationSettings: boldStyle }}>{text}</span>;

export const disableScreenOnMobile = () => {
  if (!isMobileBrowser()) return;
  const rBody = document.querySelector('body');
  if (rBody) {
    rBody.style.overflow = 'hidden';
    rBody.style.position = 'fixed';
    rBody.style.top = '0';
    rBody.style.width = '100%';
  }
};
export const enableScreenOnMobile = () => {
  if (!isMobileBrowser()) return;
  const rBody = document.querySelector('body');
  if (rBody) {
    rBody.style.removeProperty('overflow');
    rBody.style.removeProperty('position');
    rBody.style.removeProperty('top');
    rBody.style.removeProperty('width');
  }
};

export const getToastSuccessPayload = (msg: string): ToastNotificationT => ({
  notification: { message: msg, type: AppNotification.Success },
  show: true,
});

export const getToastErrorPayload = (msg: string): ToastNotificationT => ({
  notification: { message: msg, type: AppNotification.Error },
  show: true,
});

export const getToastWarningPayload = (msg: string): ToastNotificationT => ({
  notification: { message: msg, type: AppNotification.Warning },
  show: true,
});

export const getProgressClass = (num: number): string => {
  const cls = num >= 10 ? '100' : `0${num}0`;
  return `width-${cls}`;
};

export const getAppScreenMidpoint = (): number => {
  return Math.round(window.innerWidth / 2);
};
