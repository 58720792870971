import React from 'react';
import { PropsWithChildren } from 'types/App.types';

import styles from './ImagePortalContainer.module.scss';

type ImagePortalContainerOwnProps = {
  className?: string;
  contentRef: React.ForwardedRef<HTMLDivElement>;
} & Pick<React.HTMLAttributes<HTMLDivElement>, 'id' | 'style' | 'onClick'>;

type ImagePortalContainerProps = PropsWithChildren<ImagePortalContainerOwnProps>;

const ImagePortalContainer: React.FC<ImagePortalContainerProps> = (props) => {
  const onLocalClick = (e: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    if (props.onClick) props.onClick(e);
  };

  return (
    <div id={props.id} ref={props.contentRef} onClick={onLocalClick} className={styles.ImagePortalContainerWrapper}>
      <div className={styles.ImagePortalContainer}>{props.children}</div>
    </div>
  );
};

export default ImagePortalContainer;
