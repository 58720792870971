import { FruitT, MaybeType } from 'types/App.types';

export type CellT = {
  row: number;
  column: number;
  date: number;
  value?: number;
  isHeaderCell?: boolean;
  isCounted?: boolean;
  cellId?: string;
};

export type CellsT = Array<CellT>;

export interface BoardState {
  cells: CellsT;
  rows: number;
  columns: number;
  mathMode: MathModeT;
  selectedCell: MaybeType<CellT>;
  hoveredCell: MaybeType<CellT>;
  selectedRow?: number;
  selectedColumn?: number;
  countedCellsIndexes: Array<number>;
  disabled: boolean;
  mode: ModeT;
  showCellValues: boolean;
  showSelectedCellValues: boolean;
  showRowHeader: boolean;
  showColumnHeader: boolean;
  showRowHeaderValues: boolean;
  showColumnHeaderValues: boolean;
  /** `showHTracerLine` displays a horizontal dotted line across all the entire row */
  showHTracerLine: boolean;
  /** `showVTracerLine` displays a vertical dotted line across all the entire column */
  showVTracerLine: boolean;
  showResetButton: boolean;
  cellFruit: FruitT;
  highlightHeaderRow: boolean;
  highlightHeaderColumn: boolean;
  highlightFirstCell: boolean;
}

export type BoardOptions = {
  rows: number;
  columns: number;
  mathMode: MathModeT;
  mode?: ModeT;
  disabled?: boolean;
  showCellValues?: boolean;
  showSelectedCellValues?: boolean;
  showRowHeader?: boolean;
  showColumnHeader?: boolean;
  showRowHeaderValues?: boolean;
  showColumnHeaderValues?: boolean;
  showResetButton?: boolean;
  cellFruit?: FruitT;
  showHTracerLine?: boolean;
  showVTracerLine?: boolean;
  highlightHeaderRow?: boolean;
  highlightHeaderColumn?: boolean;
  highlightFirstCell?: boolean;
};

export const Mode = {
  Explore: 'explore',
  Tutorial: 'tutorial',
  Quiz: 'quiz',
} as const;
export type ModeT = (typeof Mode)[keyof typeof Mode];

export const MathMode = {
  Addition: 'addition',
  Multiplication: 'multiplication',
  Subtraction: 'subtraction',
  Division: 'division',
  Counting: 'counting',
  Comparing: 'comparing',
} as const;
export type MathModeT = (typeof MathMode)[keyof typeof MathMode];
