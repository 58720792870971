import { db } from '../firebase/Firebase';
import { doc, collection, setDoc, query, getDocs, where, updateDoc } from 'firebase/firestore';

import { CareerId, GameHistoryReportT, GameHistoryReportsT } from 'types/Career.types';

import { useStyledLogger } from 'app/config/LogConfig';
import { sortGameHistory } from 'helpers/sortHelpers';
import { getModifiedInfo } from 'helpers/careerHelpers';

import { gameHistoryToDoc, gameHistoryConverter } from 'api/converters/GameHistoryConverter';
import { store } from 'app/store';
import { setAdminNote } from 'scenes/montessori/appUiSlice';
import { getFormattedTime } from 'helpers/timeHelpers';
import { isPrint } from 'helpers/appHelpers';

const PRIMARY_COLOR = 'darkpink';
const CLS_NAME = 'HistoryAPI';
const PRINT_FLAG = true;

const printMsg = useStyledLogger(CLS_NAME, PRIMARY_COLOR);

// #region HISTORY APIs ******************************************************************
export const updateGameHistoryEntry = async (gameHistory: GameHistoryReportT): Promise<void> => {
  const path = `careers/${gameHistory.careerId}/history/${gameHistory.historyId}`;
  const docRef = doc(db, path).withConverter(gameHistoryConverter);
  const modified = getModifiedInfo();
  isPrint(PRINT_FLAG) && console.log(...printMsg('Update GameHistoryReportT', 'title', gameHistory.title));
  return await updateDoc(docRef, gameHistoryToDoc({ ...gameHistory, ...modified }));
};

export const getGameHistory = async (careerId: CareerId): Promise<GameHistoryReportsT> => {
  const collectionData = collection(db, `careers/${careerId}/history`).withConverter(gameHistoryConverter);
  const q = query(collectionData, where('reportType', '==', 'game'));

  const querySnapshot = await getDocs(q);
  const history: Array<GameHistoryReportT> = [];
  const failedHistory: Array<GameHistoryReportT> = [];
  querySnapshot.docs.forEach((doc) => {
    const docData = { ...doc.data(), historyId: doc.id } as GameHistoryReportT;
    // TODO: @Andrey11 what to do with games that have `result='failed'`?
    if (docData.gameInstance.result === 'pass') {
      history.push(docData);
    } else {
      failedHistory.push(docData);
    }
  });

  if (failedHistory) {
    const reportStr = failedHistory.map((rep) => {
      const title = rep.title;
      const level = rep.gameInstance.gameLevel;
      const time = getFormattedTime(parseInt(String(rep.timeToSolve)));
      return `GameHistoryAPI: Failed game: ${title.trim()}, ${level}, ${time}`;
    });

    store.dispatch(setAdminNote(reportStr.join('\n')));
  }

  return history;
};

export const createGameHistoryEntry = async (historyItem: GameHistoryReportT): Promise<GameHistoryReportT> => {
  const collectionData = collection(db, `careers/${historyItem.careerId}/history`).withConverter(gameHistoryConverter);
  const docRef = doc(collectionData);
  const createdHistoryEntry: GameHistoryReportT = { ...historyItem, historyId: docRef.id };
  // Create new record with autogenerated id
  await setDoc(docRef, createdHistoryEntry);
  const logValue = `${docRef.id}:${createdHistoryEntry.timeToSolve}`;
  isPrint(PRINT_FLAG) && console.log(...printMsg('Created GameHistoryReportT', 'historyId:timeToSolve', logValue));
  return createdHistoryEntry;
};
// #endregion

export const reportCardGames = async (careerId: CareerId): Promise<GameHistoryReportsT> => {
  const history: GameHistoryReportsT = await getGameHistory(careerId);
  history.sort(sortGameHistory);

  return history;
};
