import React, { useEffect, useState, AnimationEvent, RefAttributes, useRef } from 'react';
import { createPortal } from 'react-dom';

import { PropsWithChildren } from 'types/App.types';
import { isAnimationName } from 'helpers/uiHelpers';

import Banner from 'components/banner/Banner';

import DescriptionText, { defaultHelpText } from 'modules/tutorial/help/scenes/components/description/DescriptionText';
import HelpPanelHeader from 'modules/tutorial/help/scenes/components/header/HelpPanelHeader';

import styles from './HelpPopup.module.scss';

const portalContainer: HTMLElement | null = document.getElementById('portalContainer');

if (portalContainer === null) {
  throw Error('portalContainer not found');
}

type HelpPopupPositionT = 'PositionTop' | 'PositionBottom';

type HelpPopupOwnProps = {
  popupId: string;
  className?: string;
  onClose: () => void;
  showHelpPanel: boolean;
  title: string;
  titleIcon: string;
  Spot: React.ReactPortal;
  descriptionText: string;
  position?: HelpPopupPositionT;
  defaultText?: string;
};

type HelpPopupProps = PropsWithChildren<HelpPopupOwnProps> & RefAttributes<HTMLDivElement>;

const HelpPopup: React.FC<HelpPopupProps> = React.forwardRef<HTMLDivElement, HelpPopupProps>(
  ({ popupId, showHelpPanel = false, position = 'PositionBottom', ...props }, ref) => {
    const defHelpText = useRef<string>(props.defaultText || defaultHelpText);
    const [visibilityCls, setVisibilityCls] = useState<string>(styles.HelpPopupPanelInit);
    const [descriptionText, setDescriptionText] = useState<string>(defHelpText.current);
    const [soundOn, setSoundOn] = useState<boolean>(false);

    useEffect(() => {
      if (visibilityCls === styles.HelpPopupPanelInit && !showHelpPanel) return;
      setVisibilityCls(showHelpPanel ? styles.HelpPopupPanelShow : styles.HelpPopupPanelHide);
    }, [showHelpPanel]);

    useEffect(() => {
      setDescriptionText(props.descriptionText);
    }, [props.descriptionText]);

    const onOpenAnimationEnd = (e: AnimationEvent) => {
      if (isAnimationName(e.animationName, 'slide-in-bottom')) {
        console.log('animation is over');
        setTimeout(() => {
          setDescriptionText(defHelpText.current);
        }, 2500);
      }
    };

    const onCloseAnimationStart = (e: AnimationEvent) => {
      if (isAnimationName(e.animationName, 'slide-out-bottom')) {
        console.log('animation is starting');
      }
    };

    return createPortal(
      <div
        ref={ref}
        className={`${styles.HelpPopupPanel} ${position} ${visibilityCls} ${props.className ?? ''}`}
        onAnimationStart={onCloseAnimationStart}
        onAnimationEnd={onOpenAnimationEnd}>
        <div className={`${styles.HelpPopupContainer}`}>
          {props.children}

          <DescriptionText
            defaultText={props.defaultText}
            description={descriptionText}
            className={styles.DescriptionText}
          />
          <HelpPanelHeader
            soundOn={soundOn}
            onSoundToggle={setSoundOn}
            onClose={props.onClose}
            title={props.title}
            titleIcon={props.titleIcon}
          />
          <Banner bannerColor="Primary" bannerSize="Small" bannerPosition="BottomRight">
            HELP
          </Banner>
        </div>
        <div>{props.Spot}</div>
      </div>,
      portalContainer,
      popupId
    );
  }
);

export default HelpPopup;
