import React from 'react';

import InsetText from 'components/text/inset/InsetText';
import TitleHeader from 'components/titleheader/TitleHeader';

import styles from './CareerHubRow.module.scss';
import MeIconRaised from 'components/icon/meiconraised/MeIconRaised';


type CareerHubRowProps = {
  className?: string;
  title?: string;
  icon?: string;
  isFilledIcon?: boolean;
  value?: string | number;
  showButton?: boolean;
  onClick?: () => void;
};

const CareerHubRow: React.FC<CareerHubRowProps> = ({
  className = '',
  title = '',
  icon = '',
  isFilledIcon = false,
  showButton = false,
  ...props
}) => {
  const getRowWithButton = () => (
    <div className={styles.CareerHubButtonRow}>
      <div className={`${styles.CareerHubRow} ${className}`}>
        <TitleHeader icon={icon} titleSize="med" showHorizontalRule={false} isFilledIcon={isFilledIcon}>
          {title}
        </TitleHeader>
        <InsetText size="sma">{props.value || '0'}</InsetText>
      </div>
      <MeIconRaised iconSize='mlg' iconWeight='bold300' key={'key_SwitchProfiles'} icon={'switch_account'} onClick={props.onClick} />
    </div>
  );

  return (
    <>
      {showButton && getRowWithButton()}
      {!showButton && (
        <div className={`${styles.CareerHubRow} ${className}`}>
          <TitleHeader icon={icon} titleSize="med" showHorizontalRule={false} isFilledIcon={isFilledIcon}>
            {title}
          </TitleHeader>
          <InsetText size="sma">{props.value || '0'}</InsetText>
        </div>
      )}
    </>
  );
};

export default CareerHubRow;
