import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks/hooks';
import {
  getShowCompleteNotification,
  setCompleteNotification,
} from 'scenes/montessori/appUiSlice';
import { VoidFunc } from 'types/App.types';

type TrackerStatusT = 'init' | 'tracking_completed' | 'tracking_started' | undefined;

export const useCompleteNotificationDismissTracker = (onDismissFn?: VoidFunc) => {
  const popupTracker = useRef<boolean>(false);
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState<TrackerStatusT>('init');

  const isPopupOpen = useAppSelector(getShowCompleteNotification);

  useEffect(() => {
    if (isPopupOpen && !popupTracker.current) {
      popupTracker.current = true;
      setStatus('tracking_started');
    } else if (!isPopupOpen && popupTracker.current) {
      popupTracker.current = false;
      setStatus('tracking_completed');
      if (onDismissFn) onDismissFn();
    }
  }, [isPopupOpen]);

  const dispatchShowNotification = (btnLabel: string, title: string, icon: string, bodyText = '') => {
    dispatch(
      setCompleteNotification({
        showNotification: true,
        actionButtonLabel: btnLabel,
        headerLabel: title,
        notificationIcon: icon,
        showActionButton: true,
        bodyText: bodyText,
      })
    );
  };

  return { trackerStatus: status, dispatchShowNotification };
};
