export const PassKey = {
  key1: 'key1',
  key2: 'key2',
  key3: 'key3',
  key4: 'key4',
} as const;
export type PassKeyT = (typeof PassKey)[keyof typeof PassKey];

export type PassKeyInputDigitsT = {
  [PassKey.key1]: number;
  [PassKey.key2]: number;
  [PassKey.key3]: number;
  [PassKey.key4]: number;
};

export type PassKeyDigitsT = {
  digit1?: number;
  digit2?: number;
  digit3?: number;
  digit4?: number;
};
