/* eslint-disable valid-jsdoc */
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'app/store';
import { memoize } from 'proxy-memoize';
import {
  CareerItem,
  CareerItemT,
  MaybeType,
  StatusT,
  SubmitActionData,
  UIState,
  VisualStateT,
  VisualState,
  ArithmeticT,
  AwardCode,
  AwardCodesT,
  AwardStars,
  AwardMedals,
  MedalT,
  MedalsT,
} from 'types/App.types';
import { BadgeId, BadgesT, BadgeT, CareerBadgeItemT, CareerId, CareerT, GameHistoryReportT } from 'types/Career.types';
import { LessonId, LessonsT, LessonUI, LessonUIs, LessonUIUpdateReduxT } from 'types/Lesson.types';
import { TutorialId } from 'types/Tutorial.types';
import { QuestionUIResponseT, QuizId, QuizUI } from 'types/Quiz.types';
import { QuestionId, QuestionUI } from 'types/Question.types';
import {
  BaseCourseUIs,
  CareerCourseUIUpdateReduxT,
  CourseId,
  CourseIds,
  CoursesT,
  CourseUI,
  CourseUIs,
} from 'types/Course.types';
import {
  CareerChainCard,
  CareerChainCards,
  CourseCardsT,
  LessonCardsT,
  LessonCardT,
  QuestionCardsT,
  QuizCardT,
} from 'types/BaseCareer.types';
import { AwardedGamesCollection, AwardedGamesT, AwardedGameT, GameId, GameInstanceT } from 'types/Game.types';

import {
  getBoardTutorialCompletionId,
  getCareerToUrl,
  getCourseCompletionId,
  getGameCompletionId,
  getLessonCompletionId,
  getQuestionCompletionId,
  getQuizCompletionId,
  getTutorialCompletionId,
} from 'helpers/routeHelpers';
import { getActiveCareerId, isUserSignedIn } from 'modules/user/userSlice';
import { addCrestForAwardPopupToQueue, doShowAwardCrest } from 'modules/career/careerUiSlice';

import { convertToBaseHistoryEntry, convertToCareerHistoryEntry } from 'helpers/typeCastingHelpers';
import { isPrint } from 'helpers/appHelpers';
import { awardCrest } from 'helpers/badgeHelpers';
import { INTERACTIVE_MATH_GAME_ID } from 'helpers/gamesHelpers';
import { getFormattedString, getNameClsString } from 'app/config/LogConfig';

import { updateCareerCompleteList } from 'api/CareerAPI';
import { createHistoryEntry } from 'api/HistoryAPI';
import { createGameHistoryEntry } from 'api/GameHistoryAPI';
import { getFormattedTime } from 'helpers/timeHelpers';
import { getUnlockingItemMap } from 'scenes/montessori/unlocksSlice';
import { receiveGame } from 'scenes/games/gamesSlice';

export const PRIMARY_COLOR = getNameClsString('powderblue');
export const CLS_NAME = 'CareerSlice';

const printMsg = (action: string, prop: string, status: string) =>
  getFormattedString(PRIMARY_COLOR, CLS_NAME, action, prop, status);

const PRINT_FLAG = true;

export type CareerState = {
  /** career status, used by loaders, and other FE components */
  status: StatusT;
  /** career badges based on available courses and lessons */
  careerBadges: Array<CareerBadgeItemT>;
  /** Career items represented with Cards in a sorted array form */
  careerChain: CareerChainCards;
  /** Array index of the active card in Career chain */
  careerChainActiveIndex: number;

  /** @deprecated expanded Course objects containing details about each course */
  courseUIs?: BaseCourseUIs;
  /** @deprecated expanded Lesson objects containing details about each lesson */
  lessons?: LessonsT;
  /** @deprecated expanded Lesson objects containing details about each lesson */
  lessonUIs?: LessonUIs;
  /** @deprecated expanded Tutorial objects containing details about each tutorial */
  tutorialUI?: CareerChainCard;
  /** @deprecated expanded Quiz objects containing details about each quiz */
  quizUI?: QuizUI;
  /** @deprecated collection of all available Badges */
  badgesUi?: BadgesT;
  /** @deprecated expanded Question objects containing details about it */
  baseQuestionUI?: CareerChainCard;
} & CareerT;

export const initialState: CareerState = {
  status: 'idle',
  userId: '',
  profileId: '',

  complete: [],

  badges: [],
  badgesUi: [],
  careerBadges: [],
  awardCodes: [],
  awardMedals: [],
  awardGames: [],

  careerId: '',
  careerChain: [],
  careerChainActiveIndex: -1,

  courseIds: [],
  hiddenCourseIds: [],
  courses: [],
  // courseUIs: [],

  createdBy: '',
  createdOn: 0,
};

type ActionSetCourseLessonUI = {
  courseId: CourseId;
  lessonUI: LessonUI;
};

// #region AppThunk ACTIONS *************************************************************
const getCredentials = (state: RootState) => {
  if (state.user && state.user.activeProfileId) {
    const userId = state.user.uid;
    const profileId = state.user.activeProfileId;

    const careerIndex = state.user.profiles.findIndex((p) => p.profileId === profileId);
    const careerId = state.user.profiles[careerIndex].careerId;
    return { uid: userId, profileId: profileId, careerId: careerId };
  }
  return null;
};

export const doCreateCareerHistoryEntry =
  (careerItem: QuestionUI): AppThunk =>
  async (_dispatch, getState) => {
    const profileId = getState().career.profileId;
    const uid = getState().career.userId;

    const submitData = careerItem.submitActionData as SubmitActionData;
    const history = await createHistoryEntry(convertToCareerHistoryEntry(submitData, profileId, uid));
    isPrint(PRINT_FLAG) && console.log(...printMsg('Solved', 'TIME', getFormattedTime(history.timeToSolve as number)));
  };

export const doCreateGameHistoryEntry =
  (gameInstance: GameInstanceT): AppThunk =>
  async (_dispatch, getState) => {
    const credentials = getCredentials(getState());
    if (!credentials) return;

    const { profileId, uid, careerId } = credentials;
    const { elapsedTime, gameTitle } = gameInstance;
    const baseHistory = convertToBaseHistoryEntry(careerId, String(elapsedTime), gameTitle, 'game', profileId, uid);
    const historyItem: GameHistoryReportT = { ...baseHistory, gameInstance };
    await createGameHistoryEntry(historyItem);
    isPrint(PRINT_FLAG) &&
      console.log(
        ...printMsg(`Game ${gameInstance.result}`, 'TIME', getFormattedTime(historyItem.timeToSolve as number))
      );
  };

export const doUpdateActiveItem =
  (activeIndex: number): AppThunk =>
  (dispatch, getState) => {
    const chain = getState().career.careerChain.slice();
    const chainItem = { ...chain[activeIndex] } as CareerChainCard;
    chain.splice(activeIndex, 1, { ...chainItem, visualState: VisualState.Active });
    dispatch(setCareerChain(chain));
    dispatch(setCareerChainActiveIndex(activeIndex));
  };

export const doUpdateCompleteGameLevel =
  (completionId: string): AppThunk =>
  async (dispatch, getState) => {
    const isSignedIn = isUserSignedIn(getState());
    const careerId = getActiveCareerId(getState());

    if (isSignedIn && completionId) {
      await updateCareerCompleteList(String(careerId), String(completionId));
      dispatch(addComplete(completionId));
    }
  };

export const doUpdateCompleteItems =
  (indexesToComplete: Array<number>): AppThunk =>
  (dispatch, getState) => {
    const complete = getCompletedItemIds(getState());
    const unlockingMap = getUnlockingItemMap(getState());
    const chain = getState().career.careerChain.slice();
    const completed: Array<string> = [];
    indexesToComplete
      .filter((itemIndex) => !complete.includes(String(chain[itemIndex].completionId)))
      .forEach(async (itemIndex) => {
        const item = { ...chain[itemIndex], visualState: VisualState.Completed } as CareerChainCard;
        chain.splice(itemIndex, 1, item);
        completed.push(String(item.completionId));
        // award game
        if (unlockingMap[item.versionedItemId]) dispatch(receiveGame(unlockingMap[item.versionedItemId]));

        await updateCareerCompleteList(String(getState().career.careerId), String(item.completionId));
        const arithmetic = item.submitActionData?.value as ArithmeticT;
        if (item.type === CareerItem.Lesson) {
          const crest = awardCrest(CareerItem.Lesson, item.level, arithmetic, item.versionedItemTitle);
          dispatch(addCrestForAwardPopupToQueue(crest));
        } else if (item.type === CareerItem.Course) {
          const crest = awardCrest(CareerItem.Course, item.level, arithmetic, item.versionedItemTitle);
          dispatch(addCrestForAwardPopupToQueue(crest));
        }
      });

    dispatch(setCareerChain(chain));
    dispatch(setComplete(complete.concat(completed)));
    setTimeout(() => {
      dispatch(doShowAwardCrest());
    }, 1000);
  };
// #endregion

// #region REDUCER or slice
export const careerSlice = createSlice({
  name: 'career',
  initialState,
  reducers: {
    setCareerT: (state, action: PayloadAction<CareerT>) => {
      return { ...state, ...action.payload };
    },
    setCareerBadges: (state, action: PayloadAction<Array<CareerBadgeItemT>>) => {
      state.careerBadges = action.payload;
    },
    setCareerChain: (state, action: PayloadAction<CareerChainCards>) => {
      state.careerChain = action.payload;
    },
    setCareerChainActiveIndex: (state, action: PayloadAction<number>) => {
      state.careerChainActiveIndex = action.payload;
    },
    setProfileId: (state, action: PayloadAction<string>) => {
      state.profileId = action.payload;
    },
    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
    },
    setLessons: (state, action: PayloadAction<LessonsT>) => {
      state.lessons = action.payload;
    },
    setLessonUIs: (state, action: PayloadAction<LessonUIs>) => {
      state.lessonUIs = action.payload;
    },
    setCareerAwardCodes: (state, action: PayloadAction<AwardStars>) => {
      state.awardCodes = action.payload.codes;
    },
    setCareerAwardMedals: (state, action: PayloadAction<AwardMedals>) => {
      state.awardMedals = action.payload.awarded;
    },
    setCareerAwardedGames: (state, action: PayloadAction<AwardedGamesCollection>) => {
      state.awardGames = action.payload.awardedGames;
    },
    updateCourseUI: (state, action: PayloadAction<CareerCourseUIUpdateReduxT>) => {
      const temp = (state.courseUIs as BaseCourseUIs).slice();
      temp.splice(action.payload.index, 1, action.payload.courseUI);
      state.courseUIs = temp;
    },
    updateLessonUI: (state, action: PayloadAction<LessonUIUpdateReduxT>) => {
      const courseUIs = (state.courseUIs as CourseUIs).slice();
      const lessonUI = action.payload.lessonUI;
      const courseIndex = courseUIs.findIndex((c) => c.courseId === lessonUI.submitActionData?.courseId);
      const courseUI = courseUIs[courseIndex] as CourseUI;
      const temp = (courseUI.courseLessonUIs as LessonUIs).slice();
      // const indexOfQ = temp.findIndex((q) => q.id === action.payload.question.questionId);
      temp.splice(action.payload.index, 1, action.payload.lessonUI);
      courseUI.courseLessonUIs = temp;
      courseUIs.splice(courseIndex, 1, courseUI);
      state.courseUIs = courseUIs;
    },
    updateQuestionUI: (state, action: PayloadAction<QuestionUIResponseT>) => {
      if (state.quizUI && state.quizUI.quizId === action.payload.quizId) {
        const quizUI: QuizUI = { ...state.quizUI };
        state.quizUI = quizUI;
      }
    },
    setCourseIds: (state, action: PayloadAction<CourseIds>) => {
      state.courseIds = action.payload;
    },
    setHiddenCourseIds: (state, action: PayloadAction<CourseIds>) => {
      state.hiddenCourseIds = action.payload;
    },
    setCourses: (state, action: PayloadAction<CoursesT>) => {
      state.courses = action.payload;
    },
    setCourseUIs: (state, action: PayloadAction<CourseUIs>) => {
      state.courseUIs = action.payload;
    },
    setCareerStatus: (state, action: PayloadAction<StatusT>) => {
      state.status = action.payload;
    },
    setComplete: (state, action: PayloadAction<Array<string>>) => {
      state.complete = action.payload;
    },
    addComplete: (state, action: PayloadAction<string>) => {
      if (!state.complete.includes(action.payload)) {
        state.complete = state.complete.concat(action.payload);
      }
    },
    addBadge: (state, action: PayloadAction<BadgeId>) => {
      if (!state.badges.includes(action.payload)) {
        state.badges.push(action.payload);
      }
    },
    addAwardCode: (state, action: PayloadAction<AwardCode>) => {
      state.awardCodes = state.awardCodes.concat(action.payload);
    },
    addAwardMedal: (state, action: PayloadAction<MedalT>) => {
      state.awardMedals = state.awardMedals.concat(action.payload);
    },
    addAwardedGame: (state, action: PayloadAction<AwardedGameT>) => {
      state.awardGames = state.awardGames.concat(action.payload);
    },

    setTutorialUI: (state, action: PayloadAction<CareerChainCard | undefined>) => {
      state.tutorialUI = action.payload;
    },
    setCareerQuestionUI: (state, action: PayloadAction<ActionSetCourseLessonUI>) => {
      const courseIndex = (state.courseUIs as CourseUIs).findIndex((c) => c.courseId === action.payload.courseId);
      const courseUI = (state.courseUIs as CourseUIs)[courseIndex];
      const lessonIndex = (courseUI.courseLessonUIs as LessonUIs).findIndex(
        (l) => l.lessonId === action.payload.lessonUI.lessonId
      );
      const lessonUIs = (state.courseUIs as CourseUIs)[courseIndex].courseLessonUIs as LessonUIs;
      lessonUIs.splice(lessonIndex, 1, action.payload.lessonUI);
      const courseUIs = (state.courseUIs as CourseUIs).slice();
      courseUIs[courseIndex].courseLessonUIs = lessonUIs;
      state.courseUIs = courseUIs;
    },
    setCareerTutorialUI: (state, action: PayloadAction<ActionSetCourseLessonUI>) => {
      const courseIndex = (state.courseUIs as CourseUIs).findIndex((c) => c.courseId === action.payload.courseId);
      const courseUI = (state.courseUIs as CourseUIs)[courseIndex];
      const lessonIndex = (courseUI.courseLessonUIs as LessonUIs).findIndex(
        (l) => l.lessonId === action.payload.lessonUI.lessonId
      );
      const lessonUIs = (state.courseUIs as CourseUIs)[courseIndex].courseLessonUIs as LessonUIs;
      lessonUIs.splice(lessonIndex, 1, action.payload.lessonUI);
      const courseUIs = (state.courseUIs as CourseUIs).slice();
      courseUIs[courseIndex].courseLessonUIs = lessonUIs;
      state.courseUIs = courseUIs;
    },
    setCareerQuizUI: (state, action: PayloadAction<ActionSetCourseLessonUI>) => {
      const courseIndex = (state.courseUIs as CourseUIs).findIndex((c) => c.courseId === action.payload.courseId);
      const courseUI = (state.courseUIs as CourseUIs)[courseIndex];
      const lessonIndex = (courseUI.courseLessonUIs as LessonUIs).findIndex(
        (l) => l.lessonId === action.payload.lessonUI.lessonId
      );
      const lessonUIs = (state.courseUIs as CourseUIs)[courseIndex].courseLessonUIs as LessonUIs;
      lessonUIs.splice(lessonIndex, 1, action.payload.lessonUI);
      const courseUIs = (state.courseUIs as CourseUIs).slice();
      courseUIs[courseIndex].courseLessonUIs = lessonUIs;
      state.courseUIs = courseUIs;
    },
    setCareerLessonUI: (state, action: PayloadAction<ActionSetCourseLessonUI>) => {
      const courseIndex = (state.courseUIs as CourseUIs).findIndex((c) => c.courseId === action.payload.courseId);
      const courseUI = (state.courseUIs as CourseUIs)[courseIndex];
      const lessonIndex = (courseUI.courseLessonUIs as LessonUIs).findIndex(
        (l) => l.lessonId === action.payload.lessonUI.lessonId
      );
      const lessonUIs = (state.courseUIs as CourseUIs)[courseIndex].courseLessonUIs as LessonUIs;
      lessonUIs.splice(lessonIndex, 1, action.payload.lessonUI);
      const courseUIs = (state.courseUIs as CourseUIs).slice();
      courseUIs[courseIndex].courseLessonUIs = lessonUIs;
      state.courseUIs = courseUIs;
    },
    setBadgesUi: (state, action: PayloadAction<BadgesT>) => {
      state.badgesUi = action.payload;
    },
    setBaseQuestionUI: (state, action: PayloadAction<CareerChainCard | undefined>) => {
      state.baseQuestionUI = action.payload;
    },
    resetCareer: () => {
      return { ...initialState };
    },
  },
});

export const {
  setCareerT,
  setCareerBadges,
  setCareerChain,
  setCareerChainActiveIndex,
  setProfileId,
  setUserId,
  setLessons,
  setLessonUIs,
  setCareerAwardCodes,
  setCareerAwardMedals,
  setCareerAwardedGames,
  updateCourseUI,
  updateQuestionUI,
  updateLessonUI,
  setCareerStatus,
  setCourses,
  setHiddenCourseIds,
  setCourseIds,
  setCourseUIs,
  setTutorialUI,
  setCareerQuizUI,
  setCareerLessonUI,
  setCareerTutorialUI,
  setCareerQuestionUI,
  setComplete,
  addComplete,
  addBadge,
  addAwardCode,
  addAwardMedal,
  addAwardedGame,
  setBadgesUi,
  setBaseQuestionUI,
  resetCareer,
} = careerSlice.actions;
// #endregion

// #region SELECTORS
export const getCareerT = (state: RootState): CareerT => state.career;
export const getCareerChain = (state: RootState): CareerChainCards => state.career.careerChain;
export const getCareerChainActiveIndex = (state: RootState): number => state.career.careerChainActiveIndex;
export const transformCareerChainIntoHierarchy = memoize((state: RootState): CourseCardsT => {
  const chain: CareerChainCards = getCareerChain(state);
  const courseCards: CourseCardsT = [];
  let lessonCards: LessonCardsT = [];
  let questionCards: QuestionCardsT = [];
  let tutorialCard: CareerChainCard;
  let quiz: QuizCardT;
  chain.forEach((card: CareerChainCard) => {
    if (card.type === CareerItem.Tutorial) {
      tutorialCard = card;
    } else if (card.type === CareerItem.Question) {
      questionCards.push(card);
    } else if (card.type === CareerItem.Quiz) {
      quiz = { quizCard: card, questionCards: questionCards.slice() };
      questionCards = [];
    } else if (card.type === CareerItem.Lesson) {
      const lessonCard: LessonCardT = { lessonCard: card, quiz, tutorialCard };
      lessonCards.push(lessonCard);
    } else if (card.type === CareerItem.Course) {
      courseCards.push({ courseCard: card, lessonCards: lessonCards.slice() });
      lessonCards = [];
    }
  });
  return courseCards;
});

export type CareerMapT = {
  courses: CourseCardsT;
  lessons: LessonCardsT;
  quizzes: Array<QuizCardT>;
  tutorials: Array<CareerChainCard>;
  questions: QuestionCardsT;
};

export const transformCareerChainIntoCareerMap = memoize((state: RootState): CareerMapT => {
  const chain: CareerChainCards = getCareerChain(state);
  const careerMap: CareerMapT = {
    courses: [],
    lessons: [],
    quizzes: [],
    tutorials: [],
    questions: [],
  };
  const courseCards: CourseCardsT = [];
  let lessonCards: LessonCardsT = [];
  let questionCards: QuestionCardsT = [];
  let tutorialCard: CareerChainCard;
  let quiz: QuizCardT;
  chain.forEach((card: CareerChainCard) => {
    if (card.type === CareerItem.Tutorial) {
      tutorialCard = card;
      careerMap.tutorials.push(card);
    } else if (card.type === CareerItem.Question) {
      questionCards.push(card);
      careerMap.questions.push(card);
    } else if (card.type === CareerItem.Quiz) {
      quiz = { quizCard: card, questionCards: questionCards.slice() };
      careerMap.quizzes.push(quiz);
      questionCards = [];
    } else if (card.type === CareerItem.Lesson) {
      const lessonCard: LessonCardT = { lessonCard: card, quiz, tutorialCard };
      lessonCards.push(lessonCard);
      careerMap.lessons.push(lessonCard);
    } else if (card.type === CareerItem.Course) {
      courseCards.push({ courseCard: card, lessonCards: lessonCards.slice() });
      careerMap.courses.push({ courseCard: card, lessonCards: lessonCards.slice() });
      lessonCards = [];
    }
  });
  return careerMap;
});

export const getCurrentCareerId = (state: RootState): CareerId => state.career.careerId;
export const getCareerStatus = (state: RootState): StatusT => state.career.status;
export const isCareerStatusLoaded = (state: RootState): boolean => state.career.status === 'loaded';
// Course *******************************************************************************
export const getCareerCourseIds = (state: RootState): CourseIds => state.career.courseIds;
export const getCareerHiddenCourseIds = (state: RootState): CourseIds => state.career.hiddenCourseIds ?? [];

export const getCareerCourses = (state: RootState): CoursesT => state.career.courses;
export const getCareerCourseUIs = (state: RootState): CourseUIs => state.career.courseUIs as CourseUIs;
export const getCareerBadges = (state: RootState): Array<CareerBadgeItemT> => state.career.careerBadges;

export const getByIdCareerCourseUI = (state: RootState, courseId: CourseId): CourseUI =>
  createSelector([getCareerCourseUIs], (courseUIs: CourseUIs) => {
    return courseUIs.find((c) => c.courseId === courseId) as CourseUI;
  })(state);

export const getCurrentLessonUIs = (state: RootState, courseId: CourseId) =>
  createSelector([getByIdCareerCourseUI], (courseUI: CourseUI) => {
    return courseUI ? (courseUI.courseLessonUIs as LessonUIs) : [];
  })(state, courseId);

export const getByIdCareerLessonUI = (state: RootState, courseId: CourseId, lessonId: LessonId) =>
  createSelector([getCurrentLessonUIs], (lessonUIs) => {
    return lessonUIs.find((l) => l.lessonId === lessonId) as LessonUI;
  })(state, courseId);

export const getCurrentQuizUI = (state: RootState, courseId: CourseId, lessonId: LessonId): QuizUI =>
  createSelector([getByIdCareerLessonUI], (lessonUI) => {
    return lessonUI && (lessonUI.quizUI as QuizUI);
  })(state, courseId, lessonId);

// Tutorial *****************************************************************************
export const getCurrentTutorialUI = (state: RootState): MaybeType<CareerChainCard> => state.career.tutorialUI;

// Question *****************************************************************************
export const getCurrentBaseQuestionUI = (state: RootState): MaybeType<CareerChainCard> => state.career.baseQuestionUI;

export const getCompletedItemIds = (state: RootState): Array<string> => state.career.complete;

export const getBadgesUi = (state: RootState): BadgesT => state.ui.badgesUi.badges || [];

export const isCompleteById = (state: RootState, completionId: string): boolean =>
  createSelector([getCompletedItemIds], (complete: Array<string>) => {
    return complete.includes(completionId);
  })(state);

export const isCompleteBoardIntroTutorial = (state: RootState): boolean =>
  createSelector([getCompletedItemIds], (complete: Array<string>) => {
    const completionId = getBoardTutorialCompletionId('intro');
    return complete.includes(completionId);
  })(state);

export const hasCompletedOneCourse = (state: RootState): boolean =>
  createSelector([getCompletedItemIds, getCareerCourseIds], (complete: Array<string>, courseIds: CourseIds) => {
    const index = courseIds.findIndex((c) => complete.includes(getCourseCompletionId(c)));
    return index > -1;
  })(state);


export type MemoCompleteGamesProps = {
  state: RootState;
  gameId: GameId;
};
export const getCompletedLevelsByGameId = memoize((props: MemoCompleteGamesProps): Array<string> => {
  return props.state.career.complete.filter((item: string) => item.indexOf(`career:game=${props.gameId}`) !== -1);
});

export const isCompleteTutorialBoardAdd = (state: RootState): boolean =>
  state.career.complete.includes('career:boardtutorial=add');

export const isCompleteInteractiveMathGame = (state: RootState): boolean => {
  const completionId = getGameCompletionId(INTERACTIVE_MATH_GAME_ID, 'level1');
  return state.career.complete.includes(completionId);
};

export const getAvailableLessonsForCourse = (state: RootState, courseId: CourseId) =>
  createSelector([getByIdCareerCourseUI, getCompletedItemIds], (courseUI: CourseUI, complete: Array<string>) => {
    const lessonUIs = courseUI.courseLessonUIs as LessonUIs;
    const availableLessons =
      lessonUIs.filter(
        (l: LessonUI) =>
          l.visualState !== VisualState.Completed && !complete.includes(l.submitActionData?.completionId || '')
      ) || [];
    return availableLessons;
  })(state, courseId);

export const shouldCompleteCourse = (state: RootState, courseId: CourseId) =>
  createSelector(
    [getAvailableLessonsForCourse, getCompletedItemIds],
    (availableLessons: LessonUIs, complete: Array<string>) => {
      const completionId = getCourseCompletionId(courseId);
      const isComplete = complete.includes(completionId);
      const hasAvailableLessons = availableLessons.length > 0;
      return !isComplete && !hasAvailableLessons;
    }
  )(state, courseId);

export const getLessonUIForCourseUI = (state: RootState, courseId: CourseId) =>
  createSelector([getAvailableLessonsForCourse], (lessonUIs) => {
    return lessonUIs ? lessonUIs[0] : undefined;
  })(state, courseId);

export const getSimpleCareerUrl = (state: RootState): string => {
  return `/career/simple/${state.career.careerId || getActiveCareerId(state)}`;
};

export const getEditCareerCoursesUrl = (state: RootState): string => {
  return `/student/${state.career.profileId}?open="true"`;
};

export const getViewCareerBadgesUrl = (state: RootState): string => {
  return `/career/badges/${state.career.careerId || getActiveCareerId(state)}`;
};

/** @deprecated */
export const resolveUIStateByType = (
  type: CareerItemT,
  title: string,
  complete: Array<string>,
  careerId: CareerId,
  courseId: CourseId,
  lessonId?: LessonId,
  tutorialId?: TutorialId,
  quizId?: QuizId,
  questionId?: QuestionId
): UIState => {
  let completionId = '';
  let id = '';

  switch (type) {
    case CareerItem.Course:
      completionId = getCourseCompletionId(courseId);
      id = courseId as CourseId;
      break;
    case CareerItem.Lesson:
      completionId = getLessonCompletionId(courseId, lessonId as LessonId);
      id = lessonId as LessonId;
      break;
    case CareerItem.Tutorial:
      completionId = getTutorialCompletionId(courseId, lessonId as LessonId, tutorialId as TutorialId);
      id = tutorialId as TutorialId;
      break;
    case CareerItem.Quiz:
      completionId = getQuizCompletionId(courseId, lessonId as LessonId, quizId as QuizId);
      id = quizId as QuizId;
      break;
    case CareerItem.Question:
      completionId = getQuestionCompletionId(
        courseId,
        lessonId as LessonId,
        quizId as QuizId,
        questionId as QuestionId
      );
      id = questionId as QuestionId;
      break;
    default:
      isPrint(PRINT_FLAG) && console.error('Should not reach default state');
      completionId = '';
  }

  const submitActionData: SubmitActionData = {
    actionUrl: getCareerToUrl(careerId),
    simpleActionUrl: `/career/simple/${careerId}`,
    completionId: completionId,
    id: id,
    name: title,
    type: type,
    careerId: careerId,
    courseId: courseId,
    lessonId: lessonId,
    value: type,
  };

  const visualState: VisualStateT = complete.includes(completionId) ? VisualState.Completed : 'locked';

  return { visualState, completionId, submitActionData };
};

/** @deprecated */
export const getNextActiveCareerChainItem = (state: RootState): MaybeType<CareerChainCard> =>
  createSelector(getCareerChain, (chainItems) => {
    return chainItems.find((item) => item.visualState === VisualState.Active);
  })(state);

export const containsBadgeId = (state: RootState, badgeId: BadgeId): boolean => state.career.badges.includes(badgeId);

export const getBadgeIfEarned = (state: RootState, badgeId: BadgeId): MaybeType<BadgeT> =>
  createSelector([containsBadgeId, getBadgesUi], (hasBadge, badgesT) => {
    return hasBadge ? badgesT.find((b) => b.badgeId === badgeId) : undefined;
  })(state, badgeId);

export const hasAwardByAwardCode = (state: RootState, awardCode: AwardCode): boolean =>
  state.career.awardCodes.includes(awardCode);
export const getAwardCodes = (state: RootState): AwardCodesT => state.career.awardCodes;
export const getAwardMedals = (state: RootState): MedalsT => state.career.awardMedals;
export const getAwardCodesCount = (state: RootState) => state.career.awardCodes.length;
export const getAwardedGames = (state: RootState): AwardedGamesT => state.career.awardGames;
export const getAwardedGameIds = memoize(
  (state: RootState): Array<GameId> => state.career.awardGames.map((g) => g.gameId)
);

// #endregion

export default careerSlice.reducer;
