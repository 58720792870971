import React, { AnimationEvent, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { ElementSizeT, PropsWithChildren, VisualStateT, VisualState, BoldWeightT } from 'types/App.types';

import MeText from 'components/text/metext/MeText';
import MeIcon from 'components/icon/meicon/MeIcon';

import styles from './MeIconRaised.module.scss';

type OwnProps = {
  className?: string;
  icon?: string;
  showSuccess?: boolean;
  size?: ElementSizeT;
  iconSize?: ElementSizeT;
  isDanger?: boolean;
  isSuccess?: boolean;
  isUnselected?: boolean;
  disabled?: boolean;
  textLabel?: string;
  state?: VisualStateT;
  to?: string;
  scale?: number;
  isFilled?: boolean;
  mainColor?: boolean;
  iconWeight?: BoldWeightT;
  showAsPressed?: boolean;
  onAnimationEnd?: (e: AnimationEvent) => void;
} & React.RefAttributes<HTMLDivElement> &
  Pick<React.HTMLAttributes<HTMLDivElement>, 'onClick' | 'style'>;

export type MeIconRaisedProps = PropsWithChildren<OwnProps>;

const MeIconRaised: React.FC<MeIconRaisedProps> = React.forwardRef<HTMLDivElement, MeIconRaisedProps>(
  (
    {
      to = '',
      state = VisualState.Active,
      iconSize = 'xsm',
      size = 'xsm',
      disabled = false,
      textLabel = '',
      isFilled,
      isDanger,
      isSuccess,
      isUnselected,
      mainColor,
      showSuccess,
      iconWeight,
      showAsPressed = false,
      ...props
    },
    ref
  ) => {
    const navigate = useNavigate();
    let iconName = state === 'locked' ? 'shield_lock' : props.icon || '';
    iconName = state === VisualState.Completed ? 'verified_user' : iconName;

    const clickHandler = (event: MouseEvent<HTMLDivElement>) => {
      if (state !== 'locked' && !disabled) {
        if (props.onClick) {
          props.onClick(event);
          return;
        } else if (to) {
          event.stopPropagation();
          navigate(to);
        }
        return;
      }
    };

    // Used to filter out special cases of buttons that should not propagate */
    const iconClickHandler = (event: MouseEvent<HTMLDivElement>) => {
      // if (state === 'locked' || state === VisualState.Completed) {
      if (state === 'locked' || to === '' || disabled) {
        event.preventDefault();
        event.stopPropagation();
        if (props.onClick) {
          props.onClick(event);
        }
        // return false;
      }
    };

    const bgCls = mainColor ? styles.IconRaisedHighlightBg : '';
    const dangerCls = isDanger ? styles.IconRaisedDangerBg : '';
    const successCls = isSuccess ? styles.IconRaisedSuccessBg: '';
    const unselectedCls = isUnselected ? styles.IconRaisedUnselectedBg : '';
    const backgroundCls = `${bgCls} ${dangerCls} ${successCls} ${unselectedCls}`;

    const pressedCls = showAsPressed ? styles.IconRaisedActiveState : '';
    const isTextOnly = textLabel && !iconName;
    const IconBaseCls = `${styles.IconRaised} ${isTextOnly ? 'TextOnly' : ''} ${pressedCls}`;

    return (
      <div
        ref={ref}
        {...props}
        onAnimationEnd={props.onAnimationEnd}
        data-aria-disabled={disabled}
        className={`${IconBaseCls} ${size} ${state} ${backgroundCls} ${props.className ?? ''}`}
        style={props.style}
        onClick={clickHandler}>
        {(iconName || textLabel) && (
          <MeText textSize={size} insetText isDanger>
            {iconName && (
              <MeIcon
                size={iconSize}
                weight={iconWeight}
                isSuccess={!!showSuccess}
                icon={iconName}
                showPointerCursor={!disabled}
                isFilled={!!isFilled}
                isUnselected={!!isUnselected}
                onClick={iconClickHandler}
                isDanger={!!isDanger}
              />
            )}
            {textLabel && <span className={props.className}>{textLabel}</span>}
          </MeText>
        )}
      </div>
    );
  }
);

export default MeIconRaised;
