/* eslint-disable @typescript-eslint/no-explicit-any */
import { db } from '../firebase/Firebase';
import { query, collection, orderBy, getDocs, getDoc, doc, updateDoc, setDoc } from 'firebase/firestore';
import { store } from 'app/store';
import { redirect } from 'react-router-dom';

import { StatusT, VisualStateT, VisualState, Status } from 'types/App.types';
import { VersionId, VersionedItemsT } from 'types/BaseCareer.types';
import { CareerId } from 'types/Career.types';
import { CourseId } from 'types/Course.types';
import { LessonId, LessonUI } from 'types/Lesson.types';
import { QuestionsT } from 'types/Question.types';
import { QuizzesT, QuizId, QuizResponseT, QuizT, QuizUI } from 'types/Quiz.types';

import { fetchQuestions, fetchVersionedQuestions } from './QuestionsAPI';
import { careerStatusUpdated, updateCareerCompleteList } from './CareerAPI';

import { castLessonQuizToQuizUI, castToQuizUI } from 'helpers/typeCastingHelpers';
import { sortQuestions, sortQuizzes } from 'helpers/sortHelpers';
import { findMissingQuestionsFromQuiz } from 'helpers/questionHelpers';

import {
  addComplete,
  getByIdCareerLessonUI,
  getCompletedItemIds,
  getCurrentQuizUI,
  setCareerQuizUI,
  setBaseQuestionUI,
} from 'modules/career/careerSlice';
import { doSetActiveScene, setLastActiveManageScene } from 'scenes/scenesSlice';
import {
  setQuestions,
  setQuizzes,
  setQuizzesStatus,
  updateQuiz as updateManageQuiz,
} from 'scenes/management/managerSlice';
import { setActiveQuizFilters, setQuizFilterCategories } from 'scenes/management/managementUiSlice';
import { setToastNotification } from 'modules/user/userUiSlice';

import { quizConverter, quizToDoc, getInitialQuiz } from 'api/converters/QuizzesConverters';
import { getCreateModifiedInfo, getModifiedInfo } from 'helpers/careerHelpers';
import { getToastSuccessPayload } from 'helpers/uiHelpers';
import { AppRoutes } from 'app/constants/Routes';

// #region QUIZ APIs *********************************************************************
export const fetchQuizzes = async (): Promise<QuizzesT> => {
  const collectionData = collection(db, 'quizzes').withConverter(quizConverter);
  const q = query(collectionData, orderBy('type'), orderBy('level', 'asc'));
  const quizzes: QuizzesT = [];

  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.docs.forEach((doc) => {
      const docData = doc.data();
      quizzes.push({
        ...docData,
        quizId: doc.id,
      });
    });
  } catch (error) {
    console.error('There was an error while fetchQuizzes:', error);
  } finally {
    quizzes.sort(sortQuizzes);
    return quizzes;
  }
};

export const getQuizOld = async (quizId: string): Promise<QuizT> => {
  const docRef = doc(db, `quizzes/${quizId}`).withConverter(quizConverter);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const docData: QuizT = docSnap.data();
    return { ...docData, quizId };
  } else {
    console.error('No such document!');
    throw Error(`Quiz (${quizId}) unable to find`);
  }
};

export const getQuiz = async (quizId: string, versionId: VersionId): Promise<QuizT> => {
  const docRef = doc(db, `quizzes/${quizId}/versions/${versionId}`).withConverter(quizConverter);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const docData: QuizT = docSnap.data();
    return { ...docData, quizId };
  } else {
    console.error('No such document!');
    throw Error(`Quiz (${quizId}) unable to find`);
  }
};

export const getQuizUI = async (
  careerId: CareerId,
  courseId: CourseId,
  lessonId: LessonId,
  quizId: QuizId,
  visualState: VisualStateT
): Promise<QuizUI> => {
  const quizT: QuizT = await getQuizOld(quizId);

  const complete = store.getState().career.complete;
  return castToQuizUI(careerId, courseId, lessonId, visualState, complete, quizT);
};

export const createQuizOld = async (quizData: QuizT): Promise<QuizT> => {
  const collectionData = collection(db, 'quizes').withConverter(quizConverter);
  // Autogenerate id for this record
  const docRef = doc(collectionData);
  const createdQuiz: QuizT = { ...quizData, quizId: docRef.id };
  // Create new record with autogenerated id as quizId
  await setDoc(docRef, createdQuiz);
  return createdQuiz as QuizT;
};

export const createQuiz = async (quizData: QuizT): Promise<QuizT> => {
  const collectionData = collection(db, 'quizzes').withConverter(quizConverter);
  // Autogenerate id for this record
  const docRef = doc(collectionData);
  const createdQuiz: QuizT = { ...quizData, quizId: docRef.id };
  createdQuiz.versionId = await createQuizVersion(createdQuiz);
  // Create new record with autogenerated id as quizId
  await setDoc(docRef, createdQuiz);
  return createdQuiz as QuizT;
};

export const createQuizVersion = async (quiz: QuizT): Promise<VersionId> => {
  const collectionData = collection(db, `quizzes/${quiz.quizId}/versions`).withConverter(quizConverter);
  const docRef = doc(collectionData);
  const newQ = { ...quiz, versionId: docRef.id } as QuizT;
  await setDoc(docRef, newQ);
  return docRef.id as VersionId;
};

export const updateQuiz = async (quizData: QuizT) => {
  const quiz: QuizT = { ...quizData, versionId: '' };
  quiz.versionId = await createQuizVersion(quiz);
  const docQuiz = quizToDoc(quiz);
  const quizRef = doc(db, `quizzes/${quizData.quizId}`);
  return await updateDoc(quizRef, docQuiz);
};
// #endregion

// #region ROUTER LOADER & ACTION FUNCTIONS **********************************************

// #region CAREER LOADER & ACTION FUNCTIONS **********************************************
export const careerQuizSceneLoader = async ({ params }: any): Promise<QuizUI> => {
  const { careerId, courseId, lessonId } = params;

  const careerStatus: StatusT = await careerStatusUpdated();
  if (careerStatus === 'loaded') {
    const complete = getCompletedItemIds(store.getState());
    const lessonUI: LessonUI = getByIdCareerLessonUI(store.getState(), courseId, lessonId);

    if (lessonUI) {
      let quizUI = lessonUI.quizUI as QuizUI;
      if (!quizUI && lessonUI.quiz) {
        quizUI = castLessonQuizToQuizUI(careerId, courseId, lessonUI, complete);
        store.dispatch(setCareerQuizUI({ courseId, lessonUI: { ...lessonUI, quizUI: quizUI } }));
      }
      return quizUI;
    }
  }
  throw redirect('..');
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const careerQuizSceneAction = async ({ params }: any): Promise<QuizUI> => {
  const { careerId, courseId, lessonId } = params;

  const quizUI: QuizUI = getCurrentQuizUI(store.getState(), courseId, lessonId);
  const completionId = String(quizUI.completionId);
  store.dispatch(setBaseQuestionUI(undefined));
  await updateCareerCompleteList(careerId, completionId);
  store.dispatch(addComplete(completionId));
  const updatedQuiz: QuizUI = { ...quizUI, visualState: VisualState.Completed };

  return updatedQuiz;
};

// #endregion

// #region EDITOR Loader & Action functions **********************************************
export const manageQuizCollectionSceneLoader = async (): Promise<QuizzesT> => {
  store.dispatch(doSetActiveScene('manage:quiz:collection'));
  store.dispatch(setLastActiveManageScene('quiz'));
  const quizzes = await fetchQuizzes();
  store.dispatch(setQuizzes({ collection: quizzes, status: 'loaded' }));

  const availableFilters = quizzes
    .map((q) => q.quizCategory)
    .filter((item, index, list) => list.lastIndexOf(item) === index);
  store.dispatch(setQuizFilterCategories(availableFilters));
  if (store.getState().ui.managementUI.activeQuizFilters.length === 0) {
    store.dispatch(setActiveQuizFilters(availableFilters));
  }

  return quizzes;
};

export const manageEditQuizSceneLoader = async ({ params }: any): Promise<QuizResponseT> => {
  store.dispatch(doSetActiveScene('manage:quiz:edit'));
  // TODO: @Andrey11 change params to also pass versionId
  const quiz: QuizT = await getQuizOld(params.quizId);
  const managerStore = store.getState().manager;
  const questions = managerStore.questionsStatus === 'idle' ? await fetchQuestions() : managerStore.questions;
  store.dispatch(setQuestions({ collection: questions, status: 'loaded' }));

  return { quiz, questions };
};

export const manageEditVersionedQuizSceneLoader = async ({ params }: any): Promise<QuizResponseT> => {
  store.dispatch(doSetActiveScene('manage:quiz:edit'));

  const quiz: QuizT = await getQuiz(params.quizId, params.versionId);
  const questions: QuestionsT = await fetchQuestions();
  const questionsFromQuiz: VersionedItemsT = quiz.versionedQuestionIds;

  const missingQuestions = findMissingQuestionsFromQuiz(questions, questionsFromQuiz);

  if (missingQuestions && missingQuestions.length > 0) {
    const missingQs = await fetchVersionedQuestions(missingQuestions);
    const updatedQuestions = questions.concat(missingQs);
    updatedQuestions.sort(sortQuestions);
    store.dispatch(setQuestions({ collection: updatedQuestions, status: 'loaded' }));
    return { quiz, questions: updatedQuestions };
  } else {
    store.dispatch(setQuestions({ collection: questions, status: 'loaded' }));
    return { quiz, questions };
  }
};

export const manageEditQuizSceneAction = async ({ params }: any) => {
  const quizId = params.quizId;
  store.dispatch(setQuizzesStatus(Status.Updating));
  const managerQuizzes = store.getState().manager.quizzes;
  const indexOfEditedItem = managerQuizzes.findIndex((q) => q.quizId === quizId);
  const quizItem = managerQuizzes[indexOfEditedItem] as QuizT;
  const modified = getModifiedInfo();
  const quizToEdit: QuizT = {
    ...quizItem,
    ...modified,
  };

  await updateQuiz(quizToEdit);
  store.dispatch(setQuizzesStatus(Status.Updated));
  store.dispatch(setToastNotification(getToastSuccessPayload('Quiz Updated')));
  return redirect(AppRoutes.ManagerQuiz);
};

export const manageCreateQuizSceneLoader = async (): Promise<QuizResponseT> => {
  store.dispatch(doSetActiveScene('manage:quiz:create'));
  const quiz: QuizT = getInitialQuiz();
  const managerStore = store.getState().manager;
  const questions = managerStore.questionsStatus === 'idle' ? await fetchQuestions() : managerStore.questions;
  store.dispatch(setQuestions({ collection: questions, status: 'loaded' }));

  return { quiz, questions };
};

export const manageCreateQuizSceneAction = async () => {
  store.dispatch(setQuizzesStatus('creating'));
  const managerQuizzes = store.getState().manager.quizzes;
  const indexOfCreatedItem = managerQuizzes.findIndex((q) => q.quizId === 'createQuizId');
  const quizItem = managerQuizzes[indexOfCreatedItem] as QuizT;
  const createdAndModified = getCreateModifiedInfo();
  const toCreateQuiz: QuizT = {
    ...quizItem,
    ...createdAndModified,
  };

  const createdQuiz = await createQuiz(toCreateQuiz);
  store.dispatch(updateManageQuiz(createdQuiz));
  store.dispatch(setQuizzesStatus('created'));
  store.dispatch(setToastNotification(getToastSuccessPayload('Quiz Created')));
  return redirect(AppRoutes.ManagerQuiz);
};
// #endregion
