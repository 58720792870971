import React, { useState } from 'react';
import { useAwardNotificationDismissTracker } from 'app/hooks/useAwardNotificationDismissTracker';
import IconButton from 'components/buttons/icon/IconButton';

type ShowCompletionNotificationProps = {
  className?: string;
};

const ShowStarPopup: React.FC<ShowCompletionNotificationProps> = () => {
  const [notificationOpen, setNotificationOpen] = useState<boolean>(false);
  const onNotificationClose = () => setNotificationOpen(false);

  const { dispatchShowNotification } = useAwardNotificationDismissTracker(onNotificationClose);

  const showAwardStar = () => {
    setNotificationOpen(true);
    dispatchShowNotification('COLLECT', 'NOTIFICATION TITLE', true);
  };

  return <IconButton icon="award_star" isFilled={notificationOpen} onClick={showAwardStar} />;
};

export default ShowStarPopup;
