import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'app/store';
import { Animations, AnimationT, Arithmetic, MaybeType } from 'types/App.types';
import { CellsT, CellT } from 'types/Board.types';
import { QuestionUI } from 'types/Question.types';

export interface IActiveQuestionUiState {
  questionUI?: QuestionUI;
  userAnswers: CellsT;
  userAnswer: MaybeType<CellT>;
  resultStatus: AnimationT;
  showMontessoriBoard: boolean;
}

const initialState: IActiveQuestionUiState = {
  questionUI: undefined,
  userAnswers: [],
  userAnswer: undefined,
  resultStatus: Animations.None,
  showMontessoriBoard: false,
};

export const initActiveQuestion =
  (questionUI: QuestionUI): AppThunk =>
  (dispatch) => {
    const showBoard = questionUI.categoryType !== Arithmetic.Count && questionUI.categoryType !== Arithmetic.Compare;

    dispatch(resetActiveQuestionUiState());
    dispatch(setQuestion(questionUI));
    dispatch(setShowMontessoriBoard(showBoard));
  };

export const activeQuestionUiSlice = createSlice({
  name: 'activeQuestionUiSlice',
  initialState,
  reducers: {
    setQuestion: (state, action: PayloadAction<QuestionUI>) => {
      state.questionUI = action.payload;
    },
    setUserAnswers: (state, action: PayloadAction<CellsT>) => {
      state.userAnswers = action.payload;
    },
    setUserAnswer: (state, action: PayloadAction<MaybeType<CellT>>) => {
      state.userAnswer = action.payload;
    },
    setResultStatus: (state, action: PayloadAction<AnimationT>) => {
      state.resultStatus = action.payload;
    },
    setShowMontessoriBoard: (state, action: PayloadAction<boolean>) => {
      state.showMontessoriBoard = action.payload;
    },
    resetActiveQuestionUiState: () => {
      return initialState;
    },
  },
});

export const {
  setQuestion,
  setUserAnswers,
  setUserAnswer,
  setResultStatus,
  setShowMontessoriBoard,
  resetActiveQuestionUiState,
} = activeQuestionUiSlice.actions;

export const getActiveQuestionUI = (state: RootState): MaybeType<QuestionUI> => state.ui.activeQuestionUi.questionUI;
export const getUserAnswers = (state: RootState): CellsT => state.ui.activeQuestionUi.userAnswers;
export const getUserAnswer = (state: RootState): MaybeType<CellT> => state.ui.activeQuestionUi.userAnswer;
export const getResultStatus = (state: RootState): AnimationT => state.ui.activeQuestionUi.resultStatus;
export const getShowMontessoriBoard = (state: RootState): boolean => state.ui.activeQuestionUi.showMontessoriBoard;

export default activeQuestionUiSlice.reducer;
