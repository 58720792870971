import { store } from 'app/store';
import { redirect } from 'react-router-dom';

import { SubmitActionData, VisualState } from 'types/App.types';
import { CareerChainCard } from 'types/BaseCareer.types';
import { CourseUIs, CourseUI } from 'types/Course.types';
import { LessonUIs, LessonUI } from 'types/Lesson.types';
import { QuizUI } from 'types/Quiz.types';
import { QuestionUI, QuestionUIs, QuestionId } from 'types/Question.types';
import { TutorialUI } from 'types/Tutorial.types';

import { useStyledLogger } from 'app/config/LogConfig';

import { updateCareerCompleteList } from './CareerAPI';
import {
  addComplete,
  getCareerCourseUIs,
  getByIdCareerCourseUI,
  updateCourseUI,
  getByIdCareerLessonUI,
  setCareerQuizUI,
  setBaseQuestionUI,
  getCurrentBaseQuestionUI,
  setCareerLessonUI,
  setTutorialUI,
} from 'modules/career/careerSlice';
import { setCareerUiOverviewPanelOpen } from 'modules/career/careerUiSlice';

const PRIMARY_COLOR = 'darkgray';
const SECONDARY_COLOR = 'cornsilk';
const CLS_NAME = 'ResolveCareerItemsAPI';

const printMsg = useStyledLogger(CLS_NAME, PRIMARY_COLOR, SECONDARY_COLOR);

export const resolveCourse = async (submitData: SubmitActionData): Promise<Response> => {
  const date = new Date();
  console.log(...printMsg('resolveCourse timing', 'time', date.toISOString()));
  const { careerId, courseId, completionId } = submitData;

  store.dispatch(addComplete(completionId));
  await updateCareerCompleteList(String(careerId), completionId);

  const courseUIs: CourseUIs = getCareerCourseUIs(store.getState());
  const courseUI: CourseUI = getByIdCareerCourseUI(store.getState(), String(courseId));
  const courseIndex = courseUIs.findIndex((c) => c.courseId === courseUI.courseId);

  store.dispatch(updateCourseUI({ courseUI: { ...courseUI, visualState: VisualState.Completed }, index: courseIndex }));

  console.log(...printMsg('Updating CourseUI visualState', 'visualState', VisualState.Completed));

  const hasAvailableCourse = courseUIs.length > courseIndex + 1;
  if (hasAvailableCourse) {
    const nextCourseUI = courseUIs[courseIndex + 1] as CourseUI;
    const nextLessonUI = (nextCourseUI.courseLessonUIs as LessonUIs)[0] as LessonUI;
    const nextQuizUI = nextLessonUI.quizUI as QuizUI;
    store.dispatch(
      updateCourseUI({ courseUI: { ...nextCourseUI, visualState: VisualState.Active }, index: courseIndex + 1 })
    );
    return redirect(String(nextQuizUI.toUrl));
  }
  console.log(...printMsg('Redirecting to CourseURL', 'courseUI.toUrl', String(courseUI.toUrl)));
  return redirect(String(courseUI.toUrl));
};

export const resolveTutorial = async (submitData: SubmitActionData): Promise<Response> => {
  const lessonUI: LessonUI = getByIdCareerLessonUI(
    store.getState(),
    String(submitData.courseId),
    String(submitData.lessonId)
  );
  store.dispatch(setCareerUiOverviewPanelOpen(true));
  const tutorialUI = lessonUI.tutorialUI as TutorialUI;
  console.log(...printMsg('Finishing Tutorial', 'tutorialId', String(submitData.tutorialId)));
  store.dispatch(addComplete(submitData.completionId));
  await updateCareerCompleteList(String(submitData.careerId), submitData.completionId);

  const quizUI = { ...(lessonUI.quizUI as QuizUI), visualState: VisualState.Active };
  const updatedTutorialUI = { ...tutorialUI, visualState: VisualState.Completed };
  const updatedLessonUI = { ...lessonUI, tutorialUI: updatedTutorialUI, quizUI: quizUI } as LessonUI;
  store.dispatch(setCareerLessonUI({ courseId: String(submitData.courseId), lessonUI: updatedLessonUI }));
  store.dispatch(setTutorialUI(undefined));
  return redirect(String(quizUI.toUrl));
};

export const resolveLesson = async (submitData: SubmitActionData): Promise<Response> => {
  const { careerId, courseId, lessonId, completionId } = submitData;

  store.dispatch(addComplete(completionId));
  await updateCareerCompleteList(String(careerId), completionId);

  const lessonUI: LessonUI = getByIdCareerLessonUI(store.getState(), String(courseId), String(lessonId));
  store.dispatch(
    setCareerLessonUI({
      courseId: String(courseId),
      lessonUI: { ...lessonUI, visualState: VisualState.Completed },
    })
  );

  const courseUI: CourseUI = getByIdCareerCourseUI(store.getState(), String(courseId));
  const lessonIndex = (courseUI.courseLessonUIs as LessonUIs).findIndex((l) => lessonUI.lessonId === l.lessonId);
  const shouldResolveCourse = courseUI.courseLessonIds?.length === lessonIndex + 1;

  if (shouldResolveCourse) {
    // Pick a new course, lesson and quiz as currents
    return await resolveCourse(courseUI.submitActionData as SubmitActionData);
  } else {
    // Set a new lesson and quiz as currents
    const nextLessonUI = (courseUI.courseLessonUIs as LessonUIs)[lessonIndex + 1] as LessonUI;
    store.dispatch(
      setCareerLessonUI({
        courseId: String(courseId),
        lessonUI: { ...nextLessonUI, visualState: VisualState.Active },
      })
    );
    const nextQuizUI = nextLessonUI.quizUI as QuizUI;
    return redirect(String(nextQuizUI.toUrl));
  }
};

export const resolveQuiz = async (submitData: SubmitActionData): Promise<Response> => {
  const { careerId, courseId, lessonId, completionId } = submitData;

  console.log(...printMsg('Complete Quiz', 'completionId', completionId));
  store.dispatch(addComplete(completionId));
  await updateCareerCompleteList(String(careerId), completionId);

  store.dispatch(setBaseQuestionUI(undefined));
  store.dispatch(setCareerUiOverviewPanelOpen(true));

  const lessonUI: LessonUI = getByIdCareerLessonUI(store.getState(), String(courseId), String(lessonId));
  const quizUI: QuizUI = { ...(lessonUI.quizUI as QuizUI), visualState: VisualState.Completed };

  store.dispatch(
    setCareerQuizUI({
      courseId: String(courseId),
      lessonUI: { ...lessonUI, quizUI },
    })
  );

  return await resolveLesson(lessonUI.submitActionData as SubmitActionData);
};

export const resolveQuestion = async (submitData: SubmitActionData): Promise<Response> => {
  const { careerId, completionId, courseId, lessonId } = submitData;
  store.dispatch(setCareerUiOverviewPanelOpen(true));
  const questionUI = getCurrentBaseQuestionUI(store.getState()) as CareerChainCard;

  printMsg('Updating CareerQuestionScene', 'questionId', questionUI.versionedItemId as QuestionId);
  store.dispatch(addComplete(completionId));
  await updateCareerCompleteList(String(careerId), completionId);

  const lessonUI = getByIdCareerLessonUI(store.getState(), String(courseId), String(lessonId));
  const quiz: QuizUI = lessonUI.quizUI as QuizUI;
  const questions = (quiz.questionUIs as QuestionUIs).slice();
  const qIndex = questions.findIndex((q) => q.questionId === questionUI.submitActionData?.id);
  questions.splice(qIndex, 1, { ...questions[qIndex], visualState: VisualState.Completed });

  const nextQIndex = qIndex + 1;
  if (questions.length > nextQIndex) {
    const tempNextQuestion = questions[nextQIndex] as QuestionUI;
    if (tempNextQuestion.visualState !== VisualState.Completed) {
      questions.splice(nextQIndex, 1, { ...tempNextQuestion, visualState: VisualState.Active });
    }
  } else {
    const lesson = { ...lessonUI, quizUI: { ...quiz, questionUIs: questions } };
    store.dispatch(setCareerQuizUI({ courseId: String(courseId), lessonUI: { ...lesson } }));
    store.dispatch(setBaseQuestionUI(undefined));
  }

  return redirect(quiz.toUrl as string);
};
