import React from 'react';
import MeIconRaised from 'components/icon/meiconraised/MeIconRaised';

type DigitTypeProps = {
  order: number;
  icon: string;
  num: number;
  onDigitTapped: (num: number) => void;
  mainColor?: boolean;
  isDanger?: boolean;
};

const Digit: React.FC<DigitTypeProps> = (props) => {
  return (
    <MeIconRaised
      style={{ order: props.order }}
      iconSize="xl4"
      mainColor={props.mainColor}
      isDanger={props.isDanger}
      iconWeight="bold500"
      icon={props.icon}
      onClick={() => props.onDigitTapped(props.num)}
    />
  );
};

export default Digit;
