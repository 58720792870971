import React, { useEffect, useState } from 'react';

import { PropsWithChildren } from 'types/App.types';

import AwardPopupDebug from 'components/buttons/debugpanel/components/awardpopup/AwardPopupDebug';
import CaptureDebug from 'components/buttons/debugpanel/components/capture/CaptureDebug';
import DebugCareerStatus from 'components/buttons/debugpanel/components/careerstatus/DebugCareerStatus';
import InsetText from 'components/text/inset/InsetText';
import ShowCompletionNotification from 'components/buttons/debugpanel/components/notification/ShowCompletionNotification';
import ShowStarPopup from 'components/buttons/debugpanel/components/starpopup/ShowStarPopup';
import ShowToast from 'components/buttons/debugpanel/components/toast/ShowToast';

import styles from './DebugButtonWithPanel.module.scss';

type OwnProps = {
  className?: string;
  icon?: string;
  debugActive?: boolean;
  debugDisabled?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

type DebugButtonProps = PropsWithChildren<OwnProps> & React.RefAttributes<HTMLDivElement>;

const DebugButtonWithPanel: React.FC<DebugButtonProps> = React.forwardRef<HTMLDivElement, DebugButtonProps>(
  (props, ref) => {
    const [menuCls, setMenuCls] = useState<string>();
    const [openDebugMenu, setOpenDebugMenu] = useState<boolean>(false);

    useEffect(() => {
      setMenuCls(openDebugMenu ? styles.AnimationOpen : styles.AnimationClose);
    }, [openDebugMenu]);

    return (
      <div ref={ref} className={`${styles.DebugButton} ${props.className} ${menuCls}`}>
        <div className={`${styles.WoodBg}`}>
          <div className={styles.ButtonRow}>
            <DebugCareerStatus />
            <ShowToast />
            <AwardPopupDebug />
            <ShowStarPopup />
            <ShowCompletionNotification />
            <CaptureDebug />
          </div>
          <div className={styles.OpenCloseButton} onClick={() => setOpenDebugMenu(!openDebugMenu)}>
            <InsetText size="xs9">{openDebugMenu ? 'CLOSE' : 'OPEN'}</InsetText>
          </div>
        </div>
      </div>
    );
  }
);

export default DebugButtonWithPanel;
