import React, { useState } from 'react';
import { PropsWithChildren } from 'types/App.types';
import MeText from 'components/text/metext/MeText';
import InputText from 'components/text/inputtext/InputText';
import MeIcon from 'components/icon/meicon/MeIcon';

import styles from './MeArticle.module.scss';

type MeArticleOwnProps = {
  className?: string;
  titleText?: React.ReactNode;
  textList?: Array<React.ReactNode>;
  textParagraph?: React.ReactNode;
};

type MeArticleProps = PropsWithChildren<MeArticleOwnProps>;

const MeArticle: React.FC<MeArticleProps> = (props) => {
  const isAdmin = false;
  return (
    <article className={`${styles.MeArticle} ${props.className ?? ''}`}>
      {props.titleText}
      <section>
        {props.textParagraph && <MeParagraph isAdmin={isAdmin} textParagraph={props.textParagraph} />}
        {props.textList && <MeList isAdmin={isAdmin} textList={props.textList} />}
      </section>
    </article>
  );
};

export default MeArticle;

type MeArticleParagraphOwnProps = {
  className?: string;
  textParagraph?: React.ReactNode;
  isAdmin?: boolean;
};

type MeArticleParagraphProps = PropsWithChildren<MeArticleParagraphOwnProps>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MeArticleParagraph: React.FC<MeArticleParagraphProps> = ({ isAdmin = false, ...props }) => {
  const baseCls = `${styles.MeArticleParagraph} ${props.className ?? ''}`;
  return (
    <MeText textSize="sma" lineHeight="line13" weight="bold500" className={baseCls} insetText>
      {props.textParagraph && props.textParagraph}
    </MeText>
  );
};
const MeParagraph = MeArticleParagraph;

type MeArticleTextListOwnProps = {
  className?: string;
  textList?: Array<React.ReactNode>;
  isAdmin?: boolean;
};

type MeArticleTextListProps = PropsWithChildren<MeArticleTextListOwnProps>;

const MeArticleTextList: React.FC<MeArticleTextListProps> = ({ isAdmin = false, ...props }) => {
  const onEditField = (text: string, index: number) => {
    console.log(`onEditField, index=${index}, text=${text}`);
  };
  return (
    <ul>
      {props.textList &&
        props.textList.map((line, index) => (
          <MeLine
            key={`mel${index}`}
            id={`mel${index}`}
            isAdmin={isAdmin}
            onEditField={(text: string) => onEditField(text, index)}>
            {line}
          </MeLine>
        ))}
    </ul>
  );
};

const MeList = MeArticleTextList;

type MeArticleTextLineOwnProps = {
  className?: string;
  id: string;
  onEditField?: (text: string) => void;
  isAdmin: boolean;
};

type MeArticleTextLineProps = PropsWithChildren<MeArticleTextLineOwnProps>;

const MeArticleTextLine: React.FC<MeArticleTextLineProps> = (props) => {
  const [showEditField, setShowEditField] = useState<boolean>(false);
  const [fieldTextValue, setFieldTextValue] = useState<string>(String(props.children));

  const handleOnEditTap = () => {
    setShowEditField(true);
  };

  const saveFieldTextValue = () => {
    console.log(`POST updated text row to Firebase isSameValue: ${String(props.children) === fieldTextValue}`);
    setShowEditField(false);
    if (props.onEditField) props.onEditField(fieldTextValue);
  };

  return (
    <li>
      {showEditField && (
        <div style={{ display: 'flex', width: '100%', flex: '1' }}>
          <InputText
            id={props.id}
            fieldSize="small"
            borderType="outline"
            sx={{ width: '100%' }}
            fullWidth
            value={fieldTextValue}
            onChange={setFieldTextValue}
            trailingIcon="add_task"
            onTapTrailingIcon={saveFieldTextValue}
          />
          {/* <MeIcon icon="add_task" showPointerCursor onClick={onSaveEditedText} /> */}
        </div>
      )}
      {!showEditField && (
        <MeText useDiv textSize="sma" lineHeight="line12" weight="bold500" className={props.className ?? ''} insetText>
          {fieldTextValue}
          &nbsp;
          {props.onEditField && props.isAdmin && (
            <MeIcon icon="edit_document" showPointerCursor onClick={handleOnEditTap} />
          )}
        </MeText>
      )}
    </li>
  );
};

const MeLine = MeArticleTextLine;
