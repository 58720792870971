import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { getModifiedInfo } from 'helpers/careerHelpers';
import { GameHistoryReportT } from 'types/Career.types';

// #region CONVERTERS ********************************************************************
export const gameHistoryToDoc = (history: GameHistoryReportT): DocumentData => {
  return {
    historyId: history.historyId,
    title: history.title,
    reportType: history.reportType,
    timeToSolve: history.timeToSolve,
    careerId: history.careerId,
    gameInstance: history.gameInstance,
    profileId: history.profileId,
    createdOn: history.createdOn,
    createdBy: history.createdBy,
  };
};

export const gameHistoryConverter: FirestoreDataConverter<GameHistoryReportT> = {
  toFirestore(history: GameHistoryReportT): DocumentData {
    return { ...gameHistoryToDoc(history), ...getModifiedInfo() };
  },

  fromFirestore(docSnap: QueryDocumentSnapshot, options?: SnapshotOptions): GameHistoryReportT {
    const history = docSnap.data(options);
    return {
      historyId: history.historyId,
      title: history.title,
      reportType: history.reportType ?? 'question',
      timeToSolve: history.timeToSolve,
      careerId: history.careerId,
      profileId: history.profileId,
      gameInstance: history.gameInstance,
      createdOn: history.createdOn,
      createdBy: history.createdBy,
    };
  },
};

// #endregion
