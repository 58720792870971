import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks/hooks';
import { ArithmeticT, MaybeType } from 'types/App.types';
import { CrestT } from 'types/Career.types';

import { getOperatorSign } from 'helpers/mathHelpers';
import { doCloseAwardCrest, getCareerBadgeAwardPopupOpen, getCrestForAwardPopup } from 'modules/career/careerUiSlice';

import InsetText from 'components/text/inset/InsetText';
import CrestSimple from 'components/crest/CrestSimple';
import ClosePopupButton from 'components/buttons/closepopup/ClosePopupButton';
import Popup from 'components/popup/Popup';

const AwardPopup: React.FC = () => {
  const dispatch = useAppDispatch();

  const showAwardPopup: boolean = useAppSelector(getCareerBadgeAwardPopupOpen);
  const crestForPopup: MaybeType<CrestT> = useAppSelector(getCrestForAwardPopup);
  const handleClosePopup = () => dispatch(doCloseAwardCrest());

  const [awardText, setAwardText] = useState<string>('');
  const [awardType, setAwardType] = useState<string>('');

  useEffect(() => {
    if (crestForPopup) {
      const { variant, type } = crestForPopup;
      setAwardText(`${variant} Completed!`);
      setAwardType(getOperatorSign(type as ArithmeticT));
    }
  }, [crestForPopup]);

  return (
    <Popup
      id="AppAwardPopupId"
      show={showAwardPopup}
      showCloseButton={false}
      // headerTitle="New Badge Unlocked!"
      popupType="none"
      onCloseEvent={handleClosePopup}>
      {crestForPopup && showAwardPopup && (
        <CrestSimple crestItem={crestForPopup} showTitle size="size3500" badgeUrl={crestForPopup.imageUrl}>
          <InsetText size="mel" capitalize>
            {awardText}
          </InsetText>
          <InsetText style={{ justifyContent: 'center' }} size="xl10" capitalize>
            {awardType}
          </InsetText>
        </CrestSimple>
      )}

      <ClosePopupButton
        onClickHandler={handleClosePopup}
        icon="local_police"
        showButton={showAwardPopup}
        buttonText="COLLECT"
      />
    </Popup>
  );
};

export default AwardPopup;
