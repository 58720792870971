import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks/hooks';
import { Animations, VoidFunc } from 'types/App.types';
import { ProfileId } from 'types/User.types';

import { AppIcons } from 'app/constants/Constants';

import { PassKey, PassKeyInputDigitsT, PassKeyT } from 'components/passkey/PassKey.types';
import MeToggle from 'components/toggle/MeToggle';
import DigitKeypad from 'components/passkey/components/keypad/DigitKeypad';
import DisplayDigits from 'components/passkey/components/displaydigits/DisplayDigits';
import MeIcon from 'components/icon/meicon/MeIcon';
import MeText from 'components/text/metext/MeText';
import MeIconRaised from 'components/icon/meiconraised/MeIconRaised';

import {
  PassKeyActionStateT,
  doProcessOnPassKeyAction,
  doProcessOnPassKeyNoAction,
  doResetUserPassKey,
  getUserId,
} from 'modules/user/userSlice';
import { encodeUserPassKey, getPassKeyCode } from 'modules/user/secret/userHelper';
import {
  PassKeyInternalState,
  getPassKeyPanelAnimationState,
  getPassKeyPanelState,
  getPassKeyPanelVisible,
} from 'modules/user/userUiSlice';

import styles from './PassKeyPanel.module.scss';

const headerTitle = 'ENTER YOUR PASSKEY CODE';
const headerNameTitle = 'ENTER {{name}}\'s PASSKEY CODE';
const headerTitleOnNewCode = 'ENTER NEW PASSKEY CODE';

type PassKeyPanelProps = {
  className?: string;
  onEnter?: VoidFunc;
  onCloseWithoutAction?: VoidFunc;
  actionType: PassKeyActionStateT;
  profileId?: ProfileId;
  isStudentPassKeyMode?: boolean;
  hideResetPassKey?: boolean;
  panelName?: string;
  showCloseButton?: boolean;
};

const PassKeyPanel: React.FC<PassKeyPanelProps> = ({
  profileId = '',
  isStudentPassKeyMode = false,
  hideResetPassKey = false,
  panelName = '',
  ...props
}) => {
  const [digits, setDigits] = useState<PassKeyInputDigitsT>({
    [PassKey.key1]: 0,
    [PassKey.key2]: 0,
    [PassKey.key3]: 0,
    [PassKey.key4]: 0,
  });
  const [activeSpot, setActiveSpot] = useState<PassKeyT>(PassKey.key1);
  const [tries, setTries] = useState<number>(3);
  const [keypadDisabled, setKeypadDisabled] = useState<boolean>(false);
  const [title, setTitle] = useState<string>(headerTitle);
  const [enableForgotPassKey, setEnableForgotPassKey] = useState<boolean>(false);
  const [updateCollapsedState, setUpdateCollapsedState] = useState<boolean | undefined>(false);
  const [showCloseButton, setShowCloseButton] = useState<boolean>(!!props.showCloseButton);

  const animationCls = useAppSelector(getPassKeyPanelAnimationState);
  const internalState: PassKeyInternalState = useAppSelector(getPassKeyPanelState);
  const isPassKeyPanelOpen = useAppSelector(getPassKeyPanelVisible);
  const userId = useAppSelector(getUserId);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (panelName && panelName.length > 0) {
      setTitle(headerNameTitle.replace('{{name}}', panelName));
      return;
    }

    if (internalState === 'set_passkey_pin') {
      setTitle(headerTitleOnNewCode);
    } else {
      setTitle(headerTitle);
    }
  }, [internalState, panelName]);

  useEffect(() => {
    setShowCloseButton(!!props.showCloseButton);
  }, [props.showCloseButton]);

  // When the popup closes, set `keypadDisabled` state to false
  // and set the `state` of the summary details component to `closed`
  useEffect(() => {
    if (!isPassKeyPanelOpen) {
      setEnableForgotPassKey(false);
      setUpdateCollapsedState(undefined);
      setShowCloseButton(false);
      resetDigits();
    } else {
      setUpdateCollapsedState(false);
      const showBtn =
        props.actionType !== 'passkey_validate_pin' && props.actionType !== 'passkey_validate_student_pin';
      setShowCloseButton(props.showCloseButton === true || showBtn);
    }
  }, [isPassKeyPanelOpen]);

  const resetDigits = () => {
    setKeypadDisabled(false);
    setDigits({ key1: 0, key2: 0, key3: 0, key4: 0 });
    setActiveSpot(PassKey.key1);
  };

  useEffect(() => {
    if (animationCls === Animations.None) {
      resetDigits();
    } else if (animationCls === Animations.Pass) {
      setTries(3);
    } else if (animationCls === Animations.Fail) {
      setTries(tries - 1);
    }
  }, [animationCls]);

  const onDelete = () => {
    setDigits({ ...digits, [activeSpot]: 0 });
    const keyNumber = parseInt(activeSpot.replace('key', ''));
    const prevKey = keyNumber - 1 < 1 ? 4 : keyNumber - 1;
    setActiveSpot(`key${prevKey}` as PassKeyT);
  };

  const onKeypadTapped = (num: number) => {
    setDigits({ ...digits, [activeSpot]: num });
    const keyNumber = parseInt(activeSpot.replace('key', ''));
    const nextKey = keyNumber + 1 > 4 ? 1 : keyNumber + 1;
    setActiveSpot(`key${nextKey}` as PassKeyT);
  };

  const onActionButton = () => {
    const inputKey = getPassKeyCode(digits); // `${digits.key1}${digits.key2}${digits.key3}${digits.key4}`;
    const salt = profileId && profileId.length > 0 ? profileId : userId;
    setKeypadDisabled(true);
    const passPhrase = encodeUserPassKey(inputKey, salt);
    dispatch(doProcessOnPassKeyAction(passPhrase, profileId, isStudentPassKeyMode));
  };

  const onResetPassKey = (shouldReset?: boolean) => {
    setEnableForgotPassKey(!!shouldReset);
    if (shouldReset) dispatch(doResetUserPassKey());
  };

  const onClosePopupWithoutAction = () => {
    if (props.onCloseWithoutAction) props.onCloseWithoutAction();
    dispatch(doProcessOnPassKeyNoAction());
  };

  return (
    <div className={`${styles.PassKeyPanel} ${props.className ?? ''}`}>
      <MeText className={props.className ?? ''} insetText weight="bold400" lineHeight="line12" textSize="med">
        {title}
      </MeText>
      <DisplayDigits
        className={animationCls}
        activeSpot={activeSpot}
        digit1={digits.key1}
        digit2={digits.key2}
        digit3={digits.key3}
        digit4={digits.key4}
      />
      <DigitKeypad
        onDigitKey={onKeypadTapped}
        onActionKey={onActionButton}
        onDeleteKey={onDelete}
        isDisabled={keypadDisabled}
      />
      <MessagePanel remainingCount={tries} hidden />
      {!hideResetPassKey && (
        <MeToggle
          id="ResetPassKeyId"
          title="How to reset my PassKey"
          showHLine={false}
          helpIcon="help"
          isIconRaised
          toggleIcon={AppIcons.Common.LockReset}
          hideToggleWhenClosed
          isActive={updateCollapsedState}
          detailsTextSize="sma"
          onSelected={onResetPassKey}
          selected={enableForgotPassKey}>
          <span style={{ whiteSpace: 'pre-line' }}>
            &#8857; Tap on the <MeIcon icon={AppIcons.Common.LockReset} />.
            <br />
            &#8857; New PassKey will be emailed to you.
          </span>
        </MeToggle>
      )}
      {showCloseButton && (
        <MeIconRaised iconSize="med" icon="close" className={styles.CloseButton} onClick={onClosePopupWithoutAction} />
      )}
    </div>
  );
};

export default PassKeyPanel;

type MessagePanelProps = {
  className?: string;
  remainingCount?: number;
  hidden?: boolean;
};
const MessagePanel: React.FC<MessagePanelProps> = ({ hidden = false, remainingCount = 0, ...props }) => {
  const displayStyle = hidden ? 'none' : 'flex';
  return (
    <div className={`${styles.PassKeyMessagePanel} ${props.className ?? ''}`} style={{ display: displayStyle }}>
      <MeText insetText textSize="xs12">
        REMAINING TRIES: {remainingCount}
      </MeText>
    </div>
  );
};
