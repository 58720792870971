import React from 'react';
import styles from './MeIconInfo.module.scss';
import { BoldWeightT, ElementSizeT } from 'types/App.types';
import MeIcon from 'components/icon/meicon/MeIcon';

type MeIconInfoProps = {
  icon: string;
  className?: string;
  showPointerCursor?: boolean;
  isFilled?: boolean;
  insetIcon?: boolean;
  size?: ElementSizeT;
  iconPosition?: 'lineEnd' | 'textEnd';
  weight?: BoldWeightT;
};

const MeIconInfo: React.FC<MeIconInfoProps> = ({
  insetIcon = true,
  showPointerCursor = true,
  size = 'mel',
  weight = 'bold100',
  iconPosition = 'textEnd',
  ...props
}) => {
  const posCls = iconPosition === 'textEnd' ? styles.MeIconInfoPositionAtTextEnd : styles.MeIconInfoPositionAtLineEnd;

  return (
    <div className={`${styles.MeIconInfo} ${posCls} ${props.className ?? ''}`}>
      <MeIcon
        style={{ lineHeight: 1 }}
        weight={weight}
        showPointerCursor={showPointerCursor}
        isUnselected
        icon={props.icon}
        isFilled={!!props.isFilled}
        insetIcon={insetIcon}
        size={size}
      />
    </div>
  );
};

export default MeIconInfo;
