import { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useEventCallback } from '@mui/material';
import { SpotlightShapeT } from 'types/Tutorial.types';

import Spotlight from 'components/spotlight/Spotlight';

const MOUSE_OVER_ACTIVATION_DELAY = 5000;

const portalContainer: HTMLElement | null = document.getElementById('portalContainer');

if (portalContainer === null) {
  throw Error('portalContainer not found');
}

export const useSpotlight = () => {
  const spotlightHiddenRef = useRef<boolean>(false);
  const showSpotlightStartTime = useRef<number>(0);
  const [spotlightStatus, setSpotlightStatus] = useState<'ON' | 'OFF'>('OFF');
  const [showEndAnimation, setShowEndAnimation] = useState<boolean>(false);
  const [spotShape, setSpotShape] = useState<SpotlightShapeT>('circle');

  const getMidpoint = () => Math.round(screenMidpoint / 2);
  const screenMidpoint = portalContainer.getBoundingClientRect().width;

  const [coordX, setCoordX] = useState<number>(getMidpoint());
  const [coordY, setCoordY] = useState<number>(-300);
  const [width, setWidth] = useState<number>(100);
  const [height, setHeight] = useState<number>(100);

  const getDimensionWithBodyOffset = (w: number, h: number, rect: DOMRect) => {
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY,
      width: w,
      height: h,
    };
  };
  const getCircleDimensionsRelToBody = (el: HTMLElement) => {
    const rect = el.getBoundingClientRect();
    const shortest = Math.min(rect.width, rect.height);
    return getDimensionWithBodyOffset(shortest, shortest, rect);
  };
  const getRectangleDimensionsRelToBody = (el: HTMLElement) => {
    const rect = el.getBoundingClientRect();
    return getDimensionWithBodyOffset(rect.width, rect.height, rect);
  };

  const shineOn = useEventCallback((target: string, shape: SpotlightShapeT = 'circle') => {
    if (!target) return;

    setSpotShape(shape);
    setSpotlightStatus('ON');

    setTimeout(() => {
      const els = document.getElementsByClassName(target);
      if (els) {
        const el = els[els.length - 1] as HTMLDivElement;
        const dimensions = shape === 'circle' ? getCircleDimensionsRelToBody(el) : getRectangleDimensionsRelToBody(el);

        spotlightHiddenRef.current = false;
        setWidth(dimensions.width);
        setHeight(dimensions.height);
        setCoordX(dimensions.left);
        setCoordY(dimensions.top);
        showSpotlightStartTime.current = Date.now() + MOUSE_OVER_ACTIVATION_DELAY;
      }
    }, 10);
  });

  const shineOff = () => onMouseOver(); // setSpotlightStatus('OFF');

  const onMouseOver = useEventCallback(() => {
    const canHide = Date.now() > showSpotlightStartTime.current;

    if (canHide && spotlightStatus === 'ON' && spotlightHiddenRef.current === false) {
      spotlightHiddenRef.current = true;
      setShowEndAnimation(true);
    }
  });

  const clearSpotlight = () => {
    setSpotlightStatus('OFF');
    spotlightHiddenRef.current = false;
    setShowEndAnimation(false);
    // after things are removed/hidden reset the position of the spot to be off screen.
    setTimeout(() => {
      setWidth(50);
      setHeight(50);
      setCoordX(0);
      setCoordY(-300);
    }, 10);
  };

  const onRingAnimationEnd = () => clearSpotlight();

  const removeSpotlightOnTap = () => clearSpotlight();

  const Spot = createPortal(
    <Spotlight
      onMouseOver={onMouseOver}
      onClick={removeSpotlightOnTap}
      show={spotlightStatus === 'ON'}
      positionX={coordX}
      positionY={coordY}
      width={width}
      height={height}
      showRingAnimation={showEndAnimation}
      onRingAnimationEnd={onRingAnimationEnd}
      spotlightShape={spotShape}
    />,
    portalContainer,
    'spotlightKey'
  );

  return { Spot, shineOn, shineOff, spotlightStatus };
};
