/* eslint-disable @typescript-eslint/no-explicit-any */
import { db } from '../firebase/Firebase';
import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from 'firebase/firestore';
import { PublicEntityIds, PublicEntityId, PublicEntityT } from 'types/App.types';

import { useStyledLogger } from 'app/config/LogConfig';
import { isPrint } from 'helpers/appHelpers';

const printMsg = useStyledLogger('PublicEntitiesAPI', 'olivedrab', 'cornsilk');
const PRINT_FLAG = true;

// #region CONVERTERS ***********************************************************
const publicEntityToDoc = (publicEntityIds: PublicEntityIds): DocumentData => {
  return {
    publicEntityIds: publicEntityIds,
  };
};

const publicEntityConverter: FirestoreDataConverter<PublicEntityIds> = {
  toFirestore(publicEntityIds: PublicEntityIds): DocumentData {
    return publicEntityToDoc(publicEntityIds);
  },

  fromFirestore(docSnap: QueryDocumentSnapshot, options?: SnapshotOptions): PublicEntityIds {
    const data = docSnap.data(options);
    return data.publicEntityIds as PublicEntityIds;
  },
};
// #endregion

// #region PUBLISHING QUESTION APIs *****************************************************
export const fetchPublicEntityIds = async (entity: PublicEntityT): Promise<PublicEntityIds> => {
  const docRef = doc(db, `public/${entity}`).withConverter(publicEntityConverter);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    isPrint(PRINT_FLAG) && console.log(...printMsg('LOADED', 'publicEntityIds', `${entity}`));
    const publicEntityIds: PublicEntityIds = docSnap.data();
    return publicEntityIds ?? [];
  } else {
    console.error('No such document!');
    throw Error('Doc questions unable to find');
  }
};

export const updatePublicEntityIds = async (entity: PublicEntityT, publicEntityIds: PublicEntityIds): Promise<void> => {
  const entityRef = doc(db, `public/${entity}`);
  return await updateDoc(entityRef, { publicEntityIds: arrayUnion(...publicEntityIds) });
};

export const removePublicEntityId = async (entity: PublicEntityT, publicEntityId: PublicEntityId): Promise<void> => {
  const entityRef = doc(db, `public/${entity}`);
  return await updateDoc(entityRef, { publicEntityIds: arrayRemove(publicEntityId) });
};
