import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks/hooks';

import { CareerItem, CareerItemT, VisualState } from 'types/App.types';
import { CareerChainCard, CareerChainCards } from 'types/BaseCareer.types';
import { isNumber } from 'helpers/mathHelpers';
import {
  doUpdateActiveItem as doUpdateSelectedItem,
  doUpdateCompleteItems,
  getCareerChain,
  getCareerChainActiveIndex,
  getCompletedItemIds,
  getCareerCourseIds,
  setHiddenCourseIds,
} from 'modules/career/careerSlice';
import { getCareerCarouselAutoplay, getCareerCarouselHideCompletedCourses } from 'modules/career/careerUiSlice';
import { getCourseCompletionId } from 'helpers/routeHelpers';
import { CourseId } from 'types/Course.types';

export const useCareerChainCards = () => {
  // #region VARS
  /** **`REDUX`** index of the card that should be played */
  const activeCareerChainCardIndex = useAppSelector(getCareerChainActiveIndex);
  /** Full chain of career items, including non-visible cards like Quiz, Lesson, and Course */
  const careerChain: CareerChainCards = useAppSelector(getCareerChain);
  /** State of autoplay, meaning return to FULL_MODE after each completion, or go to the next question */
  const autoplay = useAppSelector(getCareerCarouselAutoplay);
  /** State of carousel showing all assigned courses, or only courses that haven't been completed */
  const hideCompletedCoursesOnCarousel = useAppSelector(getCareerCarouselHideCompletedCourses);
  /** Filtered view of the career chain, excludes Quiz, Lesson, and Course */
  const [activeChain, setFilteredChain] = useState<CareerChainCards>([]);
  const completed = useAppSelector(getCompletedItemIds);
  const courseIds = useAppSelector(getCareerCourseIds);

  const [searchParams, setSearchParams] = useSearchParams();
  /** Card that is currently being played, does not have to match the active card, because you can replay completed cards */
  const [openCard, setOpenCard] = useState<CareerChainCard>();
  /** Id of the selected (aka Active) card that is yet to be completed  */
  const [selectedCardId, setSelectedCardId] = useState<string>('');
  /** Selected (aka Active) card model that is yet to be completed  */
  const [selectedCard, setSelectedCard] = useState<CareerChainCard>();

  const dispatch = useAppDispatch();

  // Filter for career chain cards */
  const showAllQuestionsTutorialsPredicate = (card: CareerChainCard) =>
    card.type === CareerItem.Question || card.type === CareerItem.Tutorial;
  // Filter for career chain cards without completed courses and all its cards
  const isCompletedCourse = (courseId: CourseId) => completed.includes(getCourseCompletionId(courseId));
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const showIncompleteQuestionsTutorialsPredicate = (card: CareerChainCard) =>
    (card.type === CareerItem.Question || card.type === CareerItem.Tutorial) &&
    !isCompletedCourse(card.submitActionData?.courseId as CourseId);
  // #endregion

  // #region USE_EFFECTS
  // useEffect(() => {
  //   if (careerChain) {
  //     const predicate = hideCompletedCoursesOnCarousel
  //       ? showIncompleteQuestionsTutorialsPredicate
  //       : showAllQuestionsTutorialsPredicate;
  //     setFilteredChain(careerChain.filter(predicate));
  //   }
  // }, [careerChain, hideCompletedCoursesOnCarousel]);

  useEffect(() => {
    if (careerChain) {
      if (hideCompletedCoursesOnCarousel && courseIds) {
        const coursesToHide = courseIds.filter((courseId) => completed.includes(getCourseCompletionId(courseId)));
        dispatch(setHiddenCourseIds(coursesToHide));
      } else if (!hideCompletedCoursesOnCarousel) {
        dispatch(setHiddenCourseIds([]));
      }

      setFilteredChain(careerChain.filter(showAllQuestionsTutorialsPredicate));
    }
  }, [careerChain, hideCompletedCoursesOnCarousel]);

  useEffect(() => {
    if (careerChain && activeCareerChainCardIndex >= 0) {
      const nextSelectedCard: CareerChainCard = careerChain[activeCareerChainCardIndex];
      setSelectedCardId(nextSelectedCard.versionedItemId);
      setSelectedCard(nextSelectedCard);
      if (nextSelectedCard.visualState === 'locked') {
        dispatch(doUpdateSelectedItem(activeCareerChainCardIndex));
      }
    } else if (careerChain.findIndex((card) => card.visualState === 'locked') > -1) {
      const newActiveIndex = careerChain.findIndex((card) => card.visualState === 'locked');
      dispatch(doUpdateSelectedItem(newActiveIndex));
    } else {
      resetSelectedAndOpenCards();
    }
  }, [activeCareerChainCardIndex, careerChain, dispatch]);

  useEffect(() => {
    if (searchParams && searchParams.has('itemIndex')) {
      const selectedIndex = parseInt(String(searchParams.get('itemIndex')));
      setOpenCard(careerChain[selectedIndex]);
    } else {
      setOpenCard(undefined);
    }
  }, [searchParams]);
  // #endregion

  // #region HELPER FUNCTIONS
  const clearSearchParams = () => {
    if (searchParams) {
      if (searchParams.has('itemIndex')) searchParams.delete('itemIndex');
      if (searchParams.has('tabId')) searchParams.delete('tabId');
      setSearchParams(searchParams);
    }
  };

  const getIndexFromSearchParams = (): number => {
    if (searchParams && searchParams.has('itemIndex')) {
      return parseInt(String(searchParams.get('itemIndex')));
    }

    return -1;
  };

  const resetSelectedAndOpenCards = () => {
    setOpenCard(undefined);
    setSelectedCard(undefined);
    setSelectedCardId('');
  };
  // #endregion

  // #region EVENT_HANDLERS

  const openCardEventHandler = (cardIndex?: number) => {
    const selectedCardIndex = cardIndex !== undefined && isNumber(cardIndex) ? cardIndex : activeCareerChainCardIndex;

    if (selectedCardIndex > -1) {
      searchParams.set('itemIndex', String(selectedCardIndex));
      setSearchParams(searchParams);
      const card = careerChain[selectedCardIndex];
      setSelectedCard(card);
      setSelectedCardId(card.versionedItemId);
    } else {
      setSelectedCard(undefined);
      setSelectedCardId('');
    }
  };

  const closeCardEventHandler = () => {
    clearSearchParams();

    if (activeCareerChainCardIndex > -1) {
      const selectedCard: CareerChainCard = careerChain[activeCareerChainCardIndex];
      setSelectedCard(selectedCard);
      setSelectedCardId(selectedCard.versionedItemId);
    }
  };
  // #endregion

  const isCardOfType = (cardIndex: number, cardType: CareerItemT): boolean => {
    if (cardIndex > -1 && cardIndex < careerChain.length) {
      return careerChain[cardIndex].type === cardType;
    }
    return false;
  };

  const isCardQuestionOrTutorial = (cardIndex: number): boolean => {
    return isCardOfType(cardIndex, CareerItem.Question) || isCardOfType(cardIndex, CareerItem.Tutorial);
  };

  const isCompletedCard = (cardIndex: number): boolean => {
    if (cardIndex > -1 && cardIndex < careerChain.length) {
      return careerChain[cardIndex].visualState === VisualState.Completed;
    }
    return true;
  };

  const findNextSelectedCardIndex = (itemsToComplete: Array<number>, startIndex = 0): number => {
    let isFound = false;
    let nextSelectedIndex: number = startIndex;

    while (!isFound && nextSelectedIndex < careerChain.length) {
      if (
        isCardQuestionOrTutorial(nextSelectedIndex) &&
        !isCompletedCard(nextSelectedIndex) &&
        !itemsToComplete.includes(nextSelectedIndex)
      ) {
        isFound = true;
      } else {
        if (!isCompletedCard(nextSelectedIndex) && !itemsToComplete.includes(nextSelectedIndex)) {
          itemsToComplete.push(nextSelectedIndex);
        }
        nextSelectedIndex++;
      }
    }

    return isFound ? nextSelectedIndex : -1;
  };

  const selectNextCard = (careerType: CareerItemT) => {
    const itemsToComplete: Array<number> = [];
    // what did use just finish?
    const openCardIndex = getIndexFromSearchParams();

    // was the finished card index match the activeCareerChainCardIndex?
    const isMatchingIndex: boolean = openCardIndex === activeCareerChainCardIndex;

    // find next index to mark as selected
    if (isMatchingIndex && !isCompletedCard(openCardIndex)) {
      itemsToComplete.push(openCardIndex);
    }

    // was the finished card tutorial?
    const isLastCardTutorial: boolean = careerType === CareerItem.Tutorial;
    const nextSelectedIndex = findNextSelectedCardIndex(itemsToComplete);

    if (nextSelectedIndex > -1) {
      dispatch(doUpdateSelectedItem(nextSelectedIndex));
      const isNextCardTutorial: boolean = careerChain[nextSelectedIndex].type === CareerItem.Tutorial;

      if (isLastCardTutorial || isNextCardTutorial || !autoplay) {
        clearSearchParams();
        setOpenCard(undefined);
      } else {
        searchParams.set('itemIndex', String(nextSelectedIndex));
        setSearchParams(searchParams);
      }
    } else {
      // finished all available cards
      clearSearchParams();
      resetSelectedAndOpenCards();
    }

    dispatch(doUpdateCompleteItems(itemsToComplete));
  };

  const isOpenCardTypeTutorial = () => openCard && openCard.submitActionData?.type === CareerItem.Tutorial;
  const isOpenCardTypeQuestion = () => openCard && openCard.submitActionData?.type === CareerItem.Question;

  return {
    openCard,
    closeCardEventHandler,
    openCardEventHandler,
    isOpenCardTypeTutorial,
    isOpenCardTypeQuestion,
    activeChain,
    selectedCard,
    selectedCardId,
    selectedCardIndex: activeCareerChainCardIndex,
    selectNextCard,
  };
};
