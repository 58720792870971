import React, { useEffect, useState } from 'react';
import { VoidFunc } from 'types/App.types';

import RoundedButton from 'components/buttons/rounded/RoundedButton';

import styles from './FloatingButton.module.scss';

type FloatingButtonProps = {
  buttonLabel?: string;
  className?: string;
  icon?: string;
  onClick?: VoidFunc;
  mainColor?: boolean;
  showLabel?: boolean;
  showButton?: boolean;
  floatRight?: boolean;
  visible?: boolean;
};

const FloatingButton: React.FC<FloatingButtonProps> = ({
  className = '',
  mainColor = true,
  icon = 'add_task',
  buttonLabel = 'UPDATE',
  showLabel = false,
  showButton = true,
  floatRight = true,
  visible = true,
  ...props
}) => {
  const floatCls = floatRight ? styles.Right : styles.Left;
  const [animationCls, setAnimationCls] = useState<string>('');

  useEffect(() => {
    if (!visible) {
      setAnimationCls(styles.Hide);
      return;
    }
    if (showButton) {
      setAnimationCls(styles.Show);
    } else if (animationCls === styles.Show) {
      setAnimationCls(styles.Hide);
    } else {
      setAnimationCls('');
    }
  }, [showButton, visible]);

  return (
    <RoundedButton
      className={`${styles.FloatingActionButton} ${animationCls} ${floatCls} ${className}`}
      useMaxHeight={false}
      mainColor={mainColor}
      onClick={props.onClick}
      icon={icon}>
      {showLabel ? buttonLabel : ''}
    </RoundedButton>
  );
};

export default FloatingButton;
