import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { memoize } from 'proxy-memoize';
import { Dictionary, StatusT } from 'types/App.types';
import { LockedItemT, LockedType, UnlockingItemT, UnlocksT, UnlockT } from 'types/Unlocks.types';

export type UnlocksState = {
  appUnlocksStatus: StatusT;
  appUnlocks: UnlocksT;
};

const initialState: UnlocksState = {
  appUnlocksStatus: 'idle',
  appUnlocks: [],
};

export const unlocksSlice = createSlice({
  name: 'unlocks',
  initialState,
  reducers: {
    setAppUnlocks: (state, action: PayloadAction<UnlocksT>) => {
      state.appUnlocks = action.payload;
    },
    setUnlockAppStatus: (state, action: PayloadAction<StatusT>) => {
      state.appUnlocksStatus = action.payload;
    },
    resetUnlocks: () => {
      return { ...initialState };
    },
  },
});

export const { setAppUnlocks, setUnlockAppStatus, resetUnlocks } = unlocksSlice.actions;

export const getAppUnlocks = (state: RootState): UnlocksT => state.unlocks.appUnlocks;
export const getAppUnlocksStatus = (state: RootState): StatusT => state.unlocks.appUnlocksStatus;
export const getUnlockingItemMap = memoize((state: RootState): Dictionary<string> => {
  const unlockingMap: Dictionary<string> = {};
  state.unlocks.appUnlocks.forEach((item: UnlockT) => {
    item.unlockedBy.forEach((val) => (unlockingMap[val.itemId] = item.lockedItem.itemId));
  });
  return unlockingMap;
});
export const getLockedItemMap = memoize((state: RootState): Dictionary<string[]> => {
  const lockedMap: Dictionary<string[]> = {};
  state.unlocks.appUnlocks.forEach((item: UnlockT) => {
    lockedMap[item.lockedItem.itemId] = item.unlockedBy.map((val) => val.itemId);
  });
  return lockedMap;
});

export type UnlockMapItemT = {
  unlock: UnlockT;
  unlockingItems: Dictionary<UnlockingItemT>;
  lockedItem: LockedItemT;
  unlockWith: Array<string>;
};

export type UnlockMapT = Dictionary<UnlockMapItemT>;

export const getLockedGamesMap = memoize((state: RootState): Dictionary<UnlockMapItemT> => {
  const games = getAppUnlocks(state).filter((ul: UnlockT) => (ul.lockedItem.itemType as LockedType) === 'game');
  const gamesMap: Dictionary<UnlockMapItemT> = {};
  games.forEach((g) => {
    const unlockingItems: Dictionary<UnlockingItemT> = {};
    const unlockWith: Array<string> = [];
    g.unlockedBy.forEach((ul) => {
      unlockingItems[ul.itemId] = ul;
      unlockWith.push(ul.itemTitle);
    });
    gamesMap[g.lockedItem.itemId] = {
      unlock: g,
      unlockingItems,
      lockedItem: g.lockedItem,
      unlockWith,
    };
  });

  return gamesMap;
});

export default unlocksSlice.reducer;
