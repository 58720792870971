import React from 'react';

import { PropsWithChildren } from 'types/App.types';

import styles from './Controls.module.scss';

type OwnProps = {
  className?: string;
  items?: Array<unknown>;
} & Pick<React.HTMLAttributes<HTMLDivElement>, 'onClick'>;

type CarouselProps = PropsWithChildren<OwnProps>;

const Controls: React.FC<CarouselProps> = (props) => {
  return <div className={styles.Controls}>{props.children}</div>;
};

export default Controls;
