import { db } from '../firebase/Firebase';
import {
  collection,
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  doc,
  setDoc,
} from 'firebase/firestore';
import { getFormattedString, getNameClsString } from 'app/config/LogConfig';
import { ErrorLogT } from 'types/App.types';
import { getModifiedInfo } from 'helpers/careerHelpers';

export const PRIMARY_COLOR = getNameClsString('dodgerblue', 'cornsilk');
export const CLS_NAME = 'ErrorAPI';

// #region CONVERTERS ********************************************************************
const errorLogToDoc = (errorLog: ErrorLogT): DocumentData => {
  return {
    errorName: errorLog.errorName,
    fromRoute: errorLog.fromRoute,
    errorId: errorLog.errorId,
    cause: errorLog.cause,
    message: errorLog.message,
    code: errorLog.code ?? 'unknown',
    stack: errorLog.stack,
    time: errorLog.time,
    location: errorLog.location,
    email: errorLog.email,
    name: errorLog.name,
    uid: errorLog.uid,
    activeProfile: errorLog.activeProfile,
    profilesCount: errorLog.profilesCount,
    admin: errorLog.admin,
    editor: errorLog.editor,
    status: errorLog.status,
    activeScene: errorLog.activeScene,
  };
};

export const errorLogConverter: FirestoreDataConverter<ErrorLogT> = {
  toFirestore(errorLog: ErrorLogT): DocumentData {
    return { ...errorLogToDoc(errorLog), ...getModifiedInfo() };
  },

  fromFirestore(docSnap: QueryDocumentSnapshot, options?: SnapshotOptions): ErrorLogT {
    const errorLog = docSnap.data(options);
    return {
      errorName: errorLog.errorName,
      fromRoute: errorLog.fromRoute,
      errorId: errorLog.errorId,
      cause: errorLog.cause,
      message: errorLog.message,
      code: errorLog.code,
      stack: errorLog.stack,
      time: errorLog.time,
      location: errorLog.location,
      email: errorLog.email,
      name: errorLog.name,
      uid: errorLog.uid,
      activeProfile: errorLog.activeProfile,
      profilesCount: errorLog.profilesCount,
      admin: errorLog.admin,
      editor: errorLog.editor,
      status: errorLog.status,
      activeScene: errorLog.activeScene,
    };
  },
};

const printMsg = (action: string, prop: string, status: string) =>
  getFormattedString(PRIMARY_COLOR, CLS_NAME, action, prop, status);

export const createErrorLog = async (errorItem: ErrorLogT): Promise<ErrorLogT> => {
  const collectionData = collection(db, 'errors').withConverter(errorLogConverter);
  const docRef = doc(collectionData);
  const createdErrorLogEntry: ErrorLogT = { ...errorItem, errorId: docRef.id };
  // Create new record with autogenerated id as errorId
  await setDoc(docRef, createdErrorLogEntry);
  console.log(...printMsg('Created ErrorLogEntry', 'errorId', docRef.id));
  return createdErrorLogEntry;
};
