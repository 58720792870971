import React, { useState } from 'react';
import { useCompleteNotificationDismissTracker } from 'app/hooks/useCompleteNotificationDismissTracker';
import IconButton from 'components/buttons/icon/IconButton';

type ShowCompletionNotificationProps = {
  className?: string;
};

const ShowCompletionNotification: React.FC<ShowCompletionNotificationProps> = () => {
  const [notificationOpen, setNotificationOpen] = useState<boolean>(false);
  const onNotificationClose = () => {
    setNotificationOpen(false);
  };

  const { dispatchShowNotification } = useCompleteNotificationDismissTracker(onNotificationClose);

  const showNotification = () => {
    setNotificationOpen(true);
    dispatchShowNotification('COLLECT', 'Notification Title', 'thumb_up', 'Notification BODY text');
  };

  return <IconButton icon="circle_notifications" isFilled={notificationOpen} onClick={showNotification} />;
};

export default ShowCompletionNotification;
