import React from 'react';
import InsetText from 'components/text/inset/InsetText';

import styles from './PageNotFound.module.scss';
import { AppImages } from 'app/constants/ImageUrls';

const PageNotFound: React.FC = () => {
  return (
    <div className={styles.Display}>
      <div style={{ position: 'relative' }}>
        <InsetText style={{ letterSpacing: '5px' }} size="xl7">404</InsetText>
        <img className={styles.MonkeyThinking} src={AppImages.Urls.MonkeyThinking} />
      </div>
      <InsetText size="xl5">PAGE NOT FOUND</InsetText>
    </div>
  );
};

export default PageNotFound;
