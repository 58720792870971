import { IExpandedProfile, IUser, IProfile, IUsers, ProfilesT, IExpandedProfiles } from 'types/User.types';

export const getExtendedUserProfiles = (users: IUsers, profiles: ProfilesT): IExpandedProfiles => {
  return profiles.map((p: IProfile) => {
    const user = users.find((u) => u.uid === p.uid);
    const createdByUser = users.find((u) => u.uid === p.createdBy);
    if (user && createdByUser) {
      return { ...p, user: user, createdByUser: createdByUser } as IExpandedProfile;
    }

    const notFoundUser: IUser = {
      uid: p.uid,
      activeProfileId: '',
      userPassKey: '',
      userPassKeyEnabled: false,
      userLevel: 0,
      createdBy: p.uid,
      createdOn: 0,
      email: '',
      emailVerified: false,
      firstname: '',
      isAdmin: false,
      isEditor: false,
      lastname: '',
      profileIds: [],
    };
    return {
      ...p,
      user: notFoundUser,
      createdByUser: notFoundUser,
    };
  });
};
