import { AppThunk, RootState } from 'app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Animations, AnimationT, MaybeType, StatusT } from 'types/App.types';
import { CellT } from 'types/Board.types';
import { QuizT } from 'types/Quiz.types';
import { QuestionT, QuestionsT } from 'types/Question.types';

export interface QuizState extends QuizT {
  quizId: string;
  quizTitle: string;
  userId: string;
  level: number;

  currentQuestion?: QuestionT;
  currentQuestionIndex: number;
  currentUserAnswer?: CellT;
  resultStatus: AnimationT;
  startTime: number;
  endTime: number;
  quizStatus: StatusT;
}

export const processUserAnswer =
  (answer: CellT): AppThunk =>
  (dispatch, getState) => {
    const quizState = getState().quiz;
    const question = quizState.currentQuestion as QuestionT;
    const resultStatus = answer.value === question.expectedResult ? Animations.Pass : Animations.Fail;
    dispatch(setProcessedAnswer({ currentUserAnswer: answer, resultStatus }));
  };

export const processNextQuestion = (): AppThunk => (dispatch, getState) => {
  const questionsCount = getQuestionsCount(getState());
  const nextQuestionIndex = getCurrentQuestionIndex(getState()) + 1;

  if (nextQuestionIndex < questionsCount) {
    const questions = getQuestions(getState()) as QuestionsT;
    const nextQuestion = questions[nextQuestionIndex] as QuestionT;
    dispatch(
      setNextQuestion({
        currentUserAnswer: undefined,
        currentQuestion: nextQuestion,
        currentQuestionIndex: nextQuestionIndex,
        resultStatus: 'none',
      })
    );
  } else {
    dispatch(doEndQuiz());
    dispatch(resetCurrent());
    dispatch(setResultStatus('none'));
  }
};

export const doStartQuiz = (): AppThunk => (dispatch) => {
  dispatch(setStartTime(Date.now()));
  dispatch(processNextQuestion());
};

export const doEndQuiz = (): AppThunk => async (dispatch) => {
  const endTime = Date.now();
  dispatch(setEndTime(endTime));
};

export const doInitQuiz =
  (quiz: QuizT): AppThunk =>
  async (dispatch) => {
    dispatch(resetQuiz());
    dispatch(setQuiz(quiz));
  };

const initialState: QuizState = {
  quizId: '0',
  userId: '0',
  quizStatus: 'idle',
  level: 1,
  quizTitle: '',
  quizCategory: 'unknown',
  // questions: [],
  currentQuestion: undefined,
  currentQuestionIndex: -1,
  currentUserAnswer: undefined,
  resultStatus: 'none',
  startTime: Date.now(),
  endTime: 0,
  quizLevel: 0,
  createdBy: '',
  createdOn: 0,
  badgeId: '',
  versionedQuestionIds: [],
  versionId: '',
};

type NextQuestionAction = {
  currentUserAnswer: MaybeType<CellT>;
  currentQuestion: QuestionT;
  currentQuestionIndex: number;
  resultStatus: AnimationT;
};
type ProcessedAnswerAction = {
  currentUserAnswer: CellT;
  resultStatus: AnimationT;
};

export const quizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    setQuiz: (state, action: PayloadAction<QuizT>) => {
      state.quizId = action.payload.quizId;
      state.questions = action.payload.questions;
      state.level = action.payload.quizLevel;
      state.quizTitle = action.payload.quizTitle;
    },
    setQuizId: (state, action: PayloadAction<string>) => {
      state.quizId = action.payload;
    },
    setQuizStatus: (state, action: PayloadAction<StatusT>) => {
      state.quizStatus = action.payload;
    },
    setQuestions: (state, action: PayloadAction<QuestionsT>) => {
      state.questions = action.payload;
    },
    setCurrentQuestion: (state, action: PayloadAction<QuestionT>) => {
      state.currentQuestion = action.payload;
    },
    setCurrentQuestionIndex: (state, action: PayloadAction<number>) => {
      state.currentQuestionIndex = action.payload;
    },
    setNextQuestion: (state, action: PayloadAction<NextQuestionAction>) => {
      state.currentQuestion = action.payload.currentQuestion;
      state.currentQuestionIndex = action.payload.currentQuestionIndex;
      state.currentUserAnswer = action.payload.currentUserAnswer;
      state.resultStatus = action.payload.resultStatus;
    },
    setProcessedAnswer: (state, action: PayloadAction<ProcessedAnswerAction>) => {
      state.currentUserAnswer = action.payload.currentUserAnswer;
      state.resultStatus = action.payload.resultStatus;
    },
    setCurrentUserAnswer: (state, action: PayloadAction<MaybeType<CellT>>) => {
      state.currentUserAnswer = action.payload;
    },
    setResultStatus: (state, action: PayloadAction<AnimationT>) => {
      state.resultStatus = action.payload;
    },
    setStartTime: (state, action: PayloadAction<number>) => {
      state.startTime = action.payload;
    },
    setEndTime: (state, action: PayloadAction<number>) => {
      state.endTime = action.payload;
    },
    resetCurrent: (state) => {
      state.currentQuestionIndex = -1;
      state.currentQuestion = undefined;
      state.currentUserAnswer = undefined;
    },
    restartQuiz: (state) => {
      if (state.questions) {
        state.resultStatus = 'none';
        state.currentQuestionIndex = 0;
        state.currentQuestion = state.questions[0];
        state.currentUserAnswer = undefined;
        state.startTime = 0;
        state.endTime = 0;
      } else {
        return { ...initialState };
      }
    },
    resetQuiz: () => {
      return { ...initialState };
    },
  },
});

export const {
  setQuiz,
  setQuizStatus,
  setQuestions,
  setCurrentQuestion,
  setCurrentQuestionIndex,
  setProcessedAnswer,
  setCurrentUserAnswer,
  setResultStatus,
  setNextQuestion,
  setStartTime,
  setEndTime,
  setQuizId,
  resetCurrent,
  restartQuiz,
  resetQuiz,
} = quizSlice.actions;

export const getQuizId = (state: RootState): string => state.quiz.quizId;
export const getQuestions = (state: RootState): MaybeType<QuestionsT> => state.quiz.questions;
export const getQuestionsCount = (state: RootState): number => (state.quiz.questions ? state.quiz.questions.length : 0);
export const getQuizStartTime = (state: RootState): number => state.quiz.startTime;
export const getQuizEndTime = (state: RootState): number => state.quiz.endTime;
export const getCurrentQuestionIndex = (state: RootState): number => state.quiz.currentQuestionIndex;
export const getCurrentQuestion = (state: RootState): MaybeType<QuestionT> => state.quiz.currentQuestion;
export const getCurrentUserAnswer = (state: RootState): MaybeType<CellT> => state.quiz.currentUserAnswer;
export const getResultStatus = (state: RootState): AnimationT => state.quiz.resultStatus;
export const getQuizStatus = (state: RootState): StatusT => state.quiz.quizStatus;

export default quizSlice.reducer;
