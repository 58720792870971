/* eslint-disable @typescript-eslint/no-explicit-any */
import { store } from 'app/store';
import { redirect } from 'react-router-dom';
import { db } from '../firebase/Firebase';
import { collection, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from 'firebase/firestore';

import { CareerHistoryResponseT, CareerT } from 'types/Career.types';
import { CourseIds, CoursesT } from 'types/Course.types';
import {
  IUser,
  IProfile,
  IExpandedProfile,
  IUsers,
  ProfileId,
  ProfilesT,
  UserProfilePayload,
  UserProfileCoursesPayload,
} from 'types/User.types';

import { sortCourses, sortProfiles } from 'helpers/sortHelpers';
import { getExtendedUserProfiles } from 'helpers/profileHelpers';
import { getModifiedInfo } from 'helpers/careerHelpers';

import { doSetActiveScene, setLastActiveManageScene } from 'scenes/scenesSlice';
import {
  getUser,
  getUserStatus,
  doSetActiveProfileId,
  updateUserProfile,
  performUserLogout,
  // getProfileTitle,
} from 'modules/user/userSlice';
import { setToastNotification } from 'modules/user/userUiSlice';
import {
  addManagedProfile,
  getManagedUserById,
  getProfileById,
  getProfileIndexById,
  setNewProfileCourses,
  setNewProfiles,
  setProfiles,
  updateManagedUser,
} from 'scenes/management/managerSlice';
import { receiveAward } from 'modules/profiles/awards/awardsSlice';

import { AwardCodes } from 'app/constants/AwardCodes';
import { useStyledLogger } from 'app/config/LogConfig';

import { fetchPublicCourses, getCourses } from './CoursesAPI';
import { setCareerCourseIds } from './CareerAPI';
import { fetchUserInfo, fetchUsers, updateUserAccount } from './UserAPI';

import { profileConverter, profileToDoc, getInitialUserProfile } from 'api/converters/ProfilesConverter';
import { careerConverter } from 'api/converters/CareerConverter';
import { getToastErrorPayload, getToastSuccessPayload } from 'helpers/uiHelpers';
import { isPrint } from 'helpers/appHelpers';
import { MaybeType } from 'types/App.types';
import { AppRoutes } from 'app/constants/Routes';
import { reportCardDataAutoFixer } from 'api/HistoryAPI';

const PRIMARY_COLOR = 'violet';
const SECONDARY_COLOR = 'cornsilk';
const CLS_NAME = 'ProfileAPI';
const PRINT_FLAG = true;

const printMsg = useStyledLogger(CLS_NAME, PRIMARY_COLOR, SECONDARY_COLOR);

// #region APIs **************************************************************************
type CareerProfileResponseT = {
  career: CareerT;
  profile: IProfile;
};
export const createProfileWithCareer = async (
  userProfile: IProfile,
  courses?: CoursesT
): Promise<CareerProfileResponseT> => {
  const profilesCollection = collection(db, 'profiles').withConverter(profileConverter);
  const careersCollection = collection(db, 'careers').withConverter(careerConverter);
  // Autogenerate id for this record
  const profilesDocRef = doc(profilesCollection);
  const careersDocRef = doc(careersCollection);

  const createdProfile: IProfile = { ...userProfile, profileId: profilesDocRef.id, careerId: careersDocRef.id };
  const createdCareer: CareerT = {
    careerId: careersDocRef.id,
    userId: userProfile.uid,
    profileId: profilesDocRef.id,
    courseIds: createdProfile.courseIds ?? [],
        courses: courses ?? [],
    badges: [],
    awardCodes: [],
    awardMedals: [],
    awardGames: [],
    complete: [],
    createdOn: userProfile.createdOn,
    createdBy: userProfile.createdBy,
    modifiedOn: userProfile.modifiedOn,
    modifiedBy: userProfile.modifiedBy,
  };
  // Create new record with autogenerated id as profileId
  await setDoc(profilesDocRef, createdProfile);
  await setDoc(careersDocRef, createdCareer);
  isPrint(PRINT_FLAG) && console.log(...printMsg('Creating Student with Career', 'id', profilesDocRef.id));

  return {
    profile: { ...createdProfile },
    career: { ...createdCareer },
  } as CareerProfileResponseT;
};

export const createUserProfile = async (userProfile: IProfile): Promise<IProfile> => {
  const collectionData = collection(db, 'profiles').withConverter(profileConverter);
  // Autogenerate id for this record
  const docRef = doc(collectionData);
  const createdProfile: IProfile = { ...userProfile, profileId: docRef.id };
  // Create new record with autogenerated id as profileId
  await setDoc(docRef, createdProfile);
  isPrint(PRINT_FLAG) && console.log(...printMsg('Created User&Profile', 'id', docRef.id));

  return createdProfile as IProfile;
};

export const patchUserProfile = async (userProfile: IProfile): Promise<void> => {
  const profileRef = doc(db, `profiles/${userProfile.profileId}`);
  return await updateDoc(profileRef, profileToDoc(userProfile));
};

export const fetchProfilesByUserId = async (uid: string): Promise<ProfilesT> => {
  const collectionData = collection(db, 'profiles').withConverter(profileConverter);
  const q = query(collectionData, where('uid', '==', uid));

  const querySnapshot = await getDocs(q);
  const profiles: ProfilesT = [];
  querySnapshot.docs.forEach((doc) => {
    const docData = doc.data();
    profiles.push(docData);
  });

  return profiles;
};

export const fetchUserProfiles = async (profileIds: Array<ProfileId> = []): Promise<ProfilesT> => {
  const collectionData = collection(db, 'profiles').withConverter(profileConverter);
  let q = query(collectionData);

  if (profileIds.length > 0) {
    q = query(collectionData, where('profileId', 'in', [...profileIds]));
  }

  const querySnapshot = await getDocs(q);
  isPrint(PRINT_FLAG) && console.log(...printMsg('fetchUserProfiles', 'Profiles Count', String(querySnapshot.size)));

  const profiles: ProfilesT = [];
  querySnapshot.docs.forEach((doc) => {
    const docData = doc.data();
    profiles.push(docData);
  });

  return profiles;
};

export const fetchUserProfile = async (profileId: ProfileId): Promise<IProfile> => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('Loading User&Profile', 'ProfileId', String(profileId)));

  const docRef = doc(db, `profiles/${profileId}`).withConverter(profileConverter);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const userProfile: IProfile = docSnap.data();
    return userProfile;
  } else {
    console.error('No such document!');
    isPrint(PRINT_FLAG) && console.log(...printMsg('fetchUserProfile', 'User does not exist', ''));
    store.dispatch(performUserLogout());
    throw Error(`Profile (${profileId}) unable to find`);
  }
};
// #endregion

// #region     CAREER ROUTER LOADER & ACTION FUNCTIONS ***********************************
export const ownUserAccountSceneLoader = async ({ params }: any): Promise<UserProfilePayload> => {
  const statusValues = `${params.accountId}:${params.profileId}`;
  isPrint(PRINT_FLAG) && console.log(...printMsg('userAccountSceneLoader', 'account:profile', statusValues));
  store.dispatch(doSetActiveScene('account:profile:edit'));

  if (getUserStatus(store.getState()) === 'signedIn') {
    return loadProfileAndPublicCourses(params.profileId);
  }

  throw redirect(AppRoutes.Login);
};

export const loadProfileAndPublicCourses = async (profileId: ProfileId): Promise<UserProfilePayload> => {
  const courses: CoursesT = await fetchPublicCourses();
  const user: IUser = getUser(store.getState());
  const index = store.getState().user.profiles.findIndex((p: IProfile) => p.profileId === profileId);
  const profile = store.getState().user.profiles[index];
  const userProfile: IExpandedProfile = { ...profile, user, createdByUser: user };

  return { courses, userProfile, index };
};

export const updateOwnUserAccountSceneAction = async (profileId: ProfileId): Promise<IProfile> => {
  const userProfiles = store.getState().user.profiles;
  const indexOfEditedItem = userProfiles.findIndex((p) => p.profileId === profileId);
  const userProfileItem = userProfiles[indexOfEditedItem] as IProfile;
  const modified = getModifiedInfo();
  const toEditUserProfile: IProfile = {
    ...userProfileItem,
    uid: store.getState().user.uid,
    ...modified,
  };

  await setCareerCourseIds(toEditUserProfile.careerId, toEditUserProfile.courseIds);
  await patchUserProfile(toEditUserProfile);
  store.dispatch(updateUserProfile({ userProfile: toEditUserProfile, index: indexOfEditedItem }));
  store.dispatch(setToastNotification(getToastSuccessPayload('Profile Updated')));
  return toEditUserProfile;
};

export const ownUserAccountSceneAction = async ({ request, params }: any): Promise<Response> => {
  const url = new URL(request.url);
  const icon = url.searchParams.get('icon');
  const profileId = params.profileId;

  await updateOwnUserAccountSceneAction(profileId);
  if (icon === 'updated') {
    store.dispatch(receiveAward(AwardCodes.Code0004));
  }
  return redirect(AppRoutes.Hub, { statusText: 'Profile Updated' });
};

export const activeProfileCoursesLoader = async ({ params }: any): Promise<UserProfileCoursesPayload> => {
  const statusValues = `${params.accountId}:${params.profileId}`;
  const statusNames = 'account:profile';
  isPrint(PRINT_FLAG) && console.log(...printMsg('LOAD activeProfileCoursesLoader', statusNames, statusValues));

  if (getUserStatus(store.getState()) === 'signedIn') {
    const activeProfile = store.getState().user.profiles.find((p) => p.profileId === params.profileId);
    const courseIds: CourseIds = activeProfile ? activeProfile.courseIds : [];
    const courses: CoursesT = await getCourses(courseIds);
    courses.sort(sortCourses);

    return { courses, status: 'loaded' };
  }

  return { courses: [], status: 'error' };
};

export const authUserCreateProfileSceneLoader = async (): Promise<UserProfilePayload> => {
  store.dispatch(doSetActiveScene('account:profile:create'));
  const userProfile = getInitialUserProfile();
  const user: IUser = store.getState().user;
  const courses: CoursesT = await fetchPublicCourses();
  const newProfileCourses = courses.slice(0, 1);
  store.dispatch(setNewProfileCourses(newProfileCourses));

  return { courses: newProfileCourses, userProfile: { ...userProfile, user: user, createdByUser: user }, index: -1 };
};

/**
 * Action is mapped to `/account/:userId/profile/create`
 * Creates a profile for the currently logged in user
 *
 * TODO: @Andrey11 Add max allowed profiles check/handler
 *
 * @return {Promise<Response>} returns response (OK, ERROR) promise after the request completes.
 */
export const authUserCreateProfileSceneAction = async (): Promise<Response> => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('manageCreateProfileSceneAction', 'Creating UserProfile', ''));
  const user = store.getState().user;
  const userProfiles = user.profiles;
  const indexOfCreatedItem = userProfiles.findIndex((p) => p.profileId === 'createUserProfileId');
  const userProfileItem = userProfiles[indexOfCreatedItem] as IProfile;
  const toCreateUserProfile: IProfile = {
    ...userProfileItem,
    createdOn: Date.now(),
    modifiedOn: Date.now(),
  };

  const newProfileCourses = store.getState().manager.newProfileCourses;

  const { profile } = await createProfileWithCareer(toCreateUserProfile, newProfileCourses);
  store.dispatch(updateUserProfile({ userProfile: profile, index: indexOfCreatedItem }));
  const profileIds = store.getState().user.profiles.map((p) => p.profileId);
  store.dispatch(doSetActiveProfileId(profile.profileId));
  await updateUserAccount({ ...user, activeProfileId: profile.profileId, profileIds: profileIds.slice() });
  store.dispatch(setToastNotification(getToastSuccessPayload('Profile Created')));

  return redirect(AppRoutes.Hub);
};

// #region       EDITOR ROUTER LOADER & ACTION FUNCTIONS *********************************
export const manageProfileCollectionSceneLoader = async () => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('manageProfileCollectionSceneLoader', 'ProfileCollectionScene', ''));
  store.dispatch(doSetActiveScene('manage:profile:collection'));
  store.dispatch(setLastActiveManageScene('profile'));

  const profiles: ProfilesT = await fetchUserProfiles([]);
  const users: IUsers = await fetchUsers();

  const profilesWithUserData: Array<IExpandedProfile> = getExtendedUserProfiles(users, profiles);
  profilesWithUserData.sort(sortProfiles);

  store.dispatch(setProfiles({ collection: profilesWithUserData, status: 'loaded' }));
  return profilesWithUserData;
};

export const manageEditProfileByUserIdLoader = async ({ params }: any): Promise<UserProfilePayload> => {
  const userId = params.accountId;
  const profileId = params.profileId;
  const name = 'manageEditProfileByUserIdLoader';
  const label = 'User/Profile';
  const value = `${userId}/${profileId}`;
  isPrint(PRINT_FLAG) && console.log(...printMsg(name, label, value));
  store.dispatch(doSetActiveScene('manage:user:profile:edit'));

  const courses = await fetchPublicCourses();
  store.dispatch(setNewProfileCourses(courses));

  const user: MaybeType<IUser> = getManagedUserById(store.getState(), params.accountId);
  if (user && user.profileIds.includes(profileId)) {
    const userProfile: MaybeType<IExpandedProfile> = getProfileById(store.getState(), profileId);
    if (userProfile) {
      const index = getProfileIndexById(store.getState(), profileId);
      return { courses, userProfile, index };
    }
    isPrint(PRINT_FLAG) && console.log(...printMsg(name, 'Profile NOT found in REDUX store', ''));
    const profile = await fetchUserProfile(profileId);
    const fetchedProfile = { ...profile, createdByUser: user, user: user };
    store.dispatch(addManagedProfile(fetchedProfile));
    const fetchedProfileIndex = store.getState().manager.profiles.length - 1;
    return { courses, userProfile: fetchedProfile, index: fetchedProfileIndex };
  }

  store.dispatch(setToastNotification(getToastErrorPayload('Could Not Load Profile')));
  throw redirect(AppRoutes.ManagerUsers);
};

export const manageEditProfileByUserIdAction = async ({ params }: any): Promise<Response> => {
  const userId = params.accountId;
  const profileId = params.profileId;
  const profile: MaybeType<IExpandedProfile> = getProfileById(store.getState(), profileId);
  if (profile) {
    await patchUserProfile(profile);
    await setCareerCourseIds(profile.careerId, profile.courseIds);

    store.dispatch(setToastNotification(getToastSuccessPayload('Profile Updated')));
    return redirect(AppRoutes.ManagerUserEdit.replace(':accountId', userId));
  }

  store.dispatch(setToastNotification(getToastErrorPayload('Could Not Find Profile')));
  return redirect(AppRoutes.ManagerUsers);
};

export const manageCreateProfileByUserIdLoader = async ({ params }: any): Promise<UserProfilePayload> => {
  const userId = params.accountId;
  store.dispatch(doSetActiveScene('manage:user:profile:create'));
  const userProfile = getInitialUserProfile(userId);
  const authUser: IUser = store.getState().user;
  const userToAddProfile: IUser = userId === authUser.uid ? authUser : await fetchUserInfo(userId);
  const courses: CoursesT = await fetchPublicCourses();
  const newProfileCourses = courses.slice(0, 1);
  store.dispatch(setNewProfileCourses(newProfileCourses));
  store.dispatch(setNewProfiles([{ ...userProfile, user: userToAddProfile, createdByUser: authUser }]));

  return {
    courses: newProfileCourses,
    userProfile: { ...userProfile, user: userToAddProfile, createdByUser: authUser },
    index: -1,
  };
};

/**
 * Action is mapped to `manage/user/accountId/create`
 * Creates a profile for the currently logged in user
 *
 * TODO: @Andrey11 Add max allowed profiles check/handler
 *
 * @return {Promise<Response>} returns response (OK, ERROR) promise after the request completes.
 */
export const manageCreateProfileByUserIdAction = async ({ params }: any): Promise<Response> => {
  const userId = params.accountId;
  const newProfile: IExpandedProfile = store.getState().manager.newProfiles[0];
  if (newProfile) {
    const toCreateUserProfile: IExpandedProfile = {
      ...newProfile,
      modifiedOn: Date.now(),
    };
    const newProfileCourses = store.getState().manager.newProfileCourses;
    const { profile } = await createProfileWithCareer(toCreateUserProfile, newProfileCourses);
    const user = store.getState().manager.users.find((u) => u.uid === userId);
    if (user) {
      const updatedProfileIds = user.profileIds.concat(profile.profileId);
      const userToUpdate: IUser = { ...user, profileIds: updatedProfileIds };
      await updateUserAccount(userToUpdate);
      store.dispatch(updateManagedUser(userToUpdate));
      store.dispatch(setToastNotification(getToastSuccessPayload('Profile Created')));
      return redirect(AppRoutes.ManagerUserEdit.replace(':accountId', user.uid));
    }
  }

  store.dispatch(setToastNotification(getToastErrorPayload('Could Not Create Profile')));
  return redirect(AppRoutes.Hub);
};

export const manageProfileByUserIdReportCardLoader = async ({ params }: any): Promise<CareerHistoryResponseT> => {
  const userId = params.accountId;
  const profileId = params.profileId;
  const careerId = params.careerId;
  const name = 'manageProfileByUserIdReportCardLoader';
  const label = 'User/Profile/Career';
  const value = `${userId.slice(0, 5)}.../${profileId.slice(0, 3)}.../${careerId.slice(0, 3)}...`;
  isPrint(PRINT_FLAG) && console.log(...printMsg(name, label, value));
  store.dispatch(doSetActiveScene('manage:user:profile:career:reportcard'));

  return await reportCardDataAutoFixer(profileId, careerId);
};

// #endregion

// #endregion
