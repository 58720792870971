import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks/hooks';
import { useSpotlight } from 'app/hooks/useSpotlight';

import { TutorialHelpProps } from 'types/Tutorial.types';

import { getShowCareerHubHelpPanel, setShowCareerHubHelpPanel } from 'scenes/montessori/appUiSlice';
import { defaultHelpText } from 'modules/tutorial/help/scenes/components/description/DescriptionText';
import CareerHubAwards from 'modules/tutorial/help/features/CareerHubAwards';
import CareerHubCourses from 'modules/tutorial/help/features/CareerHubCourses';
import HelpPopup from 'modules/tutorial/help/scenes/components/helppopup/HelpPopup';
import MeTabs from 'components/tabs/MeTabs';

type CareerHubHelpPanelProps = {
  className?: string;
};

const portalContainer: HTMLElement | null = document.getElementById('portalContainer');

if (portalContainer === null) {
  throw Error('portalContainer not found');
}

const CareerHubHelpPanel: React.FC<CareerHubHelpPanelProps> = () => {
  const helpPopupRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
  const { Spot, shineOff, shineOn, spotlightStatus } = useSpotlight();
  const [descriptionText, setDescriptionText] = useState<string>(defaultHelpText);
  const dispatch = useAppDispatch();

  const showHelpPanel = useAppSelector(getShowCareerHubHelpPanel);
  const onClose = () => dispatch(setShowCareerHubHelpPanel(false));

  useEffect(() => {
    if (helpPopupRef && helpPopupRef.current) {
      helpPopupRef.current.style.setProperty('--help-popup-offset', 1 + 'em');
    }

    return () => {
      onClose();
    };
  }, []);

  return (
    <HelpPopup
      ref={helpPopupRef}
      popupId="CareerHubHelpPanel"
      Spot={Spot}
      onClose={onClose}
      showHelpPanel={showHelpPanel}
      descriptionText={descriptionText}
      title="MY HOME"
      titleIcon="hub">
      <Features spotOn={shineOn} spotOff={shineOff} spotState={spotlightStatus} showHelpText={setDescriptionText} />
    </HelpPopup>
  );
};

export default CareerHubHelpPanel;

// #region FEATURES ***********************************
const Features: React.FC<TutorialHelpProps> = (props) => {
  const [clearActive, setClearActive] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>('');
  return (
    <MeTabs clearActive={clearActive} onActiveChange={setActiveTab}>
      <CareerHubAwards {...props} onFinishDemo={setClearActive} activeTab={activeTab} />
      <CareerHubCourses {...props} onFinishDemo={setClearActive} activeTab={activeTab} />
    </MeTabs>
  );
};
// #endregion
