import React, { useEffect, useState } from 'react';
import styles from './MeToggle.module.scss';
import Rule from 'components/rule/Rule';
import MeIconRaised from 'components/icon/meiconraised/MeIconRaised';
import { HorizontalPositionT } from 'types/Game.types';
import HeaderWithDetails from 'scenes/account/components/headerwithdetails/HeaderWithDetails';
import { BoldWeightT, ElementSizeT, LineHeightT, PropsWithChildren, ThicknessT, VoidFunc } from 'types/App.types';
import MeIcon from 'components/icon/meicon/MeIcon';

type MeToggleOwnProps = {
  id?: string;
  className?: string;
  /** `show/hide` horizontal rule above or below the component */
  showHLine?: boolean;
  /** `Top` or `Bottom` horizontal rule position  */
  hLinePosition?: HorizontalPositionT;
  /** name of the icon to be shown at the start of the line */
  titleIcon?: string;
  /** primary title shown in both open and closed states */
  title?: string;
  /** `ElementSizeT` primary title size */
  titleSize?: ElementSizeT;
  /** Show hide the leading icon */
  showTitleIcon?: boolean;
  /** represents details summary `state` {open/closed} at initialization */
  isActive?: boolean;
  onSelected?: (selected: boolean) => void;
  selected: boolean;
  onOpen?: VoidFunc;
  onClose?: VoidFunc;
  /** show/hide the `toggle` icon at the end of the line */
  toggleIcon?: string;
  toUrl?: string;
  isDanger?: boolean;
  isSuccess?: boolean;
  isDisabled?: boolean;
  isIconRaised?: boolean;
  helpIconSize?: ElementSizeT;
  helpIcon?: string;
  indentRow?: boolean;
  lineSize?: ThicknessT;
  hideToggleWhenClosed?: boolean;
  detailsTextSize?: ElementSizeT;
  detailsTextWeight?: BoldWeightT;
  detailsLineHeight?: LineHeightT;
};

type MeToggleProps = PropsWithChildren<MeToggleOwnProps>;
const MeToggle: React.FC<MeToggleProps> = ({
  isIconRaised = false,
  showTitleIcon = false,
  titleSize = 'med',
  hLinePosition,
  showHLine = true,
  helpIconSize = 'sma',
  helpIcon = 'info',
  indentRow = false,
  lineSize = 'med',
  hideToggleWhenClosed = false,
  detailsTextSize = 'med',
  detailsTextWeight = 'bold400',
  detailsLineHeight = 'line12',
  ...props
}) => {
  const [selected, setSelected] = useState<boolean>(!!props.selected);
  const [summaryDetailsOpen, setSummaryDetailsOpen] = useState<boolean>();
  const [hideToggleBtnCls, setHideToggleBtnCls] = useState<string>('');

  const rulePositionCls = hLinePosition === 'HorizontalBottom' ? styles.MeToggleHRuleBottom : styles.MeToggleHRuleTop;
  const ruleProps = hLinePosition === 'HorizontalTop' ? { marginBottom: '0' } : { marginBottom: '0' };
  const rowIndentStyle = indentRow ? { margin: '1em 0 0.65em 0.7em' } : { margin: '1em 0 0.7em' };

  const onTappedHandler = () => {
    if (props.isDisabled) return;
    const nextSelected = !selected;
    if (props.selected === undefined) setSelected(nextSelected);
    if (props.onSelected) props.onSelected(nextSelected);
  };

  useEffect(() => {
    setSummaryDetailsOpen(!!props.isActive);
    const hideToggleIcon = hideToggleWhenClosed && !props.isActive;
    setHideToggleBtnCls(hideToggleIcon ? styles.MeToggleBtnHidden : '');
  }, [props.isActive]);

  useEffect(() => {
    const hideToggleIcon = hideToggleWhenClosed && !summaryDetailsOpen;
    setHideToggleBtnCls(hideToggleIcon ? styles.MeToggleBtnHidden : '');
    if (summaryDetailsOpen) {
      if (props.onOpen) props.onOpen();
    } else {
      if (props.onClose) props.onClose();
    }
  }, [summaryDetailsOpen]);

  useEffect(() => {
    if (props.selected !== undefined) {
      setSelected(props.selected);
    }
  }, [props.selected]);

  return (
    <div className={`${styles.MeToggle} ${rulePositionCls} ${props.className ?? ''}`}>
      <div className={`${styles.MeToggleRow} ${hideToggleBtnCls}`} style={rowIndentStyle}>
        <HeaderWithDetails
          className={styles.HeaderDetailsStyle}
          onToggle={setSummaryDetailsOpen}
          id={`id_${props.id ?? 'toggle'}`}
          icon={props.titleIcon}
          helpIcon={helpIcon}
          helpIconFilled={false}
          helpIconSize={helpIconSize}
          helpIconPositionEndLine={false}
          titleSize={titleSize}
          title={props.title}
          showTitleIcon={showTitleIcon}
          isActive={props.isActive}
          shrinkTitleOnOpen
          isTitleIconFilled={selected}
          showHorizontalRule={false}
          descriptionTextSize={detailsTextSize}
          descriptionTextLineHeight={detailsLineHeight}
          descriptionTextWeight={detailsTextWeight}>
          {props.children}
        </HeaderWithDetails>

        {isIconRaised && (
          <MeIconRaised
            style={{ alignSelf: 'flex-start', marginLeft: '1em', marginBottom: '0.2em' }}
            isFilled={selected}
            disabled={props.isDisabled}
            isDanger={props.isDanger}
            isSuccess={props.isSuccess}
            icon={props.toggleIcon}
            to={props.toUrl ?? undefined}
            iconSize="mlg"
            onClick={onTappedHandler}
          />
        )}
        {!isIconRaised && (
          <MeIcon
            size="xtl"
            insetIcon
            showPointerCursor={!props.isDisabled}
            isFilled={selected}
            icon={selected ? 'toggle_on' : 'toggle_off'}
            style={{ lineHeight: 1, marginLeft: '1em' }}
            onClick={onTappedHandler}
          />
        )}
      </div>
      {showHLine && <Rule zeroMargin size={lineSize} style={ruleProps} />}
    </div>
  );
};

export default MeToggle;
