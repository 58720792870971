import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { getModifiedInfo } from 'helpers/careerHelpers';
import { CareerHistoryReportT } from 'types/Career.types';

// #region CONVERTERS ********************************************************************
export const historyToDoc = (history: CareerHistoryReportT): DocumentData => {
  return {
    historyId: history.historyId,
    questionId: history.questionId,
    questionVersion: history.questionVersion,
    questionCategory: history.questionCategory,
    title: history.title,
    reportType: history.reportType,
    timeToSolve: history.timeToSolve,
    careerId: history.careerId,
    courseId: history.courseId,
    lessonId: history.lessonId,
    quizId: history.quizId,
    profileId: history.profileId,
    createdOn: history.createdOn,
    createdBy: history.createdBy,
    modifiedBy: history.modifiedBy || '',
    modifiedOn: history.modifiedOn || 0,
  };
};

export const historyConverter: FirestoreDataConverter<CareerHistoryReportT> = {
  toFirestore(history: CareerHistoryReportT): DocumentData {
    return { ...historyToDoc(history), ...getModifiedInfo() };
  },

  fromFirestore(docSnap: QueryDocumentSnapshot, options?: SnapshotOptions): CareerHistoryReportT {
    const history = docSnap.data(options);
    return {
      historyId: history.historyId,
      questionId: history.questionId,
      questionVersion: history.questionVersion,
      questionCategory: history.questionCategory,
      title: history.title ?? '',
      reportType: history.reportType ?? 'question',
      timeToSolve: history.timeToSolve,
      careerId: history.careerId,
      courseId: history.courseId,
      lessonId: history.lessonId,
      quizId: history.quizId,
      profileId: history.profileId,
      createdOn: history.createdOn,
      createdBy: history.createdBy,
      modifiedBy: history.modifiedBy,
      modifiedOn: history.modifiedOn,
    };
  },
};

// #endregion
