import React, { AnimationEvent, useEffect, useState } from 'react';
import { VoidFunc } from 'types/App.types';
import { SpotlightShapeT } from 'types/Tutorial.types';

import { isAnimationName } from 'helpers/uiHelpers';
import Ring from 'components/ring/Ring';

import styles from './Spotlight.module.scss';

type SpotlightProps = {
  className?: string;
  positionX?: number;
  positionY?: number;
  width?: number;
  height?: number;
  show?: boolean;
  showRingAnimation?: boolean;
  onRingAnimationEnd?: VoidFunc;
  spotlightShape?: SpotlightShapeT;
} & Pick<React.HTMLAttributes<HTMLDivElement>, 'style' | 'onMouseOver' | 'onClick'>;

const Spotlight: React.FC<SpotlightProps> = ({
  className = '',
  positionX = 50,
  positionY = 50,
  width = 50,
  height = 50,
  show = true,
  showRingAnimation = false,
  onRingAnimationEnd = () => undefined,
  spotlightShape = 'circle',
  ...props
}) => {
  const [showRing, setShowRing] = useState<boolean>(false);
  const [spotShapeCls, setSpotShapeCls] = useState<string>(styles.SpotlightCircleShape);
  const localWidth = `${width}px`;
  const localHeight = `${height}px`;
  const posX = `${positionX}px`;
  const posY = `${positionY}px`;

  useEffect(() => {
    if (show) setShowRing(false);
  }, [show]);

  useEffect(() => {
    if (!spotlightShape) return;

    let spotCls = styles.SpotlightCircleShape;
    switch (spotlightShape) {
      case 'rectangle':
        spotCls = styles.SpotlightSquareShape;
        break;
      case 'square':
        spotCls = styles.SpotlightSquareShape;
        break;
      case 'oval':
        spotCls = styles.SpotlightCircleShape;
        break;
      case 'circle':
      default: {
        spotCls = styles.SpotlightCircleShape;
      }
    }
    setSpotShapeCls(spotCls);
  }, [spotlightShape]);

  useEffect(() => {
    setShowRing(showRingAnimation);
    // console.log(`Spotlight useEffect | showRingAnimation: ${showRingAnimation}`);
  }, [showRingAnimation]);

  const onAnimationOver = (e: AnimationEvent) => {
    // console.log(`onAnimationOver = ${e.animationName}`);
    if (
      isAnimationName(e.animationName, 'Ring_rotate-left') ||
      isAnimationName(e.animationName, 'Ring_hide-left-border')
    ) {
      setShowRing(false);
      onRingAnimationEnd();
    }
  };

  return (
    <>
      {show && (
        <div className={styles.ClippedBox}>
          <div
            {...props}
            className={`${styles.Spotlight} ${spotShapeCls} ${className}`}
            onAnimationEnd={onAnimationOver}
            style={{ top: posY, left: posX, width: localWidth, height: localHeight }}>
            <span className={styles.SpotlightGlow}></span>
            {/* <MeIcon icon="touch_app" weight="bold200" className={styles.SpotlightIconDisplay} /> */}
            <Ring show={showRing} width={localWidth} height={localHeight} spotlightShape={spotlightShape} />
          </div>
        </div>
      )}
    </>
  );
};

export default Spotlight;
