import React from 'react';
import { AppIcons } from 'app/constants/Constants';
import { ButtonLabel } from 'app/constants/Localization';
import { AppRoutes } from 'app/constants/Routes';

import RoundedButton from 'components/buttons/rounded/RoundedButton';
import MeText from 'components/text/metext/MeText';

import { b } from 'helpers/uiHelpers';

import styles from './EnticePanel.module.scss';

type EnticePanelProps = {
  className?: string;
};

const EnticePanel: React.FC<EnticePanelProps> = (props) => {
  return (
    <div className={`${styles.EnticePanel} ${props.className ?? ''}`}>
      <RoundedButton to={AppRoutes.SignUp} icon={AppIcons.Common.AppRegistration} mainColor>
        {ButtonLabel.ClickToSignUp}
      </RoundedButton>
      <MeText textSize="mel" weight="bold500" style={{ margin: '1em 0' }}>
        ...or if you are already a&nbsp; {b('member')}
      </MeText>
      <RoundedButton to={AppRoutes.Login} icon={AppIcons.Common.Login} mainColor>
        {ButtonLabel.ClickToLogin}
      </RoundedButton>
    </div>
  );
};

export default EnticePanel;
