import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';
import { MaybeType } from 'types/App.types';
import { ArticleId, NewsArticle } from 'types/News.types';

import TitleHeader from 'components/titleheader/TitleHeader';
import MeArticle from 'components/article/MeArticle';

import { getNewsArticleById } from 'scenes/montessori/newsSlice';

import styles from './NewsArticlePanel.module.scss';

type NewsArticleProps = {
  className?: string;
  appVersion?: string;
  // newsArticle?: NewsArticle;
  articleId: ArticleId;
};

const NewsArticlePanel: React.FC<NewsArticleProps> = ({ articleId, ...props }) => {
  const [appendAppVersion, setAppendAppVersion] = useState<boolean>(false);
  const [titleIcon, setTitleIcon] = useState<string>('');
  const [titleName, setTitleName] = useState<string>('');
  const [articleRows, setArticleRows] = useState<Array<string>>([]);
  const newsArticle: MaybeType<NewsArticle> = useSelector((state: RootState) => getNewsArticleById(state, articleId));

  useEffect(() => {
    if (newsArticle) {
      setAppendAppVersion(!!newsArticle.articleTitleAppendAppVersion);
      setTitleIcon(newsArticle.articleTitleIcon ?? '');
      setTitleName(newsArticle.articleTitle);
      setArticleRows(newsArticle.articleItems);
    }
  }, [newsArticle]);

  const appendVersion = () => {
    return appendAppVersion ? props.appVersion : '';
  };

  return (
    <div className={`${styles.NewsArticle} ${props.className ?? ''}`}>
      <MeArticle
        titleText={
          <TitleHeader className={styles.NewsArticleHeader} icon={titleIcon} ruleSize="ultra" titleSize="med">
            {titleName}&nbsp;{appendVersion()}
          </TitleHeader>
        }
        textList={articleRows}
      />
    </div>
  );
};

export default NewsArticlePanel;
