import { store } from 'app/store';
import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { AwardMedals, AwardStars } from 'types/App.types';
import { CareerT } from 'types/Career.types';
import { AwardedGamesCollection } from 'types/Game.types';
import { getModifiedInfo } from 'helpers/careerHelpers';

// #region       CONVERTERS **************************************************************
export const careerToDoc = (career: CareerT): DocumentData => {
  return {
    careerId: career.careerId,
    userId: career.userId,
    profileId: career.profileId,
    courseIds: career.courseIds,
    courses: career.courses,
    badges: career.badges,
    complete: career.complete,
    createdOn: career.createdOn,
    createdBy: career.createdBy,
    modifiedOn: career.modifiedOn,
    modifiedBy: career.modifiedBy,
  };
};

export const careerConverter: FirestoreDataConverter<CareerT> = {
  toFirestore(career: CareerT): DocumentData {
    return { ...careerToDoc(career), ...getModifiedInfo() };
  },

  fromFirestore(docSnap: QueryDocumentSnapshot, options?: SnapshotOptions): CareerT {
    const data = docSnap.data(options);
    return {
      careerId: data.careerId,
      userId: data.userId,
      profileId: data.profileId,
      courseIds: data.courseIds,
      hiddenCourseIds: [],
      courses: data.courses || [],
      badges: data.badges,
      awardCodes: [],
      awardMedals: [],
      awardGames: [],
      complete: data.complete,
      createdOn: data.createdOn,
      createdBy: data.createdBy,
      modifiedOn: data.modifiedOn || data.createdOn,
      modifiedBy: data.modifiedBy || data.createdBy,
    };
  },
};

export const awardCodesToDoc = (data: AwardStars): DocumentData => {
  return {
    codes: data.codes,
  };
};

export const awardMedalsToDoc = (data: AwardMedals): DocumentData => {
  return {
    awarded: data.awarded,
  };
};

export const awardsConverter: FirestoreDataConverter<AwardStars> = {
  toFirestore(data: AwardStars): DocumentData {
    return awardCodesToDoc(data);
  },

  fromFirestore(docSnap: QueryDocumentSnapshot, options?: SnapshotOptions): AwardStars {
    const data = docSnap.data(options);
    return {
      codes: data.codes,
    };
  },
};

export const medalsConverter: FirestoreDataConverter<AwardMedals> = {
  toFirestore(data: AwardMedals): DocumentData {
    return awardMedalsToDoc(data);
  },

  fromFirestore(docSnap: QueryDocumentSnapshot, options?: SnapshotOptions): AwardMedals {
    const data = docSnap.data(options);
    return {
      awarded: data.awarded,
    };
  },
};

export const getCareerUser = (career: CareerT): string => {
  const currentUser = store.getState().user;

  if (currentUser.uid === career.userId) {
    return `${currentUser.firstname} ${currentUser.lastname}`;
  }

  return 'Username Unavailable';
};

// #region AWARDED GAMES
const awardedGamesToDoc = (games: AwardedGamesCollection): DocumentData => {
  return {
    awardedGames: games.awardedGames,
  };
};

export const awardedGamesConverter: FirestoreDataConverter<AwardedGamesCollection> = {
  toFirestore(data: AwardedGamesCollection): DocumentData {
    return awardedGamesToDoc(data);
  },

  fromFirestore(docSnap: QueryDocumentSnapshot, options?: SnapshotOptions): AwardedGamesCollection {
    const data = docSnap.data(options);
    return {
      awardedGames: data.awardedGames,
    };
  },
};
// #endregion
// #endregion
