export const AppRoutes = {
  /** AUTH ******************************************************************************/
  Login: '/auth/login',
  SignUp: '/auth/create',
  ResetPassword: '/auth/resetpassword',
  /** HOME HUB *************************************************************************/
  Hub: '/',
  /** BOARD TUTORIAL */
  BoardTutorial: '/boardtutorial',
  /** CAREER ***************************************************************************/
  Career: '/career',
  CareerBadges: '/career/badges/:careerId',
  CareerSimple: '/career/simple/:careerId',
  CareerById: '/career/:careerId',
  CareerCourseById: '/career/:careerId/course/:courseId',
  CareerLessonById: '/career/:careerId/course/:courseId/lesson/:lessonId',
  CareerQuizById: '/career/:careerId/course/:courseId/lesson/:lessonId/quiz/:quizId',
  CareerTutorial: '/career/tutorial',
  CareerTutorialVersioned: '/career/tutorial/:tutorialId/:versionId',
  CareerTutorialCount: '/career/tutorial/count/:tutorialId',
  CareerTutorialCompare: '/career/tutorial/compare/:tutorialId',
  CareerTutorialAdd: '/career/tutorial/add/:tutorialId',
  CareerTutorialSubtract: '/career/tutorial/subtract/:tutorialId',
  CareerTutorialMultiply: '/career/tutorial/multiply/:tutorialId',
  CareerTutorialDivide: '/career/tutorial/divide/:tutorialId',
  /** QUESTIONS ************************************************************************/
  Question: '/question',
  QuestionById: '/questionId/:questionId',
  QuestionByVersionId: '/question/:questionId/:versionId',
  QuestionCount: '/questionId/count/:questionId',
  QuestionCompare: '/questionId/compare/:questionId',
  QuestionAdd: '/questionId/add/:questionId',
  QuestionSubtract: '/questionId/subtract/:questionId',
  QuestionMultiply: '/questionId/multiply/:questionId',
  QuestionDivide: '/questionId/divide/:questionId',
  /** GAMES ****************************************************************************/
  Games: 'games',
  GameById: 'games/:gameId',
  GameWackamole: 'games/:gameId/:gameQuestionId/wackamole',
  GameWakabake: 'games/:gameId/:gameQuestionId/wakabake',
  GameWakacubes: 'games/:gameId/:gameQuestionId/wakacubes',
  GameWakaflip: 'games/:gameId/:gameQuestionId/wakaflip',
  /** */
  UIComponentsLibrary: '/montessori/components',
  /** USER ACCOUNT *********************************************************************/
  UserAccount: '/account/:accountId',
  UserCreateProfile: '/account/:accountId/profile/create',
  UserProfile: '/account/:accountId/profile/:profileId',
  UserProfileReportCard: '/account/:accountId/profile/:profileId/reportcard/:careerId',
  UserProfileCourses: '/account/:accountId/profile/:profileId/courses',
  /** STUDENT PROFILE ******************************************************************/
  StudentProfile: '/student/:profileId',
  /** LEGAL ****************************************************************************/
  Legal: '/legal',
  LegalPage: '/legal/:subpageId',
  /** EDITOR ***************************************************************************/
  Manager: 'manage',
  ManagerAwards: '/manage/awards',
  ManagerAwardsCreate: '/manage/awards/create',
  ManagerAwardsEdit: '/manage/awards/:awardId',
  ManagerMedals: '/manage/medals',
  ManagerMedalsCreate: '/manage/medals/create',
  ManagerMedalsEdit: '/manage/medals/:medalId',
  ManagerCareer: '/manage/career',
  ManagerCareerCreate: '/manage/career/create',
  ManagerCareerEdit: '/manage/career/:careerId',
  ManagerCourse: '/manage/course',
  ManagerCourseCreate: '/manage/course/create',
  ManagerCourseEdit: '/manage/course/:courseId',
  ManagerGames: '/manage/games',
  ManagerGamesCreate: '/manage/games/create',
  ManagerGamesEdit: '/manage/games/:gameId',
  ManagerGamesEditQuestionCreate: '/manage/games/:gameId/createquestion',
  ManagerGamesEditQuestionEdit: '/manage/games/:gameId/:gameQuestionId',
  ManagerLesson: '/manage/lesson',
  ManagerLessonCreate: '/manage/lesson/create',
  ManagerLessonEdit: '/manage/lesson/:lessonId',
  ManagerNews: '/manage/news',
  ManagerNewsCreate: '/manage/news/create',
  ManagerNewsEdit: '/manage/news/:articleId',
  ManagerProfile: '/manage/profile',
  ManagerProfileEdit: '/manage/profile/:profileId',
  ManagerQuestion: '/manage/question',
  ManagerQuestionCreate: '/manage/question/create',
  ManagerQuestionEdit: '/manage/question/:questionId',
  ManagerQuiz: '/manage/quiz',
  ManagerQuizCreate: '/manage/quiz/create',
  ManagerQuizEdit: '/manage/quiz/:quizId/:versionId',
  ManagerTutorial: '/manage/tutorial',
  ManagerTutorialCreate: '/manage/tutorial/create',
  ManagerTutorialEdit: '/manage/tutorial/:tutorialId',
  ManagerUnlocks: '/manage/unlocks',
  ManagerUnlocksCreate: '/manage/unlocks/create',
  ManagerUnlocksEdit: '/manage/unlocks/:unlockId',
  ManagerUserEdit: '/manage/user/:accountId',
  ManagerUserProfileCreate: '/manage/user/:accountId/create',
  ManagerUserProfileEdit: '/manage/user/:accountId/:profileId',
  ManagerUsers: '/manage/user',
} as const;
