import React from 'react';
import { Link } from 'react-router-dom';
import { BoldWeightT, ElementSizeT } from 'types/App.types';
import MeIcon from 'components/icon/meicon/MeIcon';

import styles from './HomeButton.module.scss';
import { AppIcons } from 'app/constants/Constants';

type HomeButtonProps = {
  className?: string;
  size?: ElementSizeT;
  weight?: BoldWeightT;
  isFilled?: boolean;
  icon?: string;
  insetIcon?: boolean;
} & Pick<React.HTMLAttributes<HTMLSpanElement>, 'style'>;

const HomeButton: React.FC<HomeButtonProps> = ({
  size = 'lge',
  weight = 'bold600',
  icon = AppIcons.Common.Home,
  isFilled,
  insetIcon,
  ...props
}) => {
  return (
    <Link className={`${styles.HomeButton} ${styles.Wobble} ${props.className ?? ''}`} to="/">
      <MeIcon insetIcon={insetIcon} icon={icon} size={size} weight={weight} isFilled={isFilled} style={props.style} />
    </Link>
  );
};

export default HomeButton;
