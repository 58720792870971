import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'app/hooks/hooks';
import { getAdminNote } from 'scenes/montessori/appUiSlice';

import MeText from 'components/text/metext/MeText';

import styles from './AdminNote.module.scss';
import { createPortal } from 'react-dom';
import XCloseButton from 'components/buttons/xclosebutton/XCloseButton';

type AdminNoteProps = {
  className?: string;
};

const portalContainer: HTMLElement | null = document.getElementById('portalContainer');

if (portalContainer === null) {
  throw Error('portalContainer not found');
}

const AdminNote: React.FC<AdminNoteProps> = (props) => {
  const note = useAppSelector(getAdminNote);
  const [open, setOpen] = useState<boolean>(false);
  const [notes, setNotes] = useState<Array<string>>([]);

  useEffect(() => {
    if (note) setNotes(notes.concat(note));
  }, [note]);

  return createPortal(
    <div className={`${styles.AdminNoteLayout} ${!open ? styles.Closed : ''}`}>
      <div className={`${styles.AdminNote} ${props.className ?? ''}`}>
        <MeText
          useDiv
          weight="bold300"
          textSize="sam"
          lineHeight="line12"
          style={{ whiteSpace: 'pre', overflow: 'hidden', overflowY: 'auto', paddingRight: '0.4em' }}>
          {notes.join('\n')}
        </MeText>
      </div>
      <div className={styles.AdminNoteCloseButton}>
        <XCloseButton size="xsm" onClick={() => setOpen(!open)} />
      </div>
    </div>,
    portalContainer,
    'adminNoteId'
  );
};

export default AdminNote;
