import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { StatusT } from 'types/App.types';
import { BadgesT } from 'types/Career.types';

export interface BadgesUiState {
  badges: BadgesT;
  badgesStatus: StatusT;
}

const initialState: BadgesUiState = {
  badges: [],
  badgesStatus: 'idle',
};


export const badgesUiSlice = createSlice({
  name: 'badgesUi',
  initialState,
  reducers: {
    setCrests: (state, action: PayloadAction<BadgesT>) => {
      state.badges = action.payload;
    },
    setCrestsStatus: (state, action: PayloadAction<StatusT>) => {
      state.badgesStatus = action.payload;
    },
    resetState: () => {
      return { ...initialState };
    },
  },
});

export const {
  setCrests,
  setCrestsStatus,
  resetState,
} = badgesUiSlice.actions;

export const getCrests = (state: RootState): BadgesT => state.ui.badgesUi.badges;
export const getCrestsState = (state: RootState): StatusT => state.ui.badgesUi.badgesStatus;

export default badgesUiSlice.reducer;
