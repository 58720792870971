import React from 'react';
import MeText from 'components/text/metext/MeText';

import styles from './DescriptionPanel.module.scss';

type DescriptionPanelProps = {
  className?: string;
  text?: string;
};

const DescriptionPanel: React.FC<DescriptionPanelProps> = ({ text = '', ...props }) => {
  return (
    <div className={`${styles.DescriptionPanel} ${props.className ?? ''}`}>
      <MeText className={styles.DescriptionPanelText} textSize="sma" weight="bold500" insetText lineHeight="line12">
        {text}
      </MeText>
    </div>
  );
};

export default DescriptionPanel;
