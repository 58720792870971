import React, { useEffect, useRef, useState } from 'react';
import { VisualState } from 'types/App.types';
import { CareerChainCard, CareerChainCards } from 'types/BaseCareer.types';

import { AppIcons } from 'app/constants/Constants';
import { ComponentTitle } from 'app/constants/Localization';

import ProgressIndicator from 'components/progress/ProgressIndicator';

type CareerProgressProps = {
  className?: string;
  activeChain: CareerChainCards;
  icon?: string;
  title?: string;
};

const CareerProgress: React.FC<CareerProgressProps> = ({
  icon = AppIcons.Career.Career,
  title = ComponentTitle.CareerProgress,
  ...props
}) => {
  const progressRef = useRef<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(1);
  const [completedCount, setCompletedCount] = useState<number>(1);

  useEffect(() => {
    const activeChain = props.activeChain || [];

    if (activeChain.length > 0 && !progressRef.current) {
      progressRef.current = true;
      setTotalCount(activeChain.length);
      const doneCount = props.activeChain.filter(
        (card: CareerChainCard) => card.visualState === VisualState.Completed
      ).length;
      setTimeout(() => {
        setCompletedCount(doneCount || 0);
        progressRef.current = false;
      }, 1000);
    } else {
      setTotalCount(1);
      setCompletedCount(0);
    }
  }, [props.activeChain]);

  return (
    <ProgressIndicator
      key={'pi_key'}
      icon={icon}
      title={title}
      isFilledIcon
      max={totalCount}
      value={completedCount}
      showPercentageLabel
      className={props.className ?? ''}
    />
  );
};

export default CareerProgress;
