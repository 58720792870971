import { useEffect } from 'react';

export type ResizeDimensions = {
  width: number;
  height: number;
};

const montessoriAppEl = document.getElementById('root') as HTMLElement;

export const useScrollManager = () => {
  useEffect(() => {
    window.addEventListener('scroll', () => {
      document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
    });
    return () => window.removeEventListener('scroll', () => undefined);
  }, []);

  const enableScroll = () => {
    const scrollY = montessoriAppEl.style.top;
    document.body.style.removeProperty('position');
    montessoriAppEl.style.removeProperty('top');
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  };

  const disableScroll = () => {
    const yOffset = `-${window.scrollY}px`;
    document.body.style.position = 'fixed';
    montessoriAppEl.style.top = yOffset;
  };

  return { disableScroll, enableScroll };
};
