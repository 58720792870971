import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks/hooks';

import { Arithmetic } from 'types/App.types';
import { CrestT } from 'types/Career.types';

import { createCrest, getImageUrlByVariant } from 'helpers/badgeHelpers';
import { getCareerBadgeAwardPopupOpen, setCareerBadgeAwardPopupOpen, setCrestForAwardPopup } from 'modules/career/careerUiSlice';

import IconButton from 'components/buttons/icon/IconButton';

const AwardPopupDebug: React.FC = () => {
  const dispatch = useAppDispatch();
  const [awardCount, setAwardCount] = useState<number>(0);
  const isOpen = useAppSelector(getCareerBadgeAwardPopupOpen);

  const crest1: CrestT = createCrest('LEVEL 1', getImageUrlByVariant('career', 1), Arithmetic.Count, 'course', 1);
  const crest2: CrestT = createCrest('LEVEL 2', getImageUrlByVariant('career', 2), Arithmetic.Add, 'course', 2);
  const crest3: CrestT = createCrest('LEVEL 3', getImageUrlByVariant('career', 3), Arithmetic.Subtract, 'course', 3);
  const crest4: CrestT = createCrest('LEVEL 4', getImageUrlByVariant('career', 4), Arithmetic.Multiply, 'course', 4);
  const crest5: CrestT = createCrest('LEVEL 5', getImageUrlByVariant('career', 5), Arithmetic.Divide, 'course', 5);

  const crest6: CrestT = createCrest('LEVEL 1', getImageUrlByVariant('lesson', 1), Arithmetic.Count, 'lesson', 1);
  const crest7: CrestT = createCrest('LEVEL 2', getImageUrlByVariant('lesson', 2), Arithmetic.Add, 'lesson', 2);
  const crest8: CrestT = createCrest('LEVEL 3', getImageUrlByVariant('lesson', 3), Arithmetic.Subtract, 'lesson', 3);
  const crest9: CrestT = createCrest('LEVEL 4', getImageUrlByVariant('lesson', 4), Arithmetic.Multiply, 'lesson', 4);
  const crest10: CrestT = createCrest('LEVEL 5', getImageUrlByVariant('lesson', 5), Arithmetic.Divide, 'lesson', 5);

  const crestList = [crest1, crest2, crest3, crest4, crest5, crest6, crest7, crest8, crest9, crest10];

  const showAwardPopup = () => {
    const crestIndex: number = awardCount;

    dispatch(setCrestForAwardPopup(crestList[crestIndex]));
    dispatch(setCareerBadgeAwardPopupOpen(true));

    if (awardCount >= 9) {
      setAwardCount(0);
    } else {
      setAwardCount(awardCount + 1);
    }
  };

  return <IconButton icon="trophy" isFilled={isOpen} onClick={showAwardPopup} />;
};
export default AwardPopupDebug;
