import React, { useState, SyntheticEvent, useEffect, useRef } from 'react';
import { BoldWeightT, ElementSizeT, LineHeightT, PropsWithChildren } from 'types/App.types';
import { AppIcons } from 'app/constants/Constants';

import MeIconInfo from 'components/icon/meiconinfo/MeIconInfo';
import MeText from 'components/text/metext/MeText';
import TitleHeader from 'components/titleheader/TitleHeader';

import styles from './HeaderWithDetails.module.scss';

type OwnHeaderWithDetailsProps = {
  className?: string;
  id: string;
  titleSize?: ElementSizeT;
  title?: string;
  shrinkTitleOnOpen?: boolean;
  icon?: string;
  showTitleIcon?: boolean;
  isTitleIconFilled?: boolean;
  isActive?: boolean;
  showHorizontalRule?: boolean;
  helpIcon?: string;
  helpIconFilled?: boolean;
  helpIconSize?: ElementSizeT;
  helpIconWeight?: BoldWeightT;
  helpIconPositionEndLine?: boolean;
  descriptionTextSize?: ElementSizeT;
  descriptionTextLineHeight?: LineHeightT;
  descriptionTextWeight?: BoldWeightT;
  onToggle?: (open: boolean) => void;
};

type HeaderWithDetailsProps = PropsWithChildren<OwnHeaderWithDetailsProps>;

const HeaderWithDetails: React.FC<HeaderWithDetailsProps> = ({
  showHorizontalRule = true,
  icon = AppIcons.Common.AccountAdmin,
  titleSize = 'mlg',
  showTitleIcon = true,
  shrinkTitleOnOpen = false,
  helpIcon = 'info',
  helpIconFilled = true,
  helpIconSize = 'mel',
  helpIconWeight = 'bold300',
  helpIconPositionEndLine = true,
  descriptionTextSize = 'mel',
  descriptionTextLineHeight = 'line12',
  descriptionTextWeight = 'bold500',
  ...props
}) => {
  const detailsElementRef = useRef<HTMLDetailsElement>(null);
  const [, setIsOpen] = useState<boolean>(false);
  const [localTitleSize, setLocalTitleSize] = useState<ElementSizeT>(titleSize);
  const [localTitleWeight, setLocalTitleWeight] = useState<BoldWeightT>('bold500');

  useEffect(() => {
    if (detailsElementRef && detailsElementRef.current) {
      detailsElementRef.current.open = !!props.isActive;
    }
  }, [props.isActive]);

  const setTitleSizeOnToggle = (e: SyntheticEvent<HTMLDetailsElement, Event>) => {
    const open = (e.target as HTMLDetailsElement).open;
    setIsOpen(open);
    setLocalTitleWeight(shrinkTitleOnOpen && open ? 'bold700' : 'bold500');
    setLocalTitleSize(shrinkTitleOnOpen && open ? 'sma' : titleSize);

    if (props.onToggle) props.onToggle(open);
  };

  return (
    <details
      ref={detailsElementRef}
      id={props.id}
      onToggle={setTitleSizeOnToggle}
      className={`${styles.HeaderWithDetails} ${props.className ?? ''}`}>
      <summary className={styles.HeaderWithDetailsSummary} style={{ display: 'block' }}>
        <TitleHeader
          icon={showTitleIcon ? icon : ''}
          ruleSize="ultra"
          showOverflow
          isFilledIcon={props.isTitleIconFilled}
          weight={localTitleWeight}
          titleSize={localTitleSize}
          showHorizontalRule={showHorizontalRule}>
          <div className={styles.HeaderWithDetailsRow}>
            {props.title}
            {!helpIconPositionEndLine && <MeIconInfo icon={helpIcon} size={helpIconSize} isFilled={helpIconFilled} />}
          </div>
        </TitleHeader>
        {helpIconPositionEndLine && (
          <MeIconInfo icon={helpIcon} iconPosition="lineEnd" weight={helpIconWeight} size={helpIconSize} isFilled={helpIconFilled} />
        )}
      </summary>
      <MeText
        weight={descriptionTextWeight}
        lineHeight={descriptionTextLineHeight}
        textSize={descriptionTextSize}
        useDiv
        insetText>
        {props.children}
      </MeText>
    </details>
  );
};

export default HeaderWithDetails;
