export const ImageUrls = {
  MonkeyThinking: '/images/monkey-thinking-150x240.webp',
  MonkeyHappy: '/images/monkey-happy-200x200.webp',
  MonkeyParents: '/images/monkey-parents-320x310.webp',
  MonkeyFullHeight: '/images/monkey-full-200x240.webp',
  MedalFirstGen: '/images/medals/medal-gen1-200x200.webp', // TODO: @Andrey11 Delete me soon
  MedalFirstGenLarge: '/images/medals/medal-gen1-500x500.webp', // TODO: @Andrey11 Delete me soon
  MedalBackLarge:
    'https://firebasestorage.googleapis.com/v0/b/montessori-11.appspot.com/o/images%2Fmedals%2Fmedal-back-500x500.webp?alt=media&token=a82cccd2-e9c4-47f6-b34d-683a205d32b6',
} as const;

export type ImageUrlsT = (typeof ImageUrls)[keyof typeof ImageUrls];

export type IconNameMap = {
  Urls: Record<keyof typeof ImageUrls, ImageUrlsT>;
};
export const AppImages: Readonly<IconNameMap> = {
  Urls: { ...ImageUrls },
};
