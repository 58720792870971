import { Buffer } from 'buffer/';

export const decodeUserPasskey = (hashedPass: string): string => {
  const pass = base64ToUtf8(hashedPass);
  return pass.slice(28);
};

export const decodeStudentPasskey = (hashedPass: string): string => {
  const pass = base64ToUtf8(hashedPass);
  return pass.slice(20);
};

export const encodeUserPassKey = (code: string, salt: string): string => {
  return utf8ToBase64(`${salt}${code}`);
};

export const utf8ToBase64 = (str: string): string => {
  const buf = Buffer.from(str, 'utf8');
  return buf.toString('base64');
};

export const base64ToUtf8 = (str: string): string => {
  const buf = Buffer.from(str, 'base64');
  return buf.toString('utf8');
};
