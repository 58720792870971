import { MonkeyImage, MonkeyImageT } from 'components/monkey/Monkey';
import { isNowEasterTime, isNowHalloweenTime, isNowXmasTime } from 'helpers/timeHelpers';
import { ExpectedResultsT, GameInstanceT, GameLevelT, GameResult, GameT, LockedFruits } from 'types/Game.types';

export const INTERACTIVE_MATH_GAME_ID = 'INTERACTIVE_MATH_GAME_ID';

export const GameNames = {
  WakaCubes: 'WakaCubes',
  WakaFlip: 'WakaFlip',
  MinusBalance: 'Minus Balance',
  WackAMole: 'Wack-A-Mole',
  WakaBake: 'WakaBake',
};

export const getLockedFruits = (activeQuestion: ExpectedResultsT): LockedFruits => {
  return activeQuestion.map((res, index) => ({
    expectedResult: res,
    locked: true,
    id: `id_${index}_${res.fruit}_${res.expectedCount}`,
  }));
};

export const areAllFruitUnlocked = (fruits: LockedFruits): boolean => {
  if (!fruits || fruits.length === 0) return false;

  return fruits.findIndex((f) => f.locked) === -1;
};

export const isLockedGameLevel = (completeStr: string, currentLevel: number) => {
  return parseInt(completeStr[completeStr.length - 1]) < currentLevel;
};

export const updateMinusBalanceRowHeight = (containerHeight: number, rowsCount: number) => {
  // get current default font size based on dimensions
  const cssDec = window.getComputedStyle(document.body) as CSSStyleDeclaration;
  const fontValue = String(cssDec.getPropertyValue('font-size'));
  const value = (fontValue.match(/\d+/) as RegExpMatchArray)[0];
  // get row height in EM units
  const rowHeight = Math.round(containerHeight / rowsCount);
  const rowHeightInEm = Math.fround(rowHeight / parseFloat(value));
  document.documentElement.style.setProperty('--game-row-height', `${rowHeightInEm}em`);
};

export const findUnansweredIndexInList = (
  answeredIndexList: Array<number>,
  startingIndex: number,
  listSize: number
): number => {
  if (answeredIndexList.length === listSize) return -1;

  let iterationCount = 0;
  let currentIndex = startingIndex;
  let isFound = !answeredIndexList.includes(currentIndex);
  while (!isFound && iterationCount < listSize) {
    currentIndex = currentIndex + 1 >= listSize ? 0 : currentIndex + 1;
    if (!answeredIndexList.includes(currentIndex)) isFound = true;
    iterationCount++;
  }

  return isFound ? currentIndex : -1;
};

export const createGameInstance = (game: GameT, gameLevel: GameLevelT, gameInstanceId?: string): GameInstanceT => {
  // console.log(`createGameInstance gameLevel: ${gameLevel}`);
  return {
    gameInstanceId: `${gameInstanceId ?? game.gameId}:${Date.now()}`,
    gameId: game.gameId,
    gameTitle: game.gameTitle,
    result: GameResult.Incomplete,
    gameLevel: gameLevel,
    elapsedTime: 0,
  } as GameInstanceT;
};

export const isGameTitle = (title: string): boolean => {
  return (
    title.toLowerCase() === GameNames.MinusBalance.toLowerCase() ||
    title.toLowerCase() === GameNames.WackAMole.toLowerCase() ||
    title.toLowerCase() === GameNames.WakaBake.toLowerCase() ||
    title.toLowerCase() === GameNames.WakaCubes.toLowerCase() ||
    title.toLowerCase() === GameNames.WakaFlip.toLowerCase()
  );
};

export const getMonkeyImage = (timestamp: number): MonkeyImageT => {
  if (isNowEasterTime(timestamp)) return MonkeyImage.EASTER;
  if (isNowXmasTime(timestamp)) return MonkeyImage.XMAS;
  if (isNowHalloweenTime(timestamp)) return MonkeyImage.HALLOWEEN;
  return MonkeyImage.HEAD;
};
