import React, { RefAttributes } from 'react';
import { createPortal } from 'react-dom';
import { PropsWithChildren } from 'types/App.types';

import ImagePortalContainer from 'components/imageframe/components/imageportal/ImagePortalContainer';

type ImagePortalOwnProps = {
  className?: string;
} & Pick<React.HTMLAttributes<HTMLDivElement>, 'id' | 'style' | 'onClick'>;

type ImagePortalProps = PropsWithChildren<ImagePortalOwnProps> & RefAttributes<HTMLDivElement>;

const ImagePortal: React.FC<ImagePortalProps> = React.forwardRef<HTMLDivElement, ImagePortalProps>(
  (props, ref) => {
    return createPortal(
      <ImagePortalContainer id={props.id} onClick={props.onClick} contentRef={ref}>
        {props.children}
      </ImagePortalContainer>,
      document.body
    );
  }
);

export default ImagePortal;
