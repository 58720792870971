import { ReactNode } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'app/store';
import { getNameClsString, getFormattedString } from 'app/config/LogConfig';
import { isPrint } from 'helpers/appHelpers';
import { AppConfigResponseT, AppConfigT, AppStatus, AppStatusT, AppVersionId, ArticlesConfigT, Status, StatusT } from 'types/App.types';

export const defaultWallColor = '#DCC095';
export const defaultWallColorGradient = '#BCA682';
export const windowColor = '#B1D9E7';

export const PRIMARY_COLOR = getNameClsString('brown', 'cornsilk');
export const CLS_NAME = 'AppUiSlice';

const printMsg = (action: string, prop: string, status: string) =>
  getFormattedString(PRIMARY_COLOR, CLS_NAME, action, prop, status);

const PRINT_FLAG = true;

export type AwardStarActionButtonT = {
  showAwardStar: boolean;
  showActionButton?: boolean;
  actionButtonLabel?: string;
  headerLabel?: string;
};

export type AwardStatePayload = {
  awardCode: string;
} & AwardStarActionButtonT;

export type CompletedStatePayload = {
  headerLabel: string;
} & AwardStarActionButtonT;

export type AwardStarState = AwardStatePayload & CompletedStatePayload;

export type CompleteNotificationState = {
  showNotification: boolean;
  showActionButton?: boolean;
  actionButtonLabel?: string;
  actionButtonIcon?: string;
  headerLabel?: string;
  notificationIcon?: string;
  bodyText?: string;
  bodyChildren?: ReactNode;
};

export type ColorOptions = {
  leftWallColor?: string;
  leftWallColorGradient?: string;
  centerWallColor?: string;
  rightWallColor?: string;
  rightWallColorGradient?: string;
  windowColor?: string;
};

export type AppUiState = {
  /** career status, used by loaders, and other FE components */
  initialized: boolean;
  appInitializedAt: number;
  appStatus: AppStatusT;
  appVersion: AppVersionId;
  adminNote: string;
  appConfig: AppConfigT;
  appConfigStatus: StatusT;
  /** expand / collapse career overview map panel */
  showThumbsUp: boolean;
  showBreadcrumbs: boolean;
  wallColors: ColorOptions;
  star: AwardStarState;
  completeNotification: CompleteNotificationState;
  /** cookie consent popup */
  showCookieConsent: boolean;
  /** app footer visibility */
  showAppFooter: boolean;
  /** show/hide the Help panel in CareerHub */
  showCareerHubHelpPanel: boolean;
  /** show/hide the Help panel in MyCareer Tutorial */
  showTutorialHelpPanel: boolean;
  /** show/hide the Help panel in MinusBalance game */
  showMinusBalanceHelpPanel: boolean;
  /** show/hide the Help panel in BridgeBuilder game */
  showBridgeBuilderHelpPanel: boolean;
};

export const starAwardInitState: AwardStarState = {
  showAwardStar: false,
  awardCode: '',
  headerLabel: 'GREAT JOB!',
  showActionButton: true,
  actionButtonLabel: 'COLLECT',
};

const completeNotificationInitState: CompleteNotificationState = {
  showNotification: false,
  showActionButton: true,
  actionButtonLabel: 'COMPLETE',
  actionButtonIcon: 'check_circle',
  headerLabel: 'TITLE',
  notificationIcon: 'thumb_up',
  bodyText: '',
  bodyChildren: '',
};

const getInitAppVersionId = () => {
  const version = document.head?.querySelector('[name~=version-info][content]') as HTMLHeadElement;
  const versionNumber = version && version.getAttribute ? version.getAttribute('content') : '0.0.Unit.Test';
  return versionNumber ?? '0.0.0';
};

const initialState: AppUiState = {
  appStatus: AppStatus.Unknown,
  adminNote: 'admin note',
  appVersion: getInitAppVersionId(),
  initialized: false,
  appInitializedAt: 0,
  appConfig: {
    news: {
      articles: [],
    },
  },
  appConfigStatus: Status.Idle,
  showBreadcrumbs: true,
  showThumbsUp: false,
  star: starAwardInitState,
  wallColors: {
    leftWallColor: defaultWallColor,
    leftWallColorGradient: defaultWallColorGradient,
    rightWallColor: defaultWallColor,
    rightWallColorGradient: defaultWallColorGradient,
    centerWallColor: defaultWallColor,
    windowColor: windowColor,
  },
  completeNotification: completeNotificationInitState,
  showCookieConsent: false,
  showAppFooter: true,
  showCareerHubHelpPanel: false,
  showTutorialHelpPanel: false,
  showMinusBalanceHelpPanel: false,
  showBridgeBuilderHelpPanel: false,
};

export const doInitApp = (): AppThunk => (dispatch, getState) => {
  if (getState().ui.appUi.appStatus === AppStatus.Unknown) {
    window.addEventListener('online', () => {
      isPrint(PRINT_FLAG) && console.log(...printMsg('StatusChange', 'appStatus', 'ONLINE'));
      dispatch(setAppStatus(AppStatus.Online));
    });
    window.addEventListener('offline', () => {
      isPrint(PRINT_FLAG) && console.log(...printMsg('StatusChange', 'appStatus', 'OFFLINE'));
      dispatch(setAppStatus(AppStatus.Offline));
    });
    dispatch(setAppStatus(navigator.onLine ? AppStatus.Online : AppStatus.Offline));
    // TODO: @Andrey11 Check if consent cookie exists
    dispatch(setShowCookieConsentPopup(true));
    dispatch(setAppInit(true));
    isPrint(PRINT_FLAG) && console.log(...printMsg('doInitApp', '', ''));
  }
};

export const appUiSlice = createSlice({
  name: 'appUi',
  initialState,
  reducers: {
    setAppStatus: (state, action: PayloadAction<AppStatusT>) => {
      state.appStatus = action.payload;
    },
    setAdminNote: (state, action: PayloadAction<string>) => {
      state.adminNote = action.payload;
    },
    setAppInit: (state, action: PayloadAction<boolean>) => {
      state.initialized = action.payload;
      state.appInitializedAt = Date.now();
    },
    setAppVersionId: (state, action: PayloadAction<AppVersionId>) => {
      state.appVersion = action.payload;
    },
    setAppConfig: (state, action: PayloadAction<AppConfigResponseT>) => {
      state.appConfig = action.payload.config;
      state.appConfigStatus = action.payload.status;
    },
    setAppConfigStatus: (state, action: PayloadAction<StatusT>) => {
      state.appConfigStatus = action.payload;
    },
    setShowThumbsUp: (state, action: PayloadAction<boolean>) => {
      state.showThumbsUp = action.payload;
    },
    setShowBreadcrumbs: (state, action: PayloadAction<boolean>) => {
      state.showBreadcrumbs = action.payload;
    },
    setWallColors: (state, action: PayloadAction<ColorOptions>) => {
      state.wallColors = action.payload;
    },
    setShowAwardStar: (state, action: PayloadAction<AwardStatePayload>) => {
      state.star = {
        ...state.star,
        ...action.payload,
      };
    },
    setShowStarPopup: (state, action: PayloadAction<Partial<AwardStarState>>) => {
      state.star = {
        ...state.star,
        ...action.payload,
      };
    },
    setShowCompleteNotification: (state, action: PayloadAction<boolean>) => {
      state.completeNotification.showNotification = action.payload;
    },
    setCompleteNotification: (state, action: PayloadAction<CompleteNotificationState>) => {
      state.completeNotification = { ...completeNotificationInitState, ...action.payload };
    },
    setShowCookieConsentPopup: (state, action: PayloadAction<boolean>) => {
      state.showCookieConsent = action.payload;
    },
    setShowAppFooter: (state, action: PayloadAction<boolean>) => {
      state.showAppFooter = action.payload;
    },
    setShowCareerHubHelpPanel: (state, action: PayloadAction<boolean>) => {
      state.showCareerHubHelpPanel = action.payload;
    },
    setShowTutorialHelpPanel: (state, action: PayloadAction<boolean>) => {
      state.showTutorialHelpPanel = action.payload;
    },
    setShowMinusBalanceHelpPanel: (state, action: PayloadAction<boolean>) => {
      state.showMinusBalanceHelpPanel = action.payload;
    },
    setShowBridgeBuilderHelpPanel: (state, action: PayloadAction<boolean>) => {
      state.showBridgeBuilderHelpPanel = action.payload;
    },
  },
});

export const {
  setAppInit,
  setAdminNote,
  setAppStatus,
  setAppVersionId,
  setAppConfig,
  setAppConfigStatus,
  setShowThumbsUp,
  setShowAwardStar,
  setShowStarPopup,
  setWallColors,
  setShowCompleteNotification,
  setCompleteNotification,
  setShowBreadcrumbs,
  setShowCookieConsentPopup,
  setShowAppFooter,
  setShowCareerHubHelpPanel,
  setShowTutorialHelpPanel,
  setShowMinusBalanceHelpPanel,
  setShowBridgeBuilderHelpPanel,
} = appUiSlice.actions;

export const getIsAppInit = (state: RootState): boolean => state.ui.appUi.initialized;
export const getAppStatus = (state: RootState): AppStatusT => state.ui.appUi.appStatus;
export const getAppConfigStatus = (state: RootState): StatusT => state.ui.appUi.appConfigStatus;
export const getAppConfigNews = (state: RootState): ArticlesConfigT => state.ui.appUi.appConfig.news;
export const getAppInitTime = (state: RootState): number => state.ui.appUi.appInitializedAt;
export const getAppVersionId = (state: RootState): AppVersionId => state.ui.appUi.appVersion;
export const getShowThumbsUp = (state: RootState): boolean => state.ui.appUi.showThumbsUp;
export const getShowBreadcrumbs = (state: RootState): boolean => state.ui.appUi.showBreadcrumbs;
export const getWallColors = (state: RootState): ColorOptions => state.ui.appUi.wallColors;
export const getShowAwardStar = (state: RootState): boolean => state.ui.appUi.star.showAwardStar;
export const getAwardCode = (state: RootState): string => state.ui.appUi.star.awardCode;
export const getHeaderLabel = (state: RootState): string => state.ui.appUi.star.headerLabel;
export const getShowAwardStarButton = (state: RootState): boolean => !!state.ui.appUi.star.showActionButton;
export const getAwardStarButtonLabel = (state: RootState): string => state.ui.appUi.star.actionButtonLabel ?? 'COLLECT';
export const getShowCompleteNotification = (state: RootState): boolean =>
  state.ui.appUi.completeNotification.showNotification;
export const getCompleteNotification = (state: RootState): CompleteNotificationState =>
  state.ui.appUi.completeNotification;
export const getShowCookieConsentPopup = (state: RootState): boolean => state.ui.appUi.showCookieConsent;
export const getShowAppFooter = (state: RootState): boolean => state.ui.appUi.showAppFooter;
export const getShowCareerHubHelpPanel = (state: RootState): boolean => state.ui.appUi.showCareerHubHelpPanel;
export const getShowTutorialHelpPanel = (state: RootState): boolean => state.ui.appUi.showTutorialHelpPanel;
export const getShowMinusBalanceHelpPanel = (state: RootState): boolean => state.ui.appUi.showMinusBalanceHelpPanel;
export const getShowBridgeBuilderHelpPanel = (state: RootState): boolean => state.ui.appUi.showBridgeBuilderHelpPanel;

export const getAdminNote = (state: RootState): string => state.ui.appUi.adminNote;

export default appUiSlice.reducer;
