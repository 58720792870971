import { store } from 'app/store';
import { Arithmetic, ArithmeticT, AwardKindT, CareerItem, CrestItemT, VisualState } from 'types/App.types';
import { CareerChainCard, CareerChainCards } from 'types/BaseCareer.types';
import { BadgeT, CareerBadgeItemT, CrestId, CrestT } from 'types/Career.types';
import {
  getCareerBadgeAwardPopupOpen,
  setCareerBadgeAwardPopupOpen,
  setCrestForAwardPopup,
} from 'modules/career/careerUiSlice';

export const Badges = {
  TUTORIAL_COUNT10: 'count:tutorial:10',
  TUTORIAL_COUNT20: 'count:tutorial:20',
  TUTORIAL_COUNT_DECIMAL_100: 'count:tutorial:decimal:100',
  TEST_COUNT10: 'count:test:10',
  TEST_COUNT20: 'count:test:20',
  TEST_COUNT_DECIMAL100: 'count:test:decimal:100',
};

export const EMPTY_BADGE_URL = '/images/crest-empty-300x300.webp';

export const crestTomato: CrestT = {
  title: 'LEVEL 2',
  imageUrl: '/images/crest-tomatoe-128x128.webp',
  type: Arithmetic.Add,
  variant: 'lesson',
  countUnit: '1',
  limit: 20,
  id: 'crest:lesson',
};

export const crestWatermelon: CrestT = {
  title: 'LEVEL 3',
  imageUrl: '/images/crest-watermelon-128x128.webp',
  type: Arithmetic.Add,
  variant: 'lesson',
  countUnit: '1',
  limit: 20,
  id: 'crest:lesson',
};

export const crestBlueberry: CrestT = {
  title: 'LEVEL 4',
  imageUrl: '/images/crest-blueberry-128x128.webp',
  type: Arithmetic.Add,
  variant: 'lesson',
  countUnit: '1',
  limit: 20,
  id: 'crest:lesson',
};

export const crestOrange: CrestT = {
  title: 'LEVEL 1',
  imageUrl: '/images/crest-orange-128x128.webp',
  type: Arithmetic.Add,
  variant: 'lesson',
  countUnit: '1',
  limit: 20,
  id: 'crest:lesson',
};

export const crestLemon: CrestT = {
  title: 'LEVEL 5',
  imageUrl: '/images/crest-lemon-128x128.webp',
  type: Arithmetic.Add,
  variant: 'lesson',
  countUnit: '1',
  limit: 20,
  id: 'crest:lesson',
};

export const crestLessonTomato: CrestT = {
  title: 'LEVEL 2',
  imageUrl: '/images/lesson-128x128.webp',
  type: Arithmetic.Add,
  variant: 'lesson',
  countUnit: '1',
  limit: 20,
  id: 'crest:lesson',
};

export const crestCourseTomato: CrestT = {
  title: 'LEVEL 2',
  imageUrl: '/images/course-128x128.webp',
  type: Arithmetic.Add,
  variant: 'course',
  countUnit: '1',
  limit: 20,
  id: 'crest:course',
};

export const crestCourseOrange: CrestT = {
  title: 'LEVEL 1',
  imageUrl: '/images/crest-courses-300x300.webp',
  type: Arithmetic.Add,
  variant: 'course',
  countUnit: '1',
  limit: 20,
  id: 'crest:course',
};

export const createCrest = (
  title: string,
  imageUrl: string,
  type: string,
  variant: CrestItemT,
  level: number
): CrestT => {
  const crest: CrestT = {
    title: title,
    imageUrl,
    kind: variant,
    arithmetic: type as ArithmeticT,
    level,
    type,
    variant,
    id: `crest:${variant}` as CrestId,
  };

  return crest;
};

export const getImageUrlByVariant = (variant: CrestItemT, level: number) => {
  const prefix = variant === 'lesson' ? 'v1' : 'v2';
  const suffix = 'crest-256x256.webp';
  if (level === 1) {
    return `/images/crests/orange-${prefix}-${suffix}`;
  } else if (level === 2) {
    return `/images/crests/tomato-${prefix}-${suffix}`;
  } else if (level === 3) {
    return `/images/crests/watermelon-${prefix}-${suffix}`;
  } else if (level === 4) {
    return `/images/crests/apple-${prefix}-${suffix}`;
  }
  return `/images/crests/lemon-${prefix}-${suffix}`;
};

export const getTypeByLevel = (level: number): ArithmeticT => {
  if (level === 1) {
    return Arithmetic.Count;
  } else if (level === 2) {
    return Arithmetic.Add;
  } else if (level === 3) {
    return Arithmetic.Subtract;
  } else if (level === 4) {
    return Arithmetic.Multiply;
  }
  return Arithmetic.Divide;
};

export const getArithmeticKindByTitle = (title: string): ArithmeticT => {
  if (title.toLowerCase() === 'counting') {
    return Arithmetic.Count;
  } else if (title.toLowerCase() === 'comparing') {
    return Arithmetic.Compare;
  } else if (title.toLowerCase() === 'addition') {
    return Arithmetic.Add;
  } else if (title.toLowerCase() === 'subtraction') {
    return Arithmetic.Subtract;
  } else if (title.toLowerCase() === 'multiplication') {
    return Arithmetic.Multiply;
  }
  return Arithmetic.Divide;
};

export const getBadgeByLevel = (level: number): CrestT => {
  if (level === 1) {
    return crestOrange;
  } else if (level === 2) {
    return crestTomato;
  } else if (level === 3) {
    return crestWatermelon;
  } else if (level === 4) {
    return crestBlueberry;
  }
  return crestLemon;
};

export const getLessonBadgeByLevel = (level: number): CrestT => {
  if (level === 1) {
    return crestOrange;
  } else if (level === 2) {
    return crestLessonTomato;
  } else if (level === 3) {
    return crestWatermelon;
  } else if (level === 4) {
    return crestBlueberry;
  }
  return crestLemon;
};

export const getCourseBadgeByLevel = (level: number): CrestT => {
  if (level === 1) {
    return crestOrange;
  } else if (level === 2) {
    return crestTomato;
  } else if (level === 3) {
    return crestWatermelon;
  } else if (level === 4) {
    return crestBlueberry;
  }
  return crestLemon;
};

export const getTutorialBadgeByLevel = (level: number): CrestT => {
  if (level === 1) {
    return crestOrange;
  } else if (level === 2) {
    return crestLessonTomato;
  } else if (level === 3) {
    return crestWatermelon;
  } else if (level === 4) {
    return crestBlueberry;
  }
  return crestLemon;
};

export const awardCrest = (variant: CrestItemT, level: number, arithmetic: ArithmeticT, name: string): CrestT => {
  const imageUrl = getImageUrlByVariant(variant, level);
  const crest: CrestT = createCrest(name, imageUrl, arithmetic, variant, level);

  return crest;
};

export const awardBadge = (badgeT: BadgeT, level: number) => {
  const variant = badgeT.variant as CrestItemT;
  const imageUrl = getImageUrlByVariant(variant, level);
  const crest: CrestT = createCrest(badgeT.name, imageUrl, badgeT.category, variant, level);
  store.dispatch(setCrestForAwardPopup(crest));

  setTimeout(() => {
    store.dispatch(setCareerBadgeAwardPopupOpen(true));
  }, 100);
};

export const awardCourseBadge = (badgeT: BadgeT, level: number) => {
  const crest: CrestT = {
    title: `LEVEL ${level}`,
    // imageUrl: badgeT && badgeT.imageUrl ? badgeT.imageUrl : getImageUrlByVariant('course', level),
    imageUrl: getImageUrlByVariant('course', level),
    type: badgeT ? badgeT.category : getTypeByLevel(level),
    variant: 'course',
    id: 'crest:course',
  };

  const intevalId = setInterval(() => {
    if (!getCareerBadgeAwardPopupOpen(store.getState())) {
      store.dispatch(setCrestForAwardPopup(crest));
      store.dispatch(setCareerBadgeAwardPopupOpen(true));
      clearInterval(intevalId);
    }
  }, 1000);
};

export const createCourseBadge = (courseCard: CareerChainCard): CareerBadgeItemT => {
  const courseBadge: CareerBadgeItemT = {
    imageUrl: getImageUrlByVariant('course', courseCard.level),
    completed: courseCard.visualState === VisualState.Completed,
    title: courseCard.versionedItemTitle,
    type: CareerItem.Course,
    category: courseCard.submitActionData?.value as ArithmeticT, // (courseUI.courseLessonUIs as LessonUIs)[0].category,
    completedDate: 0,
    level: courseCard.level,
    lessonCount: 10, // (courseUI.courseLessonUIs as LessonUIs).length,
  };

  return courseBadge;
};

export const createLessonBadge = (lessonCard: CareerChainCard): CareerBadgeItemT => {
  const courseBadge: CareerBadgeItemT = {
    imageUrl: getImageUrlByVariant('lesson', lessonCard.level),
    completed: lessonCard.visualState === VisualState.Completed,
    title: lessonCard.versionedItemTitle,
    type: CareerItem.Lesson,
    completedDate: 0,
    category: lessonCard.submitActionData?.value as ArithmeticT,
    tutorialTitle: 'title',
    quizTitle: 'quizTitle',
    quizQuestionCount: 10,
    level: lessonCard.level,
  };

  return courseBadge;
};

export const retrieveCareerBadges = (careerChainCards: CareerChainCards): Array<CareerBadgeItemT> => {
  const careerBadges: Array<CareerBadgeItemT> = [];

  careerChainCards.forEach((card: CareerChainCard) => {
    if (card.type === CareerItem.Course) {
      const courseBadge: CareerBadgeItemT = createCourseBadge(card);
      careerBadges.push(courseBadge);
    }

    if (card.type === CareerItem.Lesson) {
      const lessonBadge: CareerBadgeItemT = createLessonBadge(card);
      careerBadges.push(lessonBadge);
    }
  });

  return careerBadges;
};

export const getCodeFromAwardKind = (awardKind: AwardKindT): string => {
  return awardKind.slice(0, 3).toUpperCase();
};
