import { store } from 'app/store';
import { redirect } from 'react-router-dom';
import { Arithmetic, OPERATOR_SIGN } from 'types/App.types';
import { BoardTutorialResponseT } from 'types/Tutorial.types';

import { updateCareerCompleteList } from 'api/CareerAPI';

import Equation from 'components/equation/numeric/Equation';

import { getFormattedString, getNameClsString } from 'app/config/LogConfig';
import {
  getArithmeticOperationSelectorCard,
  getTomatoesFlipFruitToNumberPanel,
  getWatermelonFlipFruitToNumberPanel,
} from 'helpers/mathHelpers';
import { b } from 'helpers/uiHelpers';

import { getActiveCareerId, getUserStatus } from 'modules/user/userSlice';
import { addComplete } from 'modules/career/careerSlice';
import { doSetActiveScene } from 'scenes/scenesSlice';
import { isPrint } from 'helpers/appHelpers';

const PRIMARY_COLOR = getNameClsString('palegoldenrod');
const CLS_NAME = 'TutorialsAPI';
const PRINT_FLAG = true;

const printMsg = (action: string, prop: string, status: string) =>
  getFormattedString(PRIMARY_COLOR, CLS_NAME, action, prop, status);

const introCards: Array<JSX.Element> = [
  <span>Hello! Welcome to our Montessori inspired board tutorial.</span>,
  <span>
    The Montessori board helps you {b('understand')} the {b('fundamentals of arithmetic')}.
  </span>,
  <span>The Montessori board teaches {b('Counting, Addition, Subtraction, Multiplication and Division')}.</span>,
  <span>The Montessori board there is no need to {b('memorize the answers')}.</span>,
  <span>You will gain the understanding of {b('how to calculate')} the answers.</span>,
  <span>
    Instead of being {b('afraid of math')}, math will become your {b('friend')}!
  </span>,
  <span>We will be using a 10 by 10 {b('Montessori board')}.</span>,
  <span>
    The board consists of the {b('2 headers')}, {b('100 interactive cells')}, and a {b('reset cell')}.
  </span>,
  <span>The top row is called the {b('row header')}.</span>,
  <span>The left most column is called the {b('column header')}.</span>,
  <span>
    The other {b('100 cells')} on the board are used to {b('visually')} perform the arithmetic operations.
  </span>,
  <span>Calculations always begin from {b('top left corner')} of the board, the first interactive cell.</span>,
  <span>The Montessori board will also {b('visually')} demonstrate the concept of symmetry in math.</span>,
  <span>We will use different fruit when learning about each {b('arithmetic operation')}.</span>,
  <span>This will make it easier to {b('understand, visualize, and learn')} each operation!</span>,
  <span>
    And of course a {b('variety of fruit')} will make learning things much {b('more fun')}!
  </span>,
  <span>You have competed the introduction tutorial to the {b('Montessori Board')}!!!</span>,
  // getArithmeticOperationSelectorCard(),
];

const additionCards = [
  <span>Addition on the {b('Montessori Board')}</span>, // 0
  <span>Say we are asked to solve the equation {b('two plus one')}</span>, // 1
  <Equation operator={OPERATOR_SIGN.plus} term1={2} term2={1} result={'?'} size="lge" />, // 2
  <span>Let's use tomatoes to represent the number {b('2')}</span>, // 3
  getTomatoesFlipFruitToNumberPanel(2, 11), // 4
  <span>Now lets place the {b('2')} tomatoes one after the other on the board</span>, // 6
  <span>Remember, we always start from the top left corner of the board</span>, // 5
  <span>We will use another tomato to represent the second number {b('1')}</span>, // 7
  getTomatoesFlipFruitToNumberPanel(1, 6), // 8
  <span>Now we will add that {b('1')} tomato to the board after the other two.</span>, // 9
  <span>The answer to the question is the {b('total number')} of tomatoes on the board, which means...</span>, // 10
  <Equation operator={OPERATOR_SIGN.plus} term1={2} term2={1} result={3} size="lge" />, // 11
  <span>Well done! You are ready to solve the addition questions with the montessori board!</span>, // 12
  // getArithmeticOperationSelectorCard(),
];

const subtractionCards = [
  <span>Subtraction on the {b('Montessori Board')}</span>, // 0
  <span>Say we are asked to solve the equation {b('five minus three')}</span>, // 1
  <Equation operator={OPERATOR_SIGN.minus} term1={5} term2={3} result={'?'} size="med" />, // 1
  <span>Let's use watermelons to represent the number {b('5')}</span>, // 3
  getWatermelonFlipFruitToNumberPanel(5, 20), // 4
  <span>Remember, we always start from the top left corner of the board</span>, // 5
  <span>Now lets place all {b('5')} watermelons one after the other on the board</span>, // 6
  <span>Next we will use the watermelons to represent the number {b('3')}</span>, // 7
  getWatermelonFlipFruitToNumberPanel(3, 15), // 8
  <span>
    Next we will remove {b('1')} watermelon at a time from the board until we have removed {b('3')} watermelons.
  </span>, // 9
  <span>When performing subtraction, we begin removing items starting with the last placed item.</span>, // 10
  <span>The answer to the question is the {b('remaining number')} of watermelons on the board, which means...</span>, // 11
  <Equation operator={OPERATOR_SIGN.minus} term1={5} term2={3} result={2} size="med" />, // 12
  // getArithmeticOperationSelectorCard(),
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const boardTutorialSceneLoader = async ({ request }: any): Promise<BoardTutorialResponseT> => {
  const url = new URL(request.url);
  const mathId = url.searchParams.get('mathId');
  store.dispatch(doSetActiveScene('board:tutorial'));

  if (mathId) {
    isPrint(PRINT_FLAG) && console.log(...printMsg('boardTutorialSceneLoader', 'mathId', mathId));
    return boardTutorialArithmeticLoader(mathId);
  }

  isPrint(PRINT_FLAG) && console.log(...printMsg('boardTutorialSceneLoader', 'state', 'intro'));
  return { arithmeticType: Arithmetic.All, tutorialNotes: introCards, boardTitle: 'Board Tutorial Intro' };
};

const boardTutorialArithmeticLoader = async (arithmeticId: string): Promise<BoardTutorialResponseT> => {
  const multiplyNotes = [
    <>Multiplication with Montessori</>,
    <>How do multiply numbers?</>,
    getArithmeticOperationSelectorCard(),
  ];
  const divideNotes = [
    <>Division with Montessori</>,
    <>How do divide numbers?</>,
    getArithmeticOperationSelectorCard(),
  ];

  switch (arithmeticId) {
    case Arithmetic.Add:
      return { arithmeticType: Arithmetic.Add, tutorialNotes: additionCards, boardTitle: 'Addition Board Tutorial' };
    case Arithmetic.Subtract:
      return {
        arithmeticType: Arithmetic.Subtract,
        tutorialNotes: subtractionCards,
        boardTitle: 'Subtraction Board Tutorial',
      };
    case Arithmetic.Multiply:
      return {
        arithmeticType: Arithmetic.Multiply,
        tutorialNotes: multiplyNotes,
        boardTitle: 'Multiplication Board Tutorial',
      };
    case Arithmetic.Divide:
      return { arithmeticType: Arithmetic.Divide, tutorialNotes: divideNotes, boardTitle: 'Division Board Tutorial' };
    default:
      return { arithmeticType: Arithmetic.Unknown, tutorialNotes: [<></>], boardTitle: 'Board Tutorial' };
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const boardTutorialSceneAction = async ({ request }: any) => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('boardTutorialSceneAction', 'Completed Board Tutorial', ''));
  const formData = await request.formData();
  const redirectUrl = formData.get('redirectUrl');
  const completionId = formData.get('completionId');
  isPrint(PRINT_FLAG) && console.log(...printMsg('boardTutorialSceneAction', 'redirectUrl', redirectUrl));
  isPrint(PRINT_FLAG) && console.log(...printMsg('boardTutorialSceneAction', 'completionId', completionId));

  if (getUserStatus(store.getState()) === 'signedIn') {
    const careerId = getActiveCareerId(store.getState());
    // const completionId = `career:boardtutorial=${mathId}`;
    await updateCareerCompleteList(careerId, completionId);
    store.dispatch(addComplete(completionId));
  }

  return redirect(redirectUrl ?? '..');
};
