export const AppEvent = {
  SelectContent: 'select_content',
  Search: 'search',
  UnlockAchievement: 'unlock_achievement',
  Exception: 'exception',
};

export const AchievementType = {
  Game: { achievement_id: 'gameId', achievement_type: 'game', achievement_name: 'gameTitle' },
  Star: { achievement_id: 'awardId', achievement_type: 'star', achievement_name: 'starTitle' },
} as const;

export type AchievementT = (typeof AchievementType)[keyof typeof AchievementType];

export const getUnlockedAchievement = (achievement: AchievementT, id: string, name?: string) => {
  return {
    ...achievement,
    achievement_id: id,
    achievement_name: name ?? achievement.achievement_name,
  };
};

export const SelectContentType = {
  Autoplay: { content_type: 'enable_autoplay', content_id: 'career_id', action: 'true/false' },
  HideCarousel: { content_type: 'hide_carousel', content_id: 'career_id', action: 'true/false' },
  HideCompletedCoursesOnCarousel: {
    content_type: 'hide_completed_courses_on_carousel',
    content_id: 'career_id',
    action: 'true/false',
  },
  QuickMenu: { content_type: 'quick_menu', content_id: 'career_id', action: 'expanded' },
  CourseManagement: { content_type: 'profile_management', content_id: 'course_id', action: 'add/remove course' },
  AvatarManagement: { content_type: 'profile_management', content_id: 'avatar_id', action: 'selected avatar' },
  DemoQuestion: { content_type: 'demo_question', content_id: 'arithmetic_type', action: 'open/complete' },
  ViewedScreenShot: { content_type: 'viewed_screenshot', content_id: 'imgSrc', action: 'full_screen' },
  ViewedCrest: { content_type: 'viewed_crest', content_id: 'crestId', action: 'full_screen' },
  ActivePanel: { content_type: 'action_panel', content_id: 'scroll_carousel', action: 'scroll_right/scroll_left' },
} as const;

export type SelectContentT = (typeof SelectContentType)[keyof typeof SelectContentType];

export const getSelectedContent = (content: SelectContentT, id?: string, action?: string) => {
  return { ...content, content_id: id ?? content.content_id, action: action ?? content.action };
};
