import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { queryAppVersionId } from 'helpers/appHelpers';
import { getCreateModifiedInfo, getModifiedInfo } from 'helpers/careerHelpers';
import { AppVersionId } from 'types/App.types';
import { NewsVisualState, PreferencesT, ProfileId } from 'types/User.types';

// #region CONVERTERS *******************************************************************
export const getInitialNewsPreferences = (): NewsVisualState => {
  const appVersionId: AppVersionId = queryAppVersionId() ?? '0.0.0';
  return {
    date: Date.now(),
    open: true,
    read: false,
    appVersionId: appVersionId,
  };
};

export const getInitialPreferences = (profileId: ProfileId): PreferencesT => {
  const createModify = getCreateModifiedInfo();
  const news = getInitialNewsPreferences();
  return {
    preferencesId: 'CreatePreferencesId',
    profileId: profileId,
    autoplay: true,
    hideCarousel: false,
    hideCompletedCoursesOnCarousel: false,
    news: { ...news },
    ...createModify,
  };
};

export const preferencesToDoc = (preferences: PreferencesT): DocumentData => {
  return {
    preferencesId: preferences.preferencesId,
    profileId: preferences.profileId,
    autoplay: preferences.autoplay,
    hideCarousel: preferences.hideCarousel,
    hideCompletedCoursesOnCarousel: !!preferences.hideCompletedCoursesOnCarousel,
    news: preferences.news,
    createdBy: preferences.createdBy,
    createdOn: preferences.createdOn,
    modifiedBy: preferences.modifiedBy,
    modifiedOn: preferences.modifiedOn,
  };
};

export const preferencesConverter: FirestoreDataConverter<PreferencesT> = {
  toFirestore(preferences: PreferencesT): DocumentData {
    return { ...preferencesToDoc(preferences), ...getModifiedInfo() };
  },

  fromFirestore(docSnap: QueryDocumentSnapshot, options?: SnapshotOptions): PreferencesT {
    const preferences = docSnap.data(options);
    return {
      preferencesId: preferences.preferencesId,
      profileId: preferences.profileId,
      autoplay: preferences.autoplay,
      hideCarousel: preferences.hideCarousel,
      hideCompletedCoursesOnCarousel: !!preferences.hideCompletedCoursesOnCarousel,
      news: preferences.news,
      createdBy: preferences.createdBy,
      createdOn: preferences.createdOn,
      modifiedBy: preferences.modifiedBy,
      modifiedOn: preferences.modifiedOn,
    };
  },
};
// #endregion
