import React, { useState } from 'react';
import { useAppSelector } from 'app/hooks/hooks';

import { PropsWithChildren, ElementSizeT, VisualStateT, VisualState } from 'types/App.types';
import { CareerChainCard, CareerChainCards, CourseCardT, CourseCardsT, LessonCardT } from 'types/BaseCareer.types';

import { transformCareerChainIntoHierarchy } from 'modules/career/careerSlice';

import Carousel from 'components/carousel/Carousel';
import IconButton from 'components/buttons/icon/IconButton';
import Icon from 'components/icon/Icon';
import InsetText from 'components/text/inset/InsetText';
import Popup from 'components/popup/Popup';

import styles from './DebugCareerStatus.module.scss';

// #region HELPER COMPONENTS FOR DEBUG POPUP
type VisualStateProp = {
  visualState: VisualStateT;
};

type ChainCardProp = {
  card: CareerChainCard;
};

const StatusSpan: React.FC<VisualStateProp> = (props) => {
  const getStatusCls = (visualState: VisualStateT): string => {
    if (visualState === VisualState.Active) {
      return styles.StatusActive;
    } else if (visualState === VisualState.Completed) {
      return styles.StatusComplete;
    } else {
      return styles.StatusLocked;
    }
  };

  const getStatusIcon = (visualState: VisualStateT): JSX.Element => {
    if (visualState === VisualState.Active) {
      return <Icon icon="local_police" />;
    } else if (visualState === VisualState.Completed) {
      return <Icon icon="verified_user" />;
    } else {
      return <Icon icon="shield_lock" />;
    }
  };

  return (
    <span className={getStatusCls(props.visualState)}>
      <InsetText size="sma">{getStatusIcon(props.visualState)}</InsetText>
    </span>
  );
};

const IconSpan: React.FC<VisualStateProp> = (props) => (
  <span className={`${styles.ElevatedPanel} ${styles.IconSpot}`}>
    <StatusSpan visualState={props.visualState} />
  </span>
);

type TitleSpanProps = {
  title: string;
  size?: ElementSizeT;
};
const TitleSpan: React.FC<TitleSpanProps> = ({ title, size = 'sma' }): JSX.Element => (
  <span className={`${styles.ElevatedPanel} ${styles.TitleSpot}`}>
    <InsetText size={size}>{title}</InsetText>
  </span>
);

const QuestionIconSpan: React.FC<VisualStateProp> = (props) => (
  <span className={`${styles.IconSpot} ${styles.IconSpotQuestion}`}>
    <InsetText size="xs75">
      <StatusSpan visualState={props.visualState} />
    </InsetText>
  </span>
);

type QuestionsSpanProps = {
  questionCards: CareerChainCards;
};
const QuestionsSpan: React.FC<QuestionsSpanProps> = (props) => (
  <div className={`${styles.ElevatedPanel} ${styles.TitleSpot} Questions`}>
    <InsetText size="sma">Quiz</InsetText>
    <Carousel carouselDisplayMode="MINI_MODE" className={styles.CarouselOverrideCls}>
      <div className={`${styles.Questions}`}>
        {props.questionCards.map((card, index) => (
          <QuestionIconSpan visualState={card.visualState} key={`k${card.versionedItemId}_${index}`} />
        ))}
      </div>
    </Carousel>
  </div>
);

const QuizCarousel: React.FC<PropsWithChildren<ChainCardProp>> = ({ card, ...props }) => (
  <div className={`${styles.CardRow} Quiz`}>
    <IconSpan visualState={card.visualState} key={`keyQuiz${card.versionId}`} />
    {props.children}
  </div>
);

const TutorialSpan: React.FC<ChainCardProp> = ({ card }) => (
  <span className={`${styles.CardRow} Tutorial`}>
    <IconSpan visualState={card.visualState} key={card.versionId} />
    <TitleSpan title={`Tutorial ${card.versionedItemTitle}`} />
  </span>
);

const LessonTitleSpan: React.FC<ChainCardProp> = ({ card }) => (
  <span className={`${styles.CardRow} Lesson`}>
    <IconSpan visualState={card.visualState} key={`keyLesson${card.versionId}`} />
    <TitleSpan title={`Lesson: ${card.versionedItemTitle}`} />
  </span>
);

const Lesson: React.FC<PropsWithChildren<ChainCardProp>> = ({ card, ...props }) => (
  <div key={`k_${card.versionedItemId}_${card.versionId}`} className={styles.LessonPanel}>
    <LessonTitleSpan card={card} />
    {props.children}
  </div>
);

const TitleCmp: React.FC<ChainCardProp> = ({ card }) => (
  <span className={`${styles.CardRow} Course`}>
    <IconSpan visualState={card.visualState} key={`keyCourse${card.versionId}`} />
    <TitleSpan title={`Course: ${card.versionedItemTitle}`} />
  </span>
);

const CourseCard: React.FC<PropsWithChildren<ChainCardProp>> = ({ card, ...props }) => (
  <div key={`k_${card.versionedItemId}`} className={styles.CourseCardBorder}>
    <div className={styles.CourseCard}>
      <TitleCmp card={card} />
      {props.children}
    </div>
  </div>
);
// #endregion

const DebugCareerStatus: React.FC = () => {
  const debugInfoRef = React.createRef<HTMLDivElement>();
  const courseCards: CourseCardsT = useAppSelector(transformCareerChainIntoHierarchy);
  const [showDebugPanel, setShowDebugPanel] = useState<boolean>(false);

  const onDebugPanelClose = () => setShowDebugPanel(!showDebugPanel);
  const onDebugButtonTapped = () => setShowDebugPanel(!showDebugPanel);

  return (
    <>
      <IconButton icon="logo_dev" onClick={onDebugButtonTapped} />

      <Popup
        id="DebugPopupId"
        headerTitle="DEBUG"
        ref={debugInfoRef}
        show={!!showDebugPanel}
        popupType="wood"
        className={styles.DebugPopup}
        bodyClassName={styles.DebugPopupBodyPanel}
        onCloseEvent={onDebugPanelClose}>
        <>
          {courseCards.map((course: CourseCardT, index: number) => (
            <CourseCard key={`k${course.courseCard.versionedItemId}_${index}`} card={course.courseCard}>
              {course.lessonCards.map((l: LessonCardT) => (
                <Lesson card={l.lessonCard} key={`k${l.lessonCard.versionedItemId}`}>
                  <TutorialSpan card={l.tutorialCard} />
                  <QuizCarousel card={l.quiz.quizCard}>
                    <QuestionsSpan questionCards={l.quiz.questionCards} />
                  </QuizCarousel>
                </Lesson>
              ))}
            </CourseCard>
          ))}
        </>
      </Popup>
    </>
  );
};

export default DebugCareerStatus;
