import React, { AnimationEvent } from 'react';
import type { IconProps } from 'types/App.types';

import styles from './Icon.module.scss';

const Icon: React.FC<IconProps> = ({
  size = 'sma',
  className = '',
  isDanger = false,
  isSuccess = false,
  isFilled = false,
  isDisabled = false,
  isUnselected = false,
  showPointerCursor = false,
  ...props
}) => {
  const baseIcon = isFilled ? `${styles.Icon} ${styles.IconFilled}` : styles.Icon;
  const dangerCls = isDanger ? styles.DangerBg : '';
  const unselectedCls = isUnselected ? styles.UnselectedColor : '';
  const successCls = isSuccess ? styles.SuccessBg : '';
  const cursorCls = showPointerCursor ? styles.UsePointerCursor : '';
  const disabledCls = isDisabled ? styles.DisabledButton : '';
  const customCls = `${dangerCls} ${successCls} ${unselectedCls} ${cursorCls} ${disabledCls}`;

  const onAnimationEnd = (e: AnimationEvent) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  return (
    <span
      aria-label={`${props.icon} icon`}
      className={`material-symbols-outlined ${baseIcon} ${className} ${size} ${customCls}`}
      title={props.title}
      {...props}
      onAnimationEnd={onAnimationEnd}
      style={props.style}
      onClick={props.onClick}>
      {props.icon}
    </span>
  );
};

export default Icon;
