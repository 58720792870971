import React, { useEffect, useState } from 'react';
import { PropsWithChildren, VoidFunc } from 'types/App.types';

import CollapsibleListHandle, {
  CollapsibleListHandleProps,
} from 'components/list/collapsible/components/handle/CollapsibleListHandle';
import type { ListBaseProps } from 'components/list/List';

import styles from './CollapsibleList.module.scss';

type CollapsibleListOwnProps = {
  open?: boolean;
  handle?: React.ReactNode;
  isListOpen?: (open: boolean) => void;
  onListClosing?: VoidFunc;
  onListOpening?: VoidFunc;
  isCareerCollapsibleList?: boolean;
} & ListBaseProps &
  CollapsibleListHandleProps;

type CollapsibleListProps = PropsWithChildren<CollapsibleListOwnProps>;

const CollapsibleList: React.FC<CollapsibleListProps> = ({
  open = true,
  originPosition = 'pos-top',
  originTransform = 'transform-top',
  onListClosing = () => undefined,
  onListOpening = () => undefined,
  ...props
}) => {
  const [openCls, setOpenCls] = useState<string>(open ? 'open' : '');
  const [isOpen, setOpen] = useState<boolean>(!!open);
  const propsStyleCls = `${originPosition} ${originTransform}`;
  const baseCls = `${styles.CollapsibleList} ${propsStyleCls}`;
  const customCls = props.className ?? '';

  useEffect(() => {
    setOpen(open);
  }, [open]);

  useEffect(() => {
    if (isOpen) {
      onListOpening();
      setOpenCls('open');
    } else {
      onListClosing();
      setOpenCls('');
    }
  }, [isOpen, onListClosing, onListOpening]);

  const onHandleClicked = () => {
    setOpen(!isOpen);
    if (props.isListOpen) props.isListOpen(!isOpen);
  };

  return (
    <div className={`${baseCls} ${customCls} ${openCls}`}>
      <CollapsibleListHandle
        isOpen={isOpen}
        onHandleClicked={onHandleClicked}
        handleCloseIcon={props.handleCloseIcon}
        handleOpenIcon={props.handleOpenIcon}
        handleIconSize={props.handleIconSize}
        style={props.style}
        {...props}>
        {props.handle}
      </CollapsibleListHandle>
      <div className={`${styles.CollapsibleListWrapper} ${openCls}`}>{props.children}</div>
    </div>
  );
};

export default CollapsibleList;
