/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Link, useMatches } from 'react-router-dom';
import { PropsWithChildren } from 'types/App.types';

import Icon from 'components/icon/Icon';
import InsetIcon from 'components/icon/InsetIcon';
import InsetText from 'components/text/inset/InsetText';
import Rule from 'components/rule/Rule';

import styles from './Breadcrumbs.module.scss';
import HomeButton from 'components/buttons/homebutton/HomeButton';

type CrumbType = {
  crumb: (data: any, isLast: boolean, crumbCount: number) => void;
};
type HandleType = {
  handle: CrumbType;
};
type MatchType = {
  match: HandleType;
};
type OwnProps = {
  crumb: JSX.Element;
};
type BreadcrumbProps = PropsWithChildren<OwnProps>;

const Breadcrumb: React.FC<BreadcrumbProps> = (props) => {
  return (
    <span className={styles.CrumbWrapper}>
      {props.crumb}
      {props.children}
    </span>
  );
};

const Breadcrumbs: React.FC = () => {
  const matches = useMatches();

  const IconNext = <Icon size="lge" icon="navigate_next" />;

  const crumbs = matches
    // first get rid of any matches that don't have handle and crumb
    .filter((match) => Boolean((match.handle as MatchType) && (match.handle as CrumbType).crumb))
    // now map them into an array of elements, passing the loader data to each one
    .map((match, index, array) => {
      const handle = match.handle as CrumbType;
      // console.log(`Crumb data: ${match.data}, index: ${index}, array.length: ${array.length}`);
      return handle.crumb(match.data, index + 1 === array.length, array.length);
    });

  return (
    <div className={styles.BreadcrumbsWrapper}>
      <div className={styles.BreadcrumbsDisplay}>
        <HomeButton insetIcon isFilled style={{ marginRight: '0.25em' }} />
        {crumbs.map((crumb: any, index, arr) => (
          <Breadcrumb key={`cKey_${index}`} crumb={crumb}>
            {arr.length - index > 1 && IconNext}
          </Breadcrumb>
        ))}
      </div>
      <Rule size="thick" />
    </div>
  );
};

export const getBreadcrumbLinkCmp = (link: string, label: string) => (
  <Link to={link} relative="path">
    <InsetText>{label}</InsetText>
  </Link>
);

export const getBreadcrumbLinkIconCmp = (link: string, label: string, icon: string, replaceText: string) => {
  const textLabel: string = label.replace(replaceText, '').trim();

  return (
    <Link to={link} relative="path" state={{ from: 'breadcrumb' }}>
      <InsetIcon size="mel" icon={icon}>
        {textLabel}
      </InsetIcon>
    </Link>
  );
};

export const getCrumb = (text: string) => ({
  crumb: () => <InsetText>{text}</InsetText>,
});

export const getCrumbText = (text: string) => <InsetText capitalize>{text}</InsetText>;

export default Breadcrumbs;
