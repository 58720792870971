import { store } from 'app/store';
import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { getModifiedInfo } from 'helpers/careerHelpers';
import { Arithmetic } from 'types/App.types';
import { QuestionT } from 'types/Question.types';

// #region CONVERTERS ***********************************************************
export const getInitialQuestion = (): QuestionT => {
  const userId = store.getState().user.uid || '';
  const initQuestion: QuestionT = {
    questionId: 'createQuestionId',
    title: '',
    firstNum: 0,
    operator: '',
    secondNum: 0,
    expectedResult: 0,
    categoryType: Arithmetic.Count,
    level: 1,
    expectedResultCells: [],
    createdBy: userId,
    createdOn: Date.now(),
    modifiedBy: userId,
    modifiedOn: Date.now(),
    versionId: '',
  };

  return initQuestion;
};

export const questionToDoc = (question: QuestionT): DocumentData => {
  return {
    questionId: question.questionId,
    title: question.title,
    field1: question.firstNum,
    operator: question.operator,
    field2: question.secondNum,
    expectedAnswer: question.expectedResult,
    type: question.categoryType,
    level: question.level,
    expectedResultCells: question.expectedResultCells,
    createdBy: question.createdBy,
    createdOn: question.createdOn,
    modifiedBy: question.modifiedBy,
    modifiedOn: question.modifiedOn,
    versionId: question.versionId || '',
  };
};

export const questionConverter: FirestoreDataConverter<QuestionT> = {
  toFirestore(question: QuestionT): DocumentData {
    return { ...questionToDoc(question), ...getModifiedInfo() };
  },

  fromFirestore(docSnap: QueryDocumentSnapshot, options?: SnapshotOptions): QuestionT {
    const data = docSnap.data(options);

    return {
      questionId: data.questionId || data.id,
      title: data.title,
      firstNum: data.field1,
      operator: data.operator,
      secondNum: data.field2,
      expectedResult: data.expectedAnswer,
      expectedResultCells: data.expectedResultCells,
      categoryType: data.type,
      level: data.level,
      createdBy: data.createdBy,
      createdOn: data.createdOn,
      modifiedBy: data.modifiedBy,
      modifiedOn: data.modifiedOn,
      versionId: data.versionId,
    };
  },
};
// #endregion
