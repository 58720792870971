import { store } from 'app/store';
import { To, redirect } from 'react-router-dom';
import { db } from '../firebase/Firebase';
import { getDoc, doc, updateDoc, collection, query, getDocs, setDoc, where, orderBy } from 'firebase/firestore';

import { MaybeType, PublicEntity, VisualState, VisualStateT } from 'types/App.types';
import { BadgesT, CareerId } from 'types/Career.types';
import { CourseId, CourseIds, CourseT, CourseUI, CourseUIs, CoursesT, ManageCourseData } from 'types/Course.types';
import { LessonUI, LessonUIs } from 'types/Lesson.types';
import { QuizId, QuizUI } from 'types/Quiz.types';
import { VersionId } from 'types/BaseCareer.types';

import { useStyledLogger } from 'app/config/LogConfig';

import { getLessons, setLessonFilters } from './LessonsAPI';
import { updateCareerCompleteList } from './CareerAPI';
import { fetchPublicEntityIds } from 'api/PublicEntitiesAPI';
import { courseConverter, courseToDoc, getCreateCourse } from 'api/converters/CoursesConverter';

import { doSetActiveScene, setLastActiveManageScene } from 'scenes/scenesSlice';
import {
  setCourses,
  setCoursesStatus,
  setLessons,
  setLessonsStatus,
  setPublicCourseIds,
  updateCourse,
} from 'scenes/management/managerSlice';
import {
  addComplete,
  getLessonUIForCourseUI,
  getBadgesUi,
  getByIdCareerCourseUI,
  shouldCompleteCourse,
  updateCourseUI,
} from 'modules/career/careerSlice';
import { setToastNotification } from 'modules/user/userUiSlice';

import { getCareerToUrl, getCourseCompletionId, getCourseToUrl, getQuizToUrl } from 'helpers/routeHelpers';
import { castToCourseUIs } from 'helpers/typeCastingHelpers';
import { sortCourses } from 'helpers/sortHelpers';
import { toPublicCourseId } from 'helpers/careerHelpers';
import { isPrint } from 'helpers/appHelpers';
import { getToastSuccessPayload } from 'helpers/uiHelpers';
import { AppRoutes } from 'app/constants/Routes';

const CLS_NAME = 'CoursesAPI';
const PRIMARY_COLOR = 'saddlebrown';
const SECONDARY_COLOR = 'cornsilk';
const PRINT_FLAG = true;

const printMsg = useStyledLogger(CLS_NAME, PRIMARY_COLOR, SECONDARY_COLOR);

// #region     APIs **********************************************************************
export const getCourses = async (coursesList: CourseIds = [], onlyVersioned = true): Promise<CoursesT> => {
  const collectionData = collection(db, 'courses').withConverter(courseConverter);
  let q = onlyVersioned ? query(collectionData, orderBy('versionId')) : query(collectionData);

  if (coursesList.length > 0) {
    q = query(collectionData, where('courseId', 'in', [...coursesList]));
  }

  const querySnapshot = await getDocs(q);
  const courses: CoursesT = [];
  querySnapshot.docs.forEach((doc) => {
    courses.push({ ...doc.data(), courseId: doc.id });
  });
  isPrint(PRINT_FLAG) && console.log(...printMsg('getCourses', 'LOADED #courses', String(courses.length)));
  courses.sort(sortCourses);
  return courses;
};

export const fetchPublicCourses = async (): Promise<CoursesT> => {
  const publicCourseIds = await fetchPublicEntityIds(PublicEntity.Courses);
  const coursesUnfiltered: CoursesT = await getCourses();
  const courses: CoursesT = coursesUnfiltered.filter((course) => publicCourseIds.includes(toPublicCourseId(course)));
  // filter
  courses.sort(sortCourses);
  return courses;
};

export const getCourseUIs = async (
  careerId: CareerId,
  complete: Array<string>,
  careerVS: VisualStateT,
  courseIds?: CourseIds
): Promise<CourseUIs> => {
  const coursesT: CoursesT = await getCourses(courseIds);
  const badges: BadgesT = getBadgesUi(store.getState());
  return castToCourseUIs(careerId, complete, careerVS, badges, coursesT);
};

export const fetchLatestVersionCourse = async (courseId: CourseId): Promise<CourseT> => {
  const docRef = doc(db, `courses/${courseId}`).withConverter(courseConverter);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const docData: CourseT = { ...docSnap.data(), courseId: docSnap.id };
    return docData;
  } else {
    isPrint(PRINT_FLAG) && console.log(...printMsg('fetchLatestVersionCourse', 'Error', 'No such document!'));
    throw Error(`Course (${courseId}) unable to find`);
  }
};

export const fetchVersionedCourse = async (courseId: CourseId, versionId: VersionId): Promise<CourseT> => {
  const docRef = doc(db, `courses/${courseId}/versions/${versionId}`).withConverter(courseConverter);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const docData: CourseT = { ...docSnap.data(), courseId: docSnap.id };
    return docData;
  } else {
    isPrint(PRINT_FLAG) && console.log(...printMsg('fetchVersionedCourse', 'Error', 'No such document!'));
    throw Error(`Course (${courseId}) unable to find`);
  }
};

export const fetchCourseUI = async (
  careerId: CareerId,
  courseId: CourseId,
  complete: Array<string>,
  careerVS: VisualStateT,
  versionId: VersionId
): Promise<CourseUI> => {
  const courseT = await fetchVersionedCourse(courseId, versionId);
  const toUrl: To = getCourseToUrl(careerId, courseId);
  const completionId = getCourseCompletionId(courseId);
  const visualState: VisualStateT = complete.includes(completionId) ? VisualState.Completed : careerVS;
  const courseUI: CourseUI = { ...courseT, visualState, toUrl, completionId };
  return courseUI;
};

export const patchCourse = async (courseData: CourseT): Promise<void> => {
  const course: CourseT = { ...courseData, versionId: '' };
  course.versionId = await createCourseVersion(course);
  const docCourse = courseToDoc(course);

  const courseRef = doc(db, `courses/${course.courseId}`);
  return await updateDoc(courseRef, docCourse);
};

export const createCourse = async (course: CourseT): Promise<CourseT> => {
  const collectionData = collection(db, 'courses').withConverter(courseConverter);
  // Autogenerate id for this record
  const docRef = doc(collectionData);
  const createdCourse = { ...course, courseId: docRef.id };
  createdCourse.versionId = await createCourseVersion(createdCourse);
  // Create new record with autogenerated id as courseId
  await setDoc(docRef, createdCourse);
  return createdCourse as CourseT;
};

export const createCourseVersion = async (course: CourseT): Promise<VersionId> => {
  const collectionData = collection(db, `courses/${course.courseId}/versions`).withConverter(courseConverter);
  const docRef = doc(collectionData);
  const newC = { ...course, versionId: docRef.id } as CourseT;
  await setDoc(docRef, newC);
  return docRef.id as VersionId;
};
// #endregion

/** @deprecated */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const careerCourseSceneAction = async ({ params }: any): Promise<null> => {
  const { careerId, courseId } = params;
  isPrint(PRINT_FLAG) && console.log(...printMsg('Updating CareerCourseScene', 'courseId', courseId));
  const courseUI = getByIdCareerCourseUI(store.getState(), courseId);
  let redirectToUrl = '/';

  if (courseUI) {
    const completionId = String(courseUI.completionId);

    const shouldComplete = shouldCompleteCourse(store.getState(), courseId);
    if (shouldComplete) {
      isPrint(PRINT_FLAG) && console.log(...printMsg('CareerCourse updating completion', 'courseId', courseId));
      store.dispatch(addComplete(completionId));
      await updateCareerCompleteList(careerId, completionId);

      redirectToUrl = getCareerToUrl(careerId);
      // Update redux store with completed course
      const courseIndex = store.getState().career.courseIds.indexOf(courseId);
      const updatedCourseUI = { ...courseUI, visualState: VisualState.Completed } as CourseUI;
      store.dispatch(updateCourseUI({ courseUI: updatedCourseUI, index: courseIndex }));
      // and set the next course as active (if available)
      const nextIndex = courseIndex + 1;
      if (nextIndex < store.getState().career.courseIds.length) {
        const courseUIs = store.getState().career.courseUIs as CourseUIs;
        const nextCourseUI = courseUIs[nextIndex] as CourseUI;
        if (nextCourseUI.visualState !== VisualState.Completed) {
          store.dispatch(
            updateCourseUI({ courseUI: { ...nextCourseUI, visualState: VisualState.Active }, index: courseIndex })
          );
          const courseLessonUIs = nextCourseUI.courseLessonUIs as LessonUIs;
          const courseLessonUI = courseLessonUIs.find((c) => c.visualState !== VisualState.Completed) as LessonUI;
          redirectToUrl = getQuizToUrl(
            careerId,
            nextCourseUI.courseId,
            courseLessonUI.lessonId,
            courseLessonUI.versionedQuizId?.versionedItemId as QuizId
          );
        }
      }
      redirect(redirectToUrl);
    } else {
      // find next lesson
      const lessonUI: MaybeType<LessonUI> = getLessonUIForCourseUI(store.getState(), courseId);
      if (lessonUI) {
        redirectToUrl = String((lessonUI.quizUI as QuizUI).toUrl);
        redirect(redirectToUrl);
      }
    }
  }
  return null;
};

// #region     EDITOR LOADER & ACTION FUNCTIONS ******************************************

export const manageCoursesSceneLoader = async (): Promise<CoursesT> => {
  store.dispatch(doSetActiveScene('manage:course:collection'));
  store.dispatch(setLastActiveManageScene('course'));
  store.dispatch(setCoursesStatus('loading'));
  const courses = await getCourses([], false);
  const publicCourses = await fetchPublicEntityIds(PublicEntity.Courses);
  store.dispatch(setCourses({ collection: courses, status: 'loaded' }));
  store.dispatch(setPublicCourseIds({ collection: publicCourses, status: 'loaded' }));

  return courses;
};

export const manageCreateCourseSceneLoader = async (): Promise<ManageCourseData> => {
  store.dispatch(setLessonsStatus('loading'));
  store.dispatch(doSetActiveScene('manage:course:create'));
  const course = getCreateCourse(store.getState().user.uid);
  const lessons = await getLessons();
  store.dispatch(setLessons({ collection: lessons, status: 'loaded' }));
  return { course, lessons };
};

export const manageCreateCourseSceneAction = async () => {
  const managerCourses = store.getState().manager.courses;
  const indexOfCreatedItem = managerCourses.findIndex((ct) => ct.courseId === 'createCourseId');
  const course = managerCourses[indexOfCreatedItem] as CourseT;

  store.dispatch(setCoursesStatus('creating'));
  const toUpdateCareer: CourseT = {
    ...course,
    createdOn: Date.now(),
    modifiedOn: Date.now(),
  };
  const createdCourse = await createCourse(toUpdateCareer);
  store.dispatch(updateCourse({ course: createdCourse, index: indexOfCreatedItem }));
  store.dispatch(setCoursesStatus('created'));
  store.dispatch(setToastNotification(getToastSuccessPayload('Course Created')));
  return redirect(AppRoutes.ManagerCourse);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const manageEditCourseSceneLoader = async ({ params }: any): Promise<ManageCourseData> => {
  const courseId = params.courseId as CourseId;
  store.dispatch(setCoursesStatus('loading'));
  store.dispatch(doSetActiveScene('manage:course:edit'));
  const course = await fetchLatestVersionCourse(courseId);
  const lessons = await getLessons();
  store.dispatch(setLessons({ collection: lessons, status: 'loaded' }));
  setLessonFilters(lessons);
  store.dispatch(setCoursesStatus('loaded'));

  return { course, lessons };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const manageEditCourseSceneAction = async ({ params }: any) => {
  const templateId = params.courseId;
  const managerCourses = store.getState().manager.courses;
  const indexOfEditedItem = managerCourses.findIndex((ct) => ct.courseId === templateId);
  const course = managerCourses[indexOfEditedItem] as CourseT;

  store.dispatch(setCoursesStatus('updating'));
  const toUpdateCareer: CourseT = {
    ...course,
    modifiedOn: Date.now(),
    modifiedBy: store.getState().user.uid,
  };
  await patchCourse(toUpdateCareer);
  store.dispatch(setCoursesStatus('updated'));
  store.dispatch(setToastNotification(getToastSuccessPayload('Course Updated')));
  return redirect(AppRoutes.ManagerCourse);
};
// #endregion
