import { useEventCallback } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

export type ResizeDimensions = {
  width: number;
  height: number;
};

export const useAppResize = () => {
  const debounceRef = useRef<number>(Date.now());
  const runTimerRef = useRef<boolean>(false);
  const [appDimensions, setAppDimensions] = useState<ResizeDimensions>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = useEventCallback(() => {
    const nowDate = Date.now();
    if (nowDate - debounceRef.current > 800) {
      debounceRef.current = nowDate;
      setAppDimensions({ width: Math.round(window.innerWidth), height: Math.round(window.innerHeight) });
    } else if (!runTimerRef.current) {
      runTimerRef.current = true;
      setTimeout(() => {
        setAppDimensions({ width: Math.round(window.innerWidth), height: Math.round(window.innerHeight) });
        runTimerRef.current = false;
      }, 1000);
    }
  });

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    debounceRef.current = Date.now();
  }, [appDimensions]);

  return appDimensions;
};
