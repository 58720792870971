import { store } from 'app/store';
import { db } from '../firebase/Firebase';
import { collection, query, getDocs, doc, setDoc, updateDoc, getDoc, where } from 'firebase/firestore';

import { getFormattedString, getNameClsString } from 'app/config/LogConfig';
import { ArticleId, ArticleIds, NewsArticle, NewsArticles } from 'types/News.types';

import { isPrint } from 'helpers/appHelpers';

import { setAppNewsArticles, setAppNewsStatus } from 'scenes/montessori/newsSlice';
import { setNewsStatus as setManageNewsStatus, setNews as setManageNews, setNewsStatus, updateNewsArticle } from 'scenes/management/managerSlice';

import { articleConverter, articleToDoc, getInitialNewsArticle } from 'api/converters/NewsConverter';
import { doSetActiveScene, setLastActiveManageScene } from 'scenes/scenesSlice';
import { ArticlesConfigT, ManageNewsArticleUpdateResponseT, Status } from 'types/App.types';
import { setToastNotification } from 'modules/user/userUiSlice';
import { getToastSuccessPayload } from 'helpers/uiHelpers';
import { redirect } from 'react-router-dom';
import { getAppConfigNews } from 'scenes/montessori/appUiSlice';
import { AppRoutes } from 'app/constants/Routes';

const PRIMARY_COLOR = getNameClsString('dodgerblue', 'cornsilk');
const CLS_NAME = 'NewsAPI';
const PRINT_FLAG = true;

const printMsg = (action: string, prop: string, status: string) =>
  getFormattedString(PRIMARY_COLOR, CLS_NAME, action, prop, status);

// #region API FUNCTIONS ****************************************************************
export const fetchArticles = async (articleIds: ArticleIds = []): Promise<NewsArticles> => {
  const collectionData = collection(db, 'news');
  let q = query(collectionData);

  if (articleIds.length > 0) {
    q = query(collectionData, where('articleId', 'in', [...articleIds]));
  }

  const querySnapshot = await getDocs(q);
  const articles: NewsArticles = [];
  querySnapshot.docs.forEach((doc) => {
    const docData = doc.data() as NewsArticle;
    articles.push({ ...docData });
  });

  return articles;
};

export const fetchArticle = async (articleId: ArticleId): Promise<NewsArticle> => {
  const docRef = doc(db, `news/${articleId}`).withConverter(articleConverter);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const newsArticle: NewsArticle = docSnap.data();
    return newsArticle;
  } else {
    isPrint(PRINT_FLAG) && console.log(...printMsg('Loading news article ERROR', 'No such document!', articleId));
    throw Error(`Article (${articleId}) unable to find`);
  }
};

export const updateArticle = async (article: NewsArticle): Promise<void> => {
  const recordRef = doc(db, `news/${article.articleId}`);
  const docArticle = articleToDoc(article);
  return await updateDoc(recordRef, docArticle);
};

export const createArticle = async (article: NewsArticle): Promise<NewsArticle> => {
  const collectionData = collection(db, 'news').withConverter(articleConverter);
  const docRef = doc(collectionData);
  const createdArticle: NewsArticle = { ...article, articleId: docRef.id };
  // Create new record with autogenerated id as messageId
  await setDoc(docRef, createdArticle);
  isPrint(PRINT_FLAG) && console.log(...printMsg('Created Article', 'articleId', docRef.id));
  return createdArticle;
};
// #endregion

// #region APP NEWS LOADER & ACTION FUNCTIONS *******************************************
export const newsSceneLoader = async (): Promise<NewsArticles> => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('Load', 'NewsSceneLoader', ''));

  store.dispatch(setAppNewsStatus(Status.Loading));
  const newsArticle: ArticlesConfigT = getAppConfigNews(store.getState());
  const articles: NewsArticles = await fetchArticles(newsArticle.articles);
  isPrint(PRINT_FLAG) && console.log(...printMsg('LOADED News', 'count', String(articles.length)));
  store.dispatch(setAppNewsArticles(articles));
  store.dispatch(setAppNewsStatus(Status.Loaded));

  return articles;
};
// #endregion

// #region MANAGE NEWS LOADER & ACTION FUNCTIONS ****************************************
export const manageNewsCollectionSceneLoader = async (): Promise<NewsArticles> => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('Load', 'ManageNewsSceneLoader', ''));

  store.dispatch(doSetActiveScene('manage:news:collection'));
  store.dispatch(setLastActiveManageScene('news'));

  store.dispatch(setManageNewsStatus(Status.Loading));
  const articles: NewsArticles = await fetchArticles();
  isPrint(PRINT_FLAG) && console.log(...printMsg('LOADED ManageNews', 'count', String(articles.length)));
  store.dispatch(setManageNews({ collection: articles, status: Status.Loaded }));
  store.dispatch(setManageNewsStatus(Status.Loaded));

  return articles;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const manageEditNewsSceneLoader = async ({ params }: any): Promise<ManageNewsArticleUpdateResponseT> => {
  const articleId: ArticleId = params.newsId;

  isPrint(PRINT_FLAG) && console.log(...printMsg('Load', 'ManageEditNewsSceneLoader', ''));

  store.dispatch(doSetActiveScene('manage:news:edit'));
  store.dispatch(setLastActiveManageScene('news'));

  const newsArticle: NewsArticle = await fetchArticle(articleId);
  isPrint(PRINT_FLAG) && console.log(...printMsg('LOADED ManageEditNewsArticle', 'articleId', newsArticle.articleId));
  const index = store.getState().manager.news.findIndex((n) => n.articleId === articleId);

  return { index, newsArticle };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const manageEditNewsSceneAction = async ({ params }: any): Promise<Response> => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('Update', 'ManageEditNewsSceneLoader', ''));
  const articleId: ArticleId = params.newsId;
  const managerNews = store.getState().manager.news;
  const indexOfItem = managerNews.findIndex((n) => n.articleId === articleId);
  const article = managerNews[indexOfItem] as NewsArticle;
  store.dispatch(setNewsStatus(Status.Updating));

  await updateArticle(article);
  store.dispatch(updateNewsArticle({ index: indexOfItem, newsArticle: article }));
  store.dispatch(setNewsStatus(Status.Updated));
  store.dispatch(setAppNewsStatus(Status.Idle));
  store.dispatch(setToastNotification(getToastSuccessPayload('News Article Updated')));
  return redirect(AppRoutes.ManagerNews);
};

export const manageCreateNewsSceneLoader = async (): Promise<ManageNewsArticleUpdateResponseT> => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('Load', 'ManageCreateNewsSceneLoader', ''));

  store.dispatch(doSetActiveScene('manage:news:create'));
  store.dispatch(setLastActiveManageScene('news'));

  const article: NewsArticle = getInitialNewsArticle();
  isPrint(PRINT_FLAG) && console.log(...printMsg('LOADED ManageCreateNewsArticle', 'articleId', article.articleId));

  return { index: -1, newsArticle: article };
};

export const manageCreateNewsSceneAction = async (): Promise<Response> => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('Create', 'ManageCreateNewsSceneLoader', ''));

  const managerNews = store.getState().manager.news;
  const indexOfCreatedItem = managerNews.findIndex((n) => n.articleId === 'createArticleId');
  const article = managerNews[indexOfCreatedItem] as NewsArticle;
  store.dispatch(setNewsStatus(Status.Creating));

  const createdArticle = await createArticle(article);
  store.dispatch(updateNewsArticle({ index: indexOfCreatedItem, newsArticle: createdArticle }));
  store.dispatch(setNewsStatus(Status.Created));
  store.dispatch(setAppNewsStatus(Status.Idle));
  store.dispatch(setToastNotification(getToastSuccessPayload('News Article Created')));
  return redirect(AppRoutes.ManagerNews);
};
// #endregion
