import { GameIconsT, GameIcons } from 'types/Game.types';

export const MathOperator = {
  Add: 'add',
  Equal: 'equal',
  GreaterThan: 'chevron_right',
  LessThan: 'chevron_left',
  Multiply: 'close',
  Question: 'question_mark',
} as const;

export const CareerIcons = {
  Career: 'school',
  Course: 'workspace_premium',
  Lesson: 'collections_bookmark',
  Tutorial: 'library_books',
  Quiz: 'quiz',
  Questions: 'help_center',
  Error: 'help_center',
  Game: 'joystick',
} as const;

export const CommonIcons = {
  AccountAdmin: 'supervisor_account',
  AddCreate: 'add_box',
  Analytics: 'analytics',
  AppRegistration: 'app_registration',
  Autoplay: 'motion_photos_auto',
  Awards: 'kid_star',
  Backspace: 'backspace',
  Badge: 'local_police',
  Bug: 'bug_report',
  Cancel: 'cancel',
  Calculate: 'calculate',
  Carousel: 'view_carousel',
  CarouselSettings: 'display_settings',
  CheckedBox: 'check_box',
  Clear: 'clear',
  Create: 'add_task',
  CreateAccount: 'person_check',
  DetailInfo: 'quick_reference',
  Dot: 'fiber_manual_record',
  EditorChoice: 'editor_choice',
  EditSquare: 'edit_square',
  Email: 'alternate_email',
  Error: 'error',
  Feedback: 'feedback',
  Find: 'mystery',
  Foot: 'footprint',
  FlipMedal: 'screen_rotation_alt',
  FormatFill: 'format_color_fill',
  Gallery: 'gallery_thumbnail',
  Help: 'help',
  Hexagon: 'hexagon',
  Home: 'hub',
  HomeHouse: 'home',
  Library: 'local_library',
  Locked: 'lock',
  LockReset: 'lock_reset',
  Login: 'login',
  Logout: 'logout',
  MapleFarm: 'hex',
  NavigateBack: 'arrow_back',
  NavNext: 'double_arrow',
  NavPrev: 'chevron_left',
  Number: 'numbers',
  PassKey: 'passkey',
  Password: 'password',
  Percent: 'percent',
  Person: 'person',
  PersonEdit: 'person_edit',
  Preview: 'preview',
  Profile: 'manage_accounts',
  Progress: 'insights',
  Read: 'notifications',
  ReferenceAll: 'quick_reference_all',
  Resume: 'resume',
  Send: 'send',
  Title: 'title',
  Trophy: 'trophy',
  UncheckedBox: 'check_box_outline_blank',
  Unlocks: 'lock',
  UnRead: 'notifications_unread',
  UnverifiedUser: 'gpp_bad',
  Upload: 'file_upload',
  User: 'user_attributes',
  UsernameInputIcon: 'account_box',
  VerifiedUser: 'verified_user',
} as const;

export const NewsIcons = {
  BrandAwareness: 'brand_awareness',
  BreakingNews: 'breaking_news',
  FullCoverage: 'full_coverage',
  New: 'fiber_new',
  NewReleases: 'new_releases',
  News: 'news',
  Newspaper: 'newspaper',
  ReleaseAlert: 'release_alert',
} as const;

export type NewsIconsT = (typeof NewsIcons)[keyof typeof NewsIcons];
export type MathOperatorT = (typeof MathOperator)[keyof typeof MathOperator];
export type CareerIconsT = (typeof CareerIcons)[keyof typeof CareerIcons];
export type CommonIconsT = (typeof CommonIcons)[keyof typeof CommonIcons];

export type IconNameMap = {
  MathOperator: Record<keyof typeof MathOperator, MathOperatorT>;
  GameIcons: Record<keyof typeof GameIcons, GameIconsT>;
  Career: Record<keyof typeof CareerIcons, CareerIconsT>;
  Common: Record<keyof typeof CommonIcons, CommonIconsT>;
  NewsIcons: Record<keyof typeof NewsIcons, NewsIconsT>;
};

export const AppIcons: Readonly<IconNameMap> = {
  MathOperator: { ...MathOperator },
  GameIcons: { ...GameIcons },
  Career: { ...CareerIcons },
  Common: { ...CommonIcons },
  NewsIcons: { ...NewsIcons },
};

// short form
export const MeIcons = {
  ...AppIcons.Career,
  ...AppIcons.Common,
  ...AppIcons.GameIcons,
  ...AppIcons.MathOperator,
  ...AppIcons.NewsIcons,
};
