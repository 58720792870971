import React, { AnimationEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks/hooks';
import { VoidFunc } from 'types/App.types';

import { isAnimationName } from 'helpers/uiHelpers';
import InsetText from 'components/text/inset/InsetText';
import ClosePopupButton from 'components/buttons/closepopup/ClosePopupButton';

import styles from './CompletionStar.module.scss';
import {
  getAwardStarButtonLabel,
  getShowAwardStarButton,
  getHeaderLabel,
  getShowAwardStar,
  setShowAwardStar,
  starAwardInitState,
} from 'scenes/montessori/appUiSlice';

// ENTRANCE/EXIT ANIMATIONS
export const EnterAnimation = {
  SlideInElliptic: 'slide-in-elliptic-bottom-fwd',
  FlipOnX: 'flip-x',
} as const;
export type EnterAnimationT = (typeof EnterAnimation)[keyof typeof EnterAnimation];

export const ExitAnimation = {
  SlideOutElliptic: 'slide-out-elliptic-top-bck',
} as const;
export type ExitAnimationT = (typeof ExitAnimation)[keyof typeof ExitAnimation];

type StarAnimationT = 'slide' | 'flip';

const getAnimationClsByType = (type: StarAnimationT) => {
  switch (type) {
    case 'slide':
      return styles.SlideElliptic;
    case 'flip':
      return styles.FlipOnX;
    default:
      return styles.SlideElliptic;
  }
};

type CompletionStarProps = {
  className?: string;
  starAnimation?: StarAnimationT;
  onEnterAnimationOver?: VoidFunc;
  onExitAnimationOver?: VoidFunc;
};

const CompletionStar: React.FC<CompletionStarProps> = ({ className = '', starAnimation = 'slide', ...props }) => {
  const baseCls = `${styles.CompletionStar} ${className}`;
  const starAnimationCls = styles.ScaleUpAndFlip;
  const animationCls = getAnimationClsByType(starAnimation);

  /** header text */
  const headerLabel = useAppSelector(getHeaderLabel);
  /** flag to trigger `show popup` action */
  const showAwardStar = useAppSelector(getShowAwardStar);
  /** @optional display the action button below the popup container */
  const showButton = useAppSelector(getShowAwardStarButton);
  /** @default COLLECT action button label */
  const buttonLabel = useAppSelector(getAwardStarButtonLabel);

  const [isVisible, setVisible] = useState<boolean>(false);
  const [animationStateCls, setAnimationStateCls] = useState<string>('');

  const dispatch = useAppDispatch();

  useEffect(() => {
    setAnimationStateCls(showAwardStar ? 'PLAY' : '');
  }, [showAwardStar]);

  const isEntranceAnimation = (name: string) =>
    isAnimationName(name, EnterAnimation.SlideInElliptic) || isAnimationName(name, EnterAnimation.FlipOnX);

  const isExitAnimation = (name: string) => isAnimationName(name, ExitAnimation.SlideOutElliptic);

  const onAnimationOver = (event: AnimationEvent) => {
    if (isEntranceAnimation(event.animationName)) {
      setVisible(true);
      if (props.onEnterAnimationOver) props.onEnterAnimationOver();
    } else if (isExitAnimation(event.animationName)) {
      setVisible(false);
      setAnimationStateCls('');
      dispatch(setShowAwardStar(starAwardInitState));
      if (props.onExitAnimationOver) props.onExitAnimationOver();
    }
  };

  const hideCompletionStar = () => {
    if (isVisible) setAnimationStateCls('OFF');
  };

  return (
    <div className={`${baseCls} ${animationStateCls}`} onClick={hideCompletionStar} onAnimationEnd={onAnimationOver}>
      <div className={`${styles.CompletionStarContents} ${animationCls}`}>
        <InsetText style={{ padding: '0 .5em' }} size="lge">
          {headerLabel}
        </InsetText>
        <span className={`${starAnimationCls}`}>
          <span aria-label="kid_star icon" className={`material-symbols-outlined ${styles.KidStar}`}>
            kid_star
          </span>
          <span aria-label="family_star icon" className={`material-symbols-outlined ${styles.StarOutline}`}>
            family_star
          </span>
        </span>
        <div className={`${styles.CompletionStarContentsTextPanel}`}>
          <span aria-label="kid_star icon_shadow" className={`material-symbols-outlined ${styles.StarSpot}`}>
            kid_star
          </span>
        </div>
        <ClosePopupButton
          className={styles.ActionButton}
          onClickHandler={hideCompletionStar}
          icon="local_police"
          showButton={showButton}
          buttonText={buttonLabel}
        />
      </div>
    </div>
  );
};

export default CompletionStar;
