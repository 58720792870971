import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { store } from 'app/store';
import App from '../App';

import ErrorBoundary from 'scenes/error/ErrorBoundary';
import PageNotFound from 'scenes/pagenotfound/PageNotFound';

import { AppStatus, LegalSubpageT, Status } from 'types/App.types';
import { CareerLoaderResponseT } from 'types/Career.types';
import { GameWithQuestionResponse } from 'types/Game.types';
import { LessonUI } from 'types/Lesson.types';
import { QuestionUI } from 'types/Question.types';
import { QuizUI } from 'types/Quiz.types';

import { appBadgesSceneLoader, appConfigLoader, montessoriLegalLoader, montessoriLegalSubpageLoader } from 'api/AppAPI';
import {
  ownUserAccountInfoSceneLoader,
  ownUserAccountInfoSceneAction,
  userStatusUpdated,
  ownUserAccountInfoURLLoader,
  ownUserAccountProfileInfoURLLoader,
  completeCreateOwnAccountLoader,
  completeCreateOwnAccountWithProfileAction,
  manageUserCollectionSceneLoader,
  manageEditUserSceneLoader,
  manageEditUserByUserIdAction,
  manageEditUserBreadcrumbUrlLoader,
} from 'api/UserAPI';
import {
  activeProfileCoursesLoader,
  authUserCreateProfileSceneAction,
  authUserCreateProfileSceneLoader,
  manageCreateProfileByUserIdAction,
  manageCreateProfileByUserIdLoader,
  manageEditProfileByUserIdAction,
  manageEditProfileByUserIdLoader,
  manageProfileByUserIdReportCardLoader,
  manageProfileCollectionSceneLoader,
  ownUserAccountSceneAction,
  ownUserAccountSceneLoader,
} from 'api/ProfilesAPI';
import {
  careerSceneLoader,
  careerSceneAction,
  manageCareersSceneLoader,
  manageEditCareerSceneLoader,
  manageEditCareerSceneAction,
  montessoriHubSceneLoader,
  montessoriHubSceneAction,
  careerSceneActionSimple,
  careerBadgesLoader,
  appCareerSceneLoader,
} from 'api/CareerAPI';
import {
  manageCreateQuizSceneAction,
  manageCreateQuizSceneLoader,
  manageEditQuizSceneAction,
  manageEditQuizSceneLoader,
  manageQuizCollectionSceneLoader,
  careerQuizSceneLoader,
  careerQuizSceneAction,
  manageEditVersionedQuizSceneLoader,
} from 'api/QuizzesAPI';
import {
  manageQuestionCollectionSceneLoader,
  manageCreateQuestionSceneAction,
  manageCreateQuestionSceneLoader,
  manageEditQuestionSceneAction,
  manageEditQuestionSceneLoader,
  careerQuestionSceneLoader,
  demoQuestionSceneLoader,
} from 'api/QuestionsAPI';
import {
  careerTutorialSceneLoader,
  careerVersionedTutorialSceneLoader,
  manageCreateTutorialSceneAction,
  manageCreateTutorialSceneLoader,
  manageEditTutorialSceneAction,
  manageEditTutorialSceneLoader,
  manageTutorialCollectionSceneLoader,
} from 'api/TutorialsAPI';
import {
  // careerLessonSceneAction,
  // careerLessonSceneLoader,
  manageCreateLessonSceneAction,
  manageCreateLessonSceneLoader,
  manageEditLessonSceneAction,
  manageEditLessonSceneLoader,
  manageLessonCollectionSceneLoader,
} from 'api/LessonsAPI';
import {
  manageCoursesSceneLoader,
  manageCreateCourseSceneAction,
  manageCreateCourseSceneLoader,
  manageEditCourseSceneAction,
  manageEditCourseSceneLoader,
} from 'api/CoursesAPI';
import { reportCardSceneLoader } from 'api/HistoryAPI';
import {
  appGamesCollectionLoader,
  gameByIdLoader,
  gameQuestionByIdLoader,
  gamesCollectionLoader,
  manageCreateGameQuestionSceneAction,
  manageCreateGameQuestionSceneLoader,
  manageCreateGameSceneAction,
  manageCreateGameSceneLoader,
  manageEditGameQuestionSceneAction,
  manageEditGameQuestionSceneLoader,
  manageEditGameSceneAction,
  manageEditGameSceneLoader,
  manageGameCollectionSceneLoader,
} from 'api/GameAPI';
import {
  appAwardsSceneLoader,
  manageAwardCollectionSceneLoader,
  manageCreateAwardSceneAction,
  manageCreateAwardSceneLoader,
  manageEditAwardSceneAction,
  manageEditAwardSceneLoader,
} from 'api/AwardsAPI';

import { useStyledLogger } from 'app/config/LogConfig';

import {
  isActiveProfileIdSelected,
  isAuthStateObserverMounted,
  subscribeUserStatusChange,
} from 'modules/user/userSlice';

import { RequireAuth } from 'app/hooks/useAuth';
import MontessoriHome from 'scenes/montessori/MontessoriHome';
import {
  getBreadcrumbLinkCmp,
  getBreadcrumbLinkIconCmp,
  getCrumbText,
  getCrumb,
} from 'components/breadcrumbs/Breadcrumbs';
import { UserStatusT } from 'types/User.types';
import { boardTutorialSceneAction, boardTutorialSceneLoader } from 'api/BoardTutorialAPI';
import {
  doInitApp,
  getAppConfigStatus,
  getAppInitTime,
  getAppStatus,
  getIsAppInit,
} from 'scenes/montessori/appUiSlice';
import {
  appUnlocksLoader,
  manageCreateUnlockSceneAction,
  manageCreateUnlockSceneLoader,
  manageEditUnlockSceneLoader,
  manageEditUnlocksSceneAction,
  manageUnlocksCollectionSceneLoader,
} from 'api/UnlocksAPI';
import { MeIcons } from 'app/constants/Constants';
import { AppRoutes } from 'app/constants/Routes';
import { studentProfileAction, studentProfileLoader } from 'api/StudentAPI';
import {
  manageCreateNewsSceneAction,
  manageCreateNewsSceneLoader,
  manageEditNewsSceneAction,
  manageEditNewsSceneLoader,
  manageNewsCollectionSceneLoader,
  newsSceneLoader,
} from 'api/NewsAPI';
import { getAppNewsStatus } from 'scenes/montessori/newsSlice';
import {
  appMedalSceneLoader,
  manageCreateMedalSceneAction,
  manageCreateMedalSceneLoader,
  manageEditMedalSceneAction,
  manageEditMedalsSceneLoader,
  manageMedalsCollectionSceneLoader,
} from 'api/MedalsAPI';

// #region LAZY IMPORTS
const SceneLayout = React.lazy(() => import('scenes/SceneLayout').then((module) => ({ default: module.SceneLayout })));
const DemoQuestion = React.lazy(() =>
  import('scenes/demo/DemoQuestion').then((module) => ({ default: module.DemoQuestion }))
);
const CareerHubDisplay = React.lazy(() =>
  import('modules/career/hub/CareerHub').then((module) => ({ default: module.CareerHub }))
);
const MontessoriBoardTutorialDisplay = React.lazy(() =>
  import('scenes/montessori/subscenes/montessoriboard/MontessoriBoardTutorial').then((module) => ({
    default: module.MontessoriBoardTutorial,
  }))
);
const BoardTutorialLayout = React.lazy(() =>
  import('scenes/montessori/subscenes/montessoriboard/BoardTutorialLayout').then((module) => ({
    default: module.BoardTutorialLayout,
  }))
);
const LegalDisplay = React.lazy(() =>
  import('scenes/legal/Legal').then((module) => ({
    default: module.Legal,
  }))
);
const LandingLegalSubscene = React.lazy(() =>
  import('scenes/legal/subscenes/LandingLegalSubscene').then((module) => ({
    default: module.LandingLegalSubscene,
  }))
);
const LegalSubscene = React.lazy(() =>
  import('scenes/legal/subscenes/LegalSubscene').then((module) => ({
    default: module.LegalSubscene,
  }))
);

// #region GAMES imports ****************************************************************
const GamesLayout = React.lazy(() =>
  import('scenes/games/GamesLayout').then((module) => ({ default: module.GamesLayout }))
);
const GameQuestionSelectionDisplay = React.lazy(() =>
  import('modules/games/GameQuestionSelection').then((module) => ({ default: module.GameQuestionSelection }))
);
const WakaFlipGameDisplay = React.lazy(() =>
  import('modules/games/wakaflip/WakaFlip').then((module) => ({ default: module.WakaFlip }))
);
const WakaCubesDisplay = React.lazy(() =>
  import('modules/games/wakacubes/WakaCubes').then((module) => ({ default: module.WakaCubes }))
);
const WackAMoleDisplay = React.lazy(() =>
  import('modules/games/wackamole/WackAMole').then((module) => ({ default: module.WackAMole }))
);
const WakaBakeGameDisplay = React.lazy(() =>
  import('modules/games/wakabake/WakaBake').then((module) => ({ default: module.WakaBake }))
);
const WakaMinusBalanceDisplay = React.lazy(() =>
  import('modules/games/wakaminusbalance/MinusBalance').then((module) => ({ default: module.MinusBalance }))
);
const WakaColumnBuilderDisplay = React.lazy(() =>
  import('modules/games/wakacolumnbuilder/ColumnBuilder').then((module) => ({ default: module.ColumnBuilder }))
);
const WakaWalkAboutDisplay = React.lazy(() =>
  import('modules/games/wakawalkabout/WalkAbout').then((module) => ({ default: module.WalkAbout }))
);
const GamesDisplay = React.lazy(() =>
  import('scenes/games/GamesGallery').then((module) => ({ default: module.GamesGallery }))
);
const GameSelectionDisplay = React.lazy(() =>
  import('scenes/games/GameSelection').then((module) => ({ default: module.GameSelection }))
);
// #endregion

// #region MANAGEMENT imports *************************************************/
const ManagementLayout = React.lazy(() =>
  import('scenes/management/Management').then((module) => ({ default: module.Management }))
);
const QuestionCollectionDisplay = React.lazy(() =>
  import('scenes/management/subscenes/managecollections/lists/QuestionCollection').then((module) => ({
    default: module.QuestionCollection,
  }))
);
const LessonsCollectionDisplay = React.lazy(() =>
  import('scenes/management/subscenes/managecollections/lists/LessonCollection').then((module) => ({
    default: module.LessonCollection,
  }))
);
const QuizCollectionDisplay = React.lazy(() =>
  import('scenes/management/subscenes/managecollections/lists/QuizCollection').then((module) => ({
    default: module.QuizCollection,
  }))
);
const TutorialCollectionDisplay = React.lazy(() =>
  import('scenes/management/subscenes/managecollections/lists/TutorialCollection').then((module) => ({
    default: module.TutorialCollection,
  }))
);
const CourseCollectionDisplay = React.lazy(() =>
  import('scenes/management/subscenes/managecollections/lists/CourseCollection').then((module) => ({
    default: module.CourseCollection,
  }))
);
const CareerCollectionDisplay = React.lazy(() =>
  import('scenes/management/subscenes/managecollections/lists/CareerCollection').then((module) => ({
    default: module.CareerCollection,
  }))
);
const ProfileCollectionDisplay = React.lazy(() =>
  import('scenes/management/subscenes/managecollections/lists/ProfileCollection').then((module) => ({
    default: module.ProfileCollection,
  }))
);
const UserCollectionDisplay = React.lazy(() =>
  import('scenes/management/subscenes/managecollections/lists/UserCollection').then((module) => ({
    default: module.UserCollection,
  }))
);
const AwardsCollectionDisplay = React.lazy(() =>
  import('scenes/management/subscenes/managecollections/lists/AwardsCollection').then((module) => ({
    default: module.AwardsCollection,
  }))
);
const MedalsCollectionDisplay = React.lazy(() =>
  import('scenes/management/subscenes/managecollections/lists/MedalCollection').then((module) => ({
    default: module.MedalCollection,
  }))
);
const GamesCollectionDisplay = React.lazy(() =>
  import('scenes/management/subscenes/managecollections/lists/GamesCollection').then((module) => ({
    default: module.GamesCollection,
  }))
);
const UnlocksCollectionDisplay = React.lazy(() =>
  import('scenes/management/subscenes/managecollections/lists/UnlocksCollection').then((module) => ({
    default: module.UnlocksCollection,
  }))
);
const NewsCollectionDisplay = React.lazy(() =>
  import('scenes/management/subscenes/managecollections/lists/NewsCollection').then((module) => ({
    default: module.NewsCollection,
  }))
);
const ManageCollectionDisplay = React.lazy(() =>
  import('scenes/management/subscenes/managecollections/ManageCollection').then((module) => ({
    default: module.ManageCollection,
  }))
);
const ManageQuestion = React.lazy(() =>
  import('scenes/management/subscenes/managequestion/ManageQuestion').then((module) => ({
    default: module.ManageQuestion,
  }))
);
const ManageQuiz = React.lazy(() =>
  import('scenes/management/subscenes/managequiz/ManageQuiz').then((module) => ({ default: module.ManageQuiz }))
);
const ManageLesson = React.lazy(() =>
  import('scenes/management/subscenes/managelesson/ManageLesson').then((module) => ({
    default: module.ManageLesson,
  }))
);
const ManageTutorial = React.lazy(() =>
  import('scenes/management/subscenes/managetutorial/ManageTutorial').then((module) => ({
    default: module.ManageTutorial,
  }))
);
const ManageCourse = React.lazy(() =>
  import('scenes/management/subscenes/managecourse/ManageCourse').then((module) => ({
    default: module.ManageCourse,
  }))
);
const ManageCareer = React.lazy(() =>
  import('scenes/management/subscenes/managecareer/ManageCareer').then((module) => ({
    default: module.ManageCareer,
  }))
);
const ManageUser = React.lazy(() =>
  import('scenes/management/subscenes/manageuser/ManageUser').then((module) => ({
    default: module.ManageUser,
  }))
);
const ManageAwards = React.lazy(() =>
  import('scenes/management/subscenes/manageawards/ManageAwards').then((module) => ({
    default: module.ManageAwards,
  }))
);
const ManageMedals = React.lazy(() =>
  import('scenes/management/subscenes/managemedal/ManageMedal').then((module) => ({
    default: module.ManageMedal,
  }))
);
const ManageGames = React.lazy(() =>
  import('scenes/management/subscenes/managegames/ManageGames').then((module) => ({
    default: module.ManageGames,
  }))
);
const ManageGameQuestion = React.lazy(() =>
  import('scenes/management/subscenes/managegamequestion/ManageGameQuestion').then((module) => ({
    default: module.ManageGameQuestion,
  }))
);
const ManageUnlocks = React.lazy(() =>
  import('scenes/management/subscenes/manageunlocks/ManageUnlocks').then((module) => ({
    default: module.ManageUnlocks,
  }))
);
const ManageNews = React.lazy(() =>
  import('scenes/management/subscenes/managenews/ManageNews').then((module) => ({
    default: module.ManageNews,
  }))
);
// #endregion

// #region ACCOUNT + UI Library imports ***************************************/
const AccountDisplay = React.lazy(() =>
  import('scenes/account/Account').then((module) => ({ default: module.Account }))
);
const LoginDisplay = React.lazy(() =>
  import('modules/user/auth/login/Login').then((module) => ({ default: module.Login }))
);
const CreateDisplay = React.lazy(() =>
  import('modules/user/auth/create/Create').then((module) => ({ default: module.Create }))
);
const ResetPasswordDisplay = React.lazy(() =>
  import('modules/user/auth/resetpassword/ResetPassword').then((module) => ({ default: module.ResetPassword }))
);
const ReportsDisplay = React.lazy(() =>
  import('scenes/reports/Reports').then((module) => ({ default: module.Reports }))
);
const AdminManageStudentProfileDisplay = React.lazy(() =>
  import('modules/profiles/ManageStudentProfile').then((module) => ({ default: module.ManageStudentProfile }))
);
const StudentManageOwnProfile = React.lazy(() =>
  import('scenes/career/subscenes/studentprofile/StudentProfile').then((module) => ({ default: module.StudentProfile }))
);
const ProfileCreateDisplay = React.lazy(() =>
  import('modules/profiles/ManageCreateProfile').then((module) => ({ default: module.ManageCreateProfile }))
);
const ProfileEditDisplay = React.lazy(() =>
  import('scenes/management/subscenes/manageprofile/ManageProfile').then((module) => ({
    default: module.ManageProfile,
  }))
);
const CreateOwnAccountDisplay = React.lazy(() =>
  import('scenes/account/CreateAccount').then((module) => ({ default: module.CreateAccount }))
);

/** *************************** UI LIBRARY imports ****************************/
const MontessoriComponentLibrary = React.lazy(() =>
  import('scenes/montessori/subscenes/ui/MontessoriUI').then((module) => ({ default: module.MontessoriUI }))
);
// #endregion

// #region CAREER imports *****************************************************/
const CareerLayout = React.lazy(() =>
  import('scenes/career/CareerLayout').then((module) => ({ default: module.CareerLayout }))
);
const CareerSimpleDisplay = React.lazy(() =>
  import('modules/career/simpleview/SimpleView').then((module) => ({ default: module.SimpleView }))
);
const CareerDisplay = React.lazy(() => import('modules/career/Career').then((module) => ({ default: module.Career })));
const CourseDisplay = React.lazy(() => import('modules/course/Course').then((module) => ({ default: module.Course })));
const LessonDisplay = React.lazy(() => import('modules/lesson/Lesson').then((module) => ({ default: module.Lesson })));
const CareerBadgesDisplay = React.lazy(() =>
  import('scenes/career/subscenes/badges/CareerBadges').then((module) => ({ default: module.CareerBadges }))
);

/** *************************** CAREER TUTORIALS ****************************/
const TutorialLayout = React.lazy(() =>
  import('modules/tutorial/TutorialLayout').then((module) => ({ default: module.TutorialLayout }))
);
const CareerTutorial = React.lazy(() =>
  import('scenes/career/subscenes/tutorials/CareerTutorial').then((module) => ({
    default: module.CareerTutorial,
  }))
);

/** *************************** CAREER QUIZZES ****************************/
const QuizBaseDisplay = React.lazy(() =>
  import('scenes/career/subscenes/quizzes/QuizBase').then((module) => ({ default: module.QuizBase }))
);

//* **************************** CAREER QUESTIONS ************************/
const CareerQuestion = React.lazy(() =>
  import('scenes/career/subscenes/question/CareerQuestion').then((module) => ({ default: module.CareerQuestion }))
);
// #endregion

// #endregion

const PRIMARY_COLOR = 'darkkhaki';
const CLS_NAME = 'ROUTER';

const printMsg = useStyledLogger(CLS_NAME, PRIMARY_COLOR);

const appLoader = async () => {
  if (!getIsAppInit(store.getState())) store.dispatch(doInitApp());

  const appStatus = getAppStatus(store.getState());
  if (appStatus === AppStatus.Offline) {
    console.log(...printMsg('AppLoader', 'appStatus', appStatus.toUpperCase()));
    return null;
  }

  const appConfigStatus = getAppConfigStatus(store.getState());
  if (appConfigStatus === Status.Idle) {
    await appConfigLoader();
  }

  const appNewsStatus = getAppNewsStatus(store.getState());
  if (appNewsStatus === Status.Idle) {
    await newsSceneLoader();
  }

  if (!isAuthStateObserverMounted(store.getState())) {
    store.dispatch(subscribeUserStatusChange());
    const userStatus: UserStatusT = await userStatusUpdated();
    console.log(...printMsg('User Initialized', 'UserStatus', userStatus));
    if (userStatus === 'signedIn') {
      const profileId = isActiveProfileIdSelected(store.getState());
      if (profileId) {
        await appAwardsSceneLoader();
        await appMedalSceneLoader();
        await appGamesCollectionLoader();
        await appUnlocksLoader();
        await appCareerSceneLoader();
      }
    }
  }

  if (store.getState().games.status === Status.Idle) {
    await appGamesCollectionLoader();
  }

  if (store.getState().awards.status === Status.Idle) {
    await appAwardsSceneLoader();
  }

  if (store.getState().awards.appMedalsStatus === Status.Idle) {
    await appMedalSceneLoader();
  }

  if (store.getState().unlocks.appUnlocksStatus === Status.Idle) {
    await appUnlocksLoader();
  }

  if (store.getState().ui.badgesUi.badgesStatus === Status.Idle) {
    const badges = await appBadgesSceneLoader();
    const initTime = getAppInitTime(store.getState());
    const timeStr = new Date(initTime);
    console.log(...printMsg('App Initialized', 'time', timeStr.toLocaleString()));
    return badges;
  }

  return null;
};

const router = createBrowserRouter(
  [
    {
      id: 'route_app',
      element: <App />,
      path: '/',
      loader: appLoader,
      errorElement: <ErrorBoundary debug={'From route_app'} />,
      children: [
        // #region HOME *****************************************************************
        {
          id: 'route_home',
          index: true,
          element: <MontessoriHome />,
          errorElement: <ErrorBoundary debug={'From route_home'} />,
          handle: {
            crumb: () => getCrumbText('My HOME'),
          },
        },
        // #endregion
        // #region CAREER HUB ***********************************************************
        {
          id: 'route_home_hub',
          path: 'hub',
          element: (
            <RequireAuth>
              <CareerHubDisplay />
            </RequireAuth>
          ),
          errorElement: <ErrorBoundary debug={'From route_home_hub'} />,
          loader: montessoriHubSceneLoader,
          action: montessoriHubSceneAction,
        },
        // #endregion
        // #region MONTESSORI BOARD TUTORIAL ********************************************
        {
          id: 'route_board_tutorial',
          path: 'boardtutorial',
          element: <BoardTutorialLayout />,
          errorElement: <ErrorBoundary debug={'From route_board_tutorial'} />,
          loader: boardTutorialSceneLoader,
          action: boardTutorialSceneAction,
          handle: {
            crumb: (_data: string, isLast: boolean) => {
              if (isLast) return getCrumbText('Board Tutorial');
              return getBreadcrumbLinkCmp(AppRoutes.BoardTutorial, 'Board Tutorial');
            },
          },
          children: [
            {
              id: 'route_board_tutorial_type',
              element: <MontessoriBoardTutorialDisplay />,
              errorElement: <ErrorBoundary debug={'From route_board_tutorial_type'} />,
              index: true,
              handle: {
                crumb: () => {
                  const path = window.location.search.split('=')[1] ?? 'Intro';
                  return getCrumbText(path);
                },
              },
            },
          ],
        },
        // #endregion
        // #region CAREER LAYOUT ********************************************************
        {
          id: 'route_career',
          path: 'career',
          element: (
            <RequireAuth>
              <CareerLayout />
            </RequireAuth>
          ),
          errorElement: <ErrorBoundary debug={'From route_career'} />,
          children: [
            // #region CAREER Badges ****************************************************
            {
              id: 'route_career_badges_view',
              path: 'badges/:careerId',
              element: (
                <RequireAuth>
                  <CareerBadgesDisplay />
                </RequireAuth>
              ),
              errorElement: <ErrorBoundary debug={'From route_career_badges_view'} />,
              loader: careerBadgesLoader,
              handle: getCrumb('Awards'),
            },
            // #endregion
            // #region CAREER ID SIMPLE VIEW ********************************************
            {
              id: 'route_career_simple_view',
              path: 'simple/:careerId',
              element: (
                <RequireAuth>
                  <CareerSimpleDisplay />
                </RequireAuth>
              ),
              errorElement: <ErrorBoundary debug={'From route_career_simple_view'} />,
              loader: careerSceneLoader,
              action: careerSceneActionSimple,
            },
            // #endregion
            // #region CAREER ID ********************************************************
            {
              id: 'route_career_id',
              path: ':careerId',
              element: (
                <RequireAuth>
                  <CareerDisplay />
                </RequireAuth>
              ),
              errorElement: <ErrorBoundary debug={'From route_career_id'} />,
              loader: careerSceneLoader,
              action: careerSceneAction,
              handle: {
                crumb: (data: CareerLoaderResponseT, isLast: boolean) => {
                  if (data) {
                    if (isLast) {
                      return getBreadcrumbLinkCmp(`/career/${data.career?.careerId}`, data.profile?.title);
                    }
                    return getBreadcrumbLinkIconCmp(`/career/${data.career.careerId}`, '', 'school', 'Career');
                  }
                },
              },
              children: [
                // COURSE ID ************************************************************
                {
                  id: 'route_career_course',
                  path: 'course/:courseId',
                  element: (
                    <RequireAuth>
                      <CourseDisplay />
                    </RequireAuth>
                  ),
                  errorElement: <ErrorBoundary debug={'From route_career_course'} />,
                  children: [
                    // LESSON ID ********************************************************
                    {
                      id: 'route_career_course_lesson',
                      path: 'lesson/:lessonId',
                      element: (
                        <RequireAuth>
                          <LessonDisplay />
                        </RequireAuth>
                      ),
                      errorElement: <ErrorBoundary debug={'From route_career_course_lesson'} />,
                      handle: {
                        crumb: (lessonUI: LessonUI, isLast: boolean) =>
                          getBreadcrumbLinkIconCmp(
                            lessonUI ? (lessonUI.toUrl as string) : '..',
                            lessonUI && isLast ? lessonUI.title : '',
                            'collections_bookmark',
                            'Lesson'
                          ),
                      },
                      children: [
                        // QUIZ ID ******************************************************
                        {
                          id: 'route_career_quiz',
                          path: 'quiz/:quizId',
                          element: (
                            <RequireAuth>
                              <QuizBaseDisplay />
                            </RequireAuth>
                          ),
                          errorElement: <ErrorBoundary debug="From route_career_quiz" />,
                          loader: careerQuizSceneLoader,
                          action: careerQuizSceneAction,
                          handle: {
                            crumb: (quizUI: QuizUI, isLast: boolean) =>
                              getBreadcrumbLinkIconCmp(
                                quizUI ? String(quizUI.toUrl) : '..',
                                isLast && quizUI ? quizUI.quizTitle : '',
                                'quiz',
                                'Quiz'
                              ),
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            // #endregion
            // #region CAREER TUTORIALS *************************************************
            {
              id: 'route_career_tutorial',
              path: 'tutorial',
              element: (
                <RequireAuth>
                  <TutorialLayout />
                </RequireAuth>
              ),
              errorElement: <ErrorBoundary debug="From route_career_tutorial" />,
              handle: getCrumb('Tutorial'),
              children: [
                // #region VERSIONED TUTORIAL *******************************************
                {
                  id: 'route_career_versioned_tutorial',
                  path: ':tutorialId/:versionId',
                  element: <></>,
                  errorElement: <ErrorBoundary debug="route_career_versioned_tutorial" />,
                  loader: careerVersionedTutorialSceneLoader,
                },
                {
                  id: 'route_career_tutorial_count',
                  path: 'count/:tutorialId',
                  element: <CareerTutorial careerMode="DEFAULT" category="count" />,
                  errorElement: <ErrorBoundary debug="route_career_tutorial_count" />,
                  loader: careerTutorialSceneLoader,
                  handle: getCrumb('Count'),
                },
                {
                  id: 'route_career_tutorial_compare',
                  path: 'compare/:tutorialId',
                  element: <CareerTutorial careerMode="DEFAULT" category="compare" />,
                  errorElement: <ErrorBoundary debug="route_career_tutorial_compare" />,
                  loader: careerTutorialSceneLoader,
                  handle: getCrumb('Compare'),
                },
                {
                  id: 'route_career_tutorial_add',
                  path: 'add/:tutorialId',
                  element: <CareerTutorial careerMode="DEFAULT" category="add" />,
                  errorElement: <ErrorBoundary debug="route_career_tutorial_add" />,
                  loader: careerTutorialSceneLoader,
                  handle: getCrumb('Add'),
                },
                {
                  id: 'route_career_tutorial_subtract',
                  path: 'subtract/:tutorialId',
                  element: <CareerTutorial careerMode="DEFAULT" category="subtract" />,
                  errorElement: <ErrorBoundary debug="route_career_tutorial_subtract" />,
                  loader: careerTutorialSceneLoader,
                  handle: getCrumb('Subtract'),
                },
                {
                  id: 'route_career_tutorial_multiply',
                  path: 'multiply/:tutorialId',
                  element: <CareerTutorial careerMode="DEFAULT" category="multiply" />,
                  errorElement: <ErrorBoundary debug="route_career_tutorial_multiply" />,
                  loader: careerTutorialSceneLoader,
                  handle: getCrumb('Multiply'),
                },
                {
                  id: 'route_career_tutorial_divide',
                  path: 'divide/:tutorialId',
                  element: <CareerTutorial careerMode="DEFAULT" category="divide" />,
                  errorElement: <ErrorBoundary debug="route_career_tutorial_divide" />,
                  loader: careerTutorialSceneLoader,
                  handle: getCrumb('Divide'),
                },
                // #endregion
              ],
            },
            // #endregion
          ],
        },
        // #endregion
        // #region QUESTION *************************************************************
        {
          id: 'route_question',
          path: 'question',
          element: <SceneLayout />,
          children: [
            // DEMO QUESTION ************************************************************
            {
              id: 'route_career_demo_question',
              path: ':questionId',
              element: <DemoQuestion />,
              errorElement: <ErrorBoundary debug="route_career_versioned_question" />,
              loader: demoQuestionSceneLoader,
              handle: getCrumb('Demo Question'),
            },
            // VERSIONED QUESTION *******************************************************
            {
              id: 'route_career_versioned_question',
              path: ':questionId/:versionId',
              element: <></>,
              errorElement: <ErrorBoundary debug="route_career_versioned_question" />,
              loader: careerQuestionSceneLoader,
            },
            // COUNT QUESTION ***********************************************************
            {
              id: 'route_career_count_question',
              path: 'count/:questionId/',
              element: <CareerQuestion category="count" />,
              errorElement: <ErrorBoundary debug="route_career_count_question" />,
              handle: {
                crumb: (qUI: QuestionUI) =>
                  getBreadcrumbLinkIconCmp(String(qUI.toUrl), qUI.title, MeIcons.Questions, 'Question'),
              },
            },
            // COMPARE QUESTION *********************************************************
            {
              id: 'route_career_quiz_question_compare',
              path: 'compare/:questionId',
              element: <CareerQuestion category="compare" />,
              errorElement: <ErrorBoundary debug="route_career_quiz_question_compare" />,
              handle: {
                crumb: (qUI: QuestionUI) =>
                  getBreadcrumbLinkIconCmp(String(qUI.toUrl), qUI.title, MeIcons.Questions, 'Question'),
              },
            },
            // ADD QUESTION *************************************************************
            {
              id: 'route_career_quiz_question_add',
              path: 'add/:questionId',
              element: <CareerQuestion category="add" />,
              errorElement: <ErrorBoundary debug="route_career_quiz_question_add" />,
              handle: {
                crumb: (qUI: QuestionUI) =>
                  getBreadcrumbLinkIconCmp(String(qUI.toUrl), qUI.title, MeIcons.Questions, 'Question'),
              },
            },
            // SUBTRACT QUESTION ********************************************************
            {
              id: 'route_career_quiz_question_subtract',
              path: 'subtract/:questionId',
              element: <CareerQuestion category="subtract" />,
              errorElement: <ErrorBoundary debug="route_career_quiz_question_subtract" />,
              handle: {
                crumb: (qUI: QuestionUI) =>
                  getBreadcrumbLinkIconCmp(String(qUI.toUrl), qUI.title, MeIcons.Questions, 'Question'),
              },
            },
            // MULTIPLY QUESTION ********************************************************
            {
              id: 'route_career_quiz_question_multiply',
              path: 'multiply/:questionId',
              element: <CareerQuestion category="multiply" />,
              errorElement: <ErrorBoundary debug="route_career_quiz_question_multiply" />,
              handle: {
                crumb: (qUI: QuestionUI) =>
                  getBreadcrumbLinkIconCmp(String(qUI.toUrl), qUI.title, MeIcons.Questions, 'Question'),
              },
            },
            // DIVIDE QUESTION **********************************************************
            {
              id: 'route_career_divide_question',
              path: 'divide/:questionId',
              element: <CareerQuestion category="divide" />,
              errorElement: <ErrorBoundary debug="route_career_divide_question" />,
              handle: {
                crumb: (qUI: QuestionUI) =>
                  getBreadcrumbLinkIconCmp(String(qUI.toUrl), qUI.title, MeIcons.Questions, 'Question'),
              },
            },
          ],
        },
        // #endregion
        // #region GAMES ****************************************************************
        {
          id: 'route_games',
          element: (
            <RequireAuth>
              <GamesDisplay />
            </RequireAuth>
          ),
          errorElement: <ErrorBoundary debug={'From route_games'} />,
          path: 'games',
          handle: {
            crumb: (_data: never, isLast: boolean) => {
              if (isLast) return getCrumbText('Games');
              return getBreadcrumbLinkCmp('/games', 'Games');
            },
          },
          children: [
            {
              id: 'route_game_selection',
              element: (
                <RequireAuth>
                  <GameSelectionDisplay />
                </RequireAuth>
              ),
              errorElement: <ErrorBoundary debug={'From route_game_selection'} />,
              index: true,
              loader: gamesCollectionLoader,
            },
            {
              id: 'route_wackamole',
              element: <GameQuestionSelectionDisplay />,
              errorElement: <ErrorBoundary debug={'From route_wackamole'} />,
              loader: gameByIdLoader,
              path: ':gameId',
              handle: getCrumb('Select Level'),
            },
            {
              id: 'route_game_layout',
              element: (
                <RequireAuth>
                  <GamesLayout />
                </RequireAuth>
              ),
              errorElement: <ErrorBoundary debug={'From route_game_layout'} />,
              handle: {
                crumb: () => {
                  const path = location.pathname.split('/'); // pathname: /games/:gameId/:questionId/gameName
                  return getBreadcrumbLinkCmp(`/${path[1]}/${path[2]}`, 'Select Level');
                },
              },
              children: [
                {
                  id: 'route_wackamole_game',
                  element: <WackAMoleDisplay />,
                  path: ':gameId/:gameQuestionId/wackamole',
                  loader: gameQuestionByIdLoader,
                  errorElement: <ErrorBoundary debug={'From route_wackamole_game'} />,
                  handle: {
                    crumb: (data: GameWithQuestionResponse) =>
                      getCrumbText(data && data.gameQuestion ? data.gameQuestion.title : 'WackAMole'),
                  },
                },
                {
                  id: 'route_wakabake_game',
                  element: <WakaBakeGameDisplay />,
                  errorElement: <ErrorBoundary debug={'From route_wakabake_game'} />,
                  path: ':gameId/:gameQuestionId/wakabake',
                  loader: gameQuestionByIdLoader,
                  handle: {
                    crumb: (data: GameWithQuestionResponse) =>
                      getCrumbText(data && data.gameQuestion ? data.gameQuestion.title : 'WakaBake'),
                  },
                },
                {
                  id: 'route_route_wakaflip_game',
                  element: <WakaFlipGameDisplay />,
                  path: ':gameId/:gameQuestionId/wakaflip',
                  loader: gameQuestionByIdLoader,
                  errorElement: <ErrorBoundary debug={'From route_route_wakaflip_game'} />,
                  handle: {
                    crumb: (data: GameWithQuestionResponse) =>
                      getCrumbText(data && data.gameQuestion ? data.gameQuestion.title : 'WakaFlip'),
                  },
                },
                {
                  id: 'route_wakacubes_game',
                  element: <WakaCubesDisplay />,
                  path: ':gameId/:gameQuestionId/wakacubes',
                  loader: gameQuestionByIdLoader,
                  errorElement: <ErrorBoundary debug={'From route_wakacubes_game'} />,
                  handle: {
                    crumb: (data: GameWithQuestionResponse) =>
                      getCrumbText(data && data.gameQuestion ? data.gameQuestion.title : 'WakaCubes'),
                  },
                },
                {
                  id: 'route_wakaminusbalance_game',
                  element: <WakaMinusBalanceDisplay />,
                  path: ':gameId/:gameQuestionId/wakaminusbalance',
                  loader: gameQuestionByIdLoader,
                  errorElement: <ErrorBoundary debug={'From route_wakaminusbalance_game'} />,
                  handle: {
                    crumb: (data: GameWithQuestionResponse) =>
                      getCrumbText(data && data.gameQuestion ? data.gameQuestion.title : 'Minus Balance'),
                  },
                },
                {
                  id: 'route_wakabridgebuilder_game',
                  element: <WakaColumnBuilderDisplay />,
                  path: ':gameId/:gameQuestionId/wakabridgebuilder',
                  loader: gameQuestionByIdLoader,
                  errorElement: <ErrorBoundary debug={'From route_wakabridgebuilder_game'} />,
                  handle: {
                    crumb: (data: GameWithQuestionResponse) =>
                      getCrumbText(data && data.gameQuestion ? data.gameQuestion.title : 'Column Builder'),
                  },
                },
                {
                  id: 'route_wakawalkabout_game',
                  element: <WakaWalkAboutDisplay />,
                  path: ':gameId/:gameQuestionId/wakawalkabout',
                  loader: gameQuestionByIdLoader,
                  errorElement: <ErrorBoundary debug={'From route_wakawalkabout_game'} />,
                  handle: {
                    crumb: (data: GameWithQuestionResponse) =>
                      getCrumbText(data && data.gameQuestion ? data.gameQuestion.title : 'Walk About'),
                  },
                },
              ],
            },
          ],
        },
        // #endregion
        // #region COMPONENT UI LIBRARY *************************************************
        {
          id: 'route_ui_components',
          element: <MontessoriComponentLibrary />,
          errorElement: <ErrorBoundary debug={'From route_ui_components'} />,
          path: 'montessori/components',
          handle: {
            crumb: () => getCrumbText('Montessori UI Library'),
          },
        },
        // #endregion
        // #region CAREER EDITOR ********************************************************
        {
          id: 'route_manage_layout',
          element: (
            <RequireAuth>
              <ManagementLayout />
            </RequireAuth>
          ),
          path: 'manage',
          handle: {
            crumb: () => getBreadcrumbLinkCmp('/manage/career', 'Manage'),
          },
          children: [
            // #region MANAGE NEWS
            {
              id: 'route_manage_news_collection',
              path: 'news',
              element: <ManageCollectionDisplay />,
              errorElement: <ErrorBoundary debug="route_manage_news_collection" />,
              handle: {
                crumb: () => getBreadcrumbLinkCmp('/manage/news', 'News'),
              },
              children: [
                {
                  id: 'route_manage_news',
                  element: <NewsCollectionDisplay />,
                  loader: manageNewsCollectionSceneLoader,
                  errorElement: <ErrorBoundary debug="route_manage_news" />,
                  index: true,
                },
                {
                  id: 'route_manage_news_create',
                  path: 'create',
                  element: <ManageNews mode="create" />,
                  loader: manageCreateNewsSceneLoader,
                  action: manageCreateNewsSceneAction,
                  handle: getCrumb('Create'),
                },
                {
                  id: 'route_manage_news_edit',
                  path: ':newsId',
                  element: <ManageNews mode="edit" />,
                  loader: manageEditNewsSceneLoader,
                  action: manageEditNewsSceneAction,
                  handle: getCrumb('Edit'),
                },
              ],
            },
            // #endregion
            // #region MANAGE UNLOCKS
            {
              id: 'route_manage_unlocks_collection',
              path: 'unlocks',
              element: <ManageCollectionDisplay />,
              errorElement: <ErrorBoundary debug="route_manage_unlocks_collection" />,
              handle: {
                crumb: () => getBreadcrumbLinkCmp('/manage/unlocks', 'Unlocks'),
              },
              children: [
                {
                  id: 'route_manage_unlocks',
                  element: <UnlocksCollectionDisplay />,
                  loader: manageUnlocksCollectionSceneLoader,
                  errorElement: <ErrorBoundary debug="route_manage_unlocks" />,
                  index: true,
                },
                {
                  id: 'route_manage_unlocks_create',
                  path: 'create',
                  element: <ManageUnlocks mode="create" />,
                  loader: manageCreateUnlockSceneLoader,
                  action: manageCreateUnlockSceneAction,
                  handle: getCrumb('Create'),
                },
                {
                  id: 'route_manage_unlocks_edit',
                  path: ':unlockId',
                  element: <ManageUnlocks mode="edit" />,
                  loader: manageEditUnlockSceneLoader,
                  action: manageEditUnlocksSceneAction,
                  handle: getCrumb('Edit'),
                },
              ],
            },
            // #endregion
            // #region MANAGE GAMES
            {
              id: 'route_manage_games_collection',
              path: 'games',
              element: <ManageCollectionDisplay />,
              errorElement: <ErrorBoundary debug="route_manage_games_collection" />,
              handle: {
                crumb: () => getBreadcrumbLinkCmp('/manage/games', 'Games'),
              },
              children: [
                {
                  id: 'route_manage_games',
                  element: <GamesCollectionDisplay />,
                  loader: manageGameCollectionSceneLoader,
                  errorElement: <ErrorBoundary debug="route_manage_games" />,
                  index: true,
                },
                {
                  id: 'route_manage_game_create',
                  path: 'create',
                  element: <ManageGames mode="create" />,
                  loader: manageCreateGameSceneLoader,
                  action: manageCreateGameSceneAction,
                  handle: getCrumb('Create'),
                },
                {
                  id: 'route_manage_game_edit',
                  path: ':gameId',
                  element: <ManageGames mode="edit" />,
                  loader: manageEditGameSceneLoader,
                  action: manageEditGameSceneAction,
                  handle: getCrumb('Edit'),
                  children: [
                    {
                      id: 'route_manage_game_edit_question_create',
                      path: 'createquestion',
                      element: <ManageGameQuestion mode="create" />,
                      loader: manageCreateGameQuestionSceneLoader,
                      action: manageCreateGameQuestionSceneAction,
                      handle: getCrumb('Create'),
                    },
                    {
                      id: 'route_manage_game_edit_question_edit',
                      path: ':gameQuestionId',
                      element: <ManageGameQuestion mode="edit" />,
                      loader: manageEditGameQuestionSceneLoader,
                      action: manageEditGameQuestionSceneAction,
                      handle: getCrumb('Edit'),
                    },
                  ],
                },
              ],
            },
            // #endregion
            // #region MANAGE AWARDS
            {
              id: 'route_manage_awards_collection',
              path: 'awards',
              element: <ManageCollectionDisplay />,
              errorElement: <ErrorBoundary debug="route_manage_awards_collection" />,
              handle: {
                crumb: () => getBreadcrumbLinkCmp('/manage/awards', 'Awards'),
              },
              children: [
                {
                  id: 'route_manage_awards',
                  element: <AwardsCollectionDisplay />,
                  loader: manageAwardCollectionSceneLoader,
                  errorElement: <ErrorBoundary debug="route_manage_awards" />,
                  index: true,
                },
                {
                  id: 'route_manage_award_create',
                  path: 'create',
                  element: <ManageAwards mode="create" />,
                  loader: manageCreateAwardSceneLoader,
                  action: manageCreateAwardSceneAction,
                  handle: getCrumb('Create'),
                },
                {
                  id: 'route_manage_award_edit',
                  path: ':awardId',
                  element: <ManageAwards mode="edit" />,
                  loader: manageEditAwardSceneLoader,
                  action: manageEditAwardSceneAction,
                  handle: getCrumb('Edit'),
                },
              ],
            },
            // #endregion
            // #region MANAGE AWARDS
            {
              id: 'route_manage_medal_collection',
              path: 'medals',
              element: <ManageCollectionDisplay />,
              errorElement: <ErrorBoundary debug="route_manage_medal_collection" />,
              handle: {
                crumb: () => getBreadcrumbLinkCmp('/manage/medals', 'Medals'),
              },
              children: [
                {
                  id: 'route_manage_medals',
                  element: <MedalsCollectionDisplay />,
                  loader: manageMedalsCollectionSceneLoader,
                  errorElement: <ErrorBoundary debug="route_manage_medals" />,
                  index: true,
                },
                {
                  id: 'route_manage_medal_create',
                  path: 'create',
                  element: <ManageMedals mode="create" />,
                  loader: manageCreateMedalSceneLoader,
                  action: manageCreateMedalSceneAction,
                  handle: getCrumb('Create'),
                },
                {
                  id: 'route_manage_medal_edit',
                  path: ':medalId',
                  element: <ManageMedals mode="edit" />,
                  loader: manageEditMedalsSceneLoader,
                  action: manageEditMedalSceneAction,
                  handle: getCrumb('Edit'),
                },
              ],
            },
            // #endregion
            // #region MANAGE QUESTIONS
            {
              id: 'route_manage_question_collection',
              path: 'question',
              element: <ManageCollectionDisplay />,
              errorElement: <ErrorBoundary debug="route_manage_question_collection" />,
              handle: {
                crumb: () => getBreadcrumbLinkCmp('/manage/question', 'Questions'),
              },
              children: [
                {
                  id: 'route_manage_questions',
                  element: <QuestionCollectionDisplay />,
                  loader: manageQuestionCollectionSceneLoader,
                  errorElement: <ErrorBoundary debug="route_manage_questions" />,
                  index: true,
                },
                {
                  id: 'route_manage_question_create',
                  path: 'create',
                  element: <ManageQuestion mode="create" />,
                  loader: manageCreateQuestionSceneLoader,
                  action: manageCreateQuestionSceneAction,
                  handle: getCrumb('Create'),
                },
                {
                  id: 'route_manage_question_edit',
                  path: ':questionId',
                  element: <ManageQuestion mode="edit" />,
                  loader: manageEditQuestionSceneLoader,
                  action: manageEditQuestionSceneAction,
                  handle: getCrumb('Edit'),
                },
              ],
            },
            // #endregion
            // #region MANAGE QUIZZES
            {
              id: 'route_manage_quiz_collection',
              path: 'quiz',
              element: <ManageCollectionDisplay />,
              errorElement: <ErrorBoundary debug="route_manage_quiz_collection" />,
              handle: {
                crumb: () => getBreadcrumbLinkCmp('/manage/quiz', 'Quizzes'),
              },
              children: [
                {
                  id: 'route_manage_quizzes',
                  element: <QuizCollectionDisplay />,
                  loader: manageQuizCollectionSceneLoader,
                  errorElement: <ErrorBoundary debug="route_manage_quiz" />,
                  index: true,
                },
                {
                  id: 'route_manage_quiz_create',
                  path: 'create',
                  element: <ManageQuiz mode="create" />,
                  loader: manageCreateQuizSceneLoader,
                  action: manageCreateQuizSceneAction,
                  handle: getCrumb('Create'),
                },
                {
                  id: 'route_manage_quiz_edit',
                  path: ':quizId',
                  element: <ManageQuiz mode="edit" />,
                  errorElement: <ErrorBoundary debug="From route_manage_quiz_edit" />,
                  loader: manageEditQuizSceneLoader,
                  action: manageEditQuizSceneAction,
                  handle: getCrumb('Edit'),
                },
                {
                  id: 'route_manage_versioned_quiz_edit',
                  path: ':quizId/:versionId',
                  element: <ManageQuiz mode="edit" />,
                  errorElement: <ErrorBoundary debug="From route_manage_versioned_quiz_edit" />,
                  loader: manageEditVersionedQuizSceneLoader,
                  action: manageEditQuizSceneAction,
                  handle: getCrumb('Edit'),
                },
              ],
            },
            // #endregion
            // #region MANAGE LESSONS
            {
              id: 'route_manage_lesson_collection',
              path: 'lesson',
              element: <ManageCollectionDisplay />,
              errorElement: <ErrorBoundary debug="route_manage_lesson_collection" />,
              handle: {
                crumb: () => getBreadcrumbLinkCmp('/manage/lesson', 'Lessons'),
              },
              children: [
                {
                  id: 'route_manage_lessons',
                  element: <LessonsCollectionDisplay />,
                  errorElement: <ErrorBoundary debug="route_manage_lessons" />,
                  loader: manageLessonCollectionSceneLoader,
                  index: true,
                },
                {
                  id: 'route_manage_lesson_create',
                  path: 'create',
                  element: <ManageLesson mode="create" />,
                  loader: manageCreateLessonSceneLoader,
                  action: manageCreateLessonSceneAction,
                  handle: getCrumb('Create'),
                },
                {
                  id: 'route_manage_lesson_edit',
                  path: ':lessonId',
                  element: <ManageLesson mode="edit" />,
                  errorElement: <ErrorBoundary debug="From route_manage_lesson_edit" />,
                  loader: manageEditLessonSceneLoader,
                  action: manageEditLessonSceneAction,
                  handle: getCrumb('Edit'),
                },
              ],
            },
            // #endregion
            // #region MANAGE TUTORIALS
            {
              id: 'route_manage_tutorial_collection',
              path: 'tutorial',
              element: <ManageCollectionDisplay />,
              errorElement: <ErrorBoundary debug="route_manage_tutorial_collection" />,
              handle: {
                crumb: () => getBreadcrumbLinkCmp('/manage/tutorial', 'Tutorial'),
              },
              children: [
                {
                  id: 'route_manage_tutorials',
                  element: <TutorialCollectionDisplay />,
                  loader: manageTutorialCollectionSceneLoader,
                  index: true,
                },
                {
                  id: 'route_manage_tutorial_create',
                  path: 'create',
                  element: <ManageTutorial mode="create" />,
                  loader: manageCreateTutorialSceneLoader,
                  action: manageCreateTutorialSceneAction,
                  handle: getCrumb('Create'),
                },
                {
                  id: 'route_manage_tutorial_edit',
                  path: ':tutorialId',
                  element: <ManageTutorial mode="edit" />,
                  loader: manageEditTutorialSceneLoader,
                  action: manageEditTutorialSceneAction,
                  handle: getCrumb('Edit'),
                },
              ],
            },
            // #endregion
            // #region MANAGE COURSES
            {
              id: 'route_manage_course_collection',
              path: 'course',
              element: <ManageCollectionDisplay />,
              errorElement: <ErrorBoundary debug="route_manage_course_collection" />,
              handle: {
                crumb: () => getBreadcrumbLinkCmp('/manage/course', 'Course'),
              },
              children: [
                {
                  id: 'route_manage_course',
                  element: <CourseCollectionDisplay mode="edit" />,
                  loader: manageCoursesSceneLoader,
                  index: true,
                },
                {
                  id: 'route_manage_course_create',
                  path: 'create',
                  element: <ManageCourse mode="create" />,
                  loader: manageCreateCourseSceneLoader,
                  action: manageCreateCourseSceneAction,
                  handle: getCrumb('Create'),
                },
                {
                  id: 'route_manage_course_edit',
                  path: ':courseId',
                  element: <ManageCourse mode="edit" />,
                  loader: manageEditCourseSceneLoader,
                  action: manageEditCourseSceneAction,
                  handle: getCrumb('Edit'),
                },
              ],
            },
            // #endregion
            // #region MANAGE CAREER
            {
              id: 'route_manage_career_collection',
              path: 'career',
              element: <ManageCollectionDisplay />,
              errorElement: <ErrorBoundary debug="route_manage_career_collection" />,
              handle: {
                crumb: () => getBreadcrumbLinkCmp('/manage/career', 'Career'),
              },
              children: [
                {
                  id: 'route_manage_career',
                  element: <CareerCollectionDisplay mode="edit" showHeaderActionIcon={false} />,
                  loader: manageCareersSceneLoader,
                  index: true,
                },
                {
                  id: 'route_manage_career_edit',
                  path: ':careerId',
                  element: <ManageCareer mode="edit" />,
                  loader: manageEditCareerSceneLoader,
                  action: manageEditCareerSceneAction,
                  handle: getCrumb('Edit'),
                },
              ],
            },
            // #endregion
            // #region MANAGE PROFILE (NOT ACCOUNT ONE)
            {
              id: 'route_manage_profile_collection',
              path: 'profile',
              element: <ManageCollectionDisplay />,
              errorElement: <ErrorBoundary debug="route_manage_profile_collection" />,
              handle: {
                crumb: () => getBreadcrumbLinkCmp('/manage/profile', 'Profiles'),
              },
              children: [
                {
                  id: 'route_manage_profile',
                  element: <ProfileCollectionDisplay mode="edit" showHeaderActionIcon={false} />,
                  loader: manageProfileCollectionSceneLoader,
                  index: true,
                },
              ],
            },
            // #endregion
            // #region MANAGE USER
            {
              id: 'route_manage_users',
              path: 'user',
              element: <ManageCollectionDisplay />,
              errorElement: <ErrorBoundary debug="route_manage_users" />,
              handle: {
                crumb: () => getBreadcrumbLinkCmp('/manage/user', 'Users'),
              },
              children: [
                {
                  id: 'route_manage_users_collection',
                  element: <UserCollectionDisplay mode="edit" showHeaderActionIcon={false} />,
                  loader: manageUserCollectionSceneLoader,
                  index: true,
                },
                {
                  id: 'route_manage_user',
                  path: ':accountId',
                  element: <SceneLayout asSubscene />,
                  loader: manageEditUserBreadcrumbUrlLoader,
                  action: manageEditUserByUserIdAction,
                  handle: {
                    crumb: (data: string, isLast: boolean) => {
                      if (isLast) return getCrumbText('User');
                      return getBreadcrumbLinkCmp(data, 'User');
                    },
                  },
                  children: [
                    {
                      id: 'route_manage_user_edit',
                      index: true,
                      element: (
                        <RequireAuth>
                          <ManageUser mode="edit" />
                        </RequireAuth>
                      ),
                      loader: manageEditUserSceneLoader,
                      handle: getCrumb('Edit'),
                    },
                    {
                      id: 'route_manage_user_create_profile',
                      path: 'create',
                      element: (
                        <RequireAuth>
                          <ProfileCreateDisplay isManageMode />
                        </RequireAuth>
                      ),
                      loader: manageCreateProfileByUserIdLoader,
                      action: manageCreateProfileByUserIdAction,
                      handle: getCrumb('Create Student'),
                    },
                    {
                      id: 'route_manage_user_edit_profile',
                      path: ':profileId',
                      element: (
                        <RequireAuth>
                          <ProfileEditDisplay mode="edit" />
                        </RequireAuth>
                      ),
                      loader: manageEditProfileByUserIdLoader,
                      action: manageEditProfileByUserIdAction,
                      handle: getCrumb('Edit Student'),
                    },
                    {
                      id: 'route_manage_user_profile_report_card',
                      path: ':profileId/:careerId',
                      element: (
                        <RequireAuth>
                          <ReportsDisplay />
                        </RequireAuth>
                      ),
                      loader: manageProfileByUserIdReportCardLoader,
                      handle: getCrumb("Student's Report Card"),
                    },
                  ],
                },
              ],
            },
            // #endregion
          ],
        },
        // #endregion
        // #region ADMIN USER ACCOUNT ***************************************************
        {
          id: 'route_account',
          path: 'account/:accountId',
          element: <SceneLayout />,
          errorElement: <ErrorBoundary debug="From route_account" />,
          loader: ownUserAccountInfoURLLoader,
          action: ownUserAccountInfoSceneAction,
          // handle: {
          //   crumb: (data: string, isLast: boolean) => {
          //     if (isLast) return getCrumbText('Enrollment');
          //     return getBreadcrumbLinkCmp(data, 'Enrollment');
          //   },
          // },
          children: [
            {
              id: 'route_account_id',
              index: true,
              element: (
                <RequireAuth>
                  <AccountDisplay />
                </RequireAuth>
              ),
              errorElement: <ErrorBoundary debug={'From route_account_id'} />,
              loader: ownUserAccountInfoSceneLoader,
              handle: getCrumb('Edit Account'),
            },
            {
              id: 'route_account_id_create',
              path: 'create',
              element: (
                <RequireAuth>
                  <CreateOwnAccountDisplay />
                </RequireAuth>
              ),
              errorElement: <ErrorBoundary debug={'From route_account_id_create'} />,
              loader: completeCreateOwnAccountLoader,
              action: completeCreateOwnAccountWithProfileAction,
              handle: getCrumb('Enrollment'),
            },
            {
              id: 'route_account_create_profile',
              path: 'profile/create',
              element: (
                <RequireAuth>
                  <ProfileCreateDisplay />
                </RequireAuth>
              ),
              errorElement: <ErrorBoundary debug={'From route_account_create_profile'} />,
              loader: authUserCreateProfileSceneLoader,
              action: authUserCreateProfileSceneAction,
              handle: getCrumb('Enroll New Student'),
            },
            {
              id: 'route_account_edit_profile_layout',
              path: 'profile/:profileId',
              element: (
                <RequireAuth>
                  <SceneLayout asSubscene />
                </RequireAuth>
              ),
              errorElement: <ErrorBoundary debug={'From route_account_edit_profile_layout'} />,
              loader: ownUserAccountProfileInfoURLLoader,
              action: ownUserAccountSceneAction,
              handle: {
                crumb: (data: string, isLast: boolean) => {
                  if (isLast) return getCrumbText('Manage Student');
                  return getBreadcrumbLinkCmp(data, 'Student');
                },
              },
              children: [
                {
                  id: 'route_account_edit_profile',
                  index: true,
                  element: (
                    <RequireAuth>
                      <AdminManageStudentProfileDisplay mode="edit" />
                    </RequireAuth>
                  ),
                  errorElement: <ErrorBoundary debug={'From route_account_edit_profile'} />,
                  loader: ownUserAccountSceneLoader,
                },
                {
                  id: 'route_account_edit_profile_reportcard',
                  path: 'reportcard/:careerId',
                  element: (
                    <RequireAuth>
                      <ReportsDisplay />
                    </RequireAuth>
                  ),
                  errorElement: <ErrorBoundary debug={'From route_account_edit_profile_reportcard'} />,
                  loader: reportCardSceneLoader,
                  handle: getCrumb('Report Card'),
                },
                {
                  id: 'route_account_profile_courses',
                  path: 'courses',
                  element: (
                    <RequireAuth>
                      <SceneLayout />
                    </RequireAuth>
                  ),
                  errorElement: <ErrorBoundary debug={'From route_account_profile_courses'} />,
                  loader: activeProfileCoursesLoader,
                },
              ],
            },
          ],
        },
        // #endregion
        // #region STUDENT PROFILE ******************************************************
        {
          id: 'route_student',
          path: 'student/:profileId',
          element: <SceneLayout />,
          action: studentProfileAction,
          errorElement: <ErrorBoundary debug="From route_student" />,
          handle: {
            crumb: () => getCrumbText('My Student Profile'),
          },
          children: [
            {
              id: 'route_student_id',
              index: true,
              element: (
                <RequireAuth>
                  <StudentManageOwnProfile mode="edit" />
                </RequireAuth>
              ),
              errorElement: <ErrorBoundary debug={'From route_student_id'} />,
              loader: studentProfileLoader,
            },
          ],
        },
        // #endregion
        // #region USER AUTH ************************************************************
        {
          id: 'route_auth',
          path: 'auth',
          element: <SceneLayout />,
          errorElement: <ErrorBoundary debug="From route_auth" />,
          children: [
            {
              id: 'route_auth_login',
              element: <LoginDisplay />,
              path: 'login',
              errorElement: <ErrorBoundary debug="From route_auth_login" />,
              handle: getCrumb('Login'),
            },
            {
              id: 'route_auth_create',
              element: <CreateDisplay />,
              path: 'create',
              errorElement: <ErrorBoundary debug="From route_auth_create" />,
              handle: getCrumb('Sign Up'),
            },
            {
              id: 'route_auth_resetpassword',
              element: <ResetPasswordDisplay />,
              path: 'resetpassword',
              errorElement: <ErrorBoundary debug="From route_auth_resetpassword" />,
              handle: getCrumb('Reset Password'),
            },
          ],
        },
        // #endregion
        // #region LEGAL ****************************************************************
        {
          id: 'route_legal',
          path: 'legal',
          element: <LegalDisplay />,
          loader: montessoriLegalLoader,
          errorElement: <ErrorBoundary debug="From route_legal" />,
          handle: {
            crumb: () => getBreadcrumbLinkCmp('/legal', 'Legal'),
          },
          children: [
            {
              id: 'route_landing_legal_display',
              element: <LandingLegalSubscene />,
              index: true,
              errorElement: <ErrorBoundary debug="From route_landing_legal_display" />,
            },
            {
              id: 'route_legal_display',
              element: <LegalSubscene />,
              path: ':subpageId',
              loader: montessoriLegalSubpageLoader,
              errorElement: <ErrorBoundary debug="From route_legal_display" />,
              handle: {
                crumb: (data: LegalSubpageT) => getBreadcrumbLinkCmp(data.url, data.title),
              },
            },
          ],
        },
        // #endregion
      ],
    },
    {
      path: '*',
      element: <PageNotFound />,
    },
  ],
  {
    basename: '/',
  }
);

export default router;
