import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks/hooks';

import { Animations } from 'types/App.types';
import { ProfileId } from 'types/User.types';

import { createPassKeyUIState } from 'modules/user/secret/userHelper';

import Popup from 'components/popup/Popup';
import PassKeyPanel from 'components/passkey/PassKeyPanel';

import { getPassKeyPanelState, getPassKeyPanelVisible, setPassKeyUiState } from 'modules/user/userUiSlice';
import {
  PassKeyActionStateT,
  doDisableStudentPassKey,
  doEnableStudentPassKey,
  getUserPassKeyActionPrevState,
  getUserPassKeyActionState,
  getUserProfiles,
  setUserPassKeyActionState,
} from 'modules/user/userSlice';

export const usePassKey = (isStudentMode = false, studentProfileId: ProfileId = '') => {
  const studentActionStateRef = useRef<PassKeyActionStateT | null>(null);
  const passKeyRef = useRef<HTMLDivElement>(null);
  const [profileId, setProfileId] = useState<ProfileId>('');
  const [studentPinValidated, setStudentPinValidated] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [closedWithoutAction, setClosedWithoutAction] = useState<boolean>(false);
  const [showCloseButton, setShowCloseButton] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const profiles = useAppSelector(getUserProfiles);
  const isPassKeyPanelVisible = useAppSelector(getPassKeyPanelVisible);
  const passKeyActionState: PassKeyActionStateT = useAppSelector(getUserPassKeyActionState);
  const passKeyActionPrevState: PassKeyActionStateT | undefined = useAppSelector(getUserPassKeyActionPrevState);
  const passKeyInternalState = useAppSelector(getPassKeyPanelState);

  useEffect(() => {
    if (studentProfileId && studentProfileId.length > 0) setProfileId(studentProfileId);
  }, []);

  useEffect(() => {
    if (isStudentMode) return;

    if (passKeyActionState !== 'passkey_hidden' && !isPassKeyPanelVisible) {
      if (passKeyActionState === 'passkey_enable') {
        dispatch(setPassKeyUiState(createPassKeyUIState(Animations.None, true, 'set_passkey_pin')));
      } else if (passKeyActionState === 'passkey_disable' || passKeyActionState === 'passkey_validate_pin') {
        dispatch(setPassKeyUiState(createPassKeyUIState(Animations.None, true, 'verify_passkey_pin')));
      } else if (passKeyActionState === 'passkey_update_pin') {
        // console.log(`useEffect what is happening? passKeyInternalState=${passKeyInternalState}`);
        dispatch(setPassKeyUiState(createPassKeyUIState(Animations.None, true, passKeyInternalState)));
      } else if (passKeyActionState === 'passkey_reset_pin') {
        navigate(-1);
      }
    }
  }, [passKeyActionState]);

  useEffect(() => {
    if (!isStudentMode) return;

    if (passKeyActionState === 'passkey_validate_student_pin') {
      studentActionStateRef.current = 'passkey_validate_student_pin';
      setStudentPinValidated(false);
    } else if (
      passKeyActionState === 'passkey_hidden' &&
      passKeyActionPrevState === 'passkey_validate_student_pin' &&
      !closedWithoutAction
    ) {
      studentActionStateRef.current = null;
      setStudentPinValidated(true);
    }
  }, [passKeyActionState]);

  const setStudentProfileId = (profileId: ProfileId) => setProfileId(profileId);

  const isStudentPassKeyEnabled = (profileId: ProfileId): boolean => {
    const profile = profiles.find((p) => p.profileId === profileId);
    if (profile) {
      return profile.userPassKeyEnabled;
    } else {
      console.log(`Profile NOT found ${profileId}`);
      return false;
    }
  };

  const openPopupToValidateStudentPin = () => {
    // console.log('onOpenPopup');
    setClosedWithoutAction(false);
    dispatch(setUserPassKeyActionState('passkey_validate_student_pin'));
    dispatch(setPassKeyUiState(createPassKeyUIState(Animations.None, true, 'verify_passkey_pin')));
  };

  const openPassKeyPanelToUpdateStudentPin = () => {
    // console.log('onOpenPopup');
    setClosedWithoutAction(false);
    dispatch(setUserPassKeyActionState('passkey_update_student_pin'));
    dispatch(setPassKeyUiState(createPassKeyUIState(Animations.None, true, 'set_passkey_pin')));
  };

  const enableDisableStudentPassKey = (passedInStudentPassKeyEnabled: boolean, encodedPassKey: string) => {
    // console.log(`updateStatusUserPassKey, passedInStudentPassKeyEnabled=${passedInStudentPassKeyEnabled}`);
    if (!passedInStudentPassKeyEnabled) {
      dispatch(doEnableStudentPassKey(encodedPassKey, profileId));
    } else {
      dispatch(doDisableStudentPassKey(encodedPassKey, profileId));
    }
  };

  const openPopupToValidateAdminPin = () => {
    // console.log('onOpenPopup');
    setClosedWithoutAction(false);
    dispatch(setUserPassKeyActionState('passkey_validate_pin'));
    dispatch(setPassKeyUiState(createPassKeyUIState(Animations.None, true, 'verify_passkey_pin')));
  };

  const onClosePopup = () => {
    dispatch(setUserPassKeyActionState('passkey_hidden'));
    dispatch(setPassKeyUiState(createPassKeyUIState(Animations.None, false, 'verify_passkey_pin')));
    setClosedWithoutAction(true);
    setShowCloseButton(false);
  };

  const PassKeyPopup = (
    <Popup
      id="StudentPassCodePanelId"
      ref={passKeyRef}
      show={isPassKeyPanelVisible}
      showBackButton
      blocking
      popupType="wood"
      showCloseButton={false}
      closeOnClickOutside={false}
      onCloseEvent={onClosePopup}>
      <PassKeyPanel
        onEnter={onClosePopup}
        actionType={passKeyActionState}
        isStudentPassKeyMode={isStudentMode}
        hideResetPassKey={isStudentMode}
        profileId={profileId}
        panelName={name}
        showCloseButton={showCloseButton}
        onCloseWithoutAction={() => setClosedWithoutAction(true)}
      />
    </Popup>
  );

  return {
    PassKeyPopup,
    passKeyActionState,
    studentPinValidated,
    closedWithoutAction,
    closePopup: onClosePopup,
    validateStudentPin: openPopupToValidateStudentPin,
    updateStudentPin: openPassKeyPanelToUpdateStudentPin,
    enableStudentPassKey: enableDisableStudentPassKey,
    validateAdminPin: openPopupToValidateAdminPin,
    isStudentPassKeyEnabled,
    setStudentProfileId,
    setStudentName: setName,
    closeButtonVisible: setShowCloseButton,
  };
};
