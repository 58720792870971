import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useAppSelector } from 'app/hooks/hooks';
import { ElementSizeT, PropsWithChildren } from 'types/App.types';

import { MeIcons } from 'app/constants/Constants';
import { SpotlightEls } from 'app/constants/SpotlightElements';
import { getFontSize } from 'helpers/appHelpers';

import { getActiveProfileId, getUserId, isUserAdmin, isUserSignedIn } from 'modules/user/userSlice';
import { getSimpleCareerUrl, getViewCareerBadgesUrl } from 'modules/career/careerSlice';

import SimpleButton from 'components/buttons/simple/SimpleButton';
import MenuHandle from 'components/menu/components/MenuHandle';

import styles from './Menu.module.scss';
import { AppRoutes } from 'app/constants/Routes';

type MenuProps = {
  className?: string;
  open?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const FontSize = '2.25em';

const Menu: React.FC<MenuProps> = ({ open = true }) => {
  const menuRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
  const [showHideCls, setShowHideCls] = useState<string>('');
  const userSignedIn = useAppSelector(isUserSignedIn);
  const userIsAdmin = useAppSelector(isUserAdmin);
  const userId = useAppSelector(getUserId);
  const profileId = useAppSelector(getActiveProfileId);
  const toCareerUrl = useAppSelector(getSimpleCareerUrl);
  const toCareerBadgesUrl = useAppSelector(getViewCareerBadgesUrl);

  useEffect(() => {
    if (open) {
      setShowHideCls(styles.MenuOpen);
    } else {
      setTimeout(() => {
        setShowHideCls(styles.MenuHide);
      }, 1000);
    }
  }, [open]);

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef && menuRef.current && !menuRef.current.contains(event.target as HTMLElement)) {
      setShowHideCls(styles.MenuHide);
    }
  };

  const updateAppMenuWidth = () => {
    if (menuRef && menuRef.current) {
      const rect = menuRef.current.getBoundingClientRect();
      const fontSize = getFontSize();
      const widthInEm = Math.fround(Math.round(rect.width) / parseFloat(fontSize));
      // console.log(`Setting menu width:${Math.round(rect.width)}, fontSize:${fontSize}, widthInEm:${widthInEm}`);
      document.documentElement.style.setProperty('--app-menu-width', `${widthInEm}em`);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    updateAppMenuWidth();
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (showHideCls === styles.MenuHide) {
      updateAppMenuWidth();
    }
  }, [showHideCls, userSignedIn, userId]);

  const onMenu = () => setShowHideCls(showHideCls === styles.MenuOpen ? styles.MenuHide : styles.MenuOpen);
  const onEnableDisableHandler = (node: HTMLDivElement | null) => {
    node && (showHideCls === styles.MenuHide ? node.setAttribute('inert', '') : node.removeAttribute('inert'));
  };

  return (
    <div ref={menuRef} className={`${styles.MenuDisplay} ${showHideCls}`}>
      <div ref={onEnableDisableHandler} className={styles.MenuItemsWrapper}>
        <MenuItem to="/" end={false} itemClicked={onMenu} icon="hub">
          {userSignedIn ? 'MY HOME' : 'HOME'}
        </MenuItem>
        {!userSignedIn && (
          <MenuItem to="/auth/login" itemClicked={onMenu} icon="person">
            LOGIN
          </MenuItem>
        )}
        {userSignedIn && profileId && (
          <>
            <MenuItem to={toCareerUrl} end={false} itemClicked={onMenu} icon={MeIcons.Career}>
              MY CAREER
            </MenuItem>
            <MenuItem to={`/student/${profileId}`} end={false} itemClicked={onMenu} icon={MeIcons.Person}>
              MY PROFILE
            </MenuItem>
            <MenuItem to={toCareerBadgesUrl} itemClicked={onMenu} icon={MeIcons.Trophy}>
              MY AWARDS
            </MenuItem>
            <MenuItem to="/games" itemClicked={onMenu} icon={MeIcons.Game}>
              GAMES
            </MenuItem>
          </>
        )}
        <>
          {userIsAdmin && (
            <>
              <MenuItem to="/manage" end={false} itemClicked={onMenu} icon={MeIcons.EditSquare}>
                EDITOR
              </MenuItem>
              <MenuItem to={AppRoutes.UIComponentsLibrary} end={true} itemClicked={onMenu} icon={MeIcons.Library}>
                UI LIBRARY
              </MenuItem>
            </>
          )}
          {userSignedIn && (
            <div className={styles.MenuSectionRestricted}>
              <MenuItem
                textSize="sam"
                iconSize="mel"
                to={`/account/${userId}`}
                end={false}
                itemClicked={onMenu}
                icon={MeIcons.AccountAdmin}>
                PARENT ACCESS
              </MenuItem>
              <MenuItem textSize="sam" iconSize="mel" to="/legal" end={false} itemClicked={onMenu} icon="copyright">
                LEGAL
              </MenuItem>
            </div>
          )}
        </>
        {!userSignedIn && (
          <MenuItem to="/legal" end={false} itemClicked={onMenu} icon="copyright">
            LEGAL
          </MenuItem>
        )}
      </div>
      <MenuHandle
        className={SpotlightEls.MenuHandle}
        menuClickHandler={onMenu}
        appMenu
        hideMenu={showHideCls === styles.MenuHide}
      />
    </div>
  );
};

type MenuItemProps = {
  className?: string;
  to: string;
  end?: boolean;
  icon?: string;
  textSize?: ElementSizeT;
  iconSize?: ElementSizeT;
  itemClicked?: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

const MenuItem: React.FC<PropsWithChildren<MenuItemProps>> = ({
  itemClicked = () => undefined,
  end = true,
  tabIndex = -1,
  icon,
  textSize = 'mel',
  iconSize = 'mlg',
  ...props
}) => {
  return (
    <NavLink
      to={props.to}
      end={end}
      tabIndex={tabIndex}
      onClick={itemClicked}
      className={({ isActive, isPending }) =>
        isPending ? styles.MenuItemPending : isActive ? styles.MenuItemActive : styles.MenuItem
      }>
      <SimpleButton textSize={textSize} iconSize={iconSize} icon={icon} style={{ fontSize: `${FontSize}` }}>
        {props.children}
      </SimpleButton>
    </NavLink>
  );
};

export default Menu;
