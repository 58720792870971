import { ArithmeticT, Arithmetic } from 'types/App.types';
import { GameHistoryReportT, CareerHistoryReportT } from 'types/Career.types';
import { CourseUI, CourseT } from 'types/Course.types';
import { GameQuestionT, GameWithLockedStatusT } from 'types/Game.types';
import { LessonT } from 'types/Lesson.types';
import { QuestionT } from 'types/Question.types';
import { QuizT } from 'types/Quiz.types';
import { TutorialT } from 'types/Tutorial.types';
import { IExpandedProfile } from 'types/User.types';

// #region SORTERS
export const sortCourseUIs = (a: CourseUI, b: CourseUI) => {
  /**
   * ORDER:
   * ************
   * Count
   * Compare
   * Add
   * Subtract
   * Multiply
   * Divide
   */

  const aCategory = a.courseCategory as ArithmeticT;
  const bCategory = b.courseCategory as ArithmeticT;

  if (aCategory === Arithmetic.Count || bCategory === Arithmetic.Count) {
    return aCategory === bCategory ? a.courseLevel - b.courseLevel : aCategory === Arithmetic.Count ? -1 : 1;
  } else if (aCategory === Arithmetic.Compare || bCategory === Arithmetic.Compare) {
    return aCategory === bCategory ? a.courseLevel - b.courseLevel : aCategory === Arithmetic.Compare ? -1 : 1;
  } else if (aCategory === Arithmetic.Add || bCategory === Arithmetic.Add) {
    return aCategory === bCategory ? a.courseLevel - b.courseLevel : aCategory === Arithmetic.Add ? -1 : 1;
  } else if (aCategory === Arithmetic.Subtract || bCategory === Arithmetic.Subtract) {
    return aCategory === bCategory ? a.courseLevel - b.courseLevel : aCategory === Arithmetic.Subtract ? -1 : 1;
  } else if (aCategory === Arithmetic.Multiply || bCategory === Arithmetic.Multiply) {
    return aCategory === bCategory ? a.courseLevel - b.courseLevel : aCategory === Arithmetic.Multiply ? -1 : 1;
  } else if (aCategory === Arithmetic.Divide || bCategory === Arithmetic.Divide) {
    return aCategory === bCategory ? a.courseLevel - b.courseLevel : aCategory === Arithmetic.Divide ? -1 : 1;
  }

  return a.courseLevel - b.courseLevel;
};

export const sortCourses = (a: CourseT, b: CourseT) => {
  /**
   * ORDER:
   * ************
   * Count
   * Compare
   * Add
   * Subtract
   * Multiply
   * Divide
   */

  return a.courseLevel - b.courseLevel;
};

export const sortLessons = (a: LessonT, b: LessonT) => {
  /**
   * ORDER:
   * ************
   * Count
   * Compare
   * Add
   * Subtract
   * Multiply
   * Divide
   */

  const aCategory = a.category;
  const bCategory = b.category;

  if (aCategory === Arithmetic.Count || bCategory === Arithmetic.Count) {
    return aCategory === bCategory ? a.level - b.level : aCategory === Arithmetic.Count ? -1 : 1;
  } else if (aCategory === Arithmetic.Compare || bCategory === Arithmetic.Compare) {
    return aCategory === bCategory ? a.level - b.level : aCategory === Arithmetic.Compare ? -1 : 1;
  } else if (aCategory === Arithmetic.Add || bCategory === Arithmetic.Add) {
    return aCategory === bCategory ? a.level - b.level : aCategory === Arithmetic.Add ? -1 : 1;
  } else if (aCategory === Arithmetic.Subtract || bCategory === Arithmetic.Subtract) {
    return aCategory === bCategory ? a.level - b.level : aCategory === Arithmetic.Subtract ? -1 : 1;
  } else if (aCategory === Arithmetic.Multiply || bCategory === Arithmetic.Multiply) {
    return aCategory === bCategory ? a.level - b.level : aCategory === Arithmetic.Multiply ? -1 : 1;
  } else if (aCategory === Arithmetic.Divide || bCategory === Arithmetic.Divide) {
    return aCategory === bCategory ? a.level - b.level : aCategory === Arithmetic.Divide ? -1 : 1;
  }

  return a.level - b.level;
};

export const sortTutorials = (a: TutorialT, b: TutorialT) => {
  /**
   * ORDER:
   * ************
   * Count
   * Compare
   * Add
   * Subtract
   * Multiply
   * Divide
   */

  const getStringCmp = (strA: string, strB: string): number => (strA === strB ? 0 : strA < strB ? -1 : 1);

  const aCategory = a.category;
  const bCategory = b.category;

  if (aCategory === Arithmetic.Count || bCategory === Arithmetic.Count) {
    return aCategory === bCategory ? getStringCmp(a.title, b.title) : aCategory === Arithmetic.Count ? -1 : 1;
  } else if (aCategory === Arithmetic.Compare || bCategory === Arithmetic.Compare) {
    return aCategory === bCategory ? getStringCmp(a.title, b.title) : aCategory === Arithmetic.Compare ? -1 : 1;
  } else if (aCategory === Arithmetic.Add || bCategory === Arithmetic.Add) {
    return aCategory === bCategory ? getStringCmp(a.title, b.title) : aCategory === Arithmetic.Add ? -1 : 1;
  } else if (aCategory === Arithmetic.Subtract || bCategory === Arithmetic.Subtract) {
    return aCategory === bCategory ? getStringCmp(a.title, b.title) : aCategory === Arithmetic.Subtract ? -1 : 1;
  } else if (aCategory === Arithmetic.Multiply || bCategory === Arithmetic.Multiply) {
    return aCategory === bCategory ? getStringCmp(a.title, b.title) : aCategory === Arithmetic.Multiply ? -1 : 1;
  } else if (aCategory === Arithmetic.Divide || bCategory === Arithmetic.Divide) {
    return aCategory === bCategory ? getStringCmp(a.title, b.title) : aCategory === Arithmetic.Divide ? -1 : 1;
  }

  return getStringCmp(a.title, b.title);
};

export const sortQuizzes = (aQuiz: QuizT, bQuiz: QuizT) => {
  /**
   * ORDER:
   * ************
   * Count
   * Compare
   * Add
   * Subtract
   * Multiply
   * Divide
   */

  const aCategory = aQuiz.quizCategory;
  const bCategory = bQuiz.quizCategory;

  if (aCategory === Arithmetic.Count || bCategory === Arithmetic.Count) {
    return aCategory === bCategory ? aQuiz.quizLevel - bQuiz.quizLevel : aCategory === Arithmetic.Count ? -1 : 1;
  } else if (aCategory === Arithmetic.Compare || bCategory === Arithmetic.Compare) {
    return aCategory === bCategory ? aQuiz.quizLevel - bQuiz.quizLevel : aCategory === Arithmetic.Compare ? -1 : 1;
  } else if (aCategory === Arithmetic.Add || bCategory === Arithmetic.Add) {
    return aCategory === bCategory ? aQuiz.quizLevel - bQuiz.quizLevel : aCategory === Arithmetic.Add ? -1 : 1;
  } else if (aCategory === Arithmetic.Subtract || bCategory === Arithmetic.Subtract) {
    return aCategory === bCategory ? aQuiz.quizLevel - bQuiz.quizLevel : aCategory === Arithmetic.Subtract ? -1 : 1;
  } else if (aCategory === Arithmetic.Multiply || bCategory === Arithmetic.Multiply) {
    return aCategory === bCategory ? aQuiz.quizLevel - bQuiz.quizLevel : aCategory === Arithmetic.Multiply ? -1 : 1;
  } else if (aCategory === Arithmetic.Divide || bCategory === Arithmetic.Divide) {
    return aCategory === bCategory ? aQuiz.quizLevel - bQuiz.quizLevel : aCategory === Arithmetic.Divide ? -1 : 1;
  }

  return aQuiz.quizLevel - bQuiz.quizLevel;
};

export const sortQuestionHistoryByDate = (a: CareerHistoryReportT, b: CareerHistoryReportT) => {
  const getStringCmp = (strA: string, strB: string): number => (strA === strB ? 0 : strA < strB ? -1 : 1);
  const getStringTimeCmp = (a: CareerHistoryReportT, b: CareerHistoryReportT) => {
    const diff = getStringCmp(String(a.title), String(b.title));
    if (diff === 0) {
      const val = getStringCmp(a.questionCategory, b.questionCategory);
      if (val === 0) {
        return (a.createdOn as number) - (b.createdOn as number);
      }
      return val;
    }
    return diff;
  };

  return getStringTimeCmp(a, b);
};

export const sortGameHistoryByDate = (a: GameHistoryReportT, b: GameHistoryReportT) => {
  const getStringCmp = (strA: string, strB: string): number => (strA === strB ? 0 : strA < strB ? -1 : 1);
  const getStringTimeCmp = (a: GameHistoryReportT, b: GameHistoryReportT) => {
    const diff = getStringCmp(String(a.gameInstance.gameTitle), String(b.gameInstance.gameTitle));
    if (diff === 0) {
      const val = getStringCmp(a.gameInstance.gameLevel, b.gameInstance.gameLevel);
      if (val === 0) {
        return (a.createdOn as number) - (b.createdOn as number);
      }
      return val;
    }
    return diff;
  };

  return getStringTimeCmp(a, b);
};

export const sortGameHistory = (a: GameHistoryReportT, b: GameHistoryReportT) => {
  const getStringCmp = (strA: string, strB: string): number => (strA === strB ? 0 : strA < strB ? -1 : 1);
  const getStringTimeCmp = (a: GameHistoryReportT, b: GameHistoryReportT) => {
    const diff = getStringCmp(String(a.gameInstance.gameTitle), String(b.gameInstance.gameTitle));
    if (diff === 0) {
      const val = getStringCmp(a.gameInstance.gameLevel, b.gameInstance.gameLevel);
      if (val === 0) {
        return (a.timeToSolve as number) - (b.timeToSolve as number);
      }
      return val;
    }
    return diff;
  };

  return getStringTimeCmp(a, b);
};

export const sortHistory = (a: CareerHistoryReportT, b: CareerHistoryReportT) => {
  /**
   * ORDER:
   * ************
   * Count
   * Compare
   * Add
   * Subtract
   * Multiply
   * Divide
   */

  const aCategory = a.questionCategory;
  const bCategory = b.questionCategory;

  const getStringCmp = (strA: string, strB: string): number => (strA === strB ? 0 : strA < strB ? -1 : 1);
  const getStringTimeCmp = (a: CareerHistoryReportT, b: CareerHistoryReportT) => {
    const val = getStringCmp(String(a.questionTitle), String(b.questionTitle));
    if (val === 0) {
      return (a.timeToSolve as number) - (b.timeToSolve as number);
    }
    return val;
  };
  if (aCategory === Arithmetic.Count || bCategory === Arithmetic.Count) {
    return aCategory === bCategory ? getStringTimeCmp(a, b) : aCategory === Arithmetic.Count ? -1 : 1;
  } else if (aCategory === Arithmetic.Compare || bCategory === Arithmetic.Compare) {
    return aCategory === bCategory ? getStringTimeCmp(a, b) : aCategory === Arithmetic.Compare ? -1 : 1;
  } else if (aCategory === Arithmetic.Add || bCategory === Arithmetic.Add) {
    return aCategory === bCategory ? getStringTimeCmp(a, b) : aCategory === Arithmetic.Add ? -1 : 1;
  } else if (aCategory === Arithmetic.Subtract || bCategory === Arithmetic.Subtract) {
    return aCategory === bCategory ? getStringTimeCmp(a, b) : aCategory === Arithmetic.Subtract ? -1 : 1;
  } else if (aCategory === Arithmetic.Multiply || bCategory === Arithmetic.Multiply) {
    return aCategory === bCategory ? getStringTimeCmp(a, b) : aCategory === Arithmetic.Multiply ? -1 : 1;
  } else if (aCategory === Arithmetic.Divide || bCategory === Arithmetic.Divide) {
    return aCategory === bCategory ? getStringTimeCmp(a, b) : aCategory === Arithmetic.Divide ? -1 : 1;
  }

  return getStringTimeCmp(a, b);
};

export const sortQuestions = (a: QuestionT, b: QuestionT) => {
  /**
   * ORDER:
   * ************
   * Count
   * Compare
   * Add
   * Subtract
   * Multiply
   * Divide
   */

  const aCategory = a.categoryType;
  const bCategory = b.categoryType;

  const getStringCmp = (strA: string, strB: string): number => (strA === strB ? 0 : strA < strB ? -1 : 1);
  const getStringTimeCmp = (a: QuestionT, b: QuestionT) => {
    const val = getStringCmp(String(a.title), String(b.title));
    return val;
  };
  if (aCategory === Arithmetic.Count || bCategory === Arithmetic.Count) {
    return aCategory === bCategory ? getStringTimeCmp(a, b) : aCategory === Arithmetic.Count ? -1 : 1;
  } else if (aCategory === Arithmetic.Compare || bCategory === Arithmetic.Compare) {
    return aCategory === bCategory ? getStringTimeCmp(a, b) : aCategory === Arithmetic.Compare ? -1 : 1;
  } else if (aCategory === Arithmetic.Add || bCategory === Arithmetic.Add) {
    return aCategory === bCategory ? getStringTimeCmp(a, b) : aCategory === Arithmetic.Add ? -1 : 1;
  } else if (aCategory === Arithmetic.Subtract || bCategory === Arithmetic.Subtract) {
    return aCategory === bCategory ? getStringTimeCmp(a, b) : aCategory === Arithmetic.Subtract ? -1 : 1;
  } else if (aCategory === Arithmetic.Multiply || bCategory === Arithmetic.Multiply) {
    return aCategory === bCategory ? getStringTimeCmp(a, b) : aCategory === Arithmetic.Multiply ? -1 : 1;
  } else if (aCategory === Arithmetic.Divide || bCategory === Arithmetic.Divide) {
    return aCategory === bCategory ? getStringTimeCmp(a, b) : aCategory === Arithmetic.Divide ? -1 : 1;
  }

  return getStringTimeCmp(a, b);
};

export const sortProfiles = (a: IExpandedProfile, b: IExpandedProfile) => {
  const aName = `${a.createdByUser.firstname} ${a.createdByUser.lastname}`;
  const bName = `${b.createdByUser.firstname} ${b.createdByUser.lastname}`;

  return aName === bName ? 0 : aName < bName ? -1 : 1;
};

export const gameLevelSort = (a: GameQuestionT, b: GameQuestionT): number =>
  parseInt(a.level.replace('level', '')) - parseInt(b.level.replace('level', ''));

export const gameLockedSort = (a: GameWithLockedStatusT, b: GameWithLockedStatusT): number =>
  (((b.locked ? 0 : 1) as number) - (a.locked ? 0 : 1)) as number;

export const sortNumbers = (a: number, b: number) => {
  return a - b;
};
// #endregion
// #endregion
