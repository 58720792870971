import React, { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks/hooks';

import { ArticlesConfigT } from 'types/App.types';
import { NewsVisualState } from 'types/User.types';

import { MeIcons } from 'app/constants/Constants';
import { Handle } from 'app/constants/Localization';

import CollapsibleList from 'components/list/collapsible/CollapsibleList';
import MeIcon from 'components/icon/meicon/MeIcon';
import TitleHeader from 'components/titleheader/TitleHeader';

import EnticePanel from 'scenes/montessori/subscenes/news/components/enticepanel/EnticePanel';
import NewsArticlePanel from 'scenes/montessori/subscenes/news/components/newsarticle/NewsArticlePanel'; /* cspell: disable-line */
import { doUpdateUserPreferencesNews, getUserAppPreferencesNews } from 'modules/user/userPreferencesSlice';
import { getActiveProfileId, isUserSignedIn } from 'modules/user/userSlice';
import { getAppConfigNews, getAppVersionId } from 'scenes/montessori/appUiSlice';


import styles from './News.module.scss';

const News: React.FC = () => {
  const readStateRef = useRef<boolean>(false);
  const isSignedIn = useAppSelector(isUserSignedIn);
  const isActiveProfileId = useAppSelector(getActiveProfileId);
  const appVersion = useAppSelector(getAppVersionId);
  const news: ArticlesConfigT = useAppSelector(getAppConfigNews);
  const dispatch = useAppDispatch();

  const newsUI: NewsVisualState = useAppSelector(getUserAppPreferencesNews);

  const updateNewsState = (flag: boolean) => {
    if (isSignedIn && isActiveProfileId) dispatch(doUpdateUserPreferencesNews(flag));
  };

  useEffect(() => {
    if (newsUI.open && !newsUI.read && !readStateRef.current) {
      setTimeout(() => {
        updateNewsState(newsUI.open);
      }, 3000);
    }
    readStateRef.current = true;
  }, [newsUI]);

  return (
    <div className={styles.NewsDisplay}>
      <CollapsibleList
        open={newsUI.open}
        handleClickable={true}
        isListOpen={updateNewsState}
        aria-expanded={newsUI.open}
        handle={
          <TitleHeader fillWidth icon={MeIcons.Newspaper} showHorizontalRule={false} isFilledIcon>
            {Handle.News}
            <div className={styles.ActionButtons}>
              {isSignedIn && <NewsNotificationIcon className={styles.NewsHeaderHandleIcon} isRead={newsUI.read} />}
            </div>
          </TitleHeader>
        }>
        <>
          <section itemID="NewsColumnId" className={styles.NewsColumn}>
            <NewsArticlePanel appVersion={appVersion} articleId={news.articles[0]} />
            {!isSignedIn && <EnticePanel className={styles.AlignCenter} />}
            <NewsArticlePanel articleId={news.articles[1]} />
          </section>
        </>
      </CollapsibleList>
    </div>
  );
};

export default News;

type NewsNotificationIconProps = {
  className?: string;
  isRead: boolean;
};
const NewsNotificationIcon: React.FC<NewsNotificationIconProps> = (props) => {
  return (
    <MeIcon
      insetIcon
      lineHeight="line1"
      size="lge"
      showPointerCursor
      className={props.className ?? ''}
      isFilled={!props.isRead}
      icon={props.isRead ? MeIcons.Read : MeIcons.UnRead}
    />
  );
};
