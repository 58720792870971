export const SpotlightEls = {
  AwardsBtn: 'HLSPOT_CareerHub_awards_button',
  BadgeProgress: 'HLSPOT_CareerHub_badge_progress',
  ChapterTab: 'HLSPOT_Tutorial_chapter_tabs',
  CountNumbersLA: 'HLSPOT_CountNumbers_left_arrow',
  CountNumbersRA: 'HLSPOT_CountNumbers_right_arrow',
  CoursesProgress: 'HLSPOT_CareerHub_courses_progress',
  Flip3DBody: 'HLSPOT_FlipBoard3D_body',
  Flip3DNavLA: 'HLSPOT_FlipBoard3D_left_arrow',
  Flip3DNavRA: 'HLSPOT_FlipBoard3D_right_arrow',
  ManageCoursesBtn: 'HLSPOT_CareerHub_manage_courses_button',
  MenuHandle: 'HLSPOT_CareerHub_menu_handle',
  PlayPauseBtn: 'HLSPOT_CareerCarousel_playpause_button',
  StarAwardsDisplay: 'HLSPOT_CareerHub_StarAwards',
  TutorialCardHelpIcon: 'HLSPOT_TutorCard_help_icon',
  VoiceBtn: 'HLSPOT_BasicCardHeader_voice_button',
  MinusBalanceLivesPanel: 'HLSPOT_MinusBalance_lives_panel',
  MinusBalanceActiveRow: 'HLSPOT_MinusBalance_active_row',
  MinusBalanceGateRow: 'HLSPOT_MinusBalance_gate_row',
  MinusBalanceActionButtons: 'HLSPOT_MinusBalance_action_buttons',
  BridgeBuilderBoardPanel: 'HLSPOT_BridgeBuilderBoard_panel',
  BridgeBuilderUserBlock: 'HLSPOT_BridgeBuilderUserBlock',
} as const;

export type SpotlightTarget = (typeof SpotlightEls)[keyof typeof SpotlightEls];
