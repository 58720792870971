import React, { MouseEvent, useEffect, useState } from 'react';
import styles from './CopyToClipboard.module.scss';
import { useAppDispatch, useAppSelector } from 'app/hooks/hooks';
import { setToastNotification, showToastNotification } from 'modules/user/userUiSlice';
import { MeIconProps, AppNotification } from 'types/App.types';
import MeIcon from 'components/icon/meicon/MeIcon';

type CopyToClipboardProps = {
  className?: string;
  textToCopy: string;
  icon?: string;
} & React.HTMLAttributes<HTMLSpanElement> &
  Omit<MeIconProps, 'icon'>;

const EMPTY_CLIPBOARD = 'content_paste';
const COPIED_CLIPBOARD = 'inventory';

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({
  className = '',
  textToCopy,
  icon = EMPTY_CLIPBOARD,
  size = 'xs15',
  ...props
}) => {
  const dispatch = useAppDispatch();
  const [clipboardIcon, setClipboardIcon] = useState<string>(icon);

  const isShowing = useAppSelector(showToastNotification);

  useEffect(() => {
    if (!isShowing && clipboardIcon === COPIED_CLIPBOARD) {
      setClipboardIcon(EMPTY_CLIPBOARD);
    }
  }, [isShowing, clipboardIcon]);

  const onCopyText = (e: MouseEvent, textToCopy: string) => {
    e.preventDefault();
    e.stopPropagation();

    setClipboardIcon(COPIED_CLIPBOARD);
    navigator.clipboard.writeText(textToCopy);

    dispatch(
      setToastNotification({
        notification: { message: 'Text copied to clipboard', type: AppNotification.Success },
        show: true,
      })
    );
  };

  return (
    <MeIcon
      {...props}
      icon={clipboardIcon}
      className={`${styles.CopyToClipboardIcon} ${className}`}
      onClick={(e) => onCopyText(e, textToCopy)}
      size={size}
    />
  );
};

export default CopyToClipboard;
