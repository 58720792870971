import React from 'react';
import { useAppSelector, useAppDispatch } from 'app/hooks/hooks';
import { getToastSuccessPayload } from 'helpers/uiHelpers';
import IconButton from 'components/buttons/icon/IconButton';
import { showToastNotification, setShowToast, setToastNotification } from 'modules/user/userUiSlice';

type ShowToastProps = {
  className?: string;
};

const ShowToast: React.FC<ShowToastProps> = () => {
  const isToasterVisible = useAppSelector(showToastNotification);
  const dispatch = useAppDispatch();

  const showToast = () => {
    if (isToasterVisible) {
      dispatch(setShowToast(false));
    } else {
      dispatch(setToastNotification(getToastSuccessPayload('Hello there...!!!')));
    }
  };
  return <IconButton icon="toast" isFilled={isToasterVisible} onClick={showToast} />;
};

export default ShowToast;
