import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks/hooks';
import * as DomPurify from 'dompurify';
import { useCapture } from 'app/hooks/useCapture';
import { MessageT, VoidFunc } from 'types/App.types';

import { createMessage } from 'api/AppAPI';
import { getInitialMessage } from 'api/converters/MessageConverter';

import { AppIcons } from 'app/constants/Constants';
import { ComponentTitle, HeaderTitle } from 'app/constants/Localization';
import { getToastSuccessPayload } from 'helpers/uiHelpers';

import FloatingButton from 'components/buttons/floatingbutton/FloatingButton';
import InputText from 'components/text/inputtext/InputText';
import Popup from 'components/popup/Popup';
import RoundedButton from 'components/buttons/rounded/RoundedButton';
import TitleHeader from 'components/titleheader/TitleHeader';
import MeText from 'components/text/metext/MeText';

import { setToastNotification } from 'modules/user/userUiSlice';
import { getShowContactSupportPanel } from 'modules/user/userSlice';

import styles from './CreateMessageForm.module.scss';


type MessageFormProps = {
  className?: string;
  onMessageSent: VoidFunc;
};

const CreateMessageForm: React.FC = () => {
  const captureRef = useRef<HTMLDivElement>(null);
  const [showMessageForm, setShowMessageForm] = useState<boolean>(false);

  const showMessageFormPopup = () => setShowMessageForm(!showMessageForm);
  const onMessageFormPopupClose = () => setShowMessageForm(!showMessageForm);

  const showFAB = useAppSelector(getShowContactSupportPanel);

  return (
    <>
      <FloatingButton
        onClick={showMessageFormPopup}
        floatRight={false}
        visible={showFAB}
        mainColor={false}
        icon={AppIcons.Common.Feedback}
        buttonLabel="open contact form"
      />
      {showFAB && (
        <Popup
          id="MessageFormPopupId"
          ref={captureRef}
          show={showMessageForm}
          popupType="wood"
          className={styles.CapturePopup}
          bodyClassName={styles.CapturePopupBodyPanel}
          onCloseEvent={onMessageFormPopupClose}>
          {showMessageForm && <MessageForm onMessageSent={onMessageFormPopupClose} />}
        </Popup>
      )}
    </>
  );
};

const MessageForm: React.FC<MessageFormProps> = (props) => {
  const baseCls = `${styles.CreateMessageForm} ${props.className ?? ''}`;
  const { captureState } = useCapture();
  const dispatch = useAppDispatch();

  const [messageTitle, setMessageTitle] = useState<string>('');
  const [messageBody, setMessageBody] = useState<string>('');
  const [message, setMessage] = useState<MessageT>();

  const btnDisabled = useMemo(() => {
    return messageTitle.trim().length <= 0 || messageBody.trim().length <= 0;
  }, [messageTitle, messageBody]);

  useEffect(() => {
    if (captureState) {
      const msgTemp = getInitialMessage(captureState);
      setMessage(msgTemp);
    }
  }, [captureState]);

  const resetMessage = () => {
    setMessage(undefined);
    setMessageBody('');
    setMessageTitle('');
  };

  const setMessageTitleDirty = (str: string) => setMessageTitle(DomPurify.sanitize(str));
  const setMessageBodyDirty = (str: string) => setMessageBody(DomPurify.sanitize(str));

  const postMessage = async () => {
    const messageToSend: MessageT = { ...(message as MessageT) };
    await createMessage({ ...messageToSend, messageTitle, messageBody });

    dispatch(setToastNotification(getToastSuccessPayload('Message Sent')));
    resetMessage();
    props.onMessageSent();
  };

  return (
    <div className={baseCls}>
      <TitleHeader icon={AppIcons.Common.Feedback} titleSize="mlg" ruleSize="med" showHorizontalRule>
        {HeaderTitle.CreateMessage}
      </TitleHeader>

      <MeText textSize="sma" weight="bold500">
        We like receiving messages from our users! Do not hesitate to reach out and let us know how we can make your
        experience even better.
      </MeText>

      <InputText
        id="contactSubjectId"
        borderType="outline"
        onChange={setMessageTitleDirty}
        titleLabel={ComponentTitle.Subject}
        value={messageTitle}
      />
      <InputText
        id="messageId"
        borderType="outline"
        multiline
        titleLabel={ComponentTitle.Message}
        onChange={setMessageBodyDirty}
        value={messageBody}
      />
      <RoundedButton
        icon={AppIcons.Common.Send}
        disabled={btnDisabled}
        mainColor
        onClick={postMessage}
        style={{ alignSelf: 'flex-end' }}>
        SEND
      </RoundedButton>
    </div>
  );
};

export default CreateMessageForm;
