import React from 'react';
import { BoldWeightT, ElementSizeT, LineHeightT, PropsWithChildren } from 'types/App.types';

import styles from './XCloseButton.module.scss';


type XCloseButtonOwnProps = {
  className?: string;
  size?: ElementSizeT;
  weight?: BoldWeightT;
  lineHeight?: LineHeightT;
  icon?: string;
} & Pick<React.HTMLAttributes<HTMLSpanElement>, 'onClick' | 'style'>;

type XCloseButtonProps = PropsWithChildren<XCloseButtonOwnProps>;

const XCloseButton: React.FC<XCloseButtonProps> = ({
  className = '',
  size = 'med',
  weight = 'bold400',
  lineHeight = 'line1',
  icon = 'close',
  ...props
}) => {
  const fontPropsCls = `FontStyle ${size} ${weight} ${lineHeight}`;
  return (
    <div className={`${styles.XCloseButtonInsetText} ${className}`}>
      <span
        aria-label={`${icon} icon`}
        className={`material-symbols-outlined ${styles.XCloseButton} ${fontPropsCls}`}
        {...props}>
        {icon}
      </span>
    </div>
  );
};

export default XCloseButton;
