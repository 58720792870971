import { store } from 'app/store';
import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { getModifiedInfo } from 'helpers/careerHelpers';
import { QuizT } from 'types/Quiz.types';

// #region CONVERTERS *******************************************************************
export const getInitialQuiz = (): QuizT => {
  const userId = store.getState().user.uid || '';
  const initQuiz: QuizT = {
    quizId: 'createQuizId',
    quizTitle: '',
    quizCategory: 'unknown',
    // questions: [],
    quizLevel: 1,
    badgeId: 'no_badge',
    createdBy: userId,
    createdOn: Date.now(),
    modifiedBy: userId,
    modifiedOn: Date.now(),
    versionedQuestionIds: [],
    versionId: '',
  };

  return initQuiz;
};

export const quizToDoc = (quiz: QuizT): DocumentData => {
  return {
    id: quiz.quizId,
    title: quiz.quizTitle,
    type: quiz.quizCategory,
    level: quiz.quizLevel,
    badgeId: quiz.badgeId,
    createdBy: quiz.createdBy,
    createdOn: quiz.createdOn,
    modifiedBy: quiz.modifiedBy,
    modifiedOn: quiz.modifiedOn,
    versionedQuestionIds: quiz.versionedQuestionIds || [],
    versionId: quiz.versionId || '',
  };
};

export const quizConverter: FirestoreDataConverter<QuizT> = {
  toFirestore(quiz: QuizT): DocumentData {
    return { ...quizToDoc(quiz), ...getModifiedInfo() };
  },

  fromFirestore(docSnap: QueryDocumentSnapshot, options?: SnapshotOptions): QuizT {
    const data = docSnap.data(options);
    return {
      quizId: data.id,
      quizTitle: data.title,
      quizCategory: data.type,
      quizLevel: data.level,
      badgeId: data.badgeId || 'no_badge',
      createdBy: data.createdBy,
      createdOn: data.createdOn,
      modifiedBy: data.modifiedBy,
      modifiedOn: data.modifiedOn,
      versionedQuestionIds: data.versionedQuestionIds || [],
      versionId: data.versionId || '',
    };
  },
};
// #endregion
