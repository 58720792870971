import React, { useEffect, useState } from 'react';
import { CareerBadgeItemT } from 'types/Career.types';

import { AppIcons } from 'app/constants/Constants';
import { useStyledLogger } from 'app/config/LogConfig';
import { ComponentTitle } from 'app/constants/Localization';
import { isPrint } from 'helpers/appHelpers';
import { getProgressClass } from 'helpers/uiHelpers';

import ProgressIndicator from 'components/progress/ProgressIndicator';

type BadgesProgressProps = {
  className?: string;
  badges: Array<CareerBadgeItemT>;
};

const PRINT_FLAG = false;

const BadgesProgress: React.FC<BadgesProgressProps> = (props) => {
  const printMsg = useStyledLogger('BadgesProgress', 'darkkhaki', 'cornsilk');
  const [localBadges, setLocalBadges] = useState<Array<CareerBadgeItemT>>([]);

  const [totalBadges, setTotalBadges] = useState<number>(0);
  const [currentBadges, setCurrentBadges] = useState<number>(0);
  const [badgeCls, setBadgeCls] = useState<string>('');

  useEffect(() => {
    if (props.badges) {
      setLocalBadges(props.badges);
    }
  }, [props.badges]);

  useEffect(() => {
    if (localBadges && localBadges.length > 0) {
      const totalBadges = localBadges.length;
      const completedBadges: number = localBadges.filter((b) => b.completed).length;
      const percentage: number = totalBadges > 0 ? Math.round((completedBadges / totalBadges) * 100) : 0;
      const cls = getProgressClass(Math.round(percentage / 10));
      const propNames = 'totalBadges:completedBadges';
      const propValues = `${totalBadges}:${completedBadges}`;
      isPrint(PRINT_FLAG) && console.log(...printMsg('calculateBadgesProgress', propNames, propValues));
      setTotalBadges(totalBadges);
      setCurrentBadges(completedBadges);
      setBadgeCls(cls);
    }
  }, [localBadges]);

  return (
    <ProgressIndicator
      icon={AppIcons.Common.Badge}
      max={totalBadges}
      isFilledIcon={currentBadges > 0}
      value={currentBadges}
      className={`${props.className ?? ''} ${badgeCls}`}
      title={ComponentTitle.BadgesEarned}
    />
  );
};

export default BadgesProgress;
