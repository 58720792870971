import React from 'react';
import Rule from 'components/rule/Rule';
import Logo from 'components/logo/Logo';
import LinkText from 'components/text/metext/MeText';
import MeIcon from 'components/icon/meicon/MeIcon';

import styles from './Footer.module.scss';

type FooterProps = {
  className?: string;
} & Pick<React.HTMLAttributes<HTMLElement>, 'style'>;

const Footer: React.FC<FooterProps> = (props) => {
  return (
    <footer className={`${styles.AppFooter} ${props.className ?? ''}`} style={props.style}>
      <Rule size="thin" zeroMargin />
      <div style={{ display: 'flex', gap: '1em' }}>
        <Logo />
        <div className={styles.AppFooterTextRow}>
          <MeIcon icon="copyright" size="xs145" />&nbsp;
          <LinkText id="eleventheyeLogo" textSize="xs145" weight="bold500" isAnchor={false}>
            2024 eleventheye, Inc
          </LinkText>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
