import { useEffect } from 'react';
import { useAppSelector } from 'app/hooks/hooks';
import { getActiveSceneTitle } from 'scenes/scenesSlice';

export const usePageTitle = () => {
  const sceneTitle: string = useAppSelector(getActiveSceneTitle);

  const setPageTitle = (title: string) => {
    document.title = title;
  };

  useEffect(() => {
    if (sceneTitle) {
      setPageTitle(sceneTitle);
    }
  }, [sceneTitle]);

  return setPageTitle;
};
