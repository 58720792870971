/* eslint-disable @typescript-eslint/no-explicit-any */
import { store } from 'app/store';
import { db } from '../firebase/Firebase';
import { collection, doc, getDoc, getDocs, orderBy, query, setDoc, updateDoc } from 'firebase/firestore';
import { defer, redirect } from 'react-router-dom';

import { GameQuestionUpdateResponseT, GameUpdateResponseT, PublicEntity, Status, StatusT } from 'types/App.types';
import { GameId, GameQuestionT, GameQuestionsT, GameT, GameWithQuestionResponse, GamesT } from 'types/Game.types';

import { setToastNotification } from 'modules/user/userUiSlice';
import {
  setGames,
  setGamesStatus,
  setPublicGameIds,
  updateGame,
  updateGameQuestion,
} from 'scenes/management/managerSlice';
import { doSetActiveScene, setLastActiveManageScene } from 'scenes/scenesSlice';
import {
  addCollectionGame,
  getCollectionGameQuestion,
  getGamesCollection,
  setGamesCollection,
  setGamesCollectionStatus,
  updateCollectionGame,
} from 'scenes/games/gamesSlice';

import { useStyledLogger } from 'app/config/LogConfig';
import { getModifiedInfo, toPublicGameId } from 'helpers/careerHelpers';
import { gameLevelSort } from 'helpers/sortHelpers';
import {
  gameConverter,
  gameToDoc,
  gameQuestionConverter,
  gameQuestionToDoc,
  getInitialGame,
  getInitialGameQuestion,
} from 'api/converters/GameConverter';
import { getToastSuccessPayload } from 'helpers/uiHelpers';
import { isPrint } from 'helpers/appHelpers';
import { fetchPublicEntityIds } from 'api/PublicEntitiesAPI';
import { isUserAdmin } from 'modules/user/userSlice';
import { AppRoutes } from 'app/constants/Routes';

const PRIMARY_COLOR = 'salmon';
const SECONDARY_COLOR = 'cornsilk';
const CLS_NAME = 'GameAPI';

const printMsg = useStyledLogger(CLS_NAME, PRIMARY_COLOR, SECONDARY_COLOR);
const PRINT_FLAG = false;

// #region GAMES APIs *******************************************************************
export const fetchGames = async (): Promise<GamesT> => {
  const collectionData = collection(db, 'games').withConverter(gameConverter);
  const q = query(collectionData, orderBy('createdOn', 'desc'));
  const querySnapshot = await getDocs(q);
  const games: GamesT = [];
  querySnapshot.docs.forEach((doc) => {
    const docData: GameT = doc.data();
    games.push({
      ...docData,
      gameId: doc.id,
      gameQuestions: [],
    });
  });

  return games;
};

export const fetchPublicGames = async (isAdmin = false): Promise<GamesT> => {
  const publicGameIds = await fetchPublicEntityIds(PublicEntity.Games);
  const gamesUnfiltered: GamesT = await fetchGames();
  let games: GamesT = [];
  if (isAdmin) {
    games = gamesUnfiltered.map((game) => {
      return {
        ...game,
        isPublic: publicGameIds.includes(toPublicGameId(game)),
      };
    });
  } else {
    games = gamesUnfiltered.filter((game) => publicGameIds.includes(toPublicGameId(game)));
  }
  // filter
  // games.sort(sortGames);
  return games;
};

export const getGame = async (gameId: GameId): Promise<GameT> => {
  const docRef = doc(db, `games/${gameId}`).withConverter(gameConverter);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const docData: GameT = docSnap.data();
    const gameQuestions = await fetchGameQuestions(gameId);
    gameQuestions.sort(gameLevelSort);
    return { ...docData, gameId, gameQuestions };
  } else {
    isPrint(PRINT_FLAG) && console.log(...printMsg('ERROR getGame Not Found', 'gameId', gameId));
    throw Error(`Game (${gameId}) unable to find`);
  }
};

export const createGame = async (game: GameT): Promise<GameT> => {
  const collectionData = collection(db, 'games').withConverter(gameConverter);
  const docRef = doc(collectionData);
  const createdGame: GameT = { ...game, gameId: docRef.id };
  // Create new record with autogenerated id as GameId
  await setDoc(docRef, createdGame);

  return createdGame as GameT;
};

export const patchGame = async (game: GameT): Promise<void> => {
  const gameRef = doc(db, `games/${game.gameId}`);
  const docGame = gameToDoc(game);
  return await updateDoc(gameRef, docGame);
};

export const fetchGameQuestions = async (gameId: string): Promise<GameQuestionsT> => {
  const collectionData = collection(db, `games/${gameId}/gameQuestions`).withConverter(gameQuestionConverter);
  const q = query(collectionData, orderBy('createdOn', 'desc'));
  const querySnapshot = await getDocs(q);
  const gameQuestions: GameQuestionsT = [];
  querySnapshot.docs.forEach((doc) => {
    const docData: GameQuestionT = doc.data();
    gameQuestions.push({
      ...docData,
      gameId: gameId,
    });
  });

  return gameQuestions;
};

export const createGameQuestion = async (gameId: string, gameQuestion: GameQuestionT): Promise<GameQuestionT> => {
  const collectionData = collection(db, `games/${gameId}/gameQuestions/`).withConverter(gameQuestionConverter);
  const docRef = doc(collectionData);

  const createdGameQuestion: GameQuestionT = { ...gameQuestion, gameQuestionId: docRef.id };
  // Create new record with autogenerated id as GameId
  await setDoc(docRef, createdGameQuestion);

  return createdGameQuestion as GameQuestionT;
};

export const patchGameQuestion = async (gameId: string, gameQuestion: GameQuestionT): Promise<void> => {
  const gameQuestionRef = doc(db, `games/${gameId}/gameQuestions/${gameQuestion.gameQuestionId}`);
  const docGameQuestion = gameQuestionToDoc(gameQuestion);
  return await updateDoc(gameQuestionRef, docGameQuestion);
};
// #endregion

// #region LOADERS/ACTIONS Games ********************************************************
export const appGamesCollectionLoader = async (): Promise<GamesT> => {
  store.dispatch(setGamesCollectionStatus('loading'));
  // const collection: GamesT = await fetchGames();
  const collection: GamesT = await fetchPublicGames();
  store.dispatch(setGamesCollection({ collection, status: 'loaded' }));
  return collection;
};

export type DeferReturnData = ReturnType<typeof defer>;
export const gamesCollectionLoader = async (): Promise<DeferReturnData> => {
  store.dispatch(setGamesCollectionStatus('loading'));
  store.dispatch(doSetActiveScene('games'));
  const isAdminUser = isUserAdmin(store.getState());
  const collection: Promise<GamesT> = fetchPublicGames(isAdminUser);
  isPrint(PRINT_FLAG) && console.log(...printMsg('LOADED GAMES', 'isAdminUser', String(isAdminUser)));

  return defer({ collection });
};

const awaitForGamesCollectionToLoad = async (): Promise<StatusT> => {
  const gamesStatus = store.getState().games.status;
  return await new Promise((resolve) => {
    if (gamesStatus === 'idle' || gamesStatus === 'loading') {
      const intervalId = setInterval((gamesStatus: StatusT) => {
        gamesStatus = store.getState().games.status as StatusT;
        if (gamesStatus === 'loaded' || gamesStatus === 'updated' || gamesStatus === 'created') {
          clearInterval(intervalId);
          resolve(gamesStatus);
        } else if (gamesStatus === 'error' || gamesStatus === 'idle') {
          clearInterval(intervalId);
          resolve(gamesStatus);
        }
      }, 100);
    } else {
      resolve(gamesStatus);
    }
  });
};

type PromiseGamesCollection = Promise<GamesT>;

const gamesCollectionLoadHelper = async (): Promise<GamesT> => {
  const status = store.getState().games.status;
  if (status === 'idle') {
    isPrint(PRINT_FLAG) && console.log(...printMsg('gamesCollectionLoadHelper', 'games', 'not loaded'));
    const gamesCollection = (await gamesCollectionLoader()) as DeferReturnData;
    const games: GamesT = await (gamesCollection.data.collection as PromiseGamesCollection);
    store.dispatch(setGamesCollection({ collection: games, status: 'loaded' }));
    return games;
  } else if (status === 'loaded' || status === 'updated' || status === 'created') {
    isPrint(PRINT_FLAG) && console.log(...printMsg('gamesCollectionLoadHelper', 'status', status));
    return getGamesCollection(store.getState());
  } else if (status === 'loading' || status === 'updating' || status === 'creating') {
    await awaitForGamesCollectionToLoad();
    return getGamesCollection(store.getState());
  } else {
    return [];
  }
};

const getGameByIdLoaderHelper = async (gameId: GameId): Promise<GameT> => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('getGameByIdLoaderHelper', 'gameId', gameId));
  const games: GamesT = await gamesCollectionLoadHelper();

  store.dispatch(setGamesCollectionStatus('updating'));
  store.dispatch(doSetActiveScene('games'));
  const game: GameT = await getGame(gameId);
  let index = -1;
  if (games) {
    index = games.findIndex((g) => g.gameId === game.gameId);
  }
  if (index === -1) {
    store.dispatch(addCollectionGame(game));
  } else {
    store.dispatch(updateCollectionGame({ game, index }));
  }
  store.dispatch(setGamesCollectionStatus('updated'));
  return game;
};

export const gameByIdLoader = async ({ params }: any): Promise<GameT> => {
  const gameId = params.gameId;
  isPrint(PRINT_FLAG) && console.log(...printMsg('gameByIdLoader', 'gameId', gameId));
  return await getGameByIdLoaderHelper(gameId);
};

export const gameQuestionByIdLoader = async ({ params }: any): Promise<GameWithQuestionResponse> => {
  const gameId = params.gameId;
  const gameQuestionId = params.gameQuestionId;
  isPrint(PRINT_FLAG) && console.log(...printMsg('GameQuestionByIdLoader Load', 'id', `${gameId}:${gameQuestionId}`));

  await awaitForGamesCollectionToLoad();

  const game = await getGameByIdLoaderHelper(gameId);
  let gameQuestion = getCollectionGameQuestion(store.getState(), gameId, gameQuestionId);

  if (!gameQuestion) {
    gameQuestion = game.gameQuestions.find((gq) => gq.gameQuestionId === gameQuestionId);
  }

  if (gameQuestion) return { game, gameQuestion };

  isPrint(PRINT_FLAG) && console.log(...printMsg('GameQuestionByIdLoader', 'NOT FOUND', 'ERROR'));
  throw new Response('Not Found', { status: 404 });
};
// #endregion

// #region MANAGE Games *****************************************************************

export const manageGameCollectionSceneLoader = async () => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('Loading ManageGameCollectionScene', '', ''));
  store.dispatch(doSetActiveScene('manage:games:collection'));
  store.dispatch(setLastActiveManageScene('games'));

  const publicGameIds = await fetchPublicEntityIds(PublicEntity.Games);
  store.dispatch(setPublicGameIds({ collection: publicGameIds, status: 'loaded' }));
  const games = await fetchGames();
  store.dispatch(setGames({ collection: games, status: 'loaded' }));

  return games;
};

export const manageCreateGameSceneLoader = async () => {
  store.dispatch(doSetActiveScene('manage:games:create'));
  store.dispatch(setLastActiveManageScene('games'));

  const game: GameT = getInitialGame();
  return { game };
};

export const manageCreateGameSceneAction = async () => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('POST ManageCreateGameSceneAction', 'Creating Game', ''));
  store.dispatch(setGamesStatus('creating'));
  const managerGames = store.getState().manager.games;
  const indexOfCreatedItem = managerGames.findIndex((q) => q.gameId === 'createGameId');
  const GameItem = managerGames[indexOfCreatedItem] as GameT;
  const toCreateGame: GameT = {
    ...GameItem,
    createdOn: Date.now(),
    modifiedOn: Date.now(),
  };

  const createdGame = await createGame(toCreateGame);
  store.dispatch(updateGame({ game: createdGame, index: indexOfCreatedItem }));
  store.dispatch(setGamesStatus('created'));
  store.dispatch(setToastNotification(getToastSuccessPayload('Game Created')));
  // update main game redux section to indicate it will have to update itself at some point
  // store.dispatch(setAllGamesStatus('created'));
  return redirect(AppRoutes.ManagerGames);
};

export const manageEditGameSceneLoader = async ({ params }: any): Promise<GameUpdateResponseT> => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('LOAD ManageEditGameSceneLoader', 'Edit Game', ''));
  store.dispatch(doSetActiveScene('manage:games:edit'));
  const gameId = params.gameId;

  const game: GameT = await getGame(gameId);
  const managerGames = store.getState().manager.games;
  const index = managerGames.findIndex((q) => q.gameId === game.gameId);
  store.dispatch(updateGame({ game: game, index: index }));

  return { game, index, gameQuestions: game.gameQuestions ?? [] };
};

export const manageEditGameSceneAction = async ({ params }: any) => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('POST ManageEditGameSceneAction', 'Edit Game', ''));
  const gameId = params.gameId;
  store.dispatch(setGamesStatus('updating'));
  const managerGames = store.getState().manager.games;
  const indexOfEditedItem = managerGames.findIndex((q) => q.gameId === gameId);
  const gameItem = managerGames[indexOfEditedItem] as GameT;
  const modified = getModifiedInfo();
  const gameItemToEdit: GameT = { ...gameItem, ...modified };

  await patchGame(gameItemToEdit);
  store.dispatch(setGamesStatus('updated'));
  store.dispatch(setToastNotification(getToastSuccessPayload('Game Updated')));
  // update main game redux section to indicate it will have to update itself at some point
  // store.dispatch(setAllGamesStatus('updated'));
  return redirect(AppRoutes.ManagerGames);
};

// #region GAME QUESTION
export const manageEditGameQuestionSceneLoader = async ({ params }: any): Promise<GameQuestionUpdateResponseT> => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('LOAD ManageEditGameQuestionSceneLoader', 'Edit Question', ''));
  store.dispatch(doSetActiveScene('manage:gamequestion:edit'));
  const gameId = params.gameId;
  const gameQuestionId = params.gameQuestionId;
  const managerGames = store.getState().manager.games;

  const gameIndex = managerGames.findIndex((q: GameT) => q.gameId === gameId);
  const game: GameT = managerGames[gameIndex];
  const gameQuestionIndex = game.gameQuestions.findIndex((gq) => gq.gameQuestionId === gameQuestionId);

  return { game, gameIndex, gameQuestionIndex, gameQuestion: game.gameQuestions[gameQuestionIndex] };
};

export const manageEditGameQuestionSceneAction = async ({ params }: any) => {
  const gameId = params.gameId;
  const gameQuestionId = params.gameQuestionId;

  store.dispatch(setGamesStatus(Status.Updating));
  const gqId = `${gameId}:${gameQuestionId}`;
  isPrint(PRINT_FLAG) && console.log(...printMsg('POST ManageEditGameQuestionSceneAction', 'id', gqId));
  const managerGames = store.getState().manager.games;
  const gameIndex = managerGames.findIndex((q) => q.gameId === gameId);
  const game = managerGames[gameIndex] as GameT;
  const gameQuestions = game.gameQuestions;
  const gameQuestionIndex = gameQuestions.findIndex((gq: GameQuestionT) => gq.gameQuestionId === gameQuestionId);
  const modified = getModifiedInfo();
  const gameQuestion: GameQuestionT = { ...gameQuestions[gameQuestionIndex], ...modified };

  await patchGameQuestion(gameId, gameQuestion);
  store.dispatch(updateGameQuestion({ game, gameIndex, gameQuestion, gameQuestionIndex }));
  store.dispatch(setGamesStatus(Status.Updated));
  store.dispatch(setToastNotification(getToastSuccessPayload('Game Updated')));
  // update main game redux section to indicate it will have to update itself at some point
  return redirect(AppRoutes.ManagerGamesEdit.replace(':gameId', gameId));
};

export const manageCreateGameQuestionSceneLoader = async ({ params }: any): Promise<GameQuestionUpdateResponseT> => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('LOAD ManageCreateGameQuestionSceneLoader', '', ''));
  store.dispatch(doSetActiveScene('manage:gamequestion:create'));
  const gameId = params.gameId;
  const gamesStatus = store.getState().manager.gamesStatus;
  if (gamesStatus !== 'loaded' && gamesStatus !== 'updated') {
    isPrint(PRINT_FLAG) && console.log(...printMsg('ERROR ManageCreateGameQuestionSceneLoader', 'GAMES', gamesStatus));
    throw Error(`games are not loaded, status ${gamesStatus}`);
  }
  const managerGames = store.getState().manager.games;
  const gameIndex = managerGames.findIndex((q) => q.gameId === gameId);
  let gameTemp: GameT = managerGames[gameIndex];

  const gameQuestion: GameQuestionT = getInitialGameQuestion(gameTemp);
  gameTemp = { ...gameTemp, gameQuestions: gameTemp.gameQuestions.concat(gameQuestion) };

  return { game: gameTemp, gameIndex, gameQuestionIndex: gameTemp.gameQuestions.length - 1, gameQuestion };
};

export const manageCreateGameQuestionSceneAction = async ({ params }: any) => {
  const gameId = params.gameId;
  store.dispatch(setGamesStatus('creating'));
  isPrint(PRINT_FLAG) && console.log(...printMsg('POST ManageCreateGameQuestionSceneAction', '', ''));
  const managerGames = store.getState().manager.games;
  const gameIndex = managerGames.findIndex((q) => q.gameId === gameId);
  const game = managerGames[gameIndex] as GameT;
  const gameQuestions = game.gameQuestions;
  const gameQuestionIndex = gameQuestions.findIndex(
    (gq: GameQuestionT) => gq.gameQuestionId.indexOf('createGameQuestionId') === 0
  );

  const createdGameQuestion = await createGameQuestion(gameId, gameQuestions[gameQuestionIndex]);
  store.dispatch(updateGameQuestion({ game, gameIndex, gameQuestionIndex, gameQuestion: createdGameQuestion }));

  store.dispatch(setGamesStatus('created'));
  store.dispatch(setToastNotification(getToastSuccessPayload('Game Question Created')));
  return redirect(AppRoutes.ManagerGamesEdit.replace(':gameId', gameId));
};
// #endregion

// #endregion
