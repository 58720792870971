/* eslint-disable @typescript-eslint/no-explicit-any */
import { store } from 'app/store';
import { redirect } from 'react-router-dom';
import { db } from '../firebase/Firebase';
import {
  query,
  collection,
  orderBy,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  setDoc,
} from 'firebase/firestore';
import { ManageUnlockUpdateResponseT } from 'types/App.types';
import { UnlockId, UnlockT, UnlocksT } from 'types/Unlocks.types';

import { useStyledLogger } from 'app/config/LogConfig';

import { getModifiedInfo } from 'helpers/careerHelpers';
import { getToastSuccessPayload } from 'helpers/uiHelpers';
import { isPrint } from 'helpers/appHelpers';

import { doSetActiveScene, setLastActiveManageScene } from 'scenes/scenesSlice';
import { setAppUnlocks, setUnlockAppStatus } from 'scenes/montessori/unlocksSlice';
import { setToastNotification } from 'modules/user/userUiSlice';
import { setUnlocks, setUnlockStatus, updateUnlock } from 'scenes/management/managerSlice';

import { manageAwardCollectionSceneLoader } from 'api/AwardsAPI';
import { manageCoursesSceneLoader } from 'api/CoursesAPI';
import { manageGameCollectionSceneLoader } from 'api/GameAPI';
import { manageLessonCollectionSceneLoader } from 'api/LessonsAPI';
import { manageQuizCollectionSceneLoader } from 'api/QuizzesAPI';
import { manageTutorialCollectionSceneLoader } from 'api/TutorialsAPI';
import { unlocksConverter, unlockToDoc, getInitialUnlock } from 'api/converters/UnlocksConverter';
import { AppRoutes } from 'app/constants/Routes';

const printMsg = useStyledLogger('UnlocksAPI', 'gainsboro');

// #region AWARD APIs *****************************************************************
export const fetchUnlocks = async (): Promise<UnlocksT> => {
  const collectionData = collection(db, 'unlocks').withConverter(unlocksConverter);
  const q = query(collectionData, orderBy('createdOn', 'asc'));
  const querySnapshot = await getDocs(q);
  const unlocks: UnlocksT = [];
  querySnapshot.docs.forEach((doc) => {
    const docData: UnlockT = doc.data();
    unlocks.push({
      ...docData,
      unlockId: doc.id,
    });
  });

  return unlocks;
};

export const getUnlock = async (unlockId: UnlockId): Promise<UnlockT> => {
  const docRef = doc(db, `unlocks/${unlockId}`).withConverter(unlocksConverter);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const docData: UnlockT = docSnap.data();
    return { ...docData, unlockId: unlockId };
  } else {
    console.error('No such document!');
    throw Error(`Unlock (${unlockId}) unable to find`);
  }
};

export const createUnlock = async (unlock: UnlockT): Promise<UnlockT> => {
  const collectionData = collection(db, 'unlocks').withConverter(unlocksConverter);
  const docRef = doc(collectionData);
  const createdUnlock: UnlockT = { ...unlock, unlockId: docRef.id };
  // Create new record with autogenerated id as AwardId
  await setDoc(docRef, createdUnlock);
  console.log('Document written with ID: ', docRef.id);

  return createdUnlock as UnlockT;
};

export const patchUnlock = async (unlock: UnlockT): Promise<void> => {
  const docRef = doc(db, `unlocks/${unlock.unlockId}`);
  const docAward = unlockToDoc(unlock);
  return await updateDoc(docRef, docAward);
};
// #endregion

// #region ROUTER LOADER & ACTION FUNCTIONS **********************************************
export const appUnlocksLoader = async (): Promise<UnlocksT> => {
  isPrint(true) && console.log(...printMsg('Loading', 'AppUnlocksLoader', ''));
  store.dispatch(setUnlockAppStatus('loading'));
  const unlocks: UnlocksT = await fetchUnlocks();
  store.dispatch(setAppUnlocks(unlocks));
  store.dispatch(setUnlockAppStatus('loaded'));
  return unlocks;
};

export const unlocksSceneLoader = async ({ params }: any): Promise<UnlockT> => {
  const { unlockId } = params;

  console.log(...printMsg('Loading Unlocks', 'unlockId', `${unlockId}`));
  const unlock: UnlockT = await getUnlock(unlockId);

  return unlock;
};

// #endregion

// #region MANAGE Awards **************************************************************
const loadDependencyManageCollections = async () => {
  if (store.getState().manager.awardsStatus !== 'loaded') {
    await manageAwardCollectionSceneLoader();
  }

  if (store.getState().manager.gamesStatus !== 'loaded') {
    await manageGameCollectionSceneLoader();
  }

  if (store.getState().manager.tutorialsStatus !== 'loaded') {
    await manageTutorialCollectionSceneLoader();
  }

  if (store.getState().manager.quizzesStatus !== 'loaded') {
    await manageQuizCollectionSceneLoader();
  }

  if (store.getState().manager.lessonsStatus !== 'loaded') {
    await manageLessonCollectionSceneLoader();
  }

  if (store.getState().manager.coursesStatus !== 'loaded') {
    await manageCoursesSceneLoader();
  }

  store.dispatch(doSetActiveScene('manage:unlocks:collection'));
  store.dispatch(setLastActiveManageScene('unlocks'));
  return true;
};

export const manageUnlocksCollectionSceneLoader = async () => {
  console.log(...printMsg('Load', 'ManageUnlocksCollectionSceneLoader', ''));
  await loadDependencyManageCollections();

  const unlocks: UnlocksT = await fetchUnlocks();
  store.dispatch(setUnlocks({ collection: unlocks, status: 'loaded' }));

  return unlocks;
};

export const manageCreateUnlockSceneLoader = async () => {
  store.dispatch(doSetActiveScene('manage:unlocks:create'));
  store.dispatch(setLastActiveManageScene('unlocks'));
  const unlock: UnlockT = getInitialUnlock();
  return { unlock };
};

export const manageCreateUnlockSceneAction = async () => {
  console.log(...printMsg('Creating', 'ManageCreateUnlockSceneAction', ''));
  store.dispatch(setUnlockStatus('creating'));
  const managerUnlocks = store.getState().manager.unlocks;
  const indexOfCreatedItem = managerUnlocks.findIndex((q) => q.unlockId === 'createUnlockId');
  const unlockItem = managerUnlocks[indexOfCreatedItem] as UnlockT;
  const toCreateUnlock: UnlockT = {
    ...unlockItem,
    createdOn: Date.now(),
    modifiedOn: Date.now(),
  };

  const createdUnlock = await createUnlock(toCreateUnlock);
  store.dispatch(updateUnlock({ unlock: createdUnlock, index: indexOfCreatedItem }));
  store.dispatch(setUnlockStatus('created'));
  store.dispatch(setToastNotification(getToastSuccessPayload('Unlock Created')));
  // reset app unlocks store, so it knows to update itself
  store.dispatch(setUnlockAppStatus('idle'));
  return redirect(AppRoutes.ManagerUnlocks);
};

export const manageEditUnlockSceneLoader = async ({ params }: any): Promise<ManageUnlockUpdateResponseT> => {
  console.log(...printMsg('Loading', 'ManageEditUnlocksSceneLoader', ''));
  store.dispatch(doSetActiveScene('manage:unlocks:edit'));
  const unlockId = params.unlockId;

  const unlock = await getUnlock(unlockId);
  const index = store.getState().manager.unlocks.findIndex((q) => q.unlockId === unlock.unlockId);
  return { unlock, index };
};

export const manageEditUnlocksSceneAction = async ({ params }: any) => {
  console.log(...printMsg('Editing', 'ManageEditUnlocksSceneAction', ''));
  const unlockId = params.unlockId;
  store.dispatch(setUnlockStatus('updating'));
  const managerUnlocks = store.getState().manager.unlocks;
  const indexOfEditedItem = managerUnlocks.findIndex((q) => q.unlockId === unlockId);
  const unlocksItem = managerUnlocks[indexOfEditedItem] as UnlockT;
  const modifiedInfo = getModifiedInfo();
  const unlockItemToEdit: UnlockT = {
    ...unlocksItem,
    ...modifiedInfo,
  };

  await patchUnlock(unlockItemToEdit);
  store.dispatch(setUnlockStatus('updated'));
  store.dispatch(setToastNotification(getToastSuccessPayload('Unlock Updated')));
  // reset app unlocks store, so it knows to update itself
  store.dispatch(setUnlockAppStatus('idle'));
  return redirect(AppRoutes.ManagerUnlocks);
};

// #endregion
