import { Arithmetic, ArithmeticT } from 'types/App.types';
import { CellT, CellsT, MathMode, MathModeT, Mode, ModeT } from 'types/Board.types';

export const RESULT_ANIMATION_DURATION = 1500;

const getCellValue = (row: number, column: number, totalColumns: number, arithmetic: ArithmeticT): number => {
  switch (arithmetic) {
    case Arithmetic.Subtract:
    case Arithmetic.Add:
      return row === 0 || column === 0 ? 0 : (row - 1) * (totalColumns - 1) + column;
    case Arithmetic.Multiply:
      return row * column;
    case Arithmetic.Divide:
      return row;
    default:
      return row === 0 || column === 0 ? Math.max(row, column) : row * column;
  }
};

const createInitialCellArray = (rows: number, columns: number, arithmetic: ArithmeticT): CellsT => {
  const numCells = rows * columns; // 10x10 grid + column headers + row headers (11x11)
  const initCellArray: CellsT = [];
  for (let i = 0; i < numCells; i++) {
    const column = i % columns;
    const row = Math.floor(i / columns);
    const cell: CellT = {
      row: row,
      column: column,
      value: getCellValue(row, column, columns, arithmetic),
      date: 0,
      isHeaderCell: row === 0 || column === 0,
    };
    initCellArray.push(cell);
  }

  return initCellArray;
};

export const createInitialCells = (
  mode: ModeT,
  mathMode: MathModeT = MathMode.Addition,
  rows = 11,
  columns = 11
): CellsT => {
  if (mode === Mode.Explore) {
    return createInitialCellArray(rows, columns, Arithmetic.Unknown);
  }

  if (mathMode === MathMode.Addition) {
    return createInitialCellArray(rows, columns, Arithmetic.Add);
  } else if (mathMode === MathMode.Multiplication) {
    return createInitialCellArray(rows, columns, Arithmetic.Multiply);
  } else if (mathMode === MathMode.Division) {
    return createInitialCellArray(rows, columns, Arithmetic.Divide);
  }

  return createInitialCellArray(rows, columns, Arithmetic.Add);
};
