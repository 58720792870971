import React from 'react';
import { AppIcons } from 'app/constants/Constants';
import { ComponentTitle } from 'app/constants/Localization';
import { useCareerChainCards } from 'app/hooks/useCareerChainCards';
import CareerProgress from 'components/progress/careerprogress/CareerProgress';

type TotalProgressProps = {
  className?: string;
};

const TotalProgress: React.FC<TotalProgressProps> = () => {
  const { activeChain } = useCareerChainCards();
  return <CareerProgress icon={AppIcons.Common.Progress} title={ComponentTitle.Progress} activeChain={activeChain} />;
};

export default TotalProgress;
