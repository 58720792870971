import React from 'react';
import { BoldWeightT, ElementSizeT, PropsWithChildren, ThicknessT } from 'types/App.types';

import Icon from 'components/icon/Icon';
import Rule from 'components/rule/Rule';
import InsetText from 'components/text/inset/InsetText';

import styles from './TitleHeader.module.scss';

type OwnProps = {
  id?: string;
  className?: string;
  titleSize?: ElementSizeT;
  ruleSize?: ThicknessT;
  icon?: string;
  isFilledIcon?: boolean;
  showHorizontalRule?: boolean;
  fillWidth?: boolean;
  weight?: BoldWeightT;
  showOverflow?: boolean;
  zeroMargin?: boolean;
};

type TitleHeaderProps = PropsWithChildren<OwnProps>;

const TitleHeader: React.FC<TitleHeaderProps> = ({
  titleSize = 'lge',
  ruleSize = 'med',
  className = '',
  fillWidth = false,
  showHorizontalRule = true,
  isFilledIcon = false,
  showOverflow = false,
  zeroMargin = false,
  ...props
}) => {
  const overflowCls = showOverflow ? styles.TitleHeaderRowOverflowVisible : styles.TitleHeaderRowOverflowHidden;
  return (
    <div className={`${styles.TitleHeaderDisplay} ${className}`}>
      <InsetText
        size={titleSize}
        weight={props.weight}
        className={`${styles.TitleHeaderRow} ${overflowCls} ${fillWidth ? 'FillWidth' : ''}`}>
        {props.icon && <Icon icon={props.icon} isFilled={isFilledIcon} />}
        {props.children}
      </InsetText>
      {showHorizontalRule && <Rule size={ruleSize} zeroMargin={zeroMargin} />}
    </div>
  );
};

export default TitleHeader;
