/* eslint-disable @typescript-eslint/no-explicit-any */
import { store } from 'app/store';
import { redirect } from 'react-router-dom';
import { db } from '../firebase/Firebase';
import { collection, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { StatusT, VisualState, VisualStateT } from 'types/App.types';
import { BadgeT, CareerId } from 'types/Career.types';
import { VersionId } from 'types/BaseCareer.types';
import { CourseId } from 'types/Course.types';
import { LessonT, LessonIds, LessonsT, LessonId, ManageLessonResponseT, LessonUI, LessonUIs } from 'types/Lesson.types';
import { TutorialId } from 'types/Tutorial.types';
import { QuizId } from 'types/Quiz.types';

import { fetchBadges } from 'api/AppAPI';
import { careerStatusUpdated } from './CareerAPI';
import { fetchQuizzes, getQuizUI } from 'api/QuizzesAPI';
import { getTutorialUI, getTutorials } from 'api/TutorialsAPI';

import { doSetActiveScene, setLastActiveManageScene } from 'scenes/scenesSlice';
import { setLessons, setLessonsStatus, updateLesson } from 'scenes/management/managerSlice';
import { getByIdCareerLessonUI } from 'modules/career/careerSlice';
import { setToastNotification } from 'modules/user/userUiSlice';
import { setActiveLessonFilters, setLessonFilterCategories } from 'scenes/management/managementUiSlice';

import { useStyledLogger } from 'app/config/LogConfig';
import {
  getLessonCompletionId,
  getQuizCompletionId,
  getQuizToUrl,
  getTutorialCompletionId,
} from 'helpers/routeHelpers';
import { sortLessons } from 'helpers/sortHelpers';

import { lessonConverter, lessonToDoc, getInitialLesson } from 'api/converters/LessonsConverter';
import { getToastSuccessPayload } from 'helpers/uiHelpers';
import { AppRoutes } from 'app/constants/Routes';
import { isPrint } from 'helpers/appHelpers';

const CLS_NAME = 'LessonsAPI';
const PRIMARY_COLOR = 'darkseagreen';
const SECONDARY_COLOR = 'cornsilk';
const PRINT_FLAG = true;

const printMsg = useStyledLogger(CLS_NAME, PRIMARY_COLOR, SECONDARY_COLOR);

// #region APIs ********************************************************************
export const getLessons = async (lessonList: LessonIds = []): Promise<LessonsT> => {
  const collectionData = collection(db, 'lessons').withConverter(lessonConverter);
  let q = query(collectionData);
  if (lessonList.length > 0) {
    q = query(collectionData, where('lessonId', 'in', [...lessonList]));
  }

  const querySnapshot = await getDocs(q);
  const lessons: LessonsT = [];
  querySnapshot.docs.forEach((doc) => {
    const docData = { ...doc.data(), lessonId: doc.id };
    lessons.push(docData);
  });

  lessons.sort(sortLessons);
  isPrint(PRINT_FLAG) && console.log(...printMsg('getLessons', 'LOADED # lessons', String(lessons.length)));
  return lessons;
};

export const convertToLessonUIs = (
  careerId: CareerId,
  courseId: CourseId,
  lessons: LessonsT,
  courseVS: VisualStateT
): LessonUIs => {
  const complete: Array<string> = store.getState().career.complete;
  const lessonUIs: LessonUIs = lessons.map((lessonT: LessonT, index: number, lessonsT: LessonsT) => {
    const lessonId: LessonId = lessonT.lessonId;
    const completionId = getLessonCompletionId(courseId, lessonId);
    // const toUrl = getLessonToUrl(careerId, courseId, lessonId);
    const quizId: QuizId = lessonT.versionedQuizId?.versionedItemId || (lessonT.quizId as QuizId);
    const toUrl = getQuizToUrl(careerId, courseId, lessonId, quizId);
    let visualState: VisualStateT = courseVS;
    if (complete.includes(completionId)) {
      visualState = VisualState.Completed;
    } else if (index > 0 && !complete.includes(getLessonCompletionId(courseId, lessonsT[index - 1].lessonId))) {
      visualState = 'locked';
    }
    return { ...lessonT, visualState, toUrl, completionId };
  });

  return lessonUIs;
};

export const getLessonUIs = async (
  careerId: CareerId,
  courseId: CourseId,
  lessonList: LessonIds = [],
  courseVS: VisualStateT
): Promise<LessonUIs> => {
  const lessons: LessonsT = await getLessons(lessonList);
  return convertToLessonUIs(careerId, courseId, lessons, courseVS);
};

export const getLesson = async (lessonId: LessonId): Promise<LessonT> => {
  const docRef = doc(db, `lessons/${lessonId}`).withConverter(lessonConverter);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const lessonT: LessonT = docSnap.data();
    return lessonT;
  } else {
    isPrint(PRINT_FLAG) && console.log(...printMsg('Loading Lesson ERROR - No such document!', 'lessonId', lessonId));
    throw Error(`Lesson (${lessonId}) unable to find`);
  }
};

export const getLessonUI = async (
  careerId: CareerId,
  courseId: CourseId,
  lessonId: LessonId,
  complete: Array<string>
): Promise<LessonUI> => {
  const { badges, badgesStatus } = store.getState().ui.badgesUi;
  const lessonT: LessonT = await getLesson(lessonId);
  let badgesUi = badges.slice();
  if (badgesStatus !== 'loaded' || badgesUi.length === 0) {
    badgesUi = await fetchBadges();
  }
  const tutorialId: TutorialId = lessonT.versionedTutorialId?.versionedItemId || (lessonT.tutorialId as TutorialId);
  const quizId: QuizId = lessonT.versionedQuizId?.versionedItemId || (lessonT.quizId as QuizId);
  const tutorialCompletionId = getTutorialCompletionId(courseId, lessonId, tutorialId);
  const isTutorialComplete = complete.includes(tutorialCompletionId);
  const tutorialVS: VisualStateT = isTutorialComplete ? VisualState.Completed : VisualState.Active;
  const tutorialUI = await getTutorialUI(careerId, courseId, lessonId, tutorialId, tutorialVS);

  const quizCompletionId = getQuizCompletionId(courseId, lessonId, quizId);
  const isQuizComplete = complete.includes(quizCompletionId);
  const quizVS: VisualStateT = isQuizComplete
    ? VisualState.Completed
    : tutorialVS === VisualState.Active
    ? 'locked'
    : VisualState.Active;
  const quizUI = await getQuizUI(careerId, courseId, lessonId, quizId, quizVS);

  const tutorialBadge = badgesUi.find((badge: BadgeT) => tutorialUI.badgeId === badge.badgeId);
  const quizBadge = badgesUi.find((badge: BadgeT) => quizUI.badgeId === badge.badgeId);
  const lessonBadge = badgesUi.find((badge: BadgeT) => lessonT.badge === badge.badgeId);

  // const toUrl = getLessonToUrl(careerId, courseId, lessonId);
  const toUrl = quizUI.toUrl;
  const completionId = getLessonCompletionId(courseId, lessonId);

  const itemsCompleted: boolean = tutorialVS === VisualState.Completed && quizVS === VisualState.Completed;
  const visualState: VisualStateT =
    complete.includes(completionId) || itemsCompleted ? VisualState.Completed : VisualState.Active;
  isPrint(PRINT_FLAG) && console.log(...printMsg('Loading LessonUI', 'lessonId', lessonId));
  isPrint(PRINT_FLAG) && console.log(...printMsg('Calculating LessonUI visual state', 'visualState', visualState));

  const lessonUI: LessonUI = {
    ...lessonT,
    tutorialUI,
    quizUI,
    lessonBadge,
    quizBadge,
    tutorialBadge,
    visualState,
    toUrl,
    completionId: tutorialCompletionId,
  };

  return lessonUI;
};

export const patchLesson = async (lessonData: LessonT): Promise<void> => {
  const lesson: LessonT = { ...lessonData, versionId: '' };
  lesson.versionId = await createLessonVersion(lesson);
  const docLesson = lessonToDoc(lesson);
  const lessonRef = doc(db, `lessons/${lesson.lessonId}`);

  return await updateDoc(lessonRef, docLesson);
};

export const createLesson = async (lessonData: LessonT): Promise<LessonT> => {
  const collectionData = collection(db, 'lessons').withConverter(lessonConverter);
  const docRef = doc(collectionData);
  const createdLesson: LessonT = { ...lessonData, lessonId: docRef.id };
  createdLesson.versionId = await createLessonVersion(createdLesson);
  // Create new record with autogenerated id as lessonId
  await setDoc(docRef, createdLesson);
  isPrint(PRINT_FLAG) && console.log(...printMsg('Created LessonT', 'lessonId', docRef.id));
  return createdLesson;
};

export const createLessonVersion = async (lesson: LessonT): Promise<VersionId> => {
  const collectionData = collection(db, `lessons/${lesson.lessonId}/versions`).withConverter(lessonConverter);
  const docRef = doc(collectionData);
  const newL = { ...lesson, versionId: docRef.id } as LessonT;
  await setDoc(docRef, newL);
  return docRef.id as VersionId;
};
// #endregion

// #region CAREER LOADERS AND ACTIONS ****************************************************
export const careerLessonSceneLoader = async ({ params }: any): Promise<LessonUI | null> => {
  const { courseId, lessonId } = params;
  isPrint(PRINT_FLAG) && console.log(...printMsg('Loading CareerLessonSceneLoader', 'lessonId', lessonId));
  const careerStatus: StatusT = await careerStatusUpdated();
  if (careerStatus === 'loaded') {
    const lessonUI: LessonUI = getByIdCareerLessonUI(store.getState(), String(courseId), String(lessonId));

    isPrint(PRINT_FLAG) && console.log(...printMsg('LOADED LessonScene', 'lessonId', lessonId));

    return lessonUI ? lessonUI : null;
  }
  isPrint(PRINT_FLAG) && console.log(...printMsg('Oops LessonAPI', 'careerStatus', careerStatus));
  throw redirect(AppRoutes.Hub);
};

export const setLessonFilters = (lessons: LessonsT) => {
  const availableFilters: Array<string> = lessons
    .map((q) => q.category || `${q.lessonId}`)
    .filter((item, index, list) => list.lastIndexOf(item) === index);
  store.dispatch(setLessonFilterCategories(availableFilters));
  if (store.getState().ui.managementUI.activeLessonFilters.length === 0) {
    store.dispatch(setActiveLessonFilters(availableFilters));
  }
};
// #endregion

// #region EDITOR LOADERS AND ACTIONS ****************************************************
export const manageLessonCollectionSceneLoader = async (): Promise<LessonsT> => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('Loading manageLessonCollectionSceneLoader', '', ''));
  store.dispatch(doSetActiveScene('manage:lesson:collection'));
  store.dispatch(setLastActiveManageScene('lesson'));

  const lessons = await getLessons([]);
  store.dispatch(setLessons({ collection: lessons, status: 'loaded' }));
  setLessonFilters(lessons);

  return lessons;
};

export const manageCreateLessonSceneLoader = async (): Promise<ManageLessonResponseT> => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('Loading manageCreateLessonSceneLoader', '', ''));
  store.dispatch(doSetActiveScene('manage:lesson:create'));
  store.dispatch(setLastActiveManageScene('lesson'));

  const tutorials = await getTutorials();
  const quizzes = await fetchQuizzes();
  const badges = await fetchBadges();
  const lesson = getInitialLesson();

  return { tutorials, quizzes, badges, lesson, index: store.getState().manager.lessons.length };
};

export const manageCreateLessonSceneAction = async () => {
  const managerLessons = store.getState().manager.lessons;
  const indexOfCreatedItem = managerLessons.findIndex((l) => l.lessonId === 'createLessonId');
  const lesson = managerLessons[indexOfCreatedItem] as LessonT;
  store.dispatch(setLessonsStatus('creating'));

  const createdLesson = await createLesson(lesson);
  store.dispatch(updateLesson({ lesson: createdLesson, index: indexOfCreatedItem }));
  store.dispatch(setLessonsStatus('created'));
  store.dispatch(setToastNotification(getToastSuccessPayload('Lesson Created')));
  return redirect(AppRoutes.ManagerLesson);
};

export const manageEditLessonSceneLoader = async ({ params }: any): Promise<ManageLessonResponseT> => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('Loading manageEditLessonSceneLoader', '', ''));
  const lessonId: LessonId = params.lessonId;
  store.dispatch(doSetActiveScene('manage:lesson:edit'));
  store.dispatch(setLastActiveManageScene('lesson'));
  const tutorials = await getTutorials();
  const quizzes = await fetchQuizzes();
  const badges = await fetchBadges();
  const lesson: LessonT = await getLesson(lessonId);
  const lessonIndex = store.getState().manager.lessons.findIndex((l) => l.lessonId === lessonId);

  return { tutorials, quizzes, badges, lesson, index: lessonIndex };
};

export const manageEditLessonSceneAction = async ({ params }: any) => {
  const lessonId: LessonId = params.lessonId;
  const managerLessons = store.getState().manager.lessons;
  const indexOfEditedItem = managerLessons.findIndex((l: LessonT) => l.lessonId === lessonId);
  const lesson = managerLessons[indexOfEditedItem] as LessonT;
  store.dispatch(setLessonsStatus('updating'));
  const lessonToUpdate: LessonT = {
    ...lesson,
    modifiedBy: store.getState().user.uid,
    modifiedOn: Date.now(),
  };

  await patchLesson(lessonToUpdate);
  store.dispatch(setToastNotification(getToastSuccessPayload('Lesson Updated')));
  store.dispatch(setLessonsStatus('updated'));
  return redirect(AppRoutes.ManagerLesson);
};
// #endregion
