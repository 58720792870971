import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'app/store';
import { getFormattedString, getNameClsString } from 'app/config/LogConfig';
import { PreferencesT, PreferencesResponseT, NewsVisualState } from 'types/User.types';

import { StatusT } from 'types/App.types';
import { createUserPreferences, updateUserPreferences } from 'api/PreferencesAPI';
import { isCurrentAppVersion, isPrint, queryAppVersionId } from 'helpers/appHelpers';
import { setCareerCarouselAutoplay, setCareerCarouselHidden, setCareerCarouselHideCompletedCourses } from 'modules/career/careerUiSlice';
// import { setNewsOpenState, setNewsReadState } from 'scenes/montessori/appUiSlice';

export const PRIMARY_COLOR = getNameClsString('powderblue');
export const CLS_NAME = 'UserPreferencesSlice';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const printMsg = (action: string, prop: string, status: string) =>
  getFormattedString(PRIMARY_COLOR, CLS_NAME, action, prop, status);

const PRINT_FLAG = true;

type UserPreferencesState = {
  status: StatusT;
} & PreferencesT;

const initialState: UserPreferencesState = {
  status: 'idle',
  autoplay: true,
  hideCarousel: false,
  hideCompletedCoursesOnCarousel: false,
  profileId: '',
  preferencesId: '',
  news: {
    date: 0,
    open: true,
    read: false,
    appVersionId: '0.0.0',
  },
  createdBy: '',
  createdOn: 0,
  modifiedBy: '',
  modifiedOn: 0,
};

export const doCreateUserPreferences =
  (preferences: PreferencesT): AppThunk =>
  async (dispatch) => {
    await createUserPreferences(preferences);
    dispatch(setUserPreferences(preferences));
    isPrint(PRINT_FLAG) && console.log(...printMsg('User preferences', 'status', 'CREATED'));
  };

export const doSetUserPreferencesState =
  (pref: PreferencesResponseT): AppThunk =>
  (dispatch) => {
    const { autoplay, news, hideCarousel, hideCompletedCoursesOnCarousel } = pref.preferences;
    dispatch(setUserPreferencesState(pref));
    dispatch(setCareerCarouselAutoplay(autoplay));
    dispatch(setCareerCarouselHidden(hideCarousel));
    dispatch(setCareerCarouselHideCompletedCourses(hideCompletedCoursesOnCarousel));

    const isRead = isCurrentAppVersion(news.appVersionId) && news.read;
    const date = isRead !== news.read ? Date.now() : news.date;
    dispatch(setUserPreferencesNews({ ...news, read: isRead, date: date }));

    isPrint(PRINT_FLAG) && console.log(...printMsg('User preferences', 'status', 'LOADED'));
  };

export const doUpdateUserPreferencesAutoplay =
  (flag: boolean): AppThunk =>
  async (dispatch, getState) => {
    const preferences: PreferencesT = getUserAppPreferences(getState());
    await updateUserPreferences({ ...preferences, autoplay: flag });
    dispatch(setUserPreferencesAutoplay(flag));
    isPrint(PRINT_FLAG) && console.log(...printMsg('Updated', 'AUTOPLAY', String(flag)));
  };

export const doUpdateUserPreferencesHideCarousel =
  (flag: boolean): AppThunk =>
  async (dispatch, getState) => {
    const preferences: PreferencesT = getUserAppPreferences(getState());
    await updateUserPreferences({ ...preferences, hideCarousel: flag });
    dispatch(setUserPreferencesHideCarousel(flag));
    isPrint(PRINT_FLAG) && console.log(...printMsg('Updated', 'HIDE_CAROUSEL', String(flag)));
  };

export const doUpdateUserPreferencesHideCompletedCoursesOnCarousel =
  (flag: boolean): AppThunk =>
  async (dispatch, getState) => {
    const preferences: PreferencesT = getUserAppPreferences(getState());
    await updateUserPreferences({ ...preferences, hideCompletedCoursesOnCarousel: flag });
    dispatch(setUserPreferenceHideCompletedCoursesOnCarousel(flag));
    isPrint(PRINT_FLAG) && console.log(...printMsg('Updated', 'HIDE_COMPLETED_COURSES_ON_CAROUSEL', String(flag)));
  };

export const doUpdateUserPreferencesNews =
  (isOpen: boolean): AppThunk =>
  async (dispatch, getState) => {
    const preferences: PreferencesT = getUserAppPreferences(getState());
    const news: NewsVisualState = {
      appVersionId: queryAppVersionId(),
      date: Date.now(),
      open: isOpen,
      read: true,
    };

    await updateUserPreferences({ ...preferences, news: { ...preferences.news, ...news } });
    dispatch(setUserPreferencesNews(news));
    isPrint(PRINT_FLAG) && console.log(...printMsg('Updated', 'NEWS', String(news.open)));
  };

export const userPreferencesSlice = createSlice({
  name: 'preferences',
  initialState,
  reducers: {
    setUserPreferencesState: (state, action: PayloadAction<PreferencesResponseT>) => {
      state.status = action.payload.status;
      state.preferencesId = action.payload.preferences.preferencesId;
      state.autoplay = action.payload.preferences.autoplay;
      state.hideCarousel = action.payload.preferences.hideCarousel;
      state.hideCompletedCoursesOnCarousel = action.payload.preferences.hideCompletedCoursesOnCarousel;
      state.profileId = action.payload.preferences.profileId;
      state.news = action.payload.preferences.news;
      state.createdBy = action.payload.preferences.createdBy;
      state.createdOn = action.payload.preferences.createdOn;
      state.modifiedBy = action.payload.preferences.modifiedBy;
      state.modifiedOn = action.payload.preferences.modifiedOn;
    },
    setUserPreferences: (state, action: PayloadAction<PreferencesT>) => {
      state.preferencesId = action.payload.preferencesId;
      state.autoplay = action.payload.autoplay;
      state.hideCarousel = action.payload.hideCarousel;
      state.hideCompletedCoursesOnCarousel = action.payload.hideCompletedCoursesOnCarousel;
      state.profileId = action.payload.profileId;
      state.news = action.payload.news;
      state.createdBy = action.payload.createdBy;
      state.createdOn = action.payload.createdOn;
      state.modifiedBy = action.payload.modifiedBy;
      state.modifiedOn = action.payload.modifiedOn;
    },
    setUserPreferencesStatus: (state, action: PayloadAction<StatusT>) => {
      state.status = action.payload;
    },
    setUserPreferencesAutoplay: (state, action: PayloadAction<boolean>) => {
      state.autoplay = action.payload;
    },
    setUserPreferencesHideCarousel: (state, action: PayloadAction<boolean>) => {
      state.hideCarousel = action.payload;
    },
    setUserPreferenceHideCompletedCoursesOnCarousel: (state, action: PayloadAction<boolean>) => {
      state.hideCompletedCoursesOnCarousel = action.payload;
    },
    setUserPreferencesNews: (state, action: PayloadAction<Partial<NewsVisualState>>) => {
      state.news = { ...state.news, ...action.payload };
    },
    resetUserPreferencesState: () => {
      return { ...initialState };
    },
  },
});

export const {
  setUserPreferencesState,
  setUserPreferences,
  setUserPreferencesStatus,
  setUserPreferencesAutoplay,
  setUserPreferencesNews,
  setUserPreferencesHideCarousel,
  setUserPreferenceHideCompletedCoursesOnCarousel,
  resetUserPreferencesState,
} = userPreferencesSlice.actions;

export const getUserAppPreferences = (state: RootState): PreferencesT => state.preferences;
export const getUserAppPreferencesStatus = (state: RootState): StatusT => state.preferences.status;
export const getUserAppPreferencesNews = (state: RootState): NewsVisualState => state.preferences.news;
export const getUserAppPreferencesAutoplay = (state: RootState): boolean => state.preferences.autoplay;
export const getUserAppPreferencesHideCarousel = (state: RootState): boolean => state.preferences.hideCarousel;
export const getUserAppPreferencesHideCompletedCoursesOnCarousel = (state: RootState): boolean =>
  state.preferences.hideCompletedCoursesOnCarousel;

export default userPreferencesSlice.reducer;
