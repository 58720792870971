import { convertArithmeticTypeToMathModeType } from 'helpers/typeCastingHelpers';
import { getFruitTypeByCategory } from 'helpers/uiHelpers';
import { Arithmetic, ArithmeticT, FruitT } from 'types/App.types';
import { VersionedItemsT } from 'types/BaseCareer.types';
import { BoardOptions, MathModeT, Mode, ModeT } from 'types/Board.types';
import { QuestionT, QuestionsT } from 'types/Question.types';

export const getDefaultBoardOptions = (category: ArithmeticT): BoardOptions => {
  const mode: ModeT = Mode.Quiz;
  const mathMode: MathModeT = convertArithmeticTypeToMathModeType(category);
  const fruit: FruitT = getFruitTypeByCategory(category);

  return {
    rows: 11,
    columns: 11,
    mathMode: mathMode,
    disabled: false,
    cellFruit: fruit,
    showCellValues: true,
    showSelectedCellValues: true,
    mode: mode,
    showRowHeaderValues: true,
    showColumnHeaderValues: true,
    showRowHeader: true,
    showColumnHeader: true,
    showResetButton: true,
    showHTracerLine: category === Arithmetic.Multiply,
    showVTracerLine: category === Arithmetic.Multiply || category === Arithmetic.Divide,
  };
};

export const questionUsesMontessoriBoard = (arithmeticKind: ArithmeticT): boolean =>
  arithmeticKind === Arithmetic.Add ||
  arithmeticKind === Arithmetic.Subtract ||
  arithmeticKind === Arithmetic.Multiply ||
  arithmeticKind === Arithmetic.Divide;

/**
 * Quizzes contain versioned questions, and if question has since been updated, that version will not be
 * returned from `fetchQuestions` API call. This function identifies questions in a quiz that are not
 * in the list of questions returned from the `fetchQuestions` API call.
 *
 * @param {QuestionsT} questions - questions returned from `fetchQuestions` API call
 * @param {VersionedItemsT} quizQuestions - questions stored in a `QuizT.versionedQuestionIds` property
 * @return {Array<string>} - list of `${questionId}:{versionId}` strings not included a `questions` list
 */
export const findMissingQuestionsFromQuiz = (questions: QuestionsT, quizQuestions: VersionedItemsT): Array<string> => {
  const allQsAsIdAndVersion: Array<string> = questions.map((q) => `${q.questionId}:${q.versionId}`);
  allQsAsIdAndVersion.sort();
  const quizQsAsIdAndVersion: Array<string> = quizQuestions.map((q) => `${q.versionedItemId}:${q.versionId}`);
  quizQsAsIdAndVersion.sort();

  return quizQsAsIdAndVersion.filter((q) => !allQsAsIdAndVersion.includes(q));
};

export const getVersionedString = (question: QuestionT): string => `${question.questionId}:${question.versionId}`;
