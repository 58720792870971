import React from 'react';
import { PropsWithChildren, InsetTextProps } from 'types/App.types';

import styles from './InsetText.module.scss';

const InsetText: React.FC<PropsWithChildren<InsetTextProps>> = ({
  size = 'lge',
  weight = '',
  className = '',
  color = '',
  capitalize = false,
  uppercase = false,
  nowrap = false,
  data = '',
  ...props
}) => {
  const capitalizeCls = capitalize ? 'Capitalize' : '';
  const uppercaseCls = uppercase ? 'Uppercase' : '';
  const wrapTextCls = nowrap ? 'SingleLine' : '';
  const textDecorCls = `${capitalizeCls} ${uppercaseCls} ${wrapTextCls}`;
  const styleCls = `${styles.InsetText} ${className} ${color} ${size} ${weight} ${textDecorCls}`;
  return (
    <h1
      id={props.id}
      onClick={props.onClick}
      data-state={data}
      style={props.style}
      className={styleCls}>
      {props.children || props.text}
    </h1>
  );
};

export default InsetText;
