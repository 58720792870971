import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FruitT, OperatorSignT } from 'types/App.types';

import RoundedButton from 'components/buttons/rounded/RoundedButton';
import FruitIcon from 'components/icon/FruitIcon';
import MathSign from 'components/mathsign/MathSign';

type MathSignPanelProps = {
  className?: string;
  fruit: FruitT;
  sign: OperatorSignT;
  to: string;
  insetStyle?: boolean;
};

const MathSignPanel: React.FC<MathSignPanelProps> = ({ insetStyle = true, ...props }) => {
  const [isActiveLink, setIsActiveLink] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    setIsActiveLink(`${location.pathname}${location.search}` === props.to);
  }, [location]);

  return (
    <RoundedButton to={props.to} disabled={isActiveLink}>
      <FruitIcon fruitType={props.fruit} size="sma" />
      <MathSign sign={props.sign} insetStyle={insetStyle} />
      <FruitIcon fruitType={props.fruit} size="sma" />
    </RoundedButton>
  );
};

export default MathSignPanel;
