import React, { useEffect, useRef, useState } from 'react';
import { TutorialHelpProps } from 'types/Tutorial.types';

import { SpotlightEls } from 'app/constants/SpotlightElements';
import { Description } from 'app/constants/Localization';
import { showSpotlightWithDelay } from 'helpers/helpFeaturesHelper';

import { MeTab } from 'components/tabs/MeTabs';

const STEP_1 = 1;
const STEP_2 = 2;
const STEP_3 = 3;
const STEP_4 = 4;

const CareerHubAwards: React.FC<TutorialHelpProps> = ({
  showHelpText = () => undefined,
  onFinishDemo = () => undefined,
  activeTab = '',
  ...props
}) => {
  const spotStateRef = useRef<'ON' | 'OFF'>(props.spotState);
  const [spotState, setSpotState] = useState<'ON' | 'OFF'>('OFF');

  useEffect(() => {
    spotStateRef.current = props.spotState;
    setSpotState(props.spotState);
  }, [props.spotState]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isActive, setActive] = useState<boolean>(false);

  const helpControlsDemo = (stepNumber: number) => {
    if (stepNumber === STEP_1) {
      onFinishDemo(false);
      setActive(true);
      props.spotOn(SpotlightEls.StarAwardsDisplay, 'circle');
      showHelpText(Description.HelpStarAwardsDisplay);
      showSpotlightWithDelay(6000, () => helpControlsDemo(STEP_2));
    } else if (stepNumber === STEP_2) {
      setActive(true);
      props.spotOn(SpotlightEls.BadgeProgress, 'rectangle');
      showHelpText(Description.HelpBadgeProgressBar);
      showSpotlightWithDelay(6000, () => helpControlsDemo(STEP_3));
    } else if (stepNumber === STEP_3) {
      setActive(true);
      props.spotOn(SpotlightEls.AwardsBtn, 'rectangle');
      showHelpText(Description.HelpAwardsSectionBtn);
      showSpotlightWithDelay(6000, () => helpControlsDemo(STEP_4));
    } else if (stepNumber === STEP_4) {
      setActive(false);
      showHelpText('');
      if (props.spotOff) props.spotOff();
      onFinishDemo(true);
    }
  };

  const showHelpControls = () => helpControlsDemo(STEP_1);

  return (
    <MeTab
      tabId="CareerHubAwards"
      icon="family_star"
      title="Collectables"
      onTabTapped={showHelpControls}
      tabState={spotState}
      activeTab={activeTab}
    />
  );
};

export default CareerHubAwards;
