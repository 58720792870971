import { MinMaxTuple } from 'types/App.types';

export const oneHourInMs = () => {
  const oneSec = 1000;
  const oneMin = 60 * oneSec;
  const oneHour = 60 * oneMin;

  return oneHour;
};

export const getTotalTime = (startTime: number, endTime: number): string => {
  if (startTime && endTime) {
    const seconds = (endTime - startTime) / 1000;
    const minutes = Math.floor(seconds / 60);
    const secs = Math.round(seconds % 60);
    return `${minutes > 0 ? minutes + 'm ' : ''}${secs > 9 ? '' : '0'}${secs}s`;
  }

  return '0';
};

/**
 * Returns time in HH:MM:SS.MSS format
 * @param {number} time time in milliseconds
 * @return {string} formatted time
 */
export const getFormattedTime = (time: number): string => {
  if (isNaN(time)) return '00:00:00.000';
  const ms = String(Math.round(time % 1000)).padStart(3, '0');
  const timeInSeconds = Math.floor(time / 1000);
  const sec = String(timeInSeconds % 60);
  const secPadded = String(timeInSeconds % 60).padStart(2, '0');
  const min = String(Math.floor(timeInSeconds / 60) % 60).padStart(2, '0');
  const hour = String(Math.floor(timeInSeconds / 60 / 60) % 24).padStart(2, '0');

  if (hour === '00' && min === '00') {
    return `${sec}.${ms} sec`;
  } else if (hour === '00') {
    return `${min}:${secPadded}.${ms} min`;
  }
  return `${hour}:${min}:${secPadded}.${ms} hrs`;
};

export const getFormattedDate = (dateMs: number): string => {
  const date = new Date(dateMs);
  return date.toLocaleDateString();
};

export const getFullFormattedDate = (dateMs: number): string => {
  const date = new Date(dateMs);
  return date.toLocaleString();
};

export const getFormattedStartEndDate = (minMax: MinMaxTuple): string => {
  const start = new Date(minMax.min).toLocaleDateString().split('/');
  const end = new Date(minMax.max).toLocaleDateString().split('/');
  const startDate = `${start[0]}/${start[1]}`;
  const endDate = `${end[0]}/${end[1]}`;

  return startDate === endDate ? start.join('/') : `${startDate} - ${endDate}`;
};

export const isNowEasterTime = (timestamp: number): boolean => {
  const currentDate = new Date(timestamp);
  const timeStart = new Date(`March 22 ${currentDate.getFullYear()}`);
  const timeEnd = new Date(`April 25 ${currentDate.getFullYear()}`);

  return timeStart.getTime() <= timestamp && timestamp <= timeEnd.getTime();
};

export const isNowXmasTime = (timestamp: number): boolean => {
  const currentDate = new Date(timestamp);
  const timeStart = new Date(`December 5 ${currentDate.getFullYear()}`);
  const timeEnd = new Date(`December 26 ${currentDate.getFullYear()}`);

  return timeStart.getTime() <= timestamp && timestamp <= timeEnd.getTime();
};

export const isNowHalloweenTime = (timestamp: number): boolean => {
  const currentDate = new Date(timestamp);
  const timeStart = new Date(`October 25 ${currentDate.getFullYear()}`);
  const timeEnd = new Date(`November 1 ${currentDate.getFullYear()}`);

  return timeStart.getTime() <= timestamp && timestamp <= timeEnd.getTime();
};

export const isHolidayTime = (timestamp: number): boolean => {
  return isNowEasterTime(timestamp) || isNowXmasTime(timestamp) || isNowHalloweenTime(timestamp);
};
