import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'app/store';
import { memoize } from 'proxy-memoize';

import { MeIcons } from 'app/constants/Constants';

import {
  AwardId,
  AwardsT,
  AwardT,
  GameQuestionUpdateResponseT,
  GameUpdateResponseT,
  ManageAwardUpdateResponseT,
  ManageGameUpdateResponseT,
  ManageMedalUpdateResponseT,
  ManageNewsArticleUpdateResponseT,
  ManageUnlockUpdateResponseT,
  MaybeType,
  MedalId,
  MedalsT,
  MedalT,
  PublicEntity,
  PublicEntityId,
  PublicEntityIds,
  Status,
  StatusT,
} from 'types/App.types';
import { CareerId, CareersT, CareerT, CareerUpdateResponseT } from 'types/Career.types';
import { CoursesT, CourseT, ManageCourseUpdateResponseT } from 'types/Course.types';
import { GameId, GamesT, GameT } from 'types/Game.types';
import { LessonsT, LessonT, LessonUpdateReduxT } from 'types/Lesson.types';
import { QuizzesT, QuizT, QuizId } from 'types/Quiz.types';
import { QuestionUpdateResponseT, QuestionT, QuestionsT, QuestionId } from 'types/Question.types';
import { TutorialsT, TutorialT, TutorialUpdateResponseT } from 'types/Tutorial.types';
import {
  IExpandedProfiles,
  IExpandedProfile,
  ManageProfileUpdateResponseT,
  ProfileId,
  IUsers,
  IUser,
} from 'types/User.types';
import { UnlockId, UnlocksT, UnlockT } from 'types/Unlocks.types';

import { ManagementNavBarProps } from 'components/navbars/management/ManagementNavBar';

import { toPublicCourseId, toPublicGameId, toPublicQuestionId } from 'helpers/careerHelpers';
import { updatePublicEntityIds } from 'api/PublicEntitiesAPI';
import { setToastNotification } from 'modules/user/userUiSlice';
import { getToastSuccessPayload } from 'helpers/uiHelpers';
import { ArticleId, NewsArticle, NewsArticles } from 'types/News.types';
import { updateCareerAwardMedalsCollection } from 'api/CareerAPI';

export type CareerUncompleteItemsT = {
  careerId: CareerId;
  itemsToUncomplete: Array<string>;
};

interface ManagerState {
  quizzesStatus: StatusT;
  questionsStatus: StatusT;
  publicQuestionIdsStatus: StatusT;
  publicCourseIdsStatus: StatusT;
  publicGameIdsStatus: StatusT;
  lessonsStatus: StatusT;
  tutorialsStatus: StatusT;
  careersStatus: StatusT;
  coursesStatus: StatusT;
  profilesStatus: StatusT;
  awardsStatus: StatusT;
  medalsStatus: StatusT;
  gamesStatus: StatusT;
  unlocksStatus: StatusT;
  userStatus: StatusT;
  newsStatus: StatusT;
  questions: QuestionsT;
  publicQuestionIds: PublicEntityIds;
  publicCourseIds: PublicEntityIds;
  publicGameIds: PublicEntityIds;
  quizzes: QuizzesT;
  lessons: LessonsT;
  tutorials: TutorialsT;
  careers: CareersT;
  courses: CoursesT;
  profiles: IExpandedProfiles;
  awards: AwardsT;
  medals: MedalsT;
  games: GamesT;
  unlocks: UnlocksT;
  users: IUsers;
  news: NewsArticles;
  managementMenu: ManagementNavBarProps;
  careerUncompleteItems: CareerUncompleteItemsT;
  newProfileCourses: CoursesT;
  newProfiles: IExpandedProfiles;
}

type ManagerPayload = {
  collection:
    | QuizzesT
    | LessonsT
    | QuestionsT
    | TutorialsT
    | CoursesT
    | CareersT
    | IExpandedProfiles
    | AwardsT
    | MedalsT
    | GamesT
    | PublicEntityIds
    | UnlocksT
    | IUsers
    | NewsArticles;
  status: StatusT;
};

type UpdateCareerMedalsPayload = {
  addMedals: MedalsT;
  removeMedals: MedalsT;
  careerId: CareerId;
};

const initialState: ManagerState = {
  newsStatus: Status.Idle,
  quizzesStatus: Status.Idle,
  questionsStatus: Status.Idle,
  publicQuestionIdsStatus: Status.Idle,
  publicCourseIdsStatus: Status.Idle,
  publicGameIdsStatus: Status.Idle,
  lessonsStatus: Status.Idle,
  tutorialsStatus: Status.Idle,
  coursesStatus: Status.Idle,
  careersStatus: Status.Idle,
  profilesStatus: Status.Idle,
  awardsStatus: Status.Idle,
  medalsStatus: Status.Idle,
  gamesStatus: Status.Idle,
  unlocksStatus: Status.Idle,
  userStatus: Status.Idle,
  questions: [],
  publicCourseIds: [],
  publicQuestionIds: [],
  publicGameIds: [],
  quizzes: [],
  lessons: [],
  tutorials: [],
  courses: [],
  careers: [],
  profiles: [],
  awards: [],
  medals: [],
  games: [],
  unlocks: [],
  users: [],
  news: [],
  managementMenu: {
    items: [
      { toUrl: '/manage/news', title: 'NEWS', icon: MeIcons.Newspaper, isIcon: true, navType: 'App' },
      { toUrl: '/manage/unlocks', title: 'UNLOCKS', icon: MeIcons.Unlocks, isIcon: true, navType: 'App' },
      { toUrl: '/manage/games', title: 'GAMES', icon: MeIcons.Game, isIcon: true, navType: 'App' },
      { toUrl: '/manage/awards', title: 'AWARDS', icon: MeIcons.Awards, isIcon: true, navType: 'App' },
      { toUrl: '/manage/medals', title: 'MEDALS', icon: MeIcons.Hexagon, isIcon: true, navType: 'App' },
      { toUrl: '/manage/user', title: 'USERS', icon: MeIcons.User, isIcon: true, navType: 'User' },
      { toUrl: '/manage/profile', title: 'PROFILES', icon: MeIcons.Profile, isIcon: true, navType: 'User' },
      { toUrl: '/manage/career', title: 'CAREER', icon: MeIcons.Career, isIcon: true, navType: 'School' },
      { toUrl: '/manage/course', title: 'COURSES', icon: MeIcons.Course, isIcon: true, navType: 'School' },
      { toUrl: '/manage/lesson', title: 'LESSONS', icon: MeIcons.Lesson, isIcon: true, navType: 'School' },
      { toUrl: '/manage/quiz', title: 'QUIZZES', icon: MeIcons.Quiz, isIcon: true, navType: 'School' },
      { toUrl: '/manage/tutorial', title: 'TUTORIALS', icon: MeIcons.Tutorial, isIcon: true, navType: 'School' },
      { toUrl: '/manage/question', title: 'QUESTIONS', icon: MeIcons.Questions, isIcon: true, navType: 'School' },
    ],
  },
  careerUncompleteItems: {
    careerId: '',
    itemsToUncomplete: [],
  },
  newProfileCourses: [],
  newProfiles: [],
};

export const doPublishQuestions = (): AppThunk => async (dispatch, getState) => {
  const publicQs: PublicEntityIds = getState().manager.questions.map((q) => toPublicQuestionId(q));
  await updatePublicEntityIds(PublicEntity.Questions, publicQs);
  dispatch(setPublicQuestionIds({ collection: publicQs, status: 'loaded' }));
  dispatch(setToastNotification(getToastSuccessPayload('Published all questions')));
};

export const doPublishCourses = (): AppThunk => async (dispatch, getState) => {
  const publicCs: PublicEntityIds = getState().manager.courses.map((c) => toPublicCourseId(c));
  await updatePublicEntityIds(PublicEntity.Courses, publicCs);
  dispatch(setPublicCourseIds({ collection: publicCs, status: 'loaded' }));
  dispatch(setToastNotification(getToastSuccessPayload('Published all courses')));
};

export const doPublishGames = (): AppThunk => async (dispatch, getState) => {
  const publicGs: PublicEntityIds = getState().manager.games.map((game) => toPublicGameId(game));
  await updatePublicEntityIds(PublicEntity.Games, publicGs);
  dispatch(setPublicGameIds({ collection: publicGs, status: 'loaded' }));
  dispatch(setToastNotification(getToastSuccessPayload('Published all games')));
};

export const doUpdateCareerAwardMedals =
  (payload: UpdateCareerMedalsPayload): AppThunk =>
  async (dispatch) => {
    await updateCareerAwardMedalsCollection(payload.careerId, payload.addMedals, payload.removeMedals);
    dispatch(updateCareerMedals(payload));
  };

export const managerSlice = createSlice({
  name: 'manager',
  initialState,
  reducers: {
    // #region SET
    setQuestions: (state, action: PayloadAction<ManagerPayload>) => {
      state.questions = action.payload.collection as QuestionsT;
      state.questionsStatus = action.payload.status;
    },
    setPublicQuestionIds: (state, action: PayloadAction<ManagerPayload>) => {
      state.publicQuestionIds = action.payload.collection as PublicEntityIds;
      state.publicQuestionIdsStatus = action.payload.status;
    },
    setPublicCourseIds: (state, action: PayloadAction<ManagerPayload>) => {
      state.publicCourseIds = action.payload.collection as PublicEntityIds;
      state.publicCourseIdsStatus = action.payload.status;
    },
    setPublicGameIds: (state, action: PayloadAction<ManagerPayload>) => {
      state.publicGameIds = action.payload.collection as PublicEntityIds;
      state.publicGameIdsStatus = action.payload.status;
    },
    setQuizzes: (state, action: PayloadAction<ManagerPayload>) => {
      state.quizzes = action.payload.collection as QuizzesT;
      state.quizzesStatus = action.payload.status;
    },
    setLessons: (state, action: PayloadAction<ManagerPayload>) => {
      state.lessons = action.payload.collection as LessonsT;
      state.lessonsStatus = action.payload.status;
    },
    setTutorials: (state, action: PayloadAction<ManagerPayload>) => {
      state.tutorials = action.payload.collection as TutorialsT;
      state.tutorialsStatus = action.payload.status;
    },
    setCourses: (state, action: PayloadAction<ManagerPayload>) => {
      state.courses = action.payload.collection as CoursesT;
      state.coursesStatus = action.payload.status;
    },
    setCareers: (state, action: PayloadAction<ManagerPayload>) => {
      state.careers = action.payload.collection as CareersT;
      state.careersStatus = action.payload.status;
    },
    setProfiles: (state, action: PayloadAction<ManagerPayload>) => {
      state.profiles = action.payload.collection as IExpandedProfiles;
      state.profilesStatus = action.payload.status;
    },
    setAwards: (state, action: PayloadAction<ManagerPayload>) => {
      state.awards = action.payload.collection as AwardsT;
      state.awardsStatus = action.payload.status;
    },
    setMedals: (state, action: PayloadAction<ManagerPayload>) => {
      state.medals = action.payload.collection as MedalsT;
      state.medalsStatus = action.payload.status;
    },
    setGames: (state, action: PayloadAction<ManagerPayload>) => {
      state.games = action.payload.collection as GamesT;
      state.gamesStatus = action.payload.status;
    },
    setUnlocks: (state, action: PayloadAction<ManagerPayload>) => {
      state.unlocks = action.payload.collection as UnlocksT;
      state.unlocksStatus = action.payload.status;
    },
    setUsers: (state, action: PayloadAction<ManagerPayload>) => {
      state.users = action.payload.collection as IUsers;
      state.userStatus = action.payload.status;
    },
    setGameQuestions: (state, action: PayloadAction<GameUpdateResponseT>) => {
      state.games[action.payload.index].gameQuestions = action.payload.gameQuestions;
    },
    setNewProfileCourses: (state, action: PayloadAction<CoursesT>) => {
      state.newProfileCourses = action.payload;
    },
    setNewProfiles: (state, action: PayloadAction<IExpandedProfiles>) => {
      state.newProfiles = action.payload;
    },
    setNews: (state, action: PayloadAction<ManagerPayload>) => {
      state.news = action.payload.collection as NewsArticles;
      state.newsStatus = action.payload.status;
    },
    // #endregion
    // #region ADD
    addQuestion: (state, action: PayloadAction<QuestionT>) => {
      const temp = state.questions.slice();
      temp.push(action.payload);
      state.questions = temp;
    },
    addQuiz: (state, action: PayloadAction<QuizT>) => {
      state.quizzes = state.quizzes.concat(action.payload);
    },
    addLesson: (state, action: PayloadAction<LessonT>) => {
      state.lessons = state.lessons.concat(action.payload);
    },
    addTutorial: (state, action: PayloadAction<TutorialT>) => {
      state.tutorials = state.tutorials.concat(action.payload);
    },
    addCourse: (state, action: PayloadAction<CourseT>) => {
      state.courses = state.courses.concat(action.payload);
    },
    addAward: (state, action: PayloadAction<AwardT>) => {
      let updatedCode = action.payload.code;
      const countByKind = state.awards.filter((award) => award.code.indexOf(updatedCode) !== -1).length;
      const totalCount = state.awards.length;
      updatedCode = `${updatedCode}-${String(countByKind + 1).padStart(4, '0')}-${String(totalCount).padStart(4, '0')}`;
      state.awards = [...state.awards, { ...action.payload, code: updatedCode }];
    },
    addMedal: (state, action: PayloadAction<MedalT>) => {
      let updatedCode = action.payload.code;
      const countByKind = state.medals.filter((medal) => medal.code.indexOf(updatedCode) !== -1).length;
      const totalCount = state.medals.length;
      updatedCode = `${updatedCode}-${String(countByKind + 1).padStart(4, '0')}-${String(totalCount).padStart(4, '0')}`;
      state.medals = [...state.medals, { ...action.payload, code: updatedCode }];
    },
    addGame: (state, action: PayloadAction<GameT>) => {
      state.games = state.games.concat(action.payload);
    },
    addUnlock: (state, action: PayloadAction<UnlockT>) => {
      state.unlocks = state.unlocks.concat(action.payload);
    },
    addGameQuestion: (state, action: PayloadAction<GameUpdateResponseT>) => {
      state.games[action.payload.index].gameQuestions = action.payload.gameQuestions;
    },
    addManagedProfile: (state, action: PayloadAction<IExpandedProfile>) => {
      state.profiles = state.profiles.concat(action.payload);
    },
    addNewsArticle: (state, action: PayloadAction<NewsArticle>) => {
      state.news = state.news.concat(action.payload);
    },
    // #endregion
    // #region REMOVE
    removePublicQuestionId: (state, action: PayloadAction<PublicEntityId>) => {
      const qIndex = state.publicQuestionIds.indexOf(action.payload);
      const temp = state.publicQuestionIds.slice();
      temp.splice(qIndex, 1);
      state.publicQuestionIds = temp;
    },
    removePublicCourseId: (state, action: PayloadAction<PublicEntityId>) => {
      const cIndex = state.publicCourseIds.indexOf(action.payload);
      const temp = state.publicCourseIds.slice();
      temp.splice(cIndex, 1);
      state.publicCourseIds = temp;
    },
    removePublicGameId: (state, action: PayloadAction<PublicEntityId>) => {
      const cIndex = state.publicGameIds.indexOf(action.payload);
      const temp = state.publicGameIds.slice();
      temp.splice(cIndex, 1);
      state.publicGameIds = temp;
    },
    removeNewsArticle: (state, action: PayloadAction<ArticleId>) => {
      const cIndex = state.news.findIndex((n) => n.articleId === action.payload);
      const temp = state.news.slice();
      temp.splice(cIndex, 1);
      state.news = temp;
    },
    // #endregion
    // #region UPDATE
    updateManagedUser: (state, action: PayloadAction<IUser>) => {
      const userIndex = state.users.findIndex((u) => u.uid === action.payload.uid);
      if (userIndex > -1) {
        const tempUsers = state.users.slice();
        tempUsers.splice(userIndex, 1, action.payload);
        state.users = tempUsers;
      }
    },
    updateCareer: (state, action: PayloadAction<CareerUpdateResponseT>) => {
      const careers = state.careers.slice();
      careers.splice(action.payload.index, 1, action.payload.career);
      state.careers = careers;
    },
    updateCareerMedals: (state, action: PayloadAction<UpdateCareerMedalsPayload>) => {
      const { careerId, addMedals } = action.payload;
      const careerIndex = state.careers.findIndex((c) => c.careerId === careerId);
      if (careerIndex > -1) {
        const career: CareerT = state.careers[careerIndex];
        const temp = state.careers.slice();
        temp.splice(careerIndex, 1, { ...career, awardMedals: addMedals });
        state.careers = temp;
      }
    },

    updateQuiz: (state, action: PayloadAction<QuizT>) => {
      const quizzesTemp = state.quizzes.slice();
      const indexOfQuiz = quizzesTemp.findIndex((q) => q.quizId === action.payload.quizId);
      quizzesTemp.splice(indexOfQuiz, 1, action.payload);
      state.quizzes = quizzesTemp;
    },
    updateQuestion: (state, action: PayloadAction<QuestionUpdateResponseT>) => {
      const temp = state.questions.slice();
      temp.splice(action.payload.index, 1, action.payload.question);
      state.questions = temp;
    },
    updatePublicQuestionIds: (state, action: PayloadAction<PublicEntityId | PublicEntityIds>) => {
      state.publicQuestionIds = state.publicQuestionIds.concat(action.payload);
    },
    updatePublicCourseIds: (state, action: PayloadAction<PublicEntityId | PublicEntityIds>) => {
      state.publicCourseIds = state.publicCourseIds.concat(action.payload);
    },
    updatePublicGamesIds: (state, action: PayloadAction<PublicEntityId | PublicEntityIds>) => {
      state.publicGameIds = state.publicGameIds.concat(action.payload);
    },
    updateLesson: (state, action: PayloadAction<LessonUpdateReduxT>) => {
      const temp = state.lessons.slice();
      // const indexOfQ = temp.findIndex((q) => q.id === action.payload.question.questionId);
      temp.splice(action.payload.index, 1, action.payload.lesson);
      state.lessons = temp;
    },
    updateTutorial: (state, action: PayloadAction<TutorialUpdateResponseT>) => {
      const temp = state.tutorials.slice();
      // const indexOfQ = temp.findIndex((q) => q.id === action.payload.question.questionId);
      temp.splice(action.payload.index, 1, action.payload.tutorial);
      state.tutorials = temp;
    },
    updateCourse: (state, action: PayloadAction<ManageCourseUpdateResponseT>) => {
      const temp = state.courses.slice();
      // const indexOfQ = temp.findIndex((q) => q.id === action.payload.question.questionId);
      temp.splice(action.payload.index, 1, action.payload.course);
      state.courses = temp;
    },
    updateProfile: (state, action: PayloadAction<ManageProfileUpdateResponseT>) => {
      const temp = state.profiles.slice();
      temp.splice(action.payload.index, 1, action.payload.userProfile);
      state.profiles = temp;
    },
    updateAward: (state, action: PayloadAction<ManageAwardUpdateResponseT>) => {
      const temp = state.awards.slice();
      temp.splice(action.payload.index, 1, action.payload.award);
      state.awards = temp;
    },
    updateMedal: (state, action: PayloadAction<ManageMedalUpdateResponseT>) => {
      const temp = state.medals.slice();
      temp.splice(action.payload.index, 1, action.payload.medal);
      state.medals = temp;
    },
    updateGame: (state, action: PayloadAction<ManageGameUpdateResponseT>) => {
      const temp = state.games.slice();
      temp.splice(action.payload.index, 1, action.payload.game);
      state.games = temp;
    },
    updateUnlock: (state, action: PayloadAction<ManageUnlockUpdateResponseT>) => {
      const temp = state.unlocks.slice();
      temp.splice(action.payload.index, 1, action.payload.unlock);
      state.unlocks = temp;
    },
    updateGameQuestion: (state, action: PayloadAction<GameQuestionUpdateResponseT>) => {
      const tempGameQuestions = state.games[action.payload.gameIndex].gameQuestions;
      tempGameQuestions.splice(action.payload.gameQuestionIndex, 1, action.payload.gameQuestion);
      state.games[action.payload.gameIndex].gameQuestions = tempGameQuestions;
    },
    updateNewsArticle: (state, action: PayloadAction<ManageNewsArticleUpdateResponseT>) => {
      const temp = state.news.slice();
      temp.splice(action.payload.index, 1, action.payload.newsArticle);
      state.news = temp;
    },
    // #endregion
    // #region STATUS
    setQuizzesStatus: (state, action: PayloadAction<StatusT>) => {
      state.quizzesStatus = action.payload;
    },
    setQuestionsStatus: (state, action: PayloadAction<StatusT>) => {
      state.questionsStatus = action.payload;
    },
    setPublicQuestionsStatus: (state, action: PayloadAction<StatusT>) => {
      state.publicQuestionIdsStatus = action.payload;
    },
    setPublicCoursesStatus: (state, action: PayloadAction<StatusT>) => {
      state.publicCourseIdsStatus = action.payload;
    },
    setPublicGamesStatus: (state, action: PayloadAction<StatusT>) => {
      state.publicGameIdsStatus = action.payload;
    },
    setLessonsStatus: (state, action: PayloadAction<StatusT>) => {
      state.lessonsStatus = action.payload;
    },
    setTutorialsStatus: (state, action: PayloadAction<StatusT>) => {
      state.tutorialsStatus = action.payload;
    },
    setCoursesStatus: (state, action: PayloadAction<StatusT>) => {
      state.coursesStatus = action.payload;
    },
    setManageCareersStatus: (state, action: PayloadAction<StatusT>) => {
      state.careersStatus = action.payload;
    },
    setCareerUncompleteItems: (state, action: PayloadAction<CareerUncompleteItemsT>) => {
      state.careerUncompleteItems = action.payload;
    },
    setProfileStatus: (state, action: PayloadAction<StatusT>) => {
      state.profilesStatus = action.payload;
    },
    setAwardsStatus: (state, action: PayloadAction<StatusT>) => {
      state.awardsStatus = action.payload;
    },
    setMedalsStatus: (state, action: PayloadAction<StatusT>) => {
      state.medalsStatus = action.payload;
    },
    setGamesStatus: (state, action: PayloadAction<StatusT>) => {
      state.gamesStatus = action.payload;
    },
    setUnlockStatus: (state, action: PayloadAction<StatusT>) => {
      state.unlocksStatus = action.payload;
    },
    setUserStatus: (state, action: PayloadAction<StatusT>) => {
      state.userStatus = action.payload;
    },
    setNewsStatus: (state, action: PayloadAction<StatusT>) => {
      state.newsStatus = action.payload;
    },
    // #endregion
    resetEditor: () => {
      return { ...initialState };
    },
  },
});

export const {
  // #region Setter functions
  setQuestions,
  setPublicQuestionIds,
  setPublicCourseIds,
  setPublicGameIds,
  setQuizzes,
  setLessons,
  setTutorials,
  setCourses,
  setCareers,
  setProfiles,
  setAwards,
  setMedals,
  setGames,
  setUnlocks,
  setGameQuestions,
  setNews,
  setQuizzesStatus,
  setQuestionsStatus,
  setPublicQuestionsStatus,
  setLessonsStatus,
  setTutorialsStatus,
  setCoursesStatus,
  setPublicCoursesStatus,
  setManageCareersStatus,
  setCareerUncompleteItems,
  setProfileStatus,
  setAwardsStatus,
  setMedalsStatus,
  setGamesStatus,
  setPublicGamesStatus,
  setUnlockStatus,
  setNewProfileCourses,
  setNewProfiles,
  setUsers,
  setUserStatus,
  setNewsStatus,
  // #endregion
  // #region Add functions
  addQuestion,
  addQuiz,
  addLesson,
  addTutorial,
  addCourse,
  addManagedProfile,
  addAward,
  addMedal,
  addGame,
  addUnlock,
  addGameQuestion,
  addNewsArticle,
  // #endregion
  // #region Update functions
  updateAward,
  updateCareer,
  updateCareerMedals,
  updateCourse,
  updateGame,
  updateGameQuestion,
  updateLesson,
  updateManagedUser,
  updateMedal,
  updateNewsArticle,
  updateProfile,
  updatePublicCourseIds,
  updatePublicGamesIds,
  updatePublicQuestionIds,
  updateQuestion,
  updateQuiz,
  updateTutorial,
  updateUnlock,
  // #endregion
  // #region Remove/Reset functions
  removePublicQuestionId,
  removePublicCourseId,
  removePublicGameId,
  removeNewsArticle,
  resetEditor,
  // #endregion
} = managerSlice.actions;
// #region
export const getQuizzes = (state: RootState): QuizzesT => state.manager.quizzes;
export const getQuizzesDropdown = memoize((state: RootState) =>
  state.manager.quizzes.map((t) => ({ name: t.quizTitle, value: t.quizId }))
);
export const getQuestions = (state: RootState): QuestionsT => state.manager.questions;
export const getPublicQuestionIds = (state: RootState): PublicEntityIds => state.manager.publicQuestionIds;
export const getPublicCourseIds = (state: RootState): PublicEntityIds => state.manager.publicCourseIds;
export const getPublicGameIds = (state: RootState): PublicEntityIds => state.manager.publicGameIds;
export const getTutorials = (state: RootState): TutorialsT => state.manager.tutorials;
export const getTutorialsDropdown = memoize((state: RootState) =>
  state.manager.tutorials.map((t) => ({ name: t.title, value: t.tutorialId }))
);
export const getLessons = (state: RootState): LessonsT => state.manager.lessons;
export const getLessonsDropdown = memoize((state: RootState) =>
  state.manager.lessons.map((t) => ({ name: t.title, value: t.lessonId }))
);

export const getCourses = (state: RootState): CoursesT => state.manager.courses;
export const getCoursesDropdown = memoize((state: RootState) =>
  state.manager.courses.map((t) => ({ name: t.courseTitle, value: t.courseId }))
);
export const getCareers = (state: RootState): CareersT => state.manager.careers;
export const getProfiles = (state: RootState): IExpandedProfiles => state.manager.profiles;
export const getMedals = (state: RootState): MedalsT => state.manager.medals;
export const getAwards = (state: RootState): AwardsT => state.manager.awards;
export const getAwardsDropdown = memoize((state: RootState) =>
  state.manager.awards.map((t) => ({ name: t.title, value: t.awardId }))
);
export const getGames = (state: RootState): GamesT => state.manager.games;
export const getGamesDropdown = memoize((state: RootState) =>
  state.manager.games.map((t) => ({ name: t.gameTitle, value: t.gameId }))
);
export const getUnlocks = (state: RootState): UnlocksT => state.manager.unlocks;
export const getManagedUsers = (state: RootState): IUsers => state.manager.users;
export const getCareerUncompleteItems = (state: RootState): CareerUncompleteItemsT =>
  state.manager.careerUncompleteItems;
export const getNewProfileCourses = (state: RootState): CoursesT => state.manager.newProfileCourses;
export const getNewProfiles = (state: RootState): IExpandedProfiles => state.manager.newProfiles;

export const getManagementMenu = (state: RootState): ManagementNavBarProps => state.manager.managementMenu;
// #endregion

// #region STATUS GETTERS ***************************************************************
export const getQuizzesStatus = (state: RootState): StatusT => state.manager.quizzesStatus;
export const getQuestionsStatus = (state: RootState): StatusT => state.manager.questionsStatus;
export const getPublicQuestionIdsStatus = (state: RootState): StatusT => state.manager.publicQuestionIdsStatus;
export const getPublicCourseIdsStatus = (state: RootState): StatusT => state.manager.publicCourseIdsStatus;
export const getPublicGameIdsStatus = (state: RootState): StatusT => state.manager.publicGameIdsStatus;
export const getLessonsStatus = (state: RootState): StatusT => state.manager.lessonsStatus;
export const getTutorialsStatus = (state: RootState): StatusT => state.manager.tutorialsStatus;
export const getCoursesStatus = (state: RootState): StatusT => state.manager.coursesStatus;
export const getCareersStatus = (state: RootState): StatusT => state.manager.careersStatus;
export const getProfilesStatus = (state: RootState): StatusT => state.manager.profilesStatus;
export const getAwardsStatus = (state: RootState): StatusT => state.manager.awardsStatus;
export const getMedalsStatus = (state: RootState): StatusT => state.manager.medalsStatus;
export const getGamesStatus = (state: RootState): StatusT => state.manager.gamesStatus;
export const getUnlocksStatus = (state: RootState): StatusT => state.manager.unlocksStatus;
export const getUsersStatus = (state: RootState): StatusT => state.manager.userStatus;
// #endregion

// #region GET BY IDs *******************************************************************
export const getProfileById = (state: RootState, profileId: ProfileId): MaybeType<IExpandedProfile> => {
  const profiles = getProfiles(state);
  return profiles.find((p) => p.profileId === profileId);
};
export const getProfileIndexById = (state: RootState, profileId: ProfileId): number => {
  const profiles = getProfiles(state);
  return profiles.findIndex((p) => p.profileId === profileId);
};
export const getQuestionById = (state: RootState, questionId: QuestionId): MaybeType<QuestionT> => {
  const questions = getQuestions(state);
  return questions.find((q) => q.questionId === questionId);
};
export const getQuizById = (state: RootState, quizId: QuizId): MaybeType<QuizT> => {
  const quizzes = getQuizzes(state);
  return quizzes.find((q) => q.quizId === quizId);
};
export const getAwardById = (state: RootState, awardId: AwardId): MaybeType<AwardT> => {
  const awards = getAwards(state);
  return awards.find((a) => a.awardId === awardId);
};
export const getMedalById = (state: RootState, medalId: MedalId): MaybeType<MedalT> => {
  const medals = getMedals(state);
  return medals.find((m) => m.medalId === medalId);
};
export const getGameById = (state: RootState, gameId: GameId): MaybeType<GameT> => {
  const games = getGames(state);
  return games.find((g) => g.gameId === gameId);
};
export const getUnlockById = (state: RootState, unlockId: UnlockId): MaybeType<UnlockT> => {
  const unlocks = getUnlocks(state);
  return unlocks.find((g) => g.unlockId === unlockId);
};
export const getManagedUserById = (state: RootState, userId: string): MaybeType<IUser> => {
  const users = getManagedUsers(state);
  return users.find((user) => user.uid === userId);
};
// #endregion

export default managerSlice.reducer;
