import { store } from 'app/store';
import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { getModifiedInfo } from 'helpers/careerHelpers';
import { CaptureInfoT, MessageT } from 'types/App.types';

// #region CONVERTERS *******************************************************************
export const getInitialMessage = (capture: CaptureInfoT): MessageT => {
  const userId = store.getState().user.uid || '';
  const initMessage: MessageT = {
    messageId: 'createMessageId',
    messageTitle: '',
    messageBody: '',
    ...capture,
    createdBy: userId,
    createdOn: Date.now(),
    modifiedBy: userId,
    modifiedOn: Date.now(),
  };

  return initMessage;
};

export const messageToDoc = (message: MessageT): DocumentData => {
  return {
    messageId: message.messageId,
    messageTitle: message.messageTitle,
    messageBody: message.messageBody,
    status: message.status,
    time: message.time,
    location: message.location,
    activeScene: message.activeScene,
    email: message.email,
    name: message.name,
    uid: message.uid,
    activeProfile: message.activeProfile,
    profilesCount: message.profilesCount,
    admin: message.admin,
    editor: message.editor,
    createdBy: message.createdBy,
    createdOn: message.createdOn,
    modifiedBy: message.modifiedBy,
    modifiedOn: message.modifiedOn,
  };
};

export const messageConverter: FirestoreDataConverter<MessageT> = {
  toFirestore(award: MessageT): DocumentData {
    return { ...messageToDoc(award), ...getModifiedInfo() };
  },

  fromFirestore(docSnap: QueryDocumentSnapshot, options?: SnapshotOptions): MessageT {
    const message = docSnap.data(options);

    return {
      messageId: message.messageId,
      messageTitle: message.messageTitle,
      messageBody: message.messageBody,
      status: message.status,
      time: message.time,
      location: message.location,
      activeScene: message.activeScene,
      email: message.email,
      name: message.name,
      uid: message.uid,
      activeProfile: message.activeProfile,
      profilesCount: message.profilesCount,
      admin: message.admin,
      editor: message.editor,
      createdBy: message.createdBy,
      createdOn: message.createdOn,
      modifiedBy: message.modifiedBy,
      modifiedOn: message.modifiedOn,
    };
  },
};
// #endregion
