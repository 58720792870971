import React from 'react';

import styles from './Logo.module.scss';

type LogoSizeT = 'LogoSize16' | 'LogoSize24' | 'LogoSize32' | 'LogoSize48';

type LogoProps = {
  className?: string;
  size?: LogoSizeT;
  hexShape?: boolean
};

const Logo: React.FC<LogoProps> = ({ size = 'LogoSize48', hexShape = false, ...props }) => {
  const logoSizeCls = styles[size];
  const hexCls = hexShape ? styles.ClippedMask : '';
  return (
    <span className={`${hexCls} ${styles.Logo} ${logoSizeCls} ${props.className ?? ''}`}>
      <img src="/images/logo-172x172.webp" alt="logo" />
    </span>
  );
};

export default Logo;
