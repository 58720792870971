import { store } from 'app/store';
import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { getModifiedInfo } from 'helpers/careerHelpers';
import { NewsArticle } from 'types/News.types';

// #region CONVERTERS *******************************************************************
export const getInitialNewsArticle = (article?: NewsArticle): NewsArticle => {
  const userId = store.getState().user.uid || '';
  const initArticle: NewsArticle = {
    ...article,
    articleId: 'createArticleId',
    articleTitle: article?.articleTitle ?? 'Article title',
    articleItems: article?.articleItems ?? [],
    articleTitleAppendAppVersion: !!article?.articleTitleAppendAppVersion,
    articleTitleIcon: article?.articleTitleIcon ?? '',
    createdBy: article?.createdBy ?? userId,
    createdOn: article?.createdOn ?? Date.now(),
    modifiedBy: userId,
    modifiedOn: Date.now(),
  };

  return initArticle;
};

export const articleToDoc = (article: NewsArticle): DocumentData => {
  return {
    articleId: article.articleId,
    articleTitle: article.articleTitle,
    articleTitleIcon: article.articleTitleIcon,
    articleTitleAppendAppVersion: article.articleTitleAppendAppVersion,
    articleItems: article.articleItems,
    createdBy: article.createdBy,
    createdOn: article.createdOn,
    modifiedBy: article.modifiedBy,
    modifiedOn: article.modifiedOn,
  };
};

export const articleConverter: FirestoreDataConverter<NewsArticle> = {
  toFirestore(article: NewsArticle): DocumentData {
    return { ...articleToDoc(article), ...getModifiedInfo() };
  },

  fromFirestore(docSnap: QueryDocumentSnapshot, options?: SnapshotOptions): NewsArticle {
    const article = docSnap.data(options);

    return {
      articleId: article.articleId,
      articleTitle: article.articleTitle,
      articleItems: article.articleItems,
      articleTitleIcon: article.articleTitleIcon,
      articleTitleAppendAppVersion: article.articleTitleAppendAppVersion,
      createdBy: article.createdBy,
      createdOn: article.createdOn,
      modifiedBy: article.modifiedBy,
      modifiedOn: article.modifiedOn,
    };
  },
};
// #endregion
