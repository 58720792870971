export type LogStyle = {
  color?: string;
  padding?: string;
  'background-color'?: string;
  border?: string;
  'border-radius'?: string;
  'font-weight'?: string;
  'font-size'?: string;
  'letter-spacing'?: string;
  'font-family'?: string;
};

export const StyleDefaults: LogStyle = {
  'color': 'black',
  'padding': '4px 4px 2px',
  'border': 'thin solid black',
  'border-radius': '4px',
  'font-weight': '500',
  'font-size': '12px',
  'background-color': 'white',
  'letter-spacing': '1px',
};

export const getStyleString = (overrides?: LogStyle): string => {
  const styles = { ...StyleDefaults, ...overrides };
  let styleString = '';
  Object.keys(styles).forEach((styleName) => {
    styleString += `${styleName}: ${styles[styleName as keyof LogStyle]}; `;
  });

  return styleString;
};

export const BLANK = getStyleString({ border: 'none' });
export const LOG_PROP = getStyleString({
  'color': 'royalblue',
  'border': 'thin solid royalblue',
  'font-weight': '300',
  'font-size': '10px',
});
export const LOG_VALUE = getStyleString({
  'color': 'crimson',
  'background-color': 'lavenderblush',
  'font-weight': '600',
  'font-size': '12px',
  'letter-spacing': '1px',
});

export const LOG_INFO = getStyleString({ 'color': 'coral', 'background-color': 'darkseagreen', 'font-weight': 'bold' });
export const LOG_ACTION = getStyleString({
  'color': 'orangered',
  'font-weight': '600',
  'font-family': 'monospace',
  'font-size': '13px',
});
export const LOG_ACTION_REVERSE = getStyleString({ 'background-color': 'darkorange', 'font-weight': '600' });
export const LOG_ACTION_LOAD = getStyleString({
  'background-color': 'crimson',
  'font-weight': '600',
  'color': 'blanchedalmond',
  'border': 'thin solid black',
  'border-radius': '16px',
  'padding': '4px',
});
export const LOG_ACTION_FETCH = getStyleString({
  'background-color': 'darkorange',
  'font-weight': '600',
  'color': 'blanchedalmond',
  'border': 'thin solid black',
  'border-radius': '16px',
  'padding': '4px',
});
export const LOG_ACTION_USER = getStyleString({
  'background-color': 'maroon',
  'font-weight': '600',
  'color': 'blanchedalmond',
  'border': 'thin solid black',
  'border-radius': '16px',
  'padding': '4px',
});
export const LOG_SUCCESS = getStyleString({ color: '#08A734' }); // green
export const LOG_FAILURE = getStyleString({ color: '#FF5733' }); // red-ish

export const getNameClsString = (bgColor: string, textColor = String(StyleDefaults.color)): string => {
  return getStyleString({
    'font-size': '10px',
    'font-weight': 'bold',
    'background-color': bgColor,
    'color': textColor,
  });
};

type ActionCodeT = {
  isSpecial: boolean;
  code: string;
  colorStyle: string;
};
const getSpecialActionCode = (action: string): ActionCodeT => {
  let foundIndex = action.indexOf('onAuthStateChanged');
  if (foundIndex > -1) {
    return {
      isSpecial: true,
      code: 'U',
      colorStyle: LOG_ACTION_USER,
    };
  }
  foundIndex = action.indexOf('Fetcher');
  if (foundIndex > -1) {
    return {
      isSpecial: true,
      code: 'F',
      colorStyle: LOG_ACTION_FETCH,
    };
  }

  foundIndex = action.indexOf('Load');
  foundIndex = foundIndex < 0 ? action.indexOf('LOAD') : foundIndex;
  if (foundIndex > -1) {
    return {
      isSpecial: true,
      code: 'L',
      colorStyle: LOG_ACTION_LOAD,
    };
  }

  return {
    isSpecial: false,
    code: '',
    colorStyle: '',
  };
};

export const getFormattedString = (
  componentNameStyle: string,
  title: string,
  action: string,
  prop?: string,
  value?: string
) => {
  const specialAction = getSpecialActionCode(action);
  if (specialAction.isSpecial) {
    return [
      '%c%s%c%c%s%c %c%s%c %c%s%c%s',
      componentNameStyle,
      title,
      BLANK,
      specialAction.colorStyle,
      specialAction.code,
      BLANK,
      LOG_ACTION,
      action,
      BLANK,
      LOG_PROP,
      prop,
      LOG_VALUE,
      value && value.indexOf(':') === -1 && value.indexOf(',') === -1 ? value.slice(0, 10) : value,
    ];
  }
  return [
    '%c%s%c %c%s%c %c%s%c%s',
    componentNameStyle,
    title,
    BLANK,
    LOG_ACTION,
    action,
    BLANK,
    LOG_PROP,
    prop,
    LOG_VALUE,
    value,
  ];
};

export const useStyledLogger = (cmpTitle: string, bgColor: string, textColor = 'black') => {
  const nameCls = getNameClsString(bgColor, textColor);
  const title = cmpTitle;

  return (action: string, prop: string, status: string) => getFormattedString(nameCls, title, action, prop, status);
};
