import React, { useRef, useState } from 'react';

import IconButton from 'components/buttons/icon/IconButton';
import Popup from 'components/popup/Popup';
import Capture from 'scenes/error/components/capture/Capture';

import styles from './Capture.module.scss';

const CaptureDebug: React.FC = () => {
  const captureRef = useRef<HTMLDivElement>(null);
  const [showCapture, setShowCapture] = useState<boolean>(false);

  const showCaptureInfo = () => setShowCapture(!showCapture);
  const onCapturePopupClose = () => setShowCapture(!showCapture);

  return (
    <>
      <IconButton icon="capture" onClick={showCaptureInfo} />
      <Popup
        id="DebugCapturePopupId"
        headerTitle="INFO"
        ref={captureRef}
        show={showCapture}
        popupType="wood"
        className={styles.CapturePopup}
        bodyClassName={styles.CapturePopupBodyPanel}
        onCloseEvent={onCapturePopupClose}>
        <Capture />
      </Popup>
    </>
  );
};
export default CaptureDebug;
