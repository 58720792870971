import React from 'react';
import { ElementSizeT } from 'types/App.types';

import InsetText from 'components/text/inset/InsetText';
import Icon from 'components/icon/Icon';

import styles from './Level.module.scss';

type LevelProps = {
  level: number;
  className?: string;
  size?: ElementSizeT;
};

const Level: React.FC<LevelProps> = ({ level, size = 'xs5', className = '' }) => {
  const levelCls = `Level_${level}`;

  return (
    <div className={`${styles.LevelLayout} ${className}`}>
      <div className={`${styles.LevelStars} ${levelCls}`}>
        <InsetText size={size}>
          <Icon icon="grade" />
          <Icon icon="grade" />
          <Icon icon="grade" />
          <Icon icon="grade" />
          <Icon icon="grade" />
        </InsetText>
      </div>
    </div>
  );
};

export default Level;
