import React from 'react';
import { VoidFunc } from 'types/App.types';
import { AppIcons } from 'app/constants/Constants';
import Digit from 'components/passkey/components/digit/Digit';

import styles from './DigitKeypad.module.scss';

type DigitKeypadProps = {
  className?: string;
  onDigitKey?: (num: number) => void;
  onActionKey?: VoidFunc;
  onDeleteKey?: VoidFunc;
  isDisabled?: boolean;
};

const KEYS: Array<number> = Array.from(Array(10).keys());

/**
 * Renders a 3x4 keypad panel with 0-9 digits, Delete, and Enter keys.
 *
 * @param {DigitKeypadProps} props
 * ```
 * type DigitKeypadProps = {
 *   className?: string;
 *   onDigitKeyTapped?: (num: number) => void;
 *   onActionKeyTapped?: VoidFunc;
 *   onDeleteKeyTapped?: VoidFunc;
 * };
 * ```
 *
 * @return {JSX.Element}
 */
const DigitKeypad: React.FC<DigitKeypadProps> = (props: DigitKeypadProps): JSX.Element => {
  const onDelete = () => {
    if (props.onDeleteKey) props.onDeleteKey();
  };

  const onAction = () => {
    if (props.onActionKey) props.onActionKey();
  };

  const onKeypadTapped = (num: number) => {
    if (props.onDigitKey) props.onDigitKey(num);
  };

  const onEnableDisableHandler = (node: HTMLDivElement | null) => {
    node && (props.isDisabled ? node.setAttribute('inert', '') : node.removeAttribute('inert'));
  };

  return (
    <div className={`${styles.DigitKeypad} ${props.className ?? ''}`} ref={onEnableDisableHandler}>
      {KEYS.map((n) => {
        const order = n === 0 ? 10 : n;
        return <Digit key={`key${n}`} order={order} icon={`counter_${n}`} num={n} onDigitTapped={onKeypadTapped} />;
      })}
      <Digit icon={AppIcons.Common.Backspace} order={8} num={-1} onDigitTapped={onDelete} />
      <Digit icon={AppIcons.Common.Login} order={12} num={-1} onDigitTapped={onAction} mainColor />
    </div>
  );
};

export default DigitKeypad;
