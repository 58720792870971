import React, { CSSProperties } from 'react';
import { ElementSizeT, OPERATOR_SIGN, OperatorSignT } from 'types/App.types';

import InsetText from 'components/text/inset/InsetText';
import MathSign from 'components/mathsign/MathSign';

import styles from './Equation.module.scss';

type EquationProps = {
  className?: string;
  term1: number | string;
  term2: number | string;
  result: number | string;
  operator: OperatorSignT;
  size?: ElementSizeT;
  operatorFontSize?: number;
  animateOperator?: boolean;
  animateEquals?: boolean;
  showEqualSign?: boolean;
  showResult?: boolean;
  highlightLeftSide?: boolean;
  highlightRightSide?: boolean;
  useWideGap?: boolean;
  showRightSide?: boolean;
};

const Equation: React.FC<EquationProps> = ({
  showEqualSign = true,
  showResult = true,
  size = 'lge',
  operatorFontSize = '2.5',
  showRightSide = true,
  ...props
}) => {
  const opStyle = { fontSize: `${operatorFontSize}em` };
  const eqStyle = { fontSize: `${operatorFontSize}em`, visibility: `${showEqualSign ? 'visible' : 'hidden'}` };
  const resultStyle = { lineHeight: 'initial', visibility: `${showResult ? 'visible' : 'hidden'}` };

  const pulsateOpSignCls = props.animateOperator ? styles.EquationPulsate : '';
  const pulsateEqSignCls = props.animateEquals ? styles.EquationPulsate : '';
  const leftSideHighlightCls = props.highlightLeftSide ? styles.HighlightOn : styles.HighlightOff;
  const rightSideHighlightCls = props.highlightRightSide ? styles.HighlightOn : styles.HighlightOff;
  const gapValue = props.useWideGap ? 1.5 : 0.5;

  const leftSideCls = `${styles.EquationSideLeft} ${styles.Highlight} ${leftSideHighlightCls}`;
  const rightSideCls = `${styles.EquationSideRight} ${styles.Highlight} ${rightSideHighlightCls}`;

  const leftHighLight = props.highlightLeftSide ? styles.LeftHighlight : '';
  const rightHighLight = props.highlightRightSide ? styles.RightHighlight : '';
  const hlCls = `${leftHighLight} ${rightHighLight}`;

  return (
    <div className={`${styles.Equation} ${hlCls}`} style={{ gap: `${props.useWideGap ? gapValue : 0}em` }}>
      <div className={leftSideCls} style={{ gap: `${gapValue}em` }}>
        <InsetText style={{ lineHeight: 'initial' }} weight="bold900" size={size}>
          {props.term1}
        </InsetText>
        <MathSign style={opStyle as CSSProperties} className={pulsateOpSignCls} insetStyle sign={props.operator} />
        <InsetText style={{ lineHeight: 'initial' }} weight="bold900" size={size}>
          {props.term2}
        </InsetText>
      </div>
      {showRightSide && (
        <>
          <MathSign
            style={eqStyle as CSSProperties}
            className={`${styles.EqualSign} ${pulsateEqSignCls}`}
            insetStyle
            sign={OPERATOR_SIGN.equal}
          />
          <div className={rightSideCls} style={{ gap: `${gapValue}em` }}>
            <InsetText style={resultStyle as CSSProperties} weight="bold900" size={size}>
              {props.result}
            </InsetText>
          </div>
        </>
      )}
    </div>
  );
};

export default Equation;
