import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { persistStore, FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import userReducer from 'modules/user/userSlice';
import activeQuestionUiReducer from 'modules/question/activeQuestionUISlice';
import appUiReducer from 'scenes/montessori/appUiSlice';
import awardStarsReducer from 'modules/profiles/awards/awardsSlice';
import badgesUiReducer from 'modules/badges/badgesUiSlice';
import blocksUiReducer from 'components/text/blocks/blocksUiSlice';
import boardReducer from 'modules/board/boardSlice';
import careerReducer from 'modules/career/careerSlice';
import careerUiReducer from 'modules/career/careerUiSlice';
import gamesReducer from 'scenes/games/gamesSlice';
import managementUiReducer from 'scenes/management/managementUiSlice';
import managerReducer from 'scenes/management/managerSlice';
import newsReducer from 'scenes/montessori/newsSlice';
import quizReducer from 'modules/quiz/quizSlice';
import scenesReducer from 'scenes/scenesSlice';
import imagesReducer from 'modules/storageimages/storageImagesSlice';
import unlocksReducer from 'scenes/montessori/unlocksSlice';
import userPreferencesReducer from 'modules/user/userPreferencesSlice';
import userUiReducer from 'modules/user/userUiSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['scenes', 'games', 'career', 'unlocks', 'awards', 'preferences'],
};

const uiReducers = combineReducers({
  userUi: userUiReducer,
  badgesUi: badgesUiReducer,
  careerUi: careerUiReducer,
  managementUI: managementUiReducer,
  activeQuestionUi: activeQuestionUiReducer,
  blocksUi: blocksUiReducer,
  appUi: appUiReducer,
});

export const rootReducer = combineReducers({
  scenes: scenesReducer,
  ui: uiReducers,
  user: userReducer,
  career: careerReducer,
  preferences: userPreferencesReducer,
  board: boardReducer,
  games: gamesReducer,
  awards: awardStarsReducer,
  unlocks: unlocksReducer,
  news: newsReducer,
  images: imagesReducer,
  manager: managerReducer,
  quiz: quizReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// export const store = setupStore(persistedReducer);

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
// export type AppStore = ReturnType<typeof setupStore>;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
