import { CSSProperties } from 'react';
import { Arithmetic, ArithmeticT, MaybeType, FruitT, OPERATOR_SIGN, Fruit } from 'types/App.types';
import { CellT, MathMode, MathModeT } from 'types/Board.types';
import { CareerHistoryReportsT, CategoryTimeAverageT, CategoryTimeAveragesT } from 'types/Career.types';
import { QuestionUI } from 'types/Question.types';

import FlipFruitToNumber from 'components/flipfruittonumber/FlipFruitToNumber';
import MathSignPanel from 'components/mathsign/MathSignPanel';

export const getOperatorSign = (type: ArithmeticT): string => {
  let operatorSign = '';
  switch (type) {
    case Arithmetic.Count:
      // case 'count:amount':
      operatorSign = OPERATOR_SIGN.amount;
      break;
    case Arithmetic.Compare:
      operatorSign = OPERATOR_SIGN.compare;
      break;
    case Arithmetic.Add:
      // case 'add:numbers':
      // case 'add:missingNumbers':
      operatorSign = OPERATOR_SIGN.plus;
      break;
    case Arithmetic.Subtract:
      // case 'subtract:numbers':
      // case 'subtract:missingNumbers':
      operatorSign = OPERATOR_SIGN.minus;
      break;
    case Arithmetic.Multiply:
      // case 'multiply:numbers':
      // case 'multiply:missingNumbers':
      operatorSign = OPERATOR_SIGN.multiply;
      break;
    case Arithmetic.Divide:
      // case 'divide:numbers':
      // case 'divide:missingNumbers':
      operatorSign = OPERATOR_SIGN.divide;
      break;
    case 'mix':
      operatorSign = OPERATOR_SIGN.mix;
      break;
    case 'all':
      operatorSign = OPERATOR_SIGN.all;
      break;
    default:
      operatorSign = OPERATOR_SIGN.unknown;
  }

  return operatorSign;
};

export const getOperatorTypeName = (type: ArithmeticT | ArithmeticT): string => {
  let operatorSign = '';
  switch (type) {
    case Arithmetic.Count:
      // case 'count:amount':
      operatorSign = Arithmetic.Count;
      break;
    case Arithmetic.Compare:
      operatorSign = Arithmetic.Compare;
      break;
    case Arithmetic.Add:
      // case 'add:numbers':
      // case 'add:missingNumbers':
      operatorSign = Arithmetic.Add;
      break;
    case Arithmetic.Subtract:
      // case 'subtract:numbers':
      operatorSign = Arithmetic.Subtract;
      break;
    // case 'subtract:missingNumbers':
    //   operatorSign = 'find subtractor';
    //   break;
    case Arithmetic.Multiply:
      // case 'multiply:numbers':
      operatorSign = Arithmetic.Multiply;
      break;
    // case 'multiply:missingNumbers':
    //   operatorSign = 'find multiplier';
    //   break;
    case Arithmetic.Divide:
      // case 'divide:numbers':
      operatorSign = Arithmetic.Divide;
      break;
    // case 'divide:missingNumbers':
    //   operatorSign = 'find divisor';
    //   break;
    case 'mix':
      operatorSign = 'mix';
      break;
    case 'all':
      operatorSign = 'total';
      break;
    default:
      operatorSign = 'unknown';
  }

  return operatorSign;
};

export const getOperatorIconName = (mathMode: MathModeT): string => {
  if (mathMode === MathMode.Addition) {
    return 'add';
  } else if (mathMode === MathMode.Subtraction) {
    return 'remove';
  } else if (mathMode === MathMode.Multiplication) {
    return 'close';
  } else if (mathMode === MathMode.Division) {
    return 'pen_size_2';
  } else {
    return 'question_mark';
  }
};

export const getFruitPluralByFruitType = (fruit: FruitT): string => {
  if (fruit === Fruit.Watermelon) {
    return 'watermelons';
  } else if (fruit === Fruit.Orange) {
    return 'oranges';
  } else if (fruit === Fruit.Lemon) {
    return 'lemons';
  } else if (fruit === Fruit.Apple) {
    return 'apples';
  }
  return 'tomatoes';
};

export const NUMBER_NAME: Array<string> = [
  'zero',
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
  'ten',
];

export const getRandomizedNumbers = (numCount: number): Array<number> => {
  const sorter = (a: number, b: number) =>
    Math.floor(Math.random() * b) + 3 > Math.floor(Math.random() * a) + 2 ? -1 : 0;
  const numberArray = [];
  for (let i = 1; i <= numCount; i++) {
    numberArray.push(i);
  }
  numberArray.sort(sorter);
  return numberArray;
};

export const getRandomizedRangeNumbers = (min: number, max: number, useRandom: boolean): Array<number> => {
  const sorter = (a: number, b: number) =>
    Math.floor(Math.random() * b) + 3 > Math.floor(Math.random() * a) + 2 ? -1 : 0;
  const numberArray = [];
  for (let i = min; i <= max; i++) {
    numberArray.push(i);
  }
  if (useRandom) {
    numberArray.sort(sorter);
  }
  return numberArray;
};

type TypedArray = Array<unknown>;
export const randomizeArray = (array: TypedArray): TypedArray => {
  const length = array.length;
  const sortedArray: Array<number> = Array.from(Array(length).keys());
  const shuffledArray = array.slice();
  sortedArray.forEach((slot) => {
    const newSlot = Math.floor(Math.random() * length);
    const temp = shuffledArray[newSlot];
    shuffledArray[newSlot] = shuffledArray[slot];
    shuffledArray[slot] = temp;
  });
  return shuffledArray;
};

export const getAverageTimeMS = (list: CareerHistoryReportsT) => {
  const startVal = 0;
  const totalSum: number = list.reduce((sum: number, history) => sum + (history.timeToSolve as number), startVal);
  return Math.round(totalSum / list.length);
};

export const getTimeAveragesByCategory = (historyList: CareerHistoryReportsT): CategoryTimeAveragesT => {
  const categoryArray: Array<ArithmeticT> = [
    Arithmetic.Count,
    Arithmetic.Compare,
    Arithmetic.Add,
    Arithmetic.Subtract,
    Arithmetic.Multiply,
    Arithmetic.Divide,
    'all',
  ];
  const categoryAveragesList: CategoryTimeAveragesT = [];

  categoryArray.forEach((category: ArithmeticT) => {
    const list = category !== 'all' ? historyList.filter((h) => h.questionCategory === category) : historyList;
    if (list) {
      const timeAverageT: CategoryTimeAverageT = {
        categoryOperator: getOperatorSign(category),
        categoryTitle: getOperatorTypeName(category),
        avgTimeMs: getAverageTimeMS(list),
        count: list.length,
      };
      categoryAveragesList.push(timeAverageT);
    }
  });

  return categoryAveragesList;
};

export const isAnswerCorrect = (questionUI: QuestionUI, userAnswer?: CellT): boolean => {
  if (!userAnswer) {
    return false;
  }

  if (questionUI.expectedResultCells && questionUI.expectedResultCells.length > 0) {
    const maybeAnswer: MaybeType<CellT> = questionUI.expectedResultCells.find(
      (result) =>
        result.row === userAnswer.row && result.column === userAnswer.column && result.value === userAnswer.value
    );
    return !!maybeAnswer && questionUI.expectedResult === userAnswer.value;
  }

  return questionUI.expectedResult === userAnswer.value;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isNumber = (value: any): boolean => {
  if (value === undefined) return false;
  if (typeof value === 'number') return true;
  if (typeof value === 'string') {
    return value === '' || isNaN(parseInt(value)) ? false : typeof parseInt(value) === 'number';
  }

  return false;
};

export const getArithmeticOperationSelectorCard = () => {
  const commonStyle: CSSProperties = {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '1',
    width: '-webkit-fill-available',
  };

  const containerStyle = {
    ...(commonStyle as CSSProperties),
    fontSize: '1.5rem',
    flexDirection: 'column',
  };

  const rowStyle = {
    ...(commonStyle as CSSProperties),
    fontSize: '0.7rem',
    flexDirection: 'row',
  };

  return (
    <div style={{ ...(containerStyle as CSSProperties), gap: '0.25em' }}>
      Tap to open an arithmetic operation tutorial.
      <div style={{ ...(rowStyle as CSSProperties), gap: '1em' }}>
        <MathSignPanel fruit={Fruit.Tomato} sign={OPERATOR_SIGN.plus} to={`/boardtutorial?mathId=${Arithmetic.Add}`} />
        {/* eslint-disable-next-line max-len */}
        <MathSignPanel
          fruit={Fruit.Watermelon}
          sign={OPERATOR_SIGN.minus}
          to={`/boardtutorial?mathId=${Arithmetic.Subtract}`}
        />
        {/* eslint-disable-next-line max-len */}
        <MathSignPanel
          fruit={Fruit.Apple}
          sign={OPERATOR_SIGN.multiply}
          to={`/boardtutorial?mathId=${Arithmetic.Multiply}`}
        />
        {/* eslint-disable-next-line max-len */}
        <MathSignPanel
          fruit={Fruit.Lemon}
          sign={OPERATOR_SIGN.divide}
          to={`/boardtutorial?mathId=${Arithmetic.Divide}`}
        />
      </div>
    </div>
  );
};

export const getWatermelonFlipFruitToNumberPanel = (count: number, maxWidth: number) => {
  const countText = count > 1 ? getFruitPluralByFruitType(Fruit.Watermelon) : 1;
  return (
    <FlipFruitToNumber
      style={{ maxWidth: `${maxWidth}em`, fontSize: '.5em' }}
      fruitType={Fruit.Watermelon}
      itemCount={count}
      itemCountText={`${count} ${countText}`}
    />
  );
};

export const getTomatoesFlipFruitToNumberPanel = (count: number, maxWidth: number) => {
  const countText = count > 1 ? getFruitPluralByFruitType(Fruit.Tomato) : '';
  return (
    <FlipFruitToNumber
      style={{ maxWidth: `${maxWidth}em`, fontSize: '.5em' }}
      fruitType={Fruit.Tomato}
      itemCount={count}
      itemCountText={`${count} ${countText}`}
    />
  );
};

export const getNRandomNumbers = (numCount: number, max: number, min = 1): Array<number> => {
  const numbersFromZeroToMax = Array.from(Array(max + 1).keys());
  let numberRange = numbersFromZeroToMax.slice(min);

  const nRandomNumbers: Array<number> = [];

  const getRandomNum = (arrayOfNumbers: Array<number>) => {
    const index = Math.floor(Math.random() * arrayOfNumbers.length);
    const value = arrayOfNumbers[index];
    arrayOfNumbers.splice(index, 1);
    return { value, arrayOfNumbers };
  };
  let isFilled = false;
  while (!isFilled) {
    const result = getRandomNum(numberRange);
    const n = result.value;
    numberRange = result.arrayOfNumbers;

    if (!nRandomNumbers.includes(n)) {
      nRandomNumbers.push(n);
    }

    if (nRandomNumbers.length === numCount) {
      isFilled = true;
    }
  }

  return nRandomNumbers;
};
