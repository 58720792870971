import React, { HTMLInputTypeAttribute, useEffect, useState } from 'react';
import { FormControl, InputAdornment, InputLabel, OutlinedInput, SxProps, Theme } from '@mui/material';
import Icon from 'components/icon/Icon';

import styles from './InputText.module.scss';
import { VoidFunc } from 'types/App.types';

type InputTextProps = {
  /**
   * @required `id` unique identifier
   */
  id: string;
  /**
   * @optional `class` style to set on the root element
   */
  className?: string;
  /**
   * @optional `class` style to set on the input element
   */
  inputClassName?: string;
  /**
   * @optional `fieldSize` size of the input field to render
   */
  fieldSize?: 'small' | 'default';
  /**
   * @optional `titleLabel` is shown above and to the left of the input field
   */
  titleLabel?: string;
  /**
   * @optional `placeholder` is shown inside the input field when no value is present and title label is positioned above the input field
   */
  placeholder?: string;
  /**
   * @optional `icon` name of the icon to render at the start of the input field
   */
  icon?: string;
  /**
   * @optional `trailingIcon` name of the icon to render at the end of the input field
   */
  trailingIcon?: string;
  /**
   * @optional `onTapTrailingIcon` on click listener/handler
   */
  onTapTrailingIcon?: VoidFunc;
  /**
   * @optional `type` of the input field
   *
   * @default text
   */
  type?: HTMLInputTypeAttribute;
  /**
   * @optional `enterKeyHint` type of action key to display on mobile keyboard
   *
   * @default undefined default action key, usually enter
   */
  enterKeyHint?: 'search' | 'enter' | 'done' | 'go' | 'next' | 'previous' | 'send' | undefined;
  /**
   * @optional `borderType` specifies the border to render around the input field
   *
   * @default indent
   */
  borderType?: 'indent' | 'outline';
  /**
   * @optional `disabled` specifies if the field should be disabled
   *
   * @default false
   */
  disabled?: boolean;
  /**
   * @optional `isFilled` specifies if the icon should be filled or not
   *
   * @default false
   */
  isFilled?: boolean;
  /**
   * @optional `value` specifies `string` value to display
   *
   * @default undefined
   */
  value?: string;
  /**
   * @optional `fullWidth` when true stretches the input field to the max available width
   */
  fullWidth?: boolean;
  /**
   * @optional `multiline` when true allows multiple lines to be used
   */
  multiline?: boolean;
  /**
   * @optional `onChange(value: string)` callback when user makes a change
   */
  onChange?: (value: string) => void;
  /**
   * @optional `onEnterTapped()` callback when user presses Enter key
   */
  onEnterTapped?: () => void;
  /**
   * @optional The system prop that allows defining system overrides as well as additional CSS styles.
   *
   * @default { my: 1 } `margin: 1em 0`
   */
  sx?: SxProps<Theme>;
};

const InputText: React.FC<InputTextProps> = ({
  id,
  fieldSize = 'default',
  titleLabel = '',
  placeholder = '',
  icon = '',
  trailingIcon = '',
  onTapTrailingIcon = () => undefined,
  disabled = false,
  className = '',
  inputClassName = '',
  type = 'text',
  onChange = () => undefined,
  onEnterTapped = () => undefined,
  value = '',
  enterKeyHint,
  borderType = 'indent',
  multiline = false,
  fullWidth = true,
  sx = { my: 1 },
  isFilled = false,
}) => {
  const [mount, setMount] = useState<boolean>(false);

  useEffect(() => {
    if (!mount) setMount(true);
  }, [mount]);

  const onKeyTapped = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.key === 'Enter') onEnterTapped();
  };

  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const getTrailingIcon = () => (
    <Icon
      icon={trailingIcon}
      showPointerCursor
      onClick={onTapTrailingIcon}
      size={fieldSize === 'small' ? 'xs75' : 'sma'}
    />
  );

  const passwordIcon = (
    <Icon
      onClick={handleClickShowPassword}
      showPointerCursor
      onMouseDown={handleMouseDownPassword}
      icon={showPassword ? 'visibility_off' : 'visibility'}
    />
  );

  const startAdornment = icon ? (
    <InputAdornment position="start">
      <Icon icon={icon} isFilled={isFilled} />
    </InputAdornment>
  ) : (
    ''
  );
  const endAdornment = trailingIcon ? (
    <InputAdornment position="end">{type === 'password' ? passwordIcon : getTrailingIcon()}</InputAdornment>
  ) : (
    ''
  );

  return (
    <FormControl
      id={id}
      fullWidth={fullWidth}
      className={`${styles.InputText} ${borderType} ${className}`}
      sx={sx}
      variant="outlined">
      <InputLabel htmlFor={`${id}-adornment`}>{titleLabel}</InputLabel>
      <OutlinedInput
        id={`${id}-adornment`}
        className={`${inputClassName ?? ''} ${fieldSize}`}
        type={showPassword ? 'text' : type}
        onKeyUp={onKeyTapped}
        fullWidth
        autoComplete="off"
        label={titleLabel}
        disabled={disabled}
        multiline={multiline}
        minRows={multiline ? 5 : 1}
        inputProps={{ enterKeyHint: enterKeyHint }}
        value={value}
        placeholder={placeholder}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onChange(event.target.value);
        }}
      />
    </FormControl>
  );
};

export default InputText;
