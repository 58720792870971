import React from 'react';
import { PropsWithChildren } from 'types/App.types';
import { BannerColorT, BannerPositionT } from 'types/Game.types';

import MeText from 'components/text/metext/MeText';

import styles from './Banner.module.scss';

type BannerOwnProps = {
  className?: string;
  bannerColor?: Capitalize<BannerColorT>;
  bannerPosition?: BannerPositionT;
  bannerSize?: string;
};

type BannerProps = PropsWithChildren<BannerOwnProps>;

const Banner: React.FC<BannerProps> = ({ bannerPosition = 'TopLeft', bannerColor = 'Primary', ...props }) => {
  const colorCls = styles[bannerColor];
  const posCls = styles[`Position${bannerPosition}`];
  const sizeCls = props.bannerSize ?? '';
  return (
    <div className={`${styles.Banner} ${posCls} ${colorCls} ${sizeCls} ${props.className ?? ''}`}>
      <MeText textSize="xs11" lineHeight="line13" weight="bold900" insetText>
        {props.children}
      </MeText>
    </div>
  );
};

export default Banner;
