import React, { AnimationEvent } from 'react';
import { ElementSizeT, Fruit, FruitT } from 'types/App.types';

import styles from './FruitIcon.module.scss';

type TomatoColorT = 'GreenTomato' | 'RedTomato';

type OwnProps = {
  className?: string;
  size?: ElementSizeT;
  fruitType?: FruitT;
  onAnimationOver?: (e: AnimationEvent) => void;
} & Pick<React.HTMLAttributes<HTMLImageElement>, 'style'>;

type TomatoProps = {
  tomatoColor?: TomatoColorT;
} & OwnProps;

const FruitIcon: React.FC<OwnProps> = ({ fruitType = Fruit.Tomato, ...props }) => {
  if (fruitType === Fruit.Tomato) {
    return <TomatoIcon {...props} className={props.className ?? ''} />;
  } else if (fruitType === Fruit.Watermelon) {
    return <WatermelonIcon {...props} className={props.className ?? ''}/>;
  } else if (fruitType === Fruit.Orange) {
    return <OrangeIcon {...props} />;
  } else if (fruitType === Fruit.Apple) {
    return <AppleIcon {...props} />;
  } else if (fruitType === Fruit.Lemon) {
    return <LemonIcon {...props} />;
  }

  return <TomatoIcon {...props} />;
};

const TomatoIcon: React.FC<TomatoProps> = ({ tomatoColor = 'RedTomato', size = 'sma', className = '', ...props }) => {
  return (
    <img
      onAnimationEnd={props.onAnimationOver}
      title="Tomato"
      src="/images/icons/icon-tomato-128x128.webp"
      alt="Tomato Icon"
      className={`${styles.TomatoIcon} ${tomatoColor} ${size} ${className}`}
      style={{ ...props.style }}
    />
  );
};

const WatermelonIcon: React.FC<OwnProps> = ({ size = 'sma', className = '', ...props }) => {
  return (
    <img
      onAnimationEnd={props.onAnimationOver}
      title="Watermelon"
      src="/images/icons/icon-watermelon-128x128.webp"
      alt="Watermelon Icon"
      className={`${styles.WatermelonIcon} ${className} ${size}`}
      style={{ ...props.style }}
    />
  );
};

const OrangeIcon: React.FC<OwnProps> = ({ size = 'sma', className = '', ...props }) => {
  return (
    <img
      onAnimationEnd={props.onAnimationOver}
      title="Orange"
      aria-details="Fruit icon"
      src="/images/icons/icon-orange-128x128.webp"
      alt="Orange Icon"
      className={`${styles.OrangeIcon} ${size} ${className}`}
      style={{ ...props.style }}
    />
  );
};

const AppleIcon: React.FC<OwnProps> = ({ size = 'sma', className = '', ...props }) => {
  return (
    <img
      onAnimationEnd={props.onAnimationOver}
      title="Apple"
      src="/images/icons/icon-apple-128x128.webp"
      alt="Apple Icon"
      className={`${styles.AppleIcon} ${size} ${className}`}
      style={{ ...props.style }}
    />
  );
};

const LemonIcon: React.FC<OwnProps> = ({ size = 'sma', className = '', ...props }) => {
  return (
    <img
      onAnimationEnd={props.onAnimationOver}
      title="Lemon"
      src="/images/icons/icon-lemon-128x128.webp"
      alt="Lemon Icon"
      className={`${styles.LemonIcon} ${size} ${className}`}
      style={{ ...props.style }}
    />
  );
};

const RedTomatoIcon: React.FC<TomatoProps> = ({ size = 'sma', className = '', ...props }) => {
  return (
    <img
      onAnimationEnd={props.onAnimationOver}
      title="Red Tomato"
      src="/images/red-tomato-160x160.webp"
      alt="Red Tomato Icon"
      className={`${styles.RedTomatoIcon} ${size} ${className}`}
      style={{ ...props.style }}
    />
  );
};

const GreenTomatoIcon: React.FC<TomatoProps> = ({ size = 'sma', className = '', ...props }) => {
  return (
    <img
      onAnimationEnd={props.onAnimationOver}
      title="Green Tomato"
      src="/images/green-tomato-160x160.webp"
      alt="Green Tomato Icon"
      style={{ ...props.style, filter: 'drop-shadow(0px 1px 0px lightyellow)' }}
      className={`${styles.TomatoIcon} ${size} ${className}`}
    />
  );
};

const YellowTomatoIcon: React.FC<TomatoProps> = ({ size = 'sma', className = '', ...props }) => {
  return (
    <img
      onAnimationEnd={props.onAnimationOver}
      title="Yellow Tomato"
      src="/images/yellow-tomato-160x160.webp"
      alt="Yellow Tomato Icon"
      className={`${styles.TomatoIcon} ${size} ${className}`}
      style={{ ...props.style, filter: 'drop-shadow(0px 1px 0px lightyellow)' }}
    />
  );
};

const TomatoCrestIcon: React.FC<TomatoProps> = ({ size = 'sma', className = '', ...props }) => {
  return (
    <img
      onAnimationEnd={props.onAnimationOver}
      title="Tomato Crest"
      src="/images/crest-128x128.png"
      alt="Tomato Crest"
      className={`${size} ${className}`}
      style={{ ...props.style }}
    />
  );
};

export {
  FruitIcon as default,
  TomatoIcon,
  RedTomatoIcon,
  GreenTomatoIcon,
  YellowTomatoIcon,
  TomatoCrestIcon,
  WatermelonIcon,
  OrangeIcon,
  AppleIcon,
  LemonIcon,
};
