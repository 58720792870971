import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'app/store';
import { analytics, logEvent } from '../../firebase/Firebase';
import { MaybeType, StatusT } from 'types/App.types';
import { CareerId, CrestT } from 'types/Career.types';
import { CarouselScrollBehaviorT, DisplayModeT } from 'components/carousel/Carousel';
import {
  doUpdateUserPreferencesAutoplay,
  doUpdateUserPreferencesHideCarousel,
  doUpdateUserPreferencesHideCompletedCoursesOnCarousel,
  setUserPreferenceHideCompletedCoursesOnCarousel,
} from 'modules/user/userPreferencesSlice';
import { AppEvent, getSelectedContent as getContent, SelectContentType } from 'app/constants/AnalyticEvents';
import { getCurrentCareerId } from 'modules/career/careerSlice';

export const defaultWallColor = '#DCC095';
export const defaultWallColorGradient = '#BCA682';
export const windowColor = '#B1D9E7';

export type CareerUiState = {
  /** career status, used by loaders, and other FE components */
  status: StatusT;
  /** expand / collapse career overview map panel */
  careerUiOverviewPanelOpen: boolean;
  /** expand / collapse career carousel panel */
  careerCarouselDisplayMode: DisplayModeT;
  careerCarouselAutoplay: boolean;
  careerCarouselHideCompletedCourses: boolean;
  careerCarouselHidden: boolean;
  careerCarouselScrollBehavior: 'smooth' | 'auto';
  careerBadgeAwardPopupOpen: boolean;
  crestForAwardPopup?: CrestT;
  crestForAwardPopupQueue: Array<CrestT>;
  openTutorialChapterTab: boolean;
};

export const doShowAwardCrest = (): AppThunk => (dispatch, getState) => {
  if (getState().ui.careerUi.crestForAwardPopupQueue.length > 0) {
    const tempQueue = getState().ui.careerUi.crestForAwardPopupQueue.slice();
    const crest = tempQueue.shift() as CrestT;
    dispatch(setCrestForAwardPopup(crest));
    dispatch(setCareerBadgeAwardPopupOpen(true));
    dispatch(setCrestForAwardPopupToQueue(tempQueue));
    logEvent(analytics, AppEvent.SelectContent, getContent(SelectContentType.ViewedCrest, crest.id, 'show'));
  }
};

export const doCloseAwardCrest = (): AppThunk => (dispatch, getState) => {
  dispatch(setCareerBadgeAwardPopupOpen(false));

  if (getState().ui.careerUi.crestForAwardPopupQueue.length > 0) {
    setTimeout(() => {
      dispatch(doShowAwardCrest());
    }, 1000);
  }
};

export const doSetCareerCarouselAutoplay =
  (isActive: boolean): AppThunk =>
  (dispatch, getState) => {
    dispatch(doUpdateUserPreferencesAutoplay(isActive));
    dispatch(setCareerCarouselAutoplay(isActive));
    const careerId: CareerId = getCurrentCareerId(getState());
    logEvent(analytics, AppEvent.SelectContent, getContent(SelectContentType.Autoplay, careerId, `${isActive}`));
  };

export const doSetCareerCarouselHidden =
  (isActive: boolean): AppThunk =>
  (dispatch, getState) => {
    dispatch(doUpdateUserPreferencesHideCarousel(isActive));
    dispatch(setCareerCarouselHidden(isActive));
    const careerId: CareerId = getCurrentCareerId(getState());
    logEvent(analytics, AppEvent.SelectContent, getContent(SelectContentType.HideCarousel, careerId, `${isActive}`));
  };

export const doSetCareerCarouselHideCompletedCourses =
  (isHidden: boolean): AppThunk =>
  (dispatch, getState) => {
    dispatch(doUpdateUserPreferencesHideCompletedCoursesOnCarousel(isHidden));
    dispatch(setUserPreferenceHideCompletedCoursesOnCarousel(isHidden));
    dispatch(setCareerCarouselHideCompletedCourses(isHidden));
    const careerId: CareerId = getCurrentCareerId(getState());
    logEvent(
      analytics,
      AppEvent.SelectContent,
      getContent(SelectContentType.HideCompletedCoursesOnCarousel, careerId, `${isHidden}`)
    );
  };

const initialState: CareerUiState = {
  status: 'idle',
  careerUiOverviewPanelOpen: true,
  careerCarouselDisplayMode: 'FULL_MODE',
  careerCarouselAutoplay: true,
  careerCarouselHideCompletedCourses: false,
  careerCarouselHidden: false,
  careerCarouselScrollBehavior: 'auto',
  careerBadgeAwardPopupOpen: false,
  crestForAwardPopup: undefined,
  crestForAwardPopupQueue: [],
  openTutorialChapterTab: false,
};

export const careerUiSlice = createSlice({
  name: 'careerUi',
  initialState,
  reducers: {
    setCareerUiStatus: (state, action: PayloadAction<StatusT>) => {
      state.status = action.payload;
    },
    setCareerUiOverviewPanelOpen: (state, action: PayloadAction<boolean>) => {
      // console.log(`state.careerUiOverviewPanelOpen = ${action.payload}`);
      state.careerUiOverviewPanelOpen = action.payload;
    },
    setCareerCarouselDisplayMode: (state, action: PayloadAction<DisplayModeT>) => {
      state.careerCarouselDisplayMode = action.payload;
      state.careerCarouselScrollBehavior = action.payload === 'MINI_MODE' ? 'auto' : state.careerCarouselScrollBehavior;
    },
    setCareerCarouselAutoplay: (state, action: PayloadAction<boolean>) => {
      state.careerCarouselAutoplay = action.payload;
    },
    setCareerCarouselHideCompletedCourses: (state, action: PayloadAction<boolean>) => {
      state.careerCarouselHideCompletedCourses = action.payload;
    },
    setCareerBadgeAwardPopupOpen: (state, action: PayloadAction<boolean>) => {
      state.careerBadgeAwardPopupOpen = action.payload;
    },
    setCrestForAwardPopup: (state, action: PayloadAction<CrestT>) => {
      state.crestForAwardPopup = action.payload;
    },
    setCrestForAwardPopupToQueue: (state, action: PayloadAction<Array<CrestT>>) => {
      state.crestForAwardPopupQueue = action.payload;
    },
    addCrestForAwardPopupToQueue: (state, action: PayloadAction<CrestT>) => {
      state.crestForAwardPopupQueue = state.crestForAwardPopupQueue.concat(action.payload);
    },
    setCareerCarouselHidden: (state, action: PayloadAction<boolean>) => {
      state.careerCarouselHidden = action.payload;
    },
    setCareerCarouselScrollBehavior: (state, action: PayloadAction<CarouselScrollBehaviorT>) => {
      state.careerCarouselScrollBehavior = action.payload;
    },
    setOpenTutorialChapterTab: (state, action: PayloadAction<boolean>) => {
      state.openTutorialChapterTab = action.payload;
    },
  },
});

export const {
  setCareerUiStatus,
  setCareerUiOverviewPanelOpen,
  setCareerCarouselDisplayMode,
  setCareerCarouselAutoplay,
  setCareerBadgeAwardPopupOpen,
  setCrestForAwardPopup,
  addCrestForAwardPopupToQueue,
  setCrestForAwardPopupToQueue,
  setCareerCarouselHidden,
  setCareerCarouselScrollBehavior,
  setCareerCarouselHideCompletedCourses,
  setOpenTutorialChapterTab,
} = careerUiSlice.actions;

export const getCareerUiStatus = (state: RootState): StatusT => state.ui.careerUi.status;
export const getCareerOverviewPanelOpen = (state: RootState): boolean => state.ui.careerUi.careerUiOverviewPanelOpen;
export const getCareerCarouselAutoplay = (state: RootState): boolean => state.ui.careerUi.careerCarouselAutoplay;
export const getCareerCarouselDisplayMode = (state: RootState): DisplayModeT =>
  state.ui.careerUi.careerCarouselDisplayMode;
export const isCareerCarouselInMiniDisplayMode = (state: RootState): boolean =>
  state.ui.careerUi.careerCarouselDisplayMode === 'MINI_MODE';
export const getCareerBadgeAwardPopupOpen = (state: RootState): boolean => state.ui.careerUi.careerBadgeAwardPopupOpen;
export const getCrestForAwardPopup = (state: RootState): MaybeType<CrestT> => state.ui.careerUi.crestForAwardPopup;
export const isCareerCarouselHidden = (state: RootState): boolean =>
  isCareerCarouselInMiniDisplayMode(state) && state.ui.careerUi.careerCarouselHidden;
export const getCareerScrollBehavior = (state: RootState): CarouselScrollBehaviorT =>
  state.ui.careerUi.careerCarouselScrollBehavior;
export const getCareerCarouselHideCompletedCourses = (state: RootState) =>
  state.ui.careerUi.careerCarouselHideCompletedCourses;
export const getOpenTutorialChapterTab = (state: RootState): boolean => state.ui.careerUi.openTutorialChapterTab;

export default careerUiSlice.reducer;
