import React, { useEffect, useState } from 'react';

import InsetText from 'components/text/inset/InsetText';
import TitleHeader from 'components/titleheader/TitleHeader';

import styles from './ProgressIndicator.module.scss';

export type ProgressDataT = {
  total: number;
  current: number;
  percentage: number;
};

export type ProgressIndicatorProps = {
  className?: string;
  title?: string;
  icon?: string;
  isFilledIcon?: boolean;
  value?: number;
  max?: number;
  showPercentageLabel?: boolean;
  showEndLabel?: boolean;
};

const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({
  className = '',
  title = '',
  icon = '',
  isFilledIcon = false,
  max = 100,
  value = 0,
  showPercentageLabel = false,
  showEndLabel = true,
}) => {
  // const progressRef = useRef<number>(0);
  const [progress, setProgress] = useState<string>('width-000');
  const [percentageValue, setPercentageValue] = useState<number>(0);

  useEffect(() => {
    if (value >= 0) {
      const percentage = Math.floor((value / max) * 100);
      const sanitizedPercentage = isNaN(percentage) || !isFinite(percentage) ? 0 : percentage;
      setPercentageValue(sanitizedPercentage);

      requestAnimationFrame(() => {
        const cls = sanitizedPercentage === 100 ? '099' : `${sanitizedPercentage}`.padStart(3, '0');
        setProgress(`width-${cls}`);
      });
    }
  }, [value, max]);

  return (
    <>
      <div className={`${styles.ProgressIndicator} ${styles.ProgressIndicatorBar} ${progress} ${className}`}>
        <TitleHeader icon={icon} titleSize="med" showHorizontalRule={false} isFilledIcon={isFilledIcon}>
          {title}
        </TitleHeader>
        {showEndLabel &&
        <InsetText size="sma">{`${showPercentageLabel ? `${percentageValue}%` : `${value} / ${max}`}`}</InsetText>}
      </div>
    </>
  );
};

export default ProgressIndicator;
