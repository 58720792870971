import { Dictionary } from 'express-serve-static-core';
import { CoordinatesT } from 'modules/games/wakacolumnbuilder/components/cellblock/CellBlock';
import { AnimationT, ArithmeticT, CreateModifyT, FruitT, StatusT } from 'types/App.types';

export type GameId = string;
export type GameInstanceId = string;
export type GameQuestionId = string;

export const GameLevel = {
  Level1: 'level1',
  Level2: 'level2',
  Level3: 'level3',
  Level4: 'level4',
  Level5: 'level5',
  Level6: 'level6',
  Level7: 'level7',
} as const;
export type GameLevelT = (typeof GameLevel)[keyof typeof GameLevel];

/** Game Definition */
export type GameT = {
  gameId: GameId;
  gameTitle: string;
  gameDescription: string;
  gameIcon: GameIconsT;
  gameImage: string;
  gameArithmeticType: ArithmeticT;
  hasQuestions: boolean;
  gameQuestions: GameQuestionsT;
  isPublic?: boolean;
} & CreateModifyT;

export type GameWithLockedStatusT = {
  locked: boolean;
  /** should contain a string in the following format
   * `{type} {title}`
   * that identify items to complete in order to unlock the game */
  unlockingDescription?: string;
} & GameT;

export type GamesT = Array<GameT>;

export type ExpectedResultT = {
  expectedCount: number;
  fruit: FruitT;
};
export type ExpectedResultsT = Array<ExpectedResultT>;

export type GameQuestionT = {
  gameQuestionId: GameQuestionId;
  title: string;
  level: GameLevelT;
  description: string;
  gameArithmeticType: ArithmeticT;
  gameId: GameId;
  gameComplete: string;
  gameTitle: string;
  expectedResults: ExpectedResultsT;
} & CreateModifyT;

export type GameQuestionsT = Array<GameQuestionT>;

export const GameResult = {
  /** Finished playing the game with outcome `pass` */
  Pass: 'pass',
  /** Finished playing the game with outcome `fail` */
  Fail: 'fail',
  /** Started playing game but did not finish */
  Incomplete: 'incomplete',
  /** Viewed the game but did not start playing */
  Viewed: 'viewed',
} as const;
export type GameResultT = (typeof GameResult)[keyof typeof GameResult];

/** A single game play iteration, these live inside career/gameHistory/gameHistoryId */
export type GameInstanceT = {
  gameInstanceId: GameInstanceId;
  gameId: GameId;
  gameTitle: string;
  result: GameResultT;
  gameLevel: GameLevelT;
  elapsedTime: number;
} & CreateModifyT;

export const GameIcons = {
  Joystick: 'joystick',
  Crossword: 'crossword',
  Extension: 'extension',
  Cooking: 'cooking',
  SmartToy: 'smart_toy',
  Strategy: 'strategy',
  SportsSoccer: 'sports_soccer',
  Chess: 'chess',
  VideogameAsset: 'videogame_asset',
  Sports: 'sports',
  Toys: 'toys',
  Robot: 'robot',
  SportsEsports: 'sports_esports',
  Tactic: 'tactic',
  Gavel: 'gavel',
  Robot2: 'robot_2',
  ToysAndGames: 'toys_and_games',
  Cube: 'deployed_code',
  Sprint: 'sprint',
  SportsTennis: 'sports_tennis',
} as const;

export type GameIconsT = (typeof GameIcons)[keyof typeof GameIcons];

export type WakaFlipCardT = {
  id: number;
  open: boolean;
  complete: boolean;
  animation: AnimationT;
  type: FruitT;
};

export const WakaGameLevel = {
  Easy: GameLevel.Level1,
  Medium: GameLevel.Level2,
  Hard: GameLevel.Level3,
  ExtraHard: GameLevel.Level4,
} as const;
export type WakaGameLevelT = (typeof WakaGameLevel)[keyof typeof WakaGameLevel];

export const WakaGameState = {
  READY: 'ready',
  PLAYING: 'playing',
  GAME_OVER: 'game_over',
  PAUSED: 'paused',
} as const;
export type WakaGameStateT = (typeof WakaGameState)[keyof typeof WakaGameState];

export type WakaCardsFruitTuple = {
  size: number;
  fruitCount: number;
};
export type LockedFruitId = string;
export type LockedFruitT = {
  id: LockedFruitId;
  expectedResult: ExpectedResultT;
  locked: boolean;
};

export const CubeSides = {
  Front: 'front',
  Right: 'right',
  Back: 'back',
  Left: 'left',
  Top: 'top',
  Bottom: 'bottom',
} as const;
export type CubeSideT = (typeof CubeSides)[keyof typeof CubeSides];

export type CubeMapT = {
  [key: string]: number;
};

export const SwipeDirection = {
  North: 'north',
  South: 'south',
  West: 'west',
  East: 'east',
  Front: 'front',
  Back: 'back',
} as const;
export type SwipeDirectionT = (typeof SwipeDirection)[keyof typeof SwipeDirection];

export type LockedFruits = Array<LockedFruitT>;

// #region GAME RESPONSES
export type UpdateGameResponseT = {
  game: GameT;
  index: number;
};

export type SetGamesResponseT = {
  collection: GamesT;
  status: StatusT;
};

export type GamesCollectionResponse = {
  collection: Awaited<GamesT>;
};

export type GameWithQuestionResponse = {
  game: GameT;
  gameQuestion: GameQuestionT;
};
// #endregion

export type AwardedGameT = {
  gameTitle: string;
  gameId: GameId;
} & CreateModifyT;

export type AwardedGamesT = Array<AwardedGameT>;
export type AwardedGamesCollection = {
  awardedGames: AwardedGamesT;
};

// #region
export type HorizontalPositionT = 'HorizontalTop' | 'HorizontalBottom';
export type BannerColorT = 'primary' | 'success' | 'info' | 'warning' | 'error' | 'private';
export type BannerPositionT = 'TopLeft' | 'TopRight' | 'BottomLeft' | 'BottomRight' | HorizontalPositionT;

export type FruitRowModel = {
  fruitInRow: number;
  gateSize: number;
  answer: number;
  row?: number;
  fruit: FruitT;
};
// #endregion

// #region
export type WalkAboutBlockT = {
  blockId: number;
  isSelected: boolean;
  isStartGate: boolean;
  isEndGate: boolean;
  isHoleBlock: boolean;
  isMultiplicationBlock: boolean;
  highlighted: boolean;
  direction?: WalkAboutFootDirectionT;
  onBlockTapped: (blockId: number) => AnimationT;
  getBlockIcon: (blockId: number) => string;
  blockIcon?: string;
  value?: number;
  onSubmit: () => AnimationT;
};
export type WalkAboutBlocksT = Array<WalkAboutBlockT>;
export type WalkAboutRowT = Array<WalkAboutBlockT>;
export type WalkAboutGridT = Array<WalkAboutRowT>;
export type WalkAboutFootDirectionT = Omit<SwipeDirectionT, 'front' | 'back'>;

export type BlockPath = {
  path: Array<BlockCoordsT>;
  pathMoves: number;
  pathSum: number;
  pathIndices?: Array<number>;
};

export type BlockPathList = Dictionary<Array<BlockPath>>;
export type MoveDirection = 'left' | 'right' | 'up' | 'down';
export type BlockCoordsT = {
  value: number;
} & CoordinatesT;


// #endregion
