import { To } from 'react-router-dom';
import { GameQuestionT, GameQuestionsT, GameT } from 'types/Game.types';
import { ArticleId, NewsArticle } from 'types/News.types';
import { UnlockT } from 'types/Unlocks.types';

// #region APP CONFIG *******************************************************************
export const AppConfig = {
  News: 'news',
} as const;

export type ArticlesConfigT = {
  articles: Array<ArticleId>;
};
export type NewsConfigT = {
  news: ArticlesConfigT;
};
export type AppConfigT = object & NewsConfigT;
export type AppConfigResponseT = {
  config: AppConfigT;
  status: StatusT;
};
// #endregion

// #region APP TYPES *********************************************************************
export type PropsWithChildren<P = unknown> = P & { children?: React.ReactNode | undefined };
export type MaybeType<T> = T | undefined;
export interface Dictionary<T> {
  [key: string]: T;
}

export type VoidFunc = () => void;

export type RefType<T> = React.MutableRefObject<T | null>;

export type AppVersionId = string;

/** **`VisualStateT`** - visual state of the given object on screen */
export const VisualState = {
  Active: 'active',
  Awaiting: 'awaiting',
  Locked: 'locked',
  Completed: 'completed',
  IsSelected: 'is-selected',
  NotSelected: 'not-selected',
  Presentation: 'presentation',
} as const;
export type VisualStateT = (typeof VisualState)[keyof typeof VisualState];

export type GameState = 'init' | 'playing' | 'paused' | 'wrong-answer' | 'over';

export const Status = {
  Idle: 'idle',
  Loading: 'loading',
  Loaded: 'loaded',
  Updating: 'updating',
  Updated: 'updated',
  Creating: 'creating',
  Created: 'created',
  Error: 'error',
} as const;
export type StatusT = (typeof Status)[keyof typeof Status];

export const AppStatus = {
  Online: 'online',
  Offline: 'offline',
  Unknown: 'unknown',
} as const;
export type AppStatusT = (typeof AppStatus)[keyof typeof AppStatus];

export type DemoBaseProps = {
  open?: boolean;
  onDemoOpen?: (demoId: string) => void;
};

export type BasicCardT<T> = {
  [key: string]: T;
};

export const AppNotification = {
  Success: 'success',
  Error: 'error',
  Info: 'info',
  Warning: 'warning',
};
export type AppNotificationT = (typeof AppNotification)[keyof typeof AppNotification];
export type ToastNotificationMessageT = {
  message: string;
  type: AppNotificationT;
};
export type ToastNotificationT = {
  notification: ToastNotificationMessageT;
  show: boolean;
};

export type MinMaxTuple = {
  min: number;
  max: number;
};

export const MathOperator = {
  Question: 'question_mark',
  LessThan: 'chevron_left',
  GreaterThan: 'chevron_right',
  Equal: 'equal',
} as const;

export type MathOperatorT = (typeof MathOperator)[keyof typeof MathOperator];

export type ConstantsMap = {
  MathOperator: MathOperatorT;
};

export type NavType = 'App' | 'School' | 'User';
// #endregion

// #region APP CAREER TYPES **************************************************************
export type CreateT = {
  createdBy: string;
  createdOn: number;
};

export type ModifyT = {
  modifiedBy?: string;
  modifiedOn?: number;
};

export type CreateModifyT = CreateT & ModifyT;

export const CareerItem = {
  Tutorial: 'tutorial',
  Quiz: 'quiz',
  Lesson: 'lesson',
  Career: 'career',
  Question: 'question',
  Course: 'course',
  Game: 'game',
  Unlock: 'unlock',
  User: 'user',
} as const;
export type CareerItemT = (typeof CareerItem)[keyof typeof CareerItem];
export type CrestItemT = CareerItemT | 'empty';

export const Arithmetic = {
  Count: 'count',
  Compare: 'compare',
  Multiply: 'multiply',
  Subtract: 'subtract',
  Divide: 'divide',
  Add: 'add',
  Mix: 'mix',
  Unknown: 'unknown',
  All: 'all',
} as const;
export type ArithmeticT = (typeof Arithmetic)[keyof typeof Arithmetic];

/**  Meant to be passed via submit() call to the Router's Action function, as form data  */
export type SubmitActionData = {
  /** Id of the item being completed */
  id: string;
  /** Title of the item being submitted  */
  name: string;
  /** Career | Course | Lesson | Quiz | Tutorial | Question */
  type: string;
  /** unique string to use in complete collection when item has been completed */
  completionId: string;
  /** Action URL where to submit the data */
  actionUrl: string;
  /** Simple Action URL where to submit the data */
  simpleActionUrl: string;
  /** Optional - use for category type, or level */
  value?: string;
  /** Used in career mode - id of the career this item belongs to */
  careerId?: string;
  /** Used in career mode - id of the course this item belongs to */
  courseId?: string;
  /** Used in career mode - id of the lesson this item belongs to */
  lessonId?: string;
  /** Used in career mode - when used, leave quizId and questionId empty */
  tutorialId?: string;
  /** Used in career mode - when used, leave tutorialId and questionId empty */
  quizId?: string;
  /** Used in career mode - when used, leave only tutorialId empty */
  questionId?: string;
  /** User in career mode - version id of the item being stored */
  versionId?: string;
  /** Used in career mode - difficulty level */
  level?: string;
  /** Used in career mode - index in the current career chain */
  chainIndex?: string;
  /** User in career mode - time in ms to solve the question */
  timeToSolve?: string;
};
// #endregion

// #region APP CAREER MANAGEMENT TYPES ***************************************************
export const ManageMode = {
  Edit: 'edit',
  Create: 'create',
  Add: 'add',
} as const;
export type ManageModeT = (typeof ManageMode)[keyof typeof ManageMode];

// template {T}:{VersionId}
// where `T` is one of `QuestionId`, `QuizId`, `TutorialId`, `LessonId`, `CourseId`
export type PublicEntityId = string;
export type PublicEntityIds = Array<PublicEntityId>;
export const PublicEntity = {
  Questions: 'questions',
  Courses: 'courses',
  Games: 'games',
} as const;
export type PublicEntityT = (typeof PublicEntity)[keyof typeof PublicEntity];

export type ManageCollectionPropsT = {
  headerTitle?: string;
  /** @default true - display an icon on the right side of the header row  */
  showHeaderActionIcon?: boolean;
  /** @optional mode can be one of `add`, `edit`, `create`, `undefined`  */
  mode?: ManageModeT;
  /** @optional {boolean} starting state of the collapsible list */
  open?: boolean;
  /** @optional {boolean} displays an copy to clipboard icon in a title column */
  showCopyToClipboard?: boolean;
};
// #endregion

// #region FILTER TYPES ******************************************************************
export type FilterCategories<T> = Array<T>;
export type BaseFiltersT = ArithmeticT | string;
export const MontessoriFilterCategories: Array<BaseFiltersT> = [
  'count',
  'compare',
  'multiply',
  'subtract',
  'divide',
  'add',
  'mix',
  'unknown',
];
// #endregion

// #region UI/VISUALS TYPES **************************************************************
export type UIState = {
  /** Current UI state of the item */
  visualState: VisualStateT;
  /** Current Router supported URL to this item */
  toUrl?: To;
  /** Value that will be written to the `Firestore` db when UI state of the item changes to completed */
  completionId?: string;
  /** Contains information about item's own state, and its connection to other items around it.
   * Additionally it is being passed via `submit form event` to the router action handler
   * Meaning all the props have to be serializable which is why all props are of type `string`
   */
  submitActionData?: SubmitActionData;
};
export type InsetTextColor = 'Alt' | 'Default' | 'Green' | 'Red';
export type FlipDirection = 'forwards' | 'backwards';

export const Animations = {
  Pass: 'pass',
  Fail: 'fail',
  None: 'none',
} as const;
export type AnimationT = (typeof Animations)[keyof typeof Animations];

export type ThicknessT = 'ultra' | 'thin' | 'med' | 'thick' | 'extra';
export type ProgressT = 'active' | 'started' | 'finished' | 'completed';
export type LineHeightT =
  | 'line1'
  | 'line11'
  | 'line12'
  | 'line13'
  | 'line14'
  | 'line15'
  | 'line16'
  | 'line17'
  | 'line18'
  | 'line19'
  | 'line2';
export type BoldWeightT =
  | 'bold900'
  | 'bold800'
  | 'bold700'
  | 'bold600'
  | 'bold500'
  | 'bold400'
  | 'bold300'
  | 'bold200'
  | 'bold100';

export type ElementSizeT =
  | 'xs5'
  | 'xs6'
  | 'xs75'
  | 'xs9'
  | 'xs1'
  | 'xs11'
  | 'xs12'
  | 'xs145'
  | 'xs15'
  | 'xxs'
  | 'xsm'
  | 'sms'
  | 'sml'
  | 'sma'
  | 'sam'
  | 'med'
  | 'mel'
  | 'mlg'
  | 'lge'
  | 'lg35'
  | 'xtl'
  | 'xxl'
  | 'xl3'
  | 'xl35'
  | 'xl4'
  | 'xl5'
  | 'xl6'
  | 'xl7'
  | 'xl8'
  | 'xl9'
  | 'xl10'
  | 'xl15'
  | 'xl20';

export const AvatarIconsSubset = {
  SentimentVerySatisfied: 'sentiment_very_satisfied',
  SentimentExcited: 'sentiment_excited',
  SentimentSatisfied: 'sentiment_satisfied',
};

export const AvatarIcons = {
  SentimentVerySatisfied: 'sentiment_very_satisfied',
  Face2: 'face_2',
  SentimentExcited: 'sentiment_excited',
  Face: 'face',
  Mood: 'mood',
  Person3: 'person_3',
  Face3: 'face_3',
  Person2: 'person_2',
  Face6: 'face_6',
  Person4: 'person_4',
  Face4: 'face_4',
  SentimentSatisfied: 'sentiment_satisfied',
};
export type AvatarIconsT = (typeof AvatarIcons)[keyof typeof AvatarIcons];

// #endregion

// #region COMPONENTS ********************************************************************
export type IconProps = {
  icon: string;
  id?: string;
  className?: string;
  weight?: BoldWeightT;
  lineHeight?: LineHeightT;
  size?: ElementSizeT;
  isDanger?: boolean;
  isSuccess?: boolean;
  mainColor?: boolean;
  isInfo?: boolean;
  isUnselected?: boolean;
  isFilled?: boolean;
  isDisabled?: boolean;
  showPointerCursor?: boolean;
} & Omit<React.HTMLAttributes<HTMLSpanElement>, 'onAnimationEnd'>;

export type MeIconProps = {
  verticalPosition?: string;
  insetIcon?: boolean;
  insetCls?: string;
} & IconProps;

export type InsetIconProps = {
  size: ElementSizeT;
  color?: InsetTextColor;
  data?: string;
  wrapperCls?: string;
} & IconProps;

export type InsetTextProps = {
  text?: string;
  className?: string;
  size?: ElementSizeT;
  weight?: BoldWeightT;
  color?: InsetTextColor;
  capitalize?: boolean;
  uppercase?: boolean;
  nowrap?: boolean;
  data?: string;
} & Pick<React.HTMLAttributes<HTMLHeadingElement>, 'onClick' | 'style' | 'id'>;

export const Fruit = {
  Tomato: 'tomato',
  Watermelon: 'watermelon',
  Orange: 'orange',
  Apple: 'apple',
  Lemon: 'lemon',
} as const;
export type FruitT = (typeof Fruit)[keyof typeof Fruit];

export type TabItemT = {
  tabId?: string;
  tabName: string;
  visible?: boolean;
  enabled?: boolean;
  selected?: boolean;
  visited?: boolean;
  complete?: boolean;
};

// #region SELECT DROP DOWN TYPES ********************************************************
export type SelectedItemT = MaybeType<SelectDropdownItemT>;
export type SelectDropdownItemT = {
  value: string;
  name: string;
};
// #endregion

type TabScreenOwnProps = {
  screenId: string;
  className?: string;
  onNextClick?: VoidFunc;
  onPrevClick?: VoidFunc;
  page: React.FC;
};
export type TabScreenProps = PropsWithChildren<TabScreenOwnProps>;
// #endregion

// #region AWARDS ************************************************************************
export const AwardKind = {
  Start: 'Start',
  Finish: 'Finish',
  Action: 'Action',
  Discovery: 'Discovery',
  Attendance: 'Attendance',
  General: 'General',
  Special: 'Special',
  Medal: 'Medal',
} as const;
export type AwardKindT = (typeof AwardKind)[keyof typeof AwardKind];
export type AwardId = string;
export type MedalId = string;
export type AwardCode = string;
export type MedalCode = string;
export type AwardCodes = Array<AwardCode>;
export type AwardStars = { codes: AwardCodes };
export type AwardMedals = { awarded: MedalsT };
export type BaseAwardT = {
  title: string;
  awardKind: AwardKindT;
  description?: string;
} & CreateModifyT;

export type AwardT = {
  awardId: AwardId;
  code: AwardCode;
} & BaseAwardT;

export type MedalT = {
  medalId: MedalId;
  code: MedalCode;
  medalUrl: string;
  largeMedalUrl: string;
  largeMedalBackUrl: string;
} & BaseAwardT;

export type UserAwardT = {
  awardedOn: number;
  recipientUid: string;
  recipientProfileId: string;
} & AwardT;

export type AwardUpdateResponseT = {
  award: AwardT;
  index: number;
};

export type ManageAwardUpdateResponseT = {
  award: AwardT;
  index: number;
};

export type ManageMedalUpdateResponseT = {
  medal: MedalT;
  index: number;
};

export type GameUpdateResponseT = {
  game: GameT;
  index: number;
  gameQuestions: GameQuestionsT;
};

export type GameQuestionUpdateResponseT = {
  gameQuestion: GameQuestionT;
  game: GameT;
  gameIndex: number;
  gameQuestionIndex: number;
};

export type ManageGameUpdateResponseT = {
  game: GameT;
  index: number;
};

export type ManageUnlockUpdateResponseT = {
  unlock: UnlockT;
  index: number;
};

export type ManageNewsArticleUpdateResponseT = {
  newsArticle: NewsArticle;
  index: number;
};

export type AwardsT = Array<AwardT>;
export type MedalsT = Array<MedalT>;
export type UserAwardsT = Array<UserAwardT>;
export type AwardCodesT = Array<AwardCode>;
export type MedalCodesT = Array<MedalCode>;
export interface AwardCodesDictionary<T> {
  [key: AwardCode]: T;
}
export type AwardCodesMap = AwardCodesDictionary<string>;
export type AwardDictionaryT = AwardCodesDictionary<AwardT>;

export type StorageItemsT = Array<IStorageItem>;
export interface IStorageItem {
  url?: string;
  urlRef?: string;
  isFolder?: boolean;
  extension?: string;
  selected?: boolean;
  name: string;
}
// #endregion

export const OPERATOR_SIGN = {
  plus: '+',
  minus: '−',
  multiply: '×',
  divide: '÷',
  compare: '≆',
  amount: '±',
  unknown: '?',
  mix: '⊌,',
  all: '∀',
  greaterthan: '>',
  lessthan: '<',
  equal: '=',
} as const;
export type OperatorSignT = (typeof OPERATOR_SIGN)[keyof typeof OPERATOR_SIGN];

// #region DEBUG / LOG ******************************************************************
export type CaptureInfoT = {
  status?: string;
  time?: string;
  location?: string;
  activeScene?: string;
  email?: string;
  name?: string;
  uid?: string;
  activeProfile?: string;
  profilesCount?: string;
  admin?: string;
  editor?: string;
};

export type ErrorLogT = {
  errorId?: string;
  errorName?: string;
  errorType?: string;
  cause?: string;
  message?: string;
  code?: string;
  stack?: string;
  fromRoute?: string;
} & CaptureInfoT;
// #endregion

// #region LEGAL ************************************************************************
export type SubpageT = {
  title: string;
  shortTitle: string;
  url: string;
  icon: string;
};

export type LegalSubpageT = {
  subpageId: string;
  subpage?: JSX.Element;
} & SubpageT;

export type SubpagesT = Array<SubpageT>;

export type LegalInfoT = {
  title: string;
  subpages: SubpagesT;
};

export type LegalPagesProps = {
  className?: string;
};

// #endregion

// #region MESSAGE **********************************************************************
export type MessageId = string;

export type MessageT = {
  messageId: MessageId;
  messageTitle: string;
  messageBody: string;
} & CaptureInfoT &
  CreateModifyT;

// #endregion
