import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PropsWithChildren } from 'types/App.types';

import styles from './List.module.scss';
import CopyToClipboard from 'components/copytoclipboard/CopyToClipboard';

type CellSizeT = 'quarter' | 'third' | 'half' | 'one' | 'onalf' | 'double';
type CellAlignT = 'left' | 'center' | 'right';

export type ListBaseProps = {
  className?: string;
  originTransform?: 'transform-top' | 'transform-bottom';
  originPosition?: 'pos-bottom' | 'pos-top';
  bgHighlight?: boolean;
  navigateTo?: string;
} & Pick<React.HTMLAttributes<HTMLDivElement>, 'style'>;

type ListTitleProps = {
  listTitle?: string;
  onHandleClicked?: () => void;
  handleIcon?: string;
} & ListBaseProps;

type ListProps = {
  show?: boolean;
} & ListBaseProps;

type ListRowProps = ListBaseProps & React.HTMLAttributes<HTMLDivElement>;

type ListCellProps = {
  isSelected?: boolean;
  isActionCell?: boolean;
  cellSize?: CellSizeT;
  cellAlign?: CellAlignT;
  copyToClipboardText?: string;
} & ListBaseProps &
  React.HTMLAttributes<HTMLDivElement>;

type ListLinkCellProps = {
  url: string;
} & ListCellProps;

const ListTitle: React.FC<PropsWithChildren<ListTitleProps>> = (props) => {
  const baseCls = styles.ListTitle;
  const titleCls = props.className ? `${baseCls} ${props.className}` : baseCls;
  return (
    <div style={props.style} className={titleCls}>
      {props.children}
    </div>
  );
};

const ListHeader: React.FC<PropsWithChildren<ListBaseProps>> = (props) => {
  const baseCls = styles.ListRowHeader;
  const headerCls = props.className ? `${baseCls} ${props.className}` : baseCls;
  return <div className={headerCls}>{props.children}</div>;
};

const ListRow: React.FC<PropsWithChildren<ListRowProps>> = ({
  className = '',
  navigateTo,
  bgHighlight = false,
  ...props
}) => {
  const navigate = useNavigate();
  const bgHighlightCls = bgHighlight ? styles.HighlightRow : '';
  const rowCls = `${styles.ListRow} ${className}`;

  const onClickFn = navigateTo ? () => navigate(String(navigateTo)) : props.onClick;

  return (
    <div
      onClick={onClickFn}
      aria-disabled={!navigateTo && !props.onClick}
      {...props}
      className={`${rowCls} ${bgHighlightCls}`}>
      {props.children}
    </div>
  );
};

const ListCell: React.FC<PropsWithChildren<ListCellProps>> = ({
  cellSize = 'one',
  cellAlign = 'left',
  copyToClipboardText = '',
  isActionCell,
  ...props
}) => {
  const actionCls = isActionCell ? styles.ListActionCell : '';
  const selectedCls = props.isSelected ? styles.Selected : '';
  const baseCls = props.className ? `${styles.ListCell} ${props.className}` : styles.ListCell;
  const copyTextCls = copyToClipboardText ? styles.CopyText : '';
  const cellCls = `${baseCls} ${actionCls} ${selectedCls} ${cellSize} ${cellAlign} ${copyTextCls}`;
  return (
    <div className={cellCls} {...props} onClick={props.onClick}>
      {props.children}
      {copyToClipboardText && (
        <CopyToClipboard textToCopy={copyToClipboardText} className={styles.CopyTextClipboardIcon} />
      )}
    </div>
  );
};

const ListLinkCell: React.FC<PropsWithChildren<ListLinkCellProps>> = ({
  cellSize = 'one',
  cellAlign = 'left',
  ...props
}) => {
  const actionCls = props.isActionCell ? styles.ListActionCell : '';
  const selectedCls = props.isSelected ? styles.Selected : '';
  const baseCls = props.className ? `${styles.ListCell} ${props.className}` : styles.ListCell;
  const cellCls = `${baseCls} ${actionCls} ${selectedCls} ${cellSize} ${cellAlign}`;
  return (
    <Link to={props.url} className={cellCls}>
      {props.children}
    </Link>
  );
};

const List: React.FC<PropsWithChildren<ListProps>> = ({ ...props }) => {
  const [popupCls, setPopupCls] = useState<string>('hide');
  const baseCls = `${props.className ?? ''} ${styles.List}`;

  useEffect(() => {
    setPopupCls(props.show ? 'show' : 'hide');
  }, [props.show]);

  return <div className={`${baseCls} ${popupCls}`}>{props.children}</div>;
};

type ListTwoLineCellOwnProps = {
  className?: string;
};

export type ListTwoLineCellProps = PropsWithChildren<ListTwoLineCellOwnProps>;

const ListTwoLineCell: React.FC<ListTwoLineCellProps> = (props) => {
  return <div className={styles.ListTwoLineCell}>{props.children}</div>;
};

export { List as default, ListTitle, ListHeader, ListRow, ListCell, ListLinkCell, ListTwoLineCell };
