import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useFetcher } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks/hooks';
import { usePassKey } from 'app/hooks/usePassKey';
import { analytics, logEvent } from '../../../firebase/Firebase';

import { Status } from 'types/App.types';
import { ProfileId } from 'types/User.types';

import { AwardCodes } from 'app/constants/AwardCodes';
import { SpotlightEls } from 'app/constants/SpotlightElements';
import { useStyledLogger } from 'app/config/LogConfig';
import { ButtonLabel, ComponentTitle, HeaderTitle } from 'app/constants/Localization';
import { AppIcons } from 'app/constants/Constants';
import { isPrint } from 'helpers/appHelpers';

import AwardsDisplay from 'components/awardsdisplay/AwardsDisplay';
import MeIcon from 'components/icon/meicon/MeIcon';
import MeIconRaised from 'components/icon/meiconraised/MeIconRaised';
import RoundedButton from 'components/buttons/rounded/RoundedButton';
import Rule from 'components/rule/Rule';
import SceneLoader from 'components/loader/SceneLoader';
import TitleHeader from 'components/titleheader/TitleHeader';

import { getEditCareerCoursesUrl, getSimpleCareerUrl, getViewCareerBadgesUrl } from 'modules/career/careerSlice';
import {
  getUserLevel,
  getUserProfileIds,
  getUserProfiles,
  isUserAdmin,
  isUserPassKeyEnabled,
} from 'modules/user/userSlice';
import { receiveAward } from 'modules/profiles/awards/awardsSlice';
import { setCareerCarouselDisplayMode } from 'modules/career/careerUiSlice';
import { setShowCareerHubHelpPanel } from 'scenes/montessori/appUiSlice';

import BadgesProgress from 'modules/career/hub/components/badgesprogress/BadgesProgress';
import CareerHubHelpPanel from 'modules/tutorial/help/scenes/careerhub/CareerHubHelpPanel';
import CareerHubRow from 'modules/career/hub/components/careerhubrow/CareerHubRow';
import CoursesProgress from 'modules/career/hub/components/coursesprogress/CoursesProgress';
import SwitchProfilesPopup from 'modules/profiles/SwitchProfilesPopup';
import TotalProgress from 'modules/career/hub/components/totalprogress/TotalProgress';
import MedalViewer from 'components/medal/viewer/MedalViewer';

import styles from './CareerHub.module.scss';

type CareerHubProps = {
  className?: string;
};

const PRINT_FLAG = true;

export const CareerHub: React.FC<CareerHubProps> = () => {
  const fetcherStateRef = useRef<'idle' | 'loading' | 'submitting'>('idle');
  const fetcherProfileIdSwitchRef = useRef<ProfileId>('');
  const printMsg = useStyledLogger('CareerHub', 'brown', 'cornsilk');

  const isAdmin = useAppSelector(isUserAdmin);
  const userLevel = useAppSelector(getUserLevel);
  const isPassKeyEnabled = useAppSelector(isUserPassKeyEnabled);
  const userProfileIds = useAppSelector(getUserProfileIds);
  const profiles = useAppSelector(getUserProfiles);
  const badgesUrl = useAppSelector(getViewCareerBadgesUrl);
  const toSimpleCareerUrl = useAppSelector(getSimpleCareerUrl);
  const toEditCoursesUrl = useAppSelector(getEditCareerCoursesUrl);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const {
    PassKeyPopup,
    isStudentPassKeyEnabled,
    validateStudentPin,
    setStudentProfileId,
    studentPinValidated,
    setStudentName,
    closeButtonVisible,
  } = usePassKey(true);

  const dispatch = useAppDispatch();

  const fetcher = useFetcher();
  // #region MEMOS & USE EFFECTS ********************************************************
  const isFetcherIdleAndEmpty = useMemo(() => {
    return fetcher.state === Status.Idle && !fetcher.data;
  }, [fetcher]);
  const isFetcherLoading = useMemo(() => {
    return fetcher && fetcher.state === Status.Loading;
  }, [fetcher]);
  const isFetcherLoaded = useMemo(() => {
    return fetcher && !!fetcher.data && fetcher.state === Status.Idle;
  }, [fetcher]);
  const isFetcherCareerExists = useMemo(() => {
    return fetcher && !!fetcher.data && !!fetcher.data.career && !!fetcher.data.career.careerId;
  }, [fetcher]);
  const isFetcherLoadedCareer = useMemo(() => {
    return isFetcherLoaded && isFetcherCareerExists;
  }, [isFetcherCareerExists, isFetcherLoaded]);
  const hasFetcherCompletedPatchAction = useMemo(() => {
    return fetcher.state === Status.Idle && fetcherStateRef.current === 'submitting' && fetcher.data.shouldRevalidate;
  }, [fetcher]);

  useEffect(() => {
    if (isFetcherIdleAndEmpty) {
      isPrint(PRINT_FLAG) && console.log(...printMsg('Fetcher LOADING CareerHub', 'hub', '/hub'));
      fetcherStateRef.current = Status.Loading;
      fetcher.load('/hub');
    }
    return () => {
      fetcher.data = null;
    };
  }, [isFetcherIdleAndEmpty]);

  useEffect(() => {
    if (hasFetcherCompletedPatchAction) {
      isPrint(PRINT_FLAG) && console.log(...printMsg('Fetcher REFRESHING CareerHub', 'hub', '/hub'));
      fetcherStateRef.current = Status.Idle;
      fetcher.load('/hub');
    } else if (isFetcherLoadedCareer) {
      isPrint(PRINT_FLAG) && console.log(...printMsg('Fetcher LOADED CareerHub', 'hub', '/hub'));
      fetcherStateRef.current = Status.Idle;
      dispatch(receiveAward(AwardCodes.Code0000));
    }
  }, [hasFetcherCompletedPatchAction, isFetcherLoadedCareer]);
  // #endregion

  // #region HANDLERS  ******************************************************************
  const handleCloseSwitchProfilePopup = () => setShowPopup(false);

  const handleOpenSwitchProfile = () => setShowPopup(true);

  const handleShowHelpPanel = () => dispatch(setShowCareerHubHelpPanel(true));

  const doSwitchProfiles = (profileId: ProfileId) => {
    fetcherProfileIdSwitchRef.current = '';
    fetcher.submit({ profileId: profileId }, { action: '/hub', method: 'PATCH' });
    fetcherStateRef.current = 'submitting';
    handleCloseSwitchProfilePopup();
    closeButtonVisible(false);
  };

  const switchProfiles = (profileId: ProfileId) => {
    if (isPassKeyEnabled && isStudentPassKeyEnabled(profileId)) {
      fetcherProfileIdSwitchRef.current = profileId;
      closeButtonVisible(true);
      setStudentProfileId(profileId);

      const profile = profiles.find((p) => p.profileId === profileId);
      if (profile) {
        setStudentName(profile.title);
      }
      validateStudentPin();
    } else {
      doSwitchProfiles(profileId);
    }
  };

  useEffect(() => {
    const studentProfileId = fetcherProfileIdSwitchRef.current;
    if (studentPinValidated && studentProfileId.length > 0) doSwitchProfiles(studentProfileId);
  }, [studentPinValidated]);

  const createViewAwardsAnalyticEvent = () => {
    logEvent(analytics, 'page_view', { page_location: badgesUrl, page_title: 'Badge Gallery' });
  };
  // #endregion

  return (
    <div className={styles.CareerHub}>
      <TitleHeader className={styles.FillWidth} icon={AppIcons.Career.Career} ruleSize="thin" isFilledIcon>
        {HeaderTitle.Career}
        <MeIcon icon="help" showPointerCursor insetIcon onClick={handleShowHelpPanel} />
        {isFetcherCareerExists && <MedalViewer medals={fetcher.data.career.awardMedals} />}
        <AwardsDisplay />
      </TitleHeader>

      {isFetcherLoading && <SceneLoader animate />}
      {isFetcherLoaded && fetcher.data.profile && fetcher.data.career && (
        <>
          <div className={styles.CareerHubContainer}>
            <CareerHubRow
              title={ComponentTitle.ActiveProfile}
              icon={fetcher.data.profile.icon}
              value={fetcher.data.profile.title}
              showButton={userProfileIds.length > 1}
              onClick={handleOpenSwitchProfile}
            />
            <div className={styles.CareerHubRow}>
              <CoursesProgress
                key={'cKey'}
                className={`${styles.CareerHubRowProgress} ${SpotlightEls.CoursesProgress}`}
                career={fetcher.data.career}
              />
              <MeIconRaised
                icon="stack_star"
                className={SpotlightEls.ManageCoursesBtn}
                iconSize="mlg"
                iconWeight="bold300"
                to={toEditCoursesUrl}
                style={{ marginBottom: '0.15em' }}
              />
            </div>
            <BadgesProgress
              key={'bKey'}
              className={`${styles.CareerHubRowProgress} ${SpotlightEls.BadgeProgress}`}
              badges={fetcher.data.careerBadges}
            />
            <TotalProgress className={styles.CareerHubRowProgress} key={'pKey'} />
          </div>
          <Rule size="thin" />
          <div className={styles.ActionButtons}>
            <RoundedButton
              to={badgesUrl}
              className={SpotlightEls.AwardsBtn}
              onClick={createViewAwardsAnalyticEvent}
              fakeLoaderTimeout={1000}
              useFakeLoader={true}
              icon={AppIcons.Common.Trophy}>
              {ButtonLabel.ViewAwards}
            </RoundedButton>

            <RoundedButton
              to={toSimpleCareerUrl}
              mainColor
              onClick={() => dispatch(setCareerCarouselDisplayMode('FULL_MODE'))}
              icon={AppIcons.Common.Resume}>
              {fetcher.data.career.complete.length > 0 ? ButtonLabel.ResumeCareer : ButtonLabel.StartCareer}
            </RoundedButton>
          </div>

          <SwitchProfilesPopup
            showPopup={showPopup}
            handleOnCloseEvent={handleCloseSwitchProfilePopup}
            handleClosePopup={handleCloseSwitchProfilePopup}
            switchProfiles={switchProfiles}
          />

          {(isAdmin || userLevel === 1) && PassKeyPopup}

          <CareerHubHelpPanel />
        </>
      )}
    </div>
  );
};

export default CareerHub;
