import React, {
  RefAttributes,
  useEffect,
  useRef,
  useState,
  MutableRefObject,
  MouseEvent,
  TransitionEvent,
} from 'react';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { PropsWithChildren } from 'types/App.types';
import { useScrollManager } from 'app/hooks/useScrollManager';

import InsetText from 'components/text/inset/InsetText';
import MeIcon from 'components/icon/meicon/MeIcon';
import Rule from 'components/rule/Rule';
import XCloseButton from 'components/buttons/xclosebutton/XCloseButton';

import styles from './Popup.module.scss';

type OwnProps = {
  id?: string;
  className?: string;
  bodyClassName?: string;
  headerClassName?: string;
  show?: boolean;
  onCloseEvent?: () => void;
  onOpenEvent?: () => void;
  headerTitle?: string;
  popupType?: 'wood' | 'glass' | 'none';
  useTransparentOverlay?: boolean;
  closeOnClickOutside?: boolean;
  showCloseButton?: boolean;
  showHeaderRule?: boolean;
  blocking?: boolean;
  /** Display back arrow in the header section and navigate users back to where they came from */
  showBackButton?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export type PopupProps = PropsWithChildren<OwnProps> & RefAttributes<HTMLDivElement>;

const portalContainer: HTMLElement | null = document.getElementById('portalContainer');

if (portalContainer === null) {
  throw Error('portalContainer not found');
}

const Popup: React.FC<PopupProps> = React.forwardRef<HTMLDivElement, PopupProps>(
  (
    {
      className = '',
      bodyClassName = '',
      headerClassName = '',
      headerTitle = '',
      popupType = 'glass',
      show = false,
      onCloseEvent = () => undefined,
      onOpenEvent = () => undefined,
      useTransparentOverlay = false,
      closeOnClickOutside = true,
      showCloseButton = true,
      showHeaderRule = false,
      blocking = false,
      showBackButton = false,
      ...props
    },
    ref
  ) => {
    const overlayRef = useRef(null);
    const PopupBodyRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
    const overlayBg = useTransparentOverlay ? 'transparentOverlay' : '';
    const { disableScroll, enableScroll } = useScrollManager();
    const [popupCls, setPopupCls] = useState<string>('');
    const [baseCls, setBaseCls] = useState<string>('');
    const [onPopupOpen, setOnPopupOpen] = useState<boolean>(false);
    const popupStateZ = blocking ? styles.BlockingZCls : '';
    const navigate = useNavigate();

    // #region USE_EFFECTS
    useEffect(() => {
      return () => enableScroll();
    }, []);

    useEffect(() => {
      setBaseCls(`${styles.Popup} ${popupType === 'glass' ? styles.Glass : popupType === 'wood' ? styles.Wood : ''}`);
    }, [popupType]);

    useEffect(() => {
      if (onPopupOpen && onOpenEvent) {
        onOpenEvent();
      }
    }, [onPopupOpen]);

    useEffect(() => {
      if (show) {
        disableScroll();
        setPopupCls('show');
      } else {
        enableScroll();
        setPopupCls('hide');
      }
    }, [show]);
    // #endregion

    const onCloseAction = (e: MouseEvent) => {
      onCloseEvent();
      e.stopPropagation();
      e.preventDefault();
    };

    const isRefContainsTarget = (target: HTMLDivElement, ref?: MutableRefObject<HTMLDivElement>) => {
      return ref && ref.current.contains(target);
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (!closeOnClickOutside) return;

      if (
        !ref &&
        overlayRef &&
        overlayRef.current &&
        overlayRef.current === event.currentTarget &&
        overlayRef.current === event.target
      ) {
        onCloseEvent();
      }

      if (!isRefContainsTarget(event.target as HTMLDivElement, ref as MutableRefObject<HTMLDivElement>)) {
        onCloseEvent();
      }
      event.stopPropagation();
      event.preventDefault();
    };

    const onTransitionOver = (e: TransitionEvent) => {
      if (popupCls === 'show' && e.propertyName === 'opacity') {
        setOnPopupOpen(true);
      } else if (popupCls === 'hide' && e.propertyName === 'transform') {
        setOnPopupOpen(false);
      }
    };

    return (
      <React.Fragment>
        {createPortal(
          <div
            ref={overlayRef}
            onClick={handleClickOutside}
            onTransitionEnd={onTransitionOver}
            className={`${styles.UnderlayWrapper} ${popupCls} ${overlayBg} ${popupStateZ}`}>
            {popupCls !== '' && (
              <div id={props.id} ref={ref} {...props} className={`${baseCls} ${popupCls} ${className}`}>
                <div className={`${styles.PopupHeader} ${headerClassName}`}>
                  {showBackButton && (
                    <MeIcon
                      style={{ lineHeight: 1 }}
                      insetCls={styles.PopupHeaderBackButton}
                      icon="keyboard_backspace"
                      insetIcon
                      showPointerCursor
                      size="xtl"
                      weight="bold300"
                      onClick={() => navigate(-1)}
                    />
                  )}
                  <InsetText size="lge" text={headerTitle} className={styles.PopupHeaderText} capitalize />
                  {showCloseButton && <XCloseButton size="sam" onClick={onCloseAction} />}
                </div>
                {showHeaderRule && <Rule size="med" zeroMargin style={{ margin: '0 1em' }} />}
                <PopupBody ref={PopupBodyRef} className={bodyClassName}>
                  {props.children}
                </PopupBody>
              </div>
            )}
          </div>,
          portalContainer
        )}
      </React.Fragment>
    );
  }
);

type PopupBodyOwnProps = {
  className?: string;
};
type PopupBodyProps = PropsWithChildren<PopupBodyOwnProps> & RefAttributes<HTMLDivElement>;

const PopupBody: React.FC<PopupBodyProps> = React.forwardRef<HTMLDivElement, PopupBodyProps>(
  ({ className = '', ...props }, ref) => {
    const [baseCls, setBaseCls] = useState<string>('');

    useEffect(() => {
      setBaseCls(`${styles.PopupBody} ${className}`);
    }, [className]);

    return (
      <div ref={ref} className={baseCls}>
        {props.children}
      </div>
    );
  }
);

export { Popup as default, PopupBody };
