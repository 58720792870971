import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import {
  Animations,
  AnimationT,
  AppNotification,
  MaybeType,
  ToastNotificationMessageT,
  ToastNotificationT,
} from 'types/App.types';

export type PassKeyInternalState = 'verify_passkey_pin' | 'set_passkey_pin';

export type PassKeyUiState = {
  state: PassKeyInternalState;
  animationState: AnimationT;
  passKeyPanelVisible: boolean;
};

export interface UserUiState {
  showLoginForm: boolean;
  loginFormError: string;
  createAccountFormError: string;
  editAccountFormError: string;
  resetPasswordFormError: string;
  showResetPasswordForm: boolean;
  showCreateAccountForm: boolean;
  showEditAccountForm: boolean;
  showToast: boolean;
  toastMessage?: ToastNotificationMessageT;
  passKey: PassKeyUiState;
}

const initialState: UserUiState = {
  showLoginForm: false,
  loginFormError: '',
  createAccountFormError: '',
  editAccountFormError: '',
  resetPasswordFormError: '',
  showResetPasswordForm: false,
  showCreateAccountForm: false,
  showEditAccountForm: false,
  showToast: false,
  toastMessage: { message: 'Welcome to Montessori', type: AppNotification.Success },
  passKey: {
    state: 'verify_passkey_pin',
    passKeyPanelVisible: false,
    animationState: Animations.None,
  },
};

export const userUiSlice = createSlice({
  name: 'userUi',
  initialState,
  reducers: {
    setShowLoginForm: (state, action: PayloadAction<boolean>) => {
      state.showLoginForm = action.payload;
    },
    setShowResetPasswordForm: (state, action: PayloadAction<boolean>) => {
      state.showResetPasswordForm = action.payload;
    },
    setShowCreateAccountForm: (state, action: PayloadAction<boolean>) => {
      state.showCreateAccountForm = action.payload;
    },
    setShowEditAccountForm: (state, action: PayloadAction<boolean>) => {
      state.showEditAccountForm = action.payload;
    },
    setLoginFormError: (state, action: PayloadAction<string>) => {
      state.loginFormError = action.payload;
    },
    setCreateAccountFormError: (state, action: PayloadAction<string>) => {
      state.createAccountFormError = action.payload;
    },
    setEditAccountFormError: (state, action: PayloadAction<string>) => {
      state.editAccountFormError = action.payload;
    },
    setResetPasswordFormError: (state, action: PayloadAction<string>) => {
      state.resetPasswordFormError = action.payload;
    },
    setShowToast: (state, action: PayloadAction<boolean>) => {
      state.showToast = action.payload;
    },
    setToastMessage: (state, action: PayloadAction<ToastNotificationMessageT>) => {
      state.toastMessage = action.payload;
    },
    setToastNotification: (state, action: PayloadAction<ToastNotificationT>) => {
      state.toastMessage = action.payload.notification;
      state.showToast = action.payload.show;
    },
    setPassKeyUiState: (state, action: PayloadAction<PassKeyUiState>) => {
      state.passKey = action.payload;
    },
    setPassKeyPanelAnimation: (state, action: PayloadAction<AnimationT>) => {
      state.passKey.animationState = action.payload;
    },
    setPassKeyPanelVisible: (state, action: PayloadAction<boolean>) => {
      state.passKey.passKeyPanelVisible = action.payload;
    },
    setPassKeyPanelClosed: (state) => {
      state.passKey = { ...initialState.passKey };
    },
    resetState: () => {
      return { ...initialState };
    },
  },
});

export const {
  setShowCreateAccountForm,
  setShowResetPasswordForm,
  setShowLoginForm,
  setShowEditAccountForm,
  setShowToast,
  setLoginFormError,
  setCreateAccountFormError,
  setEditAccountFormError,
  setResetPasswordFormError,
  setToastMessage,
  setToastNotification,
  setPassKeyUiState,
  setPassKeyPanelAnimation,
  setPassKeyPanelClosed,
  setPassKeyPanelVisible,
  resetState,
} = userUiSlice.actions;

export const showCreateAccountForm = (state: RootState): boolean => state.ui.userUi.showCreateAccountForm;
export const showResetPasswordForm = (state: RootState): boolean => state.ui.userUi.showResetPasswordForm;
export const showEditAccountForm = (state: RootState): boolean => state.ui.userUi.showEditAccountForm;
export const showToastNotification = (state: RootState): boolean => state.ui.userUi.showToast;
export const showLoginForm = (state: RootState): boolean => state.ui.userUi.showLoginForm;
export const getLoginFormError = (state: RootState): string => state.ui.userUi.loginFormError;
export const getCreateAccountFormError = (state: RootState): string => state.ui.userUi.createAccountFormError;
export const getEditAccountFormError = (state: RootState): string => state.ui.userUi.editAccountFormError;
export const getResetPasswordFormError = (state: RootState): string => state.ui.userUi.resetPasswordFormError;
export const getToastMessage = (state: RootState): MaybeType<ToastNotificationMessageT> => state.ui.userUi.toastMessage;
export const getPassKeyUiState = (state: RootState): PassKeyUiState => state.ui.userUi.passKey;
export const getPassKeyPanelState = (state: RootState): PassKeyInternalState => state.ui.userUi.passKey.state;
export const getPassKeyPanelVisible = (state: RootState): boolean => state.ui.userUi.passKey.passKeyPanelVisible;
export const getPassKeyPanelAnimationState = (state: RootState): AnimationT => state.ui.userUi.passKey.animationState;

export default userUiSlice.reducer;
