import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { getModifiedInfo } from 'helpers/careerHelpers';
import { Arithmetic } from 'types/App.types';
import { CourseT } from 'types/Course.types';

// #region     CONVERTERS ****************************************************************
export const getCreateCourse = (userId: string): CourseT => {
  const createCourse: CourseT = {
    courseId: 'createCourseId',
    courseBadgeIds: [],
    courseLevel: 1,
    courseCategory: Arithmetic.Unknown,
    courseTitle: '',
    createdOn: Date.now(),
    createdBy: userId,
    modifiedOn: Date.now(),
    modifiedBy: userId,
    versionedLessonIds: [],
    versionId: '',
  };

  return createCourse;
};

export const courseToDoc = (course: CourseT): DocumentData => {
  return {
    courseId: course.courseId,
    badgeIds: course.courseBadgeIds,
    level: course.courseLevel,
    category: course.courseCategory,
    title: course.courseTitle,
    createdOn: course.createdOn,
    createdBy: course.createdBy,
    modifiedOn: course.modifiedOn,
    modifiedBy: course.modifiedBy,
    versionedLessonIds: course.versionedLessonIds,
    versionId: course.versionId || '',
  };
};

export const courseConverter: FirestoreDataConverter<CourseT> = {
  toFirestore(course: CourseT): DocumentData {
    return { ...courseToDoc(course), ...getModifiedInfo() };
  },

  fromFirestore(docSnap: QueryDocumentSnapshot, options?: SnapshotOptions): CourseT {
    const course = docSnap.data(options);
    return {
      courseId: course.courseId || docSnap.id,
      courseBadgeIds: course.badgeIds,
      courseLevel: course.level,
      courseCategory: course.category || Arithmetic.Unknown,
      courseTitle: course.title,
      createdOn: course.createdOn,
      createdBy: course.createdBy,
      modifiedOn: course.modifiedOn,
      modifiedBy: course.modifiedBy,
      versionedLessonIds: course.versionedLessonIds || [],
      versionId: course.versionId || '',
    };
  },
};
// #endregion
