import React, { CSSProperties } from 'react';
import { OperatorSignT } from 'types/App.types';

import styles from './MathSign.module.scss';

type MathSignProps = {
  className?: string;
  sign: OperatorSignT;
  insetStyle?: boolean;
  unsetLineHeight?: boolean;
} & Pick<React.HTMLAttributes<HTMLDivElement>, 'style' | 'onClick'>;

const MathSign: React.FC<MathSignProps> = ({ className = '', ...props }) => {
  const displayCls = props.insetStyle ? styles.MathSignInsetStyle : '';
  const customStyles: CSSProperties = { lineHeight: props.unsetLineHeight ? 'unset' : '1', ...props.style };
  const isGreaterOrLessThenSign = props.sign === '>' || props.sign === '<';
  const specialCls = isGreaterOrLessThenSign ? `material-symbols-outlined ${styles.MathSignSpecial}` : '';
  const sign = !isGreaterOrLessThenSign ? props.sign : props.sign === '>' ? 'chevron_right' : 'chevron_left';

  return (
    <span className={`${styles.MathSign} ${specialCls} ${displayCls} ${className}`} style={customStyles}>
      {sign}
    </span>
  );
};

export default MathSign;
