import { SceneT } from 'scenes/scenesSlice';
import { VersionId } from 'types/BaseCareer.types';

export const getEnv = (): string => process.env.NODE_ENV;
export const isDevEnv = (): boolean => getEnv() === 'development';
export const isProdEnv = (): boolean => getEnv() === 'production';
export const isTestEnv = (): boolean => getEnv() === 'test';

export const isPrint = (flag?: boolean): boolean => isDevEnv() && flag !== false;

export const queryAppVersionId = (): string => {
  const version = document.head?.querySelector('[name~=version-info][content]') as HTMLHeadElement;
  const versionNumber = version.getAttribute('content');
  return versionNumber ?? '';
};

export const isCurrentAppVersion = (version: VersionId): boolean => {
  const currentAppVersion = queryAppVersionId() as VersionId;
  return version === currentAppVersion;
};

export const shouldShowAppFooter = (scene: SceneT) =>
  scene.indexOf('games') === -1 &&
  scene.indexOf('board:tutorial') === -1 &&
  scene.indexOf('career') === -1 &&
  scene.indexOf('account:create') === -1;

export const getFontSize = () => {
  const cssDec = window.getComputedStyle(document.body) as CSSStyleDeclaration;
  const fontValue = String(cssDec.getPropertyValue('font-size'));
  const value = (fontValue.match(/\d+/) as RegExpMatchArray)[0];

  return value;
};
