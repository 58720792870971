import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'app/store';
import { shouldShowAppFooter } from 'helpers/appHelpers';
import { isCareerQuestionOrTutorialScene } from 'helpers/careerHelpers';
import { setShowAppFooter, setShowBreadcrumbs } from 'scenes/montessori/appUiSlice';
// #region SceneT ***********************************************************************
export type SceneT =
  | 'app'
  | 'app:galleryui'
  | 'montessori'
  | 'montessori:quiz'
  | 'count'
  | 'count:tutorial'
  | 'multiply'
  | 'subtract'
  | 'add'
  | 'divide'
  | 'games'
  | 'legal'
  | 'unlocks'
  | 'news'
  // DEMOS
  | 'demo:question:count'
  | 'demo:question:compare'
  | 'demo:question:add'
  | 'demo:question:subtract'
  | 'demo:question:multiply'
  | 'demo:question:divide'
  // AUTH / ACCOUNT
  | 'auth:login'
  | 'auth:signup'
  | 'auth:resetpassword'
  | 'board:tutorial'
  | 'account'
  | 'account:create'
  | 'account:profile:create'
  | 'account:profile:edit'
  | 'account:profile:reportcard'
  | 'student'
  | 'student:profile'
  | 'student:profile:edit'
  // CAREER
  | 'career'
  | 'career:account'
  | 'career:badges:gallery'
  | 'career:question:count'
  | 'career:question:compare'
  | 'career:question:add'
  | 'career:question:subtract'
  | 'career:question:multiply'
  | 'career:question:divide'
  | 'career:tutorial:count'
  | 'career:tutorial:compare'
  | 'career:tutorial:add'
  | 'career:tutorial:subtract'
  | 'career:tutorial:multiply'
  | 'career:tutorial:divide'
  // #region MANAGE / EDITOR
  | 'manage'
  | 'manage:collections' // deprecated
  | 'manage:career:collection'
  | 'manage:career:create'
  | 'manage:career:edit'
  | 'manage:course:collection'
  | 'manage:course:create'
  | 'manage:course:edit'
  | 'manage:lesson:collection'
  | 'manage:lesson:create'
  | 'manage:lesson:edit'
  | 'manage:profile:collection'
  | 'manage:profile:edit'
  | 'manage:user:collection'
  | 'manage:user:edit'
  | 'manage:user:profile:create'
  | 'manage:user:profile:edit'
  | 'manage:user:profile:career:reportcard'
  | 'manage:question:collection'
  | 'manage:question:create'
  | 'manage:question:edit'
  | 'manage:quiz:collection'
  | 'manage:quiz:create'
  | 'manage:quiz:edit'
  | 'manage:tutorial:collection'
  | 'manage:tutorial:create'
  | 'manage:tutorial:edit'
  | 'manage:awards:collection'
  | 'manage:award:create'
  | 'manage:award:edit'
  | 'manage:medals:collection'
  | 'manage:medal:create'
  | 'manage:medal:edit'
  | 'manage:games:collection'
  | 'manage:games:create'
  | 'manage:games:edit'
  | 'manage:gamequestion:create'
  | 'manage:gamequestion:edit'
  | 'manage:news:collection'
  | 'manage:news:create'
  | 'manage:news:edit'
  | 'manage:unlocks:collection'
  | 'manage:unlocks:create'
  | 'manage:unlocks:edit';
// #endregion

type ManageSceneT =
  | 'question'
  | 'quiz'
  | 'lesson'
  | 'tutorial'
  | 'course'
  | 'career'
  | 'profile'
  | 'news'
  | 'awards'
  | 'medals'
  | 'games'
  | 'unlocks'
  | 'user';

const getTitleBySceneType = (sceneT: SceneT): string => {
  switch (sceneT) {
    case 'montessori':
      return 'Montessori | Home';
    // #region ACCOUNT / AUTH
    case 'account':
      return 'Montessori | Account Management';
    case 'account:profile:create':
      return 'Montessori | Account Management: Create Profile';
    case 'account:profile:edit':
      return 'Montessori | Account Management: Create Profile';
    case 'account:profile:reportcard':
      return 'Montessori | Account Management: Report Card';
    case 'auth:login':
      return 'Montessori | Login';
    case 'auth:signup':
      return 'Montessori | Sign up';
    case 'auth:resetpassword':
      return 'Montessori | Reset Password';
    case 'student':
    case 'student:profile':
      return 'Montessori | My Profile';
    case 'student:profile:edit':
      return 'Montessori | Edit My Profile';
    // #endregion
    // #region CAREER
    case 'career':
      return 'Montessori | My Career';
    case 'career:account':
      return 'Montessori | Administrator Account';
    case 'career:badges:gallery':
      return 'Montessori | My Career | Badge Gallery';
    case 'career:question:count':
    case 'career:question:compare':
    case 'career:question:add':
    case 'career:question:subtract':
    case 'career:question:multiply':
    case 'career:question:divide':
      return 'Montessori | My Career | Question';
    case 'career:tutorial:count':
    case 'career:tutorial:compare':
    case 'career:tutorial:add':
    case 'career:tutorial:subtract':
    case 'career:tutorial:multiply':
    case 'career:tutorial:divide':
      return 'Montessori | My Career | Tutorial';
    // #endregion
    // #region EDITOR TITLES
    case 'manage:collections':
      return 'Montessori | Editor';
    case 'manage:career:collection':
      return 'Montessori | Editor | Careers';
    case 'manage:career:create':
      return 'Montessori | Editor | Create Career';
    case 'manage:career:edit':
      return 'Montessori | Editor | Edit Career';
    case 'manage:course:collection':
      return 'Montessori | Editor | Courses';
    case 'manage:course:create':
      return 'Montessori | Editor | Create Course';
    case 'manage:course:edit':
      return 'Montessori | Editor | Edit Course';
    case 'manage:lesson:collection':
      return 'Montessori | Editor | Lessons';
    case 'manage:lesson:create':
      return 'Montessori | Editor | Create Lesson';
    case 'manage:lesson:edit':
      return 'Montessori | Editor | Edit Lesson';
    case 'manage:profile:collection':
      return 'Montessori | Montessori Editor | Profiles';
    case 'manage:profile:edit':
      return 'Montessori | Editor | Edit Profile';
    case 'manage:user:collection':
      return 'Montessori | Montessori Editor | Users';
    case 'manage:user:edit':
      return 'Montessori | Editor | Edit User';
    case 'manage:user:profile:create':
      return 'Montessori | Editor | Create Profile For User';
    case 'manage:user:profile:edit':
      return 'Montessori | Editor | Edit Profile For User';
    case 'manage:user:profile:career:reportcard':
      return 'Montessori | Editor | Report Card';
    case 'manage:question:collection':
      return 'Montessori | Montessori Editor | Questions';
    case 'manage:question:create':
      return 'Montessori | Editor | Create Question';
    case 'manage:question:edit':
      return 'Montessori | Editor | Edit Question';
    case 'manage:quiz:collection':
      return 'Montessori | Montessori Editor | Quizzes';
    case 'manage:quiz:create':
      return 'Montessori | Editor | Create Quiz';
    case 'manage:quiz:edit':
      return 'Montessori | Editor | Edit Quiz';
    case 'manage:tutorial:collection':
      return 'Montessori | Montessori Editor | Tutorials';
    case 'manage:tutorial:create':
      return 'Montessori | Editor | Create Tutorial';
    case 'manage:tutorial:edit':
      return 'Montessori | Editor | Edit Tutorial';
    case 'manage:awards:collection':
      return 'Montessori | Montessori Editor | Awards';
    case 'manage:award:create':
      return 'Montessori | Editor | Create Award';
    case 'manage:award:edit':
      return 'Montessori | Editor | Edit Award';
    case 'manage:medals:collection':
      return 'Montessori | Montessori Editor | Medals';
    case 'manage:medal:create':
      return 'Montessori | Editor | Create Medal';
    case 'manage:medal:edit':
      return 'Montessori | Editor | Edit Medal';
    case 'manage:games:collection':
      return 'Montessori | Montessori Editor | Games';
    case 'manage:games:create':
      return 'Montessori | Editor | Create Game';
    case 'manage:games:edit':
      return 'Montessori | Editor | Edit Game';
    case 'manage:unlocks:collection':
      return 'Montessori | Montessori Editor | Unlocks';
    case 'manage:unlocks:create':
      return 'Montessori | Editor | Create Unlock';
    case 'manage:unlocks:edit':
      return 'Montessori | Editor | Edit Unlock';
    case 'manage:news:collection':
      return 'Montessori | Montessori Editor | News';
    case 'manage:news:create':
      return 'Montessori | Editor | Create News Article';
    case 'manage:news:edit':
      return 'Montessori | Editor | Edit News Article';
    // #endregion EDITOR TITLES
    case 'games':
      return 'Montessori | Games';
    case 'app:galleryui':
      return 'Montessori | UI Gallery';
    case 'legal':
      return 'Montessori | Legal';
    case 'unlocks':
      return 'Montessori | Unlocks';
    case 'news':
      return 'Montessori | News';
    case 'board:tutorial':
      return 'Montessori | Board Tutorial';
    case 'demo:question:add':
      return 'Montessori | Demo | Addition';
    case 'demo:question:multiply':
      return 'Montessori | Demo | Multiplication';
    case 'app':
    default:
      return 'Montessori by eleventheye';
  }
};
// #endregion

export interface ScenesState {
  activeScene: SceneT;
  scenes?: {
    sceneId: string;
    sceneName?: string;
    sceneData?: unknown;
  };
  lastActiveManageScene: ManageSceneT;
}

const initialState: ScenesState = {
  activeScene: 'app',
  scenes: { sceneId: 'n/a' },
  lastActiveManageScene: 'career',
};

export const doSetActiveScene =
  (scene: SceneT): AppThunk =>
  async (dispatch) => {
    if (isCareerQuestionOrTutorialScene(scene)) {
      dispatch(setShowBreadcrumbs(false));
    } else {
      dispatch(setShowBreadcrumbs(true));
    }

    if (shouldShowAppFooter(scene)) {
      dispatch(setShowAppFooter(true));
    } else {
      dispatch(setShowAppFooter(false));
    }

    dispatch(setActiveScene(scene));
  };

export const scenesSlice = createSlice({
  name: 'scenes',
  initialState,
  reducers: {
    setActiveScene: (state, action: PayloadAction<SceneT>) => {
      state.activeScene = action.payload;
    },
    setSceneState: (state, action: PayloadAction<SceneT>) => {
      state.scenes = { sceneId: action.payload };
    },
    setLastActiveManageScene: (state, action: PayloadAction<ManageSceneT>) => {
      state.lastActiveManageScene = action.payload;
    },
    resetScenes: () => {
      return { ...initialState };
    },
  },
});

export const { setActiveScene, setSceneState, setLastActiveManageScene, resetScenes } = scenesSlice.actions;

export const getActiveScene = (state: RootState): SceneT => state.scenes.activeScene;
export const getActiveSceneTitle = (state: RootState): string => getTitleBySceneType(state.scenes.activeScene);
export const getLastActiveManageScene = (state: RootState): ManageSceneT => state.scenes.lastActiveManageScene;

export default scenesSlice.reducer;
