import { store } from 'app/store';
import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { getModifiedInfo } from 'helpers/careerHelpers';
import { MedalT } from 'types/App.types';

// #region CONVERTERS *******************************************************************
export const getInitialMedal = (): MedalT => {
  const userId = store.getState().user.uid || '';
  const initMedal: MedalT = {
    medalId: 'createMedalId',
    title: 'Medal title',
    description: 'Medal description',
    awardKind: 'Medal',
    code: 'MED',
    medalUrl: '',
    largeMedalUrl: '',
    largeMedalBackUrl: '',
    createdBy: userId,
    modifiedBy: userId,
    createdOn: Date.now(),
    modifiedOn: Date.now(),
  };

  return initMedal;
};

export const medalToDoc = (medal: MedalT): DocumentData => {
  return {
    medalId: medal.medalId,
    title: medal.title,
    description: medal.description,
    code: medal.code,
    awardKind: medal.awardKind,
    medalUrl: medal.medalUrl,
    largeMedalUrl: medal.largeMedalUrl,
    largeMedalBackUrl: medal.largeMedalBackUrl,
    createdBy: medal.createdBy,
    createdOn: medal.createdOn,
    modifiedBy: medal.modifiedBy,
    modifiedOn: medal.modifiedOn,
  };
};

export const medalConverter: FirestoreDataConverter<MedalT> = {
  toFirestore(medal: MedalT): DocumentData {
    return { ...medalToDoc(medal), ...getModifiedInfo() };
  },

  fromFirestore(docSnap: QueryDocumentSnapshot, options?: SnapshotOptions): MedalT {
    const medal = docSnap.data(options);
    return {
      medalId: medal.medalId,
      title: medal.title,
      description: medal.description,
      code: medal.code,
      awardKind: medal.awardKind,
      medalUrl: medal.medalUrl,
      largeMedalUrl: medal.largeMedalUrl,
      largeMedalBackUrl: medal.largeMedalBackUrl,
      createdBy: medal.createdBy,
      createdOn: medal.createdOn,
      modifiedBy: medal.modifiedBy,
      modifiedOn: medal.modifiedOn,
    };
  },
};
// #endregion
