import React, { Suspense } from 'react';
import { useAppSelector } from 'app/hooks/hooks';
import { Await, Outlet, useLoaderData } from 'react-router-dom';

import { BadgesT } from 'types/Career.types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getShowAppFooter, getShowBreadcrumbs, getShowCookieConsentPopup } from 'scenes/montessori/appUiSlice';
import { getShowDebugPanel, isUserAdmin } from 'modules/user/userSlice';
import CreateMessageForm from 'modules/contactform/CreateMessageForm';

import { usePageTitle } from 'app/hooks/usePageTitle';

import AwardPopup from 'components/awardpopup/AwardPopup';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import CompleteNotification from 'components/completenotification/CompleteNotification';
import CompletionStar from 'components/completionstar/CompletionStar';
import DebugButtonWithPanel from 'components/buttons/debugpanel/DebugButtonWithPanel';
import Footer from 'components/footer/Footer';
import Menu from 'components/menu/Menu';
import SceneLoader from 'components/loader/SceneLoader';
import ThumbsUp from 'components/thumbsup/ThumbsUp';
import Toast from 'components/toast/Toast';
// import CookiePopup from 'components/cookiepopup/CookiePopup';

import styles from './App.module.scss';
import AdminNote from 'components/adminnote/AdminNote';

const App: React.FC = () => {
  const appData: BadgesT = useLoaderData() as BadgesT;
  const showDebugPanel = useAppSelector(getShowDebugPanel);
  const showBreadcrumbs = useAppSelector(getShowBreadcrumbs);
  const showFooter = useAppSelector(getShowAppFooter);
  const isAdmin = useAppSelector(isUserAdmin);
  // const showCookiePopup = useAppSelector(getShowCookieConsentPopup);
  usePageTitle();

  return (
    <div id="MontessoriAppId" className={styles.App}>
      <Menu open={false} />
      {showBreadcrumbs && <Breadcrumbs />}
      <Suspense fallback={<SceneLoader animate />}>
        <Await resolve={appData} errorElement={<div>Could not load app data 😬</div>}>
          <Outlet />
        </Await>
      </Suspense>
      <CreateMessageForm />
      <CompletionStar />
      <CompleteNotification />
      <AwardPopup />
      <Toast />
      <ThumbsUp />
      {isAdmin && <AdminNote />}
      {showDebugPanel && <DebugButtonWithPanel />}
      {/* {showCookiePopup && <CookiePopup />} */}
      {showFooter && <Footer style={{ margin: '3em 1em 0', maxWidth: '50em', alignSelf: 'center' }} />}
    </div>
  );
};

export default App;
