import React from 'react';
import { ElementSizeT, VoidFunc } from 'types/App.types';
import RoundedButton from 'components/buttons/rounded/RoundedButton';
import styles from './ClosePopupButton.module.scss';

type ClosePopupButtonProps = {
  className?: string;
  showButton?: boolean;
  icon?: string;
  buttonText?: string;
  onClickHandler?: VoidFunc;
  textSize?: ElementSizeT;
};

const ClosePopupButton: React.FC<ClosePopupButtonProps> = ({
  icon = '',
  onClickHandler = () => undefined,
  showButton = false,
  buttonText = 'CLOSE',
  ...props
}) => {
  const cls = `${props.className ?? ''} ${showButton ? styles.ClosePopupButton : ''}`;
  return (
    <div className={`${styles.ClosePopupButtonContainer} ${cls}`}>
      <RoundedButton textSize={props.textSize} mainColor onClick={onClickHandler} icon={icon}>
        {buttonText}
      </RoundedButton>
    </div>
  );
};

export default ClosePopupButton;
