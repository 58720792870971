import React, { useEffect, useRef, useState } from 'react';
import { FruitT } from 'types/App.types';
import DynamicItemsList, { SwitchSide, SwitchSideT } from 'components/list/dynamic/DynamicItemsList';
import { getFruitIcon } from 'helpers/uiHelpers';

import styles from './FlipFruitToNumber.module.scss';

type FlipFruitToNumberProps = {
  className?: string;
  flipId?: string;
  fruitType: FruitT;
  startingSide?: SwitchSideT;
  itemCount: number;
  itemCountText?: string;
  pause?: boolean;
  widthItemCount?: number;
} & Pick<React.HTMLAttributes<HTMLDivElement>, 'style' | 'onClick'>;

const FlipFruitToNumber: React.FC<FlipFruitToNumberProps> = ({
  flipId,
  startingSide = SwitchSide.Number,
  pause = false,
  ...props
}) => {
  const listId = flipId ?? `id_${props.itemCount}_${props.fruitType}`;
  const autoFlipOnStart = useRef<string>('');
  const [flip, setFlip] = useState<boolean>(false);

  useEffect(() => {
    if (!pause && autoFlipOnStart.current !== listId) {
      autoFlipOnStart.current = listId;
      setTimeout(() => {
        setFlip(true);
      }, 1500);
    }

    return () => {
      autoFlipOnStart.current = '';
    };
  }, [listId, pause]);

  return (
    <div className={`${styles.FlipFruitToNumber} ${props.className ?? ''}`} style={props.style}>
      <DynamicItemsList
        listId={listId}
        labelTextSize="lge"
        startingSwitchSide={startingSide}
        flipList={flip}
        onAnimationOver={() => setFlip(false)}
        itemCountText={props.itemCountText}
        itemCount={props.itemCount}
        widthItemCount={props.widthItemCount}
        enableSwitch
        showSwitch
        itemIcon={getFruitIcon(props.fruitType)}
      />
    </div>
  );
};

export default FlipFruitToNumber;
