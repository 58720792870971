import React, { useEffect, useState } from 'react';
import { FirebaseError } from 'firebase/app';
import { useRouteError, useNavigate, isRouteErrorResponse, ErrorResponse } from 'react-router-dom';
import { CaptureInfoT, ErrorLogT } from 'types/App.types';

import { useCapture } from 'app/hooks/useCapture';

import RoundedButton from 'components/buttons/rounded/RoundedButton';
import Rule from 'components/rule/Rule';

import { createErrorLog } from 'api/ErrorAPI';

import styles from './ErrorBoundary.module.scss';

type ErrorBoundaryProps = {
  debug?: string;
};

type ErrorDataT = {
  title: string;
  info: string;
};

// const ERROR_NAVIGATE_BACK_IN_SECONDS = 60; // in

const ErrorBoundary: React.FC<ErrorBoundaryProps> = (props) => {
  const error = useRouteError();
  const { captureState, errorLog } = useCapture();
  // const [timeToRedirect, setTimeToRedirect] = useState<number>(ERROR_NAVIGATE_BACK_IN_SECONDS);
  const navigate = useNavigate();
  const [errorBreakdown, setErrorBreakdown] = useState<Array<ErrorDataT>>([]);
  const [submittedErrorLog, setSubmittedErrorLog] = useState<ErrorLogT>();

  console.log(`ErrorBoundary with debug message ${props.debug} ${error}`);

  useEffect(() => {
    console.error(error);

    // let timeoutId: NodeJS.Timeout;
    // if (timeToRedirect > 0) {
    //   if (timeToRedirect === 5) {
    //     console.error(error);
    //   }
    //   timeoutId = setTimeout(() => {
    //     setTimeToRedirect(timeToRedirect - 1);
    //   }, 1000);
    // } else {
    //   navigate(-1);
    // }
    // return () => {
    //   clearTimeout(timeoutId);
    // };

    const errorData: Array<ErrorDataT> = [];
    if (isRouteErrorResponse(error)) {
      const errorResponse = (error as ErrorResponse).data as Error;
      errorData.push({ title: 'errorType', info: 'RouteErrorResponse' });
      errorData.push({ title: 'errorName', info: errorResponse.name });
      errorData.push({ title: 'cause', info: String(errorResponse.cause) });
      errorData.push({ title: 'message', info: errorResponse.message });
      errorData.push({ title: 'stack', info: String(errorResponse.stack) });
      setSubmittedErrorLog({
        fromRoute: props.debug,
        errorType: 'RouteErrorResponse',
        errorName: errorResponse.name,
        cause: String(errorResponse.cause ?? ''),
        message: errorResponse.message,
        stack: errorResponse.stack,
      });
    } else {
      const fbError = error as FirebaseError;
      errorData.push({ title: 'errorType', info: 'FirebaseError (Not a route error response)' });
      errorData.push({ title: 'errorName', info: fbError.name });
      errorData.push({ title: 'code', info: fbError.code || 'UNKNOWN' });
      errorData.push({ title: 'message', info: fbError.message });
      errorData.push({ title: 'stack', info: String(fbError.stack) });
      setSubmittedErrorLog({
        fromRoute: props.debug,
        errorType: 'FirebaseError (Not a route error response)',
        errorName: fbError.name,
        code: fbError.code || 'UNKNOWN',
        cause: String(fbError.cause ?? ''),
        message: fbError.message,
        stack: String(fbError.stack),
      });
    }
    setErrorBreakdown(errorData);
  }, [error, navigate]);

  useEffect(() => {
    if (submittedErrorLog && errorLog) {
      createErrorLog({ ...errorLog, ...submittedErrorLog });
    }
  }, [submittedErrorLog, errorLog]);

  const renderCaptureDetails = () => {
    const keys = Object.keys(captureState);
    return (
      <>
        <div className={styles.ErrorBoundaryHeading}>INFO</div>
        {keys.map((key, index) => {
          const keyProp = key as keyof CaptureInfoT;
          return (
            <div key={`key_${index}`} className={styles.ErrorBoundarySubHeading}>
              <span className={styles.ErrorBoundarySubHeadingTitle}>{key.toUpperCase()}</span>
              <span className={styles.ErrorBoundarySubHeadingInfo}>{captureState[keyProp]}</span>
            </div>
          );
        })}
      </>
    );
  };

  const renderErrorInfo = (errorData: Array<ErrorDataT>) => (
    <React.Fragment>
      <div className={styles.ErrorBoundaryMessageDisplay}>
        <div className={styles.ErrorBoundaryHeadingMain}>Oops... ERROR</div>
        <div className={styles.ErrorBoundarySubHeading}>
          <span className={styles.ErrorBoundarySubHeadingTitle}>FROMROUTE</span>
          <span className={styles.ErrorBoundarySubHeadingInfo}>{props.debug}</span>
        </div>
        <div className={styles.ErrorBoundaryHeading}>DETAILS</div>
        {errorData.map((ed: ErrorDataT, index: number) => {
          return (
            <div key={`key_${index}`} className={styles.ErrorBoundarySubHeading}>
              <span className={styles.ErrorBoundarySubHeadingTitle}>{ed.title.toUpperCase()}</span>
              <span className={styles.ErrorBoundarySubHeadingInfo}>{ed.info}</span>
            </div>
          );
        })}
        {renderCaptureDetails()}
      </div>
    </React.Fragment>
  );

  return (
    <div className={styles.ErrorBoundary}>
      <div className={styles.ErrorBoundaryErrorMessage}>
        {renderErrorInfo(errorBreakdown)}
        <span className={styles.ErrorBoundaryServerValue}>{isRouteErrorResponse(error) && error.data?.message}</span>
      </div>
      <Rule size="thin" />
      <div>
        Well, that was not suppose to happen...
        <p>Remain calm...</p>
      </div>
      <RoundedButton mainColor to=".">
        RETURN HOME
      </RoundedButton>
    </div>
  );
};

export default ErrorBoundary;
