import React from 'react';
import { ElementSizeT } from 'types/App.types';
import styles from './Monkey.module.scss';

export const MonkeyImage = {
  HEAD: '/images/monkey-face-240x240.webp',
  EASTER: '/images/monkey-face-easter-240x400.webp',
  HALLOWEEN: '/images/monkey-face-halloween-240x250.webp',
  XMAS: '/images/monkey-face-xmas-240x400.webp',
} as const;

export type MonkeyImageT = (typeof MonkeyImage)[keyof typeof MonkeyImage];

type MonkeyProps = {
  className?: string;
  size?: ElementSizeT;
  monkeyImg?: MonkeyImageT;
} & Pick<React.HTMLAttributes<HTMLDivElement>, 'onClick' | 'style'>;

const Monkey: React.FC<MonkeyProps> = ({ monkeyImg = MonkeyImage.HEAD, size = 'med', ...props }) => {
  return (
    <div className={`${styles.Monkey} ${props.className ?? ''}`}>
      <img
        id="monkeyHeadImg"
        style={props.style}
        className={`${styles.MonkeyHead} ${size}`}
        src={monkeyImg}
        alt="Monkey head"
      />
    </div>
  );
};

export default Monkey;
