import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'app/store';
import { Status, StatusT, StorageItemsT } from 'types/App.types';
import { getImagesFromStorage, uploadFilesToStorage as uploadFilesToStorage } from 'api/FileStorageAPI';

export type StorageResponsePayload = {
  items: StorageItemsT;
  status: StatusT;
};

export type StoreImageState = {
  status: StatusT;
  storageImages: StorageItemsT;
  imagesToUpload: Array<File>;
};

export const STORAGE_PATH_TO_MEDALS = 'images/medals';

const initialState: StoreImageState = {
  status: Status.Idle,
  storageImages: [],
  imagesToUpload: [],
};

export const doLoadAllStorageImages =
  (forceLoad = false): AppThunk =>
  async (dispatch, getState) => {
    if (getStorageImagesStatus(getState()) !== Status.Loaded || forceLoad) {
      const images = await getImagesFromStorage(STORAGE_PATH_TO_MEDALS);
      dispatch(setAllStorageImages({ items: images, status: Status.Loaded }));
    }
  };

export const uploadMedalImages =
  (files: Array<File>): AppThunk =>
  async (dispatch) => {
    if (files && files.length > 0) {
      const uploadedFiles: StorageItemsT = await uploadFilesToStorage(files, 'medals');
      dispatch(addStorageImages(uploadedFiles));
    }
  };

export const storageImagesSlice = createSlice({
  name: 'images',
  initialState,
  reducers: {
    setAllStorageImages: (state, action: PayloadAction<StorageResponsePayload>) => {
      state.storageImages = action.payload.items;
      state.status = action.payload.status;
    },
    setStorageImages: (state, action: PayloadAction<StorageItemsT>) => {
      state.storageImages = action.payload;
    },
    setStatus: (state, action: PayloadAction<StatusT>) => {
      state.status = action.payload;
    },
    setImagesToUpload: (state, action: PayloadAction<Array<File>>) => {
      state.imagesToUpload = action.payload;
    },
    addStorageImages: (state, action: PayloadAction<StorageItemsT>) => {
      state.storageImages = state.storageImages.concat(action.payload);
    },
    resetAwards: () => {
      return { ...initialState };
    },
  },
});

export const {
  setAllStorageImages,
  setStorageImages,
  setStatus,
  setImagesToUpload,
  addStorageImages,
  resetAwards,
} = storageImagesSlice.actions;

export const getStorageImages = (state: RootState): StorageItemsT => state.images.storageImages;
export const getStorageImagesStatus = (state: RootState): StatusT => state.images.status;
export const getImagesToUpload = (state: RootState): Array<File> => state.images.imagesToUpload;


export default storageImagesSlice.reducer;
