import { AwardCodesMap } from 'types/App.types';

export const AwardCodes: AwardCodesMap = {
  Code0000: 'STA-0001-0000',
  Code0001: 'FIN-0001-0001',
  Code0002: 'FIN-0002-0002',
  Code0003: 'FIN-0003-0003',
  Code0004: 'DIS-0001-0004',
  Code0005: 'DIS-0002-0005',
  Code0006: 'FIN-0004-0006',
  Code0007: 'DIS-0003-0007',
  Code0008: 'FIN-0005-0008',
  Code0009: 'FIN-0006-0009',
  Code0010: 'FIN-0007-0010',
  Code0011: 'FIN-0008-0011',
  Code0012: 'FIN-0009-0012',
  Code0013: 'FIN-0010-0013',
  Code0014: 'FIN-0011-0014',
} as const;
