import React from 'react';
import { useAppSelector } from 'app/hooks/hooks';

import { getUserId, isActiveProfileIdSelected, isUserSignedIn } from 'modules/user/userSlice';

import CareerHub from 'modules/career/hub/CareerHub';
import AdminHub from 'modules/career/hub/components/adminhub/AdminHub';
import News from 'scenes/montessori/subscenes/news/News';
import QuestionsDemo from 'scenes/montessori/subscenes/demoquestions/QuestionsDemo';

import styles from './MontessoriHome.module.scss';

const MontessoriHome: React.FC = () => {
  const userSignedIn = useAppSelector(isUserSignedIn);
  const userId = useAppSelector(getUserId);
  const isSelectedActiveProfile = useAppSelector(isActiveProfileIdSelected);

  return (
    <div className={styles.MontessoriHome}>
      <div className={styles.MontessoriHomeBody}>
        {userSignedIn && <>{isSelectedActiveProfile ? <CareerHub /> : <AdminHub userId={userId} />}</>}

        <News />
        {!userSignedIn && <QuestionsDemo />}
      </div>
    </div>
  );
};

export default MontessoriHome;
