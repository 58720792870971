/* eslint-disable @typescript-eslint/no-explicit-any */
import { store } from 'app/store';
import { redirect } from 'react-router-dom';
import { analytics, db, logEvent } from '../firebase/Firebase';
import { query, collection, orderBy, getDocs, doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import { Dictionary, PublicEntity, Status } from 'types/App.types';
import { VersionId } from 'types/BaseCareer.types';
import { QuestionT, QuestionId, QuestionUI, QuestionsT, QuestionUpdateResponseT } from 'types/Question.types';

import { useStyledLogger } from 'app/config/LogConfig';
import { sortQuestions } from 'helpers/sortHelpers';
import { getToastSuccessPayload } from 'helpers/uiHelpers';
import { isPrint } from 'helpers/appHelpers';
import { toPublicQuestionId } from 'helpers/careerHelpers';

import { doSetActiveScene, setLastActiveManageScene } from 'scenes/scenesSlice';
import { updateQuestion, setQuestions, setQuestionsStatus, setPublicQuestionIds } from 'scenes/management/managerSlice';
import { setActiveQuestionFilters, setQuestionFilterCategories } from 'scenes/management/managementUiSlice';
import { setToastNotification } from 'modules/user/userUiSlice';
import {
  getDemoAdditionQuestion,
  getDemoMultiplicationQuestion,
  getDemoSubtractionQuestion,
} from 'scenes/career/subscenes/tutorials/Tutorial.questions';
import { fetchPublicEntityIds } from 'api/PublicEntitiesAPI';
import { questionConverter, questionToDoc, getInitialQuestion } from 'api/converters/QuestionsConverters';
import { AppRoutes } from 'app/constants/Routes';

const PRINT_FLAG = true;
const printMsg = useStyledLogger('QuestionAPI', 'aquamarine');

// #region QUESTION APIs ****************************************************************
export const fetchQuestions = async (): Promise<QuestionsT> => {
  const collectionData = collection(db, 'questions').withConverter(questionConverter);
  const q = query(collectionData, orderBy('type', 'asc'), orderBy('level', 'asc'));
  const querySnapshot = await getDocs(q);
  const questions: QuestionsT = [];
  querySnapshot.docs.forEach((doc) => {
    const docData: QuestionT = doc.data();
    questions.push({
      ...docData,
      questionId: doc.id,
    });
  });
  questions.sort(sortQuestions);
  return questions;
};

export const fetchQuestionsAsDictionary = async (): Promise<Dictionary<QuestionT>> => {
  const collectionData = collection(db, 'questions').withConverter(questionConverter);
  const q = query(collectionData, orderBy('type', 'asc'), orderBy('level', 'asc'));
  const querySnapshot = await getDocs(q);
  const questions: Dictionary<QuestionT> = {};

  querySnapshot.docs.forEach((doc) => {
    const docData: QuestionT = doc.data();
    questions[doc.id] = {
      ...docData,
      questionId: doc.id,
    };
  });

  return questions;
};

export const getQuestionOld = async (questionId: QuestionId): Promise<QuestionT> => {
  const docRef = doc(db, `questions/${questionId}`).withConverter(questionConverter);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const docData: QuestionT = docSnap.data();
    return { ...docData, questionId: questionId };
  } else {
    isPrint(PRINT_FLAG) && console.log(...printMsg('getQuestionOld', 'No document', `Unable to find ${questionId}`));
    logEvent(analytics, 'exception', { description: `Question Unable to find question (${questionId})` });
    throw Error(`Question Unable to find question (${questionId})`);
  }
};

export const getQuestion = async (questionId: QuestionId, versionId: VersionId): Promise<QuestionT> => {
  const docRef = doc(db, `questions/${questionId}/versions/${versionId}`).withConverter(questionConverter);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const docData: QuestionT = docSnap.data();
    return { ...docData, questionId: questionId, versionId: versionId };
  } else {
    isPrint(PRINT_FLAG) && console.log(...printMsg('getQuestion', 'No document', `Unable to find ${questionId}`));
    logEvent(analytics, 'exception', { description: `Question Unable to find question (${questionId})` });
    throw Error(`Question Unable to find question (${questionId})`);
  }
};

export const fetchVersionedQuestions = async (questionAndVersionStrings: Array<string>): Promise<QuestionsT> => {
  return new Promise((resolve) => {
    const versionedQuestions: QuestionsT = [];
    const totalCount = questionAndVersionStrings.length;
    questionAndVersionStrings.forEach(async (q, index: number) => {
      const idPairs = q.split(':');
      const questionId = idPairs[0];
      const versionId = idPairs[1];

      const versionedQ: QuestionT = await getQuestion(questionId, versionId);
      versionedQuestions.push(versionedQ);

      if (totalCount === index + 1) {
        resolve(versionedQuestions);
      }
    });
  });
};

export const createNonVersionedQuestion = async (question: QuestionT): Promise<QuestionT> => {
  const collectionData = collection(db, 'questions').withConverter(questionConverter);
  const docRef = doc(collectionData);
  const createdQuestion: QuestionT = { ...question, questionId: docRef.id };
  // Create new record with autogenerated id as questionId
  await setDoc(docRef, createdQuestion);
  isPrint(PRINT_FLAG) && console.log(...printMsg('createNonVersionedQuestion', 'questionId', docRef.id));
  return createdQuestion as QuestionT;
};

export const createVersionedQuestion = async (question: QuestionT): Promise<QuestionT> => {
  const collectionData = collection(db, 'questions').withConverter(questionConverter);
  const docRef = doc(collectionData);
  // Create new record with autogenerated id as questionId
  const qWithVersion: QuestionT = { ...question, questionId: docRef.id, versionId: '' };
  qWithVersion.versionId = await createQuestionVersion(qWithVersion);
  await setDoc(docRef, qWithVersion);
  const withVersion = toPublicQuestionId(qWithVersion);
  isPrint(PRINT_FLAG) && console.log(...printMsg('createVersionedQuestion', 'questionId:versionId', withVersion));
  return qWithVersion as QuestionT;
};

export const createQuestionVersion = async (question: QuestionT): Promise<VersionId> => {
  const collectionData = collection(db, `questions/${question.questionId}/versions`).withConverter(questionConverter);
  const docRef = doc(collectionData);
  const newQ = { ...question, versionId: docRef.id } as QuestionT;
  await setDoc(docRef, newQ);
  return docRef.id as VersionId;
};

export const createQuestion = async (question: QuestionT, versioned = true): Promise<QuestionT> => {
  if (versioned) {
    return await createVersionedQuestion(question);
  }

  return await createNonVersionedQuestion(question);
};

export const patchQuestion = async (question: QuestionT): Promise<void> => {
  const qWithVersion: QuestionT = { ...question, questionId: question.questionId };
  qWithVersion.versionId = await createQuestionVersion(qWithVersion);
  const questionRef = doc(db, `questions/${qWithVersion.questionId}`);
  const docQuestion = questionToDoc(qWithVersion);
  return await updateDoc(questionRef, docQuestion);
};
// #endregion

// #region ROUTER LOADER & ACTION FUNCTIONS **********************************************
export const careerQuestionSceneLoader = async ({ params }: any): Promise<QuestionT> => {
  const { questionId, versionId } = params;
  const withVersion = `${questionId}:${versionId}`;
  isPrint(PRINT_FLAG) && console.log(...printMsg('Loading CareerQuestion', 'questionId:versionId', withVersion));
  const questionT: QuestionT = await getQuestion(questionId, versionId);

  return questionT;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const demoQuestionSceneLoader = async ({ params }: any): Promise<QuestionUI> => {
  const { questionId } = params;
  isPrint(PRINT_FLAG) && console.log(...printMsg('Load DemoQuestion', 'questionId', `${questionId}`));
  if (questionId === 'DemoAddQuestion') {
    return getDemoAdditionQuestion(10);
  } else if (questionId === 'DemoMultiplyQuestion') {
    return getDemoMultiplicationQuestion(45);
  }

  return getDemoSubtractionQuestion(1);
};
// #endregion

// #region MANAGE Questions **************************************************************

export const manageQuestionCollectionSceneLoader = async () => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('Load ManageQuestionCollectionScene', '', ''));
  store.dispatch(doSetActiveScene('manage:question:collection'));
  store.dispatch(setLastActiveManageScene('question'));

  const publicQuestionIds = await fetchPublicEntityIds(PublicEntity.Questions);
  store.dispatch(setPublicQuestionIds({ collection: publicQuestionIds, status: 'loaded' }));
  const questions = await fetchQuestions();
  store.dispatch(setQuestions({ collection: questions, status: 'loaded' }));

  const availableFilters: Array<string> = questions
    .map((q) => q.categoryType || `${q.questionId}`)
    .filter((item, index, list) => list.lastIndexOf(item) === index);
  store.dispatch(setQuestionFilterCategories(availableFilters));
  if (store.getState().ui.managementUI.activeQuestionFilters.length === 0) {
    store.dispatch(setActiveQuestionFilters(availableFilters));
  }

  return questions;
};

export const manageCreateQuestionSceneLoader = async () => {
  store.dispatch(doSetActiveScene('manage:question:create'));
  store.dispatch(setLastActiveManageScene('question'));

  const question: QuestionT = getInitialQuestion();
  return { question };
};

export const manageCreateQuestionSceneAction = async () => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('Load ManageCreateQuestionSceneAction', '', ''));
  store.dispatch(setQuestionsStatus('creating'));
  const managerQuestions = store.getState().manager.questions;
  const indexOfCreatedItem = managerQuestions.findIndex((q: QuestionT) => q.questionId === 'createQuestionId');
  const questionItem = managerQuestions[indexOfCreatedItem] as QuestionT;
  const toCreateQuestion: QuestionT = {
    ...questionItem,
    createdOn: Date.now(),
    modifiedOn: Date.now(),
  };

  const createdQuestion = await createQuestion(toCreateQuestion);
  store.dispatch(updateQuestion({ question: createdQuestion, index: indexOfCreatedItem }));
  store.dispatch(setQuestionsStatus('created'));
  store.dispatch(setToastNotification(getToastSuccessPayload('Question Created')));
  return redirect(AppRoutes.ManagerQuestion);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const manageEditQuestionSceneLoader = async ({ params }: any): Promise<QuestionUpdateResponseT> => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('Load ManageEditQuestionSceneLoader', '', ''));
  store.dispatch(doSetActiveScene('manage:question:edit'));
  const questionId = params.questionId;

  // TODO: @Andrey11 change params to also pass versionId
  const question = await getQuestionOld(questionId);
  const manager = store.getState().manager;
  const index = manager.questions.findIndex((q: QuestionT) => q.questionId === questionId);
  return { question, index };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const manageEditQuestionSceneAction = async ({ params }: any) => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('Load ManageEditQuestionSceneAction', '', ''));
  const questionId = params.questionId;
  store.dispatch(setQuestionsStatus(Status.Updating));
  const managerQuestions = store.getState().manager.questions;
  const indexOfEditedItem = managerQuestions.findIndex((q: QuestionT) => q.questionId === questionId);
  const questionItem = managerQuestions[indexOfEditedItem] as QuestionT;
  const questionItemToEdit: QuestionT = {
    ...questionItem,
    modifiedOn: Date.now(),
    modifiedBy: store.getState().user.uid,
  };

  await patchQuestion(questionItemToEdit);
  store.dispatch(setQuestionsStatus(Status.Updated));
  store.dispatch(setToastNotification(getToastSuccessPayload('Question Updated')));
  return redirect(AppRoutes.ManagerQuestion);
};

// #endregion
