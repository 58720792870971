import React, { AnimationEvent, MouseEvent, useEffect, useState } from 'react';
import { MedalT, VoidFunc } from 'types/App.types';
import { ImageUrls } from 'app/constants/ImageUrls';
import { MeIcons } from 'app/constants/Constants';

import MeText from 'components/text/metext/MeText';
import MeIconRaised from 'components/icon/meiconraised/MeIconRaised';

import styles from './Medal.module.scss';

type MedalSizeT = 'small' | 'medium' | 'large' | 'original';

type MedalProps = {
  className?: string;
  imageUrl: string;
  imageBackUrl?: string;
  alt?: string;
  medalSize?: MedalSizeT;
  onAnimationOver?: (e: AnimationEvent) => void;
  onClick?: VoidFunc;
  medalInfo?: MedalT;
  animateOnOpen?: boolean;
  performFlip?: boolean;
  showMedalInfo?: boolean;
};

const Medal: React.FC<MedalProps> = ({
  imageBackUrl = ImageUrls.MedalBackLarge,
  onAnimationOver = () => undefined,
  showMedalInfo = false,
  alt = 'medal',
  ...props
}) => {
  const [flipCls, setFlipCls] = useState<string>('');
  const [isFlipped, setIsFlipped] = useState<boolean>(false);

  useEffect(() => {
    setFlipCls(props.performFlip ? styles.FlipFrontToBackForward : styles.FlipBackToFrontForward);
  }, [props.performFlip]);

  useEffect(() => {
    setFlipCls(isFlipped ? styles.FlipFrontToBackForward : styles.FlipBackToFrontForward);
  }, [isFlipped]);

  const onFlip = (e: MouseEvent) => {
    e.stopPropagation();
    setIsFlipped(!isFlipped);
  };

  return (
    <div
      className={`${styles.Medal} ${props.className ?? ''} ${props.medalSize}`}
      onAnimationEnd={onAnimationOver}
      onClick={props.onClick}>
      <div className={`${styles.MedalWrapper} ${flipCls} ${props.medalSize}`}>
        <img src={props.imageUrl} alt={alt} className={`${styles.MedalImage} ${styles.MedalFrontImage}`} />
        <img src={imageBackUrl} alt={alt} className={`${styles.MedalImage} ${styles.MedalBackImage}`} />
        {showMedalInfo && (
          <div className={styles.MedalInfo}>
            <MeText insetText useDiv lineHeight="line12" weight="bold900" textSize="mlg">
              {props.medalInfo?.title}
            </MeText>
            <MeText insetText useDiv lineHeight="line12" weight="bold400" textSize="mel">
              {props.medalInfo?.description}
            </MeText>
          </div>
        )}
      </div>
      {showMedalInfo && (
        <MeIconRaised
          iconSize="med"
          icon={MeIcons.FlipMedal}
          mainColor
          className={styles.MedalFlipButton}
          onClick={onFlip}
        />
      )}
    </div>
  );
};

export default Medal;
