import { db } from '../firebase/Firebase';
import {
  collection,
  doc,
  setDoc,
  updateDoc,
  query,
  getDocs,
  limit,
} from 'firebase/firestore';
import { PreferencesT, ProfileId } from 'types/User.types';
import { getFormattedString, getNameClsString } from 'app/config/LogConfig';
import { getModifiedInfo } from 'helpers/careerHelpers';
import { isPrint } from 'helpers/appHelpers';
import { preferencesConverter, preferencesToDoc, getInitialPreferences } from 'api/converters/PreferencesConverter';

export const PRIMARY_COLOR = getNameClsString('darkslategray', 'cornsilk');
export const CLS_NAME = 'PreferencesAPI';

const PRINT_FLAG = false;
const printMsg = (action: string, prop: string, status: string) =>
  getFormattedString(PRIMARY_COLOR, CLS_NAME, action, prop, status);

// #region APIs *************************************************************************
export const getUserPreferences = async (profileId: ProfileId): Promise<PreferencesT> => {
  const dbPath = `profiles/${profileId}/preferences`;
  const collectionData = collection(db, dbPath).withConverter(preferencesConverter);
  const q = query(collectionData, limit(1));

  const querySnapshot = await getDocs(q);

  if (querySnapshot.size === 0) return await generateNewUserPreferences(profileId);

  const preferences = querySnapshot.docs.at(0)?.data() as PreferencesT;
  isPrint(PRINT_FLAG) && console.log(...printMsg('LOADED Preferences', 'preferences', preferences.preferencesId));
  return preferences;
};

export const updateUserPreferences = async (preferences: PreferencesT): Promise<void> => {
  const dbPath = `profiles/${preferences.profileId}/preferences/${preferences.preferencesId}`;
  const docRef = doc(db, dbPath).withConverter(preferencesConverter);
  const modified = getModifiedInfo();
  isPrint(PRINT_FLAG) && console.log(...printMsg('Update', 'preferencesId', preferences.preferencesId));
  return await updateDoc(docRef, preferencesToDoc({ ...preferences, ...modified }));
};

const generateNewUserPreferences = async (profileId: ProfileId): Promise<PreferencesT> => {
  const initPreferences = getInitialPreferences(profileId);
  const createdPreferences = await createUserPreferences(initPreferences);
  return createdPreferences;
};

export const createUserPreferences = async (preferences: PreferencesT): Promise<PreferencesT> => {
  const dbPath = `profiles/${preferences.profileId}/preferences`;
  const collectionData = collection(db, dbPath);
  const docRef = doc(collectionData);
  // Create new record with autogenerated id as preferencesId
  const createdPreferences: PreferencesT = { ...preferences, preferencesId: docRef.id };
  await setDoc(docRef, createdPreferences);
  isPrint(PRINT_FLAG) && console.log(...printMsg('Create', 'preferencesId', docRef.id));
  return createdPreferences;
};

// #endregion
