import { Arithmetic, OPERATOR_SIGN, VisualState } from 'types/App.types';
import { QuestionUI } from 'types/Question.types';

export const getTutorialCountingQuestion = (sign: string, expectedResult = 5): QuestionUI => ({
  questionId: 'TutorialQuestionId',
  title: 'Count to 5',
  firstNum: 2,
  operator: sign,
  secondNum: 3,
  expectedResult,
  categoryType: Arithmetic.Count,
  level: 1,
  versionId: 'createVersionId',
  expectedResultCells: [
    {
      cellId: '1_3_3',
      column: expectedResult,
      date: Date.now(),
      row: 1,
      isHeaderCell: false,
      isCounted: false,
      value: expectedResult,
    },
  ],
  createdBy: 'userId',
  createdOn: Date.now(),
  modifiedBy: 'userId',
  modifiedOn: Date.now(),
  visualState: VisualState.Active,
});

export const getTutorialCompareQuestion = (): QuestionUI => ({
  questionId: 'TutorialCompareQuestionId',
  title: 'Compare 2 to 4',
  firstNum: 2,
  operator: OPERATOR_SIGN.compare,
  secondNum: 4,
  expectedResult: 4,
  categoryType: Arithmetic.Compare,
  level: 1,
  versionId: 'createVersionId',
  expectedResultCells: [
    {
      cellId: '1_4_4',
      column: 4,
      date: Date.now(),
      row: 1,
      isHeaderCell: false,
      isCounted: false,
      value: 4,
    },
  ],
  createdBy: 'userId',
  createdOn: Date.now(),
  modifiedBy: 'userId',
  modifiedOn: Date.now(),
  visualState: VisualState.Active,
});

export const getTutorialAdditionQuestion = (expectedResult = 5): QuestionUI => ({
  questionId: 'DemoAddQuestion',
  title: `2 ${OPERATOR_SIGN.plus} 3`,
  firstNum: 2,
  operator: OPERATOR_SIGN.plus,
  secondNum: 3,
  expectedResult,
  categoryType: Arithmetic.Add,
  level: 2,
  versionId: 'DemoAddQuestionVersionId',
  expectedResultCells: [
    {
      cellId: '1_5_5',
      column: 5,
      date: Date.now(),
      row: 1,
      isHeaderCell: false,
      isCounted: false,
      value: expectedResult,
    },
  ],
  createdBy: 'userId',
  createdOn: Date.now(),
  modifiedBy: 'userId',
  modifiedOn: Date.now(),
  visualState: VisualState.Active,
});

export const getTutorialMultiplicationQuestion = (expectedResult = 8): QuestionUI => ({
  questionId: 'DemoQuestion',
  title: `4 ${OPERATOR_SIGN.multiply} 2`,
  firstNum: 4,
  operator: OPERATOR_SIGN.multiply,
  secondNum: 2,
  expectedResult,
  categoryType: Arithmetic.Multiply,
  level: 4,
  versionId: 'DemoVersionId',
  expectedResultCells: [
    {
      cellId: '2_4_8',
      row: 2,
      column: 4,
      date: Date.now(),
      isHeaderCell: false,
      isCounted: false,
      value: expectedResult,
    },
    {
      cellId: '4_2_8',
      column: 2,
      date: Date.now(),
      row: 4,
      isHeaderCell: false,
      isCounted: false,
      value: expectedResult,
    },
  ],
  createdBy: 'userId',
  createdOn: Date.now(),
  modifiedBy: 'userId',
  modifiedOn: Date.now(),
  visualState: VisualState.Active,
});

export const getTutorialSubtractionQuestion = (expectedResult = 1): QuestionUI => ({
  questionId: 'DemoQuestion',
  title: `4 ${OPERATOR_SIGN.minus} 3`,
  firstNum: 4,
  operator: OPERATOR_SIGN.minus,
  secondNum: 3,
  expectedResult,
  categoryType: Arithmetic.Subtract,
  level: 3,
  versionId: 'DemoVersionId',
  expectedResultCells: [
    {
      cellId: '1_1_1',
      row: 1,
      column: 1,
      date: Date.now(),
      isHeaderCell: false,
      isCounted: false,
      value: expectedResult,
    },
  ],
  createdBy: 'userId',
  createdOn: Date.now(),
  modifiedBy: 'userId',
  modifiedOn: Date.now(),
  visualState: VisualState.Active,
});

export const getDemoMultiplicationQuestion = (expectedResult = 45): QuestionUI => ({
  questionId: 'DemoQuestion',
  title: `9 ${OPERATOR_SIGN.multiply} 5`,
  firstNum: 9,
  operator: OPERATOR_SIGN.multiply,
  secondNum: 5,
  expectedResult,
  categoryType: Arithmetic.Multiply,
  level: 4,
  versionId: 'DemoVersionId',
  expectedResultCells: [
    {
      cellId: '5_9_45',
      column: 9,
      date: Date.now(),
      row: 5,
      isHeaderCell: false,
      isCounted: false,
      value: expectedResult,
    },
    {
      cellId: '9_5_45',
      column: 5,
      date: Date.now(),
      row: 9,
      isHeaderCell: false,
      isCounted: false,
      value: expectedResult,
    },
  ],
  createdBy: 'userId',
  createdOn: Date.now(),
  modifiedBy: 'userId',
  modifiedOn: Date.now(),
  visualState: VisualState.Active,
});

export const getDemoSubtractionQuestion = (expectedResult = 1): QuestionUI => ({
  questionId: 'DemoQuestion',
  title: `7 ${OPERATOR_SIGN.minus} 6`,
  firstNum: 7,
  operator: OPERATOR_SIGN.minus,
  secondNum: 6,
  expectedResult,
  categoryType: Arithmetic.Subtract,
  level: 2,
  versionId: 'DemoVersionId',
  expectedResultCells: [
    {
      cellId: '1_1_1',
      column: 1,
      date: Date.now(),
      row: 1,
      isHeaderCell: false,
      isCounted: false,
      value: expectedResult,
    },
  ],
  createdBy: 'userId',
  createdOn: Date.now(),
  modifiedBy: 'userId',
  modifiedOn: Date.now(),
  visualState: VisualState.Active,
});

export const getDemoAdditionQuestion = (expectedResult = 10): QuestionUI => ({
  questionId: 'DemoQuestion',
  title: `5 ${OPERATOR_SIGN.plus} 5`,
  firstNum: 5,
  operator: OPERATOR_SIGN.plus,
  secondNum: 5,
  expectedResult,
  categoryType: Arithmetic.Add,
  level: 1,
  versionId: 'DemoVersionAddId',
  expectedResultCells: [
    {
      cellId: '1_10_10',
      column: 10,
      date: Date.now(),
      row: 1,
      isHeaderCell: false,
      isCounted: false,
      value: expectedResult,
    },
  ],
  createdBy: 'guestUserId',
  createdOn: Date.now(),
  modifiedBy: 'guestUserId',
  modifiedOn: Date.now(),
  visualState: VisualState.Active,
});
