import React from 'react';
import { ThicknessT } from 'types/App.types';

import styles from './Rule.module.scss';

type RuleProps = {
  size?: ThicknessT;
  zeroMargin?: boolean;
} & Pick<React.HTMLAttributes<HTMLHRElement>, 'style'>;

const Rule: React.FC<RuleProps> = ({ size = 'med', zeroMargin = false, ...props }) => {
  const marginCls = zeroMargin ? 'zeroMargin' : '';
  return <hr className={`${styles.Rule} ${size} ${marginCls}`} style={props.style} />;
};

export default Rule;
