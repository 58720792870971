import React, { AnimationEvent, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks/hooks';
import { VoidFunc } from 'types/App.types';

import { isAnimationName } from 'helpers/uiHelpers';
import ClosePopupButton from 'components/buttons/closepopup/ClosePopupButton';

import styles from './CompleteNotification.module.scss';
import { getCompleteNotification, setShowCompleteNotification } from 'scenes/montessori/appUiSlice';
import { useEventCallback } from '@mui/material';
import MeText from 'components/text/metext/MeText';

// ENTRANCE/EXIT ANIMATIONS
export const EnterAnimation = {
  SlideInElliptic: 'slide-in-elliptic-bottom-fwd',
  FlipOnX: 'flip-x',
} as const;
export type EnterAnimationT = (typeof EnterAnimation)[keyof typeof EnterAnimation];

export const ExitAnimation = {
  SlideOutElliptic: 'slide-out-elliptic-top-bck',
} as const;
export type ExitAnimationT = (typeof ExitAnimation)[keyof typeof ExitAnimation];

type StarAnimationT = 'slide' | 'flip';

type CompleteNotificationProps = {
  className?: string;
  starAnimation?: StarAnimationT;
  onEnterAnimationOver?: VoidFunc;
  onExitAnimationOver?: VoidFunc;
};

const CompleteNotification: React.FC<CompleteNotificationProps> = ({ className = '', ...props }) => {
  const notificationRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
  const baseCls = `${styles.CompleteNotification} ${className}`;
  const animationCls = styles.SlideElliptic;
  const animationFrameRef = useRef<number>(0);

  // const showCompleteNotification = useAppSelector(getShowCompleteNotification);
  const notificationState = useAppSelector(getCompleteNotification);

  const [isVisible, setVisible] = useState<boolean>(false);
  const [animationStateCls, setAnimationStateCls] = useState<string>('');

  const dispatch = useAppDispatch();

  const handleClickOutside = useEventCallback((event: MouseEvent) => {
    if (isVisible && notificationRef.current && !notificationRef.current.contains(event.target as HTMLElement)) {
      hideCompletionStar();
    }
  });

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const animationFrame = requestAnimationFrame(() => {
      setAnimationStateCls(notificationState.showNotification ? 'PLAY' : '');
    });

    return () => {
      cancelAnimationFrame(animationFrame);
      cancelAnimationFrame(animationFrameRef.current);
    };
  }, [notificationState]);

  const isEntranceAnimation = (name: string) =>
    isAnimationName(name, EnterAnimation.SlideInElliptic) || isAnimationName(name, EnterAnimation.FlipOnX);

  const isExitAnimation = (name: string) => isAnimationName(name, ExitAnimation.SlideOutElliptic);

  const onAnimationOver = (event: AnimationEvent) => {
    if (isEntranceAnimation(event.animationName)) {
      setVisible(true);
      if (props.onEnterAnimationOver) props.onEnterAnimationOver();
    } else if (isExitAnimation(event.animationName)) {
      setVisible(false);
      setAnimationStateCls('');
      dispatch(setShowCompleteNotification(false));
      if (props.onExitAnimationOver) props.onExitAnimationOver();
    }
  };

  const hideCompletionStar = () => {
    if (isVisible) {
      animationFrameRef.current = requestAnimationFrame(() => setAnimationStateCls('OFF'));
    }
  };

  return (
    <div
      ref={notificationRef}
      className={`${baseCls} ${animationStateCls}`}
      onClick={hideCompletionStar}
      onAnimationEnd={onAnimationOver}>
      <div className={`${styles.CompleteNotificationContents} ${animationCls}`}>
        <div className={styles.CompleteNotificationTitle}>
          <MeText
            style={{ width: '100%', justifyContent: 'center', padding: '0 2em' }}
            weight="bold800"
            insetText
            textSize="lge">
            {notificationState.headerLabel}
          </MeText>
        </div>
        <span
          aria-label={`${notificationState.notificationIcon} icon`}
          className={`material-symbols-outlined ${styles.CompleteNotificationIcon}`}>
          {notificationState.notificationIcon}
        </span>
        {notificationState.bodyText && (
          <span>
            <MeText style={{ width: '100%', justifyContent: 'center' }} weight="bold500" lineHeight='line12' insetText textSize="mlg">
              {notificationState.bodyText}
            </MeText>
          </span>
        )}
        <ClosePopupButton
          className={styles.ActionButton}
          onClickHandler={hideCompletionStar}
          icon={notificationState.actionButtonIcon}
          showButton={notificationState.showActionButton}
          buttonText={notificationState.actionButtonLabel}
          textSize="xs12"
        />
      </div>
    </div>
  );
};

export default CompleteNotification;
