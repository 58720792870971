import React from 'react';
import { VoidFunc } from 'types/App.types';

import Icon from 'components/icon/Icon';

import styles from './MenuHandle.module.scss';

type MenuHandleProps = {
  className?: string;
  menuClickHandler?: VoidFunc;
  hideMenu?: boolean;
  hideCls?: string;
  icon?: string;
  appMenu?: boolean;
};

const MenuHandle: React.FC<MenuHandleProps> = ({
  menuClickHandler = () => undefined,
  icon = 'menu_open',
  hideCls = styles.Hide,
  ...props
}) => {
  const menuCustomCls = props.hideMenu ? hideCls : '';
  const appMenuCls = props.appMenu ? styles.MenuHandleApp : '';
  const baseCls = `${styles.MenuHandle} ${appMenuCls} ${menuCustomCls}`;
  return (
    <div className={`${baseCls} ${props.className ?? ''} `} onClick={menuClickHandler}>
      <Icon icon={icon} />
    </div>
  );
};

export default MenuHandle;
