import React from 'react';
import { ElementSizeT } from 'types/App.types';

import { useCapture } from 'app/hooks/useCapture';

import InsetText from 'components/text/inset/InsetText';
import Rule from 'components/rule/Rule';

import styles from './Capture.module.scss';

const Capture: React.FC = () => {
  const { captureState } = useCapture();

  return (
    <>
      <CaptureDataRow label="STATUS" dataValue={captureState.status} />
      <Rule />
      <div className={styles.CaptureInfo}>
        <CaptureDataRow label="TIME" size="sma" dataValue={captureState.time} />
        <CaptureDataRow label="LOCATION" size="sma" dataValue={captureState.location} />
        <CaptureDataRow label="ACTIVE SCENE" size="sma" dataValue={captureState.activeScene} />
      </div>
      <Rule />
      <div className={styles.CaptureInfo}>
        <CaptureDataRow label="EMAIL" dataValue={captureState.email} />
        <CaptureDataRow label="NAME" size="sma" dataValue={captureState.name} />
        <CaptureDataRow label="UID" size="xsm" dataValue={captureState.uid} />
        <CaptureDataRow label="ACTIVE STUDENT" size="xsm" dataValue={captureState.activeProfile} />
        <CaptureDataRow label="PROFILES COUNT" dataValue={captureState.profilesCount} />
        <CaptureDataRow label="ADMIN" dataValue={String(captureState.admin)} />
        <CaptureDataRow label="EDITOR" dataValue={String(captureState.editor)} />
      </div>
      {/* <CaptureDataRow label="LOCATION" dataValue={history.state} /> */}
    </>
  );
};
export default Capture;

type CaptureDataProps = {
  label?: string;
  dataValue?: string | number;
  size?: ElementSizeT;
};

const CaptureDataRow: React.FC<CaptureDataProps> = ({ size = 'xs12', ...props }) => {
  return (
    <div className={styles.CaptureRow}>
      <InsetText size="xs145" uppercase>
        {props.label}
      </InsetText>
      <InsetText size={size} uppercase>
        {props.dataValue}
      </InsetText>
    </div>
  );
};
