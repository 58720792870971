import React from 'react';
import { InsetIconProps, PropsWithChildren } from 'types/App.types';
import Icon from 'components/icon/Icon';
import InsetText from 'components/text/inset/InsetText';

import styles from './InsetIcon.module.scss';

const InsetIcon: React.FC<PropsWithChildren<InsetIconProps>> = ({
  isFilled = false,
  weight = '',
  wrapperCls = '',
  color = 'Default',
  size = 'mel',
  style = {},
  data = '',
  ...props
}) => {
  const baseCls = `${styles.InsetText} ${wrapperCls} ${weight} ${props.className ?? ''}`;
  return (
    <InsetText size={size} data={data} style={style} color={color} className={baseCls}>
      <Icon {...props} size={size} isFilled={isFilled} color={color} className={weight} />
      {props.children}
    </InsetText>
  );
};

export default InsetIcon;
