import React, { useEffect, useState } from 'react';
import { Link, LinkProps, To } from 'react-router-dom';
import { ElementSizeT, PropsWithChildren } from 'types/App.types';

import Icon from 'components/icon/Icon';
import InsetText from 'components/text/inset/InsetText';
import InsetIcon from 'components/icon/InsetIcon';

import styles from './RoundedButton.module.scss';

type RoundedButtonProps = {
  className?: string;
  disabled?: boolean;
  icon?: string;
  isFilled?: boolean;
  textSize?: ElementSizeT;
  loading?: boolean;
  useFakeLoader?: boolean;
  fakeLoaderTimeout?: number;
  mainColor?: boolean;
  dangerColor?: boolean;
  useMaxHeight?: boolean;
  to?: To;
} & React.HTMLAttributes<HTMLDivElement> &
  React.HTMLAttributes<HTMLAnchorElement> &
  Partial<LinkProps>;

const RoundedButton: React.FC<PropsWithChildren<RoundedButtonProps>> = ({
  disabled = false,
  isFilled = false,
  textSize = 'sma',
  className = '',
  loading = false,
  useFakeLoader = true,
  fakeLoaderTimeout = 1000,
  mainColor = false,
  dangerColor = false,
  useMaxHeight = true,
  ...props
}) => {
  const disabledCls = disabled ? styles.Disabled : '';
  const baseCls = `${styles.RoundedButton} ${styles.Glass}`;
  const actionColor = dangerColor ? styles.RoundedButtonDangerBg : mainColor ? styles.RoundedButtonHighlightBg : '';

  const maxHeightCls = useMaxHeight ? styles.RoundedButtonMaxHeight : '';

  const [isLoading, setIsLoading] = useState<boolean>(loading);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const toggleInertFn = (node: HTMLDivElement | null) =>
    node && (isLoading ? node.setAttribute('inert', '') : node.removeAttribute('inert'));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onClickHandler = (event: any) => {
    if (disabled) return;

    if (useFakeLoader) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, fakeLoaderTimeout);
    }
    if (props.onClick) {
      props.onClick(event);
    }
  };

  const InnerButton = (
    <div
      {...props}
      onClick={onClickHandler}
      ref={toggleInertFn}
      className={`${baseCls} ${disabledCls} ${actionColor} ${maxHeightCls} ${className}`}>
      {isLoading && (
        <div className={styles.RoundedButtonLoading}>
          <Icon icon="progress_activity" className={styles.RoundedButtonLoadingIcon} />
        </div>
      )}
      {props.icon && <InsetIcon icon={props.icon} isFilled={isFilled} size={textSize} />}
      {props.children && (
        <InsetText uppercase size={textSize}>
          {props.children}
        </InsetText>
      )}
    </div>
  );

  if (!!props.to && !disabled) {
    return (
      <Link to={props.to} relative='path' state={props.state} onClick={onClickHandler} className={`${styles.LinkWrapper} ${className}`}>
        {InnerButton}
      </Link>
    );
  }
  return InnerButton;
};

export default RoundedButton;
