import { store } from 'app/store';
import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { getModifiedInfo } from 'helpers/careerHelpers';
import { Arithmetic } from 'types/App.types';
import { GameT, GameQuestionT, GameLevel } from 'types/Game.types';

// #region CONVERTERS *******************************************************************
export const getInitialGame = (): GameT => {
  const userId = store.getState().user.uid || '';
  const initGame: GameT = {
    gameId: 'createGameId',
    gameIcon: 'joystick',
    gameImage: '',
    gameTitle: '',
    gameDescription: '',
    gameArithmeticType: Arithmetic.Unknown,
    gameQuestions: [],
    hasQuestions: false,
    createdBy: userId,
    createdOn: Date.now(),
    modifiedBy: userId,
    modifiedOn: Date.now(),
  };

  return initGame;
};

export const getInitialGameQuestion = (game: GameT): GameQuestionT => {
  const userId = store.getState().user.uid || '';
  const initGameQuestion: GameQuestionT = {
    gameQuestionId: 'createGameQuestionId',
    title: '',
    level: GameLevel.Level1,
    description: '',
    expectedResults: [],
    gameId: game.gameId,
    gameTitle: game.gameTitle,
    gameArithmeticType: game.gameArithmeticType,
    gameComplete: '',
    createdBy: userId,
    createdOn: Date.now(),
    modifiedBy: userId,
    modifiedOn: Date.now(),
  };

  return initGameQuestion;
};

export const gameToDoc = (game: GameT): DocumentData => {
  return {
    gameId: game.gameId,
    gameTitle: game.gameTitle,
    gameDescription: game.gameDescription,
    gameArithmeticType: game.gameArithmeticType,
    hasQuestions: game.gameQuestions && game.gameQuestions.length > 0,
    gameIcon: game.gameIcon,
    gameImage: game.gameImage,
    createdBy: game.createdBy,
    createdOn: game.createdOn,
    modifiedBy: game.modifiedBy,
    modifiedOn: game.modifiedOn,
  };
};

export const gameConverter: FirestoreDataConverter<GameT> = {
  toFirestore(game: GameT): DocumentData {
    return { ...gameToDoc(game), ...getModifiedInfo() };
  },

  fromFirestore(docSnap: QueryDocumentSnapshot, options?: SnapshotOptions): GameT {
    const game = docSnap.data(options);

    return {
      gameId: game.gameId,
      gameTitle: game.gameTitle,
      gameDescription: game.gameDescription,
      gameArithmeticType: game.gameArithmeticType,
      hasQuestions: game.hasQuestions,
      gameQuestions: [],
      gameIcon: game.gameIcon,
      gameImage: game.gameImage,
      createdBy: game.createdBy,
      createdOn: game.createdOn,
      modifiedBy: game.modifiedBy,
      modifiedOn: game.modifiedOn,
    };
  },
};

export const gameQuestionToDoc = (gameQuestion: GameQuestionT): DocumentData => {
  return {
    gameQuestionId: gameQuestion.gameQuestionId,
    title: gameQuestion.title,
    level: gameQuestion.level,
    description: gameQuestion.description,
    gameArithmeticType: gameQuestion.gameArithmeticType,
    gameId: gameQuestion.gameId,
    gameComplete: gameQuestion.gameComplete,
    gameTitle: gameQuestion.gameTitle,
    expectedResults: gameQuestion.expectedResults,
    createdBy: gameQuestion.createdBy,
    createdOn: gameQuestion.createdOn,
    modifiedBy: gameQuestion.modifiedBy,
    modifiedOn: gameQuestion.modifiedOn,
  };
};

export const gameQuestionConverter: FirestoreDataConverter<GameQuestionT> = {
  toFirestore(gameQ: GameQuestionT): DocumentData {
    return gameQuestionToDoc(gameQ);
  },

  fromFirestore(docSnap: QueryDocumentSnapshot, options?: SnapshotOptions): GameQuestionT {
    const gameQuestion = docSnap.data(options);

    return {
      gameQuestionId: gameQuestion.gameQuestionId,
      title: gameQuestion.title,
      level: gameQuestion.level,
      description: gameQuestion.description,
      gameArithmeticType: gameQuestion.gameArithmeticType,
      gameId: gameQuestion.gameId,
      gameComplete: gameQuestion.gameComplete,
      gameTitle: gameQuestion.gameTitle,
      expectedResults: gameQuestion.expectedResults,
      createdBy: gameQuestion.createdBy,
      createdOn: gameQuestion.createdOn,
      modifiedBy: gameQuestion.modifiedBy,
      modifiedOn: gameQuestion.modifiedOn,
    };
  },
};
// #endregion
