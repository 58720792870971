import React from 'react';
import styles from './FramedImage.module.scss';

type FramedImageProps = {
  className?: string;
  imgSrc: string;
};

const FramedImage: React.FC<FramedImageProps> = (props) => {
  const isLarge = props.className && props.className?.indexOf('Large') > -1;
  return (
    <div className={`${styles.FramedImageItem} ${isLarge ? styles.FramedImageItemLarge : ''}`}>
      <img src={props.imgSrc} className={props.className ?? ''} />
    </div>
  );
};

export default FramedImage;
