import React, { useEffect, useState, MouseEvent, AnimationEvent } from 'react';

import { Animations, AnimationT, PropsWithChildren, ElementSizeT } from 'types/App.types';

import { getArrayForIconSpots } from 'helpers/uiHelpers';

import InsetText from 'components/text/inset/InsetText';
import InsetIcon from 'components/icon/InsetIcon';
import { OrangeIcon } from 'components/icon/FruitIcon';

import styles from './DynamicItemsList.module.scss';

type OwnProps = {
  /** `listId` is used as a parameter in callbacks so it made sense to make it mandatory */
  listId: string;

  className?: string;

  /** Icon to use in list cells @default <OrangeIcon size="xl10" />} */
  itemIcon?: JSX.Element;

  /** Number of icons to be displayed in this list @default 1 */
  itemCount: number;

  /** Number of icons to be used to calc the size of icons in case we want to match two lists with different `itemCount` */
  widthItemCount?: number;

  /** By default this label displays the value of `itemCount`.
   *  You can override of the label field value, and set whatever you desire.
   * @default `itemCount` value */
  itemCountText?: string;

  /** Regardless of `itemCount` creates 10 cell rows even if `itemCount` is less than 10 */
  useBaseTenRows?: boolean;

  /** how many cells to show at the minimum (even if empty) */
  startingColumnCount?: number;

  /** display animated result outcome for FAIL or PASS */
  showAnimation?: AnimationT;

  /** enables / disables switch functionality, @default false */
  enableSwitch?: boolean;

  /** enables / disables visibility of a switch tappable button, @default false */
  showSwitch?: boolean;

  /** starting side, available options are `Fruit`, `Number`, or `None` side, @default SwitchSide.Fruit */
  startingSwitchSide?: SwitchSideT;

  /** font size of the `Number` switch side, @default "xl5" */
  labelTextSize?: ElementSizeT;

  /** allows outside components to trigger 'switching of sides'.
   * Note: Outside components should either clear the `true` flag of the bound prop after a short `setTimeout`, or
   * by subscribing to the `onAnimationOver` callback and setting flag to `false`. Otherwise the second time around
   * the switch will not happen*/
  flipList?: boolean;

  /** renders the dynamic list component with equal width and height  */
  square?: boolean;

  disabled?: boolean;

  /** Callback functions */
  onListTapped?: (listId: string) => void;
  onAnimationOver?: (e?: AnimationEvent) => void;
};
type DynamicItemsListProps = PropsWithChildren<OwnProps>;
export const SwitchSide = {
  Fruit: 'sideFruit',
  Number: 'sideNumber',
  None: 'sideNone',
} as const;
export type SwitchSideT = (typeof SwitchSide)[keyof typeof SwitchSide];

const DynamicItemsList: React.FC<DynamicItemsListProps> = ({
  className = '',
  itemIcon = <OrangeIcon size="xl10" />,
  itemCount = 1,
  useBaseTenRows = false,
  startingColumnCount = 0,
  onListTapped = () => undefined,
  onAnimationOver = () => undefined,
  showAnimation = Animations.None,
  flipList = false,
  enableSwitch = false,
  showSwitch = false,
  labelTextSize = 'xl5',
  startingSwitchSide = SwitchSide.Fruit,
  square = false,
  disabled = false,
  ...props
}) => {
  const [switchSideCls, setSwitchSideCls] = useState<SwitchSideT>(enableSwitch ? startingSwitchSide : SwitchSide.None);
  const baseCls = `${styles.DynamicListLayout} ${className ?? ''}`;
  const spotsList: Array<number> = getArrayForIconSpots(itemCount, useBaseTenRows, startingColumnCount);
  const widthItemCount = props.widthItemCount ?? spotsList.length;
  const [countTextLabel, setCountTextLabel] = useState<string>('');

  useEffect(() => {
    if (props.itemCountText) {
      setCountTextLabel(props.itemCountText);
    } else {
      setCountTextLabel(String(itemCount));
    }
  }, [props.itemCountText, itemCount]);

  useEffect(() => {
    if (enableSwitch && itemCount) {
      setSwitchSideCls(switchSideCls === startingSwitchSide ? SwitchSide.None : switchSideCls);
      setTimeout(() => {
        setSwitchSideCls(startingSwitchSide);
      }, 50);
    }
  }, [itemCount, enableSwitch, startingSwitchSide]);

  const onSwitchTapped = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setSwitchSideCls(switchSideCls === SwitchSide.Fruit ? SwitchSide.Number : SwitchSide.Fruit);
  };

  const handleAnimationOver = (e: AnimationEvent) => onAnimationOver(e);

  useEffect(() => {
    if (flipList) setSwitchSideCls(switchSideCls === SwitchSide.Fruit ? SwitchSide.Number : SwitchSide.Fruit);
  }, [flipList]);

  const listItemStyle = { flex: `0 0 calc((100/${widthItemCount}) * 1% - 8px)` };

  return (
    <div
      ref={(node) => node && (disabled ? node.setAttribute('inert', '') : node.removeAttribute('inert'))}
      data-testid={`DL_test_${props.listId}`}
      className={`${styles.DynamicList} ${switchSideCls} ${square ? styles.SquareMaxWidth : ''}`}>
      <div className={`${styles.DynamicListBorder} ${showAnimation}`} onAnimationEnd={handleAnimationOver}>
        <div className={baseCls} onClick={() => onListTapped(props.listId)}>
          <div className={`${styles.DynamicListItems} ${square ? styles.Square : ''}`}>
            {spotsList.map((spotNum) => (
              <div key={`k_${props.listId}_${spotNum}`} className={styles.DynamicListItem} style={listItemStyle}>
                {spotNum < itemCount ? itemIcon : <span>&nbsp;</span>}
              </div>
            ))}
          </div>
          <div className={styles.DynamicListNumericValue}>
            <InsetText size={labelTextSize}>{countTextLabel}</InsetText>
          </div>
          {showSwitch && (
            <span className={styles.DynamicListSwitchSidesBtn} style={{ zIndex: 1 }}>
              <InsetIcon icon="autorenew" size="xs75" onClick={onSwitchTapped} />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default DynamicItemsList;
