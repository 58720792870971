import { store } from 'app/store';
import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { getLevelByArithmeticType, getModifiedInfo } from 'helpers/careerHelpers';
import { LessonT } from 'types/Lesson.types';

// #region CONVERTERS ********************************************************************
export const getInitialLesson = (): LessonT => {
  const userId = store.getState().user.uid || '';
  const initLesson: LessonT = {
    lessonId: 'createLessonId',
    title: '',
    badge: '',
    category: 'unknown',
    level: 0,
    createdBy: userId,
    createdOn: Date.now(),
    modifiedBy: userId,
    modifiedOn: Date.now(),
    versionedQuizId: undefined,
    versionedTutorialId: undefined,
    versionId: '',
  };

  return initLesson;
};

export const lessonToDoc = (lesson: LessonT): DocumentData => {
  return {
    lessonId: lesson.lessonId,
    title: lesson.title,
    badge: lesson.badge,
    category: lesson.category,
    level: lesson.level || getLevelByArithmeticType(lesson.category),
    createdBy: lesson.createdBy,
    createdOn: lesson.createdOn,
    modifiedBy: lesson.modifiedBy,
    modifiedOn: lesson.modifiedOn,
    versionedQuizId: lesson.versionedQuizId,
    versionedTutorialId: lesson.versionedTutorialId,
    versionId: lesson.versionId,
  };
};

export const lessonConverter: FirestoreDataConverter<LessonT> = {
  toFirestore(lesson: LessonT): DocumentData {
    return { ...lessonToDoc(lesson), ...getModifiedInfo() };
  },

  fromFirestore(docSnap: QueryDocumentSnapshot, options?: SnapshotOptions): LessonT {
    const lesson = docSnap.data(options);
    return {
      lessonId: lesson.lessonId || docSnap.id,
      title: lesson.title,
      badge: lesson.badge,
      category: lesson.category,
      level: lesson.level || getLevelByArithmeticType(lesson.category),
      createdBy: lesson.createdBy || '',
      createdOn: lesson.createdOn || 0,
      modifiedBy: lesson.modifiedBy || '',
      modifiedOn: lesson.modifiedOn || 0,
      versionedQuizId: lesson.versionedQuizId || '',
      versionedTutorialId: lesson.versionedTutorialId || '',
      versionId: lesson.versionId || '',
    } as LessonT;
  },
};
// #endregion
