import React from 'react';
import { AppIcons } from 'app/constants/Constants';
import { ButtonLabel, Description, HeaderTitle } from 'app/constants/Localization';

import CollapsibleList from 'components/list/collapsible/CollapsibleList';
import ImageFrame from 'components/imageframe/ImageFrame';
import RoundedButton from 'components/buttons/rounded/RoundedButton';
import TitleHeader from 'components/titleheader/TitleHeader';

import styles from './QuestionsDemo.module.scss';
import { AppRoutes } from 'app/constants/Routes';

const prefix = '/images/screenshots/';

const QuestionsDemo: React.FC = () => {
  return (
    <div className={styles.QuestionsDemoDisplay}>
      <CollapsibleList
        open={true}
        handleClickable={true}
        handle={
          <TitleHeader fillWidth icon={AppIcons.Common.Calculate} showHorizontalRule={false}>
            {HeaderTitle.InteractiveDemo}
          </TitleHeader>
        }>
        <section itemID="DemoColumnId" className={`${styles.QuestionsDemoColumn}`}>
          <article>
            <TitleHeader icon={AppIcons.Career.Questions} ruleSize="ultra" titleSize="med">
              &nbsp;{HeaderTitle.QuestionsDemo}
            </TitleHeader>
            <div className={`${styles.QuestionsDemoRow} ${styles.QuestionsDemoRowTopSpacer}`}>
              <RoundedButton
                to={'/question/DemoAddQuestion'}
                className={styles.AlignCenter}
                icon={AppIcons.MathOperator.Add}>
                {ButtonLabel.AdditionDemo}
              </RoundedButton>

              <RoundedButton
                to={'/question/DemoMultiplyQuestion'}
                className={styles.AlignCenter}
                icon={AppIcons.MathOperator.Multiply}>
                {ButtonLabel.MultiplicationDemo}
              </RoundedButton>
            </div>
          </article>
          <article className={`${styles.QuestionsDemoDisplaySpacer}`}>
            <TitleHeader icon={AppIcons.Common.Gallery} ruleSize="ultra" titleSize="med">
              &nbsp;{HeaderTitle.Screenshots}
            </TitleHeader>
            <div className={`${styles.QuestionsDemoRow} ${styles.QuestionsDemoRowTopSpacer}`}>
              <ImageFrame imgSrc={`${prefix}career.webp`} title="CAREER" info={Description.CareerInfo} />
              <ImageFrame imgSrc={`${prefix}questions.webp`} title="QUESTIONS" info={Description.QuestionInfo} />
              <ImageFrame imgSrc={`${prefix}home.webp`} title="CAREER HUB" info={Description.HubInfo} />
              <ImageFrame imgSrc={`${prefix}awards.webp`} title="AWARDS" info={Description.AwardInfo} />
              <ImageFrame imgSrc={`${prefix}games.webp`} title="GAMES" info={Description.GameInfo} />
              <ImageFrame imgSrc={`${prefix}profiles.webp`} title="3 PROFILES" info={Description.ProfileInfo} />
              <ImageFrame imgSrc={`${prefix}addcourses.webp`} title="CURRICULUM" info={Description.CurriculumInfo} />
              <ImageFrame imgSrc={`${prefix}reports.webp`} title="REPORTS" info={Description.ReportInfo} />
            </div>
          </article>
          <div className={`${styles.AlignCenter} ${styles.QuestionsDemoRowTopSpacer}`}>
            <RoundedButton to={AppRoutes.SignUp} icon={AppIcons.Common.AppRegistration} mainColor>
              {ButtonLabel.ClickToSignUp}
            </RoundedButton>
          </div>
        </section>
      </CollapsibleList>
    </div>
  );
};

export default QuestionsDemo;
