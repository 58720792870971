import { useEffect, useState } from 'react';
import { useAppSelector } from 'app/hooks/hooks';
import { CaptureInfoT, ErrorLogT } from 'types/App.types';
import { IUser, UserStatusT } from 'types/User.types';

import { getUser, getUserStatus } from 'modules/user/userSlice';
import { getActiveScene } from 'scenes/scenesSlice';

export const useCapture = () => {
  const user: IUser = useAppSelector(getUser);
  const userStatus: UserStatusT = useAppSelector(getUserStatus);
  const activeScene = useAppSelector(getActiveScene);

  const [captureState, setCaptureState] = useState<CaptureInfoT>({});
  const [errorLog, setCreatedErrorLog] = useState<ErrorLogT>();

  useEffect(() => {
    let captureTemp: CaptureInfoT = { ...captureState };

    captureTemp = {
      ...captureTemp,
      time: new Date().toISOString(),
      location: window.location.href,
    };

    if (user) {
      captureTemp = {
        ...captureTemp,
        email: user.email,
        name: `${user.firstname} ${user.lastname}`,
        uid: user.uid,
        activeProfile: user.activeProfileId,
        profilesCount: String(user.profileIds.length),
        admin: String(user.isAdmin),
        editor: String(user.isEditor),
      };
    }
    if (userStatus) {
      captureTemp = { ...captureTemp, status: userStatus };
    }
    if (activeScene) {
      captureTemp = { ...captureTemp, activeScene: activeScene };
    }

    setCaptureState(captureTemp);
    setCreatedErrorLog({ ...captureTemp });
  }, [user, userStatus, activeScene]);

  return { captureState, errorLog };
};
