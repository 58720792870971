import { store } from 'app/store';
import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { getModifiedInfo } from 'helpers/careerHelpers';
import { UnlockT } from 'types/Unlocks.types';

// #region CONVERTERS ***********************************************************
export const getInitialUnlock = (): UnlockT => {
  const userId = store.getState().user.uid || '';
  const initUnlock: UnlockT = {
    unlockId: 'createUnlockId',
    lockedItem: {
      itemId: '',
      itemTitle: '',
      itemType: 'game',
    },
    unlockedBy: [],
    createdBy: userId,
    createdOn: Date.now(),
    modifiedBy: userId,
    modifiedOn: Date.now(),
  };

  return initUnlock;
};

export const unlockToDoc = (unlock: UnlockT): DocumentData => {
  return {
    unlockId: unlock.unlockId,
    lockedItem: unlock.lockedItem,
    unlockedBy: unlock.unlockedBy,
    createdBy: unlock.createdBy,
    createdOn: unlock.createdOn,
    modifiedBy: unlock.modifiedBy,
    modifiedOn: unlock.modifiedOn,
  };
};

export const unlocksConverter: FirestoreDataConverter<UnlockT> = {
  toFirestore(unlock: UnlockT): DocumentData {
    return { ...unlockToDoc(unlock), ...getModifiedInfo() };
  },

  fromFirestore(docSnap: QueryDocumentSnapshot, options?: SnapshotOptions): UnlockT {
    const unlock = docSnap.data(options);

    return {
      unlockId: unlock.unlockId,
      lockedItem: unlock.lockedItem,
      unlockedBy: unlock.unlockedBy,
      createdBy: unlock.createdBy,
      createdOn: unlock.createdOn,
      modifiedBy: unlock.modifiedBy,
      modifiedOn: unlock.modifiedOn,
    };
  },
};
// #endregion
