import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { MaybeType, StatusT } from 'types/App.types';
import { ArticleId, NewsArticle, NewsArticles } from 'types/News.types';

export type NewsState = {
  appNewsStatus: StatusT;
  appNewsArticles: NewsArticles;
};

const initialState: NewsState = {
  appNewsStatus: 'idle',
  appNewsArticles: [],
};

export const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setAppNewsArticles: (state, action: PayloadAction<NewsArticles>) => {
      state.appNewsArticles = action.payload;
    },
    setAppNewsStatus: (state, action: PayloadAction<StatusT>) => {
      state.appNewsStatus = action.payload;
    },
    // updateNewsArticle: (state, action: PayloadAction<NewsArticle>) => {
    //   const temp = state.newsArticles.slice();
    //   const index = temp.findIndex((n) => n.articleId === action.payload.articleId);
    //   temp.splice(index, 1, action.payload);
    //   state.newsArticles = temp;
    // },
    resetNews: () => {
      return { ...initialState };
    },
  },
});

export const { setAppNewsArticles, setAppNewsStatus, resetNews } = newsSlice.actions;

export const getAppNewsStatus = (state: RootState): StatusT => state.news.appNewsStatus;
export const getAppNewsArticles = (state: RootState): NewsArticles => state.news.appNewsArticles;
export const getNewsArticleById = (state: RootState, articleId: ArticleId): MaybeType<NewsArticle> =>
  createSelector([getAppNewsArticles], (articles: NewsArticles) => {
    return articles.find((a) => a.articleId === articleId) as NewsArticle;
  })(state);
export const getAppNewsArticlesStatus = (state: RootState): StatusT => state.news.appNewsStatus;

export default newsSlice.reducer;
