import { store } from 'app/store';
import { redirect } from 'react-router-dom';

import { SubmitActionData, VisualState } from 'types/App.types';
import { BadgeId, BadgeT, BadgesT } from 'types/Career.types';
import { CourseUIs, CourseUI } from 'types/Course.types';
import { LessonUIs, LessonUI } from 'types/Lesson.types';
import { QuizUI } from 'types/Quiz.types';
import { BaseQuestionUIs, BaseQuestionUI } from 'types/Question.types';
import { CareerChainCard } from 'types/BaseCareer.types';
import { TutorialUI } from 'types/Tutorial.types';

import { updateCareerCompleteList, updateCareerBadges } from './CareerAPI';
import { createHistoryEntry } from './HistoryAPI';

import {
  addComplete,
  getCareerCourseUIs,
  getByIdCareerCourseUI,
  updateCourseUI,
  getByIdCareerLessonUI,
  setCareerQuizUI,
  setBaseQuestionUI,
  setCareerLessonUI,
  setTutorialUI,
  setCareerTutorialUI,
  getNextActiveCareerChainItem,
  setCareerQuestionUI,
  addBadge,
  getBadgeIfEarned,
  getCareerChainActiveIndex,
} from 'modules/career/careerSlice';
import { getCareerCarouselAutoplay, setCareerCarouselDisplayMode } from 'modules/career/careerUiSlice';

import { convertToCareerHistoryEntry } from 'helpers/typeCastingHelpers';
import { awardBadge, awardCourseBadge } from 'helpers/badgeHelpers';
import { useStyledLogger } from 'app/config/LogConfig';

const PRIMARY_COLOR = 'darkgray';
const SECONDARY_COLOR = 'cornsilk';
const CLS_NAME = 'ResolveCareerItemsAPI';

const printMsg = useStyledLogger(CLS_NAME, PRIMARY_COLOR, SECONDARY_COLOR);

export const simpleResolveCourse = async (submitData: SubmitActionData): Promise<Response | null> => {
  const date = new Date();
  const { careerId, courseId, completionId } = submitData;

  const courseUI: CourseUI = getByIdCareerCourseUI(store.getState(), String(courseId));

  if (courseUI.visualState !== VisualState.Completed) {
    store.dispatch(addComplete(completionId));
    await updateCareerCompleteList(String(careerId), completionId);
    const courseIndex = store.getState().career.courseUIs?.findIndex((c) => c.courseId === courseUI.courseId) as number;
    store.dispatch(
      updateCourseUI({ courseUI: { ...courseUI, visualState: VisualState.Completed }, index: courseIndex })
    );
    const courseBadgeT = getBadgeIfEarned(store.getState(), courseUI.courseBadgeIds[0]);
    console.log(...printMsg('calling award course badge', 'time', date.toISOString()));
    awardCourseBadge(courseBadgeT as BadgeT, courseUI.courseLevel);
  }

  console.log(...printMsg('Updating CourseUI visualState', 'visualState', VisualState.Completed));
  const courseUIs: CourseUIs = getCareerCourseUIs(store.getState());
  const availableIndex = courseUIs.findIndex((c) => c.visualState !== VisualState.Completed);
  const hasAvailableCourse = availableIndex > -1;
  if (hasAvailableCourse) {
    const nextCourseUI = courseUIs[availableIndex] as CourseUI;
    const nextLessonUIs = (nextCourseUI.courseLessonUIs as LessonUIs).slice();
    const nextLessonIndex = nextLessonUIs.findIndex((l) => l.visualState !== VisualState.Completed);
    const nextLessonUI = { ...(nextLessonUIs[nextLessonIndex] as LessonUI) };
    const nextTutorialUI = { ...(nextLessonUI.tutorialUI as TutorialUI) };
    if (nextTutorialUI.visualState !== VisualState.Completed) {
      nextLessonUIs.splice(nextLessonIndex, 1, {
        ...nextLessonUI,
        visualState: VisualState.Active,
        tutorialUI: { ...nextTutorialUI, visualState: VisualState.Active },
      });
    } else if (nextLessonUI.quizUI?.visualState !== VisualState.Completed) {
      const nextQuizUI = nextLessonUI.quizUI as QuizUI;
      const baseQuestionsUIs = nextQuizUI.baseQuestionUIs as BaseQuestionUIs;
      const nextBaseQuestionUIs = baseQuestionsUIs.slice();
      const nextBaseQuestionIndex = baseQuestionsUIs.findIndex((q) => q.visualState !== VisualState.Completed);
      const nextBaseQuestionUI = baseQuestionsUIs[nextBaseQuestionIndex];
      baseQuestionsUIs.splice(nextBaseQuestionIndex, 1, { ...nextBaseQuestionUI, visualState: VisualState.Active });
      nextLessonUIs.splice(nextLessonIndex, 1, {
        ...nextLessonUI,
        visualState: VisualState.Active,
        quizUI: { ...nextQuizUI, baseQuestionUIs: nextBaseQuestionUIs, visualState: VisualState.Active },
      });
    } else {
      // tutorial and quiz have been completed, lesson should also be marked as completed
      console.log(...printMsg('Updating CourseUI lesson should be resolved', 'lessonId', `${nextLessonUI.lessonId}`));
      return simpleResolveLesson(nextLessonUI.submitActionData as SubmitActionData);
    }

    store.dispatch(setCareerCarouselDisplayMode('FULL_MODE'));
    return null;
  }

  console.log(...printMsg('All courses comlpete', 'FINISH', 'WOO-WOO'));
  store.dispatch(setCareerCarouselDisplayMode('FULL_MODE'));
  return null;
};

export const simpleResolveTutorial = async (submitData: SubmitActionData): Promise<Response | null> => {
  const lessonUI: LessonUI = getByIdCareerLessonUI(
    store.getState(),
    String(submitData.courseId),
    String(submitData.lessonId)
  );

  const tutorialUI = lessonUI.tutorialUI as TutorialUI;

  if (tutorialUI.visualState !== VisualState.Completed) {
    console.log(...printMsg('Finishing Tutorial', 'tutorialId', String(submitData.tutorialId)));
    store.dispatch(addComplete(submitData.completionId));
    await updateCareerCompleteList(String(submitData.careerId), submitData.completionId);

    const tutorialBadgeId: BadgeId = tutorialUI.badgeId;
    await updateCareerBadges(String(submitData.careerId), tutorialBadgeId);
    store.dispatch(addBadge(tutorialBadgeId));

    const updatedTutorialUI = { ...tutorialUI, visualState: VisualState.Completed };
    const updatedLessonUI = { ...lessonUI, tutorialUI: updatedTutorialUI } as LessonUI;
    store.dispatch(setCareerTutorialUI({ courseId: String(submitData.courseId), lessonUI: updatedLessonUI }));
    store.dispatch(setTutorialUI(undefined));
  }

  const activeItem = getNextActiveCareerChainItem(store.getState());
  if (activeItem) {
    if ((activeItem.submitActionData as SubmitActionData).type === 'question') {
      store.dispatch(setBaseQuestionUI(activeItem as CareerChainCard));
    } else {
      store.dispatch(setTutorialUI(activeItem as CareerChainCard));
    }
  }

  store.dispatch(setCareerCarouselDisplayMode('FULL_MODE'));
  return null;
};

export const simpleResolveLesson = async (submitData: SubmitActionData): Promise<Response | null> => {
  const { careerId, courseId, lessonId, completionId } = submitData;

  const lessonUI: LessonUI = getByIdCareerLessonUI(store.getState(), String(courseId), String(lessonId));

  if (lessonUI.visualState !== VisualState.Completed) {
    store.dispatch(addComplete(completionId));
    await updateCareerCompleteList(String(careerId), completionId);

    store.dispatch(
      setCareerLessonUI({ courseId: String(courseId), lessonUI: { ...lessonUI, visualState: VisualState.Completed } })
    );

    const lessonBadgeId: BadgeId = lessonUI.badge;
    await updateCareerBadges(String(careerId), lessonBadgeId);
    store.dispatch(addBadge(lessonBadgeId));

    const reduxBadgesT: BadgesT = store.getState().ui.badgesUi.badges;
    const badgeT: BadgeT = lessonUI.lessonBadge || (reduxBadgesT.find((b) => b.badgeId === lessonBadgeId) as BadgeT);
    awardBadge({ ...badgeT, variant: 'lesson' }, (lessonUI.quizUI as QuizUI).quizLevel);
  }

  const courseUI: CourseUI = getByIdCareerCourseUI(store.getState(), String(courseId));
  const nextAvailableLessonIndex = courseUI.courseLessonUIs?.findIndex(
    (l) => l.visualState !== VisualState.Completed
  ) as number;
  const shouldResolveCourse = nextAvailableLessonIndex === -1;

  if (shouldResolveCourse) {
    // Pick a new course, lesson and quiz as currents
    return await simpleResolveCourse(courseUI.submitActionData as SubmitActionData);
  } else {
    // Set a new lesson and quiz as currents
    const nextLessonUI = (courseUI.courseLessonUIs as LessonUIs)[nextAvailableLessonIndex] as LessonUI;
    const nextTutorialUI = nextLessonUI.tutorialUI as TutorialUI;

    store.dispatch(
      setCareerLessonUI({
        courseId: String(courseId),
        lessonUI: {
          ...nextLessonUI,
          visualState: VisualState.Active,
          tutorialUI: { ...nextTutorialUI, visualState: VisualState.Active },
        },
      })
    );
    store.dispatch(setCareerCarouselDisplayMode('FULL_MODE'));
    return null;
  }
};

export const simpleResolveQuiz = async (submitData: SubmitActionData): Promise<Response | null> => {
  const { careerId, courseId, lessonId, completionId } = submitData;

  const lessonUI: LessonUI = getByIdCareerLessonUI(store.getState(), String(courseId), String(lessonId));
  let quizUI: QuizUI = lessonUI.quizUI as QuizUI;

  if (quizUI.visualState !== VisualState.Completed) {
    quizUI = { ...(lessonUI.quizUI as QuizUI), visualState: VisualState.Completed };
    console.log(...printMsg('Complete Quiz', 'completionId', completionId));
    store.dispatch(addComplete(completionId));
    await updateCareerCompleteList(String(careerId), completionId);
    store.dispatch(setCareerQuizUI({ courseId: String(courseId), lessonUI: { ...lessonUI, quizUI } }));

    const quizBadgeId: BadgeId = quizUI.badgeId;
    await updateCareerBadges(String(careerId), quizBadgeId);
    store.dispatch(addBadge(quizBadgeId));
  }

  return await simpleResolveLesson(lessonUI.submitActionData as SubmitActionData);
};

export const simpleResolveQuestion = async (submitData: SubmitActionData): Promise<Response | null | string> => {
  const { careerId, completionId, courseId, lessonId, questionId } = submitData;

  console.log(...printMsg('Resolving Question', 'questionId', String(questionId)));
  store.dispatch(addComplete(completionId));
  await updateCareerCompleteList(String(careerId), completionId);

  const profileId = store.getState().user.activeProfileId;
  const uid = store.getState().user.uid;
  const history = await createHistoryEntry(convertToCareerHistoryEntry(submitData, profileId, uid));
  console.log(...printMsg('History time', 'timeToSolve', String(history.timeToSolve)));

  const lessonUI = getByIdCareerLessonUI(store.getState(), String(courseId), String(lessonId));
  const quiz: QuizUI = lessonUI.quizUI as QuizUI;
  const baseQuestionUIs = (quiz.baseQuestionUIs as BaseQuestionUIs).slice();
  const qIndex = baseQuestionUIs.findIndex((q) => q.questionId === String(questionId));
  const baseQuestionUI = baseQuestionUIs[qIndex] as BaseQuestionUI;

  let updatedLessonUI = { ...lessonUI };

  if (baseQuestionUI.visualState !== VisualState.Completed) {
    baseQuestionUIs.splice(qIndex, 1, { ...baseQuestionUI, visualState: VisualState.Completed });
    const completedLessonUI: LessonUI = { ...lessonUI, quizUI: { ...quiz, baseQuestionUIs: baseQuestionUIs.slice() } };
    store.dispatch(setCareerQuestionUI({ courseId: String(courseId), lessonUI: { ...completedLessonUI } }));
    updatedLessonUI = { ...completedLessonUI };
  }

  const nextIndex = baseQuestionUIs.findIndex((q) => q.visualState !== VisualState.Completed);
  if (nextIndex > -1) {
    const tempNextQuestionUI = baseQuestionUIs[nextIndex] as BaseQuestionUI;
    baseQuestionUIs.splice(nextIndex, 1, { ...tempNextQuestionUI, visualState: VisualState.Active });
    const nextQuestionLessonUI = { ...updatedLessonUI, quizUI: { ...quiz, baseQuestionUIs: baseQuestionUIs } };
    store.dispatch(setCareerQuestionUI({ courseId: String(courseId), lessonUI: { ...nextQuestionLessonUI } }));
    // store.dispatch(setBaseQuestionUI(tempNextQuestionUI));

    if (getCareerCarouselAutoplay(store.getState())) {
      const submitActionData = tempNextQuestionUI.submitActionData as SubmitActionData;
      const index = getCareerChainActiveIndex(store.getState());
      return redirect(`${submitActionData.simpleActionUrl}?itemIndex=${index}`);
    }
    store.dispatch(setCareerCarouselDisplayMode('FULL_MODE'));
    return null;
  } else {
    // update quiz
    store.dispatch(setBaseQuestionUI(undefined));
    const quizUI = updatedLessonUI.quizUI as QuizUI;
    return await simpleResolveQuiz(quizUI.submitActionData as SubmitActionData);
  }
};
