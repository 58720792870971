// cSpell:ignore AIzaSyAIwxmvex_by8rHyvFZGDRmvKFlQPwUQ7o
const FirebaseConfig = {
  apiKey: 'AIzaSyAIwxmvex_by8rHyvFZGDRmvKFlQPwUQ7o',
  authDomain: 'montessori-11.firebaseapp.com',
  projectId: 'montessori-11',
  storageBucket: 'montessori-11.appspot.com',
  messagingSenderId: '261943747862',
  appId: '1:261943747862:web:cf0b76c958200df6375c37',
  measurementId: 'G-JL4XLZMF5B',
};

export { FirebaseConfig as default };
