/* eslint-disable @typescript-eslint/no-explicit-any */
import { store } from 'app/store';
import { redirect } from 'react-router-dom';
import { db } from '../firebase/Firebase';
import {
  query,
  collection,
  orderBy,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  setDoc,
} from 'firebase/firestore';
import { ManageMedalUpdateResponseT, MedalId, MedalT, MedalsT, Status } from 'types/App.types';

import { useStyledLogger } from 'app/config/LogConfig';

import { doSetActiveScene, setLastActiveManageScene } from 'scenes/scenesSlice';
import { setAwardsStatus, setMedals, updateMedal, setMedalsStatus, getMedalById } from 'scenes/management/managerSlice';
import { setToastNotification } from 'modules/user/userUiSlice';
import { setAppMedals, setAppMedalsStatus } from 'modules/profiles/awards/awardsSlice';
import { isPrint } from 'helpers/appHelpers';
import { getToastSuccessPayload } from 'helpers/uiHelpers';
import { AppRoutes } from 'app/constants/Routes';
import { getInitialMedal, medalConverter, medalToDoc } from 'api/converters/MedalConverter';

const printMsg = useStyledLogger('MedalsAPI', 'coral', 'cornsilk');
const PRINT_FLAG = true;

// #region AWARD APIs *****************************************************************
export const fetchMedals = async (): Promise<MedalsT> => {
  const collectionData = collection(db, 'medals').withConverter(medalConverter);
  const q = query(collectionData, orderBy('createdOn', 'asc'));
  const querySnapshot = await getDocs(q);
  const medals: MedalsT = [];
  querySnapshot.docs.forEach((doc) => {
    const docData: MedalT = doc.data();
    medals.push({
      ...docData,
      medalId: doc.id,
    });
  });

  return medals;
};

export const getMedal = async (medalId: MedalId): Promise<MedalT> => {
  const docRef = doc(db, `medals/${medalId}`).withConverter(medalConverter);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const docData: MedalT = docSnap.data();
    return { ...docData, medalId: medalId };
  } else {
    console.error('No such document!');
    throw Error(`Medal (${medalId}) unable to find`);
  }
};

export const createMedal = async (medal: MedalT): Promise<MedalT> => {
  const collectionData = collection(db, 'medals').withConverter(medalConverter);
  const docRef = doc(collectionData);
  const createdMedal: MedalT = { ...medal, medalId: docRef.id };
  // Create new record with autogenerated id as AwardId
  await setDoc(docRef, createdMedal);
  isPrint(PRINT_FLAG) && console.log(...printMsg('Document written with ID', 'docRef.id', ''));
  return createdMedal;
};

export const patchMedal = async (medal: MedalT): Promise<void> => {
  const medalRef = doc(db, `medals/${medal.medalId}`);
  const docMedal = medalToDoc(medal);
  return await updateDoc(medalRef, docMedal);
};
// #endregion

// #region ROUTER LOADER & ACTION FUNCTIONS **********************************************
export const appMedalSceneLoader = async (): Promise<MedalsT> => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('Loading', 'appMedalSceneLoader', ''));

  store.dispatch(setAppMedalsStatus(Status.Loading));
  const medals: MedalsT = await fetchMedals();
  isPrint(PRINT_FLAG) && console.log(...printMsg('LOADED App Medals', 'count', String(medals.length)));
  store.dispatch(setAppMedals(medals));
  store.dispatch(setAppMedalsStatus(Status.Loaded));

  return medals;
};

export const medalSceneLoader = async ({ params }: any): Promise<MedalT> => {
  const { medalId } = params;

  isPrint(PRINT_FLAG) && console.log(...printMsg('Loading Medal', 'medalId', `${medalId}`));
  const medal = getMedalById(store.getState(), medalId) as MedalT;

  return medal;
};

// #endregion

// #region MANAGE Awards **************************************************************

export const manageMedalsCollectionSceneLoader = async () => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('Loading manageMedalsCollectionSceneLoader', '', ''));
  store.dispatch(doSetActiveScene('manage:medals:collection'));
  store.dispatch(setLastActiveManageScene('medals'));

  const medals = await fetchMedals();
  store.dispatch(setMedals({ collection: medals, status: Status.Loaded }));

  return medals;
};

export const manageCreateMedalSceneLoader = async () => {
  store.dispatch(doSetActiveScene('manage:medal:create'));
  store.dispatch(setLastActiveManageScene('medals'));

  const medal: MedalT = getInitialMedal();
  return { medal };
};

export const manageCreateMedalSceneAction = async () => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('manageCreateMedalSceneAction', 'Creating Medal', ''));
  store.dispatch(setAwardsStatus(Status.Creating));
  const managerMedals = store.getState().manager.medals;
  const indexOfCreatedItem = managerMedals.findIndex((q) => q.medalId === 'createMedalId');
  const medalItem = managerMedals[indexOfCreatedItem] as MedalT;
  const toCreateMedal: MedalT = {
    ...medalItem,
    createdOn: Date.now(),
    modifiedOn: Date.now(),
  };

  const createdMedal = await createMedal(toCreateMedal);
  store.dispatch(updateMedal({ medal: createdMedal, index: indexOfCreatedItem }));
  store.dispatch(setMedalsStatus(Status.Creating));
  store.dispatch(setToastNotification(getToastSuccessPayload('Medal Created')));
  // update main award redux section to indicate it will have to update itself at some point
  store.dispatch(setAppMedalsStatus(Status.Created));
  return redirect(AppRoutes.ManagerMedals);
};

export const manageEditMedalsSceneLoader = async ({ params }: any): Promise<ManageMedalUpdateResponseT> => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('ManageEditMedalSceneLoader', 'Loading ManageEditMedalScene', ''));
  store.dispatch(doSetActiveScene('manage:medal:edit'));
  const medalId = params.medalId;

  const medal = await getMedal(medalId);
  const index = store.getState().manager.medals.findIndex((q) => q.medalId === medalId);
  return { medal, index };
};

export const manageEditMedalSceneAction = async ({ params }: any) => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('manageEditMedalSceneAction', 'Editing Medal', ''));
  const medalId = params.medalId;
  store.dispatch(setAwardsStatus(Status.Updating));
  const managerMedals = store.getState().manager.medals;
  const indexOfEditedItem = managerMedals.findIndex((q) => q.medalId === medalId);
  const medalItem = managerMedals[indexOfEditedItem] as MedalT;
  const medalItemToEdit: MedalT = {
    ...medalItem,
    modifiedOn: Date.now(),
    modifiedBy: store.getState().user.uid,
  };

  await patchMedal(medalItemToEdit);
  store.dispatch(setMedalsStatus(Status.Updated));
  store.dispatch(setToastNotification(getToastSuccessPayload('Medal Updated')));
  // update main award redux section to indicate it will have to update itself at some point
  store.dispatch(setAppMedalsStatus(Status.Updated));
  return redirect(AppRoutes.ManagerMedals);
};

// #endregion
