import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import { BoldWeightT, ElementSizeT, PropsWithChildren } from 'types/App.types';

import styles from './MeTabs.module.scss';
import MeText from 'components/text/metext/MeText';
import MeIcon from 'components/icon/meicon/MeIcon';
import { useAppResize } from 'app/hooks/useResize';

type MeTabsOwnProps = {
  className?: string;
  clearActive?: boolean;
  onActiveChange?: (itemId: string) => void;
  initActiveTab?: string;
};

type MeTabsProps = PropsWithChildren<MeTabsOwnProps>;

const MeTabs: React.FC<MeTabsProps> = ({ initActiveTab, ...props }) => {
  const activeIndicatorRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
  const [activeTab, setActiveTab] = useState<string>('');
  const [activeTabWidth, setActiveTabWidth] = useState<number>(0);
  const [activeTabLeft, setActiveTabLeft] = useState<number>(0);

  const { width } = useAppResize();

  const onTabItemTap = (itemId: string) => {
    setActiveTab(itemId);
    if (props.onActiveChange) props.onActiveChange(itemId);
    // console.log(`On tab clicked ${itemId}`);
  };

  useEffect(() => {
    if (initActiveTab) onTabItemTap(initActiveTab);
  }, [initActiveTab]);

  useEffect(() => {
    if (props.clearActive) onTabItemTap('');
  }, [props.clearActive]);

  useEffect(() => {
    if (!activeTab || activeTab === '') {
      setActiveTabWidth(0);
      setActiveTabLeft(0);
    } else if (activeTab && activeTab !== undefined) {
      // console.log(`ActiveTab: ${activeTab}`);
      const parentEL = document.getElementsByClassName('TabsParent')[0];
      const offsetX = parentEL.getBoundingClientRect().left;
      const activeTabEl = document.getElementsByClassName(activeTab)[0];
      if (activeTabEl) {
        const rect = activeTabEl.getBoundingClientRect();
        setActiveTabWidth(rect.width);
        setActiveTabLeft(rect.left - offsetX);
      }
    }
  }, [activeTab, width]);

  const onTabsTapped = (e: MouseEvent) => {
    const el = e.target as HTMLDivElement;
    const itemId = String(el.dataset.itemId);
    // console.log(`onTabsTapped itemId=${itemId}`);
    onTabItemTap(itemId);
  };

  return (
    <div className={`${styles.MeTabs} ${props.className ?? ''} TabsParent`} onClick={onTabsTapped}>
      {props.children}
      <div
        ref={activeIndicatorRef}
        className={styles.MeTabsActiveIndicator}
        style={{ width: `${activeTabWidth}px`, left: `${activeTabLeft}px` }}>
        {/* active indicator */}
      </div>
    </div>
  );
};

type TabState = 'ON' | 'OFF';

type MeTabProps = {
  tabId: string;
  className?: string;
  onTabTapped?: (tabId: string) => void;
  title?: string;
  size?: ElementSizeT;
  weight?: BoldWeightT;
  icon?: string;
  iconSize?: ElementSizeT;
  iconWeight?: BoldWeightT;
  tabState: TabState;
  activeTab: string;
};

const MeTab: React.FC<MeTabProps> = ({
  tabId,
  className = '',
  onTabTapped = () => undefined,
  iconWeight = 'bold400',
  iconSize = 'xs145',
  weight = 'bold400',
  size = 'xs12',
  activeTab = '',
  ...props
}) => {
  const onTap = () => onTabTapped(tabId);
  const baseCls = `${styles.MeTabsItem} ${className} ${tabId}`;
  const isActive = activeTab === tabId;

  return (
    <MeText useDiv insetText onClick={onTap} id={tabId} className={baseCls} textSize={size} weight={weight}>
      {props.icon && (
        <MeIcon
          id={tabId}
          size={iconSize}
          isFilled={isActive}
          weight={iconWeight}
          icon={props.icon}
        />
      )}{' '}
      {props.title && props.title}
    </MeText>
  );
};

export { MeTabs as default, MeTab };
