import { store } from 'app/store';
import { defer, redirect } from 'react-router-dom';
import { db } from '../firebase/Firebase';
import { collection, query, getDocs, doc, setDoc } from 'firebase/firestore';

import {
  AppConfig,
  AppConfigT,
  ArticlesConfigT,
  Dictionary,
  LegalInfoT,
  LegalSubpageT,
  MessageT,
  Status,
  SubpageT,
} from 'types/App.types';
import { BadgesT } from 'types/Career.types';

import { isPrint } from 'helpers/appHelpers';
import { AppRoutes } from 'app/constants/Routes';
import { getFormattedString, getNameClsString } from 'app/config/LogConfig';
import { DeferReturnData } from 'api/GameAPI';
import { messageConverter } from 'api/converters/MessageConverter';

import { doSetActiveScene } from 'scenes/scenesSlice';
import { setCrests, setCrestsStatus } from 'modules/badges/badgesUiSlice';
import { setBadgesUi } from 'modules/career/careerSlice';
import { setAppConfig, setAppConfigStatus } from 'scenes/montessori/appUiSlice';
import { isActiveProfileIdSelected } from 'modules/user/userSlice';

const PRIMARY_COLOR = getNameClsString('dodgerblue', 'cornsilk');
const CLS_NAME = 'AppAPI';
const PRINT_FLAG = true;

const printMsg = (action: string, prop: string, status: string) =>
  getFormattedString(PRIMARY_COLOR, CLS_NAME, action, prop, status);

export const fetchBadges = async (): Promise<BadgesT> => {
  const collectionData = collection(db, 'badges');
  const q = query(collectionData);

  const querySnapshot = await getDocs(q);
  const badges: BadgesT = [];
  querySnapshot.docs.forEach((doc) => {
    const docData = doc.data();
    badges.push({
      badgeId: doc.id,
      category: docData.category,
      imageUrl: docData.imageUrl,
      name: docData.name,
      variant: docData.variant,
    });
  });

  return badges;
};

export const fetchAppConfig = async (): Promise<AppConfigT> => {
  const collectionData = collection(db, 'app');
  const q = query(collectionData);

  const querySnapshot = await getDocs(q);
  const appConfig: AppConfigT = {
    news: {
      articles: [],
    },
  };

  querySnapshot.docs.forEach((doc) => {
    const docData = doc.data();
    if (doc.id === AppConfig.News) {
      const docNewsArticlesData = docData as ArticlesConfigT;
      appConfig.news = docNewsArticlesData;
    }
  });

  return appConfig;
};

export const createMessage = async (messageToSend: MessageT): Promise<MessageT> => {
  const collectionData = collection(db, 'messages').withConverter(messageConverter);
  const docRef = doc(collectionData);
  const createdMessage: MessageT = { ...messageToSend, messageId: docRef.id };
  // Create new record with autogenerated id as messageId
  await setDoc(docRef, createdMessage);
  isPrint(PRINT_FLAG) && console.log(...printMsg('Created Message', 'messageId', docRef.id));
  return createdMessage;
};

const terms: SubpageT = {
  title: 'Terms Of Use',
  shortTitle: 'Terms Of Use',
  url: '/legal/terms-of-use',
  icon: 'handshake',
};

const privacy: SubpageT = {
  title: 'Privacy Policy',
  shortTitle: 'Privacy',
  url: '/legal/privacy-policy',
  icon: 'policy',
};

const cookies: SubpageT = {
  title: 'Cookie Policy',
  shortTitle: 'Manage cookies',
  url: '/legal/cookie-policy',
  icon: 'cookie',
};

const license: SubpageT = {
  title: 'License and Services Agreement',
  shortTitle: 'License',
  url: '/legal/license-and-services-agreement',
  icon: 'license',
};

// const ai: SubpageT = {
//   title: 'AI Generator Terms of Service',
//   shortTitle: 'AI ToS',
//   url: '/legal/ai-generator-terms-of-service',
//   icon: 'robot',
// };

export const validateActiveProfileSelected = (): boolean | Response => {
  const hasActiveProfile = isActiveProfileIdSelected(store.getState());
  return hasActiveProfile ? true : redirect(AppRoutes.Hub);
};

// #region LOADER & ACTION FUNCTIONS ****************************************************
export const appConfigLoader = async (): Promise<AppConfigT> => {
  store.dispatch(setAppConfigStatus(Status.Loading));
  const appConfig = await fetchAppConfig();
  store.dispatch(setAppConfig({ config: appConfig, status: Status.Loaded }));
  isPrint(PRINT_FLAG) && console.log(...printMsg('LOADED', 'AppConfigLoader', ''));
  return appConfig;
};

export const appBadgesSceneLoader = async (): Promise<BadgesT> => {
  store.dispatch(setCrestsStatus(Status.Loading));
  const badges: BadgesT = await fetchBadges();
  isPrint(PRINT_FLAG) && console.log(...printMsg('LOADED App Badges', 'count', String(badges.length)));
  store.dispatch(setBadgesUi(badges));
  store.dispatch(setCrests(badges));
  store.dispatch(setCrestsStatus(Status.Loaded));

  return badges;
};

// #region LEGAL LOADER & ACTION FUNCTIONS **********************************************
export const montessoriLegalLoader = async (): Promise<DeferReturnData> => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('Loading Legal Information', '', ''));
  store.dispatch(doSetActiveScene('legal'));

  const legalInfo = getLegal();

  return defer({ legalInfo });
};

const getLegal = async (): Promise<LegalInfoT> => {
  const legalInfo: LegalInfoT = {
    title: 'Legal',
    // subpages: [terms, privacy, cookies, license, ai],
    subpages: [terms, privacy, cookies, license],
  };

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(legalInfo);
    }, 1000);
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const montessoriLegalSubpageLoader = async ({ params }: any): Promise<LegalSubpageT> => {
  const subpageId: string = params.subpageId;

  const legalSubpages: Dictionary<LegalSubpageT> = {
    'terms-of-use': { ...terms, subpageId: 'termsId' },
    'privacy-policy': { ...privacy, subpageId: 'privacyId' },
    'cookie-policy': { ...cookies, subpageId: 'cookieId' },
    'license-and-services-agreement': { ...license, subpageId: 'licenseId' },
    // 'ai-generator-terms-of-service': { ...ai, subpageId: 'aiId' },
  };

  const subpage = legalSubpages[subpageId];
  isPrint(PRINT_FLAG) && console.log(...printMsg('LOAD', 'Legal subpage', subpage.title));
  return subpage;
};
// #endregion
// #endregion
