import { FirestoreDataConverter, DocumentData, QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { getModifiedInfo } from 'helpers/careerHelpers';
import { encodeUserPassKey } from 'modules/user/secret/userHelper';
import { IUser, ResetInfoT } from 'types/User.types';

// #region CONVERTERS
export const userToDoc = (user: IUser): DocumentData => {
  return {
    uid: user.uid,
    activeProfileId: user.activeProfileId,
    email: user.email,
    emailVerified: user.emailVerified,
    userLevel: user.userLevel,
    userPassKey: user.userPassKey,
    userPassKeyEnabled: user.userPassKeyEnabled,
    firstname: user.firstname,
    lastname: user.lastname,
    isAdmin: user.isAdmin,
    isEditor: user.isEditor,
    profileIds: user.profileIds,
    createdBy: user.createdBy,
    createdOn: user.createdOn,
    modifiedBy: user.modifiedBy,
    modifiedOn: user.modifiedOn,
  };
};

export const userConverter: FirestoreDataConverter<IUser> = {
  toFirestore(user: IUser): DocumentData {
    return { ...userToDoc(user), ...getModifiedInfo() };
  },

  fromFirestore(docSnap: QueryDocumentSnapshot, options?: SnapshotOptions): IUser {
    const user = docSnap.data(options);
    return {
      uid: user.uid,
      activeProfileId: user.activeProfileId,
      userPassKey: user.userPassKey ?? encodeUserPassKey('0000', user.uid),
      userPassKeyEnabled: !!user.userPassKeyEnabled,
      userLevel: user.userLevel ?? 0,
      email: user.email,
      emailVerified: user.emailVerified || false,
      firstname: user.firstname,
      lastname: user.lastname,
      isAdmin: user.isAdmin,
      isEditor: user.isEditor,
      profileIds: user.profileIds,
      createdBy: user.createdBy,
      createdOn: user.createdOn,
      modifiedBy: user.modifiedBy,
      modifiedOn: user.modifiedOn,
    };
  },
};
// #endregion

export const resetToDoc = (reset: ResetInfoT): DocumentData => {
  return {
    resetId: reset.resetId,
    uid: reset.uid,
    kind: reset.kind,
    userEmail: reset.userEmail,
    name: reset.name,
    passKey: reset.passKey,
    createdBy: reset.createdBy,
    createdOn: reset.createdOn,
  };
};

export const userToReset = (user: IUser): ResetInfoT => {
  return {
    resetId: '',
    uid: user.uid,
    kind: 'passkey',
    userEmail: user.email,
    name: `${user.firstname} ${user.lastname}`,
    passKey: user.userPassKey,
    createdBy: user.uid,
    createdOn: Date.now(),
  };
};

export const resetConverter: FirestoreDataConverter<ResetInfoT> = {
  toFirestore(reset: ResetInfoT): DocumentData {
    return resetToDoc(reset);
  },

  fromFirestore(docSnap: QueryDocumentSnapshot, options?: SnapshotOptions): ResetInfoT {
    const reset: ResetInfoT = docSnap.data(options) as ResetInfoT;
    return {
      resetId: reset.resetId,
      uid: reset.uid,
      kind: reset.kind,
      userEmail: reset.userEmail,
      name: reset.name,
      passKey: reset.passKey,
      createdBy: reset.createdBy,
      createdOn: reset.createdOn,
    };
  },
};
