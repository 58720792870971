import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'app/hooks/hooks';
import { SpotlightEls } from 'app/constants/SpotlightElements';
import { isNumber } from 'helpers/mathHelpers';

import { getAwardCodesCount } from 'modules/career/careerSlice';
import { getAwardStarsCount } from 'modules/profiles/awards/awardsSlice';
import InsetIcon from 'components/icon/InsetIcon';
import InsetText from 'components/text/inset/InsetText';
import StarCounter from 'components/awardsdisplay/components/starcounter/StarCounter';

import styles from './AwardsDisplay.module.scss';

type AwardsDisplayProps = {
  className?: string;
};

const AwardsDisplay: React.FC<AwardsDisplayProps> = ({ className = '' }) => {
  const wobbleAnimation = styles.AwardsDisplayAnimateStar;
  const [starAnimation, setStarAnimation] = useState<string>('');
  const [isCounterRunning, setIsCounterRunning] = useState<boolean>(false);

  const starCount = useAppSelector(getAwardStarsCount);
  const userStarsCount = useAppSelector(getAwardCodesCount);

  useEffect(() => {
    if (starCount) {
      setStarAnimation(wobbleAnimation);
    }
  }, [starCount]);

  useEffect(() => {
    if (isCounterRunning) {
      setStarAnimation(wobbleAnimation);
    }
  }, [isCounterRunning]);

  useEffect(() => {
    if (isNumber(starCount) && isNumber(userStarsCount)) {
      // console.log(`userStarsCount: ${userStarsCount}, starCount: ${starCount}`);
    }
  }, [starCount, userStarsCount]);

  const onCounterStarted = () => {
    setIsCounterRunning(true);
    setStarAnimation(wobbleAnimation);
  };

  const onCounterEnded = () => {
    setIsCounterRunning(false);
    setStarAnimation('');
  };

  return (
    <>
      <div className={`${styles.AwardsDisplay} ${className}`}>
        <InsetIcon
          size="xs75"
          isFilled
          icon="family_star"
          wrapperCls={starAnimation}
          className={SpotlightEls.StarAwardsDisplay}
        />
        <StarCounter count={userStarsCount} animationStarted={onCounterStarted} animationOver={onCounterEnded} />
        <InsetText size="xs9">/</InsetText>
        <InsetText size="xs9">{starCount}</InsetText>
      </div>
    </>
  );
};

export default AwardsDisplay;
