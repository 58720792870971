import React, { useRef } from 'react';

import { PropsWithChildren, VoidFunc } from 'types/App.types';
import Controls from './controls/Controls';

import styles from './Carousel.module.scss';

type OwnProps = {
  className?: string;
  carouselId?: string;
  items?: Array<unknown>;
  actionControls?: JSX.Element;
  carouselDisplayMode?: DisplayModeT;
  onStartScroll?: VoidFunc;
  onEndScroll?: VoidFunc;
} & Pick<React.HTMLAttributes<HTMLDivElement>, 'onClick' | 'onScroll' | 'style'>;

type CarouselProps = PropsWithChildren<OwnProps>;
export type DisplayModeT = 'FULL_MODE' | 'MINI_MODE';
export type CarouselScrollBehaviorT = 'auto' | 'smooth';

const Carousel: React.FC<CarouselProps> = ({ carouselId, carouselDisplayMode = 'FULL_MODE', onScroll, ...props }) => {
  const scrollRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);

  return (
    <div className={`${styles.CarouselLayout} ${props.className || ''}`}>
      <div className={`${styles.Carousel} ${carouselDisplayMode}`}>
        <div ref={scrollRef} id={carouselId} className={styles.CarouselBody} style={props.style} onScroll={onScroll}>
          <div className={styles.CarouselBodyWrapper}>{props.children}</div>
        </div>
      </div>
      <Controls>{props.actionControls}</Controls>
    </div>
  );
};

export default Carousel;
