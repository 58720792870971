import React from 'react';
import { BoldWeightT, ElementSizeT } from 'types/App.types';
import MeIcon from 'components/icon/meicon/MeIcon';

import styles from './SceneLoader.module.scss';

type SceneLoaderProps = {
  className?: string;
  animate?: boolean;
  visible?: boolean;
  size?: ElementSizeT;
  weight?: BoldWeightT;
} & React.HTMLAttributes<HTMLAllCollection>;

const SceneLoader: React.FC<SceneLoaderProps> = ({
  className = '',
  animate = false,
  visible = true,
  size = 'xl7',
  weight = 'bold100',
}) => {
  const animateCls = animate ? 'animate' : '';
  const visibleCls = visible ? 'shown' : 'hidden';

  return (
    <div className={`${styles.SceneLoaderDisplay} ${className} ${visibleCls} ${animateCls}`}>
      {animate && (
        <>
          <div className={styles.SceneLoaderRotate}>
            <MeIcon icon="progress_activity" className={`${styles.SceneLoaderRotateIcon} ${size} ${'bold300'}`} />
          </div>
          <div className={styles.SceneLoaderRotate}>
            <img src='/images/logo-48x48.png' alt='logo' className={styles.Logo} />
          </div>
          <div className={styles.SceneLoaderRotate}>
            <MeIcon icon="progress_activity" className={`${styles.SceneLoaderRotateIcon} 'xl6' ${weight}`} />
          </div>
        </>
      )}
    </div>
  );
};

export default SceneLoader;
