import React from 'react';
import { PropsWithChildren, VoidFunc } from 'types/App.types';


import styles from './CardShell.module.scss';

type CardShellOwnProps = {
  className?: string;
  cardShellClassName?: string;
  onClick?: VoidFunc;
  clickable?: boolean;
} & Pick<React.HTMLAttributes<HTMLDivElement>, 'style'>;

type CardShellProps = PropsWithChildren<CardShellOwnProps>;

const CardShell: React.FC<CardShellProps> = ({
  className = '',
  cardShellClassName = '',
  onClick = () => undefined,
  ...props
}) => {
  const clickableCls = props.clickable ? styles.Clickable : '';
  return (
    <div style={props.style} className={`${styles.CardShellBorder} ${clickableCls} ${className}`}>
      <div className={`${styles.CardShell} ${cardShellClassName}`} onClick={onClick}>
        {props.children}
      </div>
    </div>
  );
};

export default CardShell;
