/* eslint-disable @typescript-eslint/no-explicit-any */
import { store } from 'app/store';
import { analytics, db, logEvent } from '../firebase/Firebase';
import { User } from 'firebase/auth';
import {
  collection,
  doc,
  DocumentData,
  DocumentReference,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { redirect } from 'react-router-dom';

import { MaybeType } from 'types/App.types';
import {
  IUser,
  IProfile,
  UserStatusT,
  LoadedUserWithProfilesPayload,
  ProfilesT,
  IUsers,
  CreatedUserWithProfilePayload,
  ManageUserPayload,
  ResetInfoT,
} from 'types/User.types';

import { AppRoutes } from 'app/constants/Routes';
import { useStyledLogger } from 'app/config/LogConfig';
import { isPrint } from 'helpers/appHelpers';
import { getToastErrorPayload, getToastSuccessPayload } from 'helpers/uiHelpers';
import { encodeUserPassKey } from 'modules/user/secret/userHelper';
import { getModifiedInfo } from 'helpers/careerHelpers';

import { fetchProfilesByUserId, fetchUserProfiles, patchUserProfile } from './ProfilesAPI';
import { resetConverter, resetToDoc, userConverter, userToDoc, userToReset } from 'api/converters/UserConverter';

import { doSetActiveScene, setLastActiveManageScene } from 'scenes/scenesSlice';
import { setToastNotification } from 'modules/user/userUiSlice';
import { UserState, setUserProfiles } from 'modules/user/userSlice';
import { getManagedUserById, setUsers } from 'scenes/management/managerSlice';

type ResponseActionType = LoadedUserWithProfilesPayload | Response;

const PRIMARY_COLOR = 'darkgreen';
const SECONDARY_COLOR = 'cornsilk';
const CLS_NAME = 'UserAPI';
const PRINT_FLAG = true;

const printMsg = useStyledLogger(CLS_NAME, PRIMARY_COLOR, SECONDARY_COLOR);

// #region APIs
export const fetchUsers = async (): Promise<IUsers> => {
  const collectionData = collection(db, 'users').withConverter(userConverter);
  const q = query(collectionData);

  const querySnapshot = await getDocs(q);
  isPrint(PRINT_FLAG) && console.log(...printMsg('fetchUsers', 'Profiles Count', String(querySnapshot.size)));

  const users: IUsers = [];
  querySnapshot.docs.forEach((doc) => {
    const docData = doc.data();
    users.push(docData);
  });

  return users;
};

const isUserNotYetSignedIn = (status: UserStatusT) => status === 'idle' || status === 'signingIn';

export const userStatusUpdated = (): Promise<UserStatusT> => {
  const userStatus = store.getState().user.userStatus;
  return new Promise((resolve) => {
    if (isUserNotYetSignedIn(userStatus)) {
      let count = 0;
      const intervalId = setInterval(() => {
        const latestUserStatus = store.getState().user.userStatus as UserStatusT;
        if (latestUserStatus === 'signedIn') {
          isPrint(PRINT_FLAG) && console.log(...printMsg('userStatusSignedIn', 'count', String(count)));
          clearInterval(intervalId);
          resolve(latestUserStatus);
        } else if (latestUserStatus === 'notSignedIn') {
          isPrint(PRINT_FLAG) && console.log(...printMsg('userStatusNotSignedIn', 'count', String(count)));
          clearInterval(intervalId);
          resolve(latestUserStatus);
        } else if (latestUserStatus === 'idle' && count > 4) {
          isPrint(PRINT_FLAG) && console.log(...printMsg('userStatusIdle', 'count', String(count)));
          clearInterval(intervalId);
          resolve(latestUserStatus);
        }
        count++;
      }, 250);
    } else {
      resolve(userStatus);
    }
  });
};

export const fetchUserInfo = async (userUid: string, emailVerified = false): Promise<IUser> => {
  const docRef: DocumentReference<IUser> = doc(db, `users/${userUid}`).withConverter(userConverter);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    const user: IUser = docSnap.data();
    const userPassKey = user.userPassKey ?? encodeUserPassKey('0000', user.uid);
    const updatedUser = { ...user, userPassKey: userPassKey, emailVerified: emailVerified };
    if (emailVerified && !user.emailVerified) {
      await updateUserAccount(updatedUser);
      return updatedUser;
    } else {
      return updatedUser;
    }
  } else {
    isPrint(PRINT_FLAG) && console.log(...printMsg('Error', 'fetchUserInfo', 'Person does not exists'));
    logEvent(analytics, 'exception', { description: `Person does not exist for path=/users/${userUid}` });
    throw Error(`Person does not exist for path=/users/${userUid}`);
  }
};

export const updateUserAccount = async (user: IUser): Promise<void> => {
  const userRef = doc(db, `users/${user.uid}`);
  const modified = getModifiedInfo();
  const userToUpdate: IUser = { ...user, ...modified };
  const docToUpdate = userToDoc(userToUpdate);
  return await updateDoc(userRef, docToUpdate);
};

export const setUserProfileAsActive = async (profileId: string): Promise<IUser> => {
  const userModel: UserState = store.getState().user;

  const userRef = doc(db, `users/${userModel.uid}`);
  const userToUpdate: IUser = { ...userModel, activeProfileId: profileId, modifiedOn: Date.now() };
  const docToUpdate = userToDoc(userToUpdate);
  await updateDoc(userRef, docToUpdate);

  return userToUpdate;
};

export const createInitialUser = (uid: string, email?: string | null) => ({
  uid: uid,
  activeProfileId: '',
  userPassKey: encodeUserPassKey('0000', uid),
  userPassKeyEnabled: false,
  userLevel: 0,
  email: email || 'missing email?',
  emailVerified: false,
  firstname: "Parent's firstname",
  lastname: "Parent's lastname",
  isAdmin: false,
  isEditor: false,
  profileIds: [],
  createdBy: uid,
  createdOn: Date.now(),
  modifiedBy: uid,
  modifiedOn: Date.now(),
});

export const createUserRecordOnUserAccountCreation = async (user: User): Promise<IUser> => {
  const userToCreate: IUser = { ...createInitialUser(user.uid, user.email) };
  //   uid: user.uid,
  //   activeProfileId: '',
  //   userPassKey: encodeUserPassKey('0000', user.uid),
  //   userPassKeyEnabled: false,
  //   userLevel: 0,
  //   email: user.email || 'missing email?',
  //   emailVerified: false,
  //   firstname: "Parent's firstname",
  //   lastname: "Parent's lastname",
  //   isAdmin: false,
  //   isEditor: false,
  //   profileIds: [],
  //   createdBy: user.uid,
  //   createdOn: Date.now(),
  //   modifiedBy: user.uid,
  //   modifiedOn: Date.now(),
  // };
  const docUser: DocumentData = userToDoc(userToCreate);
  await setDoc(doc(db, 'users', user.uid), docUser);

  return userToCreate;
};

export const resetUserPassKey = async (user: IUser): Promise<void> => {
  await updateUserAccount(user);
  const resetObj: ResetInfoT = userToReset(user);

  const collectionData = collection(db, 'reset').withConverter(resetConverter);
  const docRef = doc(collectionData);
  const createdResetEntry: ResetInfoT = { ...resetObj, resetId: docRef.id };
  const resetData: DocumentData = resetToDoc(createdResetEntry);
  // Create new record with autogenerated id as errorId
  isPrint(PRINT_FLAG) && console.log(...printMsg('Created ResetEntry', 'resetId', docRef.id));
  return await setDoc(docRef, resetData);
};
// #endregion

// #region ROUTER LOADER & ACTION FUNCTIONS **********************************************
export const ownUserAccountInfoSceneLoader = async ({ params }: any): Promise<ResponseActionType> => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('Load OwnUserAccountInfoScene', '', ''));
  store.dispatch(doSetActiveScene('career:account'));
  const userId: string = params.accountId;
  try {
    const user: IUser = await fetchUserInfo(userId);
    const profiles: ProfilesT = await fetchProfilesByUserId(user.uid);
    store.dispatch(setUserProfiles(profiles));
    return { user, profiles };
  } catch (error) {
    isPrint(PRINT_FLAG) && console.log(...printMsg('Error', 'ownUserAccountInfoSceneLoader', String(error)));
    logEvent(analytics, 'exception', { description: String(error) });
    return redirect(AppRoutes.Login);
  }
};

export const ownUserAccountInfoURLLoader = async ({ params }: any): Promise<string> => {
  const userId: string = params.accountId;
  return `account/${userId}`;
};

export const ownUserAccountProfileInfoURLLoader = async ({ params }: any): Promise<string> => {
  const userId: string = params.accountId;
  const profileId: string = params.profileId;
  return `account/${userId}/profile/${profileId}`;
};

export const ownUserAccountInfoSceneAction = async ({ params }: any): Promise<ResponseActionType> => {
  const userId: string = params.accountId;
  const user = store.getState().user;
  if (user.uid === userId) {
    await updateUserAccount(user);
    const profiles = store.getState().user.profiles;
    store.dispatch(setToastNotification(getToastSuccessPayload('Account Info Updated')));
    return { user, profiles };
  }
  const errorMsg = `User cannot change another user data for personPath=/users/${userId}`;
  logEvent(analytics, 'exception', { description: errorMsg });
  return redirect(AppRoutes.Hub);
};

type CompleteCreateAccountPayload = CreatedUserWithProfilePayload | Response;
export const completeCreateOwnAccountLoader = async ({ params }: any): Promise<CompleteCreateAccountPayload> => {
  const userId: string = params.accountId;

  const userStatus = await userStatusUpdated();
  // store.dispatch(doSetActiveScene('montessori'));
  if (userStatus === 'signedIn') {
    store.dispatch(doSetActiveScene('account:create'));
    const user = store.getState().user;
    const profile = store.getState().user.profiles[0];

    if (user.uid === userId) {
      return { user, profile };
    }
  }
  isPrint(PRINT_FLAG) && console.log(...printMsg('Error in completeCreateOwnAccountLoader', 'invalid userId', userId));
  const errorMsg = `ERROR in completeCreateOwnAccountLoader, INVALID userId=(${userId})`;
  logEvent(analytics, 'exception', { description: errorMsg });
  return redirect(AppRoutes.Hub);
};

export const completeCreateOwnAccountWithProfileAction = async ({ params }: any): Promise<Response> => {
  const userId: string = params.accountId;
  const user = store.getState().user;
  if (user.uid === userId) {
    await updateUserAccount(user);
    const profile: IProfile = store.getState().user.profiles[0];
    await patchUserProfile(profile);
    store.dispatch(setToastNotification(getToastSuccessPayload('Account Info Updated')));
    return redirect(AppRoutes.Hub);
  }
  // const errorMsg = `User cannot change another user data for personPath=/users/${userId}`;
  const errorMsg = `ERROR in completeCreateOwnAccountWithProfileAction, INVALID userId=(${userId})`;
  logEvent(analytics, 'exception', { description: errorMsg });
  return redirect(AppRoutes.Hub);
};
// #endregion

// #region EDITOR MANAGER ROUTER LOADER & ACTION FUNCTIONS **********************************************
export const manageUserCollectionSceneLoader = async () => {
  store.dispatch(doSetActiveScene('manage:user:collection'));
  store.dispatch(setLastActiveManageScene('user'));

  const users: IUsers = await fetchUsers();

  store.dispatch(setUsers({ collection: users, status: 'loaded' }));
  return users;
};

export const manageEditUserBreadcrumbUrlLoader = ({ params }: any): string => {
  const userId = params.accountId;
  return AppRoutes.ManagerUserEdit.replace(':accountId', userId);
};

export const manageEditUserSceneLoader = async ({ params }: any): Promise<ManageUserPayload> => {
  store.dispatch(doSetActiveScene('manage:user:edit'));

  if (params.accountId) {
    const user: MaybeType<IUser> = getManagedUserById(store.getState(), params.accountId);
    if (user) {
      const profiles: ProfilesT = await fetchUserProfiles(user.profileIds);
      return { user, profiles };
    }
  }
  throw redirect(AppRoutes.ManagerUsers);
};

export const manageEditUserByUserIdAction = async ({ params }: any) => {
  const userId = params.accountId;

  const users: IUsers = store.getState().manager.users;
  const userToUpdate = users.find((u) => u.uid === userId);

  if (userToUpdate) {
    await updateUserAccount(userToUpdate);
    store.dispatch(setToastNotification(getToastSuccessPayload('User Updated')));
  } else {
    store.dispatch(setToastNotification(getToastErrorPayload('Unable to Update User')));
  }

  return redirect(AppRoutes.ManagerUsers);
};

// #endregion
