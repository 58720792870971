// #region BUTTON LABELS ****************************************************************
export const ButtonLabel = {
  AdditionDemo: 'ADDITION DEMO',
  BoardIntro: 'BOARD INTRO TUTORIAL',
  ClickToSignUp: 'CLICK TO SIGN UP',
  ClickToLogin: 'CLICK TO LOGIN',
  CompleteSignUp: 'FINISH ENROLLMENT',
  Create: 'Create',
  FindActive: 'FIND ACTIVE CARD',
  ForgotPassword: 'Forgot Password?',
  Login: 'LOGIN',
  MultiplicationDemo: 'MULTIPLICATION DEMO',
  ResumeCareer: 'RESUME CAREER',
  Select: 'SELECT',
  SignUp: 'Sign up',
  StartCareer: 'START CAREER',
  Update: 'UPDATE',
  Upload: 'UPLOAD',
  ViewAwards: 'VIEW AWARDS',
  ViewReportCard: 'VIEW REPORT CARD',
};
// #endregion
// #region HEADER TITLES ****************************************************************
export const HeaderTitle = {
  AddRemoveCourses: 'Add/Remove Courses',
  Autoplay: 'AUTOPLAY',
  Career: 'Career',
  CareerManagement: 'Career Management',
  CarouselSettings: 'Carousel Settings',
  CloseBetaRelease: 'Close Beta Release',
  HideCompletedCourses: 'HIDE COMPLETED COURSES',
  InteractiveDemo: 'Interactive Demo',
  InteractiveLearning: 'Interactive Learning Careers and Analysis',
  QuestionsDemo: 'Questions Demo',
  Screenshots: 'Screenshots',
  ScrollToActiveCard: 'SCROLL TO ACTIVE CARD',
  TutorialsQuestions: 'Find Tutorials / Questions',
  CreateMessage: 'Send Us a Message',
  EnterPassCode: 'ENTER PARENT PASS CODE',
  UpdatePassCode: 'UPDATE PARENT PASS CODE',
  MedalDetails: 'Medal details',
  WalkAboutHelp: 'Game Instructions',
};
// #endregion
// #region COMPONENT TITLES *************************************************************
export const ComponentTitle = {
  ActiveProfile: 'Active Student',
  BadgesEarned: 'Badges Earned',
  CareerProgress: 'CAREER PROGRESS',
  Courses: 'Courses',
  Message: 'Message',
  Progress: 'Progress',
  Subject: 'Subject',
  AccountEmailVerified: 'Account Email Verified',
  VerifyAccountEmail: 'Verity Account Email',
};
// #endregion
// #region DESCRIPTION INFO *************************************************************
export const Description = {
  CareerInfo:
    'Progress your career by completing tutorials and solving questions. Replay any completed question or tutorial if a refresher is needed. Use quick menu to quickly locate any question or tutorial in your career.',
  QuestionInfo:
    'Using the Montessori board students will master solving addition, subtraction, multiplication, and division questions.',
  HubInfo:
    'Each profile has its own career home hub, informing students of their current progress and offer the access to all important areas of the app.',
  AwardInfo:
    'Students will earn stars, badges, and crests as they complete different stages of their career, all awards could be seen in the Award Gallery.',
  GameInfo:
    'Different math games will unlock after students complete tutorials and solve questions in different categories.',
  ProfileInfo:
    'Each account will offer parents the ability to create up to three separate profiles, which means each sibling can have their own profile and all their own progress tracking without the need to manage multiple accounts.',
  CurriculumInfo:
    'As a parent, guardian, student supervisor, you can control which courses to assign, and when, to your students.',
  ReportInfo:
    'The report card section is meant to highlight to parents / guardians, or student supervisors the areas where their students excel and the areas where additional attention would be beneficial. The information is meant to help parents discover the best path to develop the strong math foundation while keeping "student" motivated, engaged and curious throughout the journey.',
  // Help Panel descriptions
  HelpCoursesProgressBar:
    'Courses progress bar shows your current progress and the total amount of courses currently assigned to you.',
  HelpCoursesMyProfile:
    'You can add or modify your course workload. To accomplish that, visit My Profile section in the App menu.',
  HelpCoursesShortcut:
    'You can also access My Profile section by tapping on the Edit Courses button next to the Courses progress bar.',
  HelpStarAwardsDisplay:
    'A Star is awarded to students when they complete or discover a hidden challenge. Challenges are hidden throughout the app, can you find all the hidden challenges?',
  HelpBadgeProgressBar:
    'A student is a awarded a badge when they complete a lesson in My Career section. A crest will be awarded when they complete a course.',
  HelpAwardsSectionBtn:
    'The Awards section is a gallery of your unlocked awards and achievements. This gallery contains all memorabilia earned during your career.',
  HelpChapterTabs:
    'The jump to Chapter panel helps students to quickly navigate to a particular section of the tutorial.',
  HelpTutorialAudioBtn:
    'When an audio button is available on a given screen, a student can then listen to the description of this page via audio.',
  HelpTutorialNotesPanel:
    'This is the Notes Section of the Tutorial, this is where the new material of a Tutorial is being presented to the student.',
  HelpTutorialPauseBtn:
    'The Pause button stops the Tutorial and navigates student to the main section of My Career. Student will have to redo the Tutorial if the Pause button was pressed.',
  HelpTutorialPreviousBtn:
    'The previous button allows students to navigate one page back in case a student wants to review the previous page notes.',
  HelpTutorialNextBtn:
    'The Next button will advance the Tutorial to the next page all the way to the end of the tutorial.',
  HelpTutorialQuestionMarkBtn:
    'The question mark button will open the help menu specifically tailored for that page, including descriptions of features appearing on that page.',
  HelpMinusBalanceLivesPanel:
    'Students begin each level with three "lives", they lose one life for every invalid answer. If teh student loses all three lives, the game ends.',
  HelpMinusBalanceActiveRow:
    'The Active Row may contain between 2 and 10 fruit. The student must subtract the right number of fruit from this row such that the remaining number of fruit fills all the open spots in a gate row in order to pass the question.',
  HelpMinusBalanceGateRow:
    'The Gate Row contains between 1 and 9 open spots, depending on the difficulty level. An empty square indicates an open spot, and a filled square with an X indicates a closed spot.',
  HelpMinusBalanceActionButtons:
    'Selecting one of 5 available choices, students are tasked with solving the question of how many fruit must be subtracted from the action row in order to exactly fill all open gate spots with the remaining spots.',
  HelpBridgeBuilderBoardPanel:
    'The Board is where the available blocks are placed in order to create a support columns for a bridge. Clicking on any empty square will place a block in that column. Clicking on the block will remove it from the board.',
  HelpBridgeBuilderUserBlocks:
    'The User blocks section indicates the number of available (or remaining) blocks user must place on the game board.',
};
// #endregion
// #region COLLAPSIBLE HANDLE ***********************************************************
export const Handle = {
  QuickMenu: 'Quick Menu',
  News: 'News',
};
// #endregion
