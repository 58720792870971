import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

export type BlockId = string;
export type BlocksT = Array<BlockId>;

export type CareerUiState = {
  selectedBlocks: BlocksT;
  breakupBlocks: boolean;
};

const initialState: CareerUiState = {
  selectedBlocks: [],
  breakupBlocks: false,
};

export const blocksUiSlice = createSlice({
  name: 'blocksUi',
  initialState,
  reducers: {
    setSelectedBlocks: (state, action: PayloadAction<BlocksT>) => {
      state.selectedBlocks = action.payload;
    },
    addSelectedBlock: (state, action: PayloadAction<BlockId>) => {
      state.selectedBlocks = state.selectedBlocks.concat(action.payload);
    },
    setBreakupBlocks: (state, action: PayloadAction<boolean>) => {
      state.breakupBlocks = action.payload;
    },
    resetBlocks: (state) => {
      state.selectedBlocks = [];
      state.breakupBlocks = false;
    },
  },
});

export const { setSelectedBlocks, addSelectedBlock, setBreakupBlocks, resetBlocks } = blocksUiSlice.actions;

export const getSelectedBlocks = (state: RootState): BlocksT => state.ui.blocksUi.selectedBlocks;
export const getBreakupBlocks = (state: RootState): boolean => state.ui.blocksUi.breakupBlocks;

export default blocksUiSlice.reducer;
