import { store } from 'app/store';
import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { getModifiedInfo } from 'helpers/careerHelpers';
import { AwardT } from 'types/App.types';

// #region CONVERTERS *******************************************************************
export const getInitialAward = (): AwardT => {
  const userId = store.getState().user.uid || '';
  const initAward: AwardT = {
    awardId: 'createAwardId',
    title: '',
    description: '',
    awardKind: 'General',
    code: 'GEN_0',
    createdBy: userId,
    createdOn: Date.now(),
    modifiedBy: userId,
    modifiedOn: Date.now(),
  };

  return initAward;
};

export const awardToDoc = (award: AwardT): DocumentData => {
  return {
    awardId: award.awardId,
    title: award.title,
    description: award.description,
    code: award.code,
    awardKind: award.awardKind,
    createdBy: award.createdBy,
    createdOn: award.createdOn,
    modifiedBy: award.modifiedBy,
    modifiedOn: award.modifiedOn,
  };
};

export const awardConverter: FirestoreDataConverter<AwardT> = {
  toFirestore(award: AwardT): DocumentData {
    return { ...awardToDoc(award), ...getModifiedInfo() };
  },

  fromFirestore(docSnap: QueryDocumentSnapshot, options?: SnapshotOptions): AwardT {
    const data = docSnap.data(options);

    return {
      awardId: data.awardId,
      title: data.title,
      description: data.description,
      awardKind: data.awardKind,
      code: data.code,
      createdBy: data.createdBy,
      createdOn: data.createdOn,
      modifiedBy: data.modifiedBy,
      modifiedOn: data.modifiedOn,
    };
  },
};
// #endregion
