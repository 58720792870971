import React, { useEffect, useState } from 'react';
import styles from './ThumbsUp.module.scss';
import InsetIcon from 'components/icon/InsetIcon';
import { useAppDispatch, useAppSelector } from 'app/hooks/hooks';
import { getShowThumbsUp, setShowThumbsUp } from 'scenes/montessori/appUiSlice';

type ThumbsUpProps = {
  className?: string;
  onAnimationOver?: () => void;
};

const ThumbsUp: React.FC<ThumbsUpProps> = ({ onAnimationOver = () => undefined }) => {
  const [positionX, setPositionX] = useState(0);
  const [positionY, setPositionY] = useState(0);

  const dispatch = useAppDispatch();
  const show = useAppSelector(getShowThumbsUp);

  const updateCoords = (event: MouseEvent) => {
    setPositionX(event.pageX - 30);
    setPositionY(event.pageY);
  };

  useEffect(() => {
    const container = document.getElementById('root');
    if (container) {
      container.addEventListener('click', updateCoords);
    }

    return () => container?.removeEventListener('click', updateCoords);
  }, []);

  const animationOver = () => {
    dispatch(setShowThumbsUp(false));
    onAnimationOver();
  };

  return (
    <div
      className={`${styles.ThumbsUp} ${show ? styles.Show : styles.Hide}`}
      onAnimationEnd={animationOver}
      style={{ position: 'absolute', left: positionX, top: positionY }}>
      <InsetIcon icon="recommend" size="lge" isFilled />
    </div>
  );
};

export default ThumbsUp;
