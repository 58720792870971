import { PassKeyInputDigitsT } from 'components/passkey/PassKey.types';
import * as secret from './userSecret';
import { PassKeyInternalState, PassKeyUiState } from 'modules/user/userUiSlice';
import { AnimationT } from 'types/App.types';

export const decodeUserPasskey = (hashedPass: string) => {
  return secret ? secret.decodeUserPasskey(hashedPass) : hashedPass;
};

export const decodeStudentPasskey = (hashedPass: string) => {
  return secret ? secret.decodeStudentPasskey(hashedPass) : hashedPass;
};

/**
 *
 * @param {string} key 4-digit PassKey
 * @param {string} salt user's uid
 *
 * @return {string} encoded string that can be converted to `uid` and `PassKey`
 */
export const encodeUserPassKey = (key: string, salt: string): string => {
  return secret ? secret.encodeUserPassKey(key, salt) : key + salt;
};

export const getPassKeyCode = (digits: PassKeyInputDigitsT): string =>
  `${digits.key1}${digits.key2}${digits.key3}${digits.key4}`;

export const generateResetPassKey = (salt: string): string => {
  return encodeUserPassKey('1234', salt);
};

export const createPassKeyUIState = (
  animation: AnimationT,
  visible: boolean,
  state: PassKeyInternalState
): PassKeyUiState => {
  return { animationState: animation, passKeyPanelVisible: visible, state: state };
};
