import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'app/store';
import { AwardCode, AwardCodesT, AwardsT, AwardT, MedalsT, MedalT, Status, StatusT } from 'types/App.types';
import { useStyledLogger } from 'app/config/LogConfig';
import { updateCareerAwardsCollection } from 'api/CareerAPI';

import { addAwardCode } from 'modules/career/careerSlice';
import { setShowAwardStar } from 'scenes/montessori/appUiSlice';
import { getActiveCareerId } from 'modules/user/userSlice';
import { isPrint } from 'helpers/appHelpers';
import { analytics, logEvent } from '../../../firebase/Firebase';
import { AchievementType, AppEvent, getUnlockedAchievement as getAchievement } from 'app/constants/AnalyticEvents';

export type AwardsState = {
  /** awards status, used by loaders, and other FE components */
  status: StatusT;
  awards: AwardsT;
  awardCodes: AwardCodesT;
  appMedalsStatus: StatusT;
  appMedals: MedalsT;
};

const PRINT_FLAG = true;

const PRIMARY_COLOR = 'darkgreen';
const SECONDARY_COLOR = 'cornsilk';
const CLS_NAME = 'awardSlice';

const printMsg = useStyledLogger(CLS_NAME, PRIMARY_COLOR, SECONDARY_COLOR);

const initialState: AwardsState = {
  status: 'idle',
  awards: [],
  awardCodes: [],
  appMedals: [],
  appMedalsStatus: Status.Idle,
};

export const receiveAward =
  (awardCode: AwardCode): AppThunk =>
  async (dispatch, getState) => {
    if (!getState().career) {
      isPrint(PRINT_FLAG) && console.log(...printMsg('Cannot apply award', 'career', 'NOT LOADED'));
      return;
    }
    const isValidCode = getState().awards.awardCodes.includes(awardCode);
    const isUserReceivedAward = getState().career.awardCodes.includes(awardCode);
    if (isValidCode && !isUserReceivedAward) {
      isPrint(PRINT_FLAG) && console.log(...printMsg('User gets', 'code', awardCode));
      const awardTitle = getAwardStars(getState()).find((award: AwardT) => award.code === awardCode)?.title;
      logEvent(analytics, AppEvent.UnlockAchievement, getAchievement(AchievementType.Star, awardCode, awardTitle));
      const careerId = getActiveCareerId(getState());
      dispatch(addAwardCode(awardCode));
      await updateCareerAwardsCollection(careerId, awardCode);
      dispatch(setShowAwardStar({ showAwardStar: true, awardCode, showActionButton: true }));
    } else {
      isPrint(PRINT_FLAG) && console.log(...printMsg(`Award ${awardCode}`, 'Own it?', String(isUserReceivedAward)));
    }
  };

export const awardsSlice = createSlice({
  name: 'awards',
  initialState,
  reducers: {
    setAllAwardsStatus: (state, action: PayloadAction<StatusT>) => {
      state.status = action.payload;
    },
    setAllAwards: (state, action: PayloadAction<AwardsT>) => {
      state.awards = action.payload;
      state.awardCodes = action.payload.map((award) => award.code);
    },
    addAward: (state, action: PayloadAction<AwardT>) => {
      state.awards = state.awards.concat(action.payload);
      state.awardCodes = state.awardCodes.concat(action.payload.code);
    },
    setAppMedals: (state, action: PayloadAction<MedalsT>) => {
      state.appMedals = action.payload;
    },
    setAppMedalsStatus: (state, action: PayloadAction<StatusT>) => {
      state.appMedalsStatus = action.payload;
    },
    addAppMedal: (state, action: PayloadAction<MedalT>) => {
      state.appMedals = state.appMedals.concat(action.payload);
    },
    resetAwards: () => {
      return { ...initialState };
    },
  },
});

export const {
  setAllAwardsStatus,
  setAllAwards,
  setAppMedals,
  setAppMedalsStatus,
  addAward,
  addAppMedal,
  resetAwards,
} = awardsSlice.actions;

export const getAwardsState = (state: RootState): StatusT => state.awards.status;
export const getAwardStars = (state: RootState): AwardsT => state.awards.awards;
export const getAwardStarsCount = (state: RootState): number => (state.awards.awards ? state.awards.awards.length : 0);

export const getAppMedals = (state: RootState): MedalsT => state.awards.appMedals;
export const getAppMedalCount = (state: RootState): number => state.awards.appMedals.length;
export const getAppMedalsStatus = (state: RootState): StatusT => state.awards.appMedalsStatus;

export default awardsSlice.reducer;
