import React, { useEffect, useRef, useState } from 'react';
import { useScrollManager } from 'app/hooks/useScrollManager';
import { analytics, logEvent } from '../../firebase/Firebase';
import { VoidFunc } from 'types/App.types';

import { AppEvent, SelectContentType, getSelectedContent } from 'app/constants/AnalyticEvents';

import DescriptionInfo from 'components/imageframe/components/descriptionpanel/DescriptionPanel';
import TitlePanel from 'components/imageframe/components/titlepanel/TitlePanel';
import ImagePortal from 'components/imageframe/components/imageportal/ImagePortal';
import FramedImage from 'components/imageframe/components/framedimage/FramedImage';

import styles from './ImageFrame.module.scss';

type ImageFrameProps = {
  className?: string;
  onEnterAnimationOver?: VoidFunc;
  onExitAnimationOver?: VoidFunc;
  imgSrc: string;
  title?: string;
  info?: string;
};

const ImageFrame: React.FC<ImageFrameProps> = ({ className = '', ...props }) => {
  const notificationRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
  const baseCls = `${styles.ImageFrame} ${className}`;
  const [isShrunk, setShrunk] = useState<boolean>(true);

  const { disableScroll, enableScroll } = useScrollManager();

  const handleClickOutside = () => {
    if (!isShrunk) {
      enableScroll();
      setShrunk(true);
    }
  };

  useEffect(() => {
    return () => enableScroll();
  }, []);

  const expandImageFrame = () => {
    const nextState = !isShrunk;
    nextState ? enableScroll() : disableScroll();
    logEvent(analytics, AppEvent.SelectContent, getSelectedContent(SelectContentType.ViewedScreenShot, props.imgSrc));
    setShrunk(nextState);
  };

  const enableDisableNodeRef = (node: HTMLDivElement | null) => {
    node && (!isShrunk ? node.setAttribute('inert', '') : node.removeAttribute('inert'));
  };

  return (
    <>
      <div className={`${baseCls}`} ref={enableDisableNodeRef} onClick={expandImageFrame}>
        <div className={`${styles.ImageFrameContents}`}>
          <FramedImage imgSrc={props.imgSrc} className={styles.FramedImage} />
          <TitlePanel text={props.title} />
        </div>
      </div>

      {!isShrunk && (
        <ImagePortal id="fullSizeImg" ref={notificationRef} onClick={handleClickOutside}>
          <div className={styles.ImageFrameContents}>
            <FramedImage imgSrc={props.imgSrc} className={styles.FramedImageLarge} />
            <DescriptionInfo text={props.info} />
          </div>
        </ImagePortal>
      )}
    </>
  );
};

export default ImageFrame;
