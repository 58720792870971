import { store } from 'app/store';
import { getArithmeticKindByTitle } from 'helpers/badgeHelpers';
import {
  getCareerToUrl,
  getCourseCompletionId,
  getLessonCompletionId,
  getQuestionCompletionId,
  getQuizCompletionId,
  getTutorialCompletionId,
} from 'helpers/routeHelpers';
import { To } from 'react-router-dom';
import { SceneT } from 'scenes/scenesSlice';

import {
  Arithmetic,
  ArithmeticT,
  AwardKind,
  CareerItem,
  CareerItemT,
  CreateT,
  Fruit,
  FruitT,
  ModifyT,
  PublicEntityId,
  SelectDropdownItemT,
  SelectedItemT,
  SubmitActionData,
  UIState,
  VisualState,
  VisualStateT,
} from 'types/App.types';
import {
  CareerChainCard,
  CareerChainCards,
  CareerRelatedIds,
  CareerRelatedProps,
  CompletionState,
  VersionedItemT,
  VersionedItemsT,
  VersionedLessonItemT,
  VersionedLessonItemsT,
  VersionedQuizItemT,
} from 'types/BaseCareer.types';
import { CareerT } from 'types/Career.types';
import { CourseId, CourseIds, CourseT } from 'types/Course.types';
import { GameT } from 'types/Game.types';
import { LessonId } from 'types/Lesson.types';
import { QuestionId, QuestionT } from 'types/Question.types';
import { QuizId } from 'types/Quiz.types';
import { TutorialId } from 'types/Tutorial.types';

export type ItemCountT = { [key: string]: number };
export type CareerStripesT = {
  courseStripe: ItemCountT;
  lessonStripe: ItemCountT;
  quizTutorialStripe: ItemCountT;
};

export const getCreateModifiedInfo = (): CreateT => {
  return { createdBy: store.getState().user.uid, createdOn: Date.now(), ...getModifiedInfo() };
};

export const getModifiedInfo = (): ModifyT => {
  return { modifiedBy: store.getState().user.uid, modifiedOn: Date.now() };
};

export const CATEGORY_TYPE_DROPDOWN_ITEM: Array<SelectDropdownItemT> = [
  { name: 'Counting Category', value: Arithmetic.Count },
  { name: 'Comparing Category', value: Arithmetic.Compare },
  { name: 'Addition Category', value: Arithmetic.Add },
  { name: 'Subtraction Category', value: Arithmetic.Subtract },
  { name: 'Multiplication Category', value: Arithmetic.Multiply },
  { name: 'Division Category', value: Arithmetic.Divide },
];

export const getSelectedCategory = (type: ArithmeticT | undefined): SelectedItemT =>
  CATEGORY_TYPE_DROPDOWN_ITEM.find((item) => item.value === type);

export const getLevelByArithmeticType = (type: ArithmeticT): string => {
  switch (type) {
    case Arithmetic.Add:
      return '2';
    case Arithmetic.Subtract:
      return '3';
    case Arithmetic.Multiply:
      return '4';
    case Arithmetic.Divide:
      return '5';
    case Arithmetic.Count:
    case Arithmetic.Compare:
    default:
      return '1';
  }
};

export const TYPE_GAME_LEVEL_DROPDOWN_ITEM: Array<SelectDropdownItemT> = [
  { name: 'Easy', value: 'level1' },
  { name: 'Medium', value: 'level2' },
  { name: 'Hard', value: 'level3' },
  { name: 'Extra Hard', value: 'level4' },
  { name: 'Hardcore', value: 'level5' },
];

export const getSelectedGameLevel = (level: string): SelectedItemT =>
  TYPE_GAME_LEVEL_DROPDOWN_ITEM.find((item) => item.value === level);

export const TYPE_UNLOCKS_LOCKED_TYPE_DROPDOWN_ITEM: Array<SelectDropdownItemT> = [
  { name: 'Game', value: 'game' },
  { name: 'Award Star', value: 'award' },
  // { name: 'Course', value: 'course' },
  // { name: 'Lesson', value: 'lesson' },
  // { name: 'Puzzle', value: 'puzzle' },
];

export const getSelectedLocksType = (type: string): SelectedItemT =>
  TYPE_UNLOCKS_LOCKED_TYPE_DROPDOWN_ITEM.find((item) => item.value === type);
export const TYPE_UNLOCKS_UNLOCKED_TYPE_DROPDOWN_ITEM: Array<SelectDropdownItemT> = [
  { name: 'Tutorial', value: 'tutorial' },
  { name: 'Quiz', value: 'quiz' },
  { name: 'Lesson', value: 'lesson' },
  { name: 'Course', value: 'course' },
];

export const getSelectedUnlocksType = (type: string): SelectedItemT =>
  TYPE_UNLOCKS_LOCKED_TYPE_DROPDOWN_ITEM.find((item) => item.value === type);

export const TYPE_LEVEL_DROPDOWN_ITEM: Array<SelectDropdownItemT> = [
  { name: 'Level 1', value: '1' },
  { name: 'Level 2', value: '2' },
  { name: 'Level 3', value: '3' },
  { name: 'Level 4', value: '4' },
  { name: 'Level 5', value: '5' },
];

export const getSelectedLevel = (level: string): SelectedItemT =>
  TYPE_LEVEL_DROPDOWN_ITEM.find((item) => item.value === level);

export const TYPE_FRUIT_DROPDOWN_ITEM: Array<SelectDropdownItemT> = [
  { name: 'Orange', value: Fruit.Orange },
  { name: 'Tomato', value: Fruit.Tomato },
  { name: 'Watermelon', value: Fruit.Watermelon },
  { name: 'Apple', value: Fruit.Apple },
  { name: 'Lemon', value: Fruit.Lemon },
];

export const getSelectedRowColumnLevel = (value: string): SelectedItemT =>
  TYPE_COLS_ROWS_ITEM.find((item) => item.value === value);
export const TYPE_COLS_ROWS_ITEM: Array<SelectDropdownItemT> = [
  { name: '2', value: '2' },
  { name: '3', value: '3' },
  { name: '4', value: '4' },
  { name: '5', value: '5' },
  { name: '6', value: '6' },
  { name: '7', value: '7' },
  { name: '8', value: '8' },
  { name: '9', value: '9' },
  { name: '10', value: '10' },
  { name: '11', value: '11' },
];

export const getSelectedFruit = (fruit: FruitT): SelectedItemT =>
  TYPE_FRUIT_DROPDOWN_ITEM.find((item) => item.value === fruit);

export const TYPE_PART_DROPDOWN_ITEM: Array<SelectDropdownItemT> = [
  { name: 'Part 1', value: '1' },
  { name: 'Part 2', value: '2' },
  { name: 'Part 3', value: '3' },
  { name: 'Part 4', value: '4' },
  { name: 'Part 5', value: '5' },
];

export const getSelectedPart = (part: string): SelectedItemT =>
  TYPE_PART_DROPDOWN_ITEM.find((item) => item.value === part);

export const TYPE_AWARD_KIND_DROPDOWN_ITEM: Array<SelectDropdownItemT> = [
  { name: 'Start', value: AwardKind.Start },
  { name: 'Finish', value: AwardKind.Finish },
  { name: 'Action', value: AwardKind.Action },
  { name: 'Discovery', value: AwardKind.Discovery },
  { name: 'Attendance', value: AwardKind.Attendance },
  { name: 'General', value: AwardKind.General },
  { name: 'Special', value: AwardKind.Special },
];

export const getSelectedAwardKind = (kind: string): SelectedItemT =>
  TYPE_AWARD_KIND_DROPDOWN_ITEM.find((item) => item.value === kind);

export const createChainCard = (
  versionedItemT: VersionedItemT,
  idModel: CareerRelatedIds,
  relatedProps: CareerRelatedProps,
  completionState: CompletionState,
  index: number
): CareerChainCard => {
  const uiState: UIState = {
    ...completionState,
    submitActionData: {
      actionUrl: getCareerToUrl(idModel.careerId),
      simpleActionUrl: `/career/simple/${idModel.careerId}`,
      completionId: completionState.completionId,
      id: versionedItemT.versionedItemId,
      versionId: versionedItemT.versionId,
      level: String(relatedProps.level),
      name: versionedItemT.versionedItemTitle,
      type: relatedProps.type as CareerItemT,
      ...idModel,
      value: relatedProps.arithmetic,
      chainIndex: String(index),
      timeToSolve: '',
    },
  };

  return {
    ...versionedItemT,
    index: index,
    level: relatedProps.level as number,
    type: relatedProps.type as CareerItemT,
    ...uiState,
  };
};

const getVersionedItemFromCourse = (courseT: CourseT): VersionedItemT => ({
  versionedItemId: courseT.courseId,
  versionedItemTitle: courseT.courseTitle,
  versionId: courseT.versionId,
});

const getCompletionState = (completionId: string, completeList: Array<string>, toUrl?: To): CompletionState => {
  const visualState: VisualStateT = completeList.includes(completionId) ? VisualState.Completed : 'locked';
  return { visualState, completionId, toUrl };
};

export const createCareerChain = (careerT: CareerT): CareerChainCards => {
  const { courses, complete, careerId } = careerT;

  const getCareerRelatedIdsModel = (courseId: CourseId): CareerRelatedIds => ({ careerId, courseId });
  const getCareerRelatedProps = (level: number, arithmetic: ArithmeticT) => ({ level, arithmetic });

  let cardIndex = 0;
  const careerChain: CareerChainCards = courses.flatMap((courseT: CourseT) => {
    const idModel: CareerRelatedIds = getCareerRelatedIdsModel(courseT.courseId);
    const relatedProps: CareerRelatedProps = getCareerRelatedProps(courseT.courseLevel, courseT.courseCategory);
    const courseVersionedLessonIds = courseT.versionedLessonIds as VersionedLessonItemsT;
    const courseCards = courseVersionedLessonIds.flatMap((lesson: VersionedLessonItemT) => {
      idModel.lessonId = lesson.versionedItemId;
      const tutorial = lesson.versionedTutorialId as VersionedItemT;
      const arithmetic = getArithmeticKindByTitle(tutorial.versionedItemTitle.split(' ')[0]);
      const quiz = lesson.versionedQuizId as VersionedQuizItemT;

      return [
        // TUTORIALS **************************************************************************************************
        createChainCard(
          tutorial,
          { ...idModel, tutorialId: tutorial.versionedItemId },
          { ...relatedProps, title: tutorial.versionedItemTitle, type: CareerItem.Tutorial, arithmetic: arithmetic },
          getCompletionState(
            getTutorialCompletionId(
              idModel.courseId as CourseId,
              idModel.lessonId as LessonId,
              tutorial.versionedItemId as TutorialId
            ),
            complete,
            `/career/tutorial/${tutorial.versionedItemId}/${tutorial.versionId}`
          ),
          cardIndex++
        ),
        // QUESTIONS **************************************************************************************************
        ...((lesson.versionedQuizId as VersionedQuizItemT).versionedQuestionIds as VersionedItemsT).map(
          (versionedQuestion) => {
            return createChainCard(
              versionedQuestion,
              {
                ...idModel,
                quizId: quiz.versionedItemId,
                questionId: versionedQuestion.versionedItemId,
              },
              {
                ...relatedProps,
                title: versionedQuestion.versionedItemTitle,
                type: CareerItem.Question,
                arithmetic: arithmetic,
              },
              getCompletionState(
                getQuestionCompletionId(
                  idModel.courseId as CourseId,
                  idModel.lessonId as LessonId,
                  quiz.versionedItemId as QuizId,
                  versionedQuestion.versionedItemId as QuestionId
                ),
                complete,
                `/question/${versionedQuestion.versionedItemId}/${versionedQuestion.versionId}`
              ),
              cardIndex++
            );
          }
        ),
        // QUIZZES ****************************************************************************************************
        createChainCard(
          lesson.versionedQuizId as VersionedItemT,
          { ...idModel, quizId: quiz.versionedItemId },
          { ...relatedProps, title: quiz.versionedItemTitle, type: CareerItem.Quiz, arithmetic: arithmetic },
          getCompletionState(
            getQuizCompletionId(
              idModel.courseId as CourseId,
              idModel.lessonId as LessonId,
              quiz.versionedItemId as QuizId
            ),
            complete
          ),
          cardIndex++
        ),
        // LESSONS ****************************************************************************************************
        createChainCard(
          lesson as VersionedItemT,
          { ...idModel },
          { ...relatedProps, title: lesson.versionedItemTitle, type: CareerItem.Lesson, arithmetic: arithmetic },
          getCompletionState(
            getLessonCompletionId(idModel.courseId as CourseId, idModel.lessonId as LessonId),
            complete
          ),
          cardIndex++
        ),
      ];
    });
    return courseCards.concat(
      createChainCard(
        getVersionedItemFromCourse(courseT),
        { ...idModel },
        { ...relatedProps, title: courseT.courseTitle, type: CareerItem.Course },
        getCompletionState(getCourseCompletionId(idModel.courseId as CourseId), complete),
        cardIndex++
      )
    );
  });

  return careerChain;
};

export const findStartingActiveCardIndex = (careerChainCards: CareerChainCards): number => {
  return careerChainCards.findIndex((c: CareerChainCard) => c.visualState !== VisualState.Completed);
};

export const getCareerStripeModels = (careerChain: CareerChainCards, hiddenCourseIds: CourseIds): CareerStripesT => {
  const courseStripe: ItemCountT = {};
  const lessonStripe: ItemCountT = {};
  const quizTutorialStripe: ItemCountT = {};

  careerChain.forEach((item: CareerChainCard) => {
    const actionData = item.submitActionData as SubmitActionData;
    const courseId = actionData.courseId as CourseId;
    const lessonId = actionData.lessonId as LessonId;
    if (!hiddenCourseIds.includes(courseId)) {
      const courseKey = courseId;
      const lessonKey = `${courseId}_${lessonId}`;

      if (!courseStripe[courseKey]) {
        courseStripe[courseKey] = 0;
      }
      courseStripe[courseKey] += 1;

      if (!lessonStripe[lessonKey]) {
        lessonStripe[lessonKey] = 0;
        quizTutorialStripe[lessonKey] = -1;
      }
      lessonStripe[lessonKey] += 1;
      quizTutorialStripe[lessonKey] += 1;
    }
  });

  return { courseStripe, lessonStripe, quizTutorialStripe };
};

export const toPublicCourseId = (c: CourseT): PublicEntityId => `${c.courseId}:${c.versionId}`;
export const toPublicQuestionId = (q: QuestionT): PublicEntityId => `${q.questionId}:${q.versionId}`;
export const toPublicGameId = (g: GameT): PublicEntityId => `${g.gameId}:no_version_id`;

export const isCareerQuestionOrTutorialScene = (scene: SceneT): boolean =>
  scene === 'career' || scene.indexOf('career:tutorial') === 0 || scene.indexOf('career:question') === 0;

export const getFinishedCourses = (career: CareerT): number => {
  return career.courses.filter((c: CourseT) => career.complete.includes(getCourseCompletionId(c.courseId))).length ?? 0;
};
