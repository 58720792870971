import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from './hooks';
import { getUser, isUserSignedIn } from 'modules/user/userSlice';
import { IUser } from 'types/User.types';
import { AppRoutes } from 'app/constants/Routes';

interface AuthContextType {
  signedIn: boolean;
  user: IUser | undefined;
  // signin: (user: string, callback: VoidFunction) => void;
  // signout: (callback: VoidFunction) => void;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const AuthContext = React.createContext<AuthContextType>(null!);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [signedIn, setSignedIn] = useState<boolean>(false);
  const [user, setUser] = useState<IUser>();
  const userSignedIn = useAppSelector(isUserSignedIn);
  const currentUser = useAppSelector(getUser);

  useEffect(() => {
    setSignedIn(userSignedIn);
  }, [userSignedIn]);

  useEffect(() => {
    setUser(currentUser);
  }, [currentUser]);

  const value = { signedIn, user };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  return React.useContext(AuthContext);
};

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();
  const location = useLocation();
  if (!auth.signedIn) {
    // || !auth.user
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    // console.log(`Navigating from ${location.pathname} to /auth/login`);
    return <Navigate to={AppRoutes.Login} state={{ from: location }} replace />;
  }

  return children;
};

export { useAuth as default, AuthProvider, RequireAuth, AuthContext };
