import React from 'react';
import MeText from 'components/text/metext/MeText';

import styles from './TitlePanel.module.scss';

type TitlePanelProps = {
  className?: string;
  text?: string;
};

const TitlePanel: React.FC<TitlePanelProps> = ({ text = '', ...props }) => {
  return (
    <div className={`${styles.TitlePanel} ${props.className ?? ''}`}>
      <MeText textSize="sam" weight="bold600" insetText lineHeight="line16">
        {text}
      </MeText>
    </div>
  );
};

export default TitlePanel;
