import { store } from 'app/store';
import { db } from '../firebase/Firebase';
import { collection, doc, getDoc, getDocs, query, setDoc, updateDoc } from 'firebase/firestore';
import { redirect } from 'react-router-dom';

import { StatusT, VisualStateT } from 'types/App.types';
import { CareerId } from 'types/Career.types';
import { CourseId } from 'types/Course.types';
import { LessonId } from 'types/Lesson.types';
import { TutorialT, TutorialId, TutorialsT, TutorialResponseT, TutorialUI } from 'types/Tutorial.types';

import { getFormattedString, getNameClsString } from 'app/config/LogConfig';

import { fetchBadges } from 'api/AppAPI';
import { careerStatusUpdated } from 'api/CareerAPI';

import { getCareerChain } from 'modules/career/careerSlice';
import { doSetActiveScene, setLastActiveManageScene } from 'scenes/scenesSlice';
import { setTutorials, setTutorialsStatus, updateTutorial } from 'scenes/management/managerSlice';
import { castToTutorialUI } from 'helpers/typeCastingHelpers';
import { sortTutorials } from 'helpers/sortHelpers';
import { setToastNotification } from 'modules/user/userUiSlice';
import { CareerChainCard, VersionId } from 'types/BaseCareer.types';
import { tutorialConverter, tutorialToDoc, getInitialTutorial } from 'api/converters/TutorialConverter';
import { getToastSuccessPayload } from 'helpers/uiHelpers';
import { AppRoutes } from 'app/constants/Routes';

export const PRIMARY_COLOR = getNameClsString('palegoldenrod');
export const CLS_NAME = 'TutorialsAPI';

const printMsg = (action: string, prop: string, status: string) =>
  getFormattedString(PRIMARY_COLOR, CLS_NAME, action, prop, status);

// #region TUTORIAL APIs *****************************************************************
export const getTutorials = async (): Promise<TutorialsT> => {
  const collectionData = collection(db, 'tutorials').withConverter(tutorialConverter);
  const q = query(collectionData);

  const querySnapshot = await getDocs(q);
  const tutorials: TutorialsT = [];
  querySnapshot.docs.forEach((doc) => {
    const docData = { ...doc.data(), tutorialId: doc.id };
    tutorials.push(docData);
  });
  tutorials.sort(sortTutorials);
  return tutorials;
};

const getTutorial = async (tutorialId: TutorialId, versionId?: VersionId): Promise<TutorialT> => {
  const path = versionId ? `tutorials/${tutorialId}/versions/${versionId}` : `tutorials/${tutorialId}`;
  const docRef = doc(db, path).withConverter(tutorialConverter);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const docData: TutorialT = docSnap.data();
    return docData;
  } else {
    console.error('No such document!');
    throw Error(`Tutorial (${tutorialId}) unable to find`);
  }
};

export const getTutorialUI = async (
  careerId: CareerId,
  courseId: CourseId,
  lessonId: LessonId,
  tutorialId: TutorialId,
  visualState: VisualStateT
): Promise<TutorialUI> => {
  const tutorialT: TutorialT = await getTutorial(tutorialId);
  return castToTutorialUI(careerId, courseId, lessonId, tutorialT, visualState);
};

export const getVersionedTutorial = async (tutorialId: TutorialId, versionId: VersionId): Promise<TutorialT> => {
  console.log(...printMsg('Loading', 'getVersionedTutorial', `${tutorialId}:${versionId}`));
  const tutorialT: TutorialT = await getTutorial(tutorialId, versionId);
  return tutorialT;
};

export const createTutorial = async (tutorial: TutorialT): Promise<TutorialT> => {
  const collectionData = collection(db, 'tutorials').withConverter(tutorialConverter);
  const docRef = doc(collectionData);
  const createdTutorial: TutorialT = { ...tutorial, tutorialId: docRef.id, versionId: '' };
  createdTutorial.versionId = await createTutorialVersion(createdTutorial);
  // Create new record with autogenerated id as quizId
  await setDoc(docRef, createdTutorial);
  return createdTutorial as TutorialT;
};

export const createTutorialVersion = async (tutorial: TutorialT): Promise<VersionId> => {
  const collectionData = collection(db, `tutorials/${tutorial.tutorialId}/versions`).withConverter(tutorialConverter);
  const docRef = doc(collectionData);
  const newT = { ...tutorial, versionId: docRef.id } as TutorialT;
  await setDoc(docRef, newT);
  return docRef.id as VersionId;
};

export const patchTutorial = async (tutorialData: TutorialT): Promise<void> => {
  const tutorial: TutorialT = { ...tutorialData, versionId: '' };
  tutorial.versionId = await createTutorialVersion(tutorial);
  const tutorialRef = doc(db, `tutorials/${tutorialData.tutorialId}`);
  const docTutorial = tutorialToDoc(tutorial);
  return await updateDoc(tutorialRef, docTutorial);
};
// #endregion

// #region CAREER TUTORIAL Loader & Action functions *************************************

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const careerVersionedTutorialSceneLoader = async ({ params }: any): Promise<TutorialT> => {
  const { tutorialId, versionId } = params;

  if (!tutorialId || !versionId) {
    console.log(...printMsg('Cannot load', 'tutorialId:versionId', `${tutorialId}:${versionId}`));
    throw new Error('Missing tutorial params');
  }

  return await getVersionedTutorial(tutorialId, versionId);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const careerTutorialSceneLoader = async ({ request, params }: any): Promise<CareerChainCard> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { tutorialId, versionId } = params;
  const url = new URL(request.url);
  const itemIndex = url.searchParams.get('itemIndex');
  const careerStatus: StatusT = await careerStatusUpdated();
  if (careerStatus === 'loaded') {
    if (!tutorialId || !itemIndex) {
      console.log(...printMsg('Cannot load', 'tutorialId:index', `${tutorialId}:${itemIndex}`));
      throw redirect('..');
    }

    const careerChain = getCareerChain(store.getState());
    return careerChain[parseInt(itemIndex)] as CareerChainCard;
  }
  console.log(...printMsg('Cannot Load CareerTutorialScene', 'careerStatus', careerStatus));
  throw redirect('..');
};

// #region EDITOR LOADER & ACTION FUNCTIONS **********************************************
export const manageTutorialCollectionSceneLoader = async () => {
  store.dispatch(doSetActiveScene('manage:tutorial:collection'));
  store.dispatch(setLastActiveManageScene('tutorial'));

  const tutorials = await getTutorials();
  store.dispatch(setTutorials({ collection: tutorials, status: 'loaded' }));
  return tutorials;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const manageEditTutorialSceneLoader = async ({ params }: any): Promise<TutorialResponseT> => {
  store.dispatch(doSetActiveScene('manage:tutorial:edit'));
  const tutorialId = params.tutorialId;
  const tutorial = await getTutorial(tutorialId);
  const badgesState = store.getState().ui.badgesUi;
  const badges = badgesState.badgesStatus === 'loaded' ? badgesState.badges : await fetchBadges();
  const index = store.getState().manager.tutorials.findIndex((t) => t.tutorialId === tutorialId);

  return { tutorial, badges, index };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const manageEditTutorialSceneAction = async ({ params }: any) => {
  const tutorialId = params.tutorialId;
  store.dispatch(setTutorialsStatus('updating'));
  const managerTutorials = store.getState().manager.tutorials;
  const indexOfEditedItem = managerTutorials.findIndex((t) => t.tutorialId === tutorialId);
  const tutorialItem = managerTutorials[indexOfEditedItem] as TutorialT;
  const tutorialToEdit: TutorialT = {
    ...tutorialItem,
    modifiedOn: Date.now(),
  };

  await patchTutorial(tutorialToEdit);
  store.dispatch(setTutorialsStatus('updated'));
  store.dispatch(setToastNotification(getToastSuccessPayload('Tutorial Updated')));
  return redirect(AppRoutes.ManagerTutorial, { statusText: 'success' });
};

export const manageCreateTutorialSceneLoader = async (): Promise<TutorialResponseT> => {
  store.dispatch(doSetActiveScene('manage:tutorial:create'));
  const badgesState = store.getState().ui.badgesUi;
  const tutorial = getInitialTutorial();
  const badges = badgesState.badgesStatus === 'loaded' ? badgesState.badges : await fetchBadges();

  return { tutorial, badges, index: store.getState().manager.tutorials.length };
};

export const manageCreateTutorialSceneAction = async () => {
  store.dispatch(setTutorialsStatus('creating'));
  const managerTutorials = store.getState().manager.tutorials;
  const indexOfCreatedItem = managerTutorials.findIndex((t) => t.tutorialId === 'createTutorialId');
  const tutorialItem = managerTutorials[indexOfCreatedItem] as TutorialT;
  const toCreateTutorial: TutorialT = {
    ...tutorialItem,
    createdOn: Date.now(),
    modifiedOn: Date.now(),
  };

  const createdTutorial = await createTutorial(toCreateTutorial);
  store.dispatch(updateTutorial({ tutorial: createdTutorial, index: indexOfCreatedItem }));
  store.dispatch(setTutorialsStatus('created'));

  store.dispatch(setToastNotification(getToastSuccessPayload('Tutorial Created')));
  return redirect(AppRoutes.ManagerTutorial, { statusText: 'success' });
};
// #endregion
