import React from 'react';
import { MedalsT } from 'types/App.types';
import Medal from 'components/medal/Medal';

import styles from './MedalViewer.module.scss';

type MedalViewerProps = {
  className?: string;
  medals?: MedalsT;
};

const MedalViewer: React.FC<MedalViewerProps> = ({ medals = [], ...props }) => {
  return (
    <div className={`${styles.MedalViewer} ${props.className ?? ''}`}>
      {medals.map((m) => {
        return (
          <Medal
            key={`key_${m.medalId}`}
            medalInfo={m}
            imageUrl={m.medalUrl}
            alt={m.title}
            medalSize="medium"
            animateOnOpen
          />
        );
      })}
    </div>
  );
};

export default MedalViewer;
