import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'app/store';
import { MaybeType, Fruit, FruitT } from 'types/App.types';
import { CellsT, CellT, MathModeT, ModeT, Mode, BoardState, BoardOptions, MathMode } from 'types/Board.types';
import { createInitialCells } from './boardHelpers';

const initialState: BoardState = {
  cells: createInitialCells(Mode.Explore, MathMode.Addition, 11, 11),
  mode: Mode.Explore,
  mathMode: MathMode.Addition,
  rows: 11,
  columns: 11,
  selectedCell: undefined,
  hoveredCell: undefined,
  selectedRow: undefined,
  selectedColumn: undefined,
  countedCellsIndexes: [],
  disabled: false,
  showCellValues: true,
  showSelectedCellValues: true,
  showRowHeader: true,
  showColumnHeader: true,
  showRowHeaderValues: true,
  showColumnHeaderValues: true,
  showResetButton: true,
  showHTracerLine: false,
  showVTracerLine: false,
  cellFruit: Fruit.Tomato,
  highlightHeaderRow: false,
  highlightHeaderColumn: false,
  highlightFirstCell: false,
};

export const updateCellCountedState =
  (cell: CellT, cellIndex: number, isCounted: boolean): AppThunk =>
  (dispatch) => {
    const updatedCell: CellT = { ...cell, isCounted: isCounted };

    dispatch(updateCountedState({ cellIndex, updatedCell }));

    if (isCounted) {
      dispatch(appendCountedCellIndex(cellIndex));
    } else {
      dispatch(removeCountedCellIndex(cellIndex));
    }
  };

export const initBoardByArithmeticKind =
  ({
    rows,
    columns,
    mathMode,
    mode,
    disabled = false,
    showCellValues = true,
    showSelectedCellValues = true,
    showRowHeader = true,
    showColumnHeader = true,
    showRowHeaderValues = true,
    showColumnHeaderValues = true,
    showResetButton = true,
    cellFruit = Fruit.Tomato,
    showHTracerLine = false,
    showVTracerLine = false,
    highlightHeaderRow = false,
    highlightHeaderColumn = false,
    highlightFirstCell = false,
  }: BoardOptions): AppThunk =>
  (dispatch) => {
    dispatch(setRowsAndColumns({ rows: rows, columns: columns }));
    dispatch(setMathMode(mathMode as MathModeT));
    dispatch(setMode(mode ? mode : Mode.Quiz));
    dispatch(setDisabled(disabled));
    dispatch(setCellFruit(cellFruit));
    dispatch(setShowCellValues(showCellValues));
    dispatch(setShowSelectedCellValues(showSelectedCellValues));
    dispatch(setShowRowHeader(showRowHeader));
    dispatch(setShowColumnHeader(showColumnHeader));
    dispatch(setShowRowHeaderValues(showRowHeaderValues));
    dispatch(setShowColumnHeaderValues(showColumnHeaderValues));
    dispatch(setShowResetButton(showResetButton));
    dispatch(setCellFruit(cellFruit));
    dispatch(setShowHTracerLine(showHTracerLine));
    dispatch(setShowVTracerLine(showVTracerLine));
    dispatch(setHighlightHeaderRow(highlightHeaderRow));
    dispatch(setHighlightHeaderColumn(highlightHeaderColumn));
    dispatch(setHighlightFirstCell(highlightFirstCell));
  };

export const updateSelectedCell =
  (cell: MaybeType<CellT>): AppThunk =>
  (dispatch) => {
    const updatedCell: MaybeType<CellT> = cell ? { ...cell, isCounted: false } : undefined;
    dispatch(softResetCells());
    dispatch(setSelectedCell(updatedCell));
  };

export const setupBoardTutorial = (): AppThunk => (dispatch) => {
  dispatch(setRowsAndColumns({ rows: 11, columns: 11 }));
  dispatch(setMathMode(MathMode.Addition));
  dispatch(setCellFruit(Fruit.Tomato));
  dispatch(setMode(Mode.Tutorial));
  dispatch(setDisabled(true));
  dispatch(setShowCellValues(false));
  dispatch(setShowRowHeader(true));
  dispatch(setShowRowHeaderValues(false));
  dispatch(setShowColumnHeader(true));
  dispatch(setShowColumnHeaderValues(false));
  dispatch(setShowResetButton(true));
};

type CountedPayload = {
  cellIndex: number;
  updatedCell: CellT;
};

type RowsAndColumnsPayload = {
  rows: number;
  columns: number;
};

export const boardSlice = createSlice({
  name: 'board',
  initialState,
  reducers: {
    setCells: (state, action: PayloadAction<CellsT>) => {
      state.cells = action.payload;
    },
    setRowsAndColumns: (state, action: PayloadAction<RowsAndColumnsPayload>) => {
      state.rows = action.payload.rows;
      state.columns = action.payload.columns;
    },
    setMode: (state, action: PayloadAction<ModeT>) => {
      state.mode = action.payload;
      state.cells = createInitialCells(action.payload, state.mathMode, state.rows, state.columns);
      state.countedCellsIndexes = [];
      state.hoveredCell = undefined;
      state.selectedCell = undefined;
    },
    setMathMode: (state, action: PayloadAction<MathModeT>) => {
      state.mathMode = action.payload;
      state.cells = createInitialCells(state.mode, action.payload, state.rows, state.columns);
    },
    setSelectedCell: (state, action: PayloadAction<MaybeType<CellT>>) => {
      state.selectedCell = action.payload;
    },
    setHoveredCell: (state, action: PayloadAction<MaybeType<CellT>>) => {
      state.hoveredCell = action.payload;
    },
    setCountedCellsIndexes: (state, action: PayloadAction<Array<number>>) => {
      state.countedCellsIndexes = action.payload;
    },
    appendCountedCellIndex: (state, action: PayloadAction<number>) => {
      state.countedCellsIndexes = state.countedCellsIndexes.concat(action.payload);
    },
    removeCountedCellIndex: (state, action: PayloadAction<number>) => {
      const updatedCountedCellsIndexes = state.countedCellsIndexes.filter(
        (countedIndex: number) => countedIndex !== action.payload
      );
      state.countedCellsIndexes = updatedCountedCellsIndexes;
    },
    setDisabled: (state, action: PayloadAction<boolean>) => {
      state.disabled = action.payload;
    },
    updateCountedState: (state, action: PayloadAction<CountedPayload>) => {
      const updatedCells = state.cells.slice();
      updatedCells[action.payload.cellIndex] = action.payload.updatedCell;
      state.cells = updatedCells;
    },
    setShowCellValues: (state, action: PayloadAction<boolean>) => {
      state.showCellValues = action.payload;
    },
    setShowSelectedCellValues: (state, action: PayloadAction<boolean>) => {
      state.showSelectedCellValues = action.payload;
    },
    setShowRowHeaderValues: (state, action: PayloadAction<boolean>) => {
      state.showRowHeaderValues = action.payload;
    },
    setShowColumnHeaderValues: (state, action: PayloadAction<boolean>) => {
      state.showColumnHeaderValues = action.payload;
    },
    setShowRowHeader: (state, action: PayloadAction<boolean>) => {
      state.showRowHeader = action.payload;
    },
    setShowColumnHeader: (state, action: PayloadAction<boolean>) => {
      state.showColumnHeader = action.payload;
    },
    setShowResetButton: (state, action: PayloadAction<boolean>) => {
      state.showResetButton = action.payload;
    },
    setCellFruit: (state, action: PayloadAction<FruitT>) => {
      state.cellFruit = action.payload;
    },
    setRowSelected: (state, action: PayloadAction<number | undefined>) => {
      state.selectedRow = action.payload;
    },
    setColumnSelected: (state, action: PayloadAction<number | undefined>) => {
      state.selectedColumn = action.payload;
    },
    setShowHTracerLine: (state, action: PayloadAction<boolean>) => {
      state.showHTracerLine = action.payload;
    },
    setShowVTracerLine: (state, action: PayloadAction<boolean>) => {
      state.showVTracerLine = action.payload;
    },
    setHighlightHeaderRow: (state, action: PayloadAction<boolean>) => {
      state.highlightHeaderRow = action.payload;
    },
    setHighlightHeaderColumn: (state, action: PayloadAction<boolean>) => {
      state.highlightHeaderColumn = action.payload;
    },
    setHighlightFirstCell: (state, action: PayloadAction<boolean>) => {
      state.highlightFirstCell = action.payload;
    },
    softResetCells: (state) => {
      state.cells = createInitialCells(state.mode, state.mathMode, state.rows, state.columns);
      state.countedCellsIndexes = [];
      state.hoveredCell = undefined;
      state.selectedCell = undefined;
      state.selectedRow = -1;
      state.selectedColumn = -1;
    },
    resetCells: (state) => {
      state.cells = createInitialCells(state.mode, state.mathMode, state.rows, state.columns);
      state.countedCellsIndexes = [];
      state.hoveredCell = undefined;
      state.selectedCell = undefined;
      state.selectedRow = -1;
      state.selectedColumn = -1;
      state.showHTracerLine = false;
      state.showVTracerLine = false;
    },
  },
});

export const {
  setCells,
  setRowsAndColumns,
  setMode,
  setMathMode,
  setSelectedCell,
  setHoveredCell,
  setCountedCellsIndexes,
  appendCountedCellIndex,
  removeCountedCellIndex,
  setDisabled,
  setShowCellValues,
  setShowSelectedCellValues,
  updateCountedState,
  setShowRowHeaderValues,
  setShowColumnHeaderValues,
  setShowRowHeader,
  setShowColumnHeader,
  setCellFruit,
  setShowResetButton,
  setRowSelected,
  setColumnSelected,
  setShowHTracerLine,
  setShowVTracerLine,
  setHighlightHeaderRow,
  setHighlightHeaderColumn,
  setHighlightFirstCell,
  softResetCells,
  resetCells,
} = boardSlice.actions;

// #region SELECTORS ********************************************************************
export const getCells = (state: RootState): CellsT => state.board.cells;
export const getRows = (state: RootState): number => state.board.rows;
export const getColumns = (state: RootState): number => state.board.columns;

export const getCellAt = (state: RootState, index: number): CellT =>
  createSelector(getCells, (cells: CellsT) => cells[index])(state);

export const getMode = (state: RootState): ModeT => state.board.mode;
export const getMathMode = (state: RootState): MathModeT => state.board.mathMode;
export const getShowCellValues = (state: RootState): boolean => state.board.showCellValues;
export const getShowSelectedCellValues = (state: RootState): boolean => state.board.showSelectedCellValues;
export const getShowResetButton = (state: RootState): boolean => state.board.showResetButton;
export const getSelectedCell = (state: RootState): MaybeType<CellT> => state.board.selectedCell;
export const getSelectedCellIndex = (state: RootState): number =>
  state.board.selectedCell ? state.board.selectedCell.row * state.board.columns + state.board.selectedCell.column : -1;
export const getHoveredCell = (state: RootState): MaybeType<CellT> => state.board.hoveredCell;
export const getCountedCellsIndexes = (state: RootState): Array<number> => state.board.countedCellsIndexes;
export const isDisabled = (state: RootState): boolean => state.board.disabled;
export const getMarkCellSelected = (state: RootState, index: number): boolean =>
  createSelector(getSelectedCell, getMathMode, (selectedCell: MaybeType<CellT>, mathMode: MathModeT) => {
    if (!selectedCell || index === 0) {
      return false;
    }
    const cell: CellT = state.board.cells[index];
    if (
      mathMode === MathMode.Subtraction ||
      mathMode === MathMode.Addition ||
      mathMode === MathMode.Counting ||
      mathMode === MathMode.Comparing
    ) {
      if (cell.row === 0 || cell.column === 0) {
        return false;
      }
      return selectedCell.row > cell.row || (selectedCell.row === cell.row && selectedCell.column >= cell.column);
    } else {
      if (cell.isHeaderCell && cell.row === 0 && cell.column === selectedCell.column) {
        return true;
      } else if (cell.isHeaderCell && cell.column === 0 && cell.row === selectedCell.row) {
        return true;
      } else if (!cell.isHeaderCell) {
        return selectedCell.row >= cell.row && selectedCell.column >= cell.column;
      }
    }
    return false;
  })(state);

export const getRowHeaderVisible = (state: RootState) => state.board.showRowHeader;
export const getColumnHeaderVisible = (state: RootState) => state.board.showColumnHeader;
export const getRowHeaderValuesVisible = (state: RootState) => state.board.showRowHeaderValues;
export const getColumnHeaderValuesVisible = (state: RootState) => state.board.showColumnHeaderValues;
export const getCellFruit = (state: RootState) => state.board.cellFruit;
export const getSelectedRow = (state: RootState) => state.board.selectedRow;
export const getSelectedColumn = (state: RootState) => state.board.selectedColumn;
export const getShowHTracerLine = (state: RootState) => state.board.showHTracerLine;
export const getShowVTracerLine = (state: RootState) => state.board.showVTracerLine;
export const getHighlightHeaderRow = (state: RootState) => state.board.highlightHeaderRow;
export const getHighlightHeaderColumn = (state: RootState) => state.board.highlightHeaderColumn;
export const getHighlightFirstCell = (state: RootState) => state.board.highlightFirstCell;

// #endregion

export default boardSlice.reducer;
