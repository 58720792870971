import { IStorageItem, StorageItemsT } from 'types/App.types';
import { storage, getDownloadURL } from '../firebase/Firebase';
import { listAll, ref, StorageReference, uploadBytes, uploadBytesResumable, UploadResult } from 'firebase/storage';

type FetchUrlProps = {
  imageRefs: Array<IStorageItem>;
  markSelected?: boolean;
};

const fetchAllStorageRefs = async (path = 'images'): Promise<StorageItemsT> => {
  const imagesRef = ref(storage, path);
  let urlList: StorageItemsT = [];
  urlList = await listAll(imagesRef)
    .then((res) => {
      res.prefixes.forEach((folderRef: StorageReference) => {
        console.log(`folderRef, ${folderRef}`);
        urlList.push({ name: folderRef.name, urlRef: folderRef.fullPath, isFolder: true });
      });

      res.items.forEach((itemRef) => {
        urlList.push({ name: itemRef.name, urlRef: itemRef.fullPath, isFolder: false, selected: false });
      });
      return urlList;
    })
    .catch((error) => {
      console.error(`Error occurred when tried to get images in portfolio storage bucket, ${error}`);
      return [];
    })
    .finally(() => {
      return urlList;
    });
  console.log(`Returning urlList ${urlList.length}`);
  return urlList;
};

export const getImagesFromStorage = async (path: string): Promise<StorageItemsT> => {
  const allRefs = await fetchAllStorageRefs(path);
  const downloadUrls = await fetchDownloadUrlsForFileRefs({ imageRefs: allRefs, markSelected: false });
  return downloadUrls;
};

export const fetchDownloadUrlsForFileRefs = async (props: FetchUrlProps): Promise<StorageItemsT> => {
  const { imageRefs, markSelected = false } = props;
  const fileUrls = await Promise.all(
    imageRefs.map(async (itemRef) => {
      if (itemRef.isFolder) {
        return { ...itemRef, url: '/images/logo-codepen.png' };
      }
      const url = await getDownloadURL(ref(storage, itemRef.urlRef as string));
      return { ...itemRef, url: url, selected: markSelected };
    })
  );
  return fileUrls;
};

/**
 * Uploads `files` of type `File` to the `Firebase.Storage` at `folder` location.
 *
 * @param {Array<File>} files list of files from HD to upload
 * @param {string} folder - folder where to upload the files in `Firebase.Storage`
 *
 * @return {Promise<StorageItemsT>} promise containing an array of `StorageItemsT`
 */
export const uploadFilesToStorage = async (files: Array<File>, folder: string) => {
  const fileRefs: Array<IStorageItem> = await Promise.all(
    files.map(async (file: File) => {
      const fileRef = await upload(file, folder);

      return { name: fileRef.name, urlRef: fileRef.fullPath, isFolder: false, selected: false };
    })
  );

  const payload: FetchUrlProps = { imageRefs: fileRefs, markSelected: false };
  const uploadedItems: Array<IStorageItem> = await fetchDownloadUrlsForFileRefs(payload);

  return uploadedItems;
};

const upload = async (file: File, folder: string): Promise<StorageReference> => {
  const fileName = `images/${folder}/${file.name}`;
  const storageRef = ref(storage, fileName);

  const uploadResult: UploadResult = await uploadBytes(storageRef, file);
  return uploadResult.ref;
};

export const uploadFile = (file: File, folder: string, callbackFn: (url: string) => void) => {
  const fileName = `images/${folder}/${file.name}`;
  const storageRef = ref(storage, fileName);

  const uploadTask = uploadBytesResumable(storageRef, file);

  // Register three observers:
  // 1. 'state_changed' observer, called any time the state changes
  // 2. Error observer, called on failure
  // 3. Completion observer, called on successful completion
  uploadTask.on(
    'state_changed',
    (snapshot) => {
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    },
    (error) => {
      // Handle unsuccessful uploads
      console.log('Error File ', error);
    },
    () => {
      // Handle successful uploads on complete
      // For instance, get the download URL: https://firebasestorage.googleapis.com/...
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        console.log('File available at', downloadURL);
        callbackFn(downloadURL);
      });
    }
  );
};
