import React, { useEffect, useState } from 'react';
import { CareerT } from 'types/Career.types';

import { AppIcons } from 'app/constants/Constants';
import { useStyledLogger } from 'app/config/LogConfig';
import { ComponentTitle } from 'app/constants/Localization';
import { getFinishedCourses } from 'helpers/careerHelpers';
import { isPrint } from 'helpers/appHelpers';
import { getProgressClass } from 'helpers/uiHelpers';

import ProgressIndicator from 'components/progress/ProgressIndicator';

type CoursesProgressProps = {
  className?: string;
  career: CareerT;
};

const PRINT_FLAG = false;

const CoursesProgress: React.FC<CoursesProgressProps> = ({ className = '', career }) => {
  const printMsg = useStyledLogger('CoursesProgress', 'darkgray', 'cornsilk');
  const [localCareer, setLocalCareer] = useState<CareerT>();

  const [totalCourses, setTotalCourses] = useState<number>(1);
  const [currentCourses, setCurrentCourses] = useState<number>(1);
  const [coursesCls, setCoursesCls] = useState<string>('');

  useEffect(() => {
    if (career) {
      setTotalCourses(0);
      setCurrentCourses(0);
      setCoursesCls('');
      setLocalCareer(career);
    }

    return () => {
      setLocalCareer(undefined);
    };
  }, [career]);

  useEffect(() => {
    if (localCareer && localCareer.courseIds) {
      const subscribedCourses = career.courseIds ? career.courseIds.length : 0;
      const finishedCourses = getFinishedCourses(career);
      const percentage = subscribedCourses > 0 ? Math.round(finishedCourses / subscribedCourses) * 100 : 10;
      const propNames = 'subscribedCourses:finishedCourses';
      const propValues = `${subscribedCourses}:${finishedCourses}`;
      isPrint(PRINT_FLAG) && console.log(...printMsg('calculateCoursesProgress', propNames, propValues));
      const cls = getProgressClass(Math.round(percentage / 10));

      setCurrentCourses(finishedCourses);
      setTotalCourses(subscribedCourses);
      setTimeout(() => {
        setCoursesCls(cls);
      }, 11);
    }
  }, [localCareer]);

  return (
    <ProgressIndicator
      icon={AppIcons.Career.Course}
      max={totalCourses}
      isFilledIcon={currentCourses > 0}
      value={currentCourses}
      className={`${className ?? ''} ${coursesCls}`}
      title={ComponentTitle.Courses}
    />
  );
};

export default CoursesProgress;
