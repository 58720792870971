import React, { useEffect, useRef, useState } from 'react';
import { useAppSelector } from 'app/hooks/hooks';

import { IProfile, ProfileId } from 'types/User.types';

import { getActiveProfileId, getUserProfiles } from 'modules/user/userSlice';

import CardShell from 'components/card/shell/CardShell';
import Popup from 'components/popup/Popup';
import IconButton from 'components/buttons/icon/IconButton';
import RoundedButton from 'components/buttons/rounded/RoundedButton';
import SimpleButton from 'components/buttons/simple/SimpleButton';
import InsetIcon from 'components/icon/InsetIcon';
import { ListCell, ListRow } from 'components/list/List';

import styles from './SwitchProfilesPopup.module.scss';

type SwitchProfilesPopupProps = {
  showPopup: boolean;
  handleOnCloseEvent?: () => void;
  handleClosePopup?: () => void;
  switchProfiles?: (profileId: ProfileId) => void;
};

const SwitchProfilesPopup: React.FC<SwitchProfilesPopupProps> = ({
  showPopup = false,
  handleClosePopup = () => undefined,
  handleOnCloseEvent = () => undefined,
  switchProfiles = () => undefined,
}) => {
  const popupRef = useRef(null);
  const profiles = useAppSelector(getUserProfiles);
  const activeProfileId = useAppSelector(getActiveProfileId);

  const [localProfileId, setLocalProfileId] = useState<ProfileId>(activeProfileId);

  useEffect(() => {
    if (showPopup && activeProfileId) {
      setLocalProfileId(activeProfileId);
    }
  }, [showPopup, activeProfileId]);

  return (
    <Popup
      id="SwitchProfilePopupId"
      ref={popupRef}
      className={styles.SwitchProfilesPopup}
      show={showPopup}
      popupType="wood"
      headerTitle="Select Profile"
      onCloseEvent={handleOnCloseEvent}>
      <CardShell>
        {profiles &&
          profiles.map((profile: IProfile) => (
            <ListRow key={`key_${profile.profileId}`} onClick={() => setLocalProfileId(profile.profileId)}>
              <IconButton
                icon={localProfileId === profile.profileId ? 'radio_button_checked' : 'radio_button_unchecked'}
                onClick={() => setLocalProfileId(profile.profileId)}
              />
              <ListCell cellSize="double" cellAlign="left">
                <InsetIcon className={styles.PopupBodyIcon} icon={profile.icon} size="xs145">
                  &nbsp;{profile.title}
                </InsetIcon>
              </ListCell>
            </ListRow>
          ))}
      </CardShell>

      <div className={styles.ActionButtons}>
        <SimpleButton onClick={handleClosePopup}>CANCEL</SimpleButton>
        <RoundedButton mainColor onClick={() => switchProfiles(localProfileId)} icon="switch_account">
          SWITCH
        </RoundedButton>
      </div>
    </Popup>
  );
};

export default SwitchProfilesPopup;
