import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PropsWithChildren, VisualStateT, VisualState } from 'types/App.types';
import { CrestT, CrestSizeT } from 'types/Career.types';
import { EMPTY_BADGE_URL } from 'helpers/badgeHelpers';

import Level from 'components/level/Level';

import styles from './CrestSimple.module.scss';

type CrestSimpleOwnProps = {
  crestItem: CrestT;
  disabled?: boolean;
  size?: CrestSizeT;
  state?: VisualStateT;
  toUrl?: string;
  badgeUrl?: string;
  showTitle?: boolean;
  onClick?: VoidFunction;
  crestVersion?: string;
  showBlurred?: boolean;
};
type CrestSimpleProps = PropsWithChildren<CrestSimpleOwnProps>;

const CrestSimple: React.FC<CrestSimpleProps> = ({
  crestItem,
  onClick = () => undefined,
  state = VisualState.Active,
  size = 'size660',
  showBlurred = false,
  ...props
}) => {
  const [mount, setMount] = useState<boolean>(false);
  const isActive = state === VisualState.Active;
  const hasLink = !!props.toUrl;
  const badgeUrl = (props.badgeUrl || crestItem.imageUrl) ?? EMPTY_BADGE_URL;
  const blurredCls = showBlurred ? styles.CrestBlurred : '';

  useEffect(() => {
    if (!mount) {
      setMount(true);
    }
  }, [mount]);

  const renderCrestBody = () => (
    <React.Fragment>
      <div className={styles.CrestWrapper}>
        <div className={styles.CrestContents}>
          <div className={styles.CrestChildren}>{props.children}</div>
          <span className={styles.CrestStars}>
            <Level size="xs12" level={crestItem.level || 1} />
          </span>
          <img className={`${styles.CrestImage}`} onClick={onClick} alt="crest" src={badgeUrl} />
        </div>
      </div>
    </React.Fragment>
  );

  const renderCrestWithLink = () => (
    <Link to={props.toUrl as string} className={`${styles.CrestDisplay} ${size} ${state} ${blurredCls}`}>
      {renderCrestBody()}
    </Link>
  );
  const renderCrestWithoutLink = () => (
    <div className={`${styles.CrestDisplay} ${size} ${state} ${blurredCls}`} onClick={onClick}>
      {renderCrestBody()}
    </div>
  );

  return <>{isActive && hasLink ? renderCrestWithLink() : renderCrestWithoutLink()}</>;
};

export default CrestSimple;
