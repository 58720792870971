import React, { useState } from 'react';

import MeIcon from 'components/icon/meicon/MeIcon';
import MeText from 'components/text/metext/MeText';
import MeToggle from 'components/toggle/MeToggle';
import Rule from 'components/rule/Rule';
import XCloseButton from 'components/buttons/xclosebutton/XCloseButton';

import styles from './HelpPanelHeader.module.scss';

type HelpPanelHeaderProps = {
  className?: string;
  soundOn?: boolean;
  title?: string;
  titleIcon?: string;
  onSoundToggle?: (isEnabled: boolean) => void;
  onClose: () => {
    payload: boolean;
    type: string;
  } | void;
};

const HelpPanelHeader: React.FC<HelpPanelHeaderProps> = ({ onSoundToggle = () => undefined, ...props }) => {
  const [showSoundSettings, setShowSoundSettings] = useState<boolean>(false);

  const onSettingsToggle = () => {
    setShowSoundSettings(!showSoundSettings);
  };

  return (
    <div className={styles.Header}>
      {showSoundSettings && (
        <MeToggle
          selected={!!props.soundOn}
          titleIcon="volume_up"
          indentRow
          showTitleIcon
          title={`Turn audio ${props.soundOn ? 'Off' : 'On'}`}
          onSelected={onSoundToggle}>
          Narrate the description of each feature
        </MeToggle>
      )}
      <Rule zeroMargin style={{ margin: '0 -1em 0.5em' }} />

      <MeIcon
        insetIcon
        isFilled={showSoundSettings}
        insetCls={styles.SettingsButton}
        showPointerCursor
        size="sma"
        weight="bold700"
        icon="settings_voice"
        onClick={onSettingsToggle}
      />

      <MeText useDiv insetText lineHeight="line12" weight="bold300" textSize="mel">
        <MeIcon icon={props.titleIcon ?? ''} /> {props.title}
      </MeText>
      <XCloseButton onClick={props.onClose} size='sma' className={styles.CloseButton} />
    </div>
  );
};

export default HelpPanelHeader;
