import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'app/hooks/hooks';
import { AppRoutes } from 'app/constants/Routes';
import { IProfile, ProfileId, ProfilesT } from 'types/User.types';

import { useStyledLogger } from 'app/config/LogConfig';
import { isPrint } from 'helpers/appHelpers';
import { getFormattedDate } from 'helpers/timeHelpers';

import { getUserProfiles } from 'modules/user/userSlice';
import StudentCard, { StudentModel } from 'modules/career/hub/components/studentcard/StudentCard';

import styles from './AdminHub.module.scss';

type AdminHubProps = {
  className?: string;
  userId: string;
};

const PRINT_FLAG = true;

const AdminHub: React.FC<AdminHubProps> = (props) => {
  // const printMsg = useStyledLogger('AdminHub', 'darkorange', 'cornsilk');
  const profiles = useAppSelector(getUserProfiles);

  return <AdminManageStudents {...props} profiles={profiles} />;
};

type AdminManageStudentsProps = {
  profiles: ProfilesT;
  isManageMode?: boolean;
  activeProfileId?: ProfileId;
} & AdminHubProps;

export const AdminManageStudents: React.FC<AdminManageStudentsProps> = (props) => {
  const printMsg = useStyledLogger('AdminManageStudents', 'darkorange', 'cornsilk');
  const profiles = props.profiles;
  const [studentModels, setStudentModels] = useState<Array<StudentModel>>([]);

  const createProfileUrl = props.isManageMode
    ? AppRoutes.ManagerUserProfileCreate.replace(':accountId', props.userId)
    : AppRoutes.UserCreateProfile.replace(':accountId', props.userId);

  const getEmptySeatAsStudentModel = (): StudentModel => ({
    id: 'emptyModel',
    className: styles.OpenSpot,
    bodyText: '...',
    buttonIcon: 'add_circle',
    buttonLabel: 'CREATE PROFILE',
    footerText: 'OPEN SEAT',
    icon: 'account_circle',
    to: createProfileUrl,
    title: 'OPEN',
  });

  useEffect(() => {
    const model = getEmptySeatAsStudentModel();
    const emptyModels: Array<StudentModel> = Array(3).fill(model);
    let result: Array<StudentModel> = [];

    if (!profiles) {
      setStudentModels(emptyModels);
      return;
    }

    isPrint(PRINT_FLAG) && console.log(...printMsg('AdminHub', 'profiles', `${profiles.length}`));
    const studentProfileModels: Array<StudentModel> = profiles.map((p: IProfile) => ({
      id: p.profileId,
      className: '',
      bodyText: `Total Courses: ${p.courseIds.length}`,
      buttonIcon: 'user_attributes',
      buttonLabel: 'MANAGE PROFILE',
      footerText: `Established ${getFormattedDate(p.createdOn)}`,
      icon: p.icon,
      to: props.isManageMode
        ? AppRoutes.ManagerUserProfileEdit.replace(':accountId', p.uid).replace(':profileId', p.profileId)
        : AppRoutes.UserProfile.replace(':accountId', p.uid).replace(':profileId', p.profileId),
      title: p.title.toUpperCase(),
    }));
    result = studentProfileModels.concat(emptyModels);
    setStudentModels(result.slice(0, 3));
  }, [profiles]);

  return (
    <div className={`${styles.AdminHubPanel} ${props.className ?? ''}`}>
      {studentModels.map((model: StudentModel, index: number) => (
        <StudentCard key={`${model.id}_${index}`} {...model} isActiveProfile={model.id === props.activeProfileId} />
      ))}
    </div>
  );
};

export default AdminHub;
