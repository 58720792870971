import React, { useEffect, useRef, useState } from 'react';

import styles from './Toast.module.scss';
import { useAppDispatch, useAppSelector } from 'app/hooks/hooks';
import { MaybeType, AppNotificationT, ToastNotificationMessageT } from 'types/App.types';

import { getToastMessage, setShowToast, showToastNotification } from 'modules/user/userUiSlice';
import { getIconByNotificationToastType } from 'helpers/uiHelpers';
import CardShell from 'components/card/shell/CardShell';
import InsetText from 'components/text/inset/InsetText';
import InsetIcon from 'components/icon/InsetIcon';

type ToastOwnProps = {
  className?: string;
};

const Toast: React.FC<ToastOwnProps> = () => {
  const openToastTimerIdRef: React.MutableRefObject<NodeJS.Timeout | null> = useRef(null);
  const [visibleCls, setVisibleCls] = useState<string>('');
  const showToaster = useAppSelector(showToastNotification);
  const notification: MaybeType<ToastNotificationMessageT> = useAppSelector(getToastMessage);

  const dispatch = useAppDispatch();

  const hideToast = () => dispatch(setShowToast(false));

  useEffect(() => {
    if (!visibleCls && !showToaster) return;

    const cls = showToaster ? styles.ToastShow : styles.ToastHide;
    setVisibleCls(cls);

    if (showToaster) {
      openToastTimerIdRef.current = setTimeout(() => {
        dispatch(setShowToast(false));
      }, 3000);
    }

    return () => clearTimeout(openToastTimerIdRef.current ?? undefined);
  }, [dispatch, showToaster]);

  const getIconByType = (type: AppNotificationT = 'info') => {
    const iconName = getIconByNotificationToastType(type);
    return (
      <span className={type}>
        <InsetIcon icon={iconName} size="mlg" />
      </span>
    );
  };

  const getMessageText = (messageText = 'Welcome to Montessori') => {
    return (
      <span className={styles.ToastMessage}>
        <InsetText size="xs12">{messageText}</InsetText>
      </span>
    );
  };

  return (
    <div className={`${styles.Toast} ${visibleCls}`} onClick={hideToast}>
      <CardShell>
        <span className={`${styles.ToastRow}`}>
          {getIconByType(notification ? notification.type : 'info')}
          {getMessageText(notification ? notification.message : 'Welcome to Montessori')}
        </span>
      </CardShell>
    </div>
  );
};

export default Toast;
