import React, { useEffect, useState } from 'react';
import { PropsWithChildren, ElementSizeT, VoidFunc } from 'types/App.types';

import InsetIcon from 'components/icon/InsetIcon';

import styles from './CollapsibleListHandle.module.scss';

export type CollapsibleListHandleProps = {
  isOpen?: boolean;
  handleOpenIcon?: string;
  handleCloseIcon?: string;
  handleIconSize?: ElementSizeT;
  onHandleClicked?: VoidFunc;
  handleClickable?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const CollapsibleListHandle: React.FC<PropsWithChildren<CollapsibleListHandleProps>> = ({
  className = '',
  isOpen = true,
  onHandleClicked = () => undefined,
  handleOpenIcon = 'expand_more',
  handleCloseIcon = 'expand_less',
  handleIconSize = 'xxs',
  handleClickable = false,
  ...props
}) => {
  const [handleIcon, setHandleIcon] = useState<string>(isOpen ? handleCloseIcon : handleOpenIcon);

  useEffect(() => {
    setHandleIcon(isOpen ? handleCloseIcon : handleOpenIcon);
  }, [handleCloseIcon, handleOpenIcon, isOpen]);

  const clickableCls = handleClickable ? styles.CollapsibleListHandleClickable : '';
  const clickHandler = handleClickable ? onHandleClicked : undefined;

  return (
    <div style={props.style} className={`${styles.CollapsibleListHandle} ${className}`}>
      <InsetIcon icon={handleIcon} onClick={onHandleClicked} size={handleIconSize} />
      <div className={`${styles.CollapsibleListHandle} ${clickableCls}`} id="collapsibleHandleId" onClick={clickHandler}>
        {props.children}
      </div>
    </div>
  );
};

export default CollapsibleListHandle;
