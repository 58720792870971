import React, { useEffect, useState } from 'react';
import { SpotlightShapeT } from 'types/Tutorial.types';

import styles from './Ring.module.scss';

type RingProps = {
  className?: string;
  show?: boolean;
  width?: string;
  height?: string;
  spotlightShape: SpotlightShapeT;
};

/**
 * Animates ring as if the circle is being reduces to zero
 *
 * @param {RingProps} props - layout and control props
 *
 * @return {JSX.Element} - animating disappearing ring
 */
const Ring: React.FC<RingProps> = ({ spotlightShape = 'circle', ...props }) => {
  const [animationCls, setAnimationCls] = useState<string>(styles.RingAnimation);
  const [shapeCls, setShapeCls] = useState<string>(styles.Circle);

  useEffect(() => {
    setAnimationCls(props.show ? styles.RingAnimation : '');
  }, [props.show]);

  useEffect(() => {
    setShapeCls(spotlightShape === 'circle' ? styles.Circle : styles.Square);
  }, [spotlightShape]);

  return (
    <>
      {spotlightShape === 'circle' ? (
        <div
          className={`${styles.RingBox} ${shapeCls} ${animationCls}`}
          style={{ width: props.width, height: props.height }}>
          <span className={`${styles.RightClipped} `}>
            <span className={`${styles.RightRing} ${shapeCls} ${styles.RightRotation}`}></span>
          </span>
          <span className={`${styles.LeftClipped} `}>
            <span className={`${styles.LeftRing} ${shapeCls} ${styles.LeftRotation}`}></span>
          </span>
        </div>
      ) : (
        <div
          className={`${styles.RingBox} ${shapeCls} ${animationCls}`}
          style={{ width: props.width, height: props.height }}>
          <span className={`${styles.TopBox} ${shapeCls} ${styles.TopRotation}`}></span>
          <span className={`${styles.RightBox} ${shapeCls} ${styles.RightRotation}`}></span>
          <span className={`${styles.BottomBox} ${shapeCls} ${styles.BottomRotation}`}></span>
          <span className={`${styles.LeftBox} ${shapeCls} ${styles.LeftRotation}`}></span>
        </div>
      )}
    </>
  );
};

export default Ring;
