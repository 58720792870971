import React from 'react';
import MeText from 'components/text/metext/MeText';

type DescriptionTextProps = {
  className?: string;
  description: React.ReactNode;
  defaultText?: string;
};

export const defaultHelpText = 'Tap on a feature to learn more about it.';
const DescriptionText: React.FC<DescriptionTextProps> = (props) => {
  return (
    <MeText className={`${props.className ?? ''}`} insetText lineHeight="line12" weight="bold300" textSize="med">
      {props.description || props.defaultText || defaultHelpText}
    </MeText>
  );
};

export default DescriptionText;
