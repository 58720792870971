import { AppThunk, persistor, RootState } from 'app/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { memoize } from 'proxy-memoize';
import {
  auth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  analytics,
  logEvent,
  sendEmailVerification,
  sendPasswordResetEmail,
  User,
  UserCredential,
} from '../../firebase/Firebase';
import { AppStatus, MaybeType, AppNotification, Animations } from 'types/App.types';
import { CareerId } from 'types/Career.types';
import { CourseIds, CoursesT } from 'types/Course.types';
import {
  IUser,
  IProfile,
  ProfileId,
  ProfilesT,
  UpdatedUserProfilePayload,
  UserStatusT,
  PreferencesT,
} from 'types/User.types';

import { getFormattedString, getNameClsString } from 'app/config/LogConfig';

import {
  setShowLoginForm,
  setShowCreateAccountForm,
  setCreateAccountFormError,
  setLoginFormError,
  setToastMessage,
  setShowToast,
  setResetPasswordFormError,
  setToastNotification,
  setPassKeyPanelAnimation,
  setPassKeyPanelClosed,
  getPassKeyUiState,
  PassKeyUiState,
  setPassKeyUiState,
} from './userUiSlice';
import { resetCareer } from 'modules/career/careerSlice';
import { resetEditor } from 'scenes/management/managerSlice';
import { doSetUserPreferencesState, resetUserPreferencesState } from 'modules/user/userPreferencesSlice';
import { resetAwards } from 'modules/profiles/awards/awardsSlice';
import { resetGames } from 'scenes/games/gamesSlice';
import { resetUnlocks } from 'scenes/montessori/unlocksSlice';
import { resetScenes } from 'scenes/scenesSlice';

import {
  createProfileWithCareer,
  fetchProfilesByUserId,
  patchUserProfile,
  updateOwnUserAccountSceneAction,
} from 'api/ProfilesAPI';
import { fetchPublicCourses } from 'api/CoursesAPI';
import { createUserRecordOnUserAccountCreation, fetchUserInfo, resetUserPassKey, updateUserAccount } from 'api/UserAPI';
import { getUserPreferences } from 'api/PreferencesAPI';
import { getInitialUserProfile } from 'api/converters/ProfilesConverter';

import { isPrint } from 'helpers/appHelpers';
import { getToastSuccessPayload, getToastWarningPayload } from 'helpers/uiHelpers';
import { encodeUserPassKey, generateResetPassKey } from 'modules/user/secret/userHelper';

const PRIMARY_COLOR = getNameClsString('powderblue');
const CLS_NAME = 'UserSlice';
const PRINT_FLAG = true;

const printMsg = (action: string, prop: string, status: string) =>
  getFormattedString(PRIMARY_COLOR, CLS_NAME, action, prop, status);

export type PassKeyActionStateT =
  | 'passkey_hidden'
  | 'passkey_validate_pin'
  | 'passkey_update_pin'
  | 'passkey_validate_student_pin'
  | 'passkey_update_student_pin'
  | 'passkey_reset_pin'
  | 'passkey_enable'
  | 'passkey_disable';
export interface UserState extends IUser, VerificationInfo {
  isAuthStateObserverMounted: boolean;
  userStatus: UserStatusT;
  profiles: Array<IProfile>;
  showDebugPanel?: boolean;
  showContactSupportPanel?: boolean;
  userPassKeyActionState: PassKeyActionStateT;
  userPassKeyActionPrevState?: PassKeyActionStateT;
}

export type VerificationInfo = {
  verificationSent: boolean;
  verificationSentTimestamp: number;
};

const PASSKEY_HIDDEN_DEFAULT: PassKeyUiState = {
  state: 'verify_passkey_pin',
  passKeyPanelVisible: false,
  animationState: Animations.None,
};

export const initialState: UserState = {
  uid: '',
  activeProfileId: '',
  firstname: '',
  lastname: '',
  email: '',
  emailVerified: false,
  isEditor: false,
  isAdmin: false,
  profileIds: [],
  profiles: [],
  isAuthStateObserverMounted: false,
  userStatus: 'idle',
  createdBy: '',
  createdOn: 0,
  modifiedBy: '',
  modifiedOn: 0,
  verificationSent: false,
  verificationSentTimestamp: 0,
  showDebugPanel: false,
  showContactSupportPanel: true,
  userPassKey: '0000',
  userPassKeyEnabled: false,
  userPassKeyActionState: 'passkey_hidden',
  userLevel: 0,
};
// #region USER AUTH ACTIONS ************************************************************
export const performUserLogout = (): AppThunk => (dispatch) => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('performUserLogout', '', ''));
  dispatch(setUserStatus('signingOut'));
  signOut(auth)
    .then(() => {
      dispatch(setUserStatus('signedOut'));
      dispatch(resetCareer());
      dispatch(resetEditor());
      dispatch(resetAwards());
      dispatch(resetGames());
      dispatch(resetUnlocks());
      dispatch(resetUserPreferencesState());
      dispatch(resetScenes());
      dispatch(setToastNotification(getToastSuccessPayload('Signed Out')));
      persistor.purge();
      persistor.pause();
    })
    .catch((reason) => {
      isPrint(PRINT_FLAG) && console.log(...printMsg('performUserLogout', 'Error', `${reason}`));
      logEvent(analytics, 'exception', { description: `method: performUserLogout, reason: ${reason}` });
      dispatch(setUserStatus('idle'));
    });
};

export const displayCreateAccountForm = (): AppThunk => (dispatch) => {
  isPrint(PRINT_FLAG) && console.log(...printMsg('displayCreateAccountForm', '', ''));
  dispatch(setShowLoginForm(false));
  dispatch(setShowCreateAccountForm(true));
};

export const performCreateAccount =
  (email: string, password: string): AppThunk =>
  (dispatch) => {
    dispatch(setUserStatus('creatingAccount'));
    dispatch(setCreateAccountFormError(''));
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userModel: UserCredential) => {
        const newUser: IUser = await createUserRecordOnUserAccountCreation(userModel.user);
        dispatch(setToastNotification(getToastSuccessPayload('Account Created')));
        // create profile and career
        const userProfile: IProfile = getInitialUserProfile(newUser.uid);
        const manyCourses: CoursesT = await fetchPublicCourses();
        const courses = manyCourses.slice(0, 1);
        const courseIds: CourseIds = courses.map((c) => c.courseId);
        const createdData = await createProfileWithCareer({ ...userProfile, courseIds }, courses);
        const userData: IUser = {
          ...newUser,
          activeProfileId: createdData.profile.profileId,
          profileIds: [createdData.profile.profileId],
        };
        await updateUserAccount(userData);
        dispatch(setUserProfiles([createdData.profile]));
        dispatch(setToastNotification(getToastSuccessPayload('Profile Created')));

        // send account email verification
        dispatch(doSendVerificationEmail());
        dispatch(setShowCreateAccountForm(false));
        logEvent(analytics, 'sign_up', { method: 'signInWithEmailAndPassword' });
        dispatch(setUserStatus('accountCreated'));

        dispatch(setUserId(userData.uid));
        dispatch(setUserInfo(userData));

        const preferences = await getUserPreferences(userData.activeProfileId);
        dispatch(doSetUserPreferencesState({ preferences: preferences, status: 'loaded' }));
        dispatch(setToastNotification(getToastSuccessPayload(`${userData.email} signed in`)));
        dispatch(setUserStatus('signedIn'));
      })
      .catch((reason) => {
        isPrint(PRINT_FLAG) && console.log(...printMsg('performCreateAccount', 'Error', `${reason}`));
        logEvent(analytics, 'exception', { description: `method: performCreateAccount, reason: ${reason}` });
        dispatch(setCreateAccountFormError(reason.code));
        dispatch(setUserStatus('idle'));
      });
  };

export const doUpdateUserProfile =
  (profilePayload: UpdatedUserProfilePayload): AppThunk =>
  async (dispatch) => {
    isPrint(PRINT_FLAG) && console.log(...printMsg('doUpdateUserProfile', '', ''));
    dispatch(updateUserProfile(profilePayload));
    await updateOwnUserAccountSceneAction(profilePayload.userProfile.profileId);
  };

export const doSendVerificationEmail = (): AppThunk => async (dispatch) => {
  if (auth.currentUser) {
    sendEmailVerification(auth.currentUser);
    dispatch(setVerificationSent({ verificationSent: true, verificationSentTimestamp: Date.now() }));
  } else {
    isPrint(PRINT_FLAG) && console.log(...printMsg('doSendVerificationEmail', 'ERROR', 'no auth.currentUser'));
    logEvent(analytics, 'exception', { description: 'method: doSendVerificationEmail, reason: no auth.currentUser' });
    dispatch(setVerificationSent({ verificationSent: false, verificationSentTimestamp: Date.now() }));
  }
};

export const performEmailPasswordUserLogin =
  (email: string, password: string): AppThunk =>
  (dispatch) => {
    dispatch(setUserStatus('signingIn'));
    dispatch(setLoginFormError(''));
    signInWithEmailAndPassword(auth, email, password)
      .then((userModel) => {
        persistor.persist();
        const maybeEmail = userModel.user.email ?? 'no email';
        isPrint(PRINT_FLAG) && console.log(...printMsg('performEmailPasswordUserLogin', 'email', maybeEmail));
        dispatch(setShowLoginForm(false));
        logEvent(analytics, 'login', { method: 'signInWithEmailAndPassword' });
      })
      .catch((reason) => {
        isPrint(PRINT_FLAG) && console.log(...printMsg('performEmailPasswordUserLogin', 'Error', `${reason}`));
        dispatch(setLoginFormError(reason.code));
        logEvent(analytics, 'exception', { description: `method: signInWithEmailAndPassword, reason: ${reason}` });
        dispatch(setUserStatus('idle'));
      });
  };

export const subscribeUserStatusChange = (): AppThunk => (dispatch, getState) => {
  const isMounted = getState().user.isAuthStateObserverMounted;

  if (!isMounted) {
    dispatch(setAuthStateObserverMounted(true));
    dispatch(setUserStatus('signingIn'));

    auth.onAuthStateChanged(async (userModel: User | null) => {
      if (userModel) {
        const uid = userModel.uid;
        const verified = userModel.emailVerified;
        const verifiedStatus = `${verified ? '' : 'not '}verified`;
        isPrint(PRINT_FLAG) && console.log(...printMsg('onAuthStateChanged', `${userModel.email}`, verifiedStatus));

        if (getState().user.userStatus === 'creatingAccount') {
          isPrint(PRINT_FLAG) && console.log(...printMsg('onAuthStateChanged', 'creatingAccount', 'exiting this flow'));
          return;
        }

        if (getState().ui.appUi.appStatus === AppStatus.Offline) {
          if (getState().user.userStatus === 'signingIn' && getState().ui.userUi.showLoginForm) {
            dispatch(setLoginFormError('app/offline'));
          }

          dispatch(setUserStatus('notSignedIn'));
          dispatch(setToastMessage({ message: 'App is OFFLINE', type: AppNotification.Warning }));
          dispatch(setShowToast(true));
          return;
        }

        try {
          const userInfo: IUser = await fetchUserInfo(uid, userModel.emailVerified);
          dispatch(setUserId(uid));
          dispatch(setUserInfo(userInfo));
          const profilesT = await fetchProfilesByUserId(uid);
          if (profilesT) {
            dispatch(setUserProfiles(profilesT));
            const profileTitle = userInfo.activeProfileId ? getActiveProfileTitle(getState()) : userInfo.firstname;
            if (userInfo.activeProfileId) {
              const preferences = await getUserPreferences(userInfo.activeProfileId);
              dispatch(doSetUserPreferencesState({ preferences: preferences, status: 'loaded' }));
            }
            dispatch(setToastNotification(getToastSuccessPayload(`${profileTitle} signed in`)));
          }
          dispatch(setUserStatus('signedIn'));
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          dispatch(performUserLogout());
        }
      } else if (getUserStatus(getState()) === 'idle' || getUserStatus(getState()) === 'signingIn') {
        dispatch(setUserStatus('notSignedIn'));
      } else {
        dispatch(setEditorState(false));
        dispatch(resetState());
      }
    });
  }
};

export const resetUserPassword =
  (email: string): AppThunk =>
  (dispatch) => {
    dispatch(setUserStatus('resettingPassword'));
    dispatch(setResetPasswordFormError(''));
    sendPasswordResetEmail(auth, email)
      .then(() => {
        dispatch(setUserStatus('passwordReset'));
      })
      .catch((reason) => {
        isPrint(PRINT_FLAG) && console.log(...printMsg('resetUserPassword', 'Error', `${reason}`));
        logEvent(analytics, 'exception', { description: `method: resetUserPassword, reason: ${reason}` });
        dispatch(setResetPasswordFormError(reason.code));
        dispatch(setUserStatus('idle'));
      });
  };

export const doSetActiveProfileId =
  (profileId: ProfileId = ''): AppThunk =>
  async (dispatch) => {
    if (profileId) {
      dispatch(setActiveProfileId(profileId));
      const preferences: PreferencesT = await getUserPreferences(profileId);
      dispatch(doSetUserPreferencesState({ preferences: preferences, status: 'loaded' }));
    } else {
      dispatch(resetUserPreferencesState());
      dispatch(setActiveProfileId(''));
    }
  };
// #endregion
// #region PASSKEY ACTIONS **************************************************************
export const doProcessOnPassKeyAction =
  (passKey: string, profileId?: ProfileId, isStudentPassKeyMode?: boolean): AppThunk =>
  async (dispatch, getState) => {
    const passKeyUiState: PassKeyUiState = getPassKeyUiState(getState());
    const passKeyActionState: PassKeyActionStateT = getUserPassKeyActionState(getState());

    isPrint(PRINT_FLAG) && console.log(...printMsg('doProcessOnPassKey', 'state', passKeyUiState.state));
    isPrint(PRINT_FLAG) && console.log(...printMsg('doProcessOnPassKey', 'passKeyActionState', passKeyActionState));

    if (passKeyUiState.state === 'set_passkey_pin') {
      if (passKeyActionState === 'passkey_update_pin') {
        dispatch(doUpdateUserPassKey(passKey, 'PassKey Updated'));
      } else if (passKeyActionState === 'passkey_update_student_pin') {
        dispatch(doUpdateStudentPassKeyPin(passKey, profileId as ProfileId, !!isStudentPassKeyMode));
      } else if (passKeyActionState === 'passkey_enable') {
        dispatch(doEnableUserPassKey(passKey));
      }
    } else if (passKeyUiState.state === 'verify_passkey_pin') {
      if (passKeyActionState === 'passkey_disable') {
        dispatch(doDisableUserPassKey(passKey));
      } else if (passKeyActionState === 'passkey_validate_pin') {
        dispatch(
          doValidateUserPassKey(
            passKey,
            {
              state: 'verify_passkey_pin',
              passKeyPanelVisible: false,
              animationState: Animations.None,
            },
            true
          )
        );
      } else if (passKeyActionState === 'passkey_update_pin') {
        dispatch(
          doValidateUserPassKey(
            passKey,
            {
              state: 'set_passkey_pin',
              passKeyPanelVisible: true,
              animationState: Animations.None,
            },
            false
          )
        );
      } else if (passKeyActionState === 'passkey_validate_student_pin') {
        dispatch(doValidateStudentPassKey(passKey, PASSKEY_HIDDEN_DEFAULT, true, profileId));
      }
    }
  };

export const doProcessOnPassKeyNoAction = (): AppThunk => async (dispatch, getState) => {
  const passKeyUiState: PassKeyUiState = getPassKeyUiState(getState());
  const passKeyActionState: PassKeyActionStateT = getUserPassKeyActionState(getState());
  const title = 'doProcessOnPassKeyNoAction';

  isPrint(PRINT_FLAG) && console.log(...printMsg(title, 'state', passKeyUiState.state));
  isPrint(PRINT_FLAG) && console.log(...printMsg(title, 'passKeyActionState', passKeyActionState));

  dispatch(setPassKeyPanelClosed());
  dispatch(setUserPassKeyActionState('passkey_hidden'));
};

export const doValidateUserPassKey =
  (passKey: string, onSuccessPassKeyUiState: PassKeyUiState, shouldHide: boolean): AppThunk =>
  async (dispatch, getState) => {
    const userPassKey = getUserPassKey(getState());

    if (passKey && passKey === userPassKey) {
      dispatch(setPassKeyPanelAnimation(Animations.Pass));
      setTimeout(() => {
        dispatch(setPassKeyUiState(onSuccessPassKeyUiState));
        if (shouldHide) dispatch(setUserPassKeyActionState('passkey_hidden'));
      }, 500);
    } else if (passKey && passKey !== userPassKey) {
      dispatch(setPassKeyPanelAnimation(Animations.Fail));
      setTimeout(() => dispatch(setPassKeyPanelAnimation(Animations.None)), 1500);
    }
  };

export const doUpdateUserPassKey =
  (passKey: string, successMessage: string): AppThunk =>
  async (dispatch, getState) => {
    if (passKey) {
      const user = getUser(getState());
      await updateUserAccount({ ...user, userPassKey: passKey });
      dispatch(setUserPassKey(passKey));
      dispatch(setPassKeyPanelClosed());
      dispatch(setUserPassKeyActionState('passkey_hidden'));
      dispatch(setToastNotification(getToastSuccessPayload(successMessage)));
    }
  };

export const doResetUserPassKey = (): AppThunk => async (dispatch, getState) => {
  const user = getUser(getState());
  const passKey = generateResetPassKey(user.uid);
  const userWithNewPassKey = { ...user, userPassKey: passKey };
  await resetUserPassKey(userWithNewPassKey);
  dispatch(setUserPassKey(passKey));
  dispatch(setPassKeyPanelClosed());
  dispatch(setUserPassKeyActionState('passkey_reset_pin'));
  setTimeout(() => {
    dispatch(setToastNotification(getToastSuccessPayload('Submitted request to reset PassKey, check your email')));
  }, 500);
};

export const doDisableUserPassKey =
  (passKey: string): AppThunk =>
  async (dispatch, getState) => {
    const user = getUser(getState());
    const userPassKey = getUserPassKey(getState());

    if (passKey && passKey === userPassKey) {
      dispatch(setPassKeyPanelAnimation(Animations.Pass));
      await updateUserAccount({ ...user, userPassKeyEnabled: false, userPassKey: passKey });
      setTimeout(() => {
        dispatch(setPassKeyPanelClosed());
        dispatch(setUserPassKeyEnabled(false));
        dispatch(setUserPassKeyActionState('passkey_hidden'));
        dispatch(setToastNotification(getToastWarningPayload('PassKey has been turned OFF')));
      }, 500);
    } else if (passKey && passKey !== userPassKey) {
      dispatch(setPassKeyPanelAnimation(Animations.Fail));
      setTimeout(() => dispatch(setPassKeyPanelAnimation(Animations.None)), 1500);
    }
  };

export const doEnableUserPassKey =
  (passKey: string): AppThunk =>
  async (dispatch, getState) => {
    const user = getUser(getState());

    dispatch(setPassKeyPanelAnimation(Animations.Pass));
    await updateUserAccount({ ...user, userPassKeyEnabled: true, userPassKey: passKey });
    setTimeout(() => {
      dispatch(setUserPassKeyEnabled(true));
      dispatch(setUserPassKey(passKey));
      dispatch(setPassKeyPanelClosed());
      dispatch(setUserPassKeyActionState('passkey_hidden'));
      dispatch(setToastNotification(getToastSuccessPayload('PassKey is Set and turned ON')));
    }, 500);
  };

/**
 * Validates the correctness of Student profile by comparing the submitted PassKey pin with the stored PassKey pin.
 *
 * @param {string} passKey - encoded user entered PassKey
 * @param {PassKeyUiState} onSuccessPassKeyUiState - state to set when validation is done
 * @param {boolean} shouldHide - should hide the popup when done
 * @param {ProfileId} profileId (optional) if student mode then we need profileId to fetch the correct profile
 * @return {AppThunk}
 */
export const doValidateStudentPassKey =
  (passKey: string, onSuccessPassKeyUiState: PassKeyUiState, shouldHide: boolean, profileId?: ProfileId): AppThunk =>
  async (dispatch, getState) => {
    if (profileId) {
      const studentPassKey = getStudentPassKey(getState(), profileId);

      if (passKey && passKey === studentPassKey) {
        dispatch(setPassKeyPanelAnimation(Animations.Pass));
        setTimeout(() => {
          dispatch(setPassKeyUiState(onSuccessPassKeyUiState));
          if (shouldHide) dispatch(setUserPassKeyActionState('passkey_hidden'));
        }, 500);
      } else if (passKey && passKey !== studentPassKey) {
        dispatch(setPassKeyPanelAnimation(Animations.Fail));
        setTimeout(() => dispatch(setPassKeyPanelAnimation(Animations.None)), 1500);
      }
    } else {
      isPrint(PRINT_FLAG) && console.log(...printMsg('doValidateStudentPassKey', 'missing profile id', ''));
    }
  };

export const doEnableStudentPassKey =
  (passKey: string, profileId: ProfileId): AppThunk =>
  (dispatch) => {
    dispatch(updateStudentPassKey(passKey, profileId, true, 'is turned ON'));
  };

export const doDisableStudentPassKey =
  (passKey: string, profileId: ProfileId): AppThunk =>
  (dispatch) => {
    dispatch(updateStudentPassKey(passKey, profileId, false, 'is turned OFF'));
  };

/**
 * Sets new PassKey pin on the supplied `ProfileId` Student record.
 *
 * @param {string} passKey - encoded user entered PassKey
 * @param {ProfileId} profileId (optional) if student mode then we need profileId to fetch the correct profile
 * @param {boolean} enabled - current state of Student's PassKey (ON/OFF)
 * @return {AppThunk}
 */
export const doUpdateStudentPassKeyPin =
  (passKey: string, profileId: ProfileId, enabled: boolean): AppThunk =>
  (dispatch) => {
    dispatch(updateStudentPassKey(passKey, profileId, enabled, 'is UPDATED'));
  };

const updateStudentPassKey =
  (passKey: string, profileId: ProfileId, enable: boolean, message: string): AppThunk =>
  async (dispatch, getState) => {
    const profiles = getUserProfiles(getState());
    const profileIndex = profiles.findIndex((p) => p.profileId === profileId);
    if (profileIndex > -1) {
      const profile = profiles[profileIndex];
      const updatedProfile = { ...profile, userPassKeyEnabled: enable, userPassKey: passKey };
      await patchUserProfile(updatedProfile);
      setTimeout(() => {
        dispatch(setPassKeyUiState(PASSKEY_HIDDEN_DEFAULT));
        dispatch(updateUserProfile({ index: profileIndex, userProfile: updatedProfile }));
        dispatch(setToastNotification(getToastSuccessPayload(`Student PassKey ${message}`)));
      }, 500);
    } else {
      isPrint(PRINT_FLAG) && console.log(...printMsg('updateStudentPassKey', 'missing profile id', ''));
    }
  };
// #endregion

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<IUser>) => {
      state.uid = action.payload.uid;
      state.firstname = action.payload.firstname;
      state.lastname = action.payload.lastname;
      state.activeProfileId = action.payload.activeProfileId;
      state.email = action.payload.email;
      state.emailVerified = action.payload.emailVerified;
      state.isEditor = action.payload.isEditor;
      state.isAdmin = action.payload.isAdmin;
      state.profileIds = action.payload.profileIds;
      state.createdBy = action.payload.createdBy;
      state.createdOn = action.payload.createdOn;
      state.modifiedBy = action.payload.modifiedBy;
      state.modifiedOn = action.payload.modifiedOn;
      state.userPassKey = action.payload.userPassKey ?? encodeUserPassKey('0000', action.payload.uid);
      state.userPassKeyEnabled = !!action.payload.userPassKeyEnabled;
      state.userLevel = action.payload.userLevel;
      state.userPassKeyActionState = 'passkey_hidden';
      state.userPassKeyActionPrevState = undefined;
    },
    setUserId: (state, action: PayloadAction<string>) => {
      state.uid = action.payload;
    },
    setUserProfiles: (state, action: PayloadAction<ProfilesT>) => {
      state.profiles = action.payload;
    },
    setActiveProfileId: (state, action: PayloadAction<string>) => {
      state.activeProfileId = action.payload;
    },
    setAuthStateObserverMounted: (state, action: PayloadAction<boolean>) => {
      state.isAuthStateObserverMounted = action.payload;
    },
    setUserStatus: (state, action: PayloadAction<UserStatusT>) => {
      state.userStatus = action.payload;
    },
    setEditorState: (state, action: PayloadAction<boolean>) => {
      state.isEditor = action.payload;
    },
    setAdminState: (state, action: PayloadAction<boolean>) => {
      state.isAdmin = action.payload;
    },
    addUserProfile: (state, action: PayloadAction<IProfile>) => {
      const temp = state.profiles.concat(action.payload);
      state.profiles = temp;
      state.profileIds = temp.map((p) => p.profileId);
    },
    updateUserProfile: (state, action: PayloadAction<UpdatedUserProfilePayload>) => {
      const temp = state.profiles.slice() ?? [];
      temp.splice(action.payload.index, 1, action.payload.userProfile);
      state.profileIds = temp.map((p) => p.profileId);
      state.profiles = temp;
    },
    setVerificationSent: (state, action: PayloadAction<VerificationInfo>) => {
      state.verificationSent = action.payload.verificationSent;
      state.verificationSentTimestamp = action.payload.verificationSentTimestamp;
    },
    setEmailVerified: (state, action: PayloadAction<boolean>) => {
      state.emailVerified = action.payload;
    },
    setShowDebugPanel: (state, action: PayloadAction<boolean>) => {
      state.showDebugPanel = action.payload;
    },
    setShowContactSupportPanel: (state, action: PayloadAction<boolean>) => {
      state.showContactSupportPanel = action.payload;
    },
    setUserPassKey: (state, action: PayloadAction<string>) => {
      state.userPassKey = action.payload;
    },
    setUserPassKeyEnabled: (state, action: PayloadAction<boolean>) => {
      state.userPassKeyEnabled = action.payload;
    },
    setUserPassKeyActionState: (state, action: PayloadAction<PassKeyActionStateT>) => {
      state.userPassKeyActionPrevState = state.userPassKeyActionState;
      state.userPassKeyActionState = action.payload;
    },
    setUserLevel: (state, action: PayloadAction<number>) => {
      state.userLevel = action.payload;
    },
    resetState: (state) => {
      return { ...initialState, isAuthStateObserverMounted: state.isAuthStateObserverMounted };
    },
  },
});

export const {
  setUserInfo,
  setUserId,
  setUserProfiles,
  setActiveProfileId,
  setAuthStateObserverMounted,
  setUserStatus,
  setEditorState,
  setAdminState,
  addUserProfile,
  updateUserProfile,
  setVerificationSent,
  setEmailVerified,
  setShowDebugPanel,
  setShowContactSupportPanel,
  setUserPassKey,
  setUserPassKeyEnabled,
  setUserPassKeyActionState,
  setUserLevel,
  resetState,
} = userSlice.actions;

// #region USER PASSKEY *****************************************************************
export const isUserPassKeyEnabled = (state: RootState): boolean => state.user.userPassKeyEnabled;
export const getUserPassKeyActionState = (state: RootState): PassKeyActionStateT => state.user.userPassKeyActionState;
export const getUserPassKeyActionPrevState = (state: RootState): PassKeyActionStateT | undefined =>
  state.user.userPassKeyActionPrevState;
export const getUserPassKey = (state: RootState): string => state.user.userPassKey ?? '';
export const getUserLevel = (state: RootState): number => state.user.userLevel ?? 0;

export const getStudentPassKeyEnabled = (state: RootState, profileId: ProfileId): boolean =>
  createSelector([getUserProfiles], (profiles) => {
    if (profiles) {
      const profile: IProfile | undefined = profiles.find((p) => p.profileId === profileId);
      if (profile) {
        return profile.userPassKeyEnabled;
      }
    }
    return false;
  })(state);

export const getStudentPassKey = (state: RootState, profileId: ProfileId): string =>
  createSelector([getUserProfiles], (profiles) => {
    if (profiles) {
      const profile: IProfile | undefined = profiles.find((p) => p.profileId === profileId);
      if (profile) {
        return profile.userPassKey;
      }
    }
    return encodeUserPassKey('1111', profileId);
  })(state);
// #endregion

export const getUserId = (state: RootState): string => state.user.uid;
export const getUser = (state: RootState): IUser => state.user;
export const isAuthStateObserverMounted = (state: RootState): boolean => state.user.isAuthStateObserverMounted;
export const getUserStatus = (state: RootState): UserStatusT => state.user.userStatus;

// #region USER PROFILES (aka STUDENTS) *************************************************
export const hasProfiles = (state: RootState): boolean => state.user.profileIds.length > 0;
export const getActiveProfileId = (state: RootState): ProfileId => state.user.activeProfileId;
export const isActiveProfileIdSelected = (state: RootState): boolean =>
  createSelector([hasProfiles, getActiveProfileId], (profiles, profileId) => profiles && !!profileId)(state);
export const getUserProfileIds = (state: RootState): Array<ProfileId> => state.user.profileIds;
export const getUserProfiles = memoize(
  (state: RootState): Array<IProfile> =>
    state.user.profiles.filter((profile: IProfile) => profile.uid === state.user.uid)
);
export const getProfileTitle = (state: RootState, profileId: ProfileId): string =>
  createSelector([getUserProfiles], (profiles) => {
    return (profiles.find((p) => p.profileId === profileId) as IProfile).title;
  })(state);
export const getActiveProfile = (state: RootState): MaybeType<IProfile> =>
  createSelector([getUserProfiles, getActiveProfileId], (profiles, profileId) => {
    const profile = profiles.find((p) => p.profileId === profileId);
    return profile;
  })(state);
export const getActiveProfileTitle = (state: RootState): string =>
  createSelector([getUserProfiles, getActiveProfileId], (profiles, profileId) => {
    const profile = profiles.find((p) => p.profileId === profileId);
    return profile ? (profile as IProfile).title : 'DemoUser';
  })(state);
export const getActiveProfileAvatar = (state: RootState): string =>
  createSelector([getUserProfiles, getActiveProfileId], (profiles, profileId) => {
    const profile = profiles.find((p) => p.profileId === profileId);
    return profile ? (profile as IProfile).icon : 'sentiment_satisfied';
  })(state);
export const getActiveProfileCourseIds = (state: RootState): CourseIds =>
  createSelector([getUserProfiles, getActiveProfileId], (profiles, profileId) => {
    const profile = profiles.find((p) => p.profileId === profileId);
    return profile ? (profile as IProfile).courseIds : [];
  })(state);
// #endregion

export const canUserEdit = (state: RootState) => state.user.isEditor;
export const isUserAdmin = (state: RootState) => state.user.isAdmin;
export const isUserSignedIn = (state: RootState) =>
  !(
    state.user.userStatus === 'idle' ||
    state.user.userStatus === 'notSignedIn' ||
    state.user.userStatus === 'signingOut' ||
    state.user.userStatus === 'signedOut'
  );
export const isUserInitialized = (state: RootState) => state.user.userStatus !== 'idle';
export const getActiveCareerId = (state: RootState): CareerId => {
  const profileId: ProfileId = getActiveProfileId(state);
  const profileIndex = state.user.profiles.findIndex((p: IProfile) => p.profileId === profileId);
  const careerId: CareerId = profileIndex !== -1 ? state.user.profiles[profileIndex].careerId : '';
  return careerId;
};

export const isVerificationSent = (state: RootState): boolean => state.user.verificationSent;
export const getVerificationSentTimestamp = (state: RootState): number => state.user.verificationSentTimestamp;
export const isEmailVerified = (state: RootState): boolean => state.user.emailVerified;

export const getShowDebugPanel = (state: RootState): boolean => state.user.isAdmin && !!state.user.showDebugPanel;
export const getShowContactSupportPanel = (state: RootState): boolean =>
  state.user.userStatus === 'signedIn' && !!state.user.showContactSupportPanel;

export default userSlice.reducer;
