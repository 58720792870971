import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks/hooks';
import { getShowAwardStar, setShowAwardStar } from 'scenes/montessori/appUiSlice';
import { VoidFunc } from 'types/App.types';
import { AwardCodes } from 'app/constants/AwardCodes';

type TrackerStatusT = 'init' | 'tracking_completed' | 'tracking_started' | undefined;

export const useAwardNotificationDismissTracker = (onDismissFn?: VoidFunc) => {
  const popupTracker = useRef<boolean>(false);
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState<TrackerStatusT>('init');

  const isPopupOpen = useAppSelector(getShowAwardStar);

  useEffect(() => {
    if (isPopupOpen && !popupTracker.current) {
      popupTracker.current = true;
      setStatus('tracking_started');
    } else if (!isPopupOpen && popupTracker.current) {
      popupTracker.current = false;
      setStatus('tracking_completed');
      if (onDismissFn) onDismissFn();
    }
  }, [isPopupOpen]);

  const dispatchShowNotification = (btnLabel: string, title: string, showIcon: boolean) => {
    dispatch(
      setShowAwardStar({
        showAwardStar: true,
        awardCode: AwardCodes.Code0003,
        headerLabel: title,
        showActionButton: showIcon,
        actionButtonLabel: btnLabel,
      })
    );
  };

  return { trackerStatus: status, dispatchShowNotification };
};
