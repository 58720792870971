import React, { useEffect, useRef, useState } from 'react';
import InsetText from 'components/text/inset/InsetText';

import styles from './StarCounter.module.scss';
import { ElementSizeT, PropsWithChildren, VoidFunc } from 'types/App.types';

type StarCounterOwnProps = {
  className?: string;
  count?: number;
  size?: ElementSizeT;
  animationStarted?: VoidFunc;
  animationOver?: VoidFunc;
};

/** Starting duration `ms` between each interval; larger interval appears slower on the screen. */
const minAnimationSpeed = 250;
const maxAnimationSpeed = 30;
/** Time in `ms` to remove (speed up) or to add (slow down) to the current interval */
const speedupAnimationBy = 20;
const slowdownAnimationBy = 20;

type StarCounterProps = PropsWithChildren<StarCounterOwnProps>;

const StarCounter: React.FC<StarCounterProps> = ({ size = 'xs9', count = 0, ...props }) => {
  const timerInterval = useRef<number>(minAnimationSpeed);
  const [currentCount, setCurrentCount] = useState<number>(0);
  const [pulsateCls, setPulsateCls] = useState<string>('');
  const [spacerCls, setSpacerCls] = useState<string>('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSpacerCls(styles.StarCounterSpacer);
      if (currentCount < count) {
        if (currentCount + 3 >= count) {
          setPulsateCls(styles.StarCounterPulsate);
          timerInterval.current = Math.min(timerInterval.current + slowdownAnimationBy, minAnimationSpeed);
        } else {
          timerInterval.current = Math.max(timerInterval.current - speedupAnimationBy, maxAnimationSpeed);
        }
        setCurrentCount(currentCount + 1);
      } else if (currentCount > count) {
        if (currentCount - 3 <= count) {
          setPulsateCls(styles.StarCounterPulsate);
          timerInterval.current = Math.min(timerInterval.current + slowdownAnimationBy, minAnimationSpeed);
        } else {
          timerInterval.current = Math.max(timerInterval.current - speedupAnimationBy, maxAnimationSpeed);
        }
        setCurrentCount(currentCount - 1);
      } else {
        timerInterval.current = minAnimationSpeed;
        setSpacerCls('');
      }
    }, timerInterval.current);

    return () => {
      clearInterval(intervalId);
    };
  }, [count, currentCount]);

  const removeAnimationCls = () => {
    if (props.animationOver) {
      props.animationOver();
    }
    setPulsateCls('');
  };
  const addAnimationCls = () => {
    if (props.animationStarted) {
      props.animationStarted();
    }
  };

  return (
    <div
      className={`${styles.StarCounter} ${spacerCls} ${pulsateCls} ${props.className ?? ''}`}
      onAnimationStart={addAnimationCls}
      onAnimationEnd={removeAnimationCls}>
      <InsetText size={size}>
        {props.children}
        {currentCount}
      </InsetText>
    </div>
  );
};

export default StarCounter;
